import { Dispatch } from 'react';
import fairplayAPI from 'utils/api';
import { toTimelessISOUtc } from 'utils/dates';
import { AlertType, DateRange } from 'utils/interfaces';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { RequestsDictionary } from '../interfaces';

export const fetchData = async <T extends string>({
    companyId,
    dispatch,
    requestsDic,
    range,
    alertFunc,
}: {
    companyId: string;
    dispatch: Dispatch<{ type: T; payload: any }>;
    requestsDic: RequestsDictionary<T>;
    range?: DateRange | Record<'init' | 'end', DateRange>;
    alertFunc?: {
        showAlert: (msg: string, type?: AlertType, autoHide?: boolean) => void;
        hideAlert: () => void;
    };
}) => {
    const params =
        // eslint-disable-next-line no-nested-ternary
        range && 'init' in range && 'end' in range
            ? {
                  start: toTimelessISOUtc(range.init.from),
                  end: toTimelessISOUtc(range.init.to),
                  last_start: toTimelessISOUtc(range.end.from),
                  last_end: toTimelessISOUtc(range.end.to),
              }
            : range
            ? {
                  start: toTimelessISOUtc(range.from),
                  end: toTimelessISOUtc(range.to),
              }
            : undefined;
    alertFunc?.hideAlert();
    // eslint-disable-next-line no-restricted-syntax
    for (const [type, { actionType, errorValue, getUrl }] of Object.entries(requestsDic)) {
        try {
            // TODO: Validate this use case and eslint rule
            // eslint-disable-next-line no-await-in-loop
            const response: any = await fairplayAPI.get(
                    getUrl(companyId),
                    ...(type !== 'sessions' ? [{ params }] : []),
                ),
                fieldValue = response.data.body.results;

            dispatch({
                type: actionType,
                payload: { key: type, value: fieldValue },
            });
        } catch (errResponse: ErrorObj | any) {
            dispatch({
                type: actionType,
                payload: { key: type, value: errorValue },
            });
            alertFunc?.showAlert(
                errResponse?.error || 'Ocurrió un error con el servidor, intente más tarde',
            );
        }
    }
};
