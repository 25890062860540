import { FC, useState, useEffect, ChangeEvent } from 'react';
import { Grid } from '@mui/material';
import { EditableInput } from '@fairplay2/ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import fairplayAPI from 'utils/api';
import { useDataSources } from 'components/dashboard/data-sources/context/DataSourcesContext';
import { getConnectorAlias } from 'components/dashboard/data-sources/utils';
import { EditConnectorAliasProps } from '../../interfaces';

const EditConnectorAlias: FC<EditConnectorAliasProps> = ({ connector }) => {
    const { baseAPI, dispatch } = useDataSources(),
        [newAlias, setNewAlias] = useState(getConnectorAlias(connector)),
        [loading, setLoading] = useState(false),
        [error, setError] = useState('');

    const onCancel = () => {
        setNewAlias(getConnectorAlias(connector));
        setError('');
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewAlias(event.target.value);
        setError('');
    };

    const onSubmit = async () => {
        setLoading(true);
        setError('');
        try {
            const res: any = await fairplayAPI.patch(`${baseAPI}/${connector.id}`, {
                alias: newAlias,
            });
            dispatch({
                type: 'UPDATE',
                payload: res.data.body.results,
            });
        } catch (errResponse: ErrorObj | any) {
            const { error: newError, type } = errResponse;
            if (type === 'form') setError(newError.alias[0]);
            else setError(typeof newError === 'string' ? newError : newError[0]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => setNewAlias(getConnectorAlias(connector)), [connector]);

    return (
        <Grid container alignItems="center">
            <EditableInput
                loading={loading}
                placeholder="Alias del conector"
                type="text"
                size="small"
                value={newAlias}
                error={error}
                confirmButtonLabel="Confirmar nuevo alias"
                editButtonLabel="Editar alias"
                disableConfirmButton={newAlias === connector.alias}
                onChange={onChange}
                onCancel={onCancel}
                onConfirm={onSubmit}
            />
        </Grid>
    );
};

export default EditConnectorAlias;
