import { useState, useRef } from 'react';
import { ConnectorData } from '../interfaces';

/**
 * Allows to manage form-based connectors
 * in order to indicate either a new
 * connection or a reauthorization flow.
 */
const useDualForm = () => {
    const [showForm, setShowForm] = useState<boolean | ConnectorData>(false),
        { onConnect, closeForm, onReauth } = useRef({
            onConnect: () => setShowForm(true),
            closeForm: () => setShowForm(false),
            onReauth: (connector: ConnectorData) => {
                if (connector) setShowForm(connector);
            },
        }).current;

    return {
        showForm: !!showForm,
        connector: typeof showForm === 'object' ? showForm : null,
        onConnect,
        closeForm,
        onReauth,
    };
};

export default useDualForm;
