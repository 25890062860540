import { DateRange, SyntheticDate } from 'utils/interfaces';
import {
    MONTHS,
    SHORT_READABLE_FORMAT,
    READABLE_FORMAT,
    TIME_READABLE_FORMAT,
    TIME_SHORT_READABLE_FORMAT,
    TIMELESS_ISO_FORMAT,
    DATEPICKER_FORMAT,
    getDateParts,
    toReadableTimelessISO,
    FormatOptions,
    DateRepresentation,
    toDate,
    isValidDate,
    negateLocalOffset,
    syntheticDateToTimelessISO,
    localFormat,
    format as formatUtc,
    toTimelessISO as toTimelessISOUtc,
    getMonthRangeData as getMonthRangeDataUtc,
    generateYearsArray as generateYearsArrayUtc,
    generateMonthsArray as generateMonthsArrayUtc,
    generateQuartersArray as generateQuartersArrayUtc,
} from './utc';

export {
    toDate,
    isValidDate,
    negateLocalOffset,
    toTimelessISOUtc,
    localFormat,
    formatUtc,
    getMonthRangeDataUtc,
    generateYearsArrayUtc,
    generateMonthsArrayUtc,
    generateQuartersArrayUtc,
    MONTHS,
    SHORT_READABLE_FORMAT,
    READABLE_FORMAT,
    TIME_READABLE_FORMAT,
    TIME_SHORT_READABLE_FORMAT,
    TIMELESS_ISO_FORMAT,
    DATEPICKER_FORMAT,
    getDateParts,
    toReadableTimelessISO,
    syntheticDateToTimelessISO,
};

// !! It's important to specify 'es-MX' for correctly parsing the datetime
export const CDMXDateTimeFormatter = new Intl.DateTimeFormat('es-MX', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    // L M M J V S D
    weekday: 'narrow',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'America/Mexico_City',
    // For some reason, node 18.20 is overriding hourCycle using h24 instead of h23,
    //  going against https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#hour12
    ...(process.env.NODE_ENV === 'test' ? { hourCycle: 'h23' } : { hour12: false }),
});

/**
 *
 * @param realDate
 * @returns A date object with year, month, day, hour, minute and second
 *          values equivalent to those at CDMX timezone for the given real date.
 *          Note that UTC offset is set to 0 to ensure that any date manipulations
 *          will respect the equivalent values at CDMX.
 */
export const mimicDateAtCDMX = (realDate: Date = new Date()) => {
    if (!isValidDate(realDate)) return realDate;

    const { day, month, year, hour, minute, second } = getDateParts(
        CDMXDateTimeFormatter,
        realDate,
    );

    return new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}.000Z`);
};

export const format = (
    // eslint-disable-next-line @typescript-eslint/default-param-last
    date: DateRepresentation = new Date(),
    formatString: string,
    options?: FormatOptions,
) => formatUtc(mimicDateAtCDMX(toDate(date)), formatString, options);

/**
 * @param date Date object to format. Converted to CDMX timezone prior to calculations.
 * @returns ISO string without time data.
 */
export const toTimelessISO = (date: Date = new Date()) => toTimelessISOUtc(mimicDateAtCDMX(date));

/**
 *
 * @param delta Indicates if any amount of months
 *              should be added/substracted from the reference date.
 * @param referenceDate Defaults to current date.
 *                      Converted to CDMX timezone prior to calculations
 * @returns Month data of the first and last day of the month
 */
export const getMonthRangeData = (delta: number = 0, referenceDate: Date = new Date()): DateRange =>
    getMonthRangeDataUtc(delta, mimicDateAtCDMX(referenceDate));

/**
 * Generates an array of years in the format 'YYYY', starting at
 * the reference date and going back in time.
 *
 * @param yearsBack     Number of years in the past
 * @param referenceDate Defaults to current date,
 *                      and its converted to CDMX timezone prior to calculations.
 */
export const generateYearsArray = (yearsBack: number, startAt: Date = new Date()): number[] =>
    generateYearsArrayUtc(yearsBack, mimicDateAtCDMX(startAt));

/**
 * Generates an array of months in the format 'MMMM YYYY',
 * starting at any given date and going back in time.
 *
 * @param stopAt    Furthest date to consider in the array. Month is 1-indexed.
 * @param startAt   Initial date. Defaults to current date,
 *                  and its converted to CDMX timezone prior to calculations.
 */
export const generateMonthsArray = (
    stopAt: { year: number; month: number },
    startAt: Date = new Date(),
) => generateMonthsArrayUtc(stopAt, mimicDateAtCDMX(startAt));

/**
 * Generates an array of (year) quarter labels,
 * starting at any given date and going back in time.
 *
 * @param stopAt    Furthest date to consider in the array. Month is 1-indexed.
 * @param startAt   Initial date. Defaults to current date.
 */
export const generateQuartersArray = (
    stopAt: { year: number; month: number },
    startAt: Date = new Date(),
) => generateQuartersArrayUtc(stopAt, mimicDateAtCDMX(startAt));

// TODO: Test util
/**
 * Generates an object containing year, month & day properties,
 * from a TimelessISO date.
 *
 * @param timelessISO    Date with ISO format without time.
 * @returns    Object containing year, month & day properties.
 */
export const toSyntheticDate = (timelessISO: string): SyntheticDate | null => {
    const [year, month, day] = timelessISO.split('-'),
        syntheticDate = { year: Number(year), month: Number(month), day: Number(day) };

    if (Object.values(syntheticDate).includes(NaN)) return null;

    return syntheticDate;
};
