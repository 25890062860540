import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { SettingsOutlined } from '@mui/icons-material';
import { IconBtn, Tooltip } from '@fairplay2/ui';
import { UserSettingsButtonProps } from '../interfaces';

export const UserSettingsButton: FC<UserSettingsButtonProps> = ({ size = 'lg' }) => {
    const { push } = useHistory();

    return (
        <Tooltip title="Editar usuario" placement="bottom">
            <IconBtn
                color="secondary"
                onClick={() => push('/app/user/edit')}
                sx={size === 'lg' ? { svg: { fontSize: '1.3em' } } : undefined}
            >
                <SettingsOutlined fontSize="inherit" />
            </IconBtn>
        </Tooltip>
    );
};

export default UserSettingsButton;
