import { FC } from 'react';
import { CheckboxProps, Grid } from '@mui/material';
import { Typography, Checkbox } from '@fairplay2/ui';
import { StyledCheckboxListItem } from './styles';

const CheckboxListItem: FC<{
    leftText?: string;
    rightText?: string;
    inputValue: string;
    checked: CheckboxProps['checked'];
    onCheck: CheckboxProps['onChange'];
}> = ({ inputValue, leftText = '', rightText, checked, onCheck }) => (
    <StyledCheckboxListItem component="li" container alignItems="center">
        <Grid item xs={rightText ? 5 : 10} sm={!!rightText && 6}>
            <Typography id={`left_for_${inputValue}`} variant="body1" color="text.primary">
                {leftText}
            </Typography>
        </Grid>
        {!!rightText && (
            <Grid container item className="right-text" xs={5} sm={4}>
                <Typography id={`right_for_${inputValue}`} variant="body1" color="text.primary">
                    {rightText}
                </Typography>
            </Grid>
        )}
        <Grid container item xs={2} justifyContent="flex-end">
            <Checkbox
                inputProps={{
                    value: inputValue,
                    'aria-labelledby': `left_for_${inputValue}${
                        rightText ? ` right_for_${inputValue}` : ''
                    }`,
                }}
                onChange={onCheck}
                checked={!!checked}
            />
        </Grid>
    </StyledCheckboxListItem>
);

export default CheckboxListItem;
