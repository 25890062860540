import { CircleOutlined } from '@mui/icons-material';
import { useMediaQuery, useTheme, StepIconProps } from '@mui/material';
import { Step1Icon, Step2Icon, Step3Icon, Step4Icon, CheckCircleIcon } from '@fairplay2/ui';
import { MobileCircleStep, MobileInactiveCircleStep } from '../styles/index';

const QontoStepIcon = ({
    active,
    completed,
    icon,
    hideOnSmallScreens = true,
}: StepIconProps & { hideOnSmallScreens?: boolean }) => {
    const theme = useTheme(),
        mdDown = useMediaQuery(theme.breakpoints.down('md')),
        iconStyles = {
            fill: active ? theme.palette.secondary.main : theme.palette.grey[400],
        };

    if (completed) return <CheckCircleIcon width={25} />;

    if (mdDown && hideOnSmallScreens)
        return active ? <MobileCircleStep /> : <MobileInactiveCircleStep />;

    switch (icon) {
        case 1:
            return <Step1Icon width={25} style={iconStyles} />;
        case 2:
            return <Step2Icon width={25} style={iconStyles} />;
        case 3:
            return <Step3Icon width={25} style={iconStyles} />;
        case 4:
            return <Step4Icon width={25} style={iconStyles} />;
        default:
            return <CircleOutlined />;
    }
};

export default QontoStepIcon;
