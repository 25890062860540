import { useFieldArray } from 'react-hook-form';
import { FC, useEffect } from 'react';
import { Delete, AddCircle } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { DefaultInput, IconBtn } from '@fairplay2/ui';
import { OtherSalesSourcesListProps } from '../../interfaces';

const OtherSalesSourcesList: FC<OtherSalesSourcesListProps> = ({ control, register }) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'otherSalesSources',
        rules: { maxLength: 3 },
    });

    useEffect(() => {
        fields.forEach((item, index) => {
            if (item.name === '') remove(index);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid item container direction="column" gap={2} mt={1}>
            {fields.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item container key={index} gap={1} alignItems="center">
                    <Grid item xs={10}>
                        <DefaultInput
                            label={`Especifica cuál (opcional) ${index + 1}`}
                            id={`otherSalesSources.${index}.name`}
                            placeholder="Especifica cuál (opcional)"
                            {...register(`otherSalesSources.${index}.name` as const)}
                            defaultValue={item.name}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        {fields.length - 1 !== index || fields.length === 3 ? (
                            <IconBtn
                                onClick={() => remove(index)}
                                color="secondary"
                                variant="outlined"
                                aria-label="Eliminar fuente de ventas"
                            >
                                <Delete />
                            </IconBtn>
                        ) : (
                            <IconBtn
                                onClick={() => append({ name: '' })}
                                color="secondary"
                                aria-label="Agregar fuente de ventas"
                                sx={{ p: '0 !important' }}
                            >
                                <AddCircle fontSize="large" />
                            </IconBtn>
                        )}
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

export default OtherSalesSourcesList;
