import { FC } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Typography, NoResults, TableSkeleton } from '@fairplay2/ui';
import { DocumentsTableProps, Column, Document } from '../interfaces';
import DocTableItem from './DocTableItem';
import UploadDocTableItem from './UploadDocTableItem';

const columns: Column[] = [
    { id: 'name', label: 'Nombre del documento' },
    { id: 'status', label: 'Estatus', minWidth: 120 },
    { id: 'date', label: 'Fecha de carga' },
    { id: 'menu', label: 'Acciones', align: 'center', minWidth: 200 },
];

const DocumentsTable: FC<DocumentsTableProps> = ({
    documents,
    loading,
    showAlert,
    setSelectedDocument,
    documentTypes,
    openModal,
    onFileSelect,
}) => {
    const onUpdateDocument = (document: Document) => {
        setSelectedDocument({
            document,
            type: document.documentType,
        });
        openModal('uploadDocument');
    };

    const onDeleteDocument = (document: Document) => {
        setSelectedDocument((prev) => ({ ...prev, document }));
        openModal('deleteDocument');
    };

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map(({ id, align, minWidth, label }) => (
                            <TableCell key={id} align={align} sx={{ minWidth }}>
                                <Typography variant="body1" color="text.primary" fontWeight={500}>
                                    {label}
                                </Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <TableSkeleton columns={4} rows={5} />
                    ) : (
                        <>
                            {documents.map((document) => (
                                <DocTableItem
                                    key={document.id}
                                    document={document}
                                    onUpdateDocument={onUpdateDocument}
                                    onDeleteDocument={onDeleteDocument}
                                    showAlert={showAlert}
                                />
                            ))}
                            {documentTypes.map((type) => (
                                <UploadDocTableItem
                                    key={type.id}
                                    type={type}
                                    documentTypes={documentTypes}
                                    onFileSelect={onFileSelect}
                                    setSelectedDocument={setSelectedDocument}
                                />
                            ))}
                        </>
                    )}
                </TableBody>
            </Table>
            {documents.length === 0 && !loading && (
                <NoResults>Todavía no hay documentos legales registrados</NoResults>
            )}
        </TableContainer>
    );
};

export default DocumentsTable;
