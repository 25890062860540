import { Fragment, FC, useContext } from 'react';
import { Box, Collapse, Grid } from '@mui/material';
import { Alert, DefaultBtn, FilePicker, InterpunctLoader, Typography } from '@fairplay2/ui';
import { validateFileSize } from 'utils/files';
import SessionContext from 'context/session/sessionContext';
import fairplayAPI from 'utils/api';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { toFormData } from 'utils/parsing';
import { FileUploaderProps } from '../interfaces';

const FileUploader: FC<FileUploaderProps> = ({
    file,
    setFile,
    onCancel,
    onComplete,
    cancelText = 'Cancelar',
    submitText = 'Confirmar',
    awaitingOperation,
    setAwaitingOperation,
    alertData: { alert, showAlert, hideAlert },
    Instructions,
    limitInputWidth,
}) => {
    const { selectedCompany } = useContext(SessionContext),
        error = alert.message as string;

    const onChangeFile = (newFile: File | null) => {
        hideAlert();
        setFile(newFile);

        if (newFile) {
            const fileError = validateFileSize(
                newFile,
                5,
                'No puedes subir archivos mayores a 5MB. Te sugerimos guardar el archivo como CSV o dividir las filas del archivo en multiples archivos.',
            );
            if (fileError) showAlert(fileError, 'error', false);
        }
    };

    const onSubmit = async () => {
        hideAlert();
        setAwaitingOperation(true);

        try {
            await fairplayAPI.post(
                `/v2/companies/${selectedCompany.company?.id}/manual-upload/create`,
                toFormData({ file }),
            );
            setFile(null);
            onComplete();
        } catch (errResponse: ErrorObj | any) {
            let newError = '',
                autoHide = true;

            if (errResponse.type === 'form') {
                // eslint-disable-next-line no-restricted-syntax
                for (const value of Object.values(
                    errResponse.error.fileUrl?.[0] || errResponse.error,
                )) {
                    if (Array.isArray(value) && value.length > 0)
                        newError += `${newError ? '\n' : ''}${value.join('\n')}`;
                }
                // disabling autohide, to ensure the user can read
                //  all possible errors and cannot retry submitting
                //  the same file
                autoHide = false;
            } else newError = errResponse?.error || 'Se ha producido un error. Intente más tarde';

            showAlert(newError, 'error', autoHide);
        } finally {
            setAwaitingOperation(false);
        }
    };

    return (
        <Box maxWidth={487} mx="auto" width="100%">
            <Grid container maxWidth={limitInputWidth ? 390 : undefined} mx="auto" width="100%">
                {Instructions}
                <FilePicker
                    id="manual-upload-file"
                    accept=".csv"
                    onFileChange={onChangeFile}
                    fileName={file?.name}
                    icon={null}
                    variant="compact"
                    paperVariant="dashed"
                    sx={{
                        flexGrow: 1,
                        height: 60,
                        '.FpFileUpload-fileName': file
                            ? undefined
                            : {
                                  maxWidth: 'initial',
                                  pl: '20px',
                              },
                    }}
                />
            </Grid>

            <Typography
                fontSize={14}
                color="text.primary"
                fontWeight={400}
                mt="10px"
                maxWidth={390}
                mx="auto"
            >
                Cambia el archivo haciendo clic sobre el área punteada
            </Typography>
            <Grid container justifyContent="center">
                <Collapse in={alert.open}>
                    <Alert severity="error" sx={{ mt: 1.5, mb: -1 }}>
                        {error.includes('\n')
                            ? error.split('\n').map((errorLine, i) => (
                                  // eslint-disable-next-line react/no-array-index-key
                                  <Fragment key={i}>
                                      {errorLine}
                                      <br />
                                  </Fragment>
                              ))
                            : error}
                    </Alert>
                </Collapse>
            </Grid>
            {awaitingOperation ? (
                <Grid item textAlign="center" mt={4} display="flex" justifyContent="center">
                    <InterpunctLoader />
                </Grid>
            ) : (
                <Grid
                    className="FpInsights-FileUploaderActions"
                    container
                    rowSpacing={2}
                    columnSpacing={[0, 3]}
                    width={[undefined, 'auto']}
                    ml="auto"
                    mt={2}
                    justifyContent="center"
                >
                    <Grid item xs={12} sm="auto">
                        <DefaultBtn size="small" fullWidth variant="outlined" onClick={onCancel}>
                            {cancelText}
                        </DefaultBtn>
                    </Grid>
                    <Grid item xs={12} sm="auto">
                        <DefaultBtn
                            size="small"
                            fullWidth
                            onClick={onSubmit}
                            disabled={!file || alert.open}
                        >
                            {submitText}
                        </DefaultBtn>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export default FileUploader;
