import { FC, useState, useEffect } from 'react';
import { Status, StatusType } from 'common-components';
import { Grid } from '@mui/material';
import { InfoAlertProps, InfoStatus } from '../../interfaces';

const parseInfo = (info: string) => {
    switch (info) {
        case 'isBlended':
            return {
                status: 'active',
                msg: 'Contrato combinado',
            };
        case 'isDelinquent':
            return {
                status: 'denied',
                msg: 'Pagos atrasados',
            };
        case 'isFullyDisbursed':
            return {
                status: 'draft',
                msg: 'Dispersado por completo',
            };
        case 'isFullyPaid':
            return {
                status: 'active',
                msg: 'Liquidado por completo',
            };
        case 'isMaxedOut':
            return {
                status: 'denied',
                msg: 'Sin fondos disponibles',
            };
        case 'isNonPerformingLoan':
            return {
                status: 'denied',
                msg: 'Financiamiento moroso',
            };
        case 'isWriteOff':
            return {
                status: 'denied',
                msg: 'Contrato cancelado',
            };
        default:
            return undefined;
    }
};

const InfoAlert: FC<InfoAlertProps> = ({ infos, ...props }) => {
    const [status, setStatus] = useState<InfoStatus[]>([]);

    useEffect(() => {
        // eslint-disable-next-line no-restricted-syntax
        for (const info in infos) {
            if (infos[info]) {
                const element = parseInfo(info);
                if (element) setStatus((prev) => [...prev, element]);
            }
        }
    }, [infos]);

    return (
        <Grid container>
            {status.length > 0 &&
                infos &&
                status.map((item, i) => (
                    <Status
                        // TODO: Validate interface to avoid using index
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        variant="standard"
                        status={item.status as StatusType}
                        sx={{ marginBottom: '5px', marginRight: '15px' }}
                        {...props}
                    >
                        {item.msg}
                    </Status>
                ))}
        </Grid>
    );
};

export default InfoAlert;
