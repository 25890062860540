import { FC, ReactNode, useState, useEffect } from 'react';
import { Collapse, Grid } from '@mui/material';
import { Alert } from '@fairplay2/ui';
import { useAlert } from 'utils/hooks';
import { DataSourcesGridProps, GroupedPlatformKey } from '../interfaces';
import PlatformComponents from '../platforms';
import { useDataSources } from '../context/DataSourcesContext';

const DataSourcesGrid: FC<DataSourcesGridProps> = ({ platforms, justifyContent }) => {
    const { platforms: allPlatforms } = useDataSources(),
        [items, setItems] = useState<ReactNode[]>([]),
        { alert, showAlert } = useAlert();

    useEffect(() => {
        setItems(
            platforms.map((key) => {
                if (Object.keys(allPlatforms).includes(key as GroupedPlatformKey)) {
                    const Component = PlatformComponents[key as GroupedPlatformKey];
                    return (
                        <Grid item key={key} position="relative" sx={{ '&:hover': { zIndex: 2 } }}>
                            <Component notify={showAlert} />
                        </Grid>
                    );
                }
                return null;
            }),
        );
    }, [allPlatforms, platforms, showAlert]);

    return (
        <Grid
            container
            spacing={4}
            justifyContent={justifyContent || ['center', 'flex-start']}
            mt={0}
        >
            <Grid
                item
                container
                xs={12}
                justifyContent="center"
                py="0 !important"
                position="relative"
            >
                <Collapse in={alert.open}>
                    <Grid container item xs={12}>
                        <Alert severity={alert.type} sx={{ pt: 1.25, mt: 2 }}>
                            {alert.message}
                        </Alert>
                    </Grid>
                </Collapse>
            </Grid>
            {items}
        </Grid>
    );
};

export default DataSourcesGrid;
