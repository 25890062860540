import { FC } from 'react';
import { Delete } from '@mui/icons-material';
import { Paper, Typography, IconBtn, Tooltip } from '@fairplay2/ui';
import { capitalize } from 'utils/formatting';
import { UserProps } from '../interfaces';
import { InfoGrid, StyledAvatar, InfoContainer, ActionsContainer } from '../styles';

const User: FC<UserProps> = ({ user, onDeleteUser }) => (
    <Paper
        variant="elevation"
        sx={{
            width: '100%',
            height: { xs: '300px !important', md: '220px !important' },
        }}
    >
        <InfoGrid>
            <InfoContainer>
                <Typography component="div" color="primary.dark" fontWeight={700}>
                    {user.fullName}
                </Typography>
                <Typography component="div" color="primary.main" ellipsis mt="5px">
                    {user.email}
                </Typography>
                <Typography component="div" variant="body1" color="text.primary" mt="5px">
                    {capitalize(user.role)}
                </Typography>
            </InfoContainer>

            <StyledAvatar fallbackTo="user" src={user.avatar || ''} size={100} />
            <ActionsContainer>
                <Tooltip title="Eliminar" placement="top" distance={0}>
                    <IconBtn
                        variant="outlined"
                        color="secondary"
                        onClick={() => onDeleteUser(user)}
                        sx={{ bgcolor: '#fff !important' }}
                    >
                        <Delete />
                    </IconBtn>
                </Tooltip>
            </ActionsContainer>
        </InfoGrid>
    </Paper>
);

export default User;
