export const itemsContainerSx = {
    maxHeight: '310px',
    overflowY: 'auto',
    overflowX: 'hidden',
};

export const nonClickableItemSx = {
    listStyle: 'none',
    padding: '15px',
};
