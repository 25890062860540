import { FC, useContext, useState, ChangeEvent } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { Paper, ProfileData } from '@fairplay2/ui';
import SessionContext from 'context/session/sessionContext';
import { WomanGearAvatar } from 'assets/SVGs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import fairplayAPI from 'utils/api';
import { IMAGE_CONFIG } from 'utils/api/configs';
import { toFormData } from 'utils/parsing';
import { AvatarPaperProps } from '../interfaces';

const AvatarPaper: FC<AvatarPaperProps> = ({ onAlert }) => {
    const theme = useTheme(),
        mdDown = useMediaQuery(theme.breakpoints.down('md')),
        { selectedCompany, updateCompany } = useContext(SessionContext),
        [loading, setLoading] = useState(false);

    const onImageOrg = async (event: ChangeEvent<HTMLInputElement>) => {
        const imgFile =
            event.target.files && event.target.files.length > 0 ? event.target.files[0] : null;
        if (imgFile) {
            setLoading(true);
            try {
                const res: any = await fairplayAPI.patch(
                    `/v1/companies/${selectedCompany.company?.id}`,
                    toFormData({ avatar: imgFile }),
                    IMAGE_CONFIG,
                );
                const newCompany = { ...selectedCompany, company: res.data.body.results };
                await updateCompany(newCompany, true);
            } catch (errResponse: ErrorObj | any) {
                if (errResponse.type === 'form') onAlert(errResponse.error.avatar, 'error');
                else if (errResponse?.error) onAlert(errResponse.error, 'error');
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Paper variant="elevation" sx={{ width: '596px', mt: '30px' }}>
            <ProfileData
                src={loading ? undefined : selectedCompany.company?.avatar || ''}
                name={selectedCompany.company?.registeredName}
                legend={selectedCompany.company?.rfc}
                avatarAlt="Foto de perfil de la empresa"
                size="lg"
                nameEllipsis={false}
                fallbackTo="company"
                GridProps={{
                    sx: {
                        p: '20px 25px',
                        pr: { md: '110px' },
                    },
                }}
                AvatarInputProps={{ onChange: onImageOrg }}
                skeletonsTestidPrefix="company-profile"
            />
            {!mdDown && (
                <WomanGearAvatar
                    aria-hidden
                    style={{
                        zIndex: 0,
                        position: 'absolute',
                        right: -100,
                        top: -16,
                        width: 188,
                        height: 'auto',
                    }}
                />
            )}
        </Paper>
    );
};

export default AvatarPaper;
