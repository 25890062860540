import { FC } from 'react';
import { Grid, useMediaQuery, useTheme, MenuItem, Collapse } from '@mui/material';
import { DefaultBtn, DefaultInput, Select, InterpunctLoader, Alert } from '@fairplay2/ui';
import { Step3Props } from '../interfaces';

const nationalCurrency = [
    {
        value: 'MXN',
        name: 'Peso mexicano',
    },
    {
        value: 'USD',
        name: 'Dólar estadounidense',
    },
];

const Step3: FC<Step3Props> = ({
    alert,
    vendorType,
    values,
    errors,
    loading,
    currencies,
    onValueChange,
    onStepChange,
    onBlur,
}) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Grid container spacing={4} mt="30px">
                <Grid container item xs={12} sm={6} alignItems="center">
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {loading ? (
                        <InterpunctLoader />
                    ) : vendorType === 'national' ? (
                        <DefaultInput
                            labelVariant="contained"
                            id="account-clabe"
                            type="text"
                            inputProps={{ maxLength: 18 }}
                            name="accountClabe"
                            label="CLABE:*"
                            value={values.accountClabe}
                            onChange={onValueChange}
                            onBlur={() => onBlur('accountClabe', 'Ingresa la clabe')}
                            error={errors.accountClabe}
                        />
                    ) : (
                        <DefaultInput
                            labelVariant="contained"
                            id="account-swift"
                            name="accountSwift"
                            label="Código SWIFT:*"
                            value={values.accountSwift.toUpperCase()}
                            onChange={onValueChange}
                            onBlur={() => onBlur('accountSwift', 'Ingresa el swift')}
                            error={errors.accountSwift}
                        />
                    )}
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DefaultInput
                        disabled
                        labelVariant="contained"
                        id="account-bank"
                        name="accountBank"
                        label="Banco:"
                        value={values.accountBank}
                        error={errors.accountBank}
                    />
                </Grid>

                {vendorType === 'international' && (
                    <>
                        <Grid item xs={12} sm={6}>
                            <DefaultInput
                                labelVariant="contained"
                                id="account-number"
                                name="accountNumber"
                                label="Número de cuenta:*"
                                placeholder="Puede ser alfanumérico"
                                value={values.accountNumber}
                                onChange={onValueChange}
                                onBlur={() =>
                                    onBlur('accountNumber', 'Ingresa el número de cuenta')
                                }
                                error={errors.accountNumber}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DefaultInput
                                disabled
                                labelVariant="contained"
                                id="account-country"
                                name="accountCountry"
                                label="País del banco:"
                                value={values.accountCountry.name}
                                error={errors.accountCountry}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DefaultInput
                                disabled
                                labelVariant="contained"
                                id="account-state"
                                name="accountState"
                                label="Ciudad del banco:"
                                value={values.accountState}
                                error={errors.accountState}
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={12} sm={6}>
                    <Select
                        label="Seleccionar Divisa*"
                        id={`${vendorType}-account-currency`}
                        name="accountCurrency"
                        disabled={vendorType === 'international' && currencies.length === 0}
                        value={values.accountCurrency}
                        onChange={onValueChange}
                        onBlur={() => onBlur('accountCurrency', 'Ingresa la divisa')}
                        error={errors.accountCurrency}
                        ignoreDisabledStyles
                    >
                        <MenuItem value="" disabled>
                            Seleccionar Divisa*
                        </MenuItem>
                        {vendorType === 'national'
                            ? nationalCurrency.map((currency) => (
                                  <MenuItem key={currency.value} value={currency.value}>
                                      <Grid container>
                                          <Grid item xs={2}>
                                              {currency.value}
                                          </Grid>
                                          <Grid item xs={10}>
                                              {currency.name}
                                          </Grid>
                                      </Grid>
                                  </MenuItem>
                              ))
                            : currencies.map((currency) => (
                                  <MenuItem key={currency} value={currency}>
                                      {currency}
                                  </MenuItem>
                              ))}
                    </Select>
                </Grid>
            </Grid>
            <Grid item container xs={12} justifyContent="flex-end" padding="10px 0px">
                <Collapse sx={{ width: { xs: '100%', sm: 'initial' } }} in={alert.open}>
                    <Alert sx={{ justifyContent: 'center' }} severity={alert.type}>
                        {alert.message}
                    </Alert>
                </Collapse>
            </Grid>
            <Grid container justifyContent="flex-end">
                <DefaultBtn
                    fullWidth={smDown}
                    size="small"
                    variant="outlined"
                    onClick={(e) => onStepChange(e, 1)}
                    sx={{
                        mb: { xs: '10px', sm: 0 },
                    }}
                >
                    Atrás
                </DefaultBtn>
                <DefaultBtn
                    fullWidth={smDown}
                    size="small"
                    onClick={(e) => onStepChange(e, 3)}
                    sx={{
                        mb: { xs: '20px', sm: 0 },
                        ml: { xs: 0, sm: '20px' },
                    }}
                >
                    Continuar
                </DefaultBtn>
            </Grid>
        </>
    );
};

export default Step3;
