import { FC, useContext } from 'react';
import { Grid, useTheme, useMediaQuery } from '@mui/material';
import { Typography, DefaultBtn, Paper, LabelledIconBtn, InterpunctLoader } from '@fairplay2/ui';
import { DeleteForever } from '@mui/icons-material';
import { useDisbursementValuesContext } from 'context/disbursements/DisbursementValuesContext';
import SessionContext from 'context/session/sessionContext';
import { format, SHORT_READABLE_FORMAT } from 'utils/dates';
import { SingleDisbursementProps } from '../interfaces';

const SingleDisbursement: FC<SingleDisbursementProps> = ({
    children,
    index,
    openDeleteModal,
    onConfirm,
    loading,
}) => {
    const theme = useTheme(),
        mdDown = useMediaQuery(theme.breakpoints.down('md')),
        { selectedCompany } = useContext(SessionContext),
        { selectedContract, cartData, disbursementLocation, disbursementAlert } =
            useDisbursementValuesContext();
    return (
        <Grid container spacing={4} mt="5px">
            <Grid item xs={12} md={3} order={{ md: 1, xs: 2 }}>
                <Paper
                    variant="elevation"
                    sx={{
                        width: '100%',
                        height: { xs: 'fit-content !important', md: '256px !important' },
                    }}
                >
                    <Grid item xs={12} padding="20px 25px">
                        <Typography variant="subtitle2" color="text.primary">
                            Fecha solicitud
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            color="primary.main"
                            fontWeight={700}
                            mb="15px"
                        >
                            {format(new Date(), SHORT_READABLE_FORMAT, {
                                fallbackString: '',
                            })}
                        </Typography>
                        <Typography variant="subtitle2" color="text.primary">
                            {disbursementLocation === 'Internacional'
                                ? 'Nombre legal / TAX ID'
                                : 'Razón social / RFC'}
                        </Typography>
                        <Typography variant="subtitle2" color="primary.main" fontWeight={700}>
                            {selectedContract.productType === 'working-capital'
                                ? cartData[index].supplierData?.registeredName
                                : selectedCompany.company?.registeredName}
                        </Typography>
                        <Typography variant="subtitle2" color="primary.dark">
                            {selectedContract.productType === 'working-capital'
                                ? cartData[index].supplierData?.taxId
                                : selectedCompany.company?.rfc}
                        </Typography>
                    </Grid>
                </Paper>
            </Grid>
            {children}
            <Grid
                item
                container
                xs={12}
                md={3}
                justifyContent="flex-end"
                alignSelf="flex-start"
                order={{ md: 3, xs: 1 }}
            >
                {!!index && (
                    <LabelledIconBtn
                        label="Eliminar esta dispersión"
                        LabelProps={{
                            fontSize: '10px',
                            margin: '15px',
                            textTransform: 'none !important' as 'none',
                        }}
                        onClick={openDeleteModal}
                        id={String(index)}
                    >
                        <DeleteForever
                            sx={{
                                backgroundColor: 'error.main',
                                color: 'background.default',
                                borderRadius: '50%',
                                padding: '5px',
                                margin: '-5px',
                            }}
                        />
                    </LabelledIconBtn>
                )}
            </Grid>
            <Grid
                item
                container
                columnSpacing={{ xs: 2, md: 0 }}
                marginTop={{ xs: 0, md: '-80px' }}
                order={{ xs: 4 }}
                justifyContent="flex-end"
            >
                <Grid item container xs={12} md={index ? 9 : 12} justifyContent="flex-end">
                    {loading ? (
                        <InterpunctLoader data-testid="interpunct-loader-disbursement-form" />
                    ) : (
                        <DefaultBtn
                            disabled={
                                disbursementAlert.severity === 'error' && !!disbursementAlert.msg
                            }
                            fullWidth={mdDown}
                            onClick={onConfirm}
                        >
                            Siguiente
                        </DefaultBtn>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SingleDisbursement;
