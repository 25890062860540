import { formatAmount } from 'utils/parsing';
import {
    GroupedPlatformKey,
    DataSourcePlatform,
} from 'components/dashboard/data-sources/interfaces';
import { getConnectorAlias } from 'components/dashboard/data-sources/utils';
import { compare } from 'utils/lists';
import {
    Datum,
    GetEffectiveMultipleSelectionArgs,
    GMVOrdersAverage,
    GMVOrdersResult,
    TotalsByChannelResult,
} from '../trading/components/interfaces';
import { SummariesKeys } from '../trading/interfaces';
import { CreateGlobalDataArgs, GMVDataForRequest } from '../interfaces';
import { VALID_CHANNELS } from './constants';

export const filtersRenderValue = (
    selected: unknown,
    dictionary: Record<string, string>,
    allOption = 'all',
) => {
    if ((selected as string[]).includes(allOption)) return dictionary[allOption];
    return (selected as string[])
        .map((store) => dictionary[store as keyof typeof dictionary])
        .join(', ');
};

export const getEffectiveMultipleSelection = ({
    allOption = 'all',
    currentValue,
    dictionary,
    newValue,
}: GetEffectiveMultipleSelectionArgs) => {
    const dictionaryLength = Object.keys(dictionary).length - 2,
        // If the newValue has "" it means is the first try and the length is 2 and the dictionary has
        // only 1 option it means the user has checked all the options because has only one
        isFirstTryEdgeCase =
            newValue.includes('') && newValue.length === 2 && dictionaryLength === 1,
        // This second edge case is when the dictionary has 2 options
        isFirstTrySecondEdgeCase =
            newValue.includes('') && newValue.length === 2 && dictionaryLength === 2,
        // If all the options are checked, we want to return all the options cheking `allOption`
        isEquivalentToAll = !newValue.includes(allOption) && newValue.length === dictionaryLength,
        // If `allOption` is the user last checked, we want to return all the options cheking `allOption`
        isCheckingFromAll =
            newValue.includes(allOption) && newValue[newValue.length - 1] === allOption;

    // MUI multiple select component returns an array fo strings in the order selected
    // If the array includes `allOption`, we want to return all the other options
    // and in the case that `allOption` is removed, we want to remove all the other options
    if (currentValue.includes(allOption) && !newValue.includes(allOption)) {
        return [''];
    }

    // If `currentValue` has `allOption` checked and the new value has `allOption` and the length is 1
    // it means the user has just 1 option and has unckecked that option
    if (currentValue.includes(allOption) && newValue.includes(allOption) && newValue.length === 1) {
        return [''];
    }

    if (
        (!isFirstTrySecondEdgeCase &&
            (isFirstTryEdgeCase || isEquivalentToAll || isCheckingFromAll)) ||
        (isFirstTrySecondEdgeCase && isCheckingFromAll)
    ) {
        return Object.keys(dictionary).filter((val) => val !== '');
    }

    const filteredValues = newValue.filter((val) => val !== allOption && val !== '');

    if (filteredValues.length === 0) {
        return [''];
    }

    return filteredValues;
};

export const getStoresDic = (
    platforms: Record<GroupedPlatformKey, DataSourcePlatform | undefined>,
) => {
    if (!platforms) return {};

    return Object.entries(platforms)
        .flatMap(([key, value]) => {
            // Forcing type because .includes will work regardless of the value being passed
            if ((VALID_CHANNELS as string[]).includes(key) && value) {
                return value.connectors;
            }
            return [];
        })
        .sort((A, B) => compare(getConnectorAlias(B), getConnectorAlias(A)))
        .reduce((acc, connector) => {
            acc[connector.id] = getConnectorAlias(connector);
            return acc;
        }, {} as Record<string, string>);
};

// `chartRef` tiped as any to avoid importing HighchartsReact
export const convertSvgToPngURL = async (chartRef: any): Promise<string> => {
    const svg = chartRef.current.chart.getSVG();
    const svgUrl = URL.createObjectURL(new Blob([svg], { type: 'image/svg+xml' }));

    return new Promise((resolve) => {
        const svgImage = new Image();

        svgImage.onload = () => {
            const canvas = document.createElement('canvas'),
                scaleFactor = 4;
            canvas.width = svgImage.width * scaleFactor;
            canvas.height = svgImage.height * scaleFactor;
            canvas.style.objectFit = 'scale-down';

            const canvasCtx = canvas.getContext('2d') as CanvasRenderingContext2D;
            canvasCtx.scale(scaleFactor, scaleFactor);
            canvasCtx.drawImage(svgImage, 0, 0);

            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            resolve(imgData);
            URL.revokeObjectURL(svgUrl);
        };

        svgImage.src = svgUrl;
    });
};

// TODO: Generalize and create a util to transform snake to camel object keys
export const formatChannelsTableResults = (channelsTableResults: TotalsByChannelResult[]) =>
    channelsTableResults.map((entry: TotalsByChannelResult) => ({
        id: entry.id,
        name: entry.name,
        totals: Object.keys(entry.totals).reduce((acc: any, key: string) => {
            acc[key] = {
                periodPercentage: entry.totals[key as SummariesKeys].period_percentage,
                prevPeriodPercentage: entry.totals[key as SummariesKeys].prev_period_percentage,
                growthPercentage: entry.totals[key as SummariesKeys].growth_percentage,
            };
            return acc;
        }, {}),
    }));

export const formatMainChartResult = (
    result: GMVOrdersResult[],
    key: keyof Pick<Datum, 'gmv' | 'average' | 'orders'>,
) =>
    result.map((entry: GMVOrdersResult) => ({
        value: entry.data.map((data: Datum) => [new Date(data.date).getTime(), data[key]]),
        label: entry.alias,
    }));

export const createGlobalData = ({
    isMonthFilterSelected,
    selectedCompany,
    connectorIds,
    dateEnd,
    dateStart,
    months,
    page,
    pageSize,
    status,
    years,
}: CreateGlobalDataArgs) => {
    if (
        !selectedCompany ||
        (!isMonthFilterSelected && (!dateStart || !dateEnd)) ||
        (isMonthFilterSelected && (!months || !years))
    ) {
        // TODO: Handle error propertly on usage, or refactor util to return empty obj, or any other handy solution
        return null;
    }
    const period = isMonthFilterSelected ? 'monthly' : 'daily',
        obj: GMVDataForRequest = {
            client_id: selectedCompany,
            period,
        };

    if (isMonthFilterSelected) {
        obj.years = years;
        obj.months = months;
    } else {
        obj.date_range_start = dateStart;
        obj.date_range_end = dateEnd;
    }
    if (status) obj.status = status;
    if (page) obj.page = page;
    if (pageSize) obj.page_size = pageSize;
    if (connectorIds) obj.connector_ids = connectorIds;

    return obj;
};

// Check if any of the stores has a missing calculated growth percentage
export const hasStoreWithoutGrowthPercentage = (stores: GMVOrdersResult[]): boolean =>
    stores.some((store) =>
        Object.values(store.summaries).some(
            (value: GMVOrdersAverage) => value.growth_percentage === null,
        ),
    );

export const getTotalByConnectorValue = (tabValue: string, summaries: any, key: string) => {
    const valueFormated = formatAmount(summaries[tabValue][key]);
    if (summaries[tabValue][key] === null || summaries[tabValue][key] === undefined) return '';
    if (tabValue === 'orders') return valueFormated;

    return `$${valueFormated}`;
};
