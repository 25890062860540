import { FC, useContext, useEffect, useState, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { Collapse, Grid, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import SessionContext from 'context/session/sessionContext';
import { addGoogleTag } from 'utils/google-tag-manager';
import { LeavesWomanTwo } from 'assets/SVGs';
import fairplayAPI from 'utils/api';
import {
    DefaultBtn,
    Select,
    FilePicker,
    Alert,
    Typography,
    InterpunctLoader,
    Skeleton,
} from '@fairplay2/ui';
import { ErrorObj } from 'utils/error-handler';
import { contractPrintableName } from 'utils/formatting';
import { validateFileSize } from 'utils/files';
import { useAlert } from 'utils/hooks';
import { MonetizationOn, Publish } from '@mui/icons-material';
import { HydratedContract } from '../../../../contracts/interfaces';
import { Step1Props } from '../../interfaces';
import { useFile } from '../../context/file-context';

const Step1: FC<Step1Props> = ({ contractId, setContractId, onSubmit }) => {
    const { selectedCompany } = useContext(SessionContext),
        history = useHistory(),
        theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        mdUp = useMediaQuery(theme.breakpoints.up('md')),
        [contracts, setContracts] = useState<HydratedContract[] | undefined>(undefined),
        [loading, setLoading] = useState(false),
        [error, setError] = useState(''),
        { alert, hideAlert, showAlert } = useAlert(),
        {
            state: { file },
            dispatch,
        } = useFile();

    const onFileChange = (newFile: any) => {
        hideAlert();
        setError('');
        if (!newFile) return dispatch({ type: 'upload' });

        const fileError = validateFileSize(newFile, 5, 'El tamaño máximo de archivo es 5MB');
        if (fileError) setError(fileError);
        else dispatch({ type: 'upload', payload: newFile });
    };

    const getContracts = async () => {
        const res: any = await fairplayAPI.get(
            `/v2/companies/${selectedCompany.company?.id}/contracts?dispersions_allowed=true&page_size=200`,
        );
        if (res.data.body.count > 0) {
            setContracts(res.data.body.results);
        }
    };

    const onContractChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!contracts) return;

        hideAlert();

        const contract = contracts.find((cont) => cont.id === event.target.value),
            contractType = contract?.productType || 'null';

        if (contractType === 'sales-advancement' || contractType === 'working-capital') {
            setContractId(event.target.value);
        } else {
            setContractId('');
            showAlert(
                'No se puede continuar con la dispersión. El contrato seleccionado no tiene tipo definido, favor de contactar al equipo de Customer Success para poder continuar realizando dispersiones a este contrato.',
            );
        }
    };

    const onCancel = () => {
        addGoogleTag('disbursement-archive-cancel');
        history.push('/app/disbursements/transactions');
    };

    const submitData = () => {
        hideAlert();
        setError('');

        setLoading(true);
        onSubmit(false)
            .catch((errResponse: ErrorObj | any) => {
                if (errResponse?.type === 'form') {
                    if (errResponse?.error?.file) {
                        if (typeof errResponse?.error?.file[0] === 'string') {
                            setError(errResponse?.error?.file[0]);
                        } else {
                            dispatch({ type: 'invalidate', payload: errResponse?.error?.file });
                        }
                    } else if (errResponse?.error?.contract) {
                        if (typeof errResponse?.error?.contract[0] === 'string') {
                            showAlert(errResponse?.error?.contract[0]);
                        } else {
                            dispatch({ type: 'invalidate', payload: errResponse?.error?.contract });
                        }
                    } else {
                        showAlert(
                            (Object.values(errResponse?.error || {})[0] as string) ||
                                'Ha ocurrido un error, intente más tarde.',
                        );
                    }
                } else {
                    showAlert('Ha ocurrido un error, intente más tarde.');
                }
            })
            // This line will throw a warning during integration tests
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        // Wait for the first defined company value, ignore later
        //  updates (contracts are already loaded), index file will
        //  redirect the user so there's no need to fetch contracts again
        if (selectedCompany.company && !contracts) {
            // Get all active contracts
            getContracts();
            setContractId('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany]);

    return (
        <>
            <Grid container spacing={4} marginBottom="25px">
                <Grid item container xs={12} justifyContent="flex-start" mt="64px">
                    <Grid item>
                        <FilePicker
                            accept=".csv"
                            onFileChange={onFileChange}
                            paperVariant="elevation"
                            fileName={file?.name}
                            icon={<Publish />}
                            fileIcon={<MonetizationOn />}
                            AdditionalHelp="De solicitud múltiple"
                            sx={{
                                width: 220,
                                height: 220,
                            }}
                        />
                    </Grid>
                    {mdUp && (
                        <LeavesWomanTwo
                            style={{ zIndex: -1, position: 'relative', top: -17, right: 2 }}
                        />
                    )}
                    {error && (
                        <Grid item xs={12}>
                            <Typography variant="body1" color="error.main">
                                {error}
                            </Typography>
                        </Grid>
                    )}
                </Grid>

                <Grid item xs={12} sm={7} md={4}>
                    {contracts ? (
                        <Select
                            id="massive-disbursement-contract"
                            labelVariant="external"
                            label="Contrato"
                            value={contractId}
                            error={alert.open || undefined}
                            onChange={onContractChange}
                        >
                            <MenuItem value="" selected disabled>
                                Selecciona un contrato activo
                            </MenuItem>
                            {contracts?.length ? (
                                contracts.map((contract) => (
                                    <MenuItem
                                        key={contract.id}
                                        value={contract.id}
                                    >{`Contrato ${contractPrintableName(contract)}`}</MenuItem>
                                ))
                            ) : (
                                <MenuItem value="" disabled>
                                    No hay contratos activos registrados
                                </MenuItem>
                            )}
                        </Select>
                    ) : (
                        <Skeleton height={53} data-testid="contracts-skeleton" />
                    )}
                </Grid>
            </Grid>
            <Grid item container xs={12}>
                <Collapse in={alert.open}>
                    <Grid item xs={12}>
                        <Alert severity={alert.type}>{alert.message}</Alert>
                    </Grid>
                </Collapse>
            </Grid>
            <Grid item container xs={12} mt="25px" mb="20px">
                {loading ? (
                    <InterpunctLoader data-testid="interpunct-loader-upload-step1" />
                ) : (
                    <>
                        <DefaultBtn
                            fullWidth={smDown}
                            size="small"
                            variant="outlined"
                            onClick={onCancel}
                            sx={{
                                mb: { xs: '10px', sm: 0 },
                            }}
                        >
                            Atrás
                        </DefaultBtn>
                        <DefaultBtn
                            fullWidth={smDown}
                            size="small"
                            onClick={submitData}
                            disabled={!file?.name || !contractId}
                            type="submit"
                            sx={{
                                mb: { xs: '20px', sm: 0 },
                                ml: { xs: 0, sm: '70px' },
                            }}
                        >
                            Confirmar
                        </DefaultBtn>
                    </>
                )}
            </Grid>
        </>
    );
};

export default Step1;
