import { Grid } from '@mui/material';
import { IndeterminateCheckBoxOutlined, IndeterminateCheckBox } from '@mui/icons-material';
import { Typography } from '@fairplay2/ui';
import { TextColorDescriptionProps } from './TextColorDescription.types';

export const TextColorDescription = ({ descriptions }: TextColorDescriptionProps) => (
    <Grid container item alignItems="center" gap={2}>
        {descriptions.map(({ text, color, variant }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid key={index} item container alignItems="center" gap={1} width="fit-content">
                <Grid item pt="3px">
                    {variant === 'filled' ? (
                        <IndeterminateCheckBox fontSize="small" sx={{ color }} />
                    ) : (
                        <IndeterminateCheckBoxOutlined fontSize="small" sx={{ color }} />
                    )}
                </Grid>
                <Grid item>
                    <Typography color="text.primary" variant="body2">
                        {text}
                    </Typography>
                </Grid>
            </Grid>
        ))}
    </Grid>
);
