import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Collapse, DialogTitle, Grid } from '@mui/material';
import { ManParagraphLeavesSvg } from 'assets/SVGs';
import { Alert, DefaultBtn, InterpunctLoader, Typography } from '@fairplay2/ui';
import { ConfirmCancelDisbursementProps } from '../../interfaces';

const ConfirmCancelDisbursement: FC<ConfirmCancelDisbursementProps> = ({
    disbursementStep,
    onConfirm,
    loading,
    alert,
}) => {
    const history = useHistory();

    return (
        <Grid container>
            <Grid container item xs={12} justifyContent="center">
                <ManParagraphLeavesSvg />
            </Grid>
            <Grid item xs={12} mt="20px">
                <Typography
                    component={DialogTitle}
                    variant="subtitle1"
                    color="primary.main"
                    padding={0}
                >
                    ¿Estás seguro de cancelar la solicitud?
                </Typography>
            </Grid>
            <Grid item xs={12} mt="15px">
                <Typography color="text.primary" fontWeight={700}>
                    ¡Se perderá la información!
                </Typography>
                <Typography color="text.primary" fontWeight={500} mt="20px">
                    Si cancelas la dispersión, los datos que ya hayas ingresado se perderán y
                    deberás comenzar una nueva solicitud.
                </Typography>
            </Grid>
            <Grid container justifyContent="center">
                <Collapse in={alert.open}>
                    <Alert severity={alert.type} sx={{ mt: 3 }}>
                        {alert.message}
                    </Alert>
                </Collapse>
            </Grid>
            <Grid container item xs={12} justifyContent="center" mt="20px">
                {loading ? (
                    <Grid item sx={{ mx: '10px', mt: '15px' }}>
                        <InterpunctLoader />
                    </Grid>
                ) : (
                    <>
                        {disbursementStep > 0 && (
                            <DefaultBtn
                                size="small"
                                variant="outlined"
                                onClick={() => history.push('/app/disbursements/transactions')}
                                sx={{ mx: '10px', mt: '10px' }}
                            >
                                Terminar después
                            </DefaultBtn>
                        )}
                        <DefaultBtn
                            size="small"
                            onClick={onConfirm}
                            sx={{ mx: '10px', mt: '10px' }}
                        >
                            Sí, cancelar
                        </DefaultBtn>
                    </>
                )}
            </Grid>
        </Grid>
    );
};
export default ConfirmCancelDisbursement;
