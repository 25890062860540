import { FC, useEffect, useRef, MutableRefObject } from 'react';
import { Grid, Collapse, Alert } from '@mui/material';
import { useDisbursementValuesContext } from 'context/disbursements/DisbursementValuesContext';
import DisbursementHeader from './components/DisbursementHeader';
import DisbursementInfo from './components/DisbursementInfo';
import DisbursementConfiguration from './components/DisbursementConfiguration';
import DisbursementsContainer from './components/DisbursementsContainer';
import DisbursementConfirmationTable from './components/DisbursementConfirmationTable';

const CreateDisbursement: FC = () => {
    const { disbursementStep, disbursementAlert, setDisbursementAlert, resetDisbursementValues } =
            useDisbursementValuesContext(),
        isCartCreated: MutableRefObject<boolean> = useRef(false);

    isCartCreated.current = !!disbursementStep;

    useEffect(() => {
        setDisbursementAlert({
            msg: '',
            severity: 'success',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disbursementStep]);

    useEffect(
        () => () => {
            if (!isCartCreated.current) resetDisbursementValues();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <Grid container columnSpacing={4} data-testid="create-disbursement-v2">
            <DisbursementHeader />
            <Grid item container>
                <DisbursementInfo />
            </Grid>

            <Grid item container xs={12} justifyContent="flex-end">
                <Collapse in={!!disbursementAlert.msg} sx={{ maxWidth: { xs: '100%', md: '60%' } }}>
                    <Alert aria-label="Mensaje de alerta" severity={disbursementAlert.severity}>
                        {disbursementAlert.msg}
                    </Alert>
                </Collapse>
            </Grid>

            <Grid item xs={12} mb="20px">
                {disbursementStep === 0 && <DisbursementConfiguration />}
                {disbursementStep === 1 && <DisbursementsContainer />}
                {disbursementStep === 2 && <DisbursementConfirmationTable />}
            </Grid>
        </Grid>
    );
};

export default CreateDisbursement;
