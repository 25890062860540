import { FC, useState, useContext, useEffect } from 'react';
import { Grid, Collapse } from '@mui/material';
import { Alert, DefaultBtn, InterpunctLoader, Modal, Typography } from '@fairplay2/ui';
import { ManSittingPlanSvg } from 'assets/SVGs';
import SessionContext from 'context/session/sessionContext';
import fairplayAPI from 'utils/api';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { useAlert } from 'utils/hooks';
import { toFormData } from 'utils/parsing';
import { IndustryConfirmationProps } from '../interfaces';

interface ModalActionsProps extends Pick<IndustryConfirmationProps, 'onReturn' | 'open'> {
    onSubmit(): Promise<void>;
    loading: boolean;
}

const ModalActions: FC<ModalActionsProps> = ({ loading, onSubmit, onReturn, open }) => (
    <Grid
        container
        width="100%"
        justifyContent="center"
        columnSpacing={[0, 5.125]}
        rowSpacing={[1.5, 0]}
    >
        {loading ? (
            <Grid item>
                <InterpunctLoader />
            </Grid>
        ) : (
            <>
                <Grid item xs={12} sm="auto">
                    <DefaultBtn
                        disabled={!open}
                        size="small"
                        variant="outlined"
                        onClick={onReturn}
                        sx={{ width: { xs: '100%', sm: 'auto' } }}
                    >
                        Editar industria
                    </DefaultBtn>
                </Grid>
                <Grid item xs={12} sm="auto">
                    <DefaultBtn
                        disabled={!open}
                        size="small"
                        onClick={onSubmit}
                        sx={{ width: { xs: '100%', sm: 'auto' } }}
                    >
                        Confirmar
                    </DefaultBtn>
                </Grid>
            </>
        )}
    </Grid>
);

const modalSx = {
    '.MuiDialog-paper': {
        padding: {
            xs: 2,
            sm: '31.27px 45px 53px',
        },
        width: '100%',
    },
    '.MuiDialogContent-root': {
        padding: 1,
        paddingBottom: 3,
    },
};

const IndustryConfirmation: FC<IndustryConfirmationProps> = ({
    open,
    onClose,
    industry,
    onReturn,
    onSuccess,
    transitionDuration,
}) => {
    const { selectedCompany, updateCompany } = useContext(SessionContext),
        [loading, setLoading] = useState(false),
        { alert, hideAlert, showAlert } = useAlert();

    const onSubmit = async () => {
        if (!industry?.id) {
            showAlert('La industria seleccionada no es válida, por favor selecciona otra.');
            return;
        }

        setLoading(true);
        hideAlert();

        try {
            const res: any = await fairplayAPI.patch(
                `/v1/companies/${selectedCompany.company?.id}`,
                toFormData({ industry: industry.id }),
            );

            onClose?.();
            onSuccess?.();
            await updateCompany(
                {
                    ...selectedCompany,
                    company: res.data.body.results,
                },
                true,
            );
        } catch (errResponse: ErrorObj | any) {
            showAlert(
                errResponse?.error || 'Ocurrió un error al guardar la industria seleccionada.',
            );
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!open) hideAlert();
    }, [hideAlert, open]);

    return (
        <Modal
            transitionDuration={transitionDuration}
            open={open}
            onClose={loading ? undefined : onClose}
            closeIconButton={!loading}
            maxWidth={571}
            actions={
                <ModalActions
                    open={open}
                    loading={loading}
                    onReturn={onReturn}
                    onSubmit={onSubmit}
                />
            }
            sx={modalSx}
        >
            <Grid container direction="column" alignItems="center">
                <ManSittingPlanSvg width={152} height={120} />
                <Typography
                    color="primary.main"
                    variant="subtitle2"
                    width="100%"
                    mt="40px"
                    mb="18px"
                    lineHeight={1.125}
                    fontWeight={500}
                >
                    ¡Hey! ¿Es la industria correcta?
                </Typography>
                <Typography
                    component="p"
                    variant="subtitle2"
                    color="text.primary"
                    lineHeight={1.375}
                >
                    Estás a punto de establecer la industria de tu empresa como{' '}
                    <Typography color="primary.dark" fontWeight={700} component="span">
                        {industry?.description || ''}
                    </Typography>
                    .
                    <br /> Recuerda que una vez definido no podrás editarlo.
                </Typography>
                <Grid item width="100%">
                    <Collapse in={alert.open}>
                        <Alert severity={alert.type} sx={{ mt: 3 }}>
                            {alert.message}
                        </Alert>
                    </Collapse>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default IndustryConfirmation;
