import { FC, useContext, useState } from 'react';
import { Grid, Duration, useTheme } from '@mui/material';
import { DefaultInput, Typography, Skeleton } from '@fairplay2/ui';
import IndustriesModal from 'components/dashboard/vendors/add-vendor/components/IndustriesModal';
import { IndustryAsProp } from 'components/dashboard/vendors/add-vendor/interfaces';
import SessionContext from 'context/session/sessionContext';
import { EditIndustryProps } from '../interfaces';
import IndustryConfirmation from './IndustryConfirmation';

const getCancellingTransitions = (themeDurations: Duration) => ({
    industries: {
        enter: themeDurations.enteringScreen,
        exit: themeDurations.leavingScreen,
    },
    confirmation: {
        enter: themeDurations.enteringScreen,
        exit: themeDurations.leavingScreen,
    },
});

const returningTransitions = {
    industries: {
        enter: 0,
        exit: 0,
    },
    confirmation: {
        enter: 0,
        exit: 0,
    },
};

const getAdvancingTransitions = (themeDurations: Duration) => ({
    industries: {
        enter: themeDurations.enteringScreen,
        exit: 0,
    },
    confirmation: {
        enter: 0,
        exit: themeDurations.leavingScreen,
    },
});

const EditIndustry: FC<EditIndustryProps> = ({ onAlert, ...props }) => {
    const {
            transitions: { duration: themeDurations },
        } = useTheme(),
        { selectedCompany } = useContext(SessionContext),
        [showModal, setShowModal] = useState(false as false | 'industries' | 'confirmation'),
        [targetIndustry, setTargetIndustry] = useState<IndustryAsProp>(null),
        [transitionDurations, setTransitionDurations] = useState(
            getAdvancingTransitions(themeDurations),
        ),
        currentIndustry = selectedCompany.company ? selectedCompany.company?.industry : undefined;

    const alternateModal = (newValue: typeof showModal) => {
        if (newValue === false) setTransitionDurations(getCancellingTransitions(themeDurations));
        else if (showModal === 'confirmation' && newValue === 'industries')
            setTransitionDurations(returningTransitions);
        else setTransitionDurations(getAdvancingTransitions(themeDurations));
        if (showModal === false) setTargetIndustry(null);
        setShowModal(newValue);
    };

    const onSuccess = () => {
        onAlert('La industria se estableció correctamente', 'success');
    };

    return (
        <Grid item {...props}>
            <Typography component="label" variant="label" htmlFor="companyIndustryName">
                Industria:
            </Typography>
            {currentIndustry === undefined ? (
                <Skeleton height={48} />
            ) : (
                <DefaultInput
                    id="companyIndustryName"
                    name="companyIndustryName"
                    labelVariant="none"
                    type="text"
                    placeholder="Por favor selecciona la industria de tu empresa"
                    value={currentIndustry?.description || ''}
                    required
                    disabled={!!currentIndustry}
                    onClick={currentIndustry ? undefined : () => alternateModal('industries')}
                />
            )}
            {currentIndustry === null && (
                <>
                    <IndustriesModal
                        transitionDuration={transitionDurations.industries}
                        currentSelection={targetIndustry}
                        isModalOpen={showModal === 'industries'}
                        onIndustrySelect={(e, industry) => setTargetIndustry(industry)}
                        onModalClose={() => alternateModal(false)}
                        onConfirm={() => alternateModal('confirmation')}
                        source="company"
                    />
                    <IndustryConfirmation
                        transitionDuration={transitionDurations.confirmation}
                        open={showModal === 'confirmation'}
                        industry={targetIndustry}
                        onClose={() => {
                            alternateModal(false);
                        }}
                        onReturn={() => alternateModal('industries')}
                        onSuccess={onSuccess}
                    />
                </>
            )}
        </Grid>
    );
};

export default EditIndustry;
