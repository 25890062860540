import { useState, useContext, FC, useEffect } from 'react';
import { Grid, Collapse, useMediaQuery, useTheme } from '@mui/material';
import { Alert, Typography, ProfileData } from '@fairplay2/ui';
import sessionContext from 'context/session/sessionContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import fairplayAPI from 'utils/api';
import { validateValueDoesNotExist } from 'utils/validation';
import { useAlert } from 'utils/hooks';
import { toFormData } from 'utils/parsing';
import { capitalize } from 'utils/formatting';
import { UserCardSvg, UserCard } from './styles';
import EditData from './components/EditData';

const User: FC = () => {
    const { user, updateUser, selectedCompany } = useContext(sessionContext),
        [loading, setLoading] = useState(false),
        { alert, hideAlert, showAlert } = useAlert(),
        mdUp = useMediaQuery(useTheme().breakpoints.up('md')),
        userDoesNotExist = validateValueDoesNotExist(user);

    const onChangeImage = async (event: any) => {
        setLoading(true);
        hideAlert();

        try {
            const res: any = await fairplayAPI.patch(
                `/v1/users/update`,
                toFormData({ avatar: event.target.files[0] }),
            );
            updateUser(res.data.body.results);
            setLoading(false);
        } catch (errResponse: ErrorObj | any) {
            setLoading(false);
            if (errResponse.type === 'form')
                showAlert(errResponse.error.avatar || 'No pudimos actualizar la imagen');
            else showAlert(errResponse.error || 'No pudimos actualizar la imagen');
        }
    };

    useEffect(() => {
        setLoading(userDoesNotExist);

        if (userDoesNotExist) {
            setTimeout(() => {
                setLoading(false);
            }, 1500);
        }
    }, [userDoesNotExist]);

    return (
        <Grid container paddingRight={{ sm: 2 }}>
            <Typography variant="h4" color="primary.main" mb="28px" mt="26px">
                Datos{' '}
                <Typography component="span" color="primary.dark" fontWeight={700}>
                    personales
                </Typography>
            </Typography>

            <Grid container spacing={4} marginBottom="24px">
                {loading || !userDoesNotExist ? (
                    <Grid item xs={12} md={9} lg={7} position="relative">
                        <UserCard variant="elevation">
                            <ProfileData
                                name={
                                    loading
                                        ? undefined
                                        : `${user?.firstName || ''} ${user?.lastName || ''}`
                                }
                                legend={
                                    loading
                                        ? undefined
                                        : selectedCompany.role && capitalize(selectedCompany.role)
                                }
                                src={loading ? undefined : user?.avatar || ''}
                                avatarAlt="Foto de perfil"
                                skeletonsTestidPrefix="user"
                                AvatarInputProps={{
                                    onChange: onChangeImage,
                                }}
                                size="lg"
                                overrideAvatarSize={110}
                                GridProps={{
                                    columnGap: 1,
                                    sx: {
                                        '.MuiTypography-root': {
                                            lineHeight: '1.0526 !important',
                                        },
                                    },
                                }}
                            />
                        </UserCard>
                        {mdUp && <UserCardSvg />}
                    </Grid>
                ) : null}
            </Grid>

            <Collapse in={alert.open}>
                <Grid container item xs={12}>
                    <Alert severity={alert.type}>{alert.message}</Alert>
                </Grid>
            </Collapse>
            <EditData />
        </Grid>
    );
};

export default User;
