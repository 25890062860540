import { FC } from 'react';
import { NewCardProps } from '../../interfaces';
import NewCardSkeleton from './NewCardSkeleton';
import NewCardForm from './NewCardForm';

export const NewCard: FC<NewCardProps> = ({
    loading,
    onComplete,
    showAlert,
    selectedContractId,
    maxForDisposition,
}) =>
    loading ? (
        <NewCardSkeleton />
    ) : (
        <NewCardForm
            onComplete={onComplete}
            showAlert={showAlert}
            selectedContractId={selectedContractId}
            maxForDisposition={maxForDisposition}
        />
    );

export default NewCard;
