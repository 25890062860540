import { FC, useState, ChangeEvent, useEffect, useContext, useRef } from 'react';
import { Grid } from '@mui/material';
import { Typography, TabGroup, DefaultBtn } from '@fairplay2/ui';
import { ControlPoint } from '@mui/icons-material';
import { useDisbursementValuesContext } from 'context/disbursements/DisbursementValuesContext';
import SessionContext from 'context/session/sessionContext';
import fairplayAPI from 'utils/api';
import { hasAnyTruthyProperty, isNotEmptyError } from 'utils/validation';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { getFormStatus, getFormData, onPresignedUrlUpload } from '../utils';
import { SINGLE_DISBURSEMENT_ERRORS } from '../constants';
import { Form, Errors } from '../interfaces';
import DeleteItemModal from './modals/DeleteItemModal';
import ConfirmChangesModal from './modals/ConfirmChangesModal';
import SingleDisbursement from './SingleDisbursement';
import DisbursementForm from './DisbursementForm';

const AddDisbursement = ({ onClick, isDisabled }: any) => (
    <DefaultBtn
        variant="text"
        onClick={onClick}
        startIcon={<ControlPoint />}
        sx={{
            minWidth: '175px',
            fontWeight: 500,
            textTransform: 'none !important',
            textDecoration: 'none !important',
        }}
        disabled={isDisabled}
    >
        Agregar otra
    </DefaultBtn>
);

const DisbursementsContainer: FC = () => {
    const { user } = useContext(SessionContext),
        {
            cartData,
            setCartData,
            addDisbToCart,
            setDisbursementAlert,
            selectedContract,
            setDisbursementStep,
            formCopy,
            setFormCopy,
            setSuppliersCount,
            formsStep,
            setFormsStep,
            totalRequestedAmountSum,
        } = useDisbursementValuesContext(),
        [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false),
        [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false),
        [targetForm, setTargetForm] = useState<number>(0),
        [loading, setLoading] = useState(false),
        [errors, setErrors] = useState<Errors>(SINGLE_DISBURSEMENT_ERRORS),
        prevCartLength = useRef(0),
        isFormSubmitted = !!cartData[formsStep]?.id_dispersion;

    const onFormStepChange = (newFormsStep?: number) => {
        const isLastForm = cartData.length - 1 === formsStep,
            isNextFormTarget = (newFormsStep || targetForm) > formsStep;

        if (isLastForm && isNextFormTarget) return setDisbursementStep('forward');
        if (newFormsStep === 0) return setFormsStep(0);
        setFormsStep(newFormsStep || targetForm);
    };

    const isFormUpdated = (
        newFormsStep: number,
        previousCartData: string,
        updatedCartData: string,
    ) => {
        if (isFormSubmitted && previousCartData !== updatedCartData) {
            setTargetForm(newFormsStep);
            setIsUpdateModalOpen(true);
            return;
        }
        if (!isNotEmptyError(errors.amount)) onFormStepChange(newFormsStep);
    };

    const isFormValid = () => {
        const formStatus = getFormStatus(
            selectedContract.productType,
            cartData[formsStep],
            !!errors.amount,
        );

        if (!formStatus.valid) {
            setErrors((prev: Errors) => ({
                ...prev,
                ...formStatus.errors,
            }));
            setDisbursementAlert({
                msg: isNotEmptyError(errors.amount)
                    ? errors.amount
                    : 'Hay campos pendientes de completar, revisa el formulario',
                severity: 'error',
            });
            return false;
        }
        return true;
    };

    const onFormSubmit = async (newFormsStep: number) => {
        setLoading(true);
        try {
            const data = getFormData(
                { ...cartData[formsStep], totalRequestedAmountSum },
                selectedContract.productType,
            );

            const res: any = await fairplayAPI.post(`/v1/cart/${user?.id}/items`, data, {
                    useIdempotency: true,
                    baseService: 'DISBURSEMENT_CART',
                    headers: {
                        'Ignore-Duplicity': 'true',
                    },
                }),
                disbursementId = res.data.results.id_item,
                newCartData = [...cartData];

            newCartData[formsStep] = {
                ...cartData[formsStep],
                id_dispersion: disbursementId,
                disabled: false,
            };

            setCartData(newCartData);

            const presignedUrl = res.data.results.presigned_url;
            const { file } = cartData[formsStep];
            await onPresignedUrlUpload(presignedUrl, file, () => {
                const fallbackCartData = [...newCartData];

                fallbackCartData[formsStep].file = null;
                fallbackCartData[formsStep].fileName = '';

                setCartData(fallbackCartData);
            });

            if (!isNotEmptyError(errors.amount)) onFormStepChange(newFormsStep);
        } catch (errResponse: ErrorObj | any) {
            setDisbursementAlert({
                msg:
                    errResponse?.error ||
                    'Ocurrió un error enviando la información, intente más tarde',
                severity: 'error',
            });
            return;
        } finally {
            setLoading(false);
        }
    };

    const onTabChange = (_: ChangeEvent<{}> | any, newFormsStep: number) => {
        if (newFormsStep > formsStep) {
            if (!isFormValid()) return;
            if (!isFormSubmitted) return onFormSubmit(newFormsStep);
        }

        isFormUpdated(newFormsStep, JSON.stringify(formCopy), JSON.stringify(cartData[formsStep]));
    };

    useEffect(() => {
        if (isFormSubmitted) {
            // When changing into a different form OR when resuming a cart with backend data
            if (
                formCopy.label !== cartData[formsStep].label ||
                (formCopy.disabled && formCopy.label === cartData[formsStep].label)
            )
                setFormCopy({ ...cartData[formsStep] });

            // When resuming a cart with context data
            if (!formCopy.disabled && formCopy.label === cartData[formsStep].label)
                setFormCopy(formCopy);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formsStep]);

    useEffect(() => {
        setSuppliersCount(cartData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {isRemoveModalOpen && (
                <DeleteItemModal
                    open={isRemoveModalOpen}
                    onClose={() => setIsRemoveModalOpen(false)}
                    isFormSubmitted={isFormSubmitted}
                    setFormsStep={setFormsStep}
                    index={formsStep}
                />
            )}
            {isUpdateModalOpen && (
                <ConfirmChangesModal
                    open={isUpdateModalOpen}
                    onClose={() => setIsUpdateModalOpen(false)}
                    onFormStepChange={onFormStepChange}
                    index={formsStep}
                    errors={errors}
                />
            )}
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <Typography fontWeight={700} color="primary.dark">
                        Completa los datos de la transferencia
                    </Typography>
                </Grid>
                <TabGroup
                    value={formsStep}
                    tabs={cartData}
                    indicatorStyles={{ expand: true, height: 3 }}
                    onChange={onTabChange}
                    hideLabels={false}
                    AfterTabsSlot={
                        <AddDisbursement
                            onClick={addDisbToCart}
                            isDisabled={hasAnyTruthyProperty(errors)}
                        />
                    }
                    sx={{
                        '.MuiTabs-root': { margin: '20px 0 0 -20px' },
                        '.MuiTab-root.Mui-selected': { color: 'primary.A400' },
                        '.FpTabPanel-root': {
                            borderWidth: '3px 0 0',
                            borderColor: 'background.dark',
                            borderStyle: 'solid',
                            marginTop: '-3px',
                        },
                    }}
                >
                    {cartData?.map((singleForm: Form, index: number) => (
                        <SingleDisbursement
                            index={index}
                            openDeleteModal={() => setIsRemoveModalOpen(true)}
                            onConfirm={() => onTabChange(null, index + 1)}
                            loading={loading}
                            key={singleForm.label}
                        >
                            <DisbursementForm
                                index={index}
                                errors={errors}
                                setErrors={setErrors}
                                prevCartLength={prevCartLength}
                            />
                        </SingleDisbursement>
                    ))}
                </TabGroup>
            </Grid>
        </>
    );
};

export default DisbursementsContainer;
