import { FC, Fragment, useContext, useReducer } from 'react';
import { Grid } from '@mui/material';
import { Typography } from '@fairplay2/ui';
import sessionContext from 'context/session/sessionContext';
import { fetchData } from '../utils/fetchData';
import { RequestsDictionary } from '../interfaces';
import { TRADING_WARNING_C } from '../utils/constants';
import { CardKpiSkeleton } from '../styles';
import Filters from './components/Filters';
import CardKpi from './components/CardKpi';
import { KPIsReducer, KPIsInitialState } from './kpisReducer';
import { Dates, KPIsAction } from './interfaces';

const ERROR_VALUE = {
    current: 0,
    last: 0,
    percentage: { value: 0, amount: 0 },
    warningC: TRADING_WARNING_C.noOrders,
    warningL: TRADING_WARNING_C.noOrders,
};

const REQUESTS_DIC: RequestsDictionary<KPIsAction['type']> = {
    adSpend: {
        actionType: 'SET_ADSPEND_CARD',
        getUrl: (uuid: string) => `/v1/reports/kpis/ad-spend/${uuid}`,
        errorValue: ERROR_VALUE,
    },
    impressions: {
        actionType: 'SET_IMPRESSIONS_CARD',
        getUrl: (uuid: string) => `/v1/reports/kpis/impressions/${uuid}`,
        errorValue: ERROR_VALUE,
    },
    clicks: {
        actionType: 'SET_CLICKS_CARD',
        getUrl: (uuid: string) => `/v1/reports/kpis/clicks/${uuid}`,
        errorValue: ERROR_VALUE,
    },
    sessions: {
        actionType: 'SET_SESSIONS_CARD',
        getUrl: (uuid: string) => `/v1/reports/kpis/sessions/${uuid}`,
        errorValue: ERROR_VALUE,
    },
    orders: {
        actionType: 'SET_ORDERS_CARD',
        getUrl: (uuid: string) => `/v1/reports/kpis/orders/${uuid}`,
        errorValue: ERROR_VALUE,
    },
    gvm: {
        actionType: 'SET_GVM_CARD',
        getUrl: (uuid: string) => `/v1/reports/kpis/gmv/${uuid}`,
        errorValue: ERROR_VALUE,
    },
    ticket: {
        actionType: 'SET_TICKET_CARD',
        getUrl: (uuid: string) => `/v1/reports/kpis/average-ticket/${uuid}`,
        errorValue: ERROR_VALUE,
    },
    roas: {
        actionType: 'SET_ROAS_CARD',
        getUrl: (uuid: string) => `/v1/reports/kpis/roas/${uuid}`,
        errorValue: ERROR_VALUE,
    },
    cost: {
        actionType: 'SET_COST_CARD',
        getUrl: (uuid: string) => `/v1/reports/kpis/cost-per-convertion/${uuid}`,
        errorValue: ERROR_VALUE,
    },
    rate: {
        actionType: 'SET_RATE_CARD',
        getUrl: (uuid: string) => `/v1/reports/kpis/convertion-rate/${uuid}`,
        errorValue: ERROR_VALUE,
    },
};

const KPIs: FC = () => {
    const { selectedCompany } = useContext(sessionContext),
        [{ metrics }, dispatch] = useReducer(KPIsReducer, KPIsInitialState);

    const getData = (uuid: string, complexRange: Dates) => {
        fetchData<KPIsAction['type']>({
            companyId: uuid,
            dispatch,
            requestsDic: REQUESTS_DIC,
            range: complexRange,
        });
    };

    const applyFilters = (values: Dates) => {
        if (!selectedCompany.company?.id) return;
        dispatch({ type: 'RESTART_LOADING' });
        getData(selectedCompany.company?.id, values);
    };

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography variant="h4" color="primary.main" gutterBottom>
                        Analíticos /{' '}
                        <Typography component="span" color="primary.dark" fontWeight={700}>
                            KPIs
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>

            <Filters applyFilters={applyFilters} forceApplyOn={selectedCompany.company?.id} />

            <Grid container spacing={4}>
                {metrics &&
                    Object.entries(metrics).map(
                        ([key, { item, title, loading, symbol, perc }]: any) => (
                            <Fragment key={key}>
                                {loading ? (
                                    <Grid item xs={12} sm={6} md={4} key={key}>
                                        <CardKpiSkeleton variant="rectangular" />
                                    </Grid>
                                ) : (
                                    <CardKpi
                                        item={item}
                                        title={title}
                                        symbol={symbol}
                                        perc={perc}
                                    />
                                )}
                            </Fragment>
                        ),
                    )}
            </Grid>
        </>
    );
};

export default KPIs;
