import { toTimelessISO } from 'utils/dates';
import { HydratedContract } from '../contracts/interfaces';
import { ContractPeriod, ContractMetadata } from './transactions/interfaces';

export const getStorageContract = () => {
    const savedContract = sessionStorage.getItem('selectedContract');

    if (!savedContract) return {};

    try {
        return JSON.parse(savedContract);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error parsing selectedContract from sessionStorage', error);
        return {};
    }
};

export const addAllowDisbFlag = (
    contracts: HydratedContract[],
    disbAllowedContracts: HydratedContract[],
) =>
    contracts.map((contractToUpdate) => {
        const disbursementAllowed = disbAllowedContracts.some(
            (allowedContract) => allowedContract.id === contractToUpdate.id,
        );

        return { ...contractToUpdate, disbursementAllowed };
    });

export const formatPeriodsData = (periodsRes: ContractPeriod[]) => {
    const todayDate = toTimelessISO();

    const currentPeriodIndex = periodsRes.findIndex(
        ({ end_date, start_date }: ContractPeriod) =>
            todayDate >= start_date && todayDate <= end_date,
    );

    if (currentPeriodIndex < 0) {
        return {
            currentPeriod: null,
            nextPeriod: null,
        };
    }

    const periodsDatesData = periodsRes[currentPeriodIndex],
        nextPeriodData = periodsRes[currentPeriodIndex + 1] || null;

    return {
        currentPeriod: periodsDatesData,
        nextPeriod: nextPeriodData,
    };
};

export const canContractDisburse = (
    selectedContract: HydratedContract | undefined,
    contractInfo: ContractMetadata | null | undefined,
    moduleName: 'disbursements' | 'cards',
) => {
    // Validation necessary to avoid use chainging operator in next conditions
    // and document and handle empty contract edge case
    if (selectedContract === undefined) return { canDisburse: false, alertMessage: '' };

    if (selectedContract.status?.id === 'suspended')
        return {
            canDisburse: false,
            alertMessage: `Contrato suspendido. ${
                moduleName === 'disbursements'
                    ? 'No es posible solicitar dispersiones.'
                    : 'Ponte en contacto con nosotros.'
            }`,
        };

    if (!selectedContract.disbursementAllowed)
        return {
            canDisburse: false,
            alertMessage: 'No es posible solicitar dispersiones con este contrato.',
        };

    // This is necessary to not block the disbursement when disbursementAllowed is true
    // and enableCardsModule is false in disbursements module
    if (moduleName === 'cards' && !selectedContract.enableCardsModule)
        return {
            canDisburse: false,
            // when enableCardsModule is false the contract is not displayed in the cards picker
            alertMessage: undefined,
        };

    if (contractInfo === null) return { canDisburse: false, alertMessage: '' };

    if (!contractInfo?.start_date || !contractInfo?.end_date)
        return {
            canDisburse: false,
            alertMessage: 'Este contrato aún no cuenta con períodos.',
        };

    return {
        canDisburse: true,
        alertMessage: undefined,
    };
};

export const getContractInfo = (
    contract: HydratedContract,
    periodsData: Record<string, any> | null,
    availableData: Record<string, any> | null,
) => ({
    contract_id: contract.id,
    start_date: periodsData?.start_date || null,
    end_date: periodsData?.end_date || null,
    currentPeriod: availableData?.period || null,
    exchange_rates: availableData?.exchange_rates || null,
    global_available: availableData?.global_available ?? null,
    global_total_spend: availableData?.global_total_spend ?? null,
    period_total_available: availableData?.period_total_available ?? null,
    total_period_spend: availableData?.total_period_spend ?? null,
});
