import { FC, useContext, useState, ChangeEvent } from 'react';
import {
    useTheme,
    useMediaQuery,
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Collapse,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import {
    Checkbox,
    Modal,
    InterpunctLoader,
    DefaultBtn,
    Alert,
    NoResults,
    Typography,
    Tooltip,
    IconBtn,
} from '@fairplay2/ui';
import SessionContext from 'context/session/sessionContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import fairplayAPI from 'utils/api';
import { useAlert } from 'utils/hooks';
import { toFormData } from 'utils/parsing';
import { ManageAccountsModalProps } from '../interfaces';

const ManageAccountsModal: FC<ManageAccountsModalProps> = ({
    open,
    accounts,
    selectedAccount,
    onClose,
    onSelectAccount,
    onDelete,
    fetchAccounts,
}) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        { selectedCompany } = useContext(SessionContext),
        [loading, setLoading] = useState(false),
        { alert, showAlert } = useAlert();

    const onSaveAccount = async () => {
        if (selectedAccount !== null) {
            setLoading(true);
            try {
                await fairplayAPI.patch(
                    `/v1/companies/${selectedCompany.company?.id}/accounts/${accounts[selectedAccount].id}`,
                    toFormData({
                        status: 'default',
                    }),
                );
                setLoading(false);
                fetchAccounts();
                showAlert('Las cuentas han sido actualizadas exitosamente', 'success');
            } catch (errResponse: ErrorObj | any) {
                setLoading(false);
                if (errResponse.type === 'form') {
                    const errors = Object.values(errResponse.error);
                    showAlert(errors[0] as string);
                } else showAlert(errResponse?.error);
            }
        }
    };

    return (
        <Modal
            title="Administra tus cuentas bancarias"
            open={open}
            onClose={() => onClose('manageAccounts')}
            maxWidth="lg"
            sx={{ '.MuiDialogContent-root': { paddingTop: 0 } }}
        >
            <Grid container justifyContent="center">
                <TableContainer>
                    <Table>
                        <TableHead sx={{ opacity: accounts.length > 0 ? '1' : '0.5' }}>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Typography
                                        variant="body1"
                                        color="text.primary"
                                        fontWeight={500}
                                    >
                                        Principal
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant="body1"
                                        color="text.primary"
                                        fontWeight={500}
                                    >
                                        Alias
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant="body1"
                                        color="text.primary"
                                        fontWeight={500}
                                    >
                                        Banco
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant="body1"
                                        color="text.primary"
                                        fontWeight={500}
                                    >
                                        CLABE
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography
                                        variant="body1"
                                        color="text.primary"
                                        fontWeight={500}
                                    >
                                        Acciones
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {accounts.map((account, i) => (
                                <TableRow key={account.id} tabIndex={-1}>
                                    <TableCell padding="checkbox" align="center">
                                        <Checkbox
                                            checked={selectedAccount === i}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                                onSelectAccount(event, i)
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            variant="body1"
                                            color="primary.main"
                                            fontWeight={700}
                                        >
                                            {account.accountHolder}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            variant="body1"
                                            color="primary.dark"
                                            fontWeight={500}
                                        >
                                            {account.bank}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            variant="body1"
                                            color="primary.dark"
                                            fontWeight={500}
                                        >
                                            {account.clabe}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Tooltip
                                            title="Eliminar"
                                            placement="right-start"
                                            distance={0}
                                        >
                                            <IconBtn
                                                variant="outlined"
                                                color="secondary"
                                                onClick={() => onDelete(account)}
                                                aria-label={`Eliminar cuenta bancaria ${i}`}
                                            >
                                                <Delete />
                                            </IconBtn>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {accounts.length <= 0 && (
                        <NoResults>No hemos encontrado cuentas bancarias</NoResults>
                    )}
                </TableContainer>
                <Grid container justifyContent="flex-end" mt="20px">
                    <Collapse in={alert.open}>
                        <Alert severity={alert.type}>{alert.message}</Alert>
                    </Collapse>
                </Grid>
                <Grid item container xs={12} justifyContent="flex-end" mt="20px">
                    {loading ? (
                        <InterpunctLoader height={58} />
                    ) : (
                        <>
                            <DefaultBtn
                                size="small"
                                fullWidth={smDown}
                                sx={{
                                    mr: { xs: 0, sm: '20px' },
                                }}
                                variant="outlined"
                                onClick={() => onClose('manageAccounts')}
                            >
                                Cancelar
                            </DefaultBtn>
                            <DefaultBtn
                                size="small"
                                fullWidth={smDown}
                                sx={{
                                    mt: { xs: '20px', sm: 0 },
                                }}
                                onClick={onSaveAccount}
                            >
                                Guardar
                            </DefaultBtn>
                        </>
                    )}
                </Grid>
            </Grid>
        </Modal>
    );
};

export default ManageAccountsModal;
