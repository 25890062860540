import { FC, useEffect, useReducer, useContext } from 'react';
import sessionContext from 'context/session/sessionContext';
import { Grid } from '@mui/material';
import { TrendsInitialState, TrendsReducer } from '../trendsReducer';
import { RequestsDictionary } from '../../interfaces';
import ChartWithFallback from '../../components/ChartWithFallback';
import { TRADING_WARNING_C } from '../../utils/constants';
import { fetchData } from '../../utils/fetchData';
import { TrendsAction } from '../interfaces';

const REQUESTS_DIC: RequestsDictionary<TrendsAction['type']> = {
    distWeekly: {
        actionType: 'SET_DIST_WEEKLY',
        getUrl: (uuid) => `/v1/reports/trends/weekly-distribution/${uuid}`,
        errorValue: { warningC: TRADING_WARNING_C.insufficientData, options: [], isEmpty: true },
    },
    distMonthly: {
        actionType: 'SET_DIST_MONTHLY',
        getUrl: (uuid) => `/v1/reports/trends/monthly-disribution/${uuid}`,
        errorValue: { warningC: TRADING_WARNING_C.insufficientData, options: [], isEmpty: true },
    },
    trendMonthly: {
        actionType: 'SET_TREND_MONTHLY',
        getUrl: (uuid) => `/v1/reports/trends/monthly-trend/${uuid}`,
        errorValue: { warningC: TRADING_WARNING_C.insufficientData, options: [], isEmpty: true },
    },
};

const Trends: FC = () => {
    const { selectedCompany } = useContext(sessionContext),
        [{ chartData }, dispatch] = useReducer(TrendsReducer, TrendsInitialState);

    useEffect(() => {
        if (!selectedCompany.company?.id) return;
        dispatch({ type: 'RESTART_LOADING' });
        fetchData<TrendsAction['type']>({
            companyId: selectedCompany.company.id,
            dispatch,
            requestsDic: REQUESTS_DIC,
        });
    }, [selectedCompany.company?.id]);

    return (
        <Grid container spacing={4} my="10px">
            {Object.entries(chartData).map(([key, { options, title, isEmpty, loading }]: any) => (
                <Grid key={key} item xs={12} md={6}>
                    <ChartWithFallback
                        options={options}
                        title={title}
                        isEmpty={isEmpty}
                        loading={loading}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default Trends;
