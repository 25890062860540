import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { ToggleBtn } from '@fairplay2/ui';

export const VendorTypeContainer = styled(Grid)({
    '@media (max-width: 789px)': {
        flexDirection: 'column',
        alignItems: 'center',
    },
});

export const StyledToggleBtn = styled(ToggleBtn)({
    marginTop: 30,
    marginRight: 60,
    '@media (max-width: 789px)': {
        marginRight: 0,
    },
});
