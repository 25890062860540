import { Typography } from '@fairplay2/ui';
import { Alert, Grid } from '@mui/material';
import { useOtpStatus } from 'utils/hooks';

export const Step1 = () => {
    const { isOtpRequired } = useOtpStatus();

    return (
        <Grid container gap={3}>
            <Grid item xs={12}>
                <Typography fontWeight={500} variant="subtitle2">
                    En Fairplay mejoramos la seguridad de la plataforma para proteger tu información
                    y la de tu empresa.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2" fontWeight={500}>
                    <Typography variant="subtitle2" fontWeight={700} component="span">
                        La verificación 2FA
                    </Typography>{' '}
                    es una medida de seguridad que requiere dos pasos para autorizar un proceso en
                    línea. Además de tu contraseña para ingresar a Insights, necesitarás verificar
                    tu identidad con un código, el cual podrás obtener a través de una app
                    verificadora.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Alert severity="warning">
                    {isOtpRequired
                        ? 'Es necesario que cuentes con una aplicación de autenticación para solicitar dispersiones y diferentes procesos en Insights.'
                        : 'Próximamente será necesario que cuentes con una aplicación de autenticación para solicitar dispersiones y diferentes procesos en Insights.'}
                </Alert>
            </Grid>
        </Grid>
    );
};

export default Step1;
