import { FC, useState, useEffect, useRef } from 'react';
import { DefaultBtn, Alert } from '@fairplay2/ui';
import { BaseModal } from 'common-components';
import { Grid, Collapse } from '@mui/material';
import { CartLostConnectionSvg, GenericLostConnectionSvg } from 'assets/SVGs';
import { ArrowBackIos } from '@mui/icons-material';
import fairplayAPI from 'utils/api';
import { useAlert } from 'utils/hooks';
import { GroupedPlatformKey } from 'components/dashboard/data-sources/interfaces';
import {
    getConnectorAlias,
    getDataSourceDescription,
    getPlatformDisplayName,
} from 'components/dashboard/data-sources/utils';
import { useDataSources } from 'components/dashboard/data-sources/context/DataSourcesContext';
import { DisconnectProps } from '../../interfaces';
import { Confirmation } from './Confirmation';

const Disconnect: FC<DisconnectProps> = ({
    platform: platformProp,
    element,
    open,
    onClose,
    onCancel,
}) => {
    const { baseAPI, dispatch } = useDataSources(),
        removedDataRef = useRef<DisconnectProps['element'] | undefined | null>(null),
        [isConfirmed, setIsConfirmed] = useState(false),
        { alert, hideAlert, showAlert } = useAlert(),
        [loading, setLoading] = useState(false),
        source = getDataSourceDescription(platformProp as GroupedPlatformKey),
        platform = getPlatformDisplayName(platformProp as GroupedPlatformKey),
        isOrdersSource = source === 'la fuente de órdenes',
        showBackBtn = !loading && !platformProp.includes('sat') && !platformProp.includes('s3');

    const onCloseModal = () => {
        if (removedDataRef.current !== null)
            dispatch({ type: 'REMOVE', payload: removedDataRef.current });

        onClose();
    };

    const onConfirm = async () => {
        hideAlert();
        setLoading(true);

        if (baseAPI) {
            try {
                await fairplayAPI.delete(`${baseAPI}/${element?.id}`);
                // TODO: Assign response data if backend provides deleted connector(s)
                removedDataRef.current = !element?.platform.includes('google')
                    ? element
                    : undefined;
                setIsConfirmed(true);
            } catch {
                showAlert('No se pudo desconectar la fuente de datos, intente más tarde');
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        // Support for non-conditional rendering of the modal
        if (!open && isConfirmed) {
            setTimeout(() => setIsConfirmed(false), 200);
        }
    }, [open, isConfirmed]);

    return (
        <BaseModal
            open={open}
            onClose={onCancel}
            loading={loading}
            onPrimaryAction={isConfirmed ? onCloseModal : onCancel}
            onSecondaryAction={onConfirm}
            maxWidth={isOrdersSource ? undefined : 723}
        >
            {isConfirmed ? (
                <Confirmation platform={platform} item={element!} sourceString={source} />
            ) : (
                <>
                    {showBackBtn && (
                        <Grid
                            justifyContent="flex-start"
                            marginLeft="-20px"
                            marginTop="-30px"
                            width="auto"
                        >
                            <BaseModal.Icon>
                                <DefaultBtn
                                    variant="text"
                                    onClick={onCancel}
                                    sx={{
                                        textDecoration: 'none',
                                        textAlign: 'start',
                                    }}
                                >
                                    <ArrowBackIos fontSize="inherit" /> Regresar a conexiones
                                </DefaultBtn>
                            </BaseModal.Icon>
                        </Grid>
                    )}

                    <BaseModal.Icon>
                        {isOrdersSource ? <CartLostConnectionSvg /> : <GenericLostConnectionSvg />}
                    </BaseModal.Icon>
                    <BaseModal.Title>
                        {isOrdersSource
                            ? '¿Estás seguro de desconectar esta tienda?'
                            : `¿Deseas desconectar ${source} de ${platform}?`}
                    </BaseModal.Title>
                    <BaseModal.Content>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {isOrdersSource ? (
                            platformProp.includes('s3') ? (
                                'Los datos de esta cuenta ya no se podrán mostrar ni actualizar en la plataforma.'
                            ) : (
                                'Ya no podrás ver esta información en tu tablero de Desempeño comercial.'
                            )
                        ) : (
                            <>
                                Estás a punto de desconectar{' '}
                                <strong>{getConnectorAlias(element as any)}</strong> de la
                                plataforma. Los datos de esta cuenta ya no se podrán mostrar ni
                                actualizar en la plataforma.
                            </>
                        )}
                    </BaseModal.Content>

                    <Grid
                        item
                        container
                        xs={12}
                        justifyContent="center"
                        my={isOrdersSource ? 0 : 1}
                    >
                        <Collapse in={alert.open} sx={{ width: { xs: '100%', sm: '66%' } }}>
                            <Alert severity={alert.type} sx={{ mb: 4 }}>
                                {alert.message}
                            </Alert>
                        </Collapse>
                    </Grid>

                    <BaseModal.Actions secondaryLabel="Sí, desconectar" primaryLabel="Cancelar" />
                </>
            )}
        </BaseModal>
    );
};

export default Disconnect;
