import { FC, useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { DashboardLayout, NotFoundCompaniesPage } from 'layouts';
import { hasActiveSession } from 'utils/api';
import SessionContext from 'context/session/sessionContext';
import { validatesFinancingModules } from 'utils/validation';

interface Props {
    component: FC;
    path: string;
    exact?: boolean;
    isHome?: boolean;
}

const PrivateRoute: FC<Props> = ({ component: Component, path, ...rest }) => {
    const { selectedCompany, totalCompanies } = useContext(SessionContext);

    return (
        <Route
            path={path}
            {...rest}
            render={(props) => {
                const login = hasActiveSession();

                const { financingModulesPermission, cardsModulePermission } =
                    validatesFinancingModules(
                        path,
                        selectedCompany.enabledContracts || false,
                        selectedCompany.enabledCards || false,
                    );

                if (!login)
                    // eslint-disable-next-line react/prop-types
                    return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;

                if (!financingModulesPermission || !cardsModulePermission)
                    return <Redirect to={{ pathname: '/app/sources' }} />;

                return totalCompanies === 0 ? (
                    <NotFoundCompaniesPage />
                ) : (
                    <DashboardLayout>
                        <Component />
                    </DashboardLayout>
                );
            }}
        />
    );
};

export default PrivateRoute;
