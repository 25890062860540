import { FC } from 'react';
import { Paper, Skeleton, Tooltip, Typography, IconBtn } from '@fairplay2/ui';
import { Grid, useTheme, useMediaQuery } from '@mui/material';
import { InfoRounded, RefreshRounded } from '@mui/icons-material';
import { IconText } from '../../typographies/icon-text/IconText.component';
import { GrowthIndicator } from '../growth-indicator/GrowthIndicator.component';
import { StyledStatus } from './CardKpi.styles';
import { CardKpiProps } from './CardKpi.types';

export const CardKpi: FC<CardKpiProps> = ({
    titleProps,
    children,
    emptyDataMessage,
    growthIndicatorProps,
    helperText,
    loading,
    reloadConfig,
    status,
    'data-testid': testId,
}) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm'));

    return loading ? (
        <Skeleton data-testid={`skeleton-${testId}`} variant="rectangular" height={169} />
    ) : (
        <Grid data-testid={testId} container item direction="column" spacing={2}>
            {!!helperText && smDown && (
                <Grid item>
                    <Typography variant="body2" color="info.main">
                        {helperText}
                    </Typography>
                </Grid>
            )}
            <Grid item>
                <Paper
                    variant="outlined"
                    sx={{
                        padding: '25px 15px',
                        position: 'relative',
                        minWidth: '280px',
                        backgroundColor: 'background.default',
                    }}
                >
                    {!!helperText && !smDown && (
                        <Tooltip
                            variant="outlined"
                            color="primary"
                            title={helperText}
                            placement="bottom-end"
                            disableInteractive
                            sx={{
                                position: 'absolute',
                                top: 10,
                                right: 10,
                            }}
                            overrideStyles={{
                                width: '300px',
                                padding: '20px',
                            }}
                        >
                            <InfoRounded fontSize="small" color="disabled" />
                        </Tooltip>
                    )}

                    <Grid item container direction="column">
                        <Grid item>
                            <IconText {...titleProps} />
                        </Grid>
                        <Grid item container direction="column" pl={7}>
                            {!!emptyDataMessage && (
                                <Grid item ml="5px">
                                    <Typography variant="body1" maxWidth="182px">
                                        {emptyDataMessage}
                                    </Typography>
                                </Grid>
                            )}
                            {!!reloadConfig && (
                                <Grid item mt={0.5}>
                                    <StyledStatus
                                        role="alert"
                                        status="warning"
                                        variant="outlined"
                                        shouldReload
                                    >
                                        {reloadConfig?.message ||
                                            'Lo sentimos no pudimos cargar tus datos, por favor intenta actualizar'}
                                    </StyledStatus>
                                    <IconBtn
                                        size="small"
                                        sx={{
                                            position: 'absolute',
                                            bottom: 10,
                                            right: 10,
                                        }}
                                        onClick={reloadConfig?.onReload}
                                    >
                                        <RefreshRounded color="secondary" />
                                    </IconBtn>
                                </Grid>
                            )}

                            {!emptyDataMessage && !reloadConfig && (
                                <>
                                    <Grid item>
                                        <GrowthIndicator {...growthIndicatorProps} />
                                    </Grid>
                                    {status && (
                                        <Grid item mt={1}>
                                            <StyledStatus status={status?.type} variant="outlined">
                                                {status.message}
                                            </StyledStatus>
                                        </Grid>
                                    )}

                                    {!!children && (
                                        <Grid item mt={1}>
                                            {children}
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};
