import { UserState, UserAction } from './interfaces';
import { restoreSavedField } from './utils';

export const restoreSavedState = (initialState: UserState): UserState => ({
    ...initialState,
    selectedCompany: restoreSavedField(
        sessionStorage.getItem('selectedCompany'),
        initialState.selectedCompany,
        'object',
    ),
    user: restoreSavedField(sessionStorage.getItem('user'), initialState.user, 'object'),
    companies: restoreSavedField(
        sessionStorage.getItem('companies'),
        initialState.companies,
        'array',
    ),
    totalCompanies: restoreSavedField(
        sessionStorage.getItem('totalCompanies'),
        initialState.totalCompanies,
    ),
    globalSettings: restoreSavedField(
        sessionStorage.getItem('globalSettings'),
        initialState.globalSettings,
        'object',
    ),
});

const sessionReducer = (state: UserState, action: UserAction): UserState => {
    switch (action.type) {
        case 'SET_USER':
            sessionStorage.setItem('user', JSON.stringify(action.payload));
            return {
                ...state,
                user: action.payload,
            };
        case 'SET_COMPANY':
            sessionStorage.setItem('selectedCompany', JSON.stringify(action.payload));
            if (action.payload.company?.id !== state.selectedCompany.company?.id) {
                sessionStorage.removeItem('selectedContract');
                sessionStorage.removeItem('allowedContracts');
            }
            return {
                ...state,
                selectedCompany: action.payload,
            };
        case 'SET_COMPANIES':
            sessionStorage.setItem('companies', JSON.stringify(action.payload));
            return {
                ...state,
                companies: action.payload,
            };
        case 'TOTAL_COMPANIES':
            sessionStorage.setItem('totalCompanies', JSON.stringify(action.payload));
            return {
                ...state,
                totalCompanies: action.payload,
            };
        case 'SET_GLOBAL_SETTINGS':
            sessionStorage.setItem('globalSettings', JSON.stringify(action.payload));

            return {
                ...state,
                globalSettings: action.payload,
            };
        case 'SET_LOADING':
            return {
                ...state,
                loading: action.payload,
            };

        case 'SET_LOGOUT':
            return {
                ...state,
                token: null,
                user: null,
                errors: {
                    isFatal: false,
                    messages: {},
                },
                totalCompanies: null,
                selectedCompany: {},
                companies: [],
                loading: false,
            };
        case 'SET_ERROR':
            return {
                ...state,
                errors: {
                    isFatal: state.errors.isFatal || action.payload.isFatal,
                    messages: {
                        ...state.errors.messages,
                        ...action.payload.messages,
                    },
                },
                loading: false,
            };
        case 'RESTART_ERRORS':
            return {
                ...state,
                errors: {
                    isFatal: false,
                    messages: {
                        // TODO: Check if we can avoid this conditional in this context
                        ...(state.errors.messages.holidays && {
                            holidays: state.errors.messages.holidays,
                        }),
                    },
                },
            };
        default:
            return state;
    }
};

export default sessionReducer;
