import { FC } from 'react';
import { useHistory } from 'react-router';
import { Grid, TableRow, TableCell } from '@mui/material';
import { ReceiptLongOutlined, Visibility } from '@mui/icons-material';
import { Typography, IconBtn, Tooltip } from '@fairplay2/ui';
import { Status, StatusType } from 'common-components';
import { formatAmount } from 'utils/parsing';
import { formatUtc, SHORT_READABLE_FORMAT } from 'utils/dates';
import { downloadSlip } from '../utils';
import { DisTableItemProps } from '../../interfaces';

const DISBURSEMENT_TYPES: { [key: string]: string } = {
    international: 'Internacional',
    national: 'Nacional',
    card: 'Tarjeta',
};

const DisbursementsTableItem: FC<DisTableItemProps> = ({
    disbursement,
    selectedCompany,
    contractId,
}) => {
    const history = useHistory(),
        [alias, rfc] = disbursement.alias ? disbursement.alias.split('|') : ['', ''];

    const onRedirect = () => {
        const path = disbursement.is_card
            ? `/app/disbursements/cards/detail/${disbursement.card_id}`
            : `/app/disbursements/transactions/detail/${contractId}:${disbursement.id}`;
        history.push(path);
    };

    return (
        <TableRow>
            <TableCell
                sx={{
                    minWidth: 100,
                }}
            >
                <Typography fontWeight={500} color="primary.dark">
                    {formatUtc(disbursement.date || '', SHORT_READABLE_FORMAT, {
                        fallbackString: '',
                    })}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontWeight={500} color="primary.dark">
                    {alias} <br /> {rfc}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontWeight={700} color="primary.main">
                    {`${disbursement.currency?.id || ''}$
                            ${formatAmount(disbursement?.amount)}`}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontWeight={500}>{DISBURSEMENT_TYPES[disbursement.type]}</Typography>
            </TableCell>
            <TableCell>
                {disbursement.status?.id && (
                    <Status
                        variant="outlined"
                        role="presentation"
                        status={
                            disbursement.status?.id === 'requested'
                                ? '0'
                                : (disbursement.status?.id as StatusType)
                        }
                    >
                        {disbursement.status?.text}
                    </Status>
                )}
            </TableCell>
            <TableCell>
                <Typography fontWeight={700} color="primary.main">
                    {formatUtc(disbursement?.modified || '', SHORT_READABLE_FORMAT, {
                        fallbackString: '',
                    })}
                </Typography>
            </TableCell>
            <TableCell align="center">
                <Grid container justifyContent="space-evenly">
                    <Tooltip title="Ver detalle" placement="top" distance={0}>
                        <IconBtn color="secondary" variant="outlined" onClick={onRedirect}>
                            <Visibility />
                        </IconBtn>
                    </Tooltip>
                    <Tooltip title="Descargar comprobante" placement="top" distance={0}>
                        {/*
                            Span necessary to avoid MUI 'Tolltip containing disable button' warning.
                            Manually moving accesible label to button component.
                        */}
                        <span>
                            <IconBtn
                                aria-label="Descargar comprobante"
                                color="secondary"
                                variant="outlined"
                                onClick={() =>
                                    downloadSlip(
                                        selectedCompany?.id || '',
                                        disbursement.contract?.id,
                                        disbursement.id,
                                    )
                                }
                                disabled={
                                    disbursement.status?.id !== 'executed' ||
                                    !disbursement.bankTransferStatementFile
                                }
                            >
                                <ReceiptLongOutlined />
                            </IconBtn>
                        </span>
                    </Tooltip>
                </Grid>
            </TableCell>
        </TableRow>
    );
};

export default DisbursementsTableItem;
