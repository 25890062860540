import { useContext, useState, createContext } from 'react';

const ActiveStepContext = createContext();
const SetActiveStepContext = createContext();

export const useActiveStep = () => useContext(ActiveStepContext);

export const useSetActiveStep = () => useContext(SetActiveStepContext);

// eslint-disable-next-line react/prop-types
export const ActiveStepProvider = ({ children }) => {
    const [activeStep, setActiveStep] = useState(0);

    return (
        // eslint-disable-next-line react/jsx-filename-extension
        <ActiveStepContext.Provider value={activeStep}>
            <SetActiveStepContext.Provider value={setActiveStep}>
                {children}
            </SetActiveStepContext.Provider>
        </ActiveStepContext.Provider>
    );
};
