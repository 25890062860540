import { SummariesKeys } from '../../interfaces';

export const GENERAL_SALES_RESP = [
    {
        id: 'total',
        platform: null as unknown as 'mercado-libre',
        alias: 'Total',
        alias_edited_by_user: true,
        status: null as unknown as string,
        fss: null as unknown as string,
        summaries: {
            gmv: {
                total: 35200,
                growth_percentage: 0,
            },
            orders: {
                total: 640,
                growth_percentage: 0,
            },
            average: {
                total: 55,
                growth_percentage: 0,
            },
        },
        data: [
            {
                date: '2023-01-01',
                year: 2023,
                month: 'January',
                day: 1,
                base_currency: 'MXN',
                gmv: 1600,
                orders: 160,
                average: 10,
            },
            {
                date: '2023-02-01',
                year: 2023,
                month: 'February',
                day: 1,
                base_currency: 'MXN',
                gmv: 1600,
                orders: 160,
                average: 10,
            },
            {
                date: '2024-01-01',
                year: 2024,
                month: 'January',
                day: 1,
                base_currency: 'MXN',
                gmv: 16000,
                orders: 160,
                average: 100,
            },
            {
                date: '2024-02-01',
                year: 2024,
                month: 'February',
                day: 1,
                base_currency: 'MXN',
                gmv: 16000,
                orders: 160,
                average: 100,
            },
        ],
    },
    {
        id: '5a309f26-f511-4a36-b7d9-621e0e6d93ce',
        platform: 'amazon' as 'amazon',
        alias: 'amazon-MX-03',
        alias_edited_by_user: true,
        status: 'active',
        fss: 'active-connector',
        summaries: {
            gmv: {
                total: 17600,
                growth_percentage: 0,
            },
            orders: {
                total: 320,
                growth_percentage: 0,
            },
            average: {
                total: 55,
                growth_percentage: 0,
            },
        },
        data: [
            {
                date: '2023-01-01',
                year: 2023,
                month: 'January',
                day: 1,
                base_currency: 'MXN',
                gmv: 800,
                orders: 80,
                average: 10,
            },
            {
                date: '2023-02-01',
                year: 2023,
                month: 'February',
                day: 1,
                base_currency: 'MXN',
                gmv: 800,
                orders: 80,
                average: 10,
            },
            {
                date: '2024-01-01',
                year: 2024,
                month: 'January',
                day: 1,
                base_currency: 'MXN',
                gmv: 8000,
                orders: 80,
                average: 100,
            },
            {
                date: '2024-02-01',
                year: 2024,
                month: 'February',
                day: 1,
                base_currency: 'MXN',
                gmv: 8000,
                orders: 80,
                average: 100,
            },
        ],
    },
    {
        id: '4cbdaf0c-69f7-489f-bdcb-05162a90016b',
        platform: 'shopify' as 'shopify',
        alias: 'shopify-MX-01',
        alias_edited_by_user: true,
        status: 'active',
        fss: 'active-connector',
        summaries: {
            gmv: {
                total: 17600,
                growth_percentage: 0,
            },
            orders: {
                total: 320,
                growth_percentage: 0,
            },
            average: {
                total: 55,
                growth_percentage: 0,
            },
        },
        data: [
            {
                date: '2023-01-01',
                year: 2023,
                month: 'January',
                day: 1,
                base_currency: 'MXN',
                gmv: 800,
                orders: 80,
                average: 10,
            },
            {
                date: '2023-02-01',
                year: 2023,
                month: 'February',
                day: 1,
                base_currency: 'MXN',
                gmv: 800,
                orders: 80,
                average: 10,
            },
            {
                date: '2024-01-01',
                year: 2024,
                month: 'January',
                day: 1,
                base_currency: 'MXN',
                gmv: 8000,
                orders: 80,
                average: 100,
            },
            {
                date: '2024-02-01',
                year: 2024,
                month: 'February',
                day: 1,
                base_currency: 'MXN',
                gmv: 8000,
                orders: 80,
                average: 100,
            },
        ],
    },
];

export const CHANNELS_SALES_GRAPH_RESP = [
    {
        id: 'amazon',
        name: 'Amazon',
        totals: {
            gmv: {
                period_percentage: 50,
                prev_period_percentage: 45,
                growth_percentage: 5,
            },
            orders: {
                period_percentage: 3,
                prev_period_percentage: 1,
                growth_percentage: 2,
            },
            average: {
                period_percentage: 11.11,
                prev_period_percentage: 11.11,
                growth_percentage: 0,
            },
        },
    },
    {
        id: 'shopify',
        name: 'Shopify',
        totals: {
            gmv: {
                period_percentage: 11.11,
                prev_period_percentage: 10,
                growth_percentage: 1.11,
            },
            orders: {
                period_percentage: 13,
                prev_period_percentage: 10,
                growth_percentage: 3,
            },
            average: {
                period_percentage: 11.11,
                prev_period_percentage: 11.11,
                growth_percentage: 0,
            },
        },
    },
    {
        id: 'magento',
        name: 'Magento',
        totals: {
            gmv: {
                period_percentage: 0.11,
                prev_period_percentage: 1,
                growth_percentage: -0.89,
            },
            orders: {
                period_percentage: 1,
                prev_period_percentage: 1,
                growth_percentage: 0,
            },
            average: {
                period_percentage: 11.11,
                prev_period_percentage: 11.11,
                growth_percentage: 0,
            },
        },
    },
    {
        id: 'woo-commerce',
        name: 'Woo Commerce',
        totals: {
            gmv: {
                period_percentage: 0.78,
                prev_period_percentage: 1,
                growth_percentage: -0.22,
            },
            orders: {
                period_percentage: 20,
                prev_period_percentage: 30,
                growth_percentage: -10,
            },
            average: {
                period_percentage: 11.11,
                prev_period_percentage: 11.11,
                growth_percentage: 0,
            },
        },
    },
    {
        id: 'v-tex',
        name: 'VTEX',
        totals: {
            gmv: {
                period_percentage: 1,
                prev_period_percentage: 3,
                growth_percentage: -2,
            },
            orders: {
                period_percentage: 1,
                prev_period_percentage: 3,
                growth_percentage: -2,
            },
            average: {
                period_percentage: 11.11,
                prev_period_percentage: 11.11,
                growth_percentage: 0,
            },
        },
    },
    {
        id: 'meli',
        name: 'Mercado Libre',
        totals: {
            gmv: {
                period_percentage: 20,
                prev_period_percentage: 30,
                growth_percentage: -10,
            },
            orders: {
                period_percentage: 0.78,
                prev_period_percentage: 1,
                growth_percentage: -0.22,
            },
            average: {
                period_percentage: 11.11,
                prev_period_percentage: 11.11,
                growth_percentage: 0,
            },
        },
    },
    {
        id: 'tiendanube',
        name: 'Tiendanube',
        totals: {
            gmv: {
                period_percentage: 1,
                prev_period_percentage: 1,
                growth_percentage: 0,
            },
            orders: {
                period_percentage: 0.11,
                prev_period_percentage: 1,
                growth_percentage: -0.89,
            },
            average: {
                period_percentage: 11.11,
                prev_period_percentage: 11.11,
                growth_percentage: 0,
            },
        },
    },
    {
        id: 'walmart',
        name: 'Walmart',
        totals: {
            gmv: {
                period_percentage: 13,
                prev_period_percentage: 10,
                growth_percentage: 3,
            },
            orders: {
                period_percentage: 11.11,
                prev_period_percentage: 10,
                growth_percentage: 1.11,
            },
            average: {
                period_percentage: 11.11,
                prev_period_percentage: 11.11,
                growth_percentage: 0,
            },
        },
    },
    {
        id: 'liverpool',
        name: 'Liverpool',
        totals: {
            gmv: {
                period_percentage: 3,
                prev_period_percentage: 1,
                growth_percentage: 2,
            },
            orders: {
                period_percentage: 50,
                prev_period_percentage: 45,
                growth_percentage: 5,
            },
            average: {
                period_percentage: 11.11,
                prev_period_percentage: 11.11,
                growth_percentage: 0,
            },
        },
    },
];

export const CHANNELS_SALES_TABLE_RESP = [...CHANNELS_SALES_GRAPH_RESP.slice(0, 5)];

export const TOTALS_SALES_RESP = [
    {
        id: 'total',
        platform: null,
        alias: 'Total',
        alias_edited_by_user: true,
        status: null,
        fss: null,
        in_filters: false,
        summaries: {
            gmv: {
                valid: 35200,
                valid_growth_percentage: 0,
                invalid: 16000,
                invalid_growth_percentage: 0,
                returned: 8000,
                returned_growth_percentage: 0,
                total: 59200,
                total_growth_percentage: 0,
            },
            orders: {
                valid: 640,
                valid_growth_percentage: 0,
                invalid: 160,
                invalid_growth_percentage: 0,
                returned: 80,
                returned_growth_percentage: 0,
                total: 880,
                total_growth_percentage: 0,
            },
            average: {
                valid: 55,
                valid_growth_percentage: 0,
                invalid: 100,
                invalid_growth_percentage: 0,
                returned: 100,
                returned_growth_percentage: 0,
                total: 67.27,
                total_growth_percentage: 0,
            },
        },
    },
    {
        id: '5a309f26-f511-4a36-b7d9-621e0e6d93ce',
        platform: 'amazon',
        alias: 'amazon-MX-03',
        alias_edited_by_user: true,
        status: 'active',
        fss: 'active-connector',
        in_filters: false,
        summaries: {
            gmv: {
                valid: 17600,
                valid_growth_percentage: 0,
                invalid: 8000,
                invalid_growth_percentage: 0,
                returned: 4000,
                returned_growth_percentage: 0,
                total: 29600,
                total_growth_percentage: 0,
            },
            orders: {
                valid: 320,
                valid_growth_percentage: 0,
                invalid: 80,
                invalid_growth_percentage: 0,
                returned: 40,
                returned_growth_percentage: 0,
                total: 440,
                total_growth_percentage: 0,
            },
            average: {
                valid: 55,
                valid_growth_percentage: 0,
                invalid: 100,
                invalid_growth_percentage: 0,
                returned: 100,
                returned_growth_percentage: 0,
                total: 67.27,
                total_growth_percentage: 0,
            },
        },
    },
    {
        id: '2cbdaf0c-69f7-489f-bdcb-05162a90016b',
        platform: 'shopify',
        alias: 'shopify-MX-01',
        alias_edited_by_user: true,
        status: 'active',
        fss: 'active-connector',
        in_filters: false,
        summaries: {
            gmv: {
                valid: 17600,
                valid_growth_percentage: 0,
                invalid: 8000,
                invalid_growth_percentage: 0,
                returned: 4000,
                returned_growth_percentage: 0,
                total: 29600,
                total_growth_percentage: 0,
            },
            orders: {
                valid: 320,
                valid_growth_percentage: 0,
                invalid: 80,
                invalid_growth_percentage: 0,
                returned: 40,
                returned_growth_percentage: 0,
                total: 440,
                total_growth_percentage: 0,
            },
            average: {
                valid: 55,
                valid_growth_percentage: 0,
                invalid: 100,
                invalid_growth_percentage: 0,
                returned: 100,
                returned_growth_percentage: 0,
                total: 67.27,
                total_growth_percentage: 0,
            },
        },
    },
];

export const TOTALS_TABLE_SUMARIES_EMPTY = {
    gmv: {
        invalidTotal: null,
        returnedTotal: null,
        total: null,
        validTotal: null,
    },
    orders: {
        invalidTotal: null,
        returnedTotal: null,
        total: null,
        validTotal: null,
    },
    average: {
        invalidTotal: null,
        returnedTotal: null,
        total: null,
        validTotal: null,
    },
};

export const CHANNELS_TABLE_ITEM = {
    name: 'Amazon',
    totals: {
        gmv: {
            periodPercentage: 100,
            prevPeriodPercentage: 100,
            growthPercentage: -30.93,
        },
        orders: {
            periodPercentage: 100,
            prevPeriodPercentage: 100,
            growthPercentage: -29.2,
        },
        average: {
            periodPercentage: 100,
            prevPeriodPercentage: 100,
            growthPercentage: -2.44,
        },
    },
    category: 'gmv' as SummariesKeys,
    color: '#5bf57d',
};

export const TOTALS_BY_CONNECTOR_TABLE_DATA = {
    connectorsData: [
        {
            alias: 'amazon-MX-04',
            fss: 'active-connector',
            id: '0c4c0723-86d5-4029-885f-72a9dbc345d9',
            inFilters: false,
            isAliasEdited: true,
            platform: 'amazon',
            status: 'active',
            summaries: {
                gmv: {
                    validTotal: 562071.19,
                    invalidTotal: 0,
                    returnedTotal: 0,
                    total: 562071.19,
                },
                orders: {
                    validTotal: 1635,
                    invalidTotal: 202,
                    returnedTotal: 0,
                    total: 1837,
                },
                average: {
                    validTotal: 343.77,
                    invalidTotal: 0,
                    returnedTotal: 0,
                    total: 305.97,
                },
            },
        },
        {
            alias: 'mercado-libre-99',
            fss: 'active-connector',
            id: '5g5c0723-86d5-4029-885f-72a9dbc123x5',
            inFilters: false,
            isAliasEdited: true,
            platform: 'mercado-libre',
            status: 'active',
            summaries: {
                gmv: {
                    validTotal: 4234.66,
                    invalidTotal: 10,
                    returnedTotal: 20,
                    total: 4234.66,
                },
                orders: {
                    validTotal: 4566,
                    invalidTotal: 404,
                    returnedTotal: 50,
                    total: 3754,
                },
                average: {
                    validTotal: 777.54,
                    invalidTotal: 0,
                    returnedTotal: 0,
                    total: 909.99,
                },
            },
        },
        {
            alias: 'shopify-01',
            fss: 'active-connector',
            id: 'b9b82217-cb5d-4471-8d2f-d4b6fbc3b040',
            inFilters: false,
            isAliasEdited: false,
            platform: 'shopify',
            status: 'active',
            summaries: {
                gmv: {
                    validTotal: null,
                    invalidTotal: null,
                    returnedTotal: null,
                    total: null,
                },
                orders: {
                    validTotal: null,
                    invalidTotal: null,
                    returnedTotal: null,
                    total: null,
                },
                average: {
                    validTotal: null,
                    invalidTotal: null,
                    returnedTotal: null,
                    total: null,
                },
            },
        },
    ],
    count: 3,
    externalFilters: {
        date_range_start: '',
        date_range_end: '',
        years: '',
        months: '',
        stores: '',
        status: '',
    },
    loading: false,
    page: 0,
    rowsPerPage: 5,
    summaries: {
        gmv: {
            validTotal: 562071.19,
            invalidTotal: 0,
            returnedTotal: 0,
            total: 562071.19,
        },
        orders: {
            validTotal: 1635,
            invalidTotal: 202,
            returnedTotal: 0,
            total: 1837,
        },
        average: {
            validTotal: 343.77,
            invalidTotal: 0,
            returnedTotal: 0,
            total: 305.97,
        },
    },
    tabValue: 'gmv' as SummariesKeys,
};
