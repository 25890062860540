import { FC, useState, useEffect, useContext, ChangeEvent } from 'react';
import { Grid, MenuItem, Collapse, FormHelperText } from '@mui/material';
import { AutoFixHigh } from '@mui/icons-material';
import {
    DefaultBtn,
    DefaultInput,
    Select,
    InterpunctLoader,
    Paper,
    Typography,
    Alert,
} from '@fairplay2/ui';
import SessionContext from 'context/session/sessionContext';
import { validateEmail } from 'utils/validation';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import fairplayAPI from 'utils/api';
import { useAlert } from 'utils/hooks';
import { InviteUserForm, Role } from '../interfaces';

const InviteUser: FC = () => {
    const { selectedCompany } = useContext(SessionContext),
        [userHasBeenIvited, setUserHasBeenIvited] = useState(false),
        [values, setValues] = useState<InviteUserForm>({
            email: '',
            role: '',
        }),
        [errors, setErrors] = useState<InviteUserForm>({
            email: '',
            role: '',
        }),
        [roles, setRoles] = useState<Role[]>([]),
        [loading, setLoading] = useState(false),
        { alert, showAlert } = useAlert();

    const onChange = (event: ChangeEvent<HTMLInputElement>) =>
        setValues({ ...values, [event.target.name]: event.target.value });

    const onChangeInput = (prop: keyof InviteUserForm, errorMsg: string) => {
        if (values[prop]) {
            switch (prop) {
                case 'email': {
                    const emailError = validateEmail(values.email);
                    setErrors({ ...errors, email: emailError });
                    break;
                }
                default:
                    setErrors({ ...errors, [prop]: '' });
                    break;
            }
        } else setErrors({ ...errors, [prop]: errorMsg });
    };

    const onSelectRole = (event: ChangeEvent<{ value: string }>) =>
        setValues({ ...values, role: event.target.value });

    const onInvite = async () => {
        if (values.email === '') {
            setErrors({ ...errors, email: 'Ingresa el email del usuario' });
            return;
        }

        // Check if a role is selected
        if (!values.role) setErrors({ ...errors, role: 'Selecciona un rol válido' });
        else {
            setErrors({ ...errors, role: '' });

            // Check if there isn't an error
            if (!Object.values(errors).some((x) => x !== null && x !== '')) {
                setLoading(true);
                const data = {
                    email: values.email,
                    role: values.role,
                };

                try {
                    await fairplayAPI.post(
                        `/v1/companies/${selectedCompany.company?.id}/collaborators/invite`,
                        data,
                    );
                    setUserHasBeenIvited(true);
                } catch (errResponse: ErrorObj | any) {
                    if (errResponse.type === 'form')
                        setErrors((prev) => ({ ...prev, ...errResponse.error }));
                    else
                        showAlert(
                            errResponse?.error ||
                                'Ocurrió un error al enviar la invitación, Intenta más tarde',
                        );
                } finally {
                    setLoading(false);
                }
            }
        }
    };

    const resetInvite = () => {
        setValues({
            email: '',
            role: '',
        });
        setErrors({
            email: '',
            role: '',
        });
        setUserHasBeenIvited(false);
    };

    useEffect(() => {
        const getRoles = async () => {
            try {
                const res: any = await fairplayAPI.get(`/v1/companies/roles`);
                setRoles(res.data.body.results);
            } catch {
                showAlert('Ocurrió un error al cargar los roles, Intenta más tarde');
            }
        };

        getRoles();
    }, [showAlert]);

    return (
        <Paper variant="elevation" sx={{ padding: '20px 25px' }}>
            {userHasBeenIvited ? (
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" color="primary.main">
                            ¡Genial! Invitación enviada
                        </Typography>
                    </Grid>

                    <Grid item xs={12} marginTop="20px">
                        <Typography variant="subtitle2" color="text.primary">
                            Has agregado a{' '}
                            <Typography component="span" color="primary.main">
                                {values.email}
                            </Typography>{' '}
                            a formar parte de tu equipo. Pídele que revise su correo electrónico
                            para completar su registro y comenzar a usar la plataforma de Fairplay.
                        </Typography>
                    </Grid>

                    <Grid container justifyContent="flex-end" marginTop="50px">
                        <DefaultBtn size="small" onClick={resetInvite}>
                            Entendido
                        </DefaultBtn>
                    </Grid>
                </Grid>
            ) : (
                <Grid container component="form" aria-labelledby="invite-user-form">
                    <Grid item xs={12}>
                        <Typography id="invite-user-form" variant="subtitle1" color="primary.main">
                            Invita un nuevo usuario
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={10} marginTop="20px">
                        <DefaultInput
                            name="email"
                            type="text"
                            label="Email:"
                            labelVariant="contained"
                            placeholder="¿A quién vas a invitar?"
                            value={values.email}
                            onChange={onChange}
                            onBlur={() => onChangeInput('email', 'Ingresa el email del usuario')}
                            error={errors.email}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={10} marginTop="10px">
                        <Select
                            label="Rol:"
                            value={values.role}
                            onChange={onSelectRole}
                            onBlur={() => onChangeInput('role', 'Seleciona el rol del usuario')}
                        >
                            <MenuItem value="" disabled>
                                Seleccionar rol
                            </MenuItem>
                            {roles.map((option: any) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText error={!!errors.role} sx={{ marginLeft: '15px' }}>
                            {errors.role}
                        </FormHelperText>
                    </Grid>
                    <Grid container justifyContent="flex-end" marginTop="10px">
                        <Collapse in={alert.open}>
                            <Alert severity={alert.type}>{alert.message}</Alert>
                        </Collapse>
                    </Grid>

                    <Grid
                        container
                        justifyContent={
                            !loading && (values.role || values.email) ? 'space-between' : 'flex-end'
                        }
                        marginTop="20px"
                    >
                        {!loading && (values.role || values.email) && (
                            <DefaultBtn
                                variant="text"
                                startIcon={<AutoFixHigh />}
                                onClick={resetInvite}
                            >
                                Limpiar campos
                            </DefaultBtn>
                        )}
                        {loading ? (
                            <InterpunctLoader />
                        ) : (
                            <DefaultBtn size="small" onClick={onInvite}>
                                Invitar
                            </DefaultBtn>
                        )}
                    </Grid>
                </Grid>
            )}
        </Paper>
    );
};

export default InviteUser;
