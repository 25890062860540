import { FC } from 'react';
import { Grid } from '@mui/material';
import { Typography, Skeleton } from '@fairplay2/ui';

const AccountsDataSkeleton: FC = () => (
    <>
        <Grid item xs={12} sm={6} md={4} data-testid="account-skeleton">
            <Typography variant="label">Alias de la cuenta:</Typography>
            <Skeleton height={48} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
            <Typography variant="label">Banco:</Typography>
            <Skeleton height={48} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
            <Typography variant="label">CLABE:</Typography>
            <Skeleton height={48} />
        </Grid>
    </>
);

export default AccountsDataSkeleton;
