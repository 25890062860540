import { FC, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LoaderPage, Typography } from '@fairplay2/ui';
import { Grid, GridProps } from '@mui/material';
import { WomanManCelebratingSvg } from 'assets/SVGs';
import sessionContext from 'context/session/sessionContext';
import { LoginButton } from '../styles/index';

const SuccessConfirmation: FC<GridProps> = (props) => {
    const history = useHistory(),
        { getUser, loading, setLoading } = useContext(sessionContext);

    const onLogin = () => {
        history.push('/app/sources');
    };

    useEffect(() => {
        // refresh user data
        (async () => {
            await getUser();
            setLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return loading ? (
        <LoaderPage loading={loading} />
    ) : (
        <Grid container direction="column" alignItems="center" {...props}>
            <Typography variant="h3" component="h1" alignSelf="flex-start">
                ¡Todo listo!
            </Typography>
            <Typography
                alignSelf="flex-start"
                variant="body1"
                color="primary.dark"
                mt={1.25}
                mb={4.5}
                lineHeight={1.71}
                fontWeight={700}
            >
                Tu cuenta ha sido registrada exitosamente, ahora podrás ingresar a la plataforma de
                Fairplay con tu correo electrónico y contraseña.
            </Typography>

            <WomanManCelebratingSvg style={{ maxWidth: '100%', marginBottom: 23 }} />

            <LoginButton size="small" onClick={onLogin}>
                Navega la plataforma
            </LoginButton>
        </Grid>
    );
};

export default SuccessConfirmation;
