import { FC } from 'react';
import { Grid, Skeleton } from '@mui/material';
import { CreditCard } from '@mui/icons-material';
import { IconBtn, Tooltip, Typography } from '@fairplay2/ui';
import { StatusType } from 'common-components';
import { formatAmount } from 'utils/parsing';
import { ShowCardInfo, CardStatus } from '../styles';
import { FairplayCardProps } from '../interfaces';
import CardBackground from './CardBackground';

export const FairplayCard: FC<FairplayCardProps> = ({
    alias,
    creditLimit,
    status,
    loading,
    readMode = false,
    onOpenInfoModal,
}) => (
    <CardBackground>
        <Grid
            sx={{
                position: 'absolute',
                top: { xs: 70, sm: 92 },
            }}
        >
            {loading ? (
                <>
                    <Grid item xs={12}>
                        <Skeleton
                            height={27}
                            width="150px"
                            sx={{ bgcolor: 'primary.main' }}
                            data-testid="alias-skeleton"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Skeleton
                            height={25}
                            width="130px"
                            sx={{ bgcolor: 'primary.main' }}
                            data-testid="credit-limit-skeleton"
                        />
                    </Grid>
                </>
            ) : (
                <>
                    <Typography
                        variant="h5"
                        color="primary.contrastText"
                        fontWeight={700}
                        data-testid="alias"
                    >
                        {alias || 'Alias'}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        color="primary.contrastText"
                        fontWeight={500}
                        data-testid="credit-limit"
                    >
                        {creditLimit?.currency} $
                        {formatAmount((creditLimit?.amount as number) ?? 0) || '0.00'}
                    </Typography>
                </>
            )}
        </Grid>

        {readMode &&
            (loading ? (
                <>
                    <Grid item xs={12} sx={{ position: 'absolute', top: 18, right: 12 }}>
                        <Skeleton
                            height={60}
                            width={115}
                            sx={{ bgcolor: 'primary.main' }}
                            data-testid="status-skeleton"
                        />
                    </Grid>

                    <Grid item xs={12} sx={{ position: 'absolute', bottom: 32 }}>
                        <Skeleton
                            height={36}
                            width={36}
                            variant="circular"
                            sx={{ bgcolor: 'primary.main' }}
                            data-testid="button-skeleton"
                        />
                    </Grid>
                </>
            ) : (
                <>
                    <ShowCardInfo>
                        <Tooltip
                            variant="standard"
                            title="Usar tarjeta"
                            placement="top"
                            distance={4}
                        >
                            {/*
                            Span necessary to avoid MUI 'Tolltip containing disable button' warning.
                            Manually moving accesible label to button component.
                        */}
                            <span>
                                <IconBtn
                                    aria-label="Usar tarjeta"
                                    color="secondary"
                                    variant="outlined"
                                    size="medium"
                                    sx={{
                                        bgcolor: 'white !important',
                                    }}
                                    disabled={status?.id !== 'active'}
                                    onClick={onOpenInfoModal}
                                >
                                    <CreditCard />
                                </IconBtn>
                            </span>
                        </Tooltip>
                    </ShowCardInfo>

                    <CardStatus
                        variant="standard"
                        status={status?.id as StatusType}
                        aria-label="Estatus de la tarjeta"
                    >
                        {status?.text}
                    </CardStatus>
                </>
            ))}
    </CardBackground>
);

export default FairplayCard;
