import { FC, useState } from 'react';
import { Grid } from '@mui/material';
import { CreditScore, CompareArrows, AssignmentInd } from '@mui/icons-material';
import { Typography, TabGroup, type TabData } from '@fairplay2/ui';
import { useIsAdmin } from '../../hooks';
import { CardParamsProps } from '../interfaces';
import { ExecutedTransactionsWidget } from './executed-transactions-table/ExecutedTransactionsWidget';
import { TransitTransactionsWidget } from './transit-transactions-table/TransitTransacionsWidget';
import ApprovalRequestsWidget from './approval-requests-table/ApprovalRequestsWidget';

const NORMAL_USER_TABS: TabData[] = [
        {
            label: 'Ejecutadas',
            icon: <CreditScore />,
            panelId: 'executed-tasks-panel',
        },
        {
            label: 'En tránsito',
            icon: <CompareArrows />,
            panelId: 'in-transit-panel',
        },
    ],
    ADMIN_TABS: TabData[] = [
        ...NORMAL_USER_TABS,
        {
            label: 'Solicitudes',
            icon: <AssignmentInd />,
            panelId: 'requests-panel',
        },
    ];

export const CardDetailTabs: FC<CardParamsProps> = ({ cardId }) => {
    const [step, setStep] = useState(0),
        isAdmin = useIsAdmin(),
        labelArr = isAdmin ? ADMIN_TABS : NORMAL_USER_TABS;

    return (
        <Grid container mt={{ xs: '30px', sm: '40px' }}>
            <Typography variant="subtitle1" mb="5px">
                Transacciones de la tarjeta
            </Typography>

            <Grid item xs={12} ml={['-21px', '-21px', '-21px']}>
                <TabGroup
                    value={step}
                    tabs={labelArr}
                    onChange={(_, newValue) => setStep(newValue)}
                >
                    <Grid item xs={12} md={10} key="executed-transactions">
                        <ExecutedTransactionsWidget cardId={cardId} />
                    </Grid>
                    ,
                    <Grid item xs={12} md={8} key="transit-transactions">
                        <TransitTransactionsWidget cardId={cardId} />
                    </Grid>
                    {isAdmin && (
                        <Grid item xs={12} md={8} key="approval-requests">
                            <ApprovalRequestsWidget cardId={cardId} />
                        </Grid>
                    )}
                </TabGroup>
            </Grid>
        </Grid>
    );
};
export default CardDetailTabs;
