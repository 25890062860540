import { FC } from 'react';
import { Grid } from '@mui/material';
import { Modal, DefaultBtn, Typography } from '@fairplay2/ui';
import { EmailSendSvg, ApproveCardSvg } from 'assets/SVGs';
import { ConfirmRequestCardModalProps } from '../../interfaces';
import { REQUEST_CARD_MODAL_TEXTS } from '../../constants';

const ConfirmRequestCardModal: FC<ConfirmRequestCardModalProps> = ({
    open,
    onClose,
    alias,
    isAdmin,
}) => {
    const objectMatch = isAdmin ? 'isAdmin' : 'isNotAdmin',
        content = REQUEST_CARD_MODAL_TEXTS[objectMatch];

    return (
        <Modal
            aria-labelledby="confirm-request-card"
            disableBackdropClick
            disableEscapeKeyDown
            open={open}
            onClose={onClose}
            maxWidth={720}
        >
            <Grid container>
                <Grid container justifyContent="center">
                    {isAdmin ? <ApproveCardSvg /> : <EmailSendSvg />}
                </Grid>
                <Typography
                    variant="subtitle1"
                    color="primary.main"
                    mt="25px"
                    lineHeight={1.4}
                    id="confirm-request-card"
                >
                    {content.title}{' '}
                    <Typography component="span" color="primary.dark" fontWeight={700}>
                        {alias}
                    </Typography>{' '}
                    {content.content}
                </Typography>
                <Typography variant="subtitle2" color="text.primary" fontWeight={500} mt="15px">
                    {content.statusInfo}
                </Typography>
                <Grid container justifyContent="center" marginTop="30px">
                    <DefaultBtn size="small" onClick={onClose}>
                        Entendido
                    </DefaultBtn>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default ConfirmRequestCardModal;
