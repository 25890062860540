import {
    FC,
    createContext,
    useContext,
    useEffect,
    useReducer,
    Reducer,
    Dispatch,
    useRef,
    ReactNode,
} from 'react';
import SessionContext from 'context/session/sessionContext';
import fairplayAPI from 'utils/api';
import { forOneOrMany } from 'utils/lists';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import {
    invalidateRawSet,
    modifyLocalDataSources,
    getFormattedDataSources,
    INITIAL_FORMATTED_DATA_SOURCES,
    getPriorityConnector,
} from './utils';
import {
    DataSourcesAction,
    DataSourcesContextType,
    DataSourcesState,
    PrivateDataSourcesAction,
} from './interfaces';

export const DataSourcesContext = createContext<null | DataSourcesContextType>(null);

export const DataSourcesReducer: Reducer<DataSourcesState, PrivateDataSourcesAction> = (
    state,
    action,
) => {
    if (action.type === 'INVALIDATE') return invalidateRawSet(state);

    if (action.type === 'FOCUS')
        return {
            ...state,
            focusOn: action.payload,
        };

    if (action.type !== 'REPLACE' && action.onCommit)
        return {
            // If there is no payload, fallback to invalidate logic but keep
            // onCommit reference
            ...(action.payload ? state : invalidateRawSet(state)),
            operationQueue: [
                ...state.operationQueue,
                {
                    type: action.type,
                    payload: action.payload,
                    onCommit: action.onCommit,
                },
            ],
        };

    let raw,
        { operationQueue } = state;

    if (!action.payload) {
        // No payload was found, fallback to invalidate logic
        //  to guarantee a valid state.
        return invalidateRawSet(state);
    }
    if (action.type === 'REPLACE') {
        raw = action.payload;
        if (action.flushQueue) operationQueue = [];
    } else {
        const localActions = modifyLocalDataSources(state.raw);
        forOneOrMany(action.payload, localActions[action.type]);
        raw = localActions.FINISH();
    }
    // Only update formatted state if no operations are awaiting
    //  to avoid triggering re-renders for components that consume that data
    const formatted = operationQueue.length ? state.platforms : getFormattedDataSources(raw);

    return {
        operationQueue,
        raw,
        platforms: formatted,
        focusOn: state.focusOn,
    };
};

// Storing this value in a special const allows to
//  compare using reference equality
const EMPTY_RAW_DATA_SOURCES = {},
    initialState: DataSourcesState = {
        raw: EMPTY_RAW_DATA_SOURCES as DataSourcesState['raw'],
        platforms: INITIAL_FORMATTED_DATA_SOURCES,
        operationQueue: [] as DataSourcesState['operationQueue'],
        focusOn: null,
    };

export const DataSourcesProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const {
            selectedCompany: { company: { id: companyId } = { id: '' } } = { company: { id: '' } },
            setLoading,
            setError,
        } = useContext(SessionContext),
        [{ operationQueue, raw, platforms, focusOn }, dispatch] = useReducer(
            DataSourcesReducer,
            initialState,
        ),
        initialFetch = useRef(true),
        baseAPI = companyId ? `/v2/companies/${companyId}/connectors` : '';

    useEffect(() => {
        // No selected company
        if (!baseAPI) {
            // First fetch in the session, keep awaiting for selected company
            //  to perform any operations
            if (initialFetch.current) return;
            // User decided to logout but hasn't
            //  closed the current tab (session)
            //  Restoring state to initial values.
            dispatch({
                type: 'REPLACE',
                payload: EMPTY_RAW_DATA_SOURCES,
                flushQueue: true,
            });
            initialFetch.current = true;
            return;
        }

        const wasInitialFetch = initialFetch.current;
        initialFetch.current = false;

        (async () => {
            try {
                const res: any = await fairplayAPI.get(baseAPI),
                    dataSources = res?.data?.body?.results || EMPTY_RAW_DATA_SOURCES,
                    // TODO: Delegate logic into DataSourcesReducer
                    satConnector = getPriorityConnector(
                        [dataSources['sat-ws'][0], dataSources['sat-fairplay'][0]],
                        ['reauthorize', 'active', 'pending'],
                        'sat',
                    );

                // TODO: report to Sentry?
                //  Backend should guarantee that these connectors
                //  don't exist at the same time
                if (dataSources.s3connector?.length && dataSources['s3-manual-upload']?.length)
                    // eslint-disable-next-line no-console
                    console.error('Unexpected conflicting data');

                dispatch({
                    type: 'REPLACE',
                    payload: { ...dataSources, sat: satConnector },
                    // Don't flush if this is the
                    //  first fetch in the session
                    // Flush queue as any previous update
                    //  was performed over an entirely
                    //  different set of data sources.
                    flushQueue: !wasInitialFetch,
                });
            } catch (errResponse: ErrorObj | any) {
                setError({
                    isFatal: false,
                    messages: {
                        connectors:
                            errResponse.error ||
                            'Se ha producido un error. Por favor intenta más tarde',
                    },
                });
            } finally {
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseAPI]);

    useEffect(() => {
        // Only excecute this useEffect contents
        //  As a result of an action of type INVALIDATE
        if (!Array.isArray(raw) || !baseAPI) return;

        (async () => {
            try {
                const res: any = await fairplayAPI.get(baseAPI),
                    dataSources = res?.data?.body?.results || {},
                    // TODO: Delegate logic into DataSourcesReducer
                    satConnector = getPriorityConnector(
                        [dataSources['sat-ws'][0], dataSources['sat-fairplay'][0]],
                        ['reauthorize', 'active', 'pending'],
                        'sat',
                    );

                // TODO: report to Sentry?
                //  Backend should guarantee that these connectors
                //  don't exist at the same time
                if (dataSources.s3connector?.length && dataSources['s3-manual-upload']?.length)
                    // eslint-disable-next-line no-console
                    console.error('Unexpected conflicting data');

                dispatch({
                    type: 'REPLACE',
                    payload: { ...dataSources, sat: satConnector },
                    flushQueue: false,
                });
            } catch (errResponse: ErrorObj | any) {
                // An error occurred when fetching data sources,
                //  fallback to previous state
                dispatch({ type: 'REPLACE', payload: raw[0], flushQueue: true });
                // eslint-disable-next-line no-console
                console.error(errResponse);
            }
        })();
    }, [raw, baseAPI]);

    useEffect(() => {
        if (Array.isArray(raw)) return;
        if (!Object.keys(raw).length) return;
        if (!operationQueue.length) return;

        const localActions = modifyLocalDataSources(raw);
        operationQueue.forEach(({ type, payload, onCommit }) => {
            // If no payload is found, any changes were already fetched
            //  from backend, we only care about onCommit actions
            if (payload) forOneOrMany(payload as any, localActions[type]);
            onCommit?.();
        });
        dispatch({ type: 'REPLACE', payload: localActions.FINISH(), flushQueue: true });
    }, [raw, operationQueue]);

    return (
        <DataSourcesContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                platforms,
                awaitingOperations: !!operationQueue.length,
                baseAPI,
                dispatch: dispatch as Dispatch<DataSourcesAction>,
                focusOn,
            }}
        >
            {children}
        </DataSourcesContext.Provider>
    );
};

export const useDataSources = () => {
    const context = useContext(DataSourcesContext);
    if (context === null) {
        throw new Error('useDataSources must be used within a DataSourcesContext');
    }
    return context;
};
