import { formatAmount } from 'utils/parsing';

// TODO: centralize & standardize monetary operations in order to not convert individual decimal monetary values to integer and vice versa
/**
 * Converts a monetary value represented as a decimal number (e.g., 19.55)
 * into an integer value that corresponds to the amount in cents (e.g., 1955)
 *
 * @param decimalValue decimal monetary value
 * @returns integer monetary value
 */
export const monetaryDecimalToInteger = (decimalValue: number | undefined) =>
    typeof decimalValue === 'number' ? Math.round(decimalValue * 100) : 0;

/**
 * Multiply two integer values (each one represents decimal amounts in cents) and rounds
 * the result to 2 equivalent decimals in a monetary decimal value.
 *
 * @param integerValue1 first integer monetary value
 * @param integerValue2 second integer monetary value
 * @returns integer values product truncated to two decimal places
 */
export const multiplyMonetaryIntegers = (
    integerValue1: number | undefined,
    integerValue2: number | undefined,
) =>
    // .slice() function is used instead of .toFixed() because the product of the integer values is also an integer
    !!integerValue1 && !!integerValue2
        ? Number((integerValue1 * integerValue2).toString().slice(0, -2))
        : 0;

/**
 * Converts a monetary value represented as a integer number (e.g., 195555)
 * into an decimal value that corresponds to the amount in cents
 * if includeCommas is true return (e.g., 1,955.55) else (e.g., 1955.55)
 *
 * @param integerValue integer monetary value
 * @param includeCommas default true
 * @returns decimal monetary value
 */
export const monetaryIntegerToDecimal = (
    integerValue: number | undefined,
    includeCommas: boolean = true,
) => {
    if (typeof integerValue === 'number')
        return includeCommas ? formatAmount(integerValue / 100) : (integerValue / 100).toFixed(2);
    return '0.00';
};
