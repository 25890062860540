import { FC } from 'react';
import { Typography } from '@fairplay2/ui';
import { BaseModal } from 'common-components';
import { CartTimeSvg } from 'assets/SVGs';
import { ModalWithNamesProps } from '../interfaces';

const PendingConnectionModal: FC<ModalWithNamesProps> = ({ onConfirm, open, storeNames }) => (
    <BaseModal open={open} onClose={onConfirm} onPrimaryAction={onConfirm}>
        <BaseModal.Icon>
            <CartTimeSvg />
        </BaseModal.Icon>
        <BaseModal.Title>Se ha conectado una nueva tienda</BaseModal.Title>
        <BaseModal.Content>
            El procesamiento de datos de{' '}
            {storeNames.map((store, index) => (
                <Typography key={store} color="primary.main" component="strong">
                    {store}
                    {index < storeNames.length - 1 ? ', ' : ' '}
                </Typography>
            ))}
            puede tomar hasta 24 horas.
        </BaseModal.Content>
        <BaseModal.Actions primaryLabel="Entendido" />
    </BaseModal>
);

export default PendingConnectionModal;
