export const ORDERS_PLATFORMS = [
    'shopify',
    'magento',
    'woocommerce',
    'vtex',
    'amazon',
    'mercado-libre',
    'tiendanube',
    'walmart',
    // 'liverpool',
    's3',
    // 'prestashop',
] as const;

export const ADS_PLATFORMS = ['google-ads', 'facebook-ads'] as const;

export const ANALYTICS_PLATFORMS = ['google-analytics'] as const;

export const FISCAL_PLATFORMS = ['sat'] as const;

export const PAYMENTS_PLATFORMS = ['stripe', 'paypal', 'clip'] as const;

export const STATUS_MAP = {
    pending: 'Pendiente',
    invalid: 'Inválido',
    active: 'Conectado',
    reauthorize: 'Reautorizar',
    suspended: 'Suspendido',
};
