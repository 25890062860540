import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

export const useRecaptcha = () => {
    const ref = useRef<null | ReCAPTCHA>(null),
        [isLoading, setisLoading] = useState(true);

    const onLoad = () => {
        setisLoading(false);
    };

    const reset = () => {
        ref.current?.reset();
    };

    return {
        recaptchaRef: ref,
        recaptchaIsLoading: isLoading,
        onLoadRecaptcha: onLoad,
        resetRecaptcha: reset,
    };
};
