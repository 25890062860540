import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxContainer = styled(Grid)(({ theme }) => ({
    margin: '23px 0px',
    width: '40%',
    [theme.breakpoints.down('md')]: {
        width: '80%',
    },
    [theme.breakpoints.down('sm')]: {
        width: '95%',
    },
}));
