import { FC, useContext } from 'react';
import { Typography, Avatar } from '@fairplay2/ui';
import { Grid } from '@mui/material';
import SessionContext from 'context/session/sessionContext';
import { InvoiceDetailProps } from '../../interfaces';

const InvoiceHeader: FC<InvoiceDetailProps> = ({ invoiceValues }) => {
    const { selectedCompany } = useContext(SessionContext);

    return (
        <Grid item container xs={12}>
            <Grid item xs={8} sm={2} xl={1} marginRight={{ xs: 0, xl: '25px' }}>
                <Avatar
                    fallbackTo="company"
                    src={selectedCompany.company?.avatar || ''}
                    sx={{
                        borderWidth: '3px',
                        width: { xs: '60px', md: '87px' },
                        height: 'auto',
                    }}
                    alt="logo empresa"
                />
            </Grid>
            <Grid
                item
                xs={12}
                sm={8}
                xl={9}
                sx={{
                    mt: { xs: 2, sm: 0 },
                    ml: { xs: 0, lg: '-15px' },
                    pr: 1,
                    order: { xs: 2, sm: 1 },
                }}
            >
                <Typography variant="subtitle1" color="primary.main" fontWeight={500}>
                    {invoiceValues?.issuer?.name}
                </Typography>
                <Typography variant="subtitle2" color="primary.dark" fontWeight={500}>
                    {invoiceValues?.issuer?.rfc}
                </Typography>
                <Typography variant="subtitle2" color="text.primary" fontWeight={500}>
                    {invoiceValues?.issuer?.taxRegime}
                </Typography>
            </Grid>
            <Grid
                item
                textAlign="left"
                xs={4}
                sm={2}
                sx={{ order: { xs: 1, sm: 2 }, ml: { xs: 0, lg: '15px' } }}
            >
                <Typography variant="subtitle2" color="text.disabled" fontWeight={500}>
                    {invoiceValues?.placeOfIssue}
                </Typography>
                {/* <Typography variant="subtitle2" color="primary.main" fontWeight={500}>
                    C.P. 06500
                </Typography> */}
            </Grid>
        </Grid>
    );
};

export default InvoiceHeader;
