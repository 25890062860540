import { FC, useState, useContext } from 'react';
import { useTheme, useMediaQuery, Grid, FormHelperText, Collapse } from '@mui/material';
import { DefaultBtn, Modal, InterpunctLoader, Typography, Alert, FilePicker } from '@fairplay2/ui';
import { WomanFoldersSvg } from 'assets/SVGs';
import SessionContext from 'context/session/sessionContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj, simplifyFormErrors } from 'utils/error-handler';
import fairplayAPI from 'utils/api';
import { useAlert } from 'utils/hooks';
import { toFormData } from 'utils/parsing';
import { UploadDocModalProps, DocumentsErrors } from '../interfaces';

const UploadDocModal: FC<UploadDocModalProps> = ({
    open,
    file,
    documentType,
    selectedDocument,
    onFileSelect,
    onClose,
}) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        { selectedCompany } = useContext(SessionContext),
        [loading, setLoading] = useState(false),
        [isUploadSuccessful, setIsUploadSuccessful] = useState(false),
        [errors, setErrors] = useState<DocumentsErrors>({
            fileUrl: '',
            documentTypeId: '',
        }),
        { alert, hideAlert, showAlert } = useAlert();

    const onCloseModal = () => {
        setIsUploadSuccessful(false);
        onClose(isUploadSuccessful);
    };

    const onUpload = async () => {
        hideAlert();
        if (file && documentType) {
            setLoading(true);
            const data = toFormData({
                fileUrl: file,
                documentTypeId: documentType.id,
            });
            try {
                if (selectedDocument)
                    await fairplayAPI.patch(
                        `/v1/companies/${selectedCompany.company?.id}/documents/${selectedDocument.id}`,
                        data,
                    );
                else
                    await fairplayAPI.post(
                        `/v1/companies/${selectedCompany.company?.id}/documents`,
                        data,
                    );
                setIsUploadSuccessful(true);
            } catch (errResponse: ErrorObj | any) {
                if (errResponse.type === 'form')
                    setErrors((prev) => ({
                        ...prev,
                        ...simplifyFormErrors(errResponse.error),
                    }));
                else {
                    let errMsg = 'No se pudo subir el archivo, intente más tarde';
                    if (errResponse?.error) errMsg = errResponse.error;
                    showAlert(errMsg);
                }
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Modal
            open={open}
            onClose={onCloseModal}
            maxWidth={682}
            title={isUploadSuccessful ? '' : 'Documentos legales'}
            sx={{ '.MuiDialogContent-root': { paddingTop: 0 } }}
        >
            {isUploadSuccessful ? (
                <Grid container>
                    <Grid container justifyContent="center">
                        <WomanFoldersSvg />
                    </Grid>
                    <Typography variant="subtitle1" color="primary.main" mt="30px">
                        ¡Éxito! Documento {selectedDocument ? 'actualizado' : 'agregado'}
                    </Typography>

                    <Grid item xs={12}>
                        <Typography variant="subtitle2" color="text.primary" mt="15px">
                            Gracias por {selectedDocument ? 'actualizar' : 'agregar'} el documento{' '}
                            <Typography component="span" color="primary.dark" fontWeight={700}>
                                {documentType?.name}
                            </Typography>{' '}
                            Nuestro equipo lo revisará y validará.{' '}
                        </Typography>
                        <Typography variant="subtitle2" color="text.primary" mt="15px">
                            Verifica su estatus en el listado de Documentos legales.
                        </Typography>
                    </Grid>

                    <Grid container justifyContent="center">
                        <DefaultBtn size="small" onClick={onCloseModal} sx={{ mt: '20px' }}>
                            Entendido
                        </DefaultBtn>
                    </Grid>
                </Grid>
            ) : (
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="body1" color="text.primary">
                            Estás {selectedDocument ? 'actualizando' : 'agregando'} el documento
                        </Typography>
                        <Typography variant="subtitle1" color="primary.dark">
                            {documentType?.name}
                        </Typography>
                        <FormHelperText error={!!errors.documentTypeId}>
                            <Typography variant="body1" color="inherit" fontWeight={500}>
                                {errors.documentTypeId}
                            </Typography>
                        </FormHelperText>
                    </Grid>
                    <Typography variant="subtitle2" color="primary.main" mt="40px" fontWeight={500}>
                        {file ? '¿Subir este archivo?' : '¡Seleccione un archivo!'}
                    </Typography>
                    <Grid item xs={12} marginTop={2}>
                        <FilePicker
                            accept="image/*,.pdf"
                            onFileChange={(newFile) => onFileSelect(newFile, showAlert, hideAlert)}
                            fileName={file?.name}
                            paperVariant="dashed"
                            variant="compact"
                            sx={{
                                '.FpPaper-content': {
                                    padding: '14px',
                                },
                            }}
                        />
                        <FormHelperText
                            error={!!errors.fileUrl}
                            sx={{ marginTop: '10px', textAlign: 'center' }}
                        >
                            <Typography variant="body1" color="text.primary" fontWeight={500}>
                                {errors.fileUrl
                                    ? errors.fileUrl
                                    : 'Cambia el archivo haciendo clic sobre el área punteada'}
                            </Typography>
                        </FormHelperText>
                    </Grid>

                    <Grid container justifyContent="center" mt="20px">
                        <Collapse in={alert.open}>
                            <Alert severity={alert.type}>{alert.message}</Alert>
                        </Collapse>
                    </Grid>

                    <Grid container justifyContent="flex-end" mt="40px">
                        {loading ? (
                            <InterpunctLoader data-testid="upload-legal-doc-interpunct-loader" />
                        ) : (
                            <>
                                <DefaultBtn
                                    fullWidth={smDown}
                                    sx={{
                                        mr: { sx: 0, sm: '20px' },
                                    }}
                                    size="small"
                                    variant="outlined"
                                    onClick={onCloseModal}
                                >
                                    Cancelar
                                </DefaultBtn>
                                <DefaultBtn
                                    disabled={!file}
                                    fullWidth={smDown}
                                    sx={{
                                        mt: { sx: '20px', sm: 0 },
                                    }}
                                    size="small"
                                    onClick={onUpload}
                                >
                                    Sí, confirmar
                                </DefaultBtn>
                            </>
                        )}
                    </Grid>
                </Grid>
            )}
        </Modal>
    );
};

export default UploadDocModal;
