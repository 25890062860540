import { FC, useEffect, useState, useRef } from 'react';
import { Grid, TableCell, TableRow, TableSortLabel } from '@mui/material';
import { ArrowDownward, ExpandMore } from '@mui/icons-material';
import { Typography } from '@fairplay2/ui';
import { Table } from 'common-components';
import fairplayAPI from 'utils/api';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { asSorting } from 'utils/lists';
import { useTableContext } from '../../state/TableContext';
import { useIsAdmin } from '../../hooks';
import { CardInfoModal } from '../../card-detail/components';
import { Card, CardWithRequestData, CardsTableProps } from '../../interfaces';
import { CreditLimitCardModal } from '../CreditLimitCardModal';
import { CancelCardModal } from '../CancelCardModal';
import CardTableItem from './CardTableItem';

const CardsTable: FC<CardsTableProps> = ({
    selectedCompanyId,
    selectedContractId,
    maxForDisposition,
}) => {
    const {
            externalFilters,
            orderCriteria,
            order,
            page,
            setPage,
            rowsPerPage,
            requestParams,
            setRequestParams,
            resetFilterValues,
            onChangeRowsPerPage,
            onSortDate,
            hideAlert,
            showAlert,
        } = useTableContext(),
        [loading, setLoading] = useState(true),
        [cards, setCards] = useState<CardWithRequestData[]>([]),
        [count, setCount] = useState(0),
        waitForValidPagination = useRef(false),
        [cancelCardModal, setCancelCardModal] = useState({
            open: false,
            cardId: '',
            alias: '',
        }),
        [creditLimitCardModal, setCreditLimitCardModal] = useState({
            open: false,
            cardId: '',
            alias: '',
            availableToday: {
                amount: 0,
                currency: 'MXN',
            },
        }),
        [infoCardModalId, setInfoCardModalId] = useState(''),
        isAdmin = useIsAdmin();

    const fetchCards = async () => {
        setLoading(true);
        try {
            const resp: any = await fairplayAPI.get(
                `/cards/v1/contract/${selectedContractId}/cards${requestParams}`,
                {
                    baseService: 'CARDS',
                },
            );

            setCount(resp.data.body?.count || 0);
            setCards(resp.data.body.results);
        } catch (errResponse: ErrorObj | any) {
            showAlert(
                errResponse?.error || 'No se pudieron cargar las tarjetas, intente más tarde',
            );
        } finally {
            setLoading(false);
        }
    };

    // reset filters and wait for requestParams to be full
    useEffect(() => {
        if (selectedCompanyId && selectedContractId) {
            resetFilterValues();
            waitForValidPagination.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompanyId, selectedContractId]);

    useEffect(() => {
        if (!(selectedCompanyId && selectedContractId)) return;

        // TODO: Is it possible to improve Table component as to avoid having this extra check?
        if (waitForValidPagination.current) {
            waitForValidPagination.current = false;
            if (!requestParams.includes('page=1&')) return;
        }

        hideAlert();

        fetchCards();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestParams, selectedCompanyId, selectedContractId]);

    const onOpenCreditLimitCardModal = ({ card_id, alias, available_today }: Card) => {
        setCreditLimitCardModal({
            open: true,
            cardId: card_id,
            alias,
            availableToday: available_today,
        });
    };

    const onCloseCreditLimitCardModal = (updated?: boolean) => {
        setCreditLimitCardModal({
            open: false,
            cardId: '',
            alias: '',
            availableToday: {
                amount: 0,
                currency: 'MXN',
            },
        });
        if (updated) fetchCards();
    };

    const onOpenCancelCardModal = (cardId: string, alias: string) => {
        setCancelCardModal({ open: true, cardId, alias });
    };

    const onCloseCancelCardModal = (updated?: boolean) => {
        setCancelCardModal({ open: false, cardId: '', alias: '' });
        if (updated) fetchCards();
    };

    return (
        <>
            {/* TODO: change conditional render as implemented in Upload Modal */}
            {creditLimitCardModal.open && (
                <CreditLimitCardModal
                    onClose={onCloseCreditLimitCardModal}
                    selectedContractId={selectedContractId}
                    maxForDisposition={maxForDisposition}
                    {...creditLimitCardModal}
                />
            )}
            {cancelCardModal.open && (
                <CancelCardModal
                    onClose={onCloseCancelCardModal}
                    selectedContractId={selectedContractId}
                    {...cancelCardModal}
                />
            )}
            {!!infoCardModalId && (
                <CardInfoModal onClose={() => setInfoCardModalId('')} cardId={infoCardModalId} />
            )}

            <Grid item container xs={12} justifyContent="center">
                <Table
                    sx={{
                        marginBottom: 4,
                    }}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    getTableItem={(card: CardWithRequestData) => (
                        <CardTableItem
                            key={card.card.card_id || `requested-${card.initial_request.id}`}
                            card={card}
                            showAlert={showAlert}
                            onOpenCancelCardModal={onOpenCancelCardModal}
                            onOpenCreditLimitCardModal={onOpenCreditLimitCardModal}
                            onOpenCardInfoModal={(cardId) => setInfoCardModalId(cardId)}
                            selectedContractId={selectedContractId}
                            fetchCards={fetchCards}
                        />
                    )}
                    currentItems={cards}
                    columns={7}
                    loading={loading}
                    pagination={{
                        count,
                        page,
                        rowsPerPage,
                        onPageChange: (_, newPage) => setPage(newPage),
                        onRowsPerPageChange: onChangeRowsPerPage,
                    }}
                    externalFilters={{
                        ...externalFilters,
                        ...{ ordering: asSorting(orderCriteria, order) },
                    }}
                    emptyItemsMessage="No hemos encontrado tarjetas disponibles"
                    onParamsQueryChange={setRequestParams}
                >
                    <TableRow>
                        <TableCell
                            aria-sort={
                                // eslint-disable-next-line no-nested-ternary
                                orderCriteria === 'request_date'
                                    ? order === 'desc'
                                        ? 'descending'
                                        : 'ascending'
                                    : 'none'
                            }
                        >
                            <TableSortLabel
                                disabled={!cards.length}
                                active={orderCriteria === 'request_date'}
                                data-criteria="request_date"
                                direction={orderCriteria === 'request_date' ? order : 'desc'}
                                onClick={onSortDate}
                                IconComponent={
                                    orderCriteria === 'request_date' ? ArrowDownward : ExpandMore
                                }
                            >
                                <Typography component="span" color="text.primary">
                                    Solicitada
                                </Typography>
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary">Alias</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary">Monto disponible</Typography>
                        </TableCell>
                        <TableCell width="85px">
                            <Typography color="text.primary">
                                {isAdmin ? 'Solicito / Aprobó' : 'Aprobó'}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary">Estatus</Typography>
                        </TableCell>
                        <TableCell
                            aria-sort={
                                // eslint-disable-next-line no-nested-ternary
                                orderCriteria === 'update_date'
                                    ? order === 'desc'
                                        ? 'descending'
                                        : 'ascending'
                                    : 'none'
                            }
                        >
                            <TableSortLabel
                                disabled={!cards.length}
                                active={orderCriteria === 'update_date'}
                                direction={orderCriteria === 'update_date' ? order : 'desc'}
                                data-criteria="update_date"
                                onClick={onSortDate}
                                IconComponent={
                                    orderCriteria === 'update_date' ? ArrowDownward : ExpandMore
                                }
                            >
                                <Typography component="span" color="text.primary">
                                    Actualización
                                </Typography>
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="left">
                            <Typography color="text.primary">Acciones</Typography>
                        </TableCell>
                    </TableRow>
                </Table>
            </Grid>
        </>
    );
};

export default CardsTable;
