import { FC, ChangeEvent } from 'react';
import { Grid } from '@mui/material';
import {
    ProjectSvg /* TODO: uncomment when cards are re-enabled, PayMethodsSvg  */,
} from 'assets/SVGs';
import { Typography, DefaultBtn, ToggleBtn } from '@fairplay2/ui';
import { TypeProps } from '../../interfaces.v1';

const DisbursementType: FC<TypeProps> = ({
    disbursementType,
    onStepChange,
    onDisbursementTypeChange,
}) => (
    <>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h4" color="primary.main" mt="10px">
                    Solicitud de{' '}
                    <Typography component="span" variant="h4" color="primary.dark" fontWeight={700}>
                        dispersión
                    </Typography>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1" color="primary.main">
                    Elige el tipo de dispersión que necesitas
                </Typography>
            </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center" my="30px">
            <Grid item container xs={12} lg={8} justifyContent="space-evenly">
                <ToggleBtn
                    value="invoice"
                    data-testid="invoice-btn"
                    selected={disbursementType === 'invoice'}
                    onClick={() => onDisbursementTypeChange('invoice')}
                    sx={{ width: '250px !important', height: '220px !important', mt: '30px' }}
                >
                    <ProjectSvg width={80} height={80} style={{ marginBottom: 20 }} />
                    <Typography variant="h4" color="primary.main">
                        Factura
                    </Typography>
                    <Typography variant="h4" color="primary.dark" fontWeight={700}>
                        Única
                    </Typography>
                </ToggleBtn>

                {/*
                    TODO: Uncomment when cards are re-enabled
                    <ToggleBtn
                        value="card"
                        data-testid="card-btn"
                        selected={disbursementType === 'card'}
                        onClick={() => onDisbursementTypeChange('card')}
                        sx={{ width: '250px !important', height: '220px !important', mt: '30px' }}
                    >
                        <PayMethodsSvg width={80} height={80} style={{ marginBottom: 20 }} />
                        <Typography variant="h4" color="primary.main">
                            Tarjeta
                        </Typography>
                        <Typography variant="h4" color="primary.dark" fontWeight={700}>
                            Fairplay
                        </Typography>
                    </ToggleBtn> */}
            </Grid>
            <Grid item xs={12} textAlign="center" marginTop="50px">
                <DefaultBtn
                    size="small"
                    disabled={!disbursementType}
                    onClick={(e: ChangeEvent<{}>) => onStepChange(e, 1)}
                >
                    Continuar
                </DefaultBtn>
            </Grid>
        </Grid>
    </>
);

export default DisbursementType;
