import { FC } from 'react';
import { Grid } from '@mui/material';
import { Skeleton } from '@fairplay2/ui';

const DisbursementDetailSkeleton: FC = () => (
    <Grid container justifyContent="center" mt="15px" data-testid="disbursement-detail-skeleton">
        <Grid item container xs={12} sm={6} marginTop={4}>
            <Skeleton variant="rectangular" height={20} width="35%" />
            <Skeleton variant="rectangular" height={50} width="95%" sx={{ mt: '12px' }} />
        </Grid>
        <Grid item container xs={12} sm={6} marginTop={4}>
            <Skeleton variant="rectangular" height={20} width="35%" />
            <Skeleton variant="rectangular" height={50} width="95%" sx={{ mt: '12px' }} />
        </Grid>
        <Grid item container xs={12} sm={6} marginTop={4}>
            <Skeleton variant="rectangular" height={20} width="35%" />
            <Skeleton variant="rectangular" height={50} width="95%" sx={{ mt: '12px' }} />
        </Grid>
        <Grid item container xs={12} sm={6} marginTop={4}>
            <Skeleton variant="rectangular" height={20} width="35%" />
            <Skeleton variant="rectangular" height={50} width="95%" sx={{ mt: '12px' }} />
        </Grid>
        <Grid item container xs={12} sm={6} marginTop={4}>
            <Skeleton variant="rectangular" height={20} width="35%" />
            <Skeleton variant="rectangular" height={50} width="95%" sx={{ mt: '12px' }} />
        </Grid>
        <Grid item container xs={12} sm={6} marginTop={4}>
            <Skeleton variant="rectangular" height={20} width="35%" />
            <Skeleton variant="rectangular" height={50} width="95%" sx={{ mt: '12px' }} />
        </Grid>
        <Grid item container xs={12} sm={6} marginTop={4}>
            <Skeleton variant="rectangular" height={20} width="35%" />
            <Skeleton variant="rectangular" height={90} width="95%" sx={{ mt: '12px' }} />
        </Grid>
        <Grid item container xs={12} sm={6} marginTop={4}>
            <Skeleton variant="rectangular" height={20} width="35%" />
            <Skeleton variant="rectangular" height={50} width="95%" sx={{ mt: '12px' }} />
        </Grid>
    </Grid>
);

export default DisbursementDetailSkeleton;
