import { createTheme, styled } from '@mui/material';
import { DefaultInput, Skeleton, DefaultInputProps, FairplayTheme, Paper } from '@fairplay2/ui';
import { formatAmount } from 'utils/parsing';
import { esES } from '@mui/x-date-pickers';
import { DailyPaymentOrdersProps } from '../interfaces';

const getBorderColor = (timeType: DailyPaymentOrdersProps['timeType'], remainingAmount: number) => {
    if (timeType === 'future') return 'info';
    if (timeType === 'past') return remainingAmount ? 'error' : 'secondary';

    return '';
};

export const StyledPaymentOrderPaper = styled(Paper, {
    shouldForwardProp: (propName: string) => !['data', 'size', 'timeType'].includes(propName),
})<Pick<DailyPaymentOrdersProps, 'data' | 'size' | 'timeType'>>(
    ({ data, size, timeType = 'today' }) => {
        const borderColor = data ? getBorderColor(timeType, data.remainingAmount) : '';

        return {
            boxShadow: size === 'lg' ? '0px 3px 6px #0000001A' : '0px 3px 6px #0000000F',
            borderColor: borderColor ? FairplayTheme.palette[borderColor].main : 'transparent',
            borderWidth: 1,
            borderStyle: 'solid',
            width: `${size === 'lg' ? 205 : 141}px !important`,
            height: `${size === 'lg' ? 200 : 129}px !important`,
            borderRadius: `10px !important`,
            backgroundColor:
                timeType === 'today'
                    ? FairplayTheme.palette.primary.dark
                    : FairplayTheme.palette.background.paper,
            '.PaymentOrder-container': {
                display: 'flex',
                boxSizing: 'border-box',
                alignItems: 'flex-end',
                padding: '14px',
                flexDirection: 'column',
                height: '100%',
                textAlign: 'right',
            },
            '.PaymentOrder-header': {
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 'auto',
                width: '100%',
            },
            '.PaymentOrder-title': {
                color:
                    timeType === 'today' ? FairplayTheme.palette.primary.contrastText : '#00000099',
                fontWeight: timeType === 'today' ? 700 : 500,
                fontSize: FairplayTheme.typography[size === 'lg' ? 'h4' : 'subtitle2'].fontSize,
                lineHeight: FairplayTheme.typography[size === 'lg' ? 'h4' : 'subtitle2'].lineHeight,
            },
            '.PaymentOrder-totalPayAmount': {
                fontSize: FairplayTheme.typography[size === 'lg' ? 'h4' : 'subtitle2'].fontSize,
                color:
                    // eslint-disable-next-line no-nested-ternary
                    timeType === 'today'
                        ? FairplayTheme.palette.primary.contrastText
                        : timeType === 'past'
                        ? FairplayTheme.palette.auxiliary.main
                        : FairplayTheme.palette.primary.main,
                fontWeight: 700,
                lineHeight: size === 'sm' ? 1.22 : 1.375,
                // eslint-disable-next-line no-nested-ternary
                marginBottom: timeType === 'future' ? (size === 'lg' ? 46.4 : 18.4) : undefined,
            },
            '.PaymentOrder-todaysPayAmount': {
                color: FairplayTheme.palette.secondary.main,
                fontWeight: 700,
                fontSize: FairplayTheme.typography[size === 'lg' ? 'subtitle2' : 'body1'].fontSize,
                lineHeight: 1.3,
            },
            '.PaymentOrder-remainingAmount': {
                fontSize: FairplayTheme.typography[size === 'lg' ? 'subtitle2' : 'body1'].fontSize,
                color:
                    timeType === 'today' || !!data?.remainingAmount
                        ? FairplayTheme.palette.error.main
                        : FairplayTheme.palette.secondary.main,
                fontWeight: 700,
                lineHeight: 1.3,
                marginBottom: timeType !== 'today' && size === 'lg' ? 24 : 0,
                marginTop: timeType === 'today' ? 4 : 0,
                backgroundColor: timeType === 'today' ? '#FFFFFF' : undefined,
            },
            '& .MuiButtonBase-root': {
                padding: size === 'sm' ? 0 : undefined,
                backgroundColor: '#FFFFFF',
            },
            '& .MuiButtonBase-root.Mui-disabled': {
                backgroundColor: 'transparent',
            },
        };
    },
);

export const calendarTheme = createTheme(
    FairplayTheme,
    {
        components: {
            MuiPickerStaticWrapper: {
                styleOverrides: {
                    content: {
                        overflowX: 'auto',
                        '& .css-epd502': {
                            overflow: 'inherit',
                            width: '100%',
                            maxHeight: 'none',
                        },
                    },
                    root: {
                        '& .MuiPickerStaticWrapper-content': { backgroundColor: 'transparent' },
                    },
                },
            },
            MuiCalendarPicker: {
                styleOverrides: {
                    root: {
                        width: '100%',
                        overflow: 'inherit',
                        maxHeight: 'none',
                        '& .MuiDayPicker-weekDayLabel': {
                            fontFamily: FairplayTheme.typography.fontFamily,
                        },
                        // Month selector container
                        '& .MuiPickersCalendarHeader-root': {
                            display: 'none',
                        },
                        '& .PrivatePickersSlideTransition-root': {
                            overflow: 'inherit',
                            height: 'auto',
                            width: '1056px',
                        },
                        '& .MuiDayPicker-monthContainer': {
                            position: 'static',
                        },
                        '& .MuiIconButton-root:not(.Mui-disabled)': {
                            backgroundColor: '#FFFFFF',
                            color: FairplayTheme.palette.secondary.main,
                            border: `1px solid ${FairplayTheme.palette.secondary.main} !important`,
                        },
                        '& .MuiButtonBase-root': {
                            backgroundColor: 'transparent !important',
                            border: 'none',
                        },
                        '& .MuiButtonBase-root:not(.Mui-selected)': {
                            border: 'none',
                        },
                        // Days label container
                        '& .MuiDayPicker-header': {
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '1052px',
                            textTransform: 'uppercase',
                            '& > *': {
                                width: '141px',
                                display: 'flex',
                                justifyContent: 'left',
                                fontSize: FairplayTheme.typography.body1.fontSize,
                            },
                        },
                        '& .MuiPickersDay-root': {
                            cursor: 'default',
                        },
                        '& .MuiDayPicker-weekContainer': {
                            justifyContent: 'flex-start',
                        },
                    },
                },
            },
        },
    },
    esES,
);

const PALETTE_MAP: Record<string, 'secondary' | 'error'> = {
    positive: 'secondary',
    negative: 'error',
};

export const ColoredInput = styled(
    ({ value, ...props }: DefaultInputProps) =>
        value === undefined ? (
            <Skeleton width="100%" height={53} />
        ) : (
            <DefaultInput
                value={typeof value === 'number' ? `MXN $ ${formatAmount(value)}` : value || '-'}
                {...props}
            />
        ),
    {
        shouldForwardProp: (prop) => prop !== 'sx' && prop !== 'type',
    },
)<{
    type: 'neutral' | 'positive' | 'negative';
}>(({ theme, type }) => {
    const color =
        type !== 'neutral' ? `${theme.palette[PALETTE_MAP[type]].main} !important` : undefined;

    return {
        '.MuiInputBase-input': {
            textAlign: 'right',
            color,
            WebkitTextFillColor: color,
        },
    };
});
