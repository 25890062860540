import { forwardRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Loader } from '@fairplay2/ui';
import { ReCaptchaProps } from './ReCaptcha.types';

export const ReCaptcha = forwardRef<ReCAPTCHA, ReCaptchaProps>(
    (
        {
            onLoad,
            isLoading,
            sitekey = process.env.REACT_APP_GOOLE_RECAPTCHA_SITE_KEY as string,
            ...props
        },
        ref,
    ) => {
        if (!sitekey) throw new Error('A valid reCAPTCHA key was not provided.');

        return (
            <>
                {isLoading && <Loader />}
                <ReCAPTCHA {...props} ref={ref} asyncScriptOnLoad={onLoad} sitekey={sitekey} />
            </>
        );
    },
);
