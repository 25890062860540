import { FC } from 'react';
import { Grid } from '@mui/material';
import { Paper, Typography, Skeleton } from '@fairplay2/ui';
import { CardProps } from './interfaces';

const Component: FC<CardProps> = ({ item, title, symbol, loading }) =>
    loading ? (
        <Skeleton variant="rectangular" height="170px" sx={{ borderRadius: '10px' }} />
    ) : (
        <Paper
            variant="outlined"
            sx={{
                bgcolor: 'background.default',
                borderRadius: '10px !important',
                boxSizing: 'border-box',
                padding: '30px 20px 20px 20px',
                minHeight: ['auto', '185px', '160px', '140px'],
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} textAlign="left">
                    <Typography variant="subtitle2" color="text.primary" fontWeight={700}>
                        {title.toUpperCase()}
                    </Typography>
                </Grid>

                <Grid item xs={12} textAlign="left">
                    {item.warningC ? (
                        <Typography variant="body1" color="primary.main">
                            No hay órdenes registradas
                        </Typography>
                    ) : (
                        <Typography variant="h4" color="primary.main">
                            {`${symbol || ''}${item.total}`}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Paper>
    );

export const Card = Component;
