import { ChangeEvent, FC } from 'react';
import { Divider, Grid, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import { Info } from '@mui/icons-material';
import { IconBtn, Select, DefaultInput, Tooltip, Typography } from '@fairplay2/ui';
import { DatePicker } from '@fairplay2/ui-x-dates';
import { SearchFilters, getActiveFilters } from 'common-components';
import { asSorting } from 'utils/lists';
import { toTimelessISOUtc } from 'utils/dates';
import { CurrencyRange } from '@fairplay2/ui-x-currencies';
import { useTableContext } from '../../state/TableContext';
import { CardFilterObj } from '../../interfaces';
import { FILTER_CONTAINER_STYLE, REQUESTED_CARD_STATUS } from '../../constants';

const CardFilters: FC = () => {
    const theme = useTheme(),
        mdDown = useMediaQuery(theme.breakpoints.down('md')),
        {
            filterValues,
            setFilterValues,
            currencyRange,
            isFilterOff,
            setIsFilterOff,
            activeFilters,
            setActiveFilters,
            setExternalFilters,
            orderCriteria,
            order,
            resetFilterValues,
            onCurrencyRangeChange,
        } = useTableContext();

    const onFilterSearch = () => {
        const selectedDay = filterValues.selectedDayRange,
            dateFilters = {
                start_date: selectedDay?.from ? toTimelessISOUtc(selectedDay.from) : '',
                end_date: selectedDay?.to ? toTimelessISOUtc(selectedDay.to) : '',
            };

        setActiveFilters(
            getActiveFilters({
                date: dateFilters,
                alias: filterValues.alias,
                minMaxAmount: {
                    min_amount: currencyRange[0],
                    max_amount: currencyRange[1],
                },
                status: filterValues.status,
            }),
        );

        setExternalFilters({
            ...dateFilters,
            alias: filterValues.alias,
            min_amount: currencyRange[0],
            max_amount: currencyRange[1],
            status: filterValues.status,
            ordering: asSorting(orderCriteria, order),
        });
    };

    const onFiltersChange = (event: ChangeEvent<HTMLInputElement>) => {
        const filterName = event.target.name,
            newFilterValue = event.target.value;

        if (isFilterOff) setIsFilterOff(false);

        setFilterValues((prev: CardFilterObj) => ({
            ...prev,
            [filterName]: newFilterValue,
        }));
    };

    const onSelectedDay = (range: typeof filterValues['selectedDayRange']) =>
        onFiltersChange({
            target: { name: 'selectedDayRange', value: range },
            // Event interface expects `value` to be a string and needs more fields.
            //  Forcing type allows to reuse onFiltersChange logic
        } as any);

    return (
        <SearchFilters
            isFilterOff={isFilterOff}
            activeFilters={activeFilters}
            onFilterSearch={onFilterSearch}
            resetFilters={resetFilterValues}
            width="950px"
            marginTop={mdDown ? '10px' : '-15px'}
        >
            <Grid
                item
                container
                minWidth="23%"
                height="100%"
                textAlign="center"
                alignItems="center"
                sx={FILTER_CONTAINER_STYLE}
            >
                <DatePicker
                    variant="standard"
                    labelVariant="contained"
                    label="Fecha de solicitud"
                    mode="range"
                    placeholder="Desde - Hasta"
                    value={filterValues.selectedDayRange}
                    onChange={onSelectedDay}
                    inputAlignment="start"
                />
            </Grid>
            <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ color: 'background.dark' }}
            />
            <Grid
                item
                container
                minWidth="23%"
                height="100%"
                justifyContent="center"
                alignItems="center"
                sx={FILTER_CONTAINER_STYLE}
            >
                <DefaultInput
                    id="aliasSearch"
                    name="alias"
                    label="Alias"
                    labelVariant="contained"
                    value={filterValues.alias}
                    onChange={onFiltersChange}
                    placeholder="¿Cuál tarjeta?"
                    variant="standard"
                />
            </Grid>
            <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ color: 'background.dark' }}
            />
            <Grid
                item
                container
                minWidth="23%"
                height="100%"
                justifyContent="center"
                alignItems="center"
                sx={FILTER_CONTAINER_STYLE}
            >
                <CurrencyRange
                    values={currencyRange}
                    setValues={onCurrencyRangeChange}
                    label="Monto disponible"
                    max={10000000}
                    title="Define un rango del monto disponible"
                    name="Monto disponible"
                    variant="standard"
                />
            </Grid>
            <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ color: 'background.dark' }}
            />
            <Grid
                item
                container
                minWidth="22%"
                height="100%"
                justifyContent="center"
                alignItems="center"
                sx={FILTER_CONTAINER_STYLE}
            >
                <Select
                    value={filterValues.status}
                    onChange={onFiltersChange}
                    name="status"
                    variant="standard"
                    labelVariant="contained"
                    label={
                        <Grid container>
                            <Typography variant="body2" color="text.primary" fontWeight={500}>
                                Estatus
                            </Typography>
                            <Tooltip
                                color="paper"
                                placement="top-start"
                                title={
                                    <Grid container maxWidth="175px">
                                        <Typography
                                            variant="body2"
                                            color="info.main"
                                            fontWeight={700}
                                            my="2px"
                                        >
                                            Solicitada:{' '}
                                            <Typography
                                                color="text.primary"
                                                fontWeight={500}
                                                component="span"
                                            >
                                                Has solicitado una tarjeta.
                                            </Typography>
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="success.main"
                                            fontWeight={700}
                                            my="2px"
                                        >
                                            Activa:{' '}
                                            <Typography
                                                color="text.primary"
                                                fontWeight={500}
                                                component="span"
                                            >
                                                Tu tarjeta está activa, puedes utilizarla.
                                            </Typography>
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="warning.main"
                                            fontWeight={700}
                                            my="2px"
                                        >
                                            Suspendida:&nbsp;
                                            <Typography
                                                color="text.primary"
                                                fontWeight={500}
                                                component="span"
                                            >
                                                Tarjeta temporalmente suspendida.
                                            </Typography>
                                        </Typography>
                                        {/* TODO: Uncomment when backend functionaly is implemented */}
                                        {/* <Typography
                                            variant="body2"
                                            color="warning.main"
                                            fontWeight={700}
                                            my="2px"
                                        >
                                            Inactiva:{' '}
                                            <Typography
                                                color="text.primary"
                                                fontWeight={500}
                                                component="span"
                                            >
                                                Tu tarjeta está inactiva, no puedes utilizarla.
                                            </Typography>
                                        </Typography> */}
                                        <Typography
                                            variant="body2"
                                            color="error.main"
                                            fontWeight={700}
                                            my="2px"
                                        >
                                            Declinada:{' '}
                                            <Typography
                                                color="text.primary"
                                                fontWeight={500}
                                                component="span"
                                            >
                                                La solicitud de tarjeta no fue aprobada.
                                            </Typography>
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="error.main"
                                            fontWeight={700}
                                            my="2px"
                                        >
                                            Cancelada:{' '}
                                            <Typography
                                                color="text.primary"
                                                fontWeight={500}
                                                component="span"
                                            >
                                                La tarjeta fue cancelada.
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                }
                            >
                                <IconBtn
                                    color="primary"
                                    sx={{
                                        mt: '-10px',
                                    }}
                                >
                                    <Info />
                                </IconBtn>
                            </Tooltip>
                        </Grid>
                    }
                >
                    <MenuItem value="">Mostrar todos</MenuItem>
                    {Object.entries(REQUESTED_CARD_STATUS).map(([status, text]) => (
                        <MenuItem key={status} value={status}>
                            {text}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
        </SearchFilters>
    );
};

export default CardFilters;
