import { FC, Fragment } from 'react';
import { Grid } from '@mui/material';
import { Typography } from '@fairplay2/ui';
import { formatAmount } from 'utils/parsing';
import { SHORT_READABLE_FORMAT, formatUtc } from 'utils/dates';
import { CardApproval } from '../interfaces';

const getApprovalTexts = (approval: CardApproval) => {
    if (approval.requested_type === 'create-card')
        return {
            description: [
                {
                    legend: 'solicitó una tarjeta Fairplay con un límite de crédito por',
                    detail: `${approval.credit_limit.currency.id}$ ${formatAmount(
                        approval.credit_limit.amount ?? 0,
                    )}`,
                },
            ],
            inviteToAction: '¿Quieres aprobarla?',
        };
    if (approval.requested_type === 'update-credit-limit')
        return {
            description: [
                {
                    legend: 'solicitó la modificación del límite de crédito de la tarjeta Fairplay',
                    card: approval.alias,
                    aditionalLegend: 'de',
                    detail: `MXN$ ${formatAmount(approval?.current_credit_limit ?? 0)}`,
                },
                {
                    legend: 'a',
                    detail: `${approval.credit_limit.currency.id}$ ${formatAmount(
                        approval.credit_limit.amount ?? 0,
                    )}`,
                },
            ],
            inviteToAction: '¿Quieres aprobar el nuevo límite de crédito?',
        };
    if (approval.requested_type === 'cancel-card')
        return {
            description: [
                {
                    legend: 'solicitó la cancelación de la tarjeta Fairplay',
                    detail: `${approval.alias}`,
                },
                {
                    legend: 'con límite de crédito',
                    detail: `MXN$ ${formatAmount(approval?.credit_limit.amount ?? 0)}`,
                },
            ],
            inviteToAction: '¿Quieres aprobar la cancelación?',
        };
};

const ApprovalContents: FC<{ approval: CardApproval }> = ({ approval }) => {
    const texts = getApprovalTexts(approval);

    return (
        <>
            <Typography variant="subtitle1" color="primary.main" fontWeight={700} mt="20px">
                {formatUtc(approval?.created_at || '', SHORT_READABLE_FORMAT, {
                    fallbackString: '',
                })}
            </Typography>
            <Grid container flexDirection="column">
                <Typography color="text.primary" variant="subtitle1" lineHeight={1.5} mt="15px">
                    <Typography
                        component="span"
                        variant="subtitle1"
                        color="primary.dark"
                        fontWeight={700}
                    >
                        {approval.requested_by.name}{' '}
                    </Typography>
                    {texts!.description.map(({ legend, detail, card, aditionalLegend }, i) => (
                        <Fragment key={legend}>
                            {i !== 0 && ' '}
                            {legend}{' '}
                            {!!card && !!aditionalLegend && (
                                <>
                                    <Typography
                                        component="span"
                                        color="primary.main"
                                        fontWeight={700}
                                    >
                                        {card}{' '}
                                    </Typography>
                                    {aditionalLegend}{' '}
                                </>
                            )}
                            <Typography component="span" color="primary.main" fontWeight={700}>
                                {detail}
                            </Typography>
                        </Fragment>
                    ))}
                </Typography>
                <Typography
                    color="primary.dark"
                    variant="subtitle2"
                    fontWeight={700}
                    mt="20px"
                    mb="5px"
                >
                    {texts!.inviteToAction}
                </Typography>
            </Grid>
        </>
    );
};

export default ApprovalContents;
