import { capitalize } from '@mui/material';
import { SemiPartial } from 'utils/interfaces';
import { ConnectorData, GroupedPlatformKey, RedirectionMetadata } from './interfaces';
import {
    ADS_PLATFORMS,
    ANALYTICS_PLATFORMS,
    FISCAL_PLATFORMS,
    ORDERS_PLATFORMS,
    PAYMENTS_PLATFORMS,
} from './platforms/constants';

export const dataSourceRedirection = {
    create(url: string, metadata: RedirectionMetadata) {
        sessionStorage.setItem(
            'com.getfairplay.insights.dataSourceRedirection',
            JSON.stringify(metadata),
        );
        window.location.assign(url);
    },
    confirmLocalRedirection() {
        const metadata = dataSourceRedirection.readMetadata();
        if (!metadata?.to) return;
        sessionStorage.setItem(
            'com.getfairplay.insights.dataSourceRedirection',
            JSON.stringify({ ...metadata, to: undefined }),
        );
    },
    readMetadata(): null | RedirectionMetadata {
        return JSON.parse(
            sessionStorage.getItem('com.getfairplay.insights.dataSourceRedirection') || 'null',
        );
    },
    complete() {
        sessionStorage.removeItem('com.getfairplay.insights.dataSourceRedirection');
    },
};

export const getPlatformDisplayName = (groupKey: GroupedPlatformKey | string): string => {
    switch (groupKey) {
        case 'woocommerce':
            return 'WooCommerce';
        case 'paypal':
            return 'PayPal';
        case 'sat':
            return 'SAT';
        case 's3':
            return 'S3 Bucket';
        default:
            return groupKey
                .replaceAll('-', ' ')
                .split(' ')
                .map((word) =>
                    word[0] === 'v' && !Number.isNaN(Number(word[1])) && word.length === 2
                        ? word
                        : capitalize(word),
                )
                .join(' ');
    }
};

export const getConnectorAlias = (connector: SemiPartial<ConnectorData, 'id'>): string => {
    if (!connector) return '';
    if (connector.alias) return connector.alias;
    if (connector.storeName) return connector.storeName;
    if (connector.bucket) return connector.bucket;
    if (connector.accountName) return connector.accountName;
    if (connector.storeUrl) return new URL(connector.storeUrl).hostname;
    if (connector.username) return connector.username;
    if (connector.user) return connector.user;
    if (connector.clientEmail) return connector.clientEmail;
    if (connector.email) return connector.email;
    if (connector.accountId && typeof connector.accountId === 'string') return connector.accountId;
    if (connector.selling_partner_id) return `${connector.selling_partner_id}`;
    if (connector.shop_id) return connector.shop_id;
    return connector.id;
};

export const getDataSourceDescription = (groupKey: GroupedPlatformKey) => {
    if (ORDERS_PLATFORMS.includes(groupKey as any)) return 'la fuente de órdenes';
    if (ADS_PLATFORMS.includes(groupKey as any)) return 'la fuente de datos de publicidad';
    if (ANALYTICS_PLATFORMS.includes(groupKey as any)) return 'la fuente de datos de analítica';
    if (FISCAL_PLATFORMS.includes(groupKey as any)) return 'la fuente de datos fiscales';
    if (PAYMENTS_PLATFORMS.includes(groupKey as any)) return 'el procesador de pagos';
    return groupKey;
};

// Get all the stores that has invalid status and its data can't be shown
export const getConnectorAliasWithInvalidStatus = (connectors: ConnectorData[]): string[] => {
    const errorStatuses = ['suspended', 'reauthorize', 'invalid'],
        storesWithErrors = connectors
            .filter((connector) => errorStatuses.includes(connector.status))
            .map(getConnectorAlias);
    return storesWithErrors;
};

export const ERROR_STATUS = ['suspended', 'reauthorize', 'invalid'];
