import img from 'assets/img/connectors/paypal.png';
import { PlatformItem } from '../interfaces';
import BasePlatformItem from '../components/BasePlatformItem';
import useDualForm from '../useDualForm';
import ConnectorForm from './ConnectorForm';

// eslint-disable-next-line react/prop-types
const Paypal: PlatformItem = ({ notify }) => {
    const { showForm, connector, onConnect, closeForm, onReauth } = useDualForm();

    return (
        <BasePlatformItem
            picture={img}
            groupKey="paypal"
            notify={notify}
            onConnect={onConnect}
            onReauth={onReauth}
        >
            <ConnectorForm open={showForm} onClose={closeForm} connector={connector} />
        </BasePlatformItem>
    );
};

export default Paypal;
