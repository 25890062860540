import { ChartFactory, Serie } from 'utils/formatting';
import { BaseState, Chart, Metric, PrivateAction } from './interfaces';

export const baseReducer = <ChartKeys extends string, MetricsKeys extends string | never = never>(
    state: BaseState<ChartKeys, MetricsKeys>,
    action: PrivateAction<ChartKeys, MetricsKeys>,
) => {
    switch (action.type) {
        case 'SET_METRIC':
            return (
                state.metrics
                    ? {
                          ...state,
                          metrics: {
                              ...state.metrics,
                              [action.payload.key]: {
                                  ...state.metrics[action.payload.key as MetricsKeys],
                                  ...action.payload.value,
                                  loading: false,
                              },
                          },
                      }
                    : state
            ) as BaseState<ChartKeys, MetricsKeys>;
        case 'SET_CHART':
            return {
                ...state,
                chartData: {
                    ...state.chartData,
                    [action.payload.key]: {
                        ...state.chartData[action.payload.key as ChartKeys],
                        isEmpty: action.payload.value.isEmpty,
                        loading: false,
                        options: {
                            ...state.chartData[action.payload.key as ChartKeys].options,
                            ...action.payload.value.options,
                        },
                    },
                },
            };
        case 'RESTART_LOADING': {
            const newState = {
                ...state,
                chartData: Object.fromEntries(
                    Object.entries(state.chartData).map(([key, chart]) => [
                        key,
                        {
                            ...(chart as Chart),
                            isEmpty: true,
                            loading: true,
                            options: ChartFactory({
                                ...(chart as Chart).options,
                                series: (chart as Chart).options.series.map((serie: Serie) => ({
                                    ...serie,
                                    data: [],
                                })),
                                ...((chart as Chart).options.xCategories && {
                                    xCategories: [],
                                }),
                            }),
                        },
                    ]),
                ),
            };

            return (
                state.metrics
                    ? {
                          ...newState,
                          metrics: Object.fromEntries(
                              Object.entries(state.metrics).map(([key, metric]) => [
                                  key,
                                  {
                                      ...(metric as Metric),
                                      loading: true,
                                      total: null,
                                  },
                              ]),
                          ),
                      }
                    : newState
            ) as BaseState<ChartKeys, MetricsKeys>;
        }
        default:
            return state;
    }
};
