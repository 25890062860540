import { FC, useState, useEffect } from 'react';
import { Grid, Fade } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { IconBtn, Typography } from '@fairplay2/ui';
import { WomanFoldersThreeSvg, WomanGearAvatar, SettingsManSvg } from 'assets/SVGs';
import { SlidersProps } from 'auth/interfaces';

const Sliders: FC<SlidersProps> = ({ information }) => {
    const [slider, setSlider] = useState(0),
        shouldAddLineBreak =
            information?.[slider]?.lineBreak === undefined ||
            information?.[slider]?.lineBreak === true;

    const onSlider = (sliderNum: number) => {
        if (sliderNum < 0) {
            setSlider(2);
        } else {
            setSlider(sliderNum % 3);
        }
    };

    useEffect(() => {
        const slidersTimer = setInterval(() => onSlider(slider + 1), 4000);

        return () => {
            clearInterval(slidersTimer);
        };
    }, [slider]);

    return (
        <Grid container mt="40px" padding="10px">
            {slider === 0 && (
                <Fade in={slider === 0} timeout={800}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="570px"
                    >
                        <WomanFoldersThreeSvg />
                        <Typography variant="h4" fontWeight={700} m="60px">
                            {information?.[slider]?.title ||
                                'Todos los insights que necesitas en un solo lugar'}
                            {shouldAddLineBreak && <br />}
                            <Typography component="span" color="primary.main">
                                {information?.[slider]?.description || '¡Comienza gratis!'}
                            </Typography>
                        </Typography>
                    </Grid>
                </Fade>
            )}
            {slider === 1 && (
                <Fade in={slider === 1} timeout={800}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="570px"
                    >
                        <WomanGearAvatar style={{ height: 358 }} />
                        <Typography variant="h4" fontWeight={700} m="60px">
                            {information?.[slider]?.title ||
                                'Configura tu perfil de usuario y también el de empresa'}
                            {shouldAddLineBreak && <br />}
                            <Typography component="span" color="primary.main">
                                {information?.[slider]?.description || '¡Es muy fácil!'}
                            </Typography>
                        </Typography>
                    </Grid>
                </Fade>
            )}
            {slider === 2 && (
                <Fade in={slider === 2} timeout={800}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="570px"
                    >
                        <SettingsManSvg />
                        <Typography variant="h4" fontWeight={700} m="60px">
                            {information?.[slider]?.title ||
                                'Conecta tus cuentas de Google y Facebook'}
                            {shouldAddLineBreak && <br />}
                            <Typography component="span" color="primary.main">
                                {information?.[slider]?.description ||
                                    '¡Todas tus métricas en un solo lugar!'}
                            </Typography>
                        </Typography>
                    </Grid>
                </Fade>
            )}
            <Grid container item xs={11} justifyContent="flex-end">
                <Grid
                    item
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    width="80px"
                >
                    <IconBtn
                        size="small"
                        onClick={() => onSlider(slider - 1)}
                        variant="filled"
                        color="primary"
                        sx={{ fontSize: 1.5 }}
                    >
                        <KeyboardArrowLeft />
                    </IconBtn>
                    <IconBtn
                        size="small"
                        onClick={() => onSlider(slider + 1)}
                        variant="filled"
                        color="primary"
                        sx={{ fontSize: 1.5 }}
                    >
                        <KeyboardArrowRight />
                    </IconBtn>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Sliders;
