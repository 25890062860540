import { FC } from 'react';
import { Grid, MenuItem } from '@mui/material';
import { DefaultInput, Select, Skeleton, Typography } from '@fairplay2/ui';
import { AddInternationalAccountProps } from '../interfaces';

const AddInternational: FC<AddInternationalAccountProps> = ({
    step,
    values,
    currencies,
    loading,
    errors,
    onChange,
    onSelect,
    onBlur,
}) => (
    <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
            <Grid item mb="4px" xs={12}>
                <Typography
                    component="label"
                    color="text.primary"
                    variant="body1"
                    fontWeight={500}
                    htmlFor="swift"
                >
                    Código SWIFT
                </Typography>
            </Grid>
            {loading ? (
                <Grid container item xs={12} justifyContent="center" alignItems="center">
                    <Skeleton height={53} width="100%" data-testid="swift-skeleton" />
                </Grid>
            ) : (
                <DefaultInput
                    id="swift"
                    labelVariant="none"
                    disabled={step === 'confirm'}
                    name="swift"
                    placeholder="11 dígitos"
                    value={values.swift}
                    onChange={onChange}
                    onBlur={() => onBlur('swift', 'Ingresa el swift')}
                    error={errors.swift}
                />
            )}
        </Grid>
        <Grid item xs={12} sm={6}>
            <Select
                id="vendor-currency"
                label="Divisa"
                labelVariant="external"
                ignoreDisabledStyles
                disabled={step === 'confirm' || !currencies.length || !values.swift}
                value={values.accountCurrency}
                onChange={onSelect}
                onBlur={() => onBlur('accountCurrency', 'Ingresa la divisa')}
                error={errors.accountCurrency}
            >
                <MenuItem value="" disabled>
                    Selecciona la divisa
                </MenuItem>
                {currencies.map((currency) => (
                    <MenuItem key={currency} value={currency}>
                        {currency}
                    </MenuItem>
                ))}
            </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
            <DefaultInput
                id="vendor-account"
                label="Número de cuenta"
                labelVariant="external"
                disabled={step === 'confirm'}
                name="accountNumber"
                placeholder="Puede ser alfanumérica"
                value={values.accountNumber}
                onChange={onChange}
                onBlur={() => onBlur('accountNumber', 'Ingresa el número de cuenta')}
                error={errors.accountNumber}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <DefaultInput
                id="vendor-bank"
                label="Banco"
                labelVariant="external"
                disabled
                name="bank"
                value={values.bank}
                placeholder="Entidad bancaria"
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <DefaultInput
                id="vendor-bank-country"
                label="País del banco"
                labelVariant="external"
                disabled
                name="country"
                value={values.country.name}
                error={errors.country}
                placeholder="País"
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <DefaultInput
                id="vendor-bank-city"
                label="Ciudad del banco"
                labelVariant="external"
                disabled
                name="state"
                value={values.state}
                placeholder="Ciudad"
            />
        </Grid>
    </Grid>
);

export default AddInternational;
