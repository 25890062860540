import {
    ChangeEvent,
    FC,
    lazy,
    MouseEvent,
    Suspense,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState,
} from 'react';
import { Collapse, Grid, useMediaQuery, useTheme } from '@mui/material';
import { Alert, Skeleton, Typography } from '@fairplay2/ui';
import sessionContext from 'context/session/sessionContext';
import { Company } from 'context/session/interfaces';
import { getActiveFilters } from 'common-components';
import { useAlert } from 'utils/hooks';
import { format, localFormat, SHORT_READABLE_FORMAT, TIMELESS_ISO_FORMAT } from 'utils/dates';
import { useDataSources } from '../../../data-sources/context/DataSourcesContext';
import {
    convertSvgToPngURL,
    createGlobalData,
    getEffectiveMultipleSelection,
    getStoresDic,
} from '../../utils/utils';
import { MONTHS_DIC, TODAY_DATE, TODAY_MINUS_90 } from '../../utils/constants';
import { SalesInitialState, salesReducer, getMainChartData } from '../salesReducer';
import { SummariesKeys } from '../interfaces';
import Filters from './Filters';
import CardTab from './CardTab';
import MainChart from './MainChart';
import {
    AccumulatedFooter,
    AccumulatedHeader,
    AccumulatedSkeleton,
    TotalsByChannel,
    TotalsByConnectorTable,
} from './accumulated';
import AddConnectionModal from './AddConnectionModal';
import AlmostReadyConnectionModal from './AlmostReadyConnectionModal';
import PendingConnectionModal from './PendingConnectionModal';
import ErrorConnectionModal from './ErrorConnectionModal';
import { salesFetchChannelsData, salesFetchData, salesFetchPeriodsData } from './api';
import EmptyState from './EmptyState';
import { FilterValuesObj, HeaderActionButtonsProps } from './interfaces';

const HeaderActionButtons = lazy(
    () => import(/* webpackChunkName: 'ActionButtons' */ './HeaderActionButtons'),
);

const getFiltersValuesSelected = (filterValues: FilterValuesObj) => {
    const selectedDay = filterValues.selectedDayRange,
        monthsValue = filterValues.month.filter((month) => month !== 'all'),
        storesValue = filterValues.store.filter((store) => store !== 'all'),
        dateFilters = {
            start_date: localFormat(selectedDay?.from || '', TIMELESS_ISO_FORMAT, {
                fallbackString: '',
            }),
            end_date: localFormat(selectedDay?.to || '', TIMELESS_ISO_FORMAT, {
                fallbackString: '',
            }),
        },
        isMonthFilterSelected = filterValues.month[0] !== '';

    return { monthsValue, storesValue, dateFilters, isMonthFilterSelected };
};

const getFiltersAndPayload = (
    filterValues: FilterValuesObj,
    company: Company | undefined,
    pagination?: { page: number; pageSize: number },
) => {
    const local = getFiltersValuesSelected(filterValues);

    return {
        local,
        payload: createGlobalData({
            isMonthFilterSelected: local.isMonthFilterSelected,
            selectedCompany: company?.id || '',
            connectorIds: local.storesValue,
            dateStart: local.dateFilters.start_date,
            dateEnd: local.dateFilters.end_date,
            status: filterValues.status[0],
            years: [Number(filterValues.year[0])],
            months: local.monthsValue,
            ...pagination,
        }),
    };
};

const Sales: FC = () => {
    const { selectedCompany, user } = useContext(sessionContext),
        { platforms } = useDataSources(),
        [
            {
                activeFilters,
                allowSearch,
                channels,
                externalFilters,
                filteredValues,
                filterValues,
                isFilterOff,
                isOpenAddConnectionModal,
                loading,
                mainChartData,
                lastMainChartUpdate,
                periods,
                stores,
                tabsData,
                tabValue,
                connectedStores,
                informationModal,
            },
            dispatch,
        ] = useReducer(salesReducer, SalesInitialState),
        // `chartRef` tiped as any to avoid importing HighchartsReact
        mainChartRef = useRef<any>(null),
        pieChartRef = useRef<any>(null),
        [emptyState, setEmptyState] = useState(false),
        theme = useTheme(),
        mdDown = useMediaQuery(theme.breakpoints.down('md')),
        { alert, showAlert, hideAlert } = useAlert(),
        { alert: infoAlert, showAlert: showInfoAlert } = useAlert();

    const connectorsStores = useMemo(() => getStoresDic(platforms), [platforms]);

    const onChangePeriod = () => {
        dispatch({
            type: 'RESET_FILTER_VALUES',
        });
    };

    const toggleAddConnectionModal = () => dispatch({ type: 'TOGGLE_ADD_CONNECTION_MODAL' });

    const setRequestParams = (paramsQuery: string, key: 'channels' | 'periods') => {
        dispatch({
            type: 'SET_REQUEST_PARAMS',
            payload: {
                key,
                value: paramsQuery,
            },
        });
    };

    const onChangePage = (
        event: MouseEvent<HTMLButtonElement> | null,
        newPage: number,
        key: 'channels' | 'periods',
    ) => {
        dispatch({
            type: 'SET_PAGE',
            payload: {
                key,
                value: newPage,
            },
        });
    };

    const onChangeRowsPerPage = (
        event: ChangeEvent<HTMLInputElement>,
        key: 'channels' | 'periods',
    ) => {
        dispatch({
            type: 'SET_ROWS_PER_PAGE',
            payload: {
                key,
                value: Number(event.target.value),
            },
        });
    };

    const setTabValue = (value: SummariesKeys) => {
        dispatch({
            type: 'SET_TAB_VALUE',
            payload: value,
        });
    };

    const onSelectedDay = (range: typeof filterValues['selectedDayRange']) =>
        dispatch({
            type: 'SET_FILTER_VALUES',
            payload: {
                ...filterValues,
                selectedDayRange: range,
            },
        });

    const onSelectChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value, name },
        } = event;

        dispatch({
            type: 'SET_FILTER_VALUES',
            payload: {
                ...filterValues,
                [name]: [value],
            },
        });
    };

    const onMultipleSelectChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value, name },
        } = event;

        dispatch({
            type: 'SET_FILTER_VALUES',
            payload: {
                ...filterValues,
                [name as 'store' | 'month']: getEffectiveMultipleSelection({
                    currentValue: filterValues[name as 'store' | 'month'],
                    dictionary: name === 'store' ? stores : MONTHS_DIC,
                    newValue: value as unknown as string[],
                }),
            },
        });
    };

    const onFilterSearch = async () => {
        const {
            local: { dateFilters, monthsValue, storesValue },
            payload: globalData,
        } = getFiltersAndPayload(filterValues, selectedCompany.company);

        dispatch({
            type: 'SET_ACTIVE_FILTERS',
            payload: getActiveFilters({
                date: dateFilters,
                status: filterValues.status,
                year: filterValues.year,
                month: monthsValue,
                store: storesValue,
            }),
        });
        dispatch({
            type: 'SET_FILTERED_VALUES',
            payload: filterValues,
        });
        salesFetchData({
            dispatch,
            filterValues,
            globalData,
            hideAlert,
            showAlert,
            stores,
        });
    };

    const resetFilterValues = () => {
        dispatch({ type: 'RESET_FILTER_VALUES' });
    };

    const getPdfData: HeaderActionButtonsProps['getPdfData'] = async () => {
        const selectedMonths = filteredValues?.month
                .filter((value) => value !== 'all' && value !== '')
                .map((key) => MONTHS_DIC[key as 'january']),
            [mainChartImage, pieChartImage] = await Promise.all([
                convertSvgToPngURL(mainChartRef),
                convertSvgToPngURL(pieChartRef),
            ]);

        return {
            mainChartImage,
            pieChartImage,
            user: `${user!.firstName} ${user!.lastName}`.trim(),
            client: selectedCompany.company!.businessName,
            appliedFilters: {
                dateRange: selectedMonths?.length ? undefined : filteredValues?.selectedDayRange,
                year: selectedMonths?.length ? Number(filteredValues?.year[0]) : undefined,
                months: selectedMonths?.length ? selectedMonths : undefined,
                stores: connectedStores
                    .filter(({ id }) => filteredValues?.store.includes(id))
                    .map(({ alias, id, platform }) => ({ alias, id, platform })),
                status: filteredValues?.status || [],
            },
            graphTabsData: tabsData,
            lastUpdated: lastMainChartUpdate!,
            selectedTab: tabValue,
            totalsByChannelData: channels.tableData.map(({ id, name, totals }: any) => ({
                id,
                platform: name,
                periodGrowth: totals[tabValue].periodPercentage,
                previousPeriodgrowth: totals[tabValue].prevPeriodPercentage,
                growth: totals[tabValue].growthPercentage,
            })),
            totalsByPeriodData: periods.tableData.map(
                ({ id, alias, summaries, fss, status }: any) => ({
                    id,
                    store: alias,
                    fss,
                    status,
                    valid: summaries[tabValue].validTotal,
                    invalid: summaries[tabValue].invalidTotal,
                    returns: summaries[tabValue].returnedTotal,
                    total: summaries[tabValue].total,
                }),
            ),
        };
    };

    const getXlsxPayload = () =>
        getFiltersAndPayload(filteredValues!, selectedCompany.company).payload!;

    // Initial fetch
    useEffect(() => {
        if (
            !selectedCompany.company?.id ||
            Object.values(platforms).every((value) => value === undefined)
        )
            return;
        if (Object.keys(connectorsStores).length <= 0) setEmptyState(true);
        const { payload: globalData } = getFiltersAndPayload(
            {
                selectedDayRange: { from: TODAY_MINUS_90, to: TODAY_DATE },
                year: [],
                month: [''],
                store: [Object.keys(connectorsStores)[0]],
                status: ['valid'],
            },
            selectedCompany.company,
        );
        salesFetchData({
            dispatch,
            globalData,
            filterValues,
            hideAlert,
            showAlert,
            stores: connectorsStores,
            needsToReset: true,
        });
        showInfoAlert('YTD. Ventas válidas de los últimos 90 días.', 'info');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany.company?.id, platforms]);

    useEffect(() => {
        if (channels.requestParams === undefined || channels.requestParams.length === 0) return;

        const requestParamsObj = Object.fromEntries(
                new URLSearchParams(channels.requestParams.substring(1)),
            ),
            { payload: globalData } = getFiltersAndPayload(filterValues, selectedCompany.company, {
                page: Number(requestParamsObj.page),
                pageSize: Number(requestParamsObj.page_size),
            });

        salesFetchChannelsData({
            dispatch,
            globalData,
            showAlert,
            hideAlert,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [channels.requestParams]);

    useEffect(() => {
        if (periods.requestParams === undefined || periods.requestParams.length === 0) return;

        const requestParamsObj = Object.fromEntries(
                new URLSearchParams(periods.requestParams.substring(1)),
            ),
            { payload: globalData } = getFiltersAndPayload(filterValues, selectedCompany.company, {
                page: Number(requestParamsObj.page),
                pageSize: Number(requestParamsObj.page_size),
            });

        salesFetchPeriodsData({
            dispatch,
            globalData,
            showAlert,
            hideAlert,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [periods.requestParams]);

    if (emptyState) return <EmptyState />;

    return (
        <>
            <AddConnectionModal
                onCancel={toggleAddConnectionModal}
                open={isOpenAddConnectionModal}
            />
            <ErrorConnectionModal
                open={informationModal.type === 'error'}
                onCancel={() => dispatch({ type: 'HIDE_INFORMATION_MODAL' })}
                storeNames={informationModal.affectedStores}
            />
            <PendingConnectionModal
                open={informationModal.type === 'pending'}
                onConfirm={() => dispatch({ type: 'HIDE_INFORMATION_MODAL' })}
                storeNames={informationModal.affectedStores}
            />
            <AlmostReadyConnectionModal
                open={informationModal.type === 'almostReady'}
                onConfirm={() => dispatch({ type: 'HIDE_INFORMATION_MODAL' })}
                storeNames={informationModal.affectedStores}
            />
            <Suspense
                fallback={
                    <Grid item container gap={1} justifyContent="end">
                        <Skeleton variant="rectangular" sx={{ width: { xs: 20, lg: 150 } }} />
                        <Skeleton variant="rectangular" sx={{ width: { xs: 20, lg: 150 } }} />
                    </Grid>
                }
            >
                <HeaderActionButtons
                    onAddStore={toggleAddConnectionModal}
                    getPdfData={getPdfData}
                    getXlsxPayload={getXlsxPayload}
                    disableDownloads={loading || !allowSearch || !!tabsData.average.emptyData}
                />
            </Suspense>

            <Grid container justifyContent="end">
                <Collapse in={infoAlert.open}>
                    <Alert severity={infoAlert.type} sx={{ mt: '10px' }}>
                        {infoAlert.message}
                    </Alert>
                </Collapse>
            </Grid>

            <Grid container mb="30px" alignItems="center" rowSpacing={1}>
                <Grid item xs={12} lg={4}>
                    <Typography
                        variant="body1"
                        fontWeight={500}
                        fontSize="18px"
                        color="primary.main"
                        mb="4px"
                    >
                        Analiza el desempeño de tus ventas
                    </Typography>
                    <Typography
                        variant="body1"
                        fontSize="16px"
                        fontWeight={500}
                        color="text.primary"
                    >
                        Selecciona el periodo que deseas consultar:
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <Collapse in={alert.open}>
                        <Alert severity={alert.type} sx={{ width: 'fit-content' }}>
                            {alert.message}
                        </Alert>
                    </Collapse>
                </Grid>
            </Grid>

            <Filters
                activeFilters={activeFilters}
                allowSearch={allowSearch}
                filterValues={filterValues}
                isFilterOff={isFilterOff}
                onChangePeriod={onChangePeriod}
                onFilterSearch={onFilterSearch}
                onMultipleSelectChange={onMultipleSelectChange}
                onSelectChange={onSelectChange}
                onSelectedDay={onSelectedDay}
                resetFilterValues={resetFilterValues}
                stores={stores}
            />

            <Grid container wrap={mdDown ? 'wrap' : 'nowrap'} gap={2}>
                {Object.entries(tabsData).map(([key, value]) => (
                    <CardTab
                        key={key}
                        isActive={tabValue === key}
                        loading={loading}
                        {...value}
                        {...(!value.emptyData && {
                            onClick: () => setTabValue(key as SummariesKeys),
                        })}
                    />
                ))}
            </Grid>

            <Grid container>
                <MainChart
                    ref={mainChartRef}
                    chartOptions={getMainChartData(
                        mainChartData[tabValue].data,
                        mainChartData[tabValue].title,
                        mainChartData[tabValue].valuePrefix,
                    )}
                    lastUpdate={lastMainChartUpdate}
                    isEmpty={mainChartData[tabValue].isEmpty}
                    loading={loading}
                    tabValue={tabValue}
                />
            </Grid>

            {loading ? (
                <AccumulatedSkeleton />
            ) : (
                <Grid container mt="10px">
                    <AccumulatedHeader
                        category={tabsData[tabValue].title.toLowerCase()}
                        period={
                            activeFilters
                                ? filteredValues?.year[0] ||
                                  `${format(
                                      filteredValues?.selectedDayRange?.from,
                                      SHORT_READABLE_FORMAT,
                                  )} - ${format(
                                      filteredValues?.selectedDayRange?.to,
                                      SHORT_READABLE_FORMAT,
                                  )}`
                                : 'YTD'
                        }
                        isEmpty={tabsData[tabValue].emptyData}
                        months={
                            activeFilters
                                ? filteredValues?.month.filter((month) => month !== '')
                                : []
                        }
                    />
                    <TotalsByChannel
                        ref={pieChartRef}
                        tabValue={tabValue}
                        count={channels.count}
                        // TODO: Reconcile pieChartData & tableData in a single variable, it seems its the exact same data
                        data={channels.pieChartData}
                        tableData={channels.tableData}
                        externalFilters={externalFilters}
                        loading={channels.loading}
                        onChangePage={(event, newPage) => onChangePage(event, newPage, 'channels')}
                        onChangeRowsPerPage={(event) => onChangeRowsPerPage(event, 'channels')}
                        page={channels.page}
                        rowsPerPage={channels.rowsPerPage}
                        setRequestParams={(paramsQuery: string) =>
                            setRequestParams(paramsQuery, 'channels')
                        }
                    />
                    <TotalsByConnectorTable
                        connectorsData={periods.tableData}
                        count={periods.count}
                        externalFilters={externalFilters}
                        loading={periods.loading}
                        onAliasEdited={onFilterSearch}
                        onChangePage={(event, newPage) => onChangePage(event, newPage, 'periods')}
                        onChangeRowsPerPage={(event) => onChangeRowsPerPage(event, 'periods')}
                        page={periods.page}
                        rowsPerPage={periods.rowsPerPage}
                        setRequestParams={(paramsQuery: string) =>
                            setRequestParams(paramsQuery, 'periods')
                        }
                        summaries={periods.summary}
                        tabValue={tabValue}
                    />
                    <AccumulatedFooter onAddStore={toggleAddConnectionModal} />
                </Grid>
            )}
        </>
    );
};

export default Sales;
