import { lighten, styled } from '@mui/material/styles';
import { TreeItem } from '@mui/x-tree-view';

export const DefaultTreeItem = styled(TreeItem)(({ theme }) => ({
    '& .MuiTreeItem-content': {
        padding: '4px 5px',
        '&.Mui-focused, &:focus, &.Mui-selected, &:hover': {
            backgroundColor: lighten(theme.palette.secondary.light, 0.8),
        },
        '&.Mui-selected.Mui-focused, &.Mui-selected:hover': {
            backgroundColor: `#ecf4fb`,
        },
        '& .MuiTreeItem-label': {
            color: theme.palette.text.primary,
            fontSize: 16,
            lineHeight: 1.1875,
            paddingLeft: 0,
            borderRadius: 3,
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
    },
    '& .MuiTreeItem-iconContainer': {
        color: theme.palette.secondary.main,
        width: 16,
        justifyContent: 'flex-start',
        '& svg': {
            fontSize: 18,
        },
    },
    '& .MuiTreeItem-group': {
        marginLeft: 32,
    },
}));
