import { useHistory } from 'react-router-dom';
import { FC, useContext, useState } from 'react';
import { Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import {
    FilePicker,
    PaperButton,
    Typography,
    Notification,
    Loader,
    Tooltip,
    IconBtn,
    DefaultBtn,
} from '@fairplay2/ui';
import {
    Language,
    LocationOn,
    AttachFile,
    Info,
    WarningAmber,
    CreditCard,
} from '@mui/icons-material/';
import { LeavesManSvg } from 'assets/SVGs';
import SessionContext from 'context/session/sessionContext';
import { useDisbursementValuesContext } from 'context/disbursements/DisbursementValuesContext';
import { downloadFile, validateFileSize } from 'utils/files';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { addGoogleTag } from 'utils/google-tag-manager';
import { useFile } from '../../context/file-context';
import { DisbursementOptionsProps } from '../../interfaces';
import DisbursementOptionsSkeleton from './DisbursementOptionsSkeleton';
import PendingDisbModal from './PendingDisbModal';

const DisbNotification = styled(Notification)(({ theme }) => ({
    position: 'absolute !important' as 'absolute',
    zIndex: 1,
    minWidth: 300,
    whiteSpace: 'nowrap',
    left: 19,
    top: -19,
    '.MuiButtonBase-root': {
        backgroundColor: theme.palette.primary.A400,
    },
    [theme.breakpoints.down('sm')]: {
        minWidth: 0,
        '.MuiAlert-root': {
            marginTop: 40,
            paddingLeft: theme.spacing(1.5),
            marginLeft: -38,
        },
    },
}));

const DisbursementOptions: FC<DisbursementOptionsProps> = ({
    hideAlert,
    showAlert,
    selectedContract,
    loading,
    canContractDisburse,
}) => {
    const theme = useTheme(),
        mdUp = useMediaQuery(theme.breakpoints.up('md')),
        history = useHistory(),
        { selectedCompany } = useContext(SessionContext),
        { disbursementLocation, setDisbursementLocation, disbursementCompanyId } =
            useDisbursementValuesContext(),
        isCompanyPendingCart = disbursementCompanyId === selectedCompany.company?.id,
        [downloadable, setDownloadable] = useState(true),
        [isPendingModalOpen, setIsPendingModalOpen] = useState(false),
        { dispatch } = useFile();

    const onNewDisbursement = (location: 'Nacional' | 'Internacional') => {
        if (disbursementLocation) return setIsPendingModalOpen(true);
        setDisbursementLocation(location);
        history.push('/app/disbursements/transactions/create');
        addGoogleTag('disbursement-new');
    };

    const getDisbursementTemplate = async () => {
        hideAlert();
        if (downloadable) {
            setDownloadable(false);
            try {
                const url = `/v2/companies/${selectedCompany.company?.id}/contracts/${selectedContract?.id}/dispersions/template`;
                await downloadFile('application/vnd.ms-excel', `template_dispersiones.csv`, url);
                setDownloadable(true);
            } catch (errResponse: ErrorObj | any) {
                setDownloadable(true);
                showAlert(
                    errResponse?.error ||
                        'Hubo un error al descargar el archivo, intente más tarde',
                );
            }
        }
    };

    const onFileChange = (file: File | null) => {
        hideAlert();
        addGoogleTag('disbursement-archive-start');

        if (!file) return;

        const fileError = validateFileSize(file, 5, 'El tamaño máximo de archivo es 5MB');
        showAlert(fileError);

        if (!fileError) {
            dispatch({ type: 'upload', payload: file });
            history.push('/app/disbursements/transactions/create/file');
        }
    };

    return (
        <Grid container>
            <PendingDisbModal
                open={isPendingModalOpen}
                onClose={() => setIsPendingModalOpen(false)}
            />
            {/* eslint-disable-next-line no-nested-ternary */}
            {loading ? (
                <DisbursementOptionsSkeleton />
            ) : canContractDisburse ? (
                <Grid data-testid="disbursement-options">
                    <Grid item xs={12} mt="35px" textAlign="left">
                        <Typography variant="subtitle2" color="primary.main">
                            Elige el tipo de dispersión que necesitas
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        container
                        xs={12}
                        justifyContent={{ xs: 'center', md: 'flex-start' }}
                        alignItems={{ xs: 'center', md: 'flex-start' }}
                        flexDirection={{ xs: 'column', md: 'row' }}
                        mt="25px"
                    >
                        <Grid item position="relative">
                            {isCompanyPendingCart && disbursementLocation === 'Nacional' && (
                                <DisbNotification
                                    icon={<WarningAmber />}
                                    animation="none"
                                    severity="primary"
                                    variant="outlined"
                                    onClick={() => onNewDisbursement('Nacional')}
                                >
                                    Tienes una solicitud pendiente
                                </DisbNotification>
                            )}
                            <PaperButton
                                iconAnimation
                                icon={<LocationOn />}
                                onClick={() => onNewDisbursement('Nacional')}
                                variant="elevation"
                                sx={{
                                    margin: { xs: '0 0 20px 0', md: '0 20px 20px 0' },
                                    width: '214px',
                                    height: '220px',
                                    bgcolor: 'background.secondary',
                                }}
                            >
                                <Grid container alignItems="flex-end" height="100%" padding="20px">
                                    <Grid item>
                                        <Typography
                                            variant="subtitle1"
                                            color="primary.main"
                                            fontWeight={700}
                                            lineHeight={1.1}
                                            mb="5px"
                                        >
                                            Dispersión Nacional
                                        </Typography>
                                        <Typography variant="body1" color="text.primary">
                                            Institución bancaria dentro de México
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </PaperButton>
                        </Grid>

                        <Grid item position="relative">
                            {isCompanyPendingCart && disbursementLocation === 'Internacional' && (
                                <DisbNotification
                                    icon={<WarningAmber />}
                                    animation="none"
                                    severity="primary"
                                    variant="outlined"
                                    onClick={() => onNewDisbursement('Internacional')}
                                >
                                    Tienes una solicitud pendiente
                                </DisbNotification>
                            )}
                            <PaperButton
                                iconAnimation
                                icon={<Language />}
                                onClick={() => onNewDisbursement('Internacional')}
                                variant="elevation"
                                sx={{
                                    margin: { xs: '0 0 20px 0', md: '0 20px 20px 0' },
                                    width: '214px',
                                    height: '220px',
                                    bgcolor: 'background.secondary',
                                }}
                            >
                                <Grid container alignItems="flex-end" height="100%" padding="20px">
                                    <Grid item>
                                        <Typography
                                            variant="subtitle1"
                                            color="primary.main"
                                            fontWeight={700}
                                            lineHeight={1.1}
                                            mb="5px"
                                        >
                                            Dispersión Internacional
                                        </Typography>
                                        <Typography variant="body1" color="text.primary">
                                            Institución bancaria fuera de México
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </PaperButton>
                        </Grid>

                        <Grid
                            container
                            width={305}
                            wrap="nowrap"
                            justifyContent={{ xs: 'center', md: 'flex-start' }}
                        >
                            <FilePicker
                                iconAnimation
                                accept=".csv"
                                onFileChange={onFileChange}
                                paperVariant="elevation"
                                Instructions={
                                    <>
                                        <Tooltip
                                            color="paper"
                                            title="Solicita dispersiones nacionales o internacionales a través de la plantilla csv de Fairplay."
                                            placement="top-start"
                                            overrideStyles={{
                                                maxWidth: 135,
                                            }}
                                        >
                                            <IconBtn color="info" sx={{ p: '0 0 5px !important' }}>
                                                <Info
                                                    sx={{
                                                        fontSize: '16px',
                                                    }}
                                                />
                                            </IconBtn>
                                        </Tooltip>
                                        <br />
                                        Carga un archivo de dispersiones
                                    </>
                                }
                                icon={
                                    downloadable ? (
                                        <AttachFile />
                                    ) : (
                                        <Loader size={40} data-testid="loader-multiDisb-template" />
                                    )
                                }
                                AdditionalHelp={
                                    <DefaultBtn
                                        variant="text"
                                        onClick={getDisbursementTemplate}
                                        sx={{
                                            p: '0 !important',
                                            textAlign: 'left',
                                            mt: '-5px',
                                            lineHeight: 1.5,
                                            textTransform: 'none !important',
                                        }}
                                    >
                                        Descarga la plantilla csv de Fairplay aquí
                                    </DefaultBtn>
                                }
                                sx={{
                                    margin: 0,
                                    borderRadius: '20px',
                                    width: '214px',
                                    height: '220px',
                                    '.MuiPaper-root': {
                                        bgcolor: 'background.secondary',
                                    },
                                }}
                            />
                            {mdUp && (
                                <Grid item>
                                    <LeavesManSvg
                                        style={{
                                            zIndex: -1,
                                            position: 'relative',
                                            top: 12,
                                            right: 9,
                                        }}
                                    />
                                </Grid>
                            )}
                        </Grid>

                        {selectedContract?.enableCardsModule && (
                            <Grid
                                width={270}
                                direction="column"
                                alignItems={{ xs: 'center', md: 'flex-start ' }}
                                alignSelf="flex-end"
                                item
                                container
                                sx={{
                                    alignSelf: { xs: 'center', md: 'flex-end' },
                                    mt: { xs: '30px', md: 0 },
                                    mb: { xs: 0, md: '20px' },
                                }}
                            >
                                <DefaultBtn
                                    variant="text"
                                    startIcon={<CreditCard />}
                                    sx={{
                                        textTransform: 'none !important',
                                        flex: 1,
                                        p: '0px',
                                    }}
                                    onClick={() => history.push('/app/disbursements/cards')}
                                >
                                    ¿Quieres una Tarjeta Fairplay?
                                </DefaultBtn>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            ) : null}
        </Grid>
    );
};

export default DisbursementOptions;
