import { FC, useEffect, useRef, useState } from 'react';
import { DialogTitle, Grid } from '@mui/material';
import { DefaultBtn, Modal, Typography } from '@fairplay2/ui';
import { ManSittingPlanSvg } from 'assets/SVGs';
import { MigrateModalProps } from '../interfaces';
import S3Disclaimer from '../../platforms/s3/components/S3Disclaimer';
import S3Wizard from '../../platforms/s3/components/S3Wizard';
import { MODAL_TITLE_DIC } from '../../platforms/s3/components/HybridWizardModal';
import { useDataSources } from '../../context/DataSourcesContext';

const MigrateModal: FC<MigrateModalProps> = ({ closeModal, open }) => {
    const {
            dispatch,
            platforms: { s3 },
        } = useDataSources(),
        connectorToMigrate = s3?.connectors?.find?.(
            (connector) => connector.platform === 's3-manual-upload',
        ),
        [step, setStep] = useState(0),
        completedFlag = useRef(false),
        [awaitingOperation, setAwaitingOperation] = useState(false);

    const onClose = () => {
        if (completedFlag.current && connectorToMigrate)
            dispatch({
                type: 'REMOVE',
                payload: connectorToMigrate,
            });
        closeModal();
    };

    useEffect(() => {
        if (!open) return;
        setStep(0);
        completedFlag.current = false;
    }, [open]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            disableBackdropClick
            disableEscapeKeyDown
            closeIconButton={!awaitingOperation}
            maxWidth={753}
            title={step === 2 ? MODAL_TITLE_DIC.s3 : undefined}
            sx={{
                '.MuiDialogContent-root': step === 2 ? { paddingTop: 0 } : {},
            }}
        >
            {step === 0 && (
                <Grid container justifyContent="center">
                    <Grid container justifyContent="center">
                        <ManSittingPlanSvg width={151} height={118} />
                    </Grid>
                    <Typography
                        component={DialogTitle}
                        variant="subtitle1"
                        color="primary.main"
                        mt="30px"
                        width="100%"
                        lineHeight={1.1875}
                        padding={0}
                    >
                        Cambia de Carga Manual a S3 Bucket de Amazon
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        color="text.primary"
                        mt="15px"
                        mb="14px"
                        width="100%"
                        lineHeight={1.1875}
                    >
                        Si cuentas con un S3 Bucket puedes darnos acceso y así facilitar tu conexión
                        ya que no tendrás que subir archivos periódicamente.
                    </Typography>
                    <Grid
                        container
                        rowSpacing={2}
                        columnSpacing={[0, 3]}
                        width={[undefined, 'auto']}
                        ml="auto"
                        mt={0}
                        mb={2}
                        justifyContent="center"
                    >
                        <Grid item xs={12} sm="auto">
                            <DefaultBtn
                                size="small"
                                fullWidth
                                variant="outlined"
                                onClick={closeModal}
                            >
                                Cancelar
                            </DefaultBtn>
                        </Grid>
                        <Grid item xs={12} sm="auto">
                            <DefaultBtn size="small" fullWidth onClick={() => setStep(1)}>
                                Cambiar a S3 Bucket
                            </DefaultBtn>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {step === 1 && <S3Disclaimer onClose={onClose} onConfirm={() => setStep(2)} />}
            {step === 2 && (
                <S3Wizard
                    setAwaitingOperation={setAwaitingOperation}
                    awaitingOperation={awaitingOperation}
                    onClose={onClose}
                    onSuccess={() => {
                        completedFlag.current = true;
                    }}
                    element={null}
                />
            )}
        </Modal>
    );
};

export default MigrateModal;
