import { FC, Fragment, useEffect, useState } from 'react';
import { Grid, List, ListItem } from '@mui/material';
import { DefaultBtn, Modal, InterpunctLoader, Typography, Divider } from '@fairplay2/ui';
import { Status, StatusType } from 'common-components';
import { CancelOutlined } from '@mui/icons-material';
import { format, TIME_READABLE_FORMAT } from 'utils/dates';
import { LogsModalProps, Logs, LogsModalError } from '../interfaces';

const LogsModal: FC<LogsModalProps> = ({ open, closeModal, order }) => {
    const [loading, setLoading] = useState(true),
        [errors, setErrors] = useState<LogsModalError[]>([]);

    const proccessErrors = (logs: Logs | undefined) => {
        const errorMsgs: LogsModalError[] = [];
        if (logs) {
            if (logs.disrupted && logs.disrupted.length > 0) {
                logs.disrupted.forEach((element: string) => {
                    let errorMsg = element;
                    if (element.includes('The Month and the day are swaped in some dates'))
                        errorMsg =
                            'El archivo tiene errores en las fechas, verifica que no existan fechas futuras, es decir que el formato utilizado sea ISO8601 (e.g. YYYY-MM-DD). Todas las fechas son obligatorias ';
                    else if (element.includes('The data is empty'))
                        errorMsg =
                            'Ocurrió un error al momento de obtener la data del archivo y no se pudo procesar la información';
                    else if (element.includes('There was an error trying to read the file'))
                        errorMsg = 'Ocurrió un error tratando de leer el archivo';
                    errorMsgs.push({
                        msg: errorMsg,
                    });
                });
            }

            if (logs.errors && Object.keys(logs.errors).length !== 0) {
                const mapping: any = {
                    nan_date_error: 'Error: fecha nula',
                    order_id_error: 'Error: el id de la orden es inválido',
                    wrong_value_error: 'Error: el valor es inválido',
                    currency_value_error: 'Error: el tipo de moneda es inválido',
                };

                // eslint-disable-next-line no-restricted-syntax
                for (const [key, value] of Object.entries(logs.errors)) {
                    const values: any = value;
                    if (values) {
                        const feedback = {
                            msg: '',
                            samples: [],
                            errorType: '',
                            errorText: '',
                        };
                        if (values.quantity > 0) {
                            // map variable with definition in spanish
                            let text = mapping[key];
                            text += `. Total de ordenes afectadas: ${values.quantity}`;
                            feedback.msg = text;
                            feedback.errorType = key;
                            if (values.samples.length > 0) feedback.samples = values.samples;

                            errorMsgs.push(feedback);
                        }
                    }
                }
            }

            setErrors(errorMsgs);
        }
        setLoading(false);
    };

    useEffect(() => {
        setErrors([]);
        setLoading(true);
        // process all logs to show to the user
        proccessErrors(order?.logs);
    }, [order]);

    return (
        <Modal
            aria-labelledby="logs-modal-title"
            open={open}
            onClose={closeModal}
            maxWidth={530}
            title="Datos de carga"
            actions={
                <Grid container justifyContent="flex-end">
                    <DefaultBtn size="small" onClick={closeModal}>
                        Cerrar
                    </DefaultBtn>
                </Grid>
            }
            sx={{
                '.MuiDialogContent-root': { paddingTop: 0 },
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <Typography
                        id="order-submitter"
                        variant="subtitle2"
                        color="text.primary"
                        fontWeight={500}
                    >
                        Subido por:{' '}
                        <Typography
                            role="textbox"
                            aria-labelledby="order-submitter"
                            color="primary.main"
                            fontWeight={400}
                        >
                            {order?.uploaderBy.fullName}
                        </Typography>
                    </Typography>
                    <Typography
                        id="upload-date"
                        variant="subtitle2"
                        color="text.primary"
                        fontWeight={500}
                        mt="10px"
                    >
                        Fecha:{' '}
                        <Typography
                            role="textbox"
                            aria-labelledby="upload-date"
                            color="primary.main"
                            fontWeight={400}
                        >
                            {format(order?.uploadedDate || '', TIME_READABLE_FORMAT)}
                        </Typography>
                    </Typography>
                    <Status
                        variant="outlined"
                        status={order?.status?.id?.toString() as StatusType}
                        sx={{
                            mt: '10px',
                        }}
                    >
                        {order?.status.text}
                    </Status>
                </Grid>

                <Grid item xs={12} sm={5} mt="15px">
                    <Divider sx={{ height: '5px' }} />
                </Grid>

                {loading ? (
                    <Grid item xs={12} mt="20px">
                        <InterpunctLoader />
                    </Grid>
                ) : (
                    <Grid item xs={12} mt="20px">
                        {errors?.length > 0 &&
                            errors.map((element, i: number) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Fragment key={i}>
                                    <Grid container alignItems="center" mb={2} wrap="nowrap">
                                        <CancelOutlined color="error" />
                                        <Typography
                                            variant="body1"
                                            color="error.main"
                                            fontWeight={500}
                                            ml={2}
                                        >
                                            {element.msg}
                                        </Typography>
                                    </Grid>

                                    {element.samples && element.samples?.length > 0 && (
                                        <>
                                            {/* TODO: Create a different title for each error for better accessibility */}
                                            <Typography
                                                variant="body1"
                                                color="text.primary"
                                                fontWeight={700}
                                                mb="5px"
                                                id={`list-title-${i}`}
                                            >
                                                {element.errorType === 'currency_value_error'
                                                    ? 'Las monedas invalidas fueron las siguientes:'
                                                    : 'Las ordenes que fallaron fueron las siguientes:'}
                                            </Typography>

                                            <List aria-labelledby={`list-title-${i}`}>
                                                {element.samples.map((sample, j) => (
                                                    <ListItem
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        key={j}
                                                        aria-labelledby={`order-currency-${j}`}
                                                    >
                                                        <Typography
                                                            variant="body2"
                                                            color="text.primary"
                                                            id={`order-currency-${j}`}
                                                        >
                                                            {sample}
                                                        </Typography>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </>
                                    )}
                                    <Divider
                                        sx={{ mb: '15px', mt: '15px', color: 'text.disabled' }}
                                    />
                                </Fragment>
                            ))}

                        {order?.logs?.percentage && (
                            <Typography
                                variant="body1"
                                color="text.primary"
                                fontWeight={700}
                                mt="15px"
                            >
                                Los errores representan el {order.logs.percentage}% del archivo.
                            </Typography>
                        )}

                        {(errors === null || errors.length === 0) && (
                            <Typography variant="body1" color="text.primary">
                                No hay logs para este archivo
                            </Typography>
                        )}
                    </Grid>
                )}
            </Grid>
        </Modal>
    );
};

export default LogsModal;
