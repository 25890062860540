import { FC } from 'react';
import { DefaultBtn, Typography } from '@fairplay2/ui';
import { Grid } from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import { AccumulatedFooterProps } from './interfaces';

const AccumulatedFooter: FC<AccumulatedFooterProps> = ({ onAddStore }) => (
    <Grid item container gap={{ md: 1, lg: 2 }} alignItems="center" padding="30px 0">
        <Typography variant="subtitle2" color="primary.main" textAlign="start" fontWeight={500}>
            ¿Deseas conectar una nueva tienda a tus canales de venta?
        </Typography>
        <DefaultBtn variant="text" startIcon={<AddCircle />} onClick={onAddStore}>
            Agregar tienda
        </DefaultBtn>
    </Grid>
);

export default AccumulatedFooter;
