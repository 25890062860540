import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, TableCell, TableRow } from '@mui/material';
import { CreditCard, ReceiptLong, AttachMoney, Delete } from '@mui/icons-material';
import { IconBtn, Tooltip, Typography } from '@fairplay2/ui';
import { Status, StatusType } from 'common-components';
import { SHORT_READABLE_FORMAT, formatUtc } from 'utils/dates';
import { formatAmount } from 'utils/parsing';
import { useIsAdmin } from '../../hooks/useIsAdmin';
import { CardTableItemProps } from '../../interfaces';
import { REQUEST_TYPE_TEXT } from '../../constants';
import EditCardAlias from './EditCardAlias';

const canSeeDetail = (status?: string) =>
    status === 'active' || status === 'inactive' || status === 'cancel' || status === 'blocked';

const CardTableItem: FC<CardTableItemProps> = ({
    card: { card, initial_request: initialRequest, last_request: lastRequest },
    showAlert,
    onOpenCancelCardModal,
    onOpenCreditLimitCardModal,
    onOpenCardInfoModal,
    selectedContractId,
    fetchCards,
}) => {
    const history = useHistory(),
        isAdmin = useIsAdmin(),
        { approved_by: approvedBy, requested_by: initialRequestedBy } = initialRequest || {},
        {
            update_date: updateDate,
            status_request: statusRequest,
            request_type: requestType,
        } = lastRequest || {},
        requestedBy = initialRequestedBy || card?.requested_by,
        showRequesterName = isAdmin && requestedBy?.name,
        isEditDisabled = card?.status_card?.id !== 'active' || !card?.can_create_new_request,
        isCancelDisabled =
            (card?.status_card?.id !== 'active' && card?.status_card?.id !== 'blocked') ||
            !card?.can_create_new_request;

    return (
        <TableRow>
            <TableCell sx={{ minWidth: 90 }}>
                <Typography color="primary.dark">
                    {formatUtc(card?.request_date || '', SHORT_READABLE_FORMAT, {
                        fallbackString: '',
                    })}
                </Typography>
            </TableCell>
            <TableCell sx={{ minWidth: '160px', maxWidth: '200px' }}>
                <EditCardAlias
                    cardId={card?.card_id!}
                    alias={card?.alias}
                    statusCard={card?.status_card?.id}
                    showAlert={showAlert}
                    selectedContractId={selectedContractId}
                    onComplete={fetchCards}
                />
            </TableCell>
            <TableCell sx={{ minWidth: '130px' }}>
                <Typography fontWeight={700} color="primary.main">
                    {card?.available_today?.currency && card?.available_today?.amount
                        ? `${card.available_today.currency}$ ${formatAmount(
                              card.available_today.amount ?? 0,
                          )}`
                        : 'Sin disponible'}
                </Typography>
            </TableCell>
            <TableCell sx={{ minWidth: '120px' }}>
                {showRequesterName || approvedBy ? (
                    <>
                        {showRequesterName && (
                            <>
                                <Typography color="primary.dark">{requestedBy.name}</Typography>
                                <br />
                            </>
                        )}
                        {approvedBy?.name && (
                            <Typography color="primary.dark">{approvedBy.name}</Typography>
                        )}
                    </>
                ) : (
                    <Typography color="primary.dark">No hay usuario asociado</Typography>
                )}
            </TableCell>
            <TableCell>
                {card?.status_card?.id && card?.status_card?.text && (
                    <Status
                        role="presentation"
                        variant="outlined"
                        status={card.status_card.id as StatusType}
                    >
                        {card.status_card.text}
                    </Status>
                )}
            </TableCell>
            <TableCell sx={{ maxWidth: '118px' }}>
                {statusRequest?.text && updateDate && (
                    <>
                        <Typography fontWeight={500} color="text.primary">
                            {statusRequest.id === 'pending' && requestType
                                ? `${REQUEST_TYPE_TEXT[requestType]} pendiente`
                                : statusRequest.text}
                        </Typography>
                        <Typography fontWeight={500} color="primary.main">
                            {formatUtc(updateDate, SHORT_READABLE_FORMAT, {
                                fallbackString: '',
                            })}
                        </Typography>
                    </>
                )}
            </TableCell>
            <TableCell align="center" sx={{ minWidth: '150px' }}>
                <Grid container justifyContent="space-evenly" wrap="nowrap" gap="5px">
                    <Tooltip title="Usar tarjeta" placement="top" distance={3}>
                        {/*
                            Span necessary to avoid MUI 'Tolltip containing disable button' warning.
                            Manually moving accesible label to button component.
                        */}

                        <span>
                            <IconBtn
                                aria-label="Usar tarjeta"
                                color="secondary"
                                variant="outlined"
                                onClick={() => onOpenCardInfoModal(card.card_id)}
                                disabled={card?.status_card?.id !== 'active'}
                            >
                                <CreditCard />
                            </IconBtn>
                        </span>
                    </Tooltip>
                    <Tooltip title="Ver detalle" placement="top" distance={3}>
                        <span>
                            <IconBtn
                                aria-label="Ver detalle"
                                color="secondary"
                                variant="outlined"
                                onClick={() =>
                                    history.push(`/app/disbursements/cards/detail/${card?.card_id}`)
                                }
                                disabled={!canSeeDetail(card?.status_card?.id)}
                            >
                                <ReceiptLong />
                            </IconBtn>
                        </span>
                    </Tooltip>
                    <Tooltip title="Editar límite" placement="top" distance={3}>
                        <span>
                            <IconBtn
                                aria-label="Editar límite"
                                color="secondary"
                                variant="outlined"
                                onClick={() => onOpenCreditLimitCardModal(card)}
                                disabled={isEditDisabled}
                            >
                                <AttachMoney />
                            </IconBtn>
                        </span>
                    </Tooltip>
                    <Tooltip title="Cancelar tarjeta" placement="top" distance={3}>
                        <span>
                            <IconBtn
                                aria-label="Cancelar tarjeta"
                                color="secondary"
                                variant="outlined"
                                onClick={() => onOpenCancelCardModal(card?.card_id!, card?.alias)}
                                disabled={isCancelDisabled}
                            >
                                <Delete />
                            </IconBtn>
                        </span>
                    </Tooltip>
                </Grid>
            </TableCell>
        </TableRow>
    );
};

export default CardTableItem;
