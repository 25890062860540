import {
    FC,
    MouseEvent,
    ChangeEvent,
    Fragment,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useHistory, useParams } from 'react-router';
import { Collapse, Grid, MenuItem } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { Alert, IconBtn, Select, Typography } from '@fairplay2/ui';
import fairplayAPI from 'utils/api';
import { READABLE_FORMAT, format } from 'utils/dates';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { useDataSources } from '../context/DataSourcesContext';
import {
    ConnectorData,
    DataSourcePlatform,
    GroupedPlatformKey,
    RawPlatformKey,
} from '../interfaces';
import { getConnectorAlias, getPlatformDisplayName } from '../utils';
import {
    MappedStatusOrders,
    MultipleStatusesParams,
    ConnectorWithMappedStatus,
} from './interfaces';
import MultipleStatusesSuccess from './MultipleStatusesSuccess';
import StatusesDragDrop from './StatusesDragDrop';

const Component: FC = () => {
    const { push, replace } = useHistory(),
        { platformKey: paramKey, targetConnector } = useParams<MultipleStatusesParams>(),
        // Allow case errors in url
        platformKey = paramKey.toLowerCase() as GroupedPlatformKey,
        newProcessRef = useRef(true),
        prevId = useRef<string | undefined>(targetConnector),
        { platforms, baseAPI, dispatch } = useDataSources(),
        [step, setStep] = useState(0),
        [fssData, setFssData] = useState<
            Omit<Exclude<DataSourcePlatform['fss'], null>, 'readOnly'>
        >({
            defaultConnectorId: targetConnector || '',
            editable: new Map<string, ConnectorData & { platformGroup: GroupedPlatformKey }>(),
        }),
        [currentSelection, setCurrentSelection] = useState<ConnectorWithMappedStatus>({
            ecommerceName: '',
            connectorId: '',
            orderStatus: undefined,
            readOnlyData: undefined,
            rawPlatform: '' as RawPlatformKey,
        }),
        [error, setError] = useState(''),
        isReadOnly = !!currentSelection.readOnlyData;

    const onReturn = useCallback(() => {
        push('/app/sources');
    }, [push]);

    const onAdjust = () => {
        setStep(0);
    };

    const changeSelectedId = useCallback(
        (connectorId: string) => {
            if (!fssData.editable.size) return;
            const newSelection = fssData.editable.get(connectorId || fssData.defaultConnectorId);

            setCurrentSelection({
                ecommerceName: getConnectorAlias(newSelection as ConnectorData),
                connectorId: newSelection?.id as string,
                orderStatus: undefined,
                rawPlatform: newSelection?.platform as RawPlatformKey,
                readOnlyData: false,
            });
            // Remove connector id from url parameters after changing it using
            //  Select input or if it was an invalid id for the platform.
            if (prevId.current && prevId.current !== newSelection?.id) {
                // Prevent calling replace multiple times
                prevId.current = undefined;
                replace(`/app/sources/status-orders/${platformKey}`);
            }
            onAdjust();
        },
        [fssData, platformKey, replace],
    );

    const onContinue = () => {
        newProcessRef.current = false;
        setStep(1);
    };

    const onNextEcommerce = (e: MouseEvent<HTMLButtonElement>) => {
        newProcessRef.current = true;
        changeSelectedId((e.target as HTMLButtonElement).value);
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        changeSelectedId(e.target.value);
    };

    const onSubmit = async (statuses: MappedStatusOrders) => {
        const res: any = await fairplayAPI.patch(
            `${baseAPI}/${currentSelection.connectorId}/status-orders`,
            {
                status_orders: statuses,
                has_new_status: false,
            },
        );

        const { has_new_status, in_process } = res.data.body.results;
        // Update using format from getConnectors endpoint
        dispatch({
            type: 'UPDATE',
            payload: {
                id: currentSelection.connectorId,
                platform: currentSelection.rawPlatform,

                fss: { has_new_status, in_process },
            },
        });
        setStep(2);

        return res;
    };

    useEffect(() => {
        // Prevents error if connectors haven't been fetched before initial render
        //  but allows to redirect the user if they used an invalid platform key
        if (Object.keys(platforms).includes(platformKey) && !platforms?.[platformKey]) return;
        const newFssData = platforms[platformKey]?.fss || null;

        //  Only execute if user is in step 1 screen
        if (newProcessRef.current) {
            // Only execute if a connector was directly accessed via url
            //  (prevId becomes undefined on id change)
            if (prevId.current && newFssData?.readOnly.has(prevId.current)) {
                const connector = newFssData.readOnly.get(prevId.current);
                // changeSelectedId won't be excecuted, so we need
                //  to set this here
                setCurrentSelection({
                    ecommerceName: getConnectorAlias(connector as any),
                    connectorId: connector?.id as string,
                    orderStatus: undefined,
                    rawPlatform: connector?.platform as RawPlatformKey,
                    readOnlyData: true,
                });
                return;
            }
            if (!newFssData?.editable.size) {
                onReturn();
                return;
            }
        }
        // Updates fssData to be passed to success screen
        setFssData((prev) => {
            // Last connector was classified and no valid maps are found,
            //  the other properties are irrelevant here, we just want
            //  to keep printableName and set editable map as empty
            if (!newFssData)
                return {
                    ...prev,
                    editable: new Map(),
                };

            // A target connector was passed via url parameters and set to default
            //  id before connectors were retreived
            if (prev.editable.size === 0 && prev.defaultConnectorId !== '')
                return {
                    ...newFssData,
                    // If the desired connector isn't available, revert defaultId to
                    //  the original one provided by fssData
                    defaultConnectorId: newFssData.editable.get(prev.defaultConnectorId)
                        ? prev.defaultConnectorId
                        : newFssData.defaultConnectorId,
                };

            return newFssData;
        });
    }, [platforms, onReturn, platformKey]);

    useEffect(() => {
        // Avoid resseting currentSelection information between steps 1 and 2
        if (!newProcessRef.current) return;
        changeSelectedId('');
        // changeSelectedId changes when fssData is updated
    }, [changeSelectedId]);

    useEffect(() => {
        // Prevents error on initial render
        if (!currentSelection.connectorId || !baseAPI) return;

        const expectedId = currentSelection.connectorId;

        (async () => {
            try {
                const res: any = await fairplayAPI.get(`${baseAPI}/${expectedId}/status-orders`);
                setCurrentSelection((prev: any) => {
                    // User changed selection before promise resolved
                    if (prev.connectorId !== expectedId) return prev;

                    return {
                        ...prev,
                        orderStatus: res.data.body.results.status_orders,
                        readOnlyData:
                            prev.readOnlyData === false
                                ? false
                                : {
                                      contract: res.data.body.results.contract?.fairplayInternId,
                                      date: format(res.data.body.results.modified, READABLE_FORMAT),
                                  },
                    };
                });
                setError('');
            } catch (errResponse: ErrorObj | any) {
                setError(
                    errResponse?.error ||
                        'Ocurrió un error al obtener los estatus del conector, intente más tarde.',
                );
            }
        })();
    }, [currentSelection.connectorId, baseAPI]);

    return (
        <div style={{ maxWidth: 590 }}>
            <Grid container alignItems="center" marginTop={4} wrap="nowrap">
                <IconBtn
                    color="primary"
                    variant="filled"
                    size="small"
                    disabled={step === 1}
                    onClick={onReturn}
                    sx={{
                        marginRight: 1.3,
                        '&.Mui-disabled': {
                            bgcolor: '#405bdd !important',
                            opacity: '0.3 !important',
                        },
                    }}
                >
                    <ChevronLeft />
                </IconBtn>
                <Typography variant="h4" component="h2" color="primary.main">
                    {step === 2 || isReadOnly
                        ? 'Estatus de órdenes '
                        : 'Clasifica estatus de órdenes '}
                    <Typography fontWeight={700} color="primary.dark" component="span">
                        {getPlatformDisplayName(platformKey)} {step === 2 && '¡Clasificados!'}
                    </Typography>
                </Typography>
            </Grid>
            {step === 2 ? (
                <MultipleStatusesSuccess
                    classifiedEcommerceName={currentSelection.ecommerceName}
                    platformData={{ ...fssData, platformKey }}
                    onEcommerceClick={onNextEcommerce}
                    onDone={onReturn}
                />
            ) : (
                <>
                    <Typography
                        variant="subtitle2"
                        color="text.primary"
                        mt="20px"
                        mb={3}
                        fontWeight={400}
                    >
                        Para poder representar tus ventas reales, necesitamos saber en qué momento
                        una orden se convierte en una venta para tu tienda y aporta al volumen total
                        de ventas.
                    </Typography>
                    <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                        display="inline-flex"
                        columnSpacing={fssData.editable.size > 1 ? 3 : 0}
                        rowSpacing={1}
                    >
                        <Grid item xs="auto" maxWidth="100%">
                            <Typography variant="subtitle2" color="text.primary">
                                Estos son los estatus de órdenes en tu tienda
                                {!isReadOnly && fssData.editable.size > 1 && ': '}{' '}
                            </Typography>
                        </Grid>
                        {currentSelection.readOnlyData !== undefined && (
                            <Grid
                                item
                                xs={fssData.editable.size > 1 && !isReadOnly ? 12 : 'auto'}
                                md
                                data-testid="multiple-statuses-current"
                            >
                                {fssData.editable.size > 1 && !isReadOnly ? (
                                    <Select
                                        value={currentSelection.connectorId}
                                        fullWidth
                                        onChange={onChange}
                                        data-testid="multiple-statuses-select"
                                    >
                                        {[...fssData.editable].map(([id, item]) => (
                                            <MenuItem key={id} value={id}>
                                                {getConnectorAlias(item)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                ) : (
                                    <Typography
                                        variant="subtitle2"
                                        color="primary.dark"
                                        fontWeight={700}
                                        component="span"
                                    >
                                        {currentSelection.ecommerceName}
                                    </Typography>
                                )}
                            </Grid>
                        )}
                    </Grid>
                    <Collapse in={!!error}>
                        <Alert
                            severity="error"
                            sx={{ mt: 4 }}
                            data-testid="multiple-statuses-error"
                        >
                            {error}
                        </Alert>
                    </Collapse>
                    <StatusesDragDrop
                        statuses={error ? null : currentSelection.orderStatus}
                        onCancel={onReturn}
                        onAdjust={onAdjust}
                        onContinue={onContinue}
                        onConfirm={onSubmit}
                        readOnlyData={currentSelection.readOnlyData}
                    />
                </>
            )}
        </div>
    );
};

export default Component;
