import { monetaryDecimalToInteger } from 'utils/currency';
import { negateLocalOffset } from 'utils/dates';
import { SINGLE_DISBURSEMENT_VALUES } from './types';

interface CartItem {
    supplierAccount: any;
    id_dispersion: string;
    dispersion_date?: Date | null;
    supplierData: any;
    amount: number;
    confirmed: boolean;
    concept: string;
    reference: string;
    file: string | null | string[];
    fileName: string | null | string[];
    label: string;
    disabled: boolean;
    currency: string;
}

interface CartData {
    selectedContract:
        | {
              id: string;
              productType: string;
              fairplayInternId: string;
          }
        | string;
    suppliersCount: number;
    disbursementStep: number;
    disbursementLocation: string;
    disbursementPeriod: string;
    preSelectedDisbursementDate: Date | undefined;
    periodAvailableBalance: number;
    totalRequestedAmount: Record<string, number>;
    exchangeRates: Record<string, number>;
    minDisbursementDate: Date | undefined;
    maxDisbursementDate: Date | undefined;
    disabledDisbursementDays: Date[];
    disbursementAlert: {
        msg: string;
        severity: string;
    };
    disbursementCompanyId: string;
    formCopy: CartItem;
    prevTotalRequestedAmount: Record<string, number>;
    cartData: CartItem[];
    enableDisbursementsV2Module: boolean | null;
    isAmountValid: boolean;
    suppliers: any[];
    formsStep: number;
}

interface SavedCartData {
    items: {
        dispersion: {
            supplier_id: string;
            supplier_account: string;
            dispersion_date: string | number | Date;
            amount: number;
            pk: string;
            confirmed: boolean;
            concept: string;
            reference: string;
            url_file: string | string[];
        }[];
    } | null;
    total_items: number;
    product_type: string;
    contract_id: string;
    fairplay_intern_id: string;
    national: boolean;
    period: number;
    pre_selected_disbursement_date: string | number | Date;
    company_id: string;
    min_disbursement_date: string | number | Date;
}

export interface SupplierData {
    id: string;
    bankAccounts: { id: string; accountCurrency: { id: string } }[];
}

export const createEmptyCartItems = (totalItems: number): CartItem[] =>
    Array.from({ length: totalItems }, (_, i) => ({
        ...SINGLE_DISBURSEMENT_VALUES,
        label: `Dispersión ${i + 1}`,
    }));

export const restoreCartItems = (
    savedCartData: SavedCartData,
    suppliersData: SupplierData[] | SupplierData,
) => {
    const cartItems: CartItem[] = [],
        totalRequestedAmount: Record<string, number> = {};

    savedCartData.items!.dispersion.forEach((item, index) => {
        const supplierData = (
                savedCartData.product_type === 'working-capital' && Array.isArray(suppliersData)
                    ? suppliersData.find((supplier) => supplier.id === item.supplier_id)
                    : suppliersData
            ) as SupplierData,
            supplierAccount = supplierData?.bankAccounts.find(
                (account) => account.id === item.supplier_account,
            ),
            currency = supplierAccount!.accountCurrency.id.toLowerCase(),
            dispersionDate = item.dispersion_date
                ? negateLocalOffset(new Date(item.dispersion_date))
                : undefined;

        totalRequestedAmount[currency] =
            (totalRequestedAmount[currency] || 0) + monetaryDecimalToInteger(item.amount);

        cartItems.push({
            supplierAccount,
            id_dispersion: item.pk,
            dispersion_date: dispersionDate,
            supplierData,
            amount: item.amount,
            confirmed: item.confirmed,
            concept: item.concept,
            reference: item.reference,
            file: null,
            fileName: item.url_file ? item.url_file.slice(item.url_file.indexOf('_') + 1) : null,
            label: `Dispersión ${index + 1}`,
            disabled: !item.pk,
            currency,
        });
    });

    return { cartItems, totalRequestedAmount };
};

export const getSavedCartData = (
    initialCartValues: CartData,
    savedCartData: SavedCartData,
    suppliersData: any,
) => {
    const cartData = {
        ...initialCartValues,
        selectedContract: {
            id: savedCartData.contract_id,
            productType: savedCartData.product_type,
            fairplayInternId: savedCartData.fairplay_intern_id,
        },
        suppliers: suppliersData,
        disbursementStep: 1,
        disbursementLocation: savedCartData.national ? 'Nacional' : 'Internacional',
        disbursementPeriod: savedCartData.period === 1 ? 'current' : 'next',
        preSelectedDisbursementDate: negateLocalOffset(
            new Date(savedCartData.pre_selected_disbursement_date),
        ),
        disbursementCompanyId: savedCartData.company_id,
        minDisbursementDate: negateLocalOffset(new Date(savedCartData.min_disbursement_date)),
    };

    if (!savedCartData.items) cartData.cartData = createEmptyCartItems(savedCartData.total_items);
    else {
        const { cartItems, totalRequestedAmount } = restoreCartItems(savedCartData, suppliersData);
        cartData.totalRequestedAmount = totalRequestedAmount;
        cartData.cartData = cartItems;
    }

    return cartData;
};
