import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TitleContainer = styled(Grid)({
    '@media (min-width: 700px)': {
        position: 'absolute',
        zIndex: 10,
        top: 100,
        left: 0,
    },
});
