import { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LoaderPage } from '@fairplay2/ui';
import { NotFoundPage } from 'layouts';
import fairplayAPI, { hasActiveSession } from 'utils/api';
import { toFormData } from 'utils/parsing';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import SuccessConfirmation from '../SuccessConfirmation';
import { BicolumnLayout } from '../BicolumnLayout';
import { NewUserIndexProps } from '../../interfaces';
import CreateUser, { DEFAULT_COMPANY_NAME } from './CreateUser';

const RegisterUser: FC<NewUserIndexProps> = ({ match }) => {
    const history = useHistory(),
        [confirmation, setConfirmation] = useState(false),
        [loading, setLoading] = useState(true),
        [isValid, setIsValid] = useState(false),
        [email, setEmail] = useState(''),
        [error, setError] = useState(''),
        { token } = match.params,
        [companyName, setCompanyName] = useState(DEFAULT_COMPANY_NAME);

    const onNext = () => {
        setConfirmation(true);
    };

    useEffect(() => {
        setConfirmation(false);
        if (token) {
            // validate invite
            const data = toFormData({
                token,
            });

            (async () => {
                try {
                    const res: any = await fairplayAPI.post(
                        '/v1/companies/collaborators/validate',
                        data,
                        { authorization: false },
                    );
                    if (res.data.body.results.exists) {
                        if (hasActiveSession()) {
                            // if user is logged in, accept invite and redirect to Connectors

                            try {
                                await fairplayAPI.post('/v1/companies/collaborators/confirm', data);
                                history.push('/app/sources');
                            } catch (errResponse: ErrorObj | any) {
                                if (errResponse?.error) setError(errResponse.error);
                                setIsValid(false);
                                setLoading(false);
                            }
                        } else {
                            // if user exists, and it's logged out, sent to login w/token
                            history.push(`/${token}`);
                        }
                    } else {
                        setEmail(res.data.body.results.email);
                        setCompanyName(res.data.body.results.company_name);
                        setIsValid(true);
                        setLoading(false);
                    }
                } catch {
                    setIsValid(false);
                    setLoading(false);
                }
            })();
        } else {
            setIsValid(false);
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    // eslint-disable-next-line no-nested-ternary
    return loading ? (
        <LoaderPage loading={loading} />
    ) : isValid ? (
        <BicolumnLayout>
            {confirmation ? (
                <SuccessConfirmation />
            ) : (
                <CreateUser onNext={onNext} token={token} email={email} companyName={companyName} />
            )}
        </BicolumnLayout>
    ) : (
        <NotFoundPage message={error} />
    );
};

export default RegisterUser;
