import { FC, Fragment, useState } from 'react';
import { useHistory } from 'react-router';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { InterpunctLoader, Tooltip, Typography } from '@fairplay2/ui';
import { Status } from 'common-components';
import { groupPlatformKey, isFSSEditable } from 'components/dashboard/data-sources/context/utils';
import { dataSourceRedirection } from 'components/dashboard/data-sources/utils';
import { ErrorObj } from 'utils/error-handler';
import { capitalize } from 'utils/formatting';
import { STATUS_MAP } from '../../constants';
import { ConnectionItemProps } from '../../interfaces';
import { ItemContainer, StyledBtn } from './styles';
import EditConnectorAlias from './EditConnectorAlias';

const getAdditionalHint = (item: ConnectionItemProps['item']) => {
    if (
        !item.platform.includes('google') &&
        !item.platform.includes('fads') &&
        item.platform !== 'mercado-libre'
    )
        return null;
    if (item.username) return item.username;
    if (item.platform === 'mercado-libre') return item.email as string;

    return capitalize(item.platform).replace('-', ' ');
};

const AdditionalHint: FC<{
    hint: string | null;
    /**  If `true`, autocroll and Tooltip are not displayed, only an ellipsed text */
    ignoreEnhancements?: boolean;
}> = ({ hint, ignoreEnhancements }) => {
    const Wrapper = ignoreEnhancements ? Fragment : Tooltip,
        mdDown = useMediaQuery(useTheme().breakpoints.down('md')),
        props = ignoreEnhancements ? {} : { title: hint, placement: 'top-start', disabled: mdDown };

    if (!hint) return null;

    return (
        <Grid item xs={12}>
            <Wrapper {...(props as any)}>
                <Typography
                    variant="body1"
                    color="text.primary"
                    fontWeight={700}
                    ellipsis={ignoreEnhancements ? true : !mdDown}
                    overflow={
                        ignoreEnhancements
                            ? undefined
                            : // Using !important to override makeStyles taking priority
                              ['auto', undefined, 'hidden']
                    }
                    whiteSpace={ignoreEnhancements ? 'nowrap' : undefined}
                    paddingBottom={ignoreEnhancements ? undefined : ['5px', 0]}
                >
                    {hint}
                </Typography>
            </Wrapper>
        </Grid>
    );
};

const ConnectionItem: FC<ConnectionItemProps> = ({
    item,
    onPending: onPendingProp,
    onPendingText,
    onDisconnect,
    onReauth: onReauthProp,
    notifyError,
    onCloseModal,
}) => {
    const { location: { pathname } = {}, push } = useHistory(),
        groupKey = groupPlatformKey(item.platform),
        isOnboarding = pathname === '/app/onboarding',
        [loading, setLoading] = useState(false),
        canEditFSS = isFSSEditable(item);

    const onReauth = () => {
        const response = onReauthProp?.(item);
        if (typeof response === 'object' && typeof response.then === 'function') {
            setLoading(true);
            response.catch((errResponse: ErrorObj | any) => {
                setLoading(false);
                if (errResponse?.type === 'form') {
                    notifyError(
                        Object.values<any>(errResponse.error).reduce((acc, field) => {
                            const currentError =
                                typeof field === 'string' ? field : field.join(', ');
                            return acc ? `${acc}, ${currentError}` : currentError;
                        }, ''),
                        'error',
                    );
                } else
                    notifyError(
                        errResponse?.error || 'Ocurrió un error, intente mas tarde',
                        'error',
                    );
            });
        } else onCloseModal();
    };

    const goToFSS = () => {
        push(`/app/sources/status-orders/${groupKey}/${item.id}`);
    };

    const onPending = () => {
        if (onPendingProp) onPendingProp(item);
        else if (item.platform === 'woocommerce-v2') {
            setLoading(true);
            dataSourceRedirection.create(item.redirectUrl as string, {
                from: 'woocommerce',
            });
        }
    };

    return (
        <Grid
            container
            alignItems="center"
            role="listitem"
            border="2px solid #f5f5f5"
            borderRadius="15px"
            margin="10px 0"
            padding="15px"
        >
            <ItemContainer
                container
                item
                xs={12}
                sm={4}
                justifyContent="center"
                alignItems="center"
                alignContent="center"
            >
                <AdditionalHint
                    hint={getAdditionalHint(item)}
                    ignoreEnhancements={item.platform.startsWith('google')}
                />
                <Grid item xs={12}>
                    <EditConnectorAlias connector={item} />
                </Grid>
            </ItemContainer>

            <ItemContainer
                container
                item
                xs={12}
                sm={4}
                padding="6px 0"
                justifyContent="center"
                alignContent="center"
            >
                <Status status={item.status as 'pending'} variant="outlined">
                    {STATUS_MAP[item.status as 'pending']}
                </Status>
            </ItemContainer>
            <Grid
                container
                item
                xs={12}
                sm={4}
                direction="column"
                justifyContent="space-evenly"
                alignItems="center"
                data-testid="connection-item-actions"
            >
                {loading ? (
                    <InterpunctLoader />
                ) : (
                    <>
                        {!isOnboarding &&
                            canEditFSS !== null &&
                            (canEditFSS ? (
                                <StyledBtn variant="text" color="warning" onClick={goToFSS}>
                                    Configurar estatus de órdenes
                                </StyledBtn>
                            ) : (
                                <StyledBtn variant="text" color="info" onClick={goToFSS}>
                                    Ver estatus de órdenes
                                </StyledBtn>
                            ))}
                        {item.status === 'pending' && !!onPendingText && (
                            <StyledBtn
                                variant="text"
                                color="secondary"
                                onClick={onPending}
                                data-testid="connection-pending-action"
                            >
                                {onPendingText}
                            </StyledBtn>
                        )}
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {item.status === 'reauthorize' ? (
                            <StyledBtn variant="text" onClick={onReauth} color="error">
                                Reautorizar conexión
                            </StyledBtn>
                        ) : item.status !== 'suspended' ? (
                            <StyledBtn variant="text" onClick={() => onDisconnect(item)}>
                                Desconectar
                            </StyledBtn>
                        ) : null}
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default ConnectionItem;
