import { FC } from 'react';
import TableProvider from '../../../state/TableContext';
import { CardParamsProps } from '../../interfaces';
import { DEFAULT_APPROVAL_REQUESTS_FILTERS } from '../../../constants';
import ApprovalRequestsTable from './ApprovalRequestsTable';
import ApprovalRequestFilters from './ApprovalRequestFilters';

export const RequestsWidget: FC<CardParamsProps> = ({ cardId }) => (
    <TableProvider
        initialData={{
            ...DEFAULT_APPROVAL_REQUESTS_FILTERS,
            effective: { ...DEFAULT_APPROVAL_REQUESTS_FILTERS.effective, card: cardId },
        }}
    >
        <ApprovalRequestFilters />
        <ApprovalRequestsTable cardId={cardId} />
    </TableProvider>
);

export default RequestsWidget;
