import { FC, useState, useContext } from 'react';
import { TableRow, TableCell, Grid } from '@mui/material';
import { Checkbox, Typography, Loader } from '@fairplay2/ui';
import { formatAmount } from 'utils/parsing';
import { useDisbursementValuesContext } from 'context/disbursements/DisbursementValuesContext';
import fairplayAPI from 'utils/api';
import SessionContext from 'context/session/sessionContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { formatUtc, SHORT_READABLE_FORMAT } from 'utils/dates';
import {
    CartData,
    ConfirmationRowData,
    DisbursementConfirmationTableItemProps,
} from '../interfaces';

const getAcctNumberLast4Digits = (acctNumber: string = '') =>
    acctNumber?.slice(Math.max(acctNumber.length - 4, 1));

const getItemData = (
    cartItem: any,
    // Faulty values in case backend provides invalid response
    disbursement: CartData | undefined | null,
    isInternational: boolean,
    contractType: 'sales-advancement' | 'working-capital',
): ConfirmationRowData => {
    if (
        !cartItem?.supplierData ||
        !disbursement ||
        cartItem.supplierData.id !== disbursement.supplier_id
    )
        return {
            date: '',
            supplier: {
                name: '',
                taxId: '',
            },
            disbursementMoney: {
                currency: '',
                amount: '',
            },
            bankAccount: {
                account: '',
                bank: '',
            },
            supplierCountry: isInternational
                ? {
                      country: '',
                      state: '',
                  }
                : undefined,
            isConfirmed: false,
        };

    return {
        date: formatUtc(disbursement?.dispersion_date || '', SHORT_READABLE_FORMAT, {
            fallbackString: '',
        }),
        supplier: {
            name:
                (contractType === 'sales-advancement'
                    ? cartItem.supplierData.businessName
                    : cartItem.supplierData.alias) || '',
            taxId:
                (contractType === 'sales-advancement'
                    ? cartItem.supplierData.rfc
                    : cartItem.supplierData.taxId) || '',
        },
        disbursementMoney: {
            currency: cartItem.supplierAccount.accountCurrency?.id || '',
            amount: `${formatAmount(disbursement.amount) || ''}`,
        },
        bankAccount: {
            account: getAcctNumberLast4Digits(
                cartItem.supplierAccount?.clabe || cartItem.supplierAccount?.swift,
            ),
            bank: cartItem.supplierAccount.bank || '',
        },
        supplierCountry: isInternational
            ? {
                  country: cartItem.supplierData.country?.name || '',
                  state: cartItem.supplierData.state || '',
              }
            : undefined,
        isConfirmed: cartItem.confirmed || false,
    };
};

const DisbursementConfirmationTableItem: FC<DisbursementConfirmationTableItemProps> = ({
    index,
    disbursement,
    loadingCheckAll,
}) => {
    const { user } = useContext(SessionContext),
        {
            cartData,
            setCartData,
            disbursementLocation,
            selectedContract,
            disbursementAlert,
            setDisbursementAlert,
        } = useDisbursementValuesContext(),
        [loading, setLoading] = useState(false),
        itemData = getItemData(
            cartData[index],
            disbursement,
            disbursementLocation === 'Internacional',
            selectedContract?.productType || 'working-capital',
        );

    const toggleConfirmation = async () => {
        if (disbursementAlert.msg) setDisbursementAlert({ msg: '', severity: 'success' });
        setLoading(true);
        try {
            // Toggle backend cart confirmation
            const res: any = await fairplayAPI.put(
                `/v1/cart/${user?.id}/items/${
                    cartData[index]?.id_dispersion
                }/status-item?confirmed=${!cartData[index]?.confirmed}`,
                {},
                { baseService: 'DISBURSEMENT_CART' },
            );
            // Toggle cart context confirmation
            const newConfirmed = res.data.results.confirmed || !cartData[index]?.confirmed,
                newCartData = [...cartData];
            newCartData[index] = {
                ...cartData[index],
                confirmed: newConfirmed,
            };
            setCartData(newCartData);
        } catch (errResponse: ErrorObj | any) {
            setDisbursementAlert({
                msg:
                    errResponse?.error ||
                    'Ocurrió un error confirmando la dispersión, intente más tarde',
                severity: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <TableRow>
            <TableCell sx={{ minWidth: 100 }}>
                <Typography color="primary.main" fontWeight={700}>
                    {itemData.date}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body1" fontWeight={500} color="primary.dark">
                    {itemData.supplier.name}
                </Typography>
                <Typography variant="body1" fontWeight={500} color="primary.dark">
                    {itemData.supplier.taxId}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontWeight={700} color="primary.main">
                    {itemData.disbursementMoney.currency}$ {itemData.disbursementMoney.amount}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontWeight={700} color="primary.main">
                    *{itemData.bankAccount.account}
                </Typography>
                <Typography fontWeight={500} color="primary.dark">
                    {itemData.bankAccount.bank}
                </Typography>
            </TableCell>
            {disbursementLocation === 'Internacional' && !!itemData.supplierCountry && (
                <TableCell>
                    <Typography fontWeight={700} color="primary.main">
                        {itemData.supplierCountry.country}
                    </Typography>
                    <Typography fontWeight={500} color="primary.dark">
                        {itemData.supplierCountry.state}
                    </Typography>
                </TableCell>
            )}
            <TableCell sx={{ pl: 5 }}>
                {loading || loadingCheckAll ? (
                    <Grid container width={60}>
                        <Loader size={20} data-testid="loader-disb-cart-item" />
                    </Grid>
                ) : (
                    <Checkbox onChange={toggleConfirmation} checked={itemData.isConfirmed} />
                )}
            </TableCell>
        </TableRow>
    );
};

export default DisbursementConfirmationTableItem;
