import { FC, useState, ChangeEvent, FormEvent } from 'react';
import { Grid, Collapse, useTheme, useMediaQuery } from '@mui/material';
import {
    Modal,
    Typography,
    DefaultBtn,
    DefaultInput,
    InterpunctLoader,
    Alert,
} from '@fairplay2/ui';
import { AlertProps } from 'utils/interfaces';
import fairplayAPI from 'utils/api';
import { toFormData } from 'utils/parsing';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { ConnectorProps, PaypalForm } from '../interfaces';
import Confirmation from '../components/Confirmation';
import { useDataSources } from '../../context/DataSourcesContext';
import ConnectorFormTitle from '../components/ConnectorFormTitle';

export const PaypalModal: FC<ConnectorProps> = ({ open, connector, onClose }) => {
    const { baseAPI, dispatch } = useDataSources(),
        theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        [values, setValues] = useState<PaypalForm>({
            clientId: '',
            clientSecret: '',
        }),
        [errors, setErrors] = useState<PaypalForm>({
            clientId: '',
            clientSecret: '',
        }),
        [confirmation, setConfirmation] = useState(false),
        [loading, setLoading] = useState(false),
        [alert, setAlert] = useState<AlertProps>({
            message: '',
            type: 'error',
            open: false,
        });

    const clearForm = () => {
        setValues({ clientId: '', clientSecret: '' });
        setErrors({ clientId: '', clientSecret: '' });
        setAlert((prev) => ({ ...prev, open: false }));
    };
    const closeErrorAlert = () => setAlert({ ...alert, open: false });

    const showErrorAlert = (error: string, type: AlertProps['type']) =>
        setAlert({ message: error, type, open: true });

    const onCloseModal = () => {
        document.body.style.overflow = 'auto';
        clearForm();
        setConfirmation(false);
        onClose();
    };

    const onChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
        closeErrorAlert();
        setValues((prev) => ({ ...prev, [name]: value }));
    };

    const onChangeInput = (prop: keyof PaypalForm, errorMsg: string) => {
        if (values[prop]) {
            setErrors((prev) => ({ ...prev, [prop]: '' }));
        } else setErrors((prev) => ({ ...prev, [prop]: errorMsg }));
    };

    const onConnect = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        closeErrorAlert();

        // Check if there isn't an error
        if (!Object.values(errors).some((x) => x !== null && x !== '')) {
            setLoading(true);
            const data = toFormData({
                    platform: 'paypal',
                    clientId: values.clientId,
                    clientSecret: values.clientSecret,
                }),
                endpoint = `${baseAPI}${connector ? `/${connector.id}/reconnect` : ''}`;

            let msg =
                'No se pudo realizar la conexión, verifique sus credenciales e intente nuevamente';
            if (baseAPI) {
                try {
                    const res: any = await fairplayAPI.post(endpoint, data);
                    dispatch({
                        type: connector ? 'UPDATE' : 'ADD',
                        payload: res.data.body.results,
                    });
                    setConfirmation(true);
                } catch (errResponse: ErrorObj | any) {
                    if (errResponse.type === 'form')
                        setErrors((prev) => ({ ...prev, ...errResponse.error }));
                    else {
                        if (errResponse?.error) msg = errResponse.error;
                        showErrorAlert(msg, 'error');
                    }
                } finally {
                    setLoading(false);
                }
            } else {
                showErrorAlert(msg, 'error');
            }
        }
    };

    return (
        <Modal
            disableBackdropClick
            open={open}
            onClose={onCloseModal}
            maxWidth={640}
            disableEscapeKeyDown
            title={!confirmation && <ConnectorFormTitle groupKey="paypal" connector={connector} />}
            sx={{
                '.MuiDialogContent-root': {
                    pt: 0,
                },
            }}
        >
            {confirmation ? (
                <Confirmation item="PayPal" onClose={onCloseModal} />
            ) : (
                <Grid container spacing={4} component="form" onSubmit={onConnect}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" color="text.primary">
                            Para que podamos conectar tu tienda con nuestra plataforma, es necesario
                            que nos proporciones la siguiente información.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <DefaultInput
                            id="paypal-client-id"
                            name="clientId"
                            labelVariant="external"
                            label="Client ID"
                            type="text"
                            value={values.clientId}
                            onChange={onChange}
                            onBlur={() => onChangeInput('clientId', 'Ingresa el Client ID')}
                            error={errors.clientId}
                            InputLabelProps={{ required: false }}
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <DefaultInput
                            id="paypal-client-secret"
                            name="clientSecret"
                            labelVariant="external"
                            label="Client Secret"
                            type="text"
                            value={values.clientSecret}
                            onChange={onChange}
                            onBlur={() => onChangeInput('clientSecret', 'Ingresa el Client Secret')}
                            error={errors.clientSecret}
                            InputLabelProps={{ required: false }}
                            required
                        />
                    </Grid>

                    <Grid item container xs={12} justifyContent="center" paddingTop="0 !important">
                        <Collapse in={alert.open}>
                            <Grid container item xs={12}>
                                <Alert severity={alert.type} sx={{ mt: 3, mb: -2 }}>
                                    {alert.message}
                                </Alert>
                            </Grid>
                        </Collapse>
                    </Grid>

                    <Grid
                        item
                        container
                        xs={12}
                        justifyContent={{ xs: 'center', sm: 'flex-end' }}
                        marginTop={1.25}
                    >
                        {loading ? (
                            <InterpunctLoader />
                        ) : (
                            <>
                                <DefaultBtn
                                    fullWidth={smDown}
                                    size="small"
                                    variant="outlined"
                                    onClick={onCloseModal}
                                >
                                    Cancelar
                                </DefaultBtn>
                                <DefaultBtn
                                    fullWidth={smDown}
                                    size="small"
                                    type="submit"
                                    sx={{
                                        ml: { xs: 0, sm: '20px' },
                                        mt: { xs: '20px', sm: 0 },
                                    }}
                                >
                                    Conectar
                                </DefaultBtn>
                            </>
                        )}
                    </Grid>
                </Grid>
            )}
        </Modal>
    );
};

export default PaypalModal;
