import { FC, useEffect, useState, ChangeEvent, MouseEvent } from 'react';
import { Grid, TableCell, TableRow, useMediaQuery, useTheme } from '@mui/material';
import { Alert, DefaultBtn, Typography } from '@fairplay2/ui';
import { getItemsAtPage } from 'utils/lists';
import { ErrorObject, FileErrorsProps } from '../../interfaces';
import { StyledTable } from '../styles';
import FileErrorItem from './FileErrorItem';

const ListErrors: FC<FileErrorsProps> = ({ fileErrors, returnToFileUpload }) => {
    const [page, setPage] = useState(0),
        [rowsPerPage, setRowsPerPage] = useState(10),
        theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm'));

    const onChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        document.body.style.overflow = 'auto';
    };

    useEffect(() => {
        // Reset pagination
        setPage(0);
        setRowsPerPage(10);
    }, [fileErrors]);

    return (
        <>
            <Grid item container xs={12} m="50px 0 20px" justifyContent="flex-start">
                <Grid item container sm={12} md={6}>
                    <Alert severity="error">
                        No pudimos realizar la solicitud de dispersión múltiple
                    </Alert>
                </Grid>
                <Grid item container sm={12} md={9} alignItems="center" m="20px 0">
                    <Typography variant="subtitle2" color="primary.main" fontWeight={500}>
                        Te solicitamos ajustar en tu archivo los detalles listados abajo. Luego
                        intenta cargarlo nuevamente.
                    </Typography>
                </Grid>
                <Grid item container xs={12} justifyContent="center">
                    <StyledTable
                        currentItems={getItemsAtPage(fileErrors, page, rowsPerPage)}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        getTableItem={(error: ErrorObject, i) => (
                            <FileErrorItem key={i} error={error} />
                        )}
                        columns={2}
                        emptyItemsMessage={false}
                        pagination={{
                            count: fileErrors.length,
                            page,
                            rowsPerPage,
                            rowsPerPageOptions: [5, 10, 20, 25, 50],
                            onPageChange: onChangePage,
                            onRowsPerPageChange: onChangeRowsPerPage,
                        }}
                    >
                        <TableRow>
                            <TableCell>
                                <Typography color="text.primary">Línea</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography color="text.primary">Incidencia</Typography>
                            </TableCell>
                        </TableRow>
                    </StyledTable>
                </Grid>
            </Grid>
            <Grid item container xs={12} justifyContent="flex-end" mt="10px" mb="20px">
                <DefaultBtn
                    fullWidth={smDown}
                    sx={{
                        mb: { xs: '10px', sm: 0 },
                    }}
                    size="small"
                    variant="outlined"
                    onClick={returnToFileUpload}
                >
                    Atrás
                </DefaultBtn>
                <DefaultBtn
                    fullWidth={smDown}
                    size="small"
                    onClick={returnToFileUpload}
                    sx={{
                        mb: { xs: '10px', sm: 0 },
                        ml: { xs: 0, sm: '20px' },
                    }}
                >
                    Entendido
                </DefaultBtn>
            </Grid>
        </>
    );
};

export default ListErrors;
