import { ReactElement, useEffect, useRef } from 'react';
import {
    TableContainer,
    Table as MuiTable,
    TablePagination,
    TableHead as MuiTableHead,
    TableBody,
} from '@mui/material';
import { TableSkeleton, NoResults } from '@fairplay2/ui';
import { toParamsString } from 'utils/handleParams';
import { ExternalFilters, TableProps } from './Table.types';

/**
 *
 * @param filters Additional filters to use.
 * @param page The one-based index of the current page. zero and negative values are ignored
 * @param rowsPerPage. The number of rows per page. zero and negative values are ignored,
 *                     except -1 (used to display all the rows)
 * @returns String for URL params values
 */
export const getQueryString = (
    filters?: ExternalFilters,
    page?: number,
    rowsPerPage?: number,
): string => {
    const params: any = { ...filters };
    if (page !== undefined && page > 0) params.page = page;
    if ((rowsPerPage !== undefined && rowsPerPage > 0) || rowsPerPage === -1)
        params.page_size = rowsPerPage;

    return toParamsString(params);
};

/**
 *
 * @deprecated Use `@fairplay2/ui` component with a custom
 *              reducer instead.
 */
const Table = <T extends Object>({
    currentItems,
    getTableItem,
    children,
    columns,
    skeletonRows = 3,
    loading,
    pagination,
    emptyItemsMessage,
    externalFilters,
    onParamsQueryChange,
    ...props
}: TableProps<T>): ReactElement => {
    const prevSearch = useRef(''),
        prevQuery = useRef(''),
        searchTimeout = useRef<ReturnType<typeof setTimeout>>(),
        /**
         * Using a string representation in order to avoid conflicting
         * calls to useEffect, since it uses === equality,
         * which considers two 'identical' objects as different
         * e.g. {a:1} !== {a:1}
         * Using a stringify representation is safe here
         * since externalFilters can't contain complex values
         * that would create unreliable representations.
         * https://stackoverflow.com/questions/54095994/react-useeffect-comparing-objects
         */
        effectiveFilters = JSON.stringify(externalFilters);

    const clearLastTimeout = () => {
        if (searchTimeout.current) clearTimeout(searchTimeout.current);
    };

    useEffect(() => {
        // Don't mark invalid state if no pagination is being used
        if (pagination?.rowsPerPage === undefined) return;
        prevQuery.current = `INVALID-${prevQuery.current}`;
    }, [pagination?.rowsPerPage, effectiveFilters]);

    // OPTIMIZE: This hook is being called around 4 times even if
    //  no change has occurred in externalFilters, rowsPerPage or page,
    //  since onPageChange changes its reference at every render
    useEffect(() => {
        if (
            onParamsQueryChange === undefined ||
            // onPageChange can only be undefined if pagination prop is not present
            (pagination?.onPageChange === undefined && effectiveFilters === undefined)
        )
            return;

        // Prevents reporting multiple queryParams if the last timeout
        //  hasn't been resolved yet
        clearLastTimeout();

        // Prevents generating an invalid paginated request
        if (prevQuery.current.startsWith('INVALID-')) {
            prevQuery.current = prevQuery.current.split('INVALID-')[1];
            if (pagination?.onPageChange && pagination?.page) {
                pagination?.onPageChange(null, 0);
                return;
            }
        }

        const notifyQueryChange = () => {
            const queryString = getQueryString(
                externalFilters,
                (pagination?.page as any) + 1 || undefined,
                pagination?.rowsPerPage,
            );
            if (queryString === prevQuery.current) return;

            prevQuery.current = queryString;
            onParamsQueryChange(queryString);
        };

        // Checking if search value is present and if it is the
        //  same as last time it was used in a query, to avoid the
        //  timeout in case the effect was triggered by another dependency.
        if (
            (externalFilters?.search || prevSearch.current) &&
            externalFilters?.search !== prevSearch.current
        ) {
            prevSearch.current = externalFilters?.search || '';
            searchTimeout.current = setTimeout(() => {
                notifyQueryChange();
            }, 1000);
        } else {
            notifyQueryChange();
        }

        return clearLastTimeout;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination?.onPageChange,
        pagination?.page,
        pagination?.rowsPerPage,
        effectiveFilters,
        onParamsQueryChange,
    ]);

    return (
        <TableContainer {...props}>
            <MuiTable>
                {!!children && (
                    <MuiTableHead
                        style={{ opacity: currentItems?.length > 0 ? '1' : '0.5' }}
                        data-testid="table-head"
                    >
                        {children}
                    </MuiTableHead>
                )}
                <TableBody>
                    {loading ? (
                        <TableSkeleton rows={skeletonRows} columns={columns} />
                    ) : (
                        currentItems?.map(getTableItem)
                    )}
                </TableBody>
            </MuiTable>
            {currentItems?.length === 0 && !loading && emptyItemsMessage !== false && (
                <NoResults data-testid="table-empty-message">
                    {emptyItemsMessage || 'No hay elementos disponibles'}
                </NoResults>
            )}
            {currentItems?.length > 0 && !!pagination && !loading && (
                <TablePagination {...pagination} data-testid="table-pagination" />
            )}
        </TableContainer>
    );
};

export { Table };
