import { FC } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { Typography } from '@fairplay2/ui';
import { SHORT_READABLE_FORMAT, formatUtc } from 'utils/dates';
import { formatAmount } from 'utils/parsing';
import { TransitTransactionItemProps } from '../../interfaces';

const TransitTransactionItem: FC<TransitTransactionItemProps> = ({ transaction }) => (
    <TableRow>
        <TableCell sx={{ minWidth: 90 }}>
            <Typography color="primary.dark">
                {formatUtc(transaction?.created_at || '', SHORT_READABLE_FORMAT, {
                    fallbackString: '',
                })}
            </Typography>
        </TableCell>
        <TableCell sx={{ minWidth: '180px', maxWidth: '200px' }}>
            <Typography color="primary.dark">{transaction?.merchant_name || ''}</Typography>
        </TableCell>
        <TableCell>
            <Typography fontWeight={700} color="primary.main">
                {transaction?.transaction_currency}${' '}
                {formatAmount(Number(transaction?.transaction_amount) ?? 0)}
            </Typography>
        </TableCell>
    </TableRow>
);

export default TransitTransactionItem;
