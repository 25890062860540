import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal } from '@fairplay2/ui';
import img from 'assets/img/connectors/tiendanube-ico.png';
import fairplayAPI from 'utils/api';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { toFormData } from 'utils/parsing';
import Confirmation from 'components/dashboard/data-sources/platforms/components/Confirmation';
import { dataSourceRedirection } from '../../utils';
import BasePlatformItem from '../components/BasePlatformItem';
import { PlatformItem } from '../interfaces';
import useCodeInterceptor from '../useCodeInterceptor';
import { useDataSources } from '../../context/DataSourcesContext';
import { ConnectorData, InterceptedParams, OnCodeInterception } from '../../interfaces';

const AUTH_URL = '/v1/authentication/auth-connector?platform=tienda-nube';

const REDIRECTION_PARAMS = ['code'];

// eslint-disable-next-line react/prop-types
const Tiendanube: PlatformItem = ({ notify }) => {
    const { baseAPI, dispatch } = useDataSources(),
        [loading, setLoading] = useState(false),
        [showConfirmation, setShowConfirmation] = useState(false),
        { pathname } = useLocation();

    const onConnect = async () => {
        setLoading(true);

        try {
            const response: any = await fairplayAPI.get(AUTH_URL);
            dataSourceRedirection.create(response.data.body.results.url, {
                from: 'tiendanube',
                to: pathname === '/app/onboarding' ? '/app/onboarding' : undefined,
            });
        } catch (errResponse: ErrorObj | any) {
            setLoading(false);
            let fallBackError =
                'Ocurrió un error al generar la conexión con TiendaNube, intente más tarde';

            if (errResponse.status !== 500)
                if (errResponse?.error) fallBackError = errResponse.error;

            notify(fallBackError, 'error');
        }
    };

    const onReauth = async ({ id }: ConnectorData) => {
        const response: any = await fairplayAPI.get(AUTH_URL);
        dataSourceRedirection.create(response.data.body.results.url, {
            from: 'tiendanube',
            to: pathname === '/app/onboarding' ? '/app/onboarding' : undefined,
            connector: id,
        });

        return response;
    };

    const createConnector = async ({ code }: InterceptedParams) => {
        const data = toFormData({
            code,
            platform: 'tienda-nube',
        });
        try {
            const res: any = await fairplayAPI.post(baseAPI, data);
            dispatch({
                type: 'ADD',
                payload: res?.data?.body?.results,
                onCommit: () => {
                    setShowConfirmation(true);
                    setLoading(false);
                },
            });
        } catch (errResponse: ErrorObj | any) {
            let error = '';

            if (errResponse.status !== 500) {
                // Show single error from handleError or append form errors if needed
                if (errResponse.type === 'form') {
                    error = Object.values(errResponse?.error || {}).reduce<string>(
                        (acc, value) =>
                            Array.isArray(value) && value.length > 0 ? `${acc}${value[0]}\n` : acc,
                        '',
                    );
                } else if (errResponse?.error) error = errResponse?.error;
            }
            notify(
                error ||
                    `Ocurrió un error al generar la conexión con Tiendanube, intente más tarde`,
                'error',
            );
            setLoading(false);
        }
    };

    const reauthConnector = async (id: string, { code }: InterceptedParams) => {
        try {
            const res: any = await fairplayAPI.patch(`${baseAPI}/${id}`, toFormData({ code }));
            dispatch({
                type: 'UPDATE',
                payload: res?.data?.body?.results,
                onCommit: () => {
                    notify('Se ha reconectado Tiendanube con éxito', 'success');
                    setLoading(false);
                },
            });
        } catch (errResponse: ErrorObj | any) {
            notify(
                errResponse?.error ||
                    `Ocurrió un error al reconectar Tiendanube, intente más tarde`,
                'error',
            );
            setLoading(false);
        }
    };

    const onCodeInterception: OnCodeInterception = (foundParams, metadata) => {
        // Redirection just began and react triggered a re-render that
        //  caused the hook to be executed
        if (loading) return false;

        setLoading(true);
        if (metadata.connector) reauthConnector(metadata.connector, foundParams);
        else createConnector(foundParams);

        return true;
    };

    useCodeInterceptor('tiendanube', REDIRECTION_PARAMS, onCodeInterception);

    return (
        <BasePlatformItem
            picture={img}
            groupKey="tiendanube"
            showLoader={loading}
            notify={notify}
            onConnect={onConnect}
            onReauth={onReauth}
        >
            <Modal
                open={showConfirmation}
                onClose={() => setShowConfirmation(false)}
                maxWidth={680}
            >
                <Confirmation onClose={() => setShowConfirmation(false)} item="Tiendanube" />
            </Modal>
        </BasePlatformItem>
    );
};

export default Tiendanube;
