import { FC, useState, useEffect, useContext, ChangeEvent, MouseEvent, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, MenuItem, Collapse } from '@mui/material';
import {
    IconBtn,
    Typography,
    SearchInput,
    PaperButton,
    Divider,
    Select,
    Alert,
    FilePicker,
} from '@fairplay2/ui';
import { OrderType } from 'utils/interfaces';
import { GetApp, KeyboardArrowLeft, MonetizationOn, Publish } from '@mui/icons-material';
import SessionContext from 'context/session/sessionContext';
import fairplayAPI from 'utils/api';
import { validateFileSize } from 'utils/files';
import { useAlert } from 'utils/hooks';
import { useDataSources } from '../context/DataSourcesContext';
import { getActivePlatform } from '../platforms/s3/utils';
import OrdersTable from './components/OrdersTable';
import UploadModal from './components/UploadModal';
import MigrateModal from './components/MigrateModal';
import { downloadManualUploadTemplate } from './utils';
import { Order, SortOrderDic } from './interfaces';

const orderDic: SortOrderDic = {
        asc: 'uploadedDate',
        desc: '-uploadedDate',
    },
    statuses = [
        {
            status: 'Pendiente',
            value: '0',
        },
        {
            status: 'Exitoso',
            value: '1',
        },
        {
            status: 'Fallido',
            value: '2',
        },
    ];

const MigrationIcon = () => (
    <svg
        focusable="false"
        className="FpInsights-migrateIcon"
        x="0px"
        y="0px"
        viewBox="0 5 24 14"
        fill="currentColor"
    >
        <g>
            <path
                className="FpInsights-migrateIconTop"
                d="m 15 10 z v 0 H 22 V 8 h -7 V 5 L 11 9 l 3.99 4 z"
            />
            <path
                className="FpInsights-migrateIconBottom"
                d="M 9.01 14 H 2 v 2 h 7.01 v 3 L 13 15 l -3.99 -4 v 3 z z"
            />
        </g>
    </svg>
);

const ManualUpload: FC = () => {
    const { selectedCompany } = useContext(SessionContext),
        { push } = useHistory(),
        {
            platforms: { s3 },
        } = useDataSources(),
        // undefined value means that data sources are not loaded yet
        hasManualUpload = s3 ? getActivePlatform(s3.connectors) === 's3-manual-upload' : undefined,
        [showFileModal, setShowFileModal] = useState(false),
        [showMigrationModal, setShowMigrationModal] = useState(false),
        [file, setFile] = useState<File | null>(null),
        { alert, showAlert, hideAlert } = useAlert(),
        fileAlertData = useAlert(),
        [sortOrder, setSortOrder] = useState<OrderType>('desc'),
        [search, setSearch] = useState(''),
        [status, setStatus] = useState(''),
        [orders, setOrders] = useState<Order[]>([]),
        [loading, setLoading] = useState(true),
        [page, setPage] = useState(0),
        [rowsPerPage, setRowsPerPage] = useState(5),
        [count, setCount] = useState(0),
        [requestParams, setRequestParams] = useState('');

    const onFileChange = (newFile: File | null, target: HTMLInputElement | null) => {
        if (!newFile || !target) return;

        setFile(newFile);
        const fileError = validateFileSize(
            newFile,
            5,
            'No puedes subir archivos mayores a 5MB. Te sugerimos guardar el archivo como CSV o dividir las filas del archivo en multiples archivos.',
        );
        if (fileError) fileAlertData.showAlert(fileError, 'error', false);
        // eslint-disable-next-line no-param-reassign
        target.value = '';

        setShowFileModal(true);
    };

    const resetTable = () => {
        setSearch('');
        setStatus('');
        setPage(0);
        setRowsPerPage(5);
    };

    const onChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const onSelect = (event: ChangeEvent<HTMLInputElement>) => {
        setStatus(event.target.value);
    };

    const onSort = () => {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/naming-convention
    const manageTableUpdates_ = useRef(async (companyId?: string, params?: string) => {
        if (!companyId) return;
        setLoading(true);
        hideAlert();

        try {
            const res: any = await fairplayAPI.get(`/v1/companies/${companyId}/datafiles${params}`),
                resCount = res.data.body.count,
                resArr = res.data.body.results,
                newCount = resCount || resArr.length;
            setCount(newCount);
            setOrders(resArr);
        } catch {
            showAlert('No se pudieron cargar las órdenes, intente más tarde', 'error');
        } finally {
            setLoading(false);
        }
    });

    const onModalClose = (refreshTable = false) => {
        setShowFileModal(false);
        if (refreshTable) manageTableUpdates_.current(selectedCompany.company?.id, requestParams);
    };

    useEffect(() => {
        if (hasManualUpload) {
            manageTableUpdates_.current(selectedCompany.company?.id, requestParams);
        }
    }, [hasManualUpload, requestParams, selectedCompany.company?.id]);

    useEffect(() => {
        if (selectedCompany.company?.id) resetTable();
    }, [selectedCompany.company?.id]);

    useEffect(() => {
        // using strict check to avoid redirection
        //  if data sources are not loaded yet
        if (hasManualUpload === false && !showMigrationModal) push('/app/sources');
    }, [hasManualUpload, showMigrationModal, push]);

    return (
        <Grid container>
            <Grid item container xs={12} alignItems="center" wrap="nowrap">
                <IconBtn
                    variant="filled"
                    color="primary"
                    size="small"
                    sx={{
                        p: '0 !important',
                        svg: {
                            fontSize: '1.5em',
                        },
                    }}
                    onClick={() => push('/app/sources')}
                >
                    <KeyboardArrowLeft />
                </IconBtn>
                <Typography variant="h4" color="primary.main" fontWeight={500} ml="15px">
                    Carga masiva{' '}
                    <Typography component="span" color="primary.dark" fontWeight={700}>
                        de órdenes
                    </Typography>
                </Typography>
            </Grid>
            <Grid
                container
                justifyContent={{ xs: 'center', md: 'flex-start' }}
                spacing={4}
                mt="12px"
            >
                <Grid item>
                    <UploadModal
                        open={showFileModal}
                        closeModal={onModalClose}
                        file={file}
                        setFile={setFile}
                        alertData={fileAlertData}
                    />
                    <FilePicker
                        accept=".csv"
                        onFileChange={onFileChange}
                        icon={<Publish />}
                        fileIcon={<MonetizationOn />}
                        Instructions="Sube un nuevo archivo"
                        AdditionalHelp="CSV 5 MB máx."
                        paperVariant="dashed"
                        iconAnimation="up"
                        sx={{
                            width: 213,
                            height: 218,
                        }}
                    />
                </Grid>
                <Grid item>
                    <PaperButton
                        icon={<GetApp />}
                        iconAnimation="down"
                        onClick={downloadManualUploadTemplate}
                        variant="elevation"
                        sx={{
                            bgcolor: 'background.secondary',
                            width: '220px',
                            height: '220px !important',
                        }}
                    >
                        <Grid container alignItems="flex-end" height="100%" padding="20px">
                            <Grid item>
                                <Typography
                                    variant="subtitle1"
                                    color="primary.main"
                                    fontWeight={700}
                                    lineHeight={1.1}
                                >
                                    Obtén la plantilla de carga
                                </Typography>
                                <Typography variant="body1" color="text.primary" mt="5px">
                                    Actualiza tus órdenes
                                </Typography>
                            </Grid>
                        </Grid>
                    </PaperButton>
                </Grid>

                <Grid item>
                    <MigrateModal
                        open={showMigrationModal}
                        closeModal={() => setShowMigrationModal(false)}
                    />
                    <PaperButton
                        icon={<MigrationIcon />}
                        iconAnimation={false}
                        onClick={() => setShowMigrationModal(true)}
                        variant="elevation"
                        sx={{
                            bgcolor: 'background.secondary',
                            width: '220px',
                            height: '220px !important',
                            svg: {
                                width: '60px !important',
                                path: {
                                    transition: 'transform 0.2s ease-in-out',
                                },
                            },
                            '&:hover .FpInsights-migrateIconTop': {
                                transform: 'translateX(-1px)',
                            },
                            '&:hover .FpInsights-migrateIconBottom': {
                                transform: 'translateX(1px)',
                            },
                        }}
                    >
                        <Grid container alignItems="flex-end" height="100%" padding="20px">
                            <Grid item>
                                <Typography
                                    variant="subtitle1"
                                    color="primary.main"
                                    fontWeight={700}
                                    lineHeight={1.1}
                                >
                                    Conectar S3 Bucket
                                </Typography>
                                <Typography variant="body1" color="text.primary" mt="5px">
                                    Cambia tu conexión
                                </Typography>
                            </Grid>
                        </Grid>
                    </PaperButton>
                </Grid>
            </Grid>
            <Grid container>
                <Collapse in={alert.open}>
                    <Alert severity={alert.type} sx={{ mt: 2.5 }}>
                        {alert.message}
                    </Alert>
                </Collapse>
            </Grid>
            <Grid item mt="30px">
                <Typography variant="subtitle1" color="primary.main" mb="10px">
                    Historial de órdenes
                </Typography>
                <Divider variant="inset" sx={{ height: '5px' }} />
            </Grid>
            <Grid item xs={12} mt={2}>
                <Typography
                    variant="subtitle2"
                    color="text.primary"
                    maxWidth={948}
                    lineHeight={1.25}
                >
                    Recuerda actualizar tu información de órdenes cada 15 días para tener al día la
                    información total de tus ventas y puedas dispersar tu inversión correctamente.
                </Typography>
            </Grid>
            <Grid container justifyContent={{ xs: 'center', md: 'flex-start' }} spacing={4} mt={0}>
                <Grid item container xs={12} sm={12} md={7} alignItems="center" rowSpacing="11px">
                    <Grid item>
                        <Typography
                            id="manual-upload-status-label"
                            variant="subtitle2"
                            color="text.primary"
                            mr="20px"
                            component="label"
                            htmlFor="manual-upload-status-select"
                        >
                            Filtrar listado por estatus:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm>
                        <Select
                            id="manual-upload-status-select"
                            value={status}
                            onChange={onSelect}
                            displayEmpty
                            labelVariant="none"
                            SelectProps={{
                                SelectDisplayProps: {
                                    'aria-labelledby':
                                        'manual-upload-status-label manual-upload-status-select',
                                },
                            }}
                            sx={{
                                // Needed for legacy filters
                                '.MuiSelect-select': {
                                    maxHeight: 16.4,
                                    alignItems: 'center',
                                    display: 'flex',
                                },
                            }}
                        >
                            <MenuItem value="">Todos los estatus</MenuItem>
                            {statuses.map(({ status: stat, value }) => (
                                <MenuItem key={stat} value={value}>
                                    {stat}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                    <SearchInput
                        label="Busca por fecha o usuario"
                        value={search}
                        onChange={onSearch}
                        fullWidth
                        placeholder="Busca por fecha o usuario"
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} mt="20px">
                <OrdersTable
                    orders={orders}
                    loading={loading}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    count={count}
                    sortOrder={sortOrder}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    onAlert={showAlert}
                    onSort={onSort}
                    externalFilters={{
                        search,
                        ordering: orderDic[sortOrder],
                        status,
                    }}
                    onParamsQueryChange={setRequestParams}
                />
            </Grid>
        </Grid>
    );
};

export default ManualUpload;
