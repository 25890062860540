import { ChangeEventHandler, FocusEventHandler, FC, useContext, useState } from 'react';
import { Grid, Collapse } from '@mui/material';
import { DefaultInput, DefaultBtn, InterpunctLoader, Alert } from '@fairplay2/ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj, simplifyFormErrors } from 'utils/error-handler';
import fairplayAPI from 'utils/api';
import { useDataSources } from 'components/dashboard/data-sources/context/DataSourcesContext';
import SessionContext from 'context/session/sessionContext';
import { toFormData } from 'utils/parsing';
import { FormErrors } from 'utils/interfaces';
import { useAlert } from 'utils/hooks';
import { ManualUploadFormProps, ManualUploadForm as MUF } from '../../interfaces';

const EMPTY_ERRORS = {
    alias: 'Ingresa el nombre del conector',
};

const INITIAL_DATA = {
    alias: '',
};

const INITIAL_ERRORS = {
    ...INITIAL_DATA,
};

const ManualUploadForm: FC<ManualUploadFormProps> = ({
    onCancel,
    awaitingOperation,
    setAwaitingOperation,
    onComplete,
}) => {
    const { selectedCompany } = useContext(SessionContext),
        { dispatch } = useDataSources(),
        [data, setData] = useState<MUF>(INITIAL_DATA),
        [errors, setErrors] = useState<FormErrors<MUF>>(INITIAL_ERRORS),
        { alert, showAlert, hideAlert } = useAlert(),
        blockNextStep = !!errors.alias || !data.alias;

    const onInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name: key, value } = e.target;

        setData((prev) => ({ ...prev, [key]: value }));
        setErrors((prev) => ({ ...prev, [key]: value ? '' : EMPTY_ERRORS[key as 'alias'] }));
    };

    const onInputBlur: FocusEventHandler<HTMLInputElement> = (e) => {
        const { name: key, value } = e.target;
        setErrors((prev) => ({ ...prev, [key]: value ? '' : EMPTY_ERRORS[key as 'alias'] }));
    };

    const onSubmit = async () => {
        setAwaitingOperation(true);
        hideAlert();

        try {
            const res: any = await fairplayAPI.post(
                `/v2/companies/${selectedCompany.company?.id}/manual-upload/select`,
                toFormData({
                    alias: data.alias,
                }),
            );
            dispatch({
                type: 'ADD',
                payload: res?.data?.body?.results,
            });
            onComplete();
        } catch (errResponse: ErrorObj | any) {
            if (errResponse.type === 'form')
                setErrors((prev) => ({
                    ...prev,
                    ...simplifyFormErrors(errResponse.error, true),
                }));
            else
                showAlert(
                    errResponse?.error || 'Se ha producido un error. Intente más tarde',
                    'error',
                );
        } finally {
            setAwaitingOperation(false);
        }
    };

    return (
        <Grid container direction="column" maxWidth={390} mx="auto" rowSpacing={3}>
            <Grid item>
                <DefaultInput
                    labelVariant="external"
                    label="Crea el nombre de tu conector"
                    id="manual-upload-alias"
                    placeholder="Como identificaremos a tu conector"
                    name="alias"
                    value={data.alias}
                    error={errors.alias}
                    onChange={onInputChange}
                    onBlur={onInputBlur}
                />
            </Grid>
            <Grid item mx={-3.5} pt="0 !important">
                <Collapse in={alert.open}>
                    <Alert severity="error" sx={{ mt: 3 }}>
                        {alert.message}
                    </Alert>
                </Collapse>
            </Grid>
            {awaitingOperation ? (
                <Grid item textAlign="center" mt={2}>
                    <InterpunctLoader />
                </Grid>
            ) : (
                <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={[0, 3]}
                    width={[undefined, 'auto']}
                    ml="auto"
                    mt={2}
                    justifyContent="flex-end"
                >
                    <Grid item xs={12} sm={5}>
                        <DefaultBtn size="small" fullWidth variant="outlined" onClick={onCancel}>
                            Cancelar
                        </DefaultBtn>
                    </Grid>
                    <Grid item xs={12} sm="auto">
                        <DefaultBtn
                            size="small"
                            fullWidth
                            onClick={onSubmit}
                            disabled={blockNextStep}
                        >
                            Crear conector
                        </DefaultBtn>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default ManualUploadForm;
