import { forwardRef, lazy, Suspense } from 'react';
import { Grid, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { format, SHORT_READABLE_FORMAT } from 'utils/dates';
import { SUMMARY_KEYS } from '../../utils/constants';
import { MainChartProps } from './interfaces';

const Chart = lazy(
    () =>
        import(
            // eslint-disable-next-line import/extensions
            /* webpackChunkName: 'Chart' */ 'common-components/data-indicators/chart/Chart.component'
        ),
);

const AMMOUNT_MESSAGE = '*Todos los montos están reflejados en pesos mexicanos.';

const MainChart = forwardRef<any, MainChartProps>(
    ({ chartOptions, isEmpty, loading, lastUpdate, tabValue }, chartRef) => {
        const theme = useTheme(),
            smDown = useMediaQuery(theme.breakpoints.down('sm')),
            mdDown = useMediaQuery(theme.breakpoints.down('md')),
            todayDateFormatted = `${format(lastUpdate, `${SHORT_READABLE_FORMAT} HH:mm`, {
                fallbackString: '',
            })} hrs`;

        return (
            <Grid container>
                <Grid item xs={12}>
                    <Suspense
                        fallback={
                            <Skeleton
                                width="100%"
                                sx={{
                                    height: { xs: '400px', sm: '500px', md: '629px' },
                                    borderRadius: '0 0 20px 20px',
                                }}
                            />
                        }
                    >
                        <Chart
                            ref={chartRef}
                            emptyDataMessage="Procesando datos"
                            // eslint-disable-next-line no-nested-ternary
                            height={smDown ? '400px' : mdDown ? '500px' : '629px'}
                            isEmpty={isEmpty}
                            lastUpdate={todayDateFormatted}
                            footerMessage={AMMOUNT_MESSAGE}
                            loading={loading}
                            options={chartOptions}
                            variant="solid"
                            aria-label={`Gráfica de ${SUMMARY_KEYS[tabValue]}`}
                        />
                    </Suspense>
                </Grid>
            </Grid>
        );
    },
);

export default MainChart;
