import { FC } from 'react';
import { styled, Link, Typography } from '@mui/material';
import LightbulbOnIcon from '@fairplay2/ui/assets/svg/icons/LightbulbOnIcon.svg';
import LightbulbOffIcon from '@fairplay2/ui/assets/svg/icons/LightbulbOffIcon.svg';

const StyledIconLink = styled(Link)(({ theme }) => ({
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    top: '180px',
    right: 0,
    backgroundColor: theme.palette.primary.A400,
    borderRadius: '25px 0 0 25px',
    transition: 'width 0.1s ease-out',
    padding: '10px 13px 10px 20px',
    textDecoration: 'none',
    gap: '13px',
    '& svg': {
        width: '25px',
        height: '25px',
    },
    '& .faqs-text': {
        display: 'none',
        fontSize: '14px',
        fontWeight: 'bold',
    },
    '&:hover .faqs-text': {
        display: 'block',
        textDecoration: 'none',
    },
    '& .lightbulb-off': {
        backgroundImage: `url(${LightbulbOffIcon})`,
        backgroundSize: 'cover',
        display: 'block',
        height: '25px',
        width: '25px',
    },
    '& .lightbulb-on': {
        backgroundImage: `url(${LightbulbOnIcon})`,
        backgroundSize: 'cover',
        display: 'none',
        height: '25px',
        width: '25px',
    },
    '&:hover .lightbulb-off': {
        display: 'none',
    },
    '&:hover .lightbulb-on': {
        display: 'block',
    },
}));

export const FAQsBanner: FC = () => (
    <StyledIconLink href="https://guides.getfairplay.com/es-ES/" target="_blank">
        <div className="lightbulb-off" />
        <div className="lightbulb-on" />
        <Typography color="white" className="faqs-text">
            FAQs
        </Typography>
    </StyledIconLink>
);

export default FAQsBanner;
