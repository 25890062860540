import { FC, useState } from 'react';
import { Grid } from '@mui/material';
import { Public, OpenInBrowser, CheckCircle } from '@mui/icons-material';
import { TabGroup, Typography, Alert, DefaultBtn, TabData } from '@fairplay2/ui';
import { useAlert } from 'utils/hooks';
import FileUploader from '../../../manual-upload/components/FileUploader';
import { downloadManualUploadTemplate } from '../../../manual-upload/utils';
import { HybridWizardAbstractProps } from '../../interfaces';
import Confirmation from './Confirmation';
import ManualUploadForm from './ManualUploadForm';

const TABS_DATA: TabData[] = [
    { label: '1. Crear conector', icon: <Public />, disabled: true },
    { label: '2. Confirmar', icon: <CheckCircle />, disabled: true },
    { label: '3. Carga tu archivo', icon: <OpenInBrowser />, disabled: true },
];

const ManualUploadWizard: FC<Omit<HybridWizardAbstractProps, 'element' | 'onSuccess'>> = ({
    onClose,
    awaitingOperation,
    setAwaitingOperation,
}) => {
    const [file, setFile] = useState<File | null>(null),
        fileAlertData = useAlert(),
        [fileIsUploaded, setFileIsUploaded] = useState(false),
        [step, setStep] = useState(0);

    const onTabChange = (_: any, newStep: number) => {
        setStep(newStep);
    };

    return (
        <Grid container>
            <Typography variant="subtitle2" color="text.primary" lineHeight={1.1875} mt={2}>
                Recuerda{' '}
                <DefaultBtn
                    variant="text"
                    onClick={downloadManualUploadTemplate}
                    sx={{
                        fontSize: 'inherit',
                        padding: '0 !important',
                        lineHeight: 'inherit',
                    }}
                >
                    usar esta plantilla
                </DefaultBtn>{' '}
                para crear tu archivo csv. Los siguientes datos son obligatorios:{' '}
                <Typography color="primary.dark" component="span">
                    order__id, order__status, order__number_of_items, order__purchase_date,
                    order__price_breakdown__total, order__price_breakdown__currency.
                </Typography>
            </Typography>
            <TabGroup
                item
                tabs={TABS_DATA}
                value={step}
                onChange={onTabChange}
                tabDirection="column"
                TabsProps={{
                    variant: 'fullWidth',
                }}
                sx={{
                    '.MuiTab-labelIcon': { fontSize: 14 },
                    '.MuiTabs-root': { width: '100%', m: '20px 0px 30px 0px' },
                    '.FpTabPanel-root': {
                        mb: 0,
                    },
                }}
            >
                <ManualUploadForm
                    onCancel={onClose}
                    onComplete={() => setStep(1)}
                    awaitingOperation={awaitingOperation}
                    setAwaitingOperation={setAwaitingOperation}
                />
                <Confirmation type="manual-upload" onClose={() => setStep(2)} />
                <Grid
                    container
                    mx="auto"
                    maxWidth={390}
                    sx={{
                        '.FpInsights-FileUploaderActions': {
                            justifyContent: 'flex-end',
                        },
                    }}
                >
                    <Typography color="text.primary" fontSize={14} mb="10px" maxWidth={315}>
                        Ayúdanos a validar tu información cargando tu primer archivo.
                    </Typography>
                    {fileIsUploaded ? (
                        <>
                            <Alert severity="success">
                                El archivo se ha cargado exitosamente. Podrás verificar el estatus
                                del archivo en el historial de órdenes.
                            </Alert>
                            <Grid item ml="auto" xs={12} sm="auto" mt="31px">
                                <DefaultBtn size="small" fullWidth onClick={onClose}>
                                    Finalizar
                                </DefaultBtn>
                            </Grid>
                        </>
                    ) : (
                        <FileUploader
                            file={file}
                            setFile={setFile}
                            onCancel={onClose}
                            onComplete={() => setFileIsUploaded(true)}
                            awaitingOperation={awaitingOperation}
                            setAwaitingOperation={setAwaitingOperation}
                            alertData={fileAlertData}
                            cancelText="Lo haré después"
                            submitText="Subir archivo"
                        />
                    )}
                </Grid>
            </TabGroup>
        </Grid>
    );
};

export default ManualUploadWizard;
