import { FC } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { DefaultBtn, ToggleBtn, Typography, NationalIcon, InternationalIcon } from '@fairplay2/ui';
import { Step1Props } from '../interfaces';
import { VendorTypeContainer, StyledToggleBtn } from '../styles';

const Step1: FC<Step1Props> = ({ vendorType, onVendorTypeChange, onStepChange }) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <VendorTypeContainer item container marginTop="30px" xs={12} justifyContent="center">
            <StyledToggleBtn
                aria-label="Proveedor Nacional"
                value="national"
                selected={vendorType === 'national'}
                onClick={() => onVendorTypeChange('national')}
                sx={{
                    width: '250px !important',
                    height: '220px !important',
                }}
            >
                <Grid container justifyContent="center">
                    <NationalIcon height="75px" />
                    <Grid item xs={12} mt="20px">
                        <Typography variant="h4" color="primary.main">
                            Proveedor
                            <Typography color="primary.dark" fontWeight={700}>
                                Nacional
                            </Typography>
                        </Typography>
                    </Grid>
                </Grid>
            </StyledToggleBtn>
            <ToggleBtn
                aria-label="Proveedor Internacional"
                value="international"
                selected={vendorType === 'international'}
                onClick={() => onVendorTypeChange('international')}
                sx={{
                    width: '250px !important',
                    height: '220px !important',
                    marginTop: '30px',
                }}
            >
                <Grid container justifyContent="center">
                    <InternationalIcon height="75px" />
                    <Grid item xs={12} mt="20px">
                        <Typography variant="h4" color="primary.main">
                            Proveedor
                            <Typography color="primary.dark" fontWeight={700}>
                                Internacional
                            </Typography>
                        </Typography>
                    </Grid>
                </Grid>
            </ToggleBtn>
            <Grid container justifyContent="center" mt="50px">
                <DefaultBtn
                    fullWidth={smDown}
                    size="small"
                    disabled={!vendorType}
                    onClick={(e) => onStepChange(e, 1)}
                >
                    Continuar
                </DefaultBtn>
            </Grid>
        </VendorTypeContainer>
    );
};

export default Step1;
