import { FC } from 'react';
import { Grid, useTheme, useMediaQuery } from '@mui/material';
import { Paper, Typography, Skeleton } from '@fairplay2/ui';
import { WomanSearching } from 'assets/SVGs';
import { InsertDriveFile } from '@mui/icons-material';
import { ActiveContractsPaperProps } from '../../interfaces';

const ActiveContractsPaper: FC<ActiveContractsPaperProps> = ({ activeContracts, loading }) => {
    const theme = useTheme(),
        smUp = useMediaQuery(theme.breakpoints.up('sm'));

    return loading && !activeContracts ? (
        <Skeleton width={214} height={220} />
    ) : (
        <Paper
            icon={<InsertDriveFile />}
            elevation={1}
            data-testid="no-active-contracts-card"
            variant="elevation"
            decorateIcon
            sx={{
                borderRadius: '10px !important',
                height: '220px !important',
                width: '214px',
                backgroundColor: 'background.light',
            }}
        >
            <Grid
                container
                direction="column"
                justifyContent="flex-end"
                padding="30px 20px"
                height="100%"
            >
                <Typography variant="h4" color="primary.main" fontWeight={700}>
                    {activeContracts} {activeContracts === 1 ? 'contrato' : 'contratos'}
                </Typography>
            </Grid>
            {smUp && (
                <WomanSearching
                    style={{ zIndex: -1, position: 'absolute', top: 20, right: -210 }}
                />
            )}
        </Paper>
    );
};

export default ActiveContractsPaper;
