import { FC } from 'react';
import { Collapse, Grid, useMediaQuery, useTheme } from '@mui/material';
import {
    Checkbox,
    Alert,
    DefaultBtn,
    Typography,
    DefaultInput,
    InterpunctLoader,
    Skeleton,
    NoResults,
} from '@fairplay2/ui';
import { ConfirmProps } from '../../interfaces.v1';

interface SalesConfirmationProps extends ConfirmProps {
    bankAccount: any;
}

const SalesAdvancementDetail: FC<SalesConfirmationProps> = ({
    bankAccount,
    alert,
    onStepChange,
    loading,
    onConfirmBankAccount,
    bankAccountConfirmation,
}) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm'));

    // eslint-disable-next-line no-nested-ternary
    return bankAccount?.clabe ? (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} mt="20px">
                    <Typography variant="subtitle1" color="primary.dark" fontWeight={500}>
                        Esta es la cuenta bancaria a la que se realizará el deposito ¿Es correcto?
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <DefaultInput
                        labelVariant="external"
                        label="CLABE Interbancaria"
                        id="disbursement-clabe"
                        disabled
                        value={bankAccount.clabe}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DefaultInput
                        labelVariant="external"
                        label="Banco"
                        id="disbursement-bank"
                        disabled
                        value={bankAccount.bank || ''}
                    />
                </Grid>
                <Grid item xs={12} display="flex">
                    <Checkbox
                        checked={bankAccountConfirmation}
                        id="disbursement-confirmation"
                        onClick={onConfirmBankAccount}
                        name="bank-info"
                    />
                    <Typography
                        htmlFor="disbursement-confirmation"
                        component="label"
                        variant="label"
                        sx={{ margin: '5px' }}
                    >
                        Confirmo que mis datos bancarios asociados son correctos
                    </Typography>
                </Grid>
                <Grid item container xs={12} justifyContent="flex-end">
                    <Collapse in={alert.open}>
                        <Alert severity={alert.type}>{alert.message}</Alert>
                    </Collapse>
                </Grid>
            </Grid>
            <Grid item container xs={12} justifyContent="flex-end" mt="35px" mb="20px">
                {loading ? (
                    <Grid
                        item
                        container
                        xs={12}
                        justifyContent={{ xs: 'center', sm: 'flex-end' }}
                        data-testid="sales-advancement-loader"
                    >
                        <InterpunctLoader />
                    </Grid>
                ) : (
                    <>
                        <DefaultBtn
                            variant="outlined"
                            fullWidth={smDown}
                            sx={{
                                mb: { xs: '10px', sm: 0 },
                            }}
                            size="small"
                            onClick={(e) => onStepChange(e, 0)}
                        >
                            Atrás
                        </DefaultBtn>
                        <DefaultBtn
                            fullWidth={smDown}
                            sx={{
                                mb: { xs: '20px', sm: 0 },
                                ml: { xs: 0, sm: '20px' },
                            }}
                            size="small"
                            disabled={!bankAccountConfirmation}
                            type="submit"
                        >
                            Solicitar
                        </DefaultBtn>
                    </>
                )}
            </Grid>
        </>
    ) : bankAccount !== undefined ? (
        <Grid container>
            <NoResults>No hemos encontrado cuentas bancarias asociadas</NoResults>
        </Grid>
    ) : (
        <Skeleton height={100} data-testid="sales-advancement-detail-skeleton" />
    );
};

export default SalesAdvancementDetail;
