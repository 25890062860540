import { ChangeEventHandler, FocusEventHandler, FC, useContext } from 'react';
import { Collapse, Grid } from '@mui/material';
import { DefaultInput, PasswordInput, DefaultBtn, InterpunctLoader, Alert } from '@fairplay2/ui';
import fairplayAPI from 'utils/api';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj, simplifyFormErrors } from 'utils/error-handler';
import { toFormData } from 'utils/parsing';
import { useDataSources } from 'components/dashboard/data-sources/context/DataSourcesContext';
import SessionContext from 'context/session/sessionContext';
import { S3AuthFormProps } from '../../interfaces';

const EMPTY_ERRORS = {
    awsAccessKeyId: 'Ingresa la clave de acceso',
    awsSecretAccessKey: 'Ingresa la contraseña',
};

const S3AuthForm: FC<S3AuthFormProps> = ({
    onCancel,
    data,
    errors,
    setData,
    setErrors,
    alertData: { alert, showAlert, hideAlert },
    awaitingOperation,
    setAwaitingOperation,
    onComplete,
}) => {
    const { selectedCompany } = useContext(SessionContext),
        { baseAPI, dispatch } = useDataSources(),
        blockNextStep =
            !!errors.awsAccessKeyId ||
            !!errors.awsSecretAccessKey ||
            !data.awsAccessKeyId ||
            !data.awsSecretAccessKey;

    const onInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name: key, value } = e.target;

        setData((prev) => ({ ...prev, [key]: value }));
        setErrors((prev) => ({
            ...prev,
            [key]: value ? '' : EMPTY_ERRORS[key as 'awsSecretAccessKey'],
        }));
    };

    const onInputBlur: FocusEventHandler<HTMLInputElement> = (e) => {
        const { name: key, value } = e.target;
        setErrors((prev) => ({
            ...prev,
            [key]: value ? '' : EMPTY_ERRORS[key as 'awsSecretAccessKey'],
        }));
    };

    const onSubmit = async () => {
        setAwaitingOperation(true);
        hideAlert();

        try {
            const res: any = await fairplayAPI.post(
                data.id
                    ? `${baseAPI}/${data.id}/reconnect`
                    : `/v2/companies/${selectedCompany.company?.id}/manual-upload-s3/select`,
                toFormData({
                    aws_access_key_id: data.awsAccessKeyId,
                    aws_secret_access_key: data.awsSecretAccessKey,
                    aws_region: data.awsRegion,
                    bucket: data.bucket,
                    platform: 's3connector',
                }),
            );

            dispatch({
                type: data.id ? 'UPDATE' : 'ADD',
                payload: res?.data?.body?.results,
            });

            onComplete();
        } catch (errResponse: ErrorObj | any) {
            if (errResponse.type === 'form')
                setErrors((prev) => ({
                    ...prev,
                    ...simplifyFormErrors(errResponse.error, true),
                }));
            else
                showAlert(
                    errResponse?.error ||
                        'No se pudo realizar la conexión, verifique sus credenciales e intente nuevamente',
                    'error',
                );
        } finally {
            setAwaitingOperation(false);
        }
    };

    return (
        <Grid container direction="column" maxWidth={390} mx="auto" rowSpacing={3}>
            <Grid item>
                <DefaultInput
                    id="s3-access-key"
                    labelVariant="external"
                    label="AWS Access Key"
                    placeholder="Ingresa tu clave de acceso"
                    name="awsAccessKeyId"
                    value={data.awsAccessKeyId}
                    error={errors.awsAccessKeyId}
                    onChange={onInputChange}
                    onBlur={onInputBlur}
                />
            </Grid>
            <Grid item>
                <PasswordInput
                    id="s3-secret"
                    labelVariant="external"
                    label="AWS Secret"
                    placeholder="Ingresa tu contraseña"
                    name="awsSecretAccessKey"
                    value={data.awsSecretAccessKey}
                    error={errors.awsSecretAccessKey}
                    onChange={onInputChange}
                    onBlur={onInputBlur}
                    forceHiddenText={awaitingOperation}
                    titleAccess={{
                        show: 'Mostrar AWS Secret',
                        hide: 'Ocultar AWS Secret',
                    }}
                />
            </Grid>
            <Grid item mx={-3.5} pt="0 !important">
                <Collapse in={alert.open}>
                    <Alert severity="error" sx={{ mt: 3 }}>
                        {alert.message}
                    </Alert>
                </Collapse>
            </Grid>
            {awaitingOperation ? (
                <Grid item textAlign="center" mt={2}>
                    <InterpunctLoader sx={{ display: 'inline-block' }} />
                </Grid>
            ) : (
                <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={[0, 3]}
                    width={[undefined, 'auto']}
                    ml="auto"
                    mt={2}
                    justifyContent="flex-end"
                >
                    <Grid item xs={12} sm={5}>
                        <DefaultBtn size="small" fullWidth variant="outlined" onClick={onCancel}>
                            Cancelar
                        </DefaultBtn>
                    </Grid>
                    <Grid item container xs={12} sm={5}>
                        <DefaultBtn
                            size="small"
                            fullWidth
                            onClick={onSubmit}
                            disabled={blockNextStep}
                        >
                            Siguiente
                        </DefaultBtn>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default S3AuthForm;
