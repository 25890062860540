import { ChangeEventHandler, FC } from 'react';
import { Grid, TableCell, TableRow, Typography } from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import { HiddenInput } from '@fairplay2/ui';
import { UploadDocTableItemProps } from '../interfaces';

const UploadDocTableItem: FC<UploadDocTableItemProps> = ({
    setSelectedDocument,
    onFileSelect,
    type,
    documentTypes,
}) => {
    const docId = `document-${type.id}`;

    const onFileChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        const file = event.target.files?.[0] || null,
            fileTypeId = event.target.id?.split('document-')[1];

        setSelectedDocument((prev) => ({
            ...prev,
            type: documentTypes.find((documentType) => documentType.id === fileTypeId)!,
        }));

        onFileSelect(file);
        // Allow re-selection of the same file, triggering the change event again
        event.target.value = '';
    };

    return (
        <TableRow data-testid="file-upload-row">
            <TableCell colSpan={4}>
                <HiddenInput id={docId} type="file" onChange={onFileChange} accept="image/*,.pdf" />
                <label htmlFor={docId}>
                    <Grid container alignItems="center" sx={{ cursor: 'pointer' }}>
                        <Grid item>
                            <AddCircle color="primary" />
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="body1"
                                color="primary.main"
                                ml="5px"
                                sx={{ textDecoration: 'underline' }}
                            >
                                Agregar {type.name} {type.isOptional ? '' : '*'}
                            </Typography>
                        </Grid>
                    </Grid>
                </label>
            </TableCell>
        </TableRow>
    );
};

export default UploadDocTableItem;
