import { forwardRef } from 'react';
import { Typography } from '@fairplay2/ui';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { GrowthIndicatorProps } from './GrowthIndicator.types';

const ArrowStyle = {
    mb: '-4px',
    ml: '-2px',
    mr: '14px',
};

export const GrowthIndicator = forwardRef<HTMLDivElement, GrowthIndicatorProps>(
    (
        {
            indicator = 'below',
            percentage,
            children,
            color = indicator === 'over' ? 'secondary.main' : 'error.main',
        },
        ref,
    ) => (
        <Grid container spacing={2} ref={ref}>
            <Grid item>
                <Typography variant="h4" color={color} fontWeight={500}>
                    {indicator === 'over' ? (
                        <ArrowDropUp sx={ArrowStyle} />
                    ) : (
                        <ArrowDropDown sx={ArrowStyle} />
                    )}
                    {`${percentage}%`}
                </Typography>
            </Grid>
            <Grid item mt="10px">
                <Typography color="text.primary" variant="body2">
                    {children}
                </Typography>
            </Grid>
        </Grid>
    ),
);
