import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import { KeyboardArrowLeft, Mail } from '@mui/icons-material';
import { IconBtn, Typography, Divider, Skeleton, DefaultBtn } from '@fairplay2/ui';

const PageSkeleton: FC = () => {
    const history = useHistory();

    return (
        <Grid container>
            <Grid item container xs={12} marginTop="10px" justifyContent="space-between">
                <Grid
                    item
                    container
                    xs="auto"
                    alignItems="center"
                    alignContent="center"
                    marginBottom={3}
                    marginRight={1}
                    flexWrap="nowrap"
                    maxWidth="100%"
                >
                    <IconBtn
                        variant="filled"
                        color="primary"
                        size="small"
                        sx={{
                            p: '0 !important',
                            svg: {
                                fontSize: '1.5em',
                            },
                        }}
                        onClick={() => history.push('/app/financing/contract/')}
                    >
                        <KeyboardArrowLeft />
                    </IconBtn>
                    <Typography
                        color="primary.dark"
                        variant="h4"
                        fontWeight={700}
                        ml="15px"
                        mr="5px"
                    >
                        Contrato
                    </Typography>
                    <Skeleton width={100} height={24} />
                </Grid>
                <Skeleton variant="rectangular" width={120} height={40} />
            </Grid>
            {/* <Grid item container xs={12} spacing={4} mt="50px">
                <Grid item xs={12} md={6} lg={3}>
                    <Skeleton variant="rectangular" height={125} />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Skeleton variant="rectangular" height={125} />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Skeleton variant="rectangular" height={125} />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Skeleton variant="rectangular" height={125} />
                </Grid>
            </Grid> */}
            <Grid item container>
                <Grid item>
                    <Grid margin="37px 25px 15px 0px">
                        <Skeleton variant="rectangular" height={20} width={150} />
                    </Grid>
                    <Divider variant="inset" />
                </Grid>
                <Grid item>
                    <Grid margin="37px 25px 15px 0px">
                        <Skeleton variant="rectangular" height={20} width={150} />
                    </Grid>
                    <Divider variant="inset" />
                </Grid>
                <Grid item>
                    <Grid margin="37px 25px 15px 0px">
                        <Skeleton variant="rectangular" height={20} width={150} />
                    </Grid>
                    <Divider variant="inset" />
                </Grid>
            </Grid>
            <Grid item container spacing={4} mt="30px">
                <Grid item xs={12}>
                    <Typography color="primary.dark" fontWeight={700}>
                        Generales
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
                <Grid item xs={12}>
                    <Typography color="primary.dark" fontWeight={700}>
                        Términos de financiamiento
                    </Typography>
                </Grid>
                <Grid item container xs={12} spacing={4}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Skeleton variant="rectangular" height={50} />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
                <Grid item xs={12}>
                    <Typography color="primary.dark" fontWeight={700}>
                        Términos de dispersión
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
                <Grid item xs={12}>
                    <Typography color="primary.dark" fontWeight={700}>
                        Términos de pago
                    </Typography>
                </Grid>
                <Grid item container xs={12} spacing={4}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Skeleton variant="rectangular" height={50} />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
                <Grid item xs={12}>
                    <Typography color="primary.dark" fontWeight={700}>
                        Condiciones del contrato
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
                <Grid item container xs={12} justifyContent="space-between" alignItems="center">
                    <Grid item xs={6}>
                        <DefaultBtn
                            variant="outlined"
                            size="small"
                            onClick={() => history.push('/app/financing/contract/')}
                        >
                            Volver a listado
                        </DefaultBtn>
                    </Grid>
                    <Grid item xs={6} container direction="column" alignItems="flex-end">
                        <Typography variant="body1" color="text.primary">
                            ¿Tienes dudas o aclaraciones? ¡Contáctanos!
                        </Typography>
                        <DefaultBtn
                            startIcon={<Mail />}
                            href="mailto:cusu@getfairplay.com"
                            sx={{
                                textTransform: 'none !important',
                            }}
                            variant="text"
                        >
                            Ejecutivo de CuSu
                        </DefaultBtn>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PageSkeleton;
