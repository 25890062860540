import { FC, Fragment, useState, useEffect, ChangeEvent, SyntheticEvent } from 'react';
import { SxProps } from '@mui/system';
import { Grid, MenuItem, Grow, Collapse, useTheme, useMediaQuery } from '@mui/material';
import {
    DefaultBtn,
    SearchInput,
    Select,
    Typography,
    Alert,
    NoResults,
    InterpunctLoader,
    Skeleton,
} from '@fairplay2/ui';
import {
    itemsContainerSx,
    nonClickableItemSx,
} from 'components/dashboard/data-sources/platforms/components/styles';
import fairplayAPI from 'utils/api';
import { useAlert } from 'utils/hooks';
import { toFormData } from 'utils/parsing';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj, getMainError } from 'utils/error-handler';
import { useDataSources } from '../../../context/DataSourcesContext';
import { ConfigureConnectorProps } from './interfaces';
import LoadersAccounts from './LoadersAccounts';
import DropdownListItem from './DropdownListItem';
import CheckboxListItem from './CheckboxListItem';
import {
    searchGoogleAccount,
    compareGoogleAsc,
    compareGoogleDesc,
    getRequestPayload,
    GoogleAccountItem,
    unifyGoogleStructure,
} from './utils';

const ConfigureGoogle: FC<ConfigureConnectorProps> = ({ element, onConfirm, onClose }) => {
    const { baseAPI, dispatch } = useDataSources(),
        theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        [searchInput, setSearchInput] = useState(''),
        [sort, setSort] = useState(''),
        [loading, setLoading] = useState(false),
        [availableAccounts, setAvailableAccounts] = useState<GoogleAccountItem[]>([]),
        [filteredAvailableAccounts, setFilteredAvailableAccounts] = useState<
            GoogleAccountItem[] | undefined
        >(undefined),
        [pendingAccounts, setPendingAccounts] = useState<GoogleAccountItem[]>([]),
        [filteredPendingAccounts, setFilteredPendingAccounts] = useState<
            GoogleAccountItem[] | undefined
        >(undefined),
        [checked, setChecked] = useState<{ [k: string]: boolean }>({}),
        [selectedIndex, setSelectedIndex] = useState(-1),
        { alert, hideAlert, showAlert } = useAlert(),
        loadAccounts = !filteredAvailableAccounts || !filteredPendingAccounts;

    const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
        if (loadAccounts) return;

        const byGoogleAccount = searchGoogleAccount(event.target.value);

        setSearchInput(event.target.value);

        const newFilteredAvailableAccounts = availableAccounts.filter(byGoogleAccount);
        setFilteredAvailableAccounts(newFilteredAvailableAccounts);
        if (pendingAccounts?.length) {
            setFilteredPendingAccounts(pendingAccounts.filter(byGoogleAccount));
        }
        setSelectedIndex((prev) => {
            if (newFilteredAvailableAccounts.some((account) => account.index === prev)) return prev;
            return -1;
        });
    };

    const onSort = (event: ChangeEvent<HTMLInputElement>) => {
        if (loadAccounts) return;

        const criteria = event.target.value;
        setSort(criteria);

        if (criteria === 'asc') filteredAvailableAccounts.sort(compareGoogleAsc);
        else if (criteria === 'desc') filteredAvailableAccounts.sort(compareGoogleDesc);

        if (pendingAccounts?.length) {
            if (criteria === 'asc') filteredPendingAccounts.sort(compareGoogleAsc);
            else if (criteria === 'desc') filteredPendingAccounts.sort(compareGoogleDesc);
        }
    };

    const onSave = async (e: SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();

        hideAlert();
        setLoading(true);

        const selected: any = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const property in checked) {
            if (checked[property]) selected.push(property);
        }

        if (selected.length === 0) {
            setLoading(false);
            showAlert('Debes seleccionar al menos una propiedad/app');
            return;
        }

        try {
            const response: any = await fairplayAPI.patch(
                `${baseAPI}/${element?.id}`,
                toFormData({
                    accounts: JSON.stringify(getRequestPayload(availableAccounts, selected)),
                }),
            );
            onConfirm();
            dispatch({ type: 'UPDATE', payload: response.data.body.results });
        } catch (errResponse: ErrorObj | any) {
            if (errResponse?.error?.accounts) showAlert(getMainError(errResponse.error.accounts));
            else showAlert(errResponse?.error);
        } finally {
            setLoading(false);
        }
    };

    const onCheck = (event: ChangeEvent<HTMLInputElement>) => {
        setChecked((prev) => ({ ...prev, [event.target.value]: event.target.checked }));
    };

    const fetchAccounts = async () => {
        setFilteredAvailableAccounts(undefined);
        if (baseAPI && element?.id) {
            try {
                const response: any = await fairplayAPI.get(`${baseAPI}/${element?.id}/accounts`);
                const {
                    accounts,
                    pendingAccounts: unifiedPendingAccounts,
                    isSelected,
                } = unifyGoogleStructure(response.data.body.results);
                setAvailableAccounts(accounts);
                // Spreading the array to avoid both variables sharing the same reference
                setFilteredAvailableAccounts([...accounts]);

                setPendingAccounts(unifiedPendingAccounts || []);
                // Spreading the array to avoid both variables sharing the same reference
                setFilteredPendingAccounts([...(unifiedPendingAccounts || [])]);
                setChecked(isSelected);
            } catch (errResponse: ErrorObj | any) {
                if (errResponse.type !== 'undefined')
                    showAlert(errResponse?.error || errResponse?.message);
                else showAlert('No se pudieron obtener las cuentas');
                setFilteredAvailableAccounts([]);
                setFilteredPendingAccounts([]);
            }
        }
    };

    useEffect(() => {
        fetchAccounts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [element]);

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                    {loadAccounts ? (
                        <Skeleton height={53} />
                    ) : (
                        <SearchInput
                            type="text"
                            label="Buscar por ID o nombre de cuenta"
                            value={searchInput}
                            fullWidth
                            onChange={onSearch}
                            placeholder="Buscar por ID o nombre de cuenta"
                        />
                    )}
                </Grid>

                <Grid item xs={12} sm={6}>
                    {loadAccounts ? (
                        <Skeleton height={53} />
                    ) : (
                        <Select
                            label="Orden de las cuentas"
                            value={sort}
                            onChange={onSort}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>
                                Seleccionar
                            </MenuItem>
                            <MenuItem value="asc">Orden alfabético: A-Z</MenuItem>
                            <MenuItem value="desc">Orden alfabético: Z-A</MenuItem>
                        </Select>
                    )}
                </Grid>

                <Grid
                    item
                    container
                    spacing={4}
                    xs={12}
                    component="fieldset"
                    sx={{ border: 'none', pr: 0, pb: 0 }}
                >
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" color="primary.dark" fontWeight={700}>
                            {element?.platform?.includes('ads')
                                ? 'Google Ads '
                                : 'Google Analytics '}
                            <Typography component="span" color="primary.main" fontWeight={400}>
                                Selecciona la cuenta (Haz clic)
                            </Typography>
                        </Typography>
                    </Grid>

                    <Grow
                        in={selectedIndex !== -1}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(selectedIndex !== -1 ? { timeout: 1000 } : {})}
                    >
                        <Grid item xs={12} sm={6} sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <Typography
                                id="apps-and-properties-title"
                                variant="subtitle2"
                                color="text.primary"
                            >
                                Marca las propiedades y apps
                            </Typography>
                        </Grid>
                    </Grow>

                    <Grid item xs={12} sm={6} sx={itemsContainerSx as SxProps} component="ul">
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {loadAccounts ? (
                            <LoadersAccounts />
                        ) : filteredAvailableAccounts.length || filteredPendingAccounts.length ? (
                            <>
                                {filteredAvailableAccounts.map((item) => (
                                    <Fragment key={item.effectiveId}>
                                        {item.children?.length ? (
                                            <DropdownListItem
                                                text={`${item.readableId}${
                                                    item.auxReadableId
                                                        ? ` (${item.auxReadableId})`
                                                        : ''
                                                }`}
                                                onClick={() => setSelectedIndex(item.index)}
                                                isSelected={!smDown && item.index === selectedIndex}
                                            >
                                                {smDown &&
                                                    item.children?.map((child: any) => (
                                                        <CheckboxListItem
                                                            key={child.effectiveId}
                                                            leftText={child.readableId}
                                                            rightText={child.auxReadableId}
                                                            inputValue={child.effectiveId}
                                                            checked={checked[child.effectiveId]}
                                                            onCheck={onCheck}
                                                        />
                                                    ))}
                                            </DropdownListItem>
                                        ) : (
                                            <CheckboxListItem
                                                leftText={item.readableId}
                                                inputValue={item.effectiveId}
                                                checked={checked[item.effectiveId]}
                                                onCheck={onCheck}
                                            />
                                        )}
                                    </Fragment>
                                ))}
                                {filteredPendingAccounts.map((item: any) => (
                                    <Grid
                                        component="li"
                                        key={item.effectiveId}
                                        sx={nonClickableItemSx as SxProps}
                                    >
                                        <Typography variant="body1" color="text.primary">
                                            {item.readableId} - Pendiente
                                        </Typography>
                                    </Grid>
                                ))}
                            </>
                        ) : (
                            <NoResults>
                                <Typography variant="body1" color="text.primary">
                                    No hemos encontrado cuentas disponibles, por favor vuelve a
                                    intentarlo o prueba con otra cuenta de Google.
                                </Typography>
                            </NoResults>
                        )}
                    </Grid>

                    {/* Show all subaccounts  */}

                    <Grow
                        in={selectedIndex !== -1}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(selectedIndex !== -1 ? { timeout: 1000 } : {})}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={itemsContainerSx as SxProps}
                            component="ul"
                            aria-describedby="apps-and-properties-title"
                            aria-live="assertive"
                            aria-hidden={selectedIndex === -1}
                        >
                            {!smDown &&
                                availableAccounts[selectedIndex]?.children?.map((item) => (
                                    <CheckboxListItem
                                        key={item.effectiveId}
                                        leftText={item.readableId}
                                        rightText={item.auxReadableId}
                                        inputValue={item.effectiveId}
                                        checked={checked[item.effectiveId]}
                                        onCheck={onCheck}
                                    />
                                ))}
                        </Grid>
                    </Grow>
                </Grid>
            </Grid>

            <Grid item container xs={12} justifyContent="center">
                <Collapse in={alert.open} sx={{ margin: '20px 0px' }}>
                    <Grid container item xs={12}>
                        <Alert severity={alert.type}>{alert.message}</Alert>
                    </Grid>
                </Collapse>
            </Grid>

            <Grid
                item
                container
                xs={12}
                justifyContent={{ xs: 'center', sm: 'flex-end' }}
                mt="10px"
            >
                {loading ? (
                    <InterpunctLoader />
                ) : (
                    <DefaultBtn
                        size="small"
                        onClick={availableAccounts.length ? onSave : onClose}
                        fullWidth={smDown}
                        disabled={loadAccounts}
                    >
                        {availableAccounts.length ? 'Siguiente' : 'Cerrar'}
                    </DefaultBtn>
                )}
            </Grid>
        </>
    );
};

export default ConfigureGoogle;
