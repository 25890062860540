import { Grid } from '@mui/material';
import { Skeleton } from '@fairplay2/ui';

const DisbursementOptionsSkeleton = () => (
    <>
        <Grid item xs={12} mt="40px" sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
            <Skeleton width={314} height={10} />
        </Grid>

        <Grid
            item
            container
            xs={12}
            justifyContent={{ xs: 'center', md: 'flex-start' }}
            alignItems={{ xs: 'center', md: 'flex-end' }}
            flexDirection={{ xs: 'column', md: 'row' }}
            mt="20px"
            mb="20px"
        >
            <Grid item position="relative" sx={{ margin: { xs: '10px 0', md: '0 20px 0 0' } }}>
                <Skeleton width={214} height={220} />
            </Grid>
            <Grid item position="relative" sx={{ margin: { xs: '10px 0', md: '0 20px 0 0' } }}>
                <Skeleton width={214} height={220} />
            </Grid>
            <Grid item position="relative" sx={{ margin: { xs: '10px 0', md: '0 20px 0 0' } }}>
                <Skeleton width={214} height={220} />
            </Grid>
        </Grid>
    </>
);
export default DisbursementOptionsSkeleton;
