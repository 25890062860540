import { FC, useState, useEffect, useContext, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, useMediaQuery, useTheme, Collapse } from '@mui/material';
import fairplayAPI from 'utils/api';
import SessionContext from 'context/session/sessionContext';
import {
    DefaultInput,
    Typography,
    IconBtn,
    PaperButton,
    Skeleton,
    Alert,
    Paper,
} from '@fairplay2/ui';
import { StatusType, Status } from 'common-components';
import { MoneyTabletWomanSvg } from 'assets/SVGs';
import { GetApp, KeyboardArrowLeft } from '@mui/icons-material';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { contractPrintableName } from 'utils/formatting';
import { formatUtc, SHORT_READABLE_FORMAT } from 'utils/dates';
import { useAlert } from 'utils/hooks/useAlert';
import { formatAmount, openLink, downloadSlip, getStatusColor } from '../utils';
import { DisbDetail } from '../../interfaces';
import DisbursementDetailSkeleton from './DisbursementDetailSkeleton';

const DISBURSEMENTS_DIRECTORY_ROUTE = '/app/disbursements/transactions';

const DisbursementDetail: FC = () => {
    const { push, location } = useHistory(),
        { id } = useParams<{ id: string }>(),
        theme = useTheme(),
        isFirstRender = useRef(true),
        mdUp = useMediaQuery(theme.breakpoints.up('md')),
        { selectedCompany } = useContext(SessionContext),
        [disb, setDisb] = useState<DisbDetail | null>(null),
        [loading, setLoading] = useState(true),
        { alert, hideAlert, showAlert } = useAlert();

    const fetchDisbDetail = async (contractId: string, disbId: string) => {
        try {
            hideAlert();
            const res: any = await fairplayAPI.get(
                `/v2/companies/${selectedCompany.company?.id}/contracts/${contractId}/dispersions/${disbId}`,
            );
            setDisb(res.data.body.results);
            setLoading(false);
        } catch (errResponse: ErrorObj | any) {
            setLoading(false);
            if (
                errResponse?.status === 404 &&
                location.pathname.includes('/app/disbursements/transactions/detail/')
            )
                push(DISBURSEMENTS_DIRECTORY_ROUTE);
            else showAlert(errResponse.error);
        }
    };

    useEffect(() => {
        if (!selectedCompany.company) return;
        if (!isFirstRender.current) {
            push('/app/sources');
            return;
        }

        isFirstRender.current = false;
    }, [selectedCompany, push]);

    useEffect(() => {
        const [contractId, disbId] = id.split(':');
        fetchDisbDetail(contractId, disbId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <Grid container>
            <Grid item container sm={12} md={8} alignItems="center" wrap="nowrap">
                <IconBtn
                    aria-label="Volver a dispersiones"
                    variant="filled"
                    color="primary"
                    size="small"
                    sx={{
                        p: '0 !important',
                        svg: {
                            fontSize: '1.5em',
                        },
                    }}
                    onClick={() => push(DISBURSEMENTS_DIRECTORY_ROUTE)}
                >
                    <KeyboardArrowLeft />
                </IconBtn>
                <Typography variant="h4" component="h2" color="primary.main" ml="15px">
                    Detalle de dispersión para{' '}
                    <Typography component="span" color="primary.dark" fontWeight={700}>
                        {disb?.type === 'fairplay-card' ? 'Tarjeta Fairplay' : 'Factura única'}
                    </Typography>
                </Typography>
            </Grid>
            <Grid item container xs={12} md={9} mt="28px" wrap="nowrap">
                <Paper
                    color="grey"
                    variant="elevation"
                    sx={{
                        p: { xs: '20px', md: '20px 75px 34px 31px' },
                    }}
                >
                    <Typography variant="subtitle2" color="text.primary" fontWeight={500} mb="15px">
                        Resumen
                    </Typography>
                    <Typography variant="subtitle2" color="text.primary" fontWeight={500}>
                        La transferencia asociada al contrato activo número:{' '}
                        <Typography component="span" color="primary.dark" fontWeight={700}>
                            {contractPrintableName(disb?.contract).replace(/^#/, '')}{' '}
                        </Typography>
                        de la factura única con referencia:{' '}
                        <Typography component="span" color="primary.dark" fontWeight={700}>
                            {disb?.reference}{' '}
                        </Typography>
                        se encuentra:{' '}
                        <Typography
                            component="span"
                            fontWeight={700}
                            color={`${getStatusColor(disb?.status?.id || '')}`}
                        >
                            {disb?.status?.text || ''}
                        </Typography>
                    </Typography>
                </Paper>
                {mdUp && (
                    <Grid item>
                        <MoneyTabletWomanSvg
                            style={{ zIndex: 1, position: 'relative', top: 5, right: 74 }}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid
                item
                container
                xs={12}
                md={3}
                justifyContent="flex-end"
                alignItems="flex-start"
                mt={{ xs: '20px', sm: '28px' }}
            >
                {loading ? (
                    <Skeleton
                        variant="rectangular"
                        width={145}
                        height={54}
                        data-testid="disbursemet-detail-skeleton"
                    />
                ) : (
                    disb?.status && (
                        <Status variant="standard" status={disb?.status?.id as StatusType}>
                            {disb?.status?.text || ''}
                        </Status>
                    )
                )}
            </Grid>
            <Collapse in={alert.open}>
                <Grid item container xs={12} mt="20px">
                    <Alert severity={alert.type}>{alert.message}</Alert>
                </Grid>
            </Collapse>
            {loading ? (
                <DisbursementDetailSkeleton />
            ) : (
                <Grid item container xs={12} spacing={4} justifyContent="center" mt="16px">
                    <Grid item xs={12} sm={6}>
                        <DefaultInput
                            labelVariant="external"
                            label="Tipo de dispersión"
                            disabled
                            id="disbursement-type"
                            value={
                                disb?.type === 'fairplay-card'
                                    ? 'Tarjeta Fairplay'
                                    : 'Factura única'
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DefaultInput
                            id="disbursement-id"
                            labelVariant="external"
                            label="ID de dispersión"
                            disabled
                            value={disb?.id || ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DefaultInput
                            id="disbursement-request-date"
                            labelVariant="external"
                            label="Fecha de solicitud"
                            disabled
                            value={formatUtc(disb?.date || '', SHORT_READABLE_FORMAT, {
                                fallbackString: '',
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DefaultInput
                            id="disbursement-date"
                            labelVariant="external"
                            label="Fecha de ejecución"
                            disabled
                            value={formatUtc(disb?.executionDate || '', SHORT_READABLE_FORMAT, {
                                fallbackString: '',
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DefaultInput
                            id="disbursement-requested-amount"
                            labelVariant="external"
                            label="Monto solicitado"
                            disabled
                            value={`${disb?.currency?.id || ''} ${
                                disb?.amount && formatAmount(disb?.amount)
                            }`}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DefaultInput
                            id="disbursement-amount"
                            labelVariant="external"
                            label="Monto dispersado"
                            disabled
                            value={
                                disb?.dispersedAmount
                                    ? `${disb?.dispersedCurrency} ${formatAmount(
                                          disb?.dispersedAmount,
                                      )}`
                                    : 'No ha sido dispersado'
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {disb?.contract?.productType === 'sales-advancement' ? (
                            <DefaultInput
                                id="disbursement-recipient-name"
                                labelVariant="external"
                                label="Transferencia destinada a"
                                disabled
                                multiline
                                rows={3}
                                value={`${selectedCompany.company?.businessName || ''}\n${
                                    selectedCompany.company?.registeredName || ''
                                }\n${selectedCompany.company?.rfc || ''}`}
                            />
                        ) : (
                            <DefaultInput
                                id="disbursement-recipient-name"
                                labelVariant="external"
                                label="Transferencia destinada a"
                                disabled
                                multiline
                                rows={3}
                                value={`${disb?.supplier?.alias || ''}\n${
                                    disb?.supplier?.registeredName || ''
                                }\n${disb?.supplier?.taxId || ''}`}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DefaultInput
                            id="disbursement-concept"
                            labelVariant="external"
                            label="Por concepto de"
                            disabled
                            value={disb?.concept || ''}
                        />
                    </Grid>
                </Grid>
            )}
            <Grid item container xs={12} m="20px 0">
                {loading ? (
                    <>
                        <Grid item>
                            <Skeleton
                                variant="rectangular"
                                width={214}
                                height={220}
                                sx={{
                                    m: '10px 20px 0 0',
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Skeleton
                                variant="rectangular"
                                width={214}
                                height={220}
                                sx={{
                                    m: '10px 20px 0 0',
                                }}
                            />
                        </Grid>
                    </>
                ) : (
                    <>
                        {disb?.type !== 'fairplay-card' && disb?.invoiceFile && (
                            <Grid item>
                                <PaperButton
                                    icon={<GetApp />}
                                    iconAnimation="down"
                                    onClick={() => openLink(disb?.invoiceFile)}
                                    variant="elevation"
                                    sx={{
                                        bgcolor: 'background.secondary',
                                        width: '220px',
                                        height: '220px',
                                        m: { xs: '10px 0', md: '0 20px 0 0' },
                                    }}
                                >
                                    <Grid container alignItems="flex-end" height="100%" p="20px">
                                        <Grid item>
                                            <Typography
                                                variant="subtitle1"
                                                color="primary.main"
                                                fontWeight={700}
                                                lineHeight={1.1}
                                                mb="5px"
                                            >
                                                Ver el PDF asociado
                                            </Typography>
                                            <Typography variant="body1" color="text.primary">
                                                A la transferencia
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </PaperButton>
                            </Grid>
                        )}

                        {disb?.status?.id === 'executed' && disb?.bankTransferStatementFile && (
                            <Grid item>
                                <PaperButton
                                    icon={<GetApp />}
                                    iconAnimation="down"
                                    onClick={() => {
                                        if (
                                            !selectedCompany.company?.id ||
                                            !disb?.contract.id ||
                                            !disb?.id
                                        )
                                            return;
                                        downloadSlip(
                                            selectedCompany.company?.id,
                                            disb.contract.id,
                                            disb.id,
                                        );
                                    }}
                                    variant="elevation"
                                    sx={{
                                        bgcolor: 'background.secondary',
                                        width: '220px',
                                        height: '220px',
                                        m: { xs: '10px 0', md: '0 20px 0 0' },
                                    }}
                                >
                                    <Grid container alignItems="flex-end" height="100%" p="20px">
                                        <Grid item>
                                            <Typography
                                                variant="subtitle1"
                                                color="primary.main"
                                                fontWeight={700}
                                                lineHeight={1.1}
                                                mb="5px "
                                            >
                                                Descargar el comprobante
                                            </Typography>
                                            <Typography variant="body1" color="text.primary">
                                                De la transferencia
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </PaperButton>
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default DisbursementDetail;
