import { FC } from 'react';
import { Typography } from '@fairplay2/ui';
import { formatAmount } from 'utils/parsing';
import { StyledCell, StyledTableRow } from '../../styles';

const InvoiceDetailTableItem: FC<any> = ({ conceptDetailRows }) => (
    <StyledTableRow>
        <StyledCell align="center">
            <Typography fontWeight={500} color="primary.main">
                {conceptDetailRows?.quantity}
            </Typography>
        </StyledCell>
        <StyledCell>
            <Typography fontWeight={500} color="primary.main">
                {conceptDetailRows?.unitCode}
            </Typography>
        </StyledCell>
        <StyledCell>
            <Typography fontWeight={500} color="primary.main">
                {conceptDetailRows?.description}
            </Typography>
        </StyledCell>
        <StyledCell align="right">
            <Typography fontWeight={500} color="primary.main">
                {`$ ${formatAmount(conceptDetailRows?.unitAmount)}`}
            </Typography>
        </StyledCell>
        <StyledCell align="right">
            <Typography fontWeight={500} color="primary.main">
                {`$ ${formatAmount(conceptDetailRows?.totalAmount)}`}
            </Typography>
        </StyledCell>
    </StyledTableRow>
);

export default InvoiceDetailTableItem;
