import { FC } from 'react';
import { DefaultBtn, InterpunctLoader } from '@fairplay2/ui';
import { Grid } from '@mui/material';
import { ModalActionsProps } from './interfaces';
import { useModalContext } from './useModalContext';

const ModalActions: FC<ModalActionsProps> = ({ primaryLabel, secondaryLabel }) => {
    const { onPrimaryAction, onSecondaryAction, loading } = useModalContext();
    return (
        <Grid item container justifyContent="center" gap="25px" mb={1}>
            {loading ? (
                <InterpunctLoader duration={1} timing={0.1} />
            ) : (
                <>
                    {secondaryLabel && (
                        <DefaultBtn
                            size="small"
                            variant="outlined"
                            onClick={onSecondaryAction}
                            sx={{ width: { xs: '100%', sm: 'auto' } }}
                        >
                            {secondaryLabel}
                        </DefaultBtn>
                    )}
                    <DefaultBtn
                        size="small"
                        onClick={onPrimaryAction}
                        sx={{ width: { xs: '100%', sm: 'auto' } }}
                    >
                        {primaryLabel}
                    </DefaultBtn>
                </>
            )}
        </Grid>
    );
};

export default ModalActions;
