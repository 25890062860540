import { styled } from '@mui/material/styles';
import { PaperButton } from '@fairplay2/ui';
import { StyledPaperButtonProps } from './interfaces';

export const StyledPaperButton = styled(PaperButton, {
    shouldForwardProp: (prop: string) => prop !== 'isActive' && prop !== 'emptyData',
})<StyledPaperButtonProps>(({ theme, isActive, emptyData }) => ({
    borderRadius: '10px 10px 0 0',
    borderBottom: 'none',
    padding: '25px',
    position: 'relative',
    width: '100%',
    minHeight: '100px',
    backgroundColor: isActive ? theme.palette.background.paper : theme.palette.background.default,
    cursor: emptyData ? 'default' : 'pointer',
    transition: 'background-color 0.3s',
    ...(!emptyData && {
        '&:hover': {
            backgroundColor: theme.palette.background.paper,
        },
    }),
    ...(isActive && {
        borderColor: 'transparent',
    }),
    ...(isActive && {
        ':after': {
            content: '""',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            height: '5px',
            backgroundColor: theme.palette.secondary.main,
        },
    }),
}));
