import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { DefaultBtn } from '@fairplay2/ui';

export const ItemContainer = styled(Grid)(({ theme }) => ({
    borderRight: '1px solid #E0E0E0',
    '&:first-child': {
        paddingRight: 10,
    },
    [theme.breakpoints.down('sm')]: {
        height: 'auto',
        borderRight: '0 ',
        borderBottom: '1px solid #E0E0E0',
        '&:last-child': {
            borderBottom: '0 !important',
        },
        '&:first-child': {
            paddingBottom: 7,
            paddingRight: 0,
        },
    },
}));

export const StyledBtn = styled(DefaultBtn)({ fontWeight: 500 });
