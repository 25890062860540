import { captureException } from 'utils/error-handler';
import { Identity } from 'utils/constants';

const parseSavedField = <Target extends any, Saved = Target>(
    savedString: string,
    fallbackTo: Target,
    transform: (result: Saved) => Target = Identity as any,
): Target => {
    try {
        // JSON.parse will throw an error for string elements
        const savedField: Saved =
            typeof fallbackTo === 'string' ? savedString : JSON.parse(savedString);
        return transform(savedField);
    } catch (error) {
        captureException(error);
        return fallbackTo;
    }
};

export const restoreSavedField = <Target extends any, Saved = Target>(
    savedString: string | null,
    fallbackTo: Target,
    strategy: 'object' | 'array' | 'primitive' = 'primitive',
    transform?: (result: Saved) => Target,
): Target => {
    if (!savedString) return fallbackTo;

    let shouldRestore = false;
    try {
        if (strategy === 'object') {
            shouldRestore = !Object.keys((fallbackTo as any) || {}).length;
        } else if (strategy === 'array') {
            shouldRestore = !(fallbackTo as any[]).length;
        } else {
            shouldRestore = !fallbackTo;
        }

        return shouldRestore ? parseSavedField(savedString, fallbackTo, transform) : fallbackTo;
        // Capture errors related to the shouldRestore algorithm
    } catch (error) {
        captureException(error);
        return fallbackTo;
    }
};
