import { forwardRef, lazy, Suspense, useMemo } from 'react';
import { Divider, Typography } from '@fairplay2/ui';
import { Grid, Skeleton, TableCell, TableRow, useMediaQuery, useTheme } from '@mui/material';
import { Table } from 'common-components';
import { COLORS_DIC, SUMMARY_KEYS } from 'components/dashboard/analytics/utils/constants';
import { getChannelsChartData } from '../../salesReducer';
import { TotalsByChannelData, TotalsByChannelProps } from './interfaces';
import ChannelsTableItem from './ChannelsTableItem';

const Chart = lazy(
    () =>
        import(
            // eslint-disable-next-line import/extensions
            /* webpackChunkName: 'Chart' */ 'common-components/data-indicators/chart/Chart.component'
        ),
);

const TotalsByChannel = forwardRef<any, TotalsByChannelProps>(
    (
        {
            tabValue,
            count,
            data,
            tableData,
            externalFilters,
            loading,
            onChangePage,
            onChangeRowsPerPage,
            page,
            rowsPerPage,
            setRequestParams,
        },
        chartRef,
    ) => {
        const theme = useTheme(),
            lgDown = useMediaQuery(theme.breakpoints.down('lg')),
            category = SUMMARY_KEYS[tabValue].toLowerCase();

        const chartOptions = useMemo(() => getChannelsChartData(data, tabValue), [data, tabValue]);

        return (
            <Grid item container direction="column" padding="25px 30px 0 30px" gap="30px">
                <Typography
                    variant="subtitle2"
                    color="primary.dark"
                    textAlign="start"
                    fontWeight={500}
                >
                    Total de {category} por canal
                </Typography>
                <Grid container alignItems="center">
                    <Grid item xs={12} md={5} pb={{ xs: 2, lg: 0 }}>
                        <Suspense
                            fallback={
                                <Grid item container justifyContent="center">
                                    <Skeleton
                                        data-testid="totals-by-channel-chart-skeleton"
                                        variant="circular"
                                        sx={{
                                            height: { sm: '300px', md: '400px' },
                                            width: { sm: '300px', md: '400px' },
                                        }}
                                    />
                                </Grid>
                            }
                        >
                            <Chart
                                ref={chartRef}
                                emptyDataMessage="Procesando datos"
                                height={lgDown ? '300px' : '400px'}
                                isEmpty={data.length === 0}
                                loading={loading}
                                options={chartOptions}
                                variant="borderless"
                                aria-label={`Gráfica total de ${category} por canal`}
                            />
                        </Suspense>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Table
                            // eslint-disable-next-line react/no-unstable-nested-components
                            getTableItem={(channnel: TotalsByChannelData, i: number) => (
                                <ChannelsTableItem
                                    key={channnel.id}
                                    name={channnel.name}
                                    color={
                                        page === 0
                                            ? COLORS_DIC[i % COLORS_DIC.length]
                                            : COLORS_DIC[(rowsPerPage + i) % COLORS_DIC.length]
                                    }
                                    totals={channnel.totals}
                                    category={tabValue}
                                />
                            )}
                            currentItems={tableData}
                            columns={4}
                            loading={loading}
                            pagination={{
                                count,
                                page,
                                rowsPerPage,
                                onPageChange: onChangePage,
                                onRowsPerPageChange: onChangeRowsPerPage,
                            }}
                            externalFilters={{ ...externalFilters, ...{ ordering: '' } }}
                            emptyItemsMessage="No hemos encontrado canales disponibles"
                            onParamsQueryChange={setRequestParams}
                        >
                            <TableRow sx={{ bgcolor: 'background.paper' }}>
                                <TableCell>
                                    <Typography color="text.primary" fontWeight={500}>
                                        Canal
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="text.primary"
                                        fontWeight={500}
                                        textAlign="right"
                                        noWrap
                                    >
                                        % periodo
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="text.primary"
                                        fontWeight={500}
                                        textAlign="right"
                                        noWrap
                                    >
                                        % periodo ant.
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="text.primary"
                                        fontWeight={500}
                                        textAlign="right"
                                        noWrap
                                    >
                                        % crecimiento
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </Table>
                    </Grid>
                </Grid>
                <Divider
                    sx={{
                        borderRadius: '0px',
                        width: '100%',
                        height: '1px',
                        backgroundColor: 'grey.400',
                        borderColor: 'grey.400',
                    }}
                />
            </Grid>
        );
    },
);

export default TotalsByChannel;
