import { FC, useState } from 'react';
import { SearchFilters } from 'common-components';
import {
    Grid,
    MenuItem,
    styled,
    ToggleButton,
    ToggleButtonGroup,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Checkbox, IconBtn, Select, Tooltip, Typography } from '@fairplay2/ui';
import { DatePicker } from '@fairplay2/ui-x-dates';
import { Info } from '@mui/icons-material';
import { MONTHS_DIC, STATUS_DIC, TODAY_DATE, TODAY_MINUS_90, YEARS } from '../../utils/constants';
import { filtersRenderValue } from '../../utils/utils';
import { FiltersProps } from './interfaces';

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    borderColor: 'none',
    backgroundColor: theme.palette.background.light,
    color: theme.palette.text.primary,
    height: 'auto',
    padding: '10px 5px',
    boxShadow: 'none',
    ':hover': {
        backgroundColor: theme.palette.background.light,
        borderColor: 'transparent',
        color: theme.palette.primary.main,
    },
    '&.Mui-selected': {
        border: `1px solid ${theme.palette.primary.main}`,
        ':hover': {
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '&.MuiToggleButtonGroup-grouped': {
            ':not(:first-of-type)': {
                borderLeft: '1px solid',
            },
            ':not(:last-of-type)': {
                borderRight: '2px solid',
            },
        },
    },
}));

const filterContainerStyle = {
        pb: { xs: '10px', md: '0px' },
        '.MuiInput-input': {
            cursor: 'pointer',
        },
        flex: { md: '1' },
        overflow: { md: 'hidden' },
    },
    DATE_CONSTANTS = {
        fromDate: TODAY_MINUS_90,
        toDate: TODAY_DATE,
    };

const Filters: FC<FiltersProps> = ({
    activeFilters,
    allowSearch,
    filterValues,
    isFilterOff,
    onChangePeriod,
    onFilterSearch,
    onMultipleSelectChange,
    onSelectChange,
    onSelectedDay,
    resetFilterValues,
    stores,
}) => {
    const [period, setPeriod] = useState<'monthly' | 'daily' | null>('daily'),
        theme = useTheme(),
        mdDown = useMediaQuery(theme.breakpoints.down('md'));

    const handlePeriod = (
        event: React.MouseEvent<HTMLElement>,
        newPeriod: 'monthly' | 'daily' | null,
    ) => {
        // Validation to avoid unselecting current selection when clicking on it again
        if (newPeriod === null) return;
        setPeriod(newPeriod);
        onChangePeriod();
    };

    return (
        <SearchFilters
            isFilterOff={isFilterOff}
            activeFilters={activeFilters}
            allowSearch={allowSearch}
            onFilterSearch={onFilterSearch}
            resetFilters={resetFilterValues}
            width="100%"
            marginBottom="20px"
            variant="transparent"
        >
            <Grid container gap={{ md: '10px', lg: '20px' }} overflow="hidden">
                <Grid item xs={12} pb={{ xs: '10px', md: '0px' }}>
                    <ToggleButtonGroup
                        value={period}
                        exclusive
                        onChange={handlePeriod}
                        aria-label="Periodo de tiempo"
                        fullWidth
                    >
                        <StyledToggleButton value="monthly">Año y mes</StyledToggleButton>
                        <StyledToggleButton value="daily">Rango de fechas</StyledToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid
                    item
                    container
                    wrap={mdDown ? 'wrap' : 'nowrap'}
                    gap={{ md: '10px', lg: '20px' }}
                >
                    <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={filterContainerStyle}
                    >
                        <Select
                            value={filterValues.year}
                            onChange={onSelectChange}
                            name="year"
                            label="Año"
                            disabled={period === 'daily'}
                        >
                            <MenuItem value="" disabled>
                                Año
                            </MenuItem>
                            {Object.entries(YEARS)
                                .slice(0, Object.entries(YEARS).length - 1)
                                .map(([year, text]) => (
                                    <MenuItem key={year} value={year}>
                                        {text}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={filterContainerStyle}
                    >
                        <Select
                            value={filterValues.month}
                            onChange={onMultipleSelectChange}
                            name="month"
                            label="Mes"
                            multiple
                            disabled={period === 'daily'}
                            SelectProps={{
                                renderValue: (selected) => filtersRenderValue(selected, MONTHS_DIC),
                            }}
                        >
                            <MenuItem value="" disabled>
                                Mes
                            </MenuItem>
                            {Object.entries(MONTHS_DIC)
                                .filter(([month]) => month !== '')
                                .map(([month, text]) => (
                                    <MenuItem key={month} value={month} disabled={month === ''}>
                                        <Checkbox
                                            checked={filterValues.month.some((m) => m === month)}
                                        />
                                        {text}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={filterContainerStyle}
                    >
                        <DatePicker
                            mode="range"
                            placeholder="Desde - Hasta"
                            value={filterValues.selectedDayRange}
                            onChange={onSelectedDay}
                            labelVariant="contained"
                            PickerProps={DATE_CONSTANTS}
                            displayIcon
                            disabled={period === 'monthly'}
                            label={
                                <Grid container wrap="nowrap">
                                    <Typography
                                        variant="body2"
                                        color="text.primary"
                                        fontWeight={500}
                                    >
                                        Rango
                                    </Typography>
                                    <Tooltip
                                        color="paper"
                                        placement="top-start"
                                        title={
                                            <Grid container maxWidth="175px">
                                                <Typography
                                                    color="text.primary"
                                                    fontWeight={500}
                                                    component="span"
                                                >
                                                    Rango de fechas limitado a 90 días.
                                                </Typography>
                                            </Grid>
                                        }
                                    >
                                        <IconBtn color="primary" sx={{ mt: '-4px' }}>
                                            <Info sx={{ fontSize: '15px' }} />
                                        </IconBtn>
                                    </Tooltip>
                                </Grid>
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={filterContainerStyle}
                    >
                        <Select
                            value={filterValues.store}
                            onChange={onMultipleSelectChange}
                            name="store"
                            label="Tienda"
                            multiple
                            SelectProps={{
                                renderValue: (selected) => filtersRenderValue(selected, stores),
                            }}
                        >
                            <MenuItem value="" disabled>
                                Tienda
                            </MenuItem>
                            {Object.entries(stores)
                                .filter(([store]) => store !== '')
                                .map(([store, text]) => (
                                    <MenuItem key={store} value={store} disabled={store === ''}>
                                        <Checkbox
                                            checked={filterValues.store.some((s) => s === store)}
                                        />
                                        {text}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={filterContainerStyle}
                    >
                        <Select
                            value={filterValues.status}
                            onChange={onSelectChange}
                            name="status"
                            labelVariant="contained"
                            label={
                                <Grid container wrap="nowrap">
                                    <Typography
                                        variant="body2"
                                        color="text.primary"
                                        fontWeight={500}
                                    >
                                        Estatus
                                    </Typography>
                                    <Tooltip
                                        color="paper"
                                        placement="top-start"
                                        title={
                                            <Grid container maxWidth="175px">
                                                <Typography
                                                    color="text.primary"
                                                    fontWeight={500}
                                                    component="span"
                                                >
                                                    Clasificación de órdenes al conectar una nueva
                                                    tienda.
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="success.main"
                                                    fontWeight={900}
                                                    my="2px"
                                                >
                                                    Válido:&nbsp;
                                                    <Typography
                                                        color="text.primary"
                                                        fontWeight={500}
                                                        component="span"
                                                    >
                                                        Órdenes que se convirtieron en ventas.
                                                    </Typography>
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="error.main"
                                                    fontWeight={900}
                                                    my="2px"
                                                >
                                                    Inválido:&nbsp;
                                                    <Typography
                                                        color="text.primary"
                                                        fontWeight={500}
                                                        component="span"
                                                    >
                                                        Órdenes que no contabilizan ventas.
                                                    </Typography>
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="info.main"
                                                    fontWeight={900}
                                                    my="2px"
                                                >
                                                    Devoluciones:&nbsp;
                                                    <Typography
                                                        color="text.primary"
                                                        fontWeight={500}
                                                        component="span"
                                                    >
                                                        Órdenes que fueron devueltas.
                                                    </Typography>
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="primary.dark"
                                                    fontWeight={900}
                                                    my="2px"
                                                >
                                                    Total:&nbsp;
                                                    <Typography
                                                        color="text.primary"
                                                        fontWeight={500}
                                                        component="span"
                                                    >
                                                        Suma de todas las órdenes/estatus.
                                                    </Typography>
                                                </Typography>
                                            </Grid>
                                        }
                                    >
                                        <IconBtn color="primary" sx={{ mt: '-4px' }}>
                                            <Info sx={{ fontSize: '15px' }} />
                                        </IconBtn>
                                    </Tooltip>
                                </Grid>
                            }
                        >
                            <MenuItem value="" disabled>
                                Estatus
                            </MenuItem>
                            {Object.entries(STATUS_DIC)
                                .filter(([status]) => status !== '')
                                .map(([status, text]) => (
                                    <MenuItem key={status} value={status}>
                                        {text}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                </Grid>
            </Grid>
        </SearchFilters>
    );
};

export default Filters;
