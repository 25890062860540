import { FC, useState, useContext, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Grid, Collapse } from '@mui/material';
import { Lock as LockIcon } from '@mui/icons-material';
import { Alert, InterpunctLoader, DefaultInput, DefaultBtn, Skeleton } from '@fairplay2/ui';
import { phoneRules, validateValueDoesNotExist } from 'utils/validation';
import fairplayAPI from 'utils/api';
import { useAlert } from 'utils/hooks';
import sessionContext from 'context/session/sessionContext';
import { toFormData } from 'utils/parsing';
import { ButtonsContainer } from '../styles';
import { EditForm } from './interfaces';
import ChangePassword from './change-password';

const EditData: FC = () => {
    const [open, setOpen] = useState(false),
        { user, updateUser, errors: sessionErrors } = useContext(sessionContext),
        [loading, setLoading] = useState(false),
        { alert, showAlert } = useAlert(),
        {
            register,
            formState: { errors, isValid },
            handleSubmit,
            reset,
        } = useForm<EditForm>({
            mode: 'onBlur',
            values: {
                firstName: user?.firstName || '',
                lastName: user?.lastName || '',
                phone: user?.phone || '',
            },
        }),
        effectiveAlert = sessionErrors.messages.user
            ? { open: true, message: sessionErrors.messages.user, type: 'error' as 'error' }
            : alert;

    const onSubmit: SubmitHandler<EditForm> = async ({ firstName, lastName, phone }) => {
        if (isValid) {
            setLoading(true);
            const data = toFormData({
                firstName,
                lastName,
                phone: phone || '',
            });

            try {
                const res: any = await fairplayAPI.patch('/v1/users/update', data);
                updateUser(res.data.body.results);
                showAlert('Perfil actualizado exitosamente', 'success');
            } catch {
                showAlert('No se ha podido actualizar la información, Intente más tarde');
            } finally {
                setLoading(false);
            }
        }
    };

    const openModal = () => {
        setOpen(true);
    };

    const closeModal = () => {
        document.body.style.overflow = 'auto';
        setOpen(false);
    };

    useEffect(() => {
        setLoading(validateValueDoesNotExist(user));
        if (!user) {
            setTimeout(() => {
                setLoading(false);
            }, 1500);
        }
    }, [user]);

    return (
        <>
            <Collapse in={effectiveAlert.open} sx={{ mt: '20px', width: '100%' }}>
                <Grid container spacing={4} paddingBottom="20px">
                    <Grid item xs={12} md={6}>
                        <Alert severity={effectiveAlert.type}>{effectiveAlert.message}</Alert>
                    </Grid>
                </Grid>
            </Collapse>

            <Grid
                container
                id="edit-user"
                data-testid="edit-user"
                onSubmit={handleSubmit(onSubmit)}
                component="form"
            >
                <Grid container spacing={3}>
                    {loading ? (
                        <>
                            {Array(4)
                                .fill(0)
                                .map((_, i) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Grid item xs={12} md={6} key={i}>
                                        <Skeleton data-testid="form-input-skeleton" height="53px" />
                                    </Grid>
                                ))}
                        </>
                    ) : (
                        <>
                            <Grid item xs={12} md={6}>
                                <DefaultInput
                                    id="firstName"
                                    type="text"
                                    label="Nombre:"
                                    labelVariant="contained"
                                    error={errors.firstName?.message}
                                    disabled={!user?.firstName}
                                    {...register('firstName', {
                                        required: 'Ingresa tu nombre',
                                    })}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <DefaultInput
                                    id="lastName"
                                    type="text"
                                    label="Apellido(s):"
                                    labelVariant="contained"
                                    error={errors.lastName?.message}
                                    disabled={!user?.lastName}
                                    {...register('lastName', {
                                        required: 'Ingresa tu apellido',
                                    })}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <DefaultInput
                                    id="email"
                                    type="email"
                                    label="Correo electrónico:"
                                    labelVariant="contained"
                                    value={user?.email}
                                    disabled
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <DefaultInput
                                    id="phone"
                                    type="tel"
                                    label="Teléfono:"
                                    labelVariant="contained"
                                    error={errors.phone?.message}
                                    disabled={!user?.phone}
                                    {...register('phone', {
                                        pattern: {
                                            value: phoneRules.regex,
                                            message: phoneRules.message,
                                        },
                                        required: 'Ingresa tu teléfono',
                                    })}
                                />
                            </Grid>
                        </>
                    )}

                    <Grid item container xs={12} paddingTop={0} marginTop="27px">
                        <Grid item xs={12} md={6}>
                            {!loading && user?.email && (
                                <DefaultBtn
                                    variant="text"
                                    startIcon={<LockIcon />}
                                    onClick={openModal}
                                    sx={{
                                        mb: '16px',
                                    }}
                                >
                                    Cambiar contraseña
                                </DefaultBtn>
                            )}
                        </Grid>
                        <ButtonsContainer item xs={12} md={6}>
                            {loading && user?.email ? (
                                <InterpunctLoader />
                            ) : (
                                user?.email && (
                                    <>
                                        <DefaultBtn
                                            variant="outlined"
                                            size="small"
                                            type="button"
                                            onClick={() => reset()}
                                        >
                                            Cancelar
                                        </DefaultBtn>
                                        <DefaultBtn size="small" type="submit" disabled={!isValid}>
                                            Guardar
                                        </DefaultBtn>
                                    </>
                                )
                            )}
                        </ButtonsContainer>
                    </Grid>
                </Grid>
            </Grid>

            <ChangePassword open={open} onClose={closeModal} />
        </>
    );
};

export default EditData;
