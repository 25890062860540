export const formatAmount = (num: number | string | undefined): string => {
    if (num || num === 0) {
        return Number(num).toLocaleString('en-US', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });
    }

    return '';
};

export const formatPerc = (num: string | number | undefined) => {
    if (num === undefined) return '';

    if (num) return (parseFloat(num.toString()) * 100).toFixed(1);

    return num;
};

// Converts blob data to base64
export const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file as Blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const toFormData = (data: Record<string, any>) =>
    Object.entries(data).reduce((formData, [key, value]) => {
        formData.append(key, value);
        return formData;
    }, new FormData());

export const mapObject = <T, U>(
    obj: Record<string, T>,
    fn: ([key, value]: [string, T]) => [string, U],
) =>
    Object.entries(obj).reduce((acc, entry) => {
        const [newKey, newValue] = fn(entry);
        return { ...acc, [newKey]: newValue };
    }, {} as Record<string, U>);
