import { FC, ChangeEvent, FocusEvent, useState, FormEvent } from 'react';
import { Grid, Collapse, useTheme, useMediaQuery } from '@mui/material';
import {
    Modal,
    Typography,
    DefaultBtn,
    DefaultInput,
    PasswordInput,
    InterpunctLoader,
    Alert,
} from '@fairplay2/ui';
import fairplayAPI from 'utils/api';
import { useAlert } from 'utils/hooks';
import { toFormData } from 'utils/parsing';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { ConnectorProps, ShopifyErrors, ShopifyForm } from '../interfaces';
import Confirmation from '../components/Confirmation';
import { useDataSources } from '../../context/DataSourcesContext';
import ConnectorFormTitle from '../components/ConnectorFormTitle';

const EMPTY_VALUE_ERRORS: ShopifyErrors = {
    storeName: 'Ingresa el nombre de la tienda',
    shopifyApiPassword: 'Ingresa la API Password',
    shopifyApiKey: 'Ingresa la API Key',
};

export const Shopify: FC<ConnectorProps> = ({ open, connector, onClose }) => {
    const { dispatch, baseAPI } = useDataSources(),
        theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        [values, setValues] = useState<ShopifyForm>({
            shopifyApiPassword: '',
            shopifyApiKey: '',
            storeName: '',
        }),
        [errors, setErrors] = useState<ShopifyErrors>({
            shopifyApiPassword: '',
            shopifyApiKey: '',
            storeName: '',
        }),
        { alert, hideAlert, showAlert } = useAlert(),
        [confirmation, setConfirmation] = useState(false),
        [loading, setLoading] = useState(false);

    const onChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
        hideAlert();
        setValues((prev) => ({ ...prev, [name]: value }));
    };

    const onInputError = (e: FocusEvent<HTMLInputElement>) => {
        const name = e.target.name as keyof ShopifyErrors;
        setErrors((prevState) => ({
            ...prevState,
            [name]: values[name] ? '' : EMPTY_VALUE_ERRORS[name],
        }));
    };

    const clearForm = () => {
        setValues((prev) => ({
            ...prev,
            shopifyApiPassword: '',
            shopifyApiKey: '',
            storeName: '',
        }));
        setErrors({ shopifyApiPassword: '', shopifyApiKey: '', storeName: '' });
        hideAlert();
    };

    const onConnect = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        hideAlert();
        // Check if there isn't an error
        if (!Object.values(errors).some((x) => x !== null && x !== '')) {
            setLoading(true);
            const data = toFormData({
                    platform: 'shopify',
                    shopifyApiPassword: values.shopifyApiPassword,
                    shopifyApiKey: values.shopifyApiKey,
                    storeName: connector?.storeName || values.storeName,
                }),
                endpoint = `${baseAPI}${connector ? `/${connector.id}/reconnect` : ''}`;

            try {
                const res: any = await fairplayAPI.post(endpoint, data);
                setConfirmation(true);
                dispatch({
                    type: connector ? 'UPDATE' : 'ADD',
                    payload: res.data.body.results,
                });
            } catch (errResponse: ErrorObj | any) {
                if (errResponse.type === 'form')
                    setErrors((prev) => ({ ...prev, ...errResponse.error }));
                else
                    showAlert(
                        errResponse?.error ||
                            'No se pudo realizar la conexión, verifique sus credenciales e intente nuevamente',
                    );
            } finally {
                setLoading(false);
            }
        }
    };

    const closeModal = () => {
        document.body.style.overflow = 'auto';
        clearForm();
        setConfirmation(false);
        onClose();
    };

    return (
        <Modal
            disableBackdropClick
            open={open}
            onClose={closeModal}
            maxWidth={640}
            disableEscapeKeyDown
            title={!confirmation && <ConnectorFormTitle groupKey="shopify" connector={connector} />}
            sx={{
                '.MuiDialogContent-root': {
                    pt: 0,
                },
            }}
        >
            {confirmation ? (
                <Confirmation item="Shopify" onClose={closeModal} />
            ) : (
                <Grid container spacing={4} component="form" onSubmit={onConnect}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" color="text.primary" mb="20px">
                            Para que podamos conectar tu tienda con nuestra plataforma, es necesario
                            que nos proporciones la siguiente información. Consulta nuestra guía{' '}
                            <a
                                target="_blank"
                                href="https://guides.getfairplay.com/es-es/knowledgebase/article/KA-01037"
                                rel="noopener noreferrer"
                                style={{ color: '#405BDD' }}
                            >
                                <strong>aquí</strong>
                            </a>
                            .
                        </Typography>
                        <Typography variant="subtitle2" color="text.primary">
                            El nombre de la tienda se encuentra en la URL de tu dashboard. Es decir,
                            si la URL es{' '}
                            <span style={{ whiteSpace: 'nowrap', color: '#283074' }}>
                                <strong>mitienda.myshopify.com</strong>
                            </span>
                            , el nombre de tu tienda es{' '}
                            <strong style={{ color: '#283074' }}>mitienda</strong>.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <DefaultInput
                            id="shopify-store-name"
                            name="storeName"
                            labelVariant="external"
                            label="Nombre de la tienda"
                            type="text"
                            value={connector?.storeName || values.storeName}
                            onChange={onChange}
                            onBlur={onInputError}
                            error={errors.storeName}
                            required
                            disabled={!!connector}
                            InputLabelProps={{ required: false }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <PasswordInput
                            id="shopify-api-password"
                            name="shopifyApiPassword"
                            labelVariant="external"
                            label="Token de acceso"
                            value={values.shopifyApiPassword}
                            forceHiddenText={loading}
                            onChange={onChange}
                            onBlur={onInputError}
                            error={errors.shopifyApiPassword}
                            InputLabelProps={{ required: false }}
                            required
                            titleAccess={{
                                show: 'Mostrar token de acceso',
                                hide: 'Ocultar token de acceso',
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <DefaultInput
                            id="shopify-api-key"
                            name="shopifyApiKey"
                            labelVariant="external"
                            label="Clave API"
                            type="text"
                            value={values.shopifyApiKey}
                            onChange={onChange}
                            onBlur={onInputError}
                            error={errors.shopifyApiKey}
                            InputLabelProps={{ required: false }}
                            required
                        />
                    </Grid>

                    <Grid item container xs={12} justifyContent="center" paddingTop="0 !important">
                        <Collapse in={alert.open}>
                            <Grid container item xs={12}>
                                <Alert severity={alert.type} sx={{ mt: 3, mb: -2 }}>
                                    {alert.message}
                                </Alert>
                            </Grid>
                        </Collapse>
                    </Grid>

                    <Grid
                        item
                        container
                        xs={12}
                        justifyContent={{ xs: 'center', sm: 'flex-end' }}
                        marginTop={1.25}
                    >
                        {loading ? (
                            <InterpunctLoader />
                        ) : (
                            <>
                                <DefaultBtn
                                    fullWidth={smDown}
                                    size="small"
                                    variant="outlined"
                                    onClick={closeModal}
                                >
                                    Cancelar
                                </DefaultBtn>
                                <DefaultBtn
                                    fullWidth={smDown}
                                    size="small"
                                    type="submit"
                                    sx={{
                                        ml: { xs: 0, sm: '20px' },
                                        mt: { xs: '20px', sm: 0 },
                                    }}
                                >
                                    Conectar
                                </DefaultBtn>
                            </>
                        )}
                    </Grid>
                </Grid>
            )}
        </Modal>
    );
};

export default Shopify;
