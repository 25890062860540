import { FC, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery, useTheme, Grid, MenuItem, Collapse } from '@mui/material';
import {
    Select,
    Typography,
    Divider,
    FilePicker,
    Alert,
    Loader,
    PaperButton,
    DefaultBtn,
} from '@fairplay2/ui';
import { addGoogleTag } from 'utils/google-tag-manager';
import { LeavesManSvg } from 'assets/SVGs';
import SessionContext from 'context/session/sessionContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { downloadFile, validateFileSize } from 'utils/files';
import { contractPrintableName } from 'utils/formatting';
import { AddCircle, GetApp, MonetizationOn, Publish, CreditCard } from '@mui/icons-material';
import { DisbursementProps } from '../interfaces';
import { useFile } from '../context/file-context';
import DisbursementsTable from './components/v1/DisbursementsTable';

const DisbursementV1: FC<DisbursementProps> = ({
    contracts,
    selectedContract,
    alert,
    hideAlert,
    showAlert,
    onContractSelect,
}) => {
    const theme = useTheme(),
        mdUp = useMediaQuery(theme.breakpoints.up('md')),
        history = useHistory(),
        { selectedCompany } = useContext(SessionContext),
        { dispatch } = useFile(),
        [downloadable, setDownloadable] = useState(true);

    const onFileChange = (file: any) => {
        hideAlert();
        addGoogleTag('disbursement-archive-start');

        if (!file) return;

        const fileError = validateFileSize(file, 5, 'El tamaño máximo de archivo es 5MB');
        showAlert(fileError);

        if (!fileError) {
            dispatch({ type: 'upload', payload: file });
            history.push('/app/disbursements/transactions/create/file');
        }
    };

    const onNewDisbursement = () => {
        history.push('/app/disbursements/transactions/create');
        addGoogleTag('disbursement-new');
    };

    const getDisbursementTemplate = async () => {
        hideAlert();
        if (downloadable) {
            setDownloadable(false);
            try {
                const url = `/v2/companies/${selectedCompany.company?.id}/contracts/${selectedContract.id}/dispersions/template`;
                await downloadFile('application/vnd.ms-excel', `template_dispersiones.csv`, url);
                setDownloadable(true);
            } catch (errResponse: ErrorObj | any) {
                setDownloadable(true);
                showAlert(
                    errResponse?.error ||
                        'Hubo un error al descargar el archivo, intente más tarde',
                );
            }
        }
    };

    return (
        <Grid container data-testid="disbursements-v1">
            {selectedContract?.disbursementAllowed ? (
                <>
                    <Grid item xs={12} mb="30px" textAlign={{ xs: 'center', sm: 'left' }}>
                        <Typography variant="h4" color="primary.main" mt="10px">
                            Solicita una{' '}
                            <Typography component="span" color="primary.dark" fontWeight={700}>
                                dispersión nueva
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        justifyContent={{ xs: 'center', md: 'flex-start' }}
                        alignItems={{ xs: 'center', md: 'flex-start' }}
                        direction={{ xs: 'column', md: 'row' }}
                    >
                        <PaperButton
                            iconAnimation
                            icon={<AddCircle />}
                            onClick={onNewDisbursement}
                            variant="elevation"
                            sx={{
                                bgcolor: 'background.secondary',
                                width: '214px',
                                height: '220px',
                                m: { xs: '10px 0', md: '0 20px 20px 0' },
                            }}
                        >
                            <Grid container alignItems="flex-end" height="100%" p="20px">
                                <Grid item>
                                    <Typography
                                        variant="subtitle1"
                                        color="primary.main"
                                        fontWeight={700}
                                        lineHeight={1.1}
                                        mb="5px"
                                    >
                                        Solicitar nueva dispersión
                                    </Typography>
                                    {/* TODO: Add 'o Tarjeta Fairplay' text when cards are re-enabled */}
                                    <Typography variant="body1" color="text.primary">
                                        Única
                                    </Typography>
                                </Grid>
                            </Grid>
                        </PaperButton>
                        <PaperButton
                            iconAnimation="down"
                            icon={downloadable ? <GetApp /> : <Loader />}
                            onClick={() => getDisbursementTemplate()}
                            variant="elevation"
                            sx={{
                                bgcolor: 'background.secondary',
                                width: '214px',
                                height: '220px',
                                m: { xs: '10px 0', md: '0 20px 20px 0' },
                            }}
                        >
                            <Grid container alignItems="flex-end" height="100%" p="20px">
                                <Grid item>
                                    <Typography
                                        variant="subtitle1"
                                        color="primary.main"
                                        fontWeight={700}
                                        lineHeight={1.1}
                                        mb="5px"
                                    >
                                        Descarga el template
                                    </Typography>
                                    <Typography variant="body1" color="text.primary">
                                        Para solicitud múltiple
                                    </Typography>
                                </Grid>
                            </Grid>
                        </PaperButton>
                        <Grid
                            item
                            container
                            wrap="nowrap"
                            sx={{
                                m: { xs: '10px 0', md: 0 },
                                height: '220px !important',
                                width: '306px',
                            }}
                            justifyContent={{ xs: 'center', md: 'flex-start' }}
                        >
                            <FilePicker
                                accept=".csv"
                                onFileChange={onFileChange}
                                paperVariant="dashed"
                                icon={<Publish />}
                                fileIcon={<MonetizationOn />}
                                AdditionalHelp="De solicitud múltiple"
                                sx={{
                                    width: 213,
                                    height: 218,
                                }}
                            />
                            {mdUp && (
                                <Grid item>
                                    <LeavesManSvg
                                        style={{
                                            zIndex: -1,
                                            position: 'relative',
                                            top: -10,
                                            right: 6,
                                        }}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        {selectedContract?.enableCardsModule && (
                            <Grid
                                width={245}
                                direction="column"
                                item
                                container
                                sx={{
                                    alignSelf: { xs: 'center', md: 'flex-end' },
                                    alignItems: { xs: 'center', md: 'flex-start' },
                                    mt: { xs: '30px', md: 0 },
                                    mb: { xs: 0, md: '20px' },
                                }}
                            >
                                <DefaultBtn
                                    variant="text"
                                    startIcon={<CreditCard />}
                                    sx={{
                                        textTransform: 'none !important',
                                        p: '0px',
                                    }}
                                    onClick={() => history.push('/app/disbursements/cards')}
                                >
                                    ¿Quieres una Tarjeta Fairplay?
                                </DefaultBtn>
                            </Grid>
                        )}
                    </Grid>
                </>
            ) : (
                <Grid container mt="20px">
                    <Alert aria-label="Aviso de contrato" severity="warning">
                        No es posible solicitar dispersiones con este contrato.
                    </Alert>
                </Grid>
            )}

            <Grid item container sm={12} md={8} alignItems="center" spacing={4} mt="1px">
                <Grid item xs={12} sm={6}>
                    <Select
                        labelVariant="external"
                        label="Contrato"
                        name="contract"
                        value={contracts.length > 0 ? selectedContract.id : ''}
                        onChange={(event) => onContractSelect(event.target.value)}
                        sx={{
                            '.MuiTypography-root': { mb: '11px' },
                        }}
                    >
                        {contracts.length > 0 ? (
                            contracts.map((contract) => (
                                <MenuItem
                                    key={contract.id}
                                    value={contract.id}
                                >{`Contrato ${contractPrintableName(contract)}`}</MenuItem>
                            ))
                        ) : (
                            <MenuItem value={0}>No hay contratos registrados</MenuItem>
                        )}
                    </Select>
                </Grid>
            </Grid>

            <Grid item container xs={12} justifyContent="flex-end">
                <Collapse in={alert.open}>
                    <Alert aria-label="Error de servidor" severity={alert.type}>
                        {alert.message}
                    </Alert>
                </Collapse>
            </Grid>

            <Grid item xs={12} mt="40px">
                <Typography variant="subtitle1" color="primary.main" mb="10px">
                    Dispersiones del{' '}
                    <Typography component="span" color="primary.dark">
                        {contracts.length > 0
                            ? `Contrato ${contractPrintableName(selectedContract)}`
                            : ''}
                    </Typography>
                </Typography>
                <Divider variant="inset" sx={{ width: 120, height: '5px' }} />
            </Grid>

            <DisbursementsTable selectedContract={selectedContract} />
        </Grid>
    );
};

export default DisbursementV1;
