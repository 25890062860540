import { createContext, useContext } from 'react';
import { ModalContextProps } from './interfaces';

export const ModalContext = createContext<ModalContextProps>({} as ModalContextProps);

export const useModalContext = () => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error('useAnalyticsModalContext must be used within a AnalyticsModalProvider');
    }
    return context;
};
