import { styled } from '@mui/material/styles';
import bgCard from 'assets/img/bg-fairplay-card.png';
import { Status } from 'common-components';

export const StyledCard = styled('div')(({ theme }) => ({
    width: 300,
    height: 190,
    maxWidth: 350,
    minWidth: 300,
    boxSizing: 'border-box',
    borderRadius: 10,
    backgroundColor: theme.palette.primary.dark,
    backgroundImage: `url(${bgCard})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '25% 75%',
    backgroundSize: 'contain',
    position: 'relative',
    padding: '18px',
    [theme.breakpoints.up('sm')]: {
        width: '100%',
        height: 220,
    },
    '.FpInsights-FpCardLogo': {
        position: 'absolute',
        top: 27,
        width: 48,
        height: 15,
        [theme.breakpoints.up('sm')]: {
            width: 56,
            height: 17,
        },
    },
    '.FpInsights-FpCardMasterCardLogo': {
        position: 'absolute',
        right: 8,
        bottom: 14,
        width: 63,
        height: 39,
        [theme.breakpoints.up('sm')]: {
            right: 12,
            bottom: 17,
            width: 74,
            height: 46,
        },
    },
}));

export const ShowCardInfo = styled('div')(({ theme }) => ({
    position: 'absolute',
    bottom: 24,
    width: 35,
    [theme.breakpoints.up('sm')]: {
        bottom: 32,
    },
}));

export const CardStatus = styled(Status)({
    position: 'absolute',
    top: 18,
    right: 12,
});
