import {
    AlertMessages,
    CardFilterObj,
    RequestsFilterObj,
    TransactionFilterObj,
    RequestType,
} from './interfaces';

type RequestedCardStatus =
    | 'requested'
    | 'active'
    | 'blocked'
    // TODO: Uncomment when backend functionaly is implemented
    // |  'inactive'
    | 'declined'
    | 'cancel';
type ExecutedTransactionStatus = 'refunded' | 'approved' | 'canceled_refund' | 'canceled';

export const REQUESTED_CARD_STATUS: Record<RequestedCardStatus, string> = {
    requested: 'Solicitada',
    active: 'Activa',
    blocked: 'Suspendida',
    // TODO: Uncomment when backend functionaly is implemented
    // inactive: 'Inactiva',
    declined: 'Declinada',
    cancel: 'Cancelada',
};

export const EXECUTED_TRANSACTION_STATUS: Record<ExecutedTransactionStatus, string> = {
    refunded: 'Reembolsado',
    canceled_refund: 'Reembolso parcial',
    approved: 'Aprobado',
    canceled: 'Cancelado',
};

export const APPROVAL_REQUEST_STATUS = {
    cancellation_declined: 'Cancelación declinada',
    cancellation_approved: 'Cancelación aprobada',
    update_limit_declined: 'Actualización de límite declinado',
    update_limit_approved: 'Actualización de límite aprobada',
    creation_approved: 'Creación aprobada',
    creation_declined: 'Creación declinada',
    alias_updated: 'Alias actualizado',
    pending: 'Pendiente',
};

export const FILTER_CONTAINER_STYLE = {
    borderRadius: '40px',
    px: { xs: '10px', sm: '20px' },
    '&:hover': {
        backgroundColor: '#F6F6F9',
    },
};

export const DEFAULT_CARD_FILTERS = {
    unconfirmed: {
        selectedDayRange: {
            from: undefined,
            to: undefined,
        },
        alias: '',
        approver: '',
        status: '',
    } as CardFilterObj,
    effective: {
        ordering: undefined as undefined | string,
        start_date: '',
        end_date: '',
        alias: '',
        approver: '',
        min_amount: NaN,
        max_amount: NaN,
        status: '',
    },
};

export const DEFAULT_EXE_TRANSACTION_FILTERS = {
    unconfirmed: {
        selectedDayRange: {
            from: undefined,
            to: undefined,
        },
        merchant_name: '',
        status: '',
    } as TransactionFilterObj,
    effective: {
        ordering: 'created_at',
        start_date: '',
        end_date: '',
        merchant_name: '',
        min_amount: NaN,
        max_amount: NaN,
        status: '-pending',
    },
};

export const DEFAULT_TRANSIT_TRANSACTION_FILTERS = {
    unconfirmed: {
        selectedDayRange: {
            from: undefined,
            to: undefined,
        },
        merchant_name: '',
    } as TransactionFilterObj,
    effective: {
        ordering: 'created_at',
        start_date: '',
        end_date: '',
        merchant_name: '',
        min_amount: NaN,
        max_amount: NaN,
        status: 'pending',
    },
};

export const DEFAULT_APPROVAL_REQUESTS_FILTERS = {
    unconfirmed: {
        selectedDayRange: {
            from: undefined,
            to: undefined,
        },
        requested_by: '',
        status: '',
    } as RequestsFilterObj,
    effective: {
        ordering: 'created_at',
        start_date: '',
        end_date: '',
        requested_by: '',
        status: '',
    },
};

export const REQUEST_CARD_MODAL_TEXTS = {
    isAdmin: {
        title: 'La tarjeta',
        content: 'ha sido creada con éxito.',
        statusInfo:
            'Verifica el estatus en el listado de Tarjetas solicitadas. Para que puedas usar la tarjeta deberá tener el estatus “Activa”.',
    },
    isNotAdmin: {
        title: 'La solicitud de la tarjeta',
        content:
            'ha sido enviada para aprobación a la dirección de correo electrónico del Administrador autorizado.',
        statusInfo:
            'Verifica el estatus de la solicitud en el listado de Tarjetas solicitadas. Para que puedas usar la tarjeta deberá tener el estatus “Activa”.',
    },
};

export const ALERT_MESSAGES: AlertMessages = {
    approved: {
        'create-card': 'La solicitud de nueva tarjeta ha sido aprobada',
        'update-credit-limit': 'El límite de crédito ha sido actualizado',
        'cancel-card': 'La tarjeta ha sido cancelada',
        alertType: 'success',
    },
    declined: {
        'create-card': 'La solicitud de nueva tarjeta ha sido rechazada',
        'update-credit-limit': 'La actualización del límite de crédito ha sido declinada',
        'cancel-card': 'La solicitud de cancelación de la tarjeta ha sido declinada',
        alertType: 'info',
    },
};

export const REQUEST_TYPE_TEXT: Record<Exclude<RequestType, 'create-card'>, string> = {
    'cancel-card': 'Cancelación',
    'update-credit-limit': 'Actualización de límite',
};
