import { FC, useState, useEffect, ChangeEvent, useContext } from 'react';
import { Grid, Collapse } from '@mui/material';
import { DefaultBtn, DefaultInput, InterpunctLoader, Alert } from '@fairplay2/ui';
import fairplayAPI from 'utils/api';
import SessionContext from 'context/session/sessionContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { useAlert } from 'utils/hooks';
import { toFormData } from 'utils/parsing';
import { VendorDetailProps, VendorForm, ErrorsDic, VendorErrors } from '../interfaces';
import VendorDetailSkeleton from './VendorDetailSkeleton';

const errorsDic: ErrorsDic = {
    supplierAlias: 'alias',
    supplierAddress: 'address',
    supplierState: 'state',
    supplierWebsite: 'website',
    contactName: 'name',
    contactPhone: 'phone',
    contactEmail: 'email',
};

export const composeAddress = (address: string, zipCode: string): string => {
    const separator = address && zipCode ? '/' : '';
    return address + separator + zipCode;
};

const VendorDetail: FC<VendorDetailProps> = ({ vendor, loadingPage, fetchVendor }) => {
    const { selectedCompany } = useContext(SessionContext),
        [loading, setLoading] = useState(false),
        { alert, showAlert } = useAlert(),
        [values, setValues] = useState<VendorForm>({
            alias: '',
            state: '',
            address: '',
            zipCode: '',
            website: '',
            email: '',
            name: '',
            phone: '',
        }),
        [errors, setErrors] = useState<VendorErrors>({
            alias: '',
            state: '',
            address: '',
            website: '',
            email: '',
            name: '',
            phone: '',
        }),
        [editMode, setEditMode] = useState(false);

    const clearErrors = () => {
        setErrors({
            alias: '',
            state: '',
            address: '',
            website: '',
            email: '',
            name: '',
            phone: '',
        });
    };

    const onEditMode = () => {
        clearErrors();
        setEditMode(!editMode);
    };

    const editVendor = async ({
        alias,
        state,
        address,
        zipCode,
        website,
        email,
        name,
        phone,
    }: VendorForm) => {
        if (vendor) {
            setLoading(true);
            clearErrors();
            const data = toFormData({
                supplierAlias: alias,
                supplierAddress: composeAddress(address, zipCode),
                supplierState: state,
                supplierWebsite: website,
                contactName: name,
                contactPhone: phone,
                contactEmail: email,
                supplierId: vendor.supplierId,
                contactId: vendor.contacts.length > 0 ? vendor.contacts[0].id : '',
            });
            try {
                await fairplayAPI.patch(
                    `/v1/companies/${selectedCompany.company?.id}/suppliers/${vendor.id}`,
                    data,
                );
                setEditMode(false);
                fetchVendor();
                showAlert('Expediente actualizado exitosamente', 'success');
            } catch (errorRes: ErrorObj | any) {
                if (errorRes.type === 'form') {
                    const newErrors: VendorErrors = { ...errors };
                    // eslint-disable-next-line no-restricted-syntax, guard-for-in
                    for (const err in errorRes.error) {
                        newErrors[errorsDic[err]] = errorRes.error[err];
                    }

                    setErrors(newErrors);
                } else showAlert(errorRes.error);
            } finally {
                setLoading(false);
            }
        }
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    const showVendorValues = () => {
        if (!vendor) return;

        const newValues: VendorForm = { ...values };
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in vendor) {
            const value = vendor[key];
            if (typeof value === 'string' && key in values) {
                if (key === 'address') {
                    const addressArr = value.split('/');
                    newValues.address = addressArr[0];
                    newValues.zipCode = addressArr[1];
                } else newValues[key] = value;
            }
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const key in vendor.contacts[0]) {
            if (key in values) newValues[key] = vendor.contacts[0][key];
        }
        setValues(newValues);
    };

    const cancelEdit = () => {
        showVendorValues();
        onEditMode();
    };

    useEffect(() => {
        showVendorValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vendor]);

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {loadingPage ? (
                <VendorDetailSkeleton />
            ) : (
                <Grid container mt="10px">
                    <Grid item container xs={12} spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <DefaultInput
                                id="vendor-type"
                                label="Tipo de proveedor:"
                                labelVariant="external"
                                name="type"
                                disabled
                                defaultValue={vendor?.type}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DefaultInput
                                id="vendor-alias"
                                label="Alias:"
                                labelVariant="external"
                                name="alias"
                                disabled={!editMode}
                                value={values.alias}
                                onChange={onChange}
                                error={errors.alias}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DefaultInput
                                id="vendor-registered-name"
                                label={
                                    vendor?.type === 'Nacional' ? 'Razón social:' : 'Nombre legal:'
                                }
                                labelVariant="external"
                                name="registeredName"
                                disabled
                                defaultValue={vendor?.registeredName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DefaultInput
                                id="vendor-tax-id"
                                label={vendor?.type === 'Nacional' ? 'R.F.C.:' : 'TAX ID:'}
                                labelVariant="external"
                                name="taxId"
                                disabled
                                defaultValue={vendor?.taxId}
                            />
                        </Grid>

                        {vendor?.type === 'Internacional' && (
                            <>
                                <Grid item xs={12} sm={6}>
                                    <DefaultInput
                                        disabled
                                        id="vendor-country"
                                        label="País:"
                                        labelVariant="external"
                                        defaultValue={vendor?.country?.name}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <DefaultInput
                                        id="vendor-city"
                                        label="Ciudad:"
                                        labelVariant="external"
                                        name="state"
                                        disabled={!editMode}
                                        value={values.state}
                                        onChange={onChange}
                                        error={errors.state}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <DefaultInput
                                        id="vendor-zip"
                                        label="C.P."
                                        labelVariant="external"
                                        name="zipCode"
                                        disabled={!editMode}
                                        value={values.zipCode}
                                        onChange={onChange}
                                        error={errors.address}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <DefaultInput
                                        id="vendor-address"
                                        label="Calle o vialidad:"
                                        labelVariant="external"
                                        name="address"
                                        disabled={!editMode}
                                        value={values.address}
                                        onChange={onChange}
                                        error={errors.address}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} sm={6}>
                            <DefaultInput
                                id="vendor-website"
                                label="Sitio web:"
                                labelVariant="external"
                                name="website"
                                disabled={!editMode}
                                value={values.website}
                                onChange={onChange}
                                error={errors.website}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DefaultInput
                                id="vendor-industry"
                                label="Industria:"
                                labelVariant="external"
                                name="industry"
                                disabled
                                defaultValue={vendor?.industry?.description || ''}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <DefaultInput
                                id="vendor-name"
                                label="Nombre de contacto:"
                                labelVariant="external"
                                name="name"
                                disabled={!editMode}
                                value={values.name}
                                onChange={onChange}
                                error={errors.name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DefaultInput
                                id="vendor-email"
                                label="E-mail de contacto:"
                                labelVariant="external"
                                name="email"
                                disabled={!editMode}
                                value={values.email}
                                onChange={onChange}
                                error={errors.email}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <DefaultInput
                                id="vendor-phone"
                                label="Teléfono de contacto:"
                                labelVariant="external"
                                name="phone"
                                disabled={!editMode}
                                value={values.phone}
                                onChange={onChange}
                                error={errors.phone}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} justifyContent="flex-end">
                        <Collapse in={alert.open}>
                            <Alert aria-label="Alert detalle proveedor" severity={alert.type}>
                                {alert.message}
                            </Alert>
                        </Collapse>
                    </Grid>
                    <Grid item container xs={12} justifyContent="center" marginTop="20px">
                        <>
                            <Grid item xs={6} textAlign="left">
                                {editMode && (
                                    <DefaultBtn
                                        size="small"
                                        variant="outlined"
                                        onClick={cancelEdit}
                                    >
                                        Cancelar
                                    </DefaultBtn>
                                )}
                            </Grid>
                            <Grid item xs={6} textAlign="right">
                                {/* eslint-disable-next-line no-nested-ternary */}
                                {editMode ? (
                                    loading ? (
                                        <InterpunctLoader />
                                    ) : (
                                        <DefaultBtn size="small" onClick={() => editVendor(values)}>
                                            Listo
                                        </DefaultBtn>
                                    )
                                ) : (
                                    <DefaultBtn
                                        size="small"
                                        onClick={onEditMode}
                                        disabled={!vendor}
                                    >
                                        Editar
                                    </DefaultBtn>
                                )}
                            </Grid>
                        </>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default VendorDetail;
