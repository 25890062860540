import { FC, useState } from 'react';
import { TableCell, TableRow, Grid, TableSortLabel } from '@mui/material';
import { Typography } from '@fairplay2/ui';
import { Table } from 'common-components';
import { OrdersTableProps, Order } from '../interfaces';
import LogsModal from './LogModal';
import OrdersTableItem from './OrdersTableItem';

const OrdersTable: FC<OrdersTableProps> = ({
    orders,
    loading,
    count,
    page,
    rowsPerPage,
    sortOrder,
    externalFilters,
    onChangePage,
    onChangeRowsPerPage,
    onAlert,
    onSort,
    onParamsQueryChange,
}) => {
    const [open, setOpen] = useState(false),
        [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

    const openModal = () => setOpen(true);

    const closeModal = () => {
        document.body.style.overflow = 'auto';
        setOpen(false);
    };

    const onOpenModal = (order: Order) => {
        openModal();
        setSelectedOrder(order);
    };

    return (
        <>
            <Table
                // eslint-disable-next-line react/no-unstable-nested-components
                getTableItem={(order: Order) => (
                    <OrdersTableItem
                        key={order.id}
                        order={order}
                        onAlert={onAlert}
                        onModal={onOpenModal}
                    />
                )}
                currentItems={orders}
                columns={4}
                loading={loading}
                emptyItemsMessage="No hemos encontrado ordenes disponibles"
                externalFilters={externalFilters}
                pagination={{
                    count,
                    page,
                    rowsPerPage,
                    onPageChange: onChangePage,
                    onRowsPerPageChange: onChangeRowsPerPage,
                }}
                onParamsQueryChange={onParamsQueryChange}
            >
                <TableRow>
                    <TableCell>
                        <Typography color="text.primary">Subido por</Typography>
                    </TableCell>
                    <TableCell>
                        <Grid container>
                            <TableSortLabel
                                active={orders.length > 0}
                                direction={sortOrder}
                                onClick={onSort}
                            />
                            <Typography color="text.primary">Fecha de carga</Typography>
                        </Grid>
                    </TableCell>
                    <TableCell>
                        <Typography color="text.primary">Estatus</Typography>
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: 100, width: 100 }}>
                        <Typography color="text.primary">Acciones</Typography>
                    </TableCell>
                </TableRow>
            </Table>

            {open && <LogsModal open={open} order={selectedOrder} closeModal={closeModal} />}
        </>
    );
};

export default OrdersTable;
