import { FC, useEffect, useContext, useState, useCallback, ChangeEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, useTheme, useMediaQuery } from '@mui/material';
import {
    KeyboardArrowLeft,
    PermContactCalendar,
    AccountBalanceWallet,
    Mail,
    DataThresholdingRounded,
} from '@mui/icons-material';
import { Typography, IconBtn, TabGroup, DefaultBtn, TabData } from '@fairplay2/ui';
import { Status, StatusType } from 'common-components';
import SessionContext from 'context/session/sessionContext';
import fairplayAPI from 'utils/api';
import { contractPrintableName } from 'utils/formatting';
import { ContractDetails } from '../interfaces';
import { canSeePayments } from '../utils';
import PageSkeleton from './components/PageSkeleton';
import InfoAlert from './components/InfoAlert';
import ContractSpecs from './components/specifications/ContractSpecs';
import ContractPayments from './components/payments';
import Projections from './components/projections/Projections';

const PROJECTIONS_BLACKLIST =
    process.env.REACT_APP_PROJECTIONS_CONTRACTS_BLACKLIST?.split(',').map((item) => item.trim()) ||
    [];

const DEFAULT_TABS_DATA: TabData[] = [
    {
        label: 'Especificaciones',
        icon: <PermContactCalendar />,
    },
    {
        label: 'Pagos a tu financiamiento',
        icon: <AccountBalanceWallet />,
    },
    {
        label: 'Desempeño',
        icon: <DataThresholdingRounded />,
    },
];

const ContractDetail: FC = () => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        history = useHistory(),
        { id } = useParams<{ id: string }>(),
        { selectedCompany } = useContext(SessionContext),
        [loading, setLoading] = useState(true),
        [tabs, setTabs] = useState(DEFAULT_TABS_DATA),
        [contractValues, setContractValues] = useState<ContractDetails | null>(null),
        [activeTab, setActiveTab] = useState(0),
        hideProjections = PROJECTIONS_BLACKLIST.includes(id),
        shouldShowPayments = canSeePayments(contractValues as any);

    const onTabChange = useCallback((event: ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    }, []);

    // Open second tap if go to calendar was clicked
    useEffect(() => {
        if (sessionStorage.getItem('openPayments')) {
            setActiveTab(1);

            if (sessionStorage.getItem('openPayments') !== 'calendar') {
                sessionStorage.removeItem('openPayments');
            }
        }
    }, []);

    useEffect(() => {
        if (!selectedCompany.company?.id) return;
        const fetchContract = async () => {
            try {
                const res: any = await fairplayAPI.get(
                    `/v2/companies/${selectedCompany.company?.id}/contracts/${id}`,
                );
                setContractValues(res.data.body.results);
                const tabsArrCopy = tabs.filter(
                    (tab) =>
                        tab.label === 'Especificaciones' ||
                        (tab.label === 'Pagos a tu financiamiento' &&
                            canSeePayments(res.data.body.results)) ||
                        (tab.label === 'Desempeño' && !hideProjections),
                );
                setTabs(tabsArrCopy);
            } catch {
                if (window.location.pathname.includes('/app/financing/contract/detail/')) {
                    history.push('/app/financing/contract/');
                }
            } finally {
                setLoading(false);
            }
        };
        fetchContract();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany, id, history]);

    return loading ? (
        <PageSkeleton />
    ) : (
        <Grid container>
            <Grid item container xs={12} marginTop="10px" justifyContent="space-between">
                <Grid
                    item
                    container
                    xs="auto"
                    alignItems="center"
                    alignContent="center"
                    marginBottom={3}
                    marginRight={1}
                    flexWrap="nowrap"
                    maxWidth="100%"
                >
                    <IconBtn
                        variant="filled"
                        color="primary"
                        size="small"
                        sx={{
                            p: '0 !important',
                            svg: {
                                fontSize: '1.5em',
                            },
                        }}
                        onClick={() => history.push('/app/financing/contract/')}
                    >
                        <KeyboardArrowLeft />
                    </IconBtn>
                    <Typography
                        variant="h4"
                        fontWeight={700}
                        ml="15px"
                        display="flex"
                        alignItems="center"
                        color="primary.dark"
                    >
                        {`Contrato ${contractPrintableName(contractValues)}`}
                    </Typography>
                </Grid>
                {!!contractValues?.status && (
                    <Status variant="standard" status={contractValues?.status?.id as StatusType}>
                        {contractValues?.status?.text}
                    </Status>
                )}
            </Grid>

            {!activeTab && (
                <Grid item mt={{ xs: '10px', sm: '35px' }}>
                    <InfoAlert infos={contractValues?.importantFields} icon={false} />
                </Grid>
            )}
            <TabGroup
                tabs={tabs}
                onChange={onTabChange}
                value={activeTab}
                aria-label="Detalles de contratos"
                indicatorStyles={{ expand: true }}
            >
                <ContractSpecs contractValues={contractValues} />
                {shouldShowPayments && <ContractPayments contractId={contractValues?.id} />}
                {!hideProjections && <Projections />}
            </TabGroup>
            <Grid
                item
                container
                xs={12}
                justifyContent="space-between"
                alignItems="center"
                spacing={4}
            >
                <Grid item xs={12} sm={12} md={6}>
                    <DefaultBtn
                        fullWidth={smDown}
                        variant="outlined"
                        size="small"
                        onClick={() => history.push('/app/financing/contract/')}
                    >
                        Volver a listado
                    </DefaultBtn>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    container
                    direction="column"
                    alignItems="flex-end"
                >
                    <Typography variant="body1" color="text.primary">
                        ¿Tienes dudas o aclaraciones? ¡Contáctanos!
                    </Typography>
                    <DefaultBtn
                        variant="text"
                        startIcon={<Mail />}
                        href="mailto:cusu@getfairplay.com"
                        sx={{
                            textTransform: 'none !important',
                        }}
                    >
                        Ejecutivo de CuSu
                    </DefaultBtn>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ContractDetail;
