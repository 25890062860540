import { FC, useState, useContext, useEffect, ChangeEvent, useCallback } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
import {
    validateClabe,
    validateEmail,
    validateAlphanumeric,
    validateOnlyDigits,
    validateRFC,
    validateSWIFT,
} from 'utils/validation';
import { validateFileSize } from 'utils/files';
import { addGoogleTag } from 'utils/google-tag-manager';
import { Typography, LabelledIconBtn, TabGroup } from '@fairplay2/ui';
import fairplayAPI from 'utils/api';
import SessionContext from 'context/session/sessionContext';
import { useAlert } from 'utils/hooks';
import { toFormData } from 'utils/parsing';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj, getMainError } from 'utils/error-handler';
import {
    VendorType,
    VendorCreateForm,
    Invoice,
    Errors,
    CreateNationalVendorReq,
    CreateInternationalVendorReq,
    Country,
    IndustryAsProp,
} from './interfaces';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import Step5 from './components/Step5';
import SuccessModal from './components/SuccessModal';
import CancelModal from './components/CancelModal';
import {
    STEPS_VALUES,
    DEFAULT_TABS_DATA,
    NATIONAL_REQUIRED_FIELDS,
    INTERNATIONAL_REQUIRED_FIELDS,
} from './values';

const AddVendor: FC = () => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        { selectedCompany } = useContext(SessionContext),
        [activeTab, setActiveTab] = useState(0),
        [tabsData, setTabsData] = useState(DEFAULT_TABS_DATA),
        [vendorType, setVendorType] = useState<VendorType>(''),
        [isFirstBuy, setIsFirstBuy] = useState(''),
        [files, setFiles] = useState<Invoice[]>([]),
        [loading, setLoading] = useState(false),
        { alert, hideAlert, showAlert } = useAlert(),
        [currencies, setCurrencies] = useState([]),
        [isModalOpen, setIsModalOpen] = useState({
            success: false,
            cancel: false,
        }),
        [values, setValues] = useState<VendorCreateForm>({
            selectedYear: 0,
            supplierRegisteredName: '',
            supplierRfc: '',
            supplierTaxId: '',
            supplierWebsite: '',
            supplierCountry: null,
            supplierStreet: '',
            supplierState: '',
            supplierZipCode: '',
            supplierAlias: '',
            industry: null,
            contactName: '',
            contactPhone: '',
            contactEmail: '',
            accountClabe: '',
            accountSwift: '',
            accountNumber: '',
            accountBank: '',
            accountBankAddress: '',
            accountState: '',
            accountCountry: { id: '', name: '' },
            accountCurrency: '',
        }),
        [errors, setErrors] = useState<Errors>({
            invoiceFile: '',
            selectedYear: '',
            supplierRegisteredName: '',
            supplierRfc: '',
            supplierTaxId: '',
            supplierWebsite: '',
            supplierCountry: '',
            supplierAddress: '',
            supplierState: '',
            supplierStreet: '',
            supplierZipCode: '',
            supplierAlias: '',
            industry: '',
            contactName: '',
            contactPhone: '',
            contactEmail: '',
            accountClabe: '',
            accountSwift: '',
            accountNumber: '',
            accountBank: '',
            accountState: '',
            accountCountry: '',
            accountCurrency: '',
        });

    const clearForm = () => {
        setValues({
            selectedYear: 0,
            supplierRegisteredName: '',
            supplierRfc: '',
            supplierTaxId: '',
            supplierWebsite: '',
            supplierCountry: null,
            supplierStreet: '',
            supplierState: '',
            supplierZipCode: '',
            supplierAlias: '',
            industry: null,
            contactName: '',
            contactPhone: '',
            contactEmail: '',
            accountClabe: '',
            accountSwift: '',
            accountNumber: '',
            accountBank: '',
            accountBankAddress: '',
            accountState: '',
            accountCountry: { id: '', name: '' },
            accountCurrency: '',
        });

        setErrors({
            invoiceFile: '',
            selectedYear: '',
            supplierRegisteredName: '',
            supplierRfc: '',
            supplierTaxId: '',
            supplierWebsite: '',
            supplierCountry: '',
            supplierAddress: '',
            supplierState: '',
            supplierStreet: '',
            supplierZipCode: '',
            supplierAlias: '',
            industry: '',
            contactName: '',
            contactPhone: '',
            contactEmail: '',
            accountClabe: '',
            accountSwift: '',
            accountNumber: '',
            accountBank: '',
            accountState: '',
            accountCountry: '',
            accountCurrency: '',
        });
        hideAlert();
        setIsFirstBuy('');
        setVendorType('');
        setFiles([]);
        setActiveTab(0);
    };

    const fetchClabe = async (clabe: string) => {
        setValues((prev) => ({ ...prev, accountBank: '' }));
        setErrors((prev) => ({ ...prev, accountBank: '' }));
        setLoading(true);
        try {
            const res: any = await fairplayAPI.get(`/v1/clabe?clabe=${clabe}`, {
                baseService: 'BANK_INFORMATION',
            });
            setValues({ ...values, accountBank: res.data.bank });
            setLoading(false);
        } catch (errResponse: ErrorObj | any) {
            setLoading(false);
            setErrors({
                ...errors,
                accountClabe:
                    getMainError(errResponse?.error) ||
                    'No se pudo cargar la información del banco',
            });
        }
    };

    const fetchSWIFT = async (code: string) => {
        setValues((prev) => ({
            ...prev,
            accountBank: '',
            accountCountry: { id: '', name: '' },
            accountState: '',
        }));
        setErrors((prev) => ({
            ...prev,
            accountBank: '',
            accountCountry: '',
            accountState: '',
        }));
        setLoading(true);

        try {
            const res: any = await fairplayAPI.get(`/v1/swift?code=${code}`, {
                baseService: 'BANK_INFORMATION',
            });
            if (res.data) {
                setValues({
                    ...values,
                    accountBank: res.data.bank.name,
                    accountBankAddress: res.data.address,
                    accountState: res.data.city.name,
                    accountCountry: res.data.country,
                });

                setCurrencies(res.data.currencies);
            }
            setLoading(false);
        } catch (errResponse: ErrorObj | any) {
            setLoading(false);
            setErrors({
                ...errors,
                accountSwift:
                    getMainError(errResponse?.error) ||
                    'No se pudo cargar la información del banco',
            });
        }
    };

    const onValueChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
        setValues((prev) => {
            let newValue = value;
            switch (name) {
                case 'accountClabe':
                    newValue = validateOnlyDigits(value) ? value : prev[name];
                    break;
                case 'accountNumber':
                    newValue = validateAlphanumeric(value) ? value : prev[name];
                    break;
                default:
                    break;
            }
            return { ...prev, [name]: newValue };
        });
    };

    const onIndustrySelect = (_: object, industry: IndustryAsProp) => {
        setValues((prev) => ({
            ...prev,
            industry,
        }));
        setErrors((prev) => ({
            ...prev,
            industry: industry ? '' : 'Ingresa la Industria',
        }));
    };

    const onVendorTypeChange = (type: VendorType) => {
        clearForm();
        setVendorType(type);
    };

    const onTabChange = (_: ChangeEvent<{}>, newValue: number) => {
        if (vendorType) setActiveTab(newValue);
    };

    const onFileChange = (file: File | null, target: HTMLInputElement | null) => {
        setErrors((prev) => ({ ...prev, invoiceFile: '' }));
        if (file) {
            const fileError = validateFileSize(file, 5, 'No puedes subir archivos mayores a 5MB.');
            if (fileError)
                setErrors((prev) => ({
                    ...prev,
                    invoiceFile: fileError,
                }));
            else setFiles((prev) => [...prev, { url: file, name: file.name }]);
        }

        if (file === null) {
            const newFiles = files.filter((_, i) => i !== Number(target?.name.slice(-1)));
            setFiles(newFiles);
        }
    };

    const uploadFiles = async (id: string) => {
        const invoicesData = toFormData({
            firstPurchaseYear: values.selectedYear,
            ...files.reduce(
                (acc, file, i) => ({
                    ...acc,
                    [`invoice${i + 1}`]: file.url,
                }),
                {},
            ),
        });
        try {
            const res = await fairplayAPI.post(
                `/v1/companies/${selectedCompany.company?.id}/suppliers/${id}/invoices`,
                invoicesData,
            );
            return res;
        } catch {
            showAlert(
                'El proveedor fue agregado  con éxito, pero no se pudo cargar las facturas. Por favor comuníquese con el administrador para cargar las facturas manualmente.',
            );
        }
    };

    const checkRequiredFields = (): boolean => {
        const newErrors: Errors = { ...errors };
        const requiredFields =
            vendorType === 'national' ? NATIONAL_REQUIRED_FIELDS : INTERNATIONAL_REQUIRED_FIELDS;
        let result = false;

        // eslint-disable-next-line no-restricted-syntax
        for (const value of requiredFields) {
            if (!values[value]) {
                newErrors[value] = 'Campo requerido';
                result = true;
            }
        }

        if (!values.selectedYear && isFirstBuy === 'no') {
            newErrors.selectedYear = 'Campo requerido';
            result = true;
        }
        if (files.length === 0 && isFirstBuy === 'no') {
            newErrors.invoiceFile = 'Campo requerido';
            result = true;
        }
        setErrors(newErrors);
        return result;
    };

    const onModalOpen = (target: string) => {
        setIsModalOpen({ ...isModalOpen, [target]: true });
    };

    const onRegisterUser = async () => {
        hideAlert();

        const {
            supplierRegisteredName,
            supplierRfc,
            supplierTaxId,
            supplierWebsite,
            supplierCountry,
            supplierStreet,
            supplierState,
            supplierZipCode,
            supplierAlias,
            industry,
            contactName,
            contactPhone,
            contactEmail,
            accountClabe,
            accountSwift,
            accountNumber,
            accountBank,
            accountBankAddress,
            accountState,
            accountCountry,
            accountCurrency,
        } = values;

        const nationalVendor: CreateNationalVendorReq = {
            accountBank,
            accountClabe,
            accountCurrency,
            contactEmail,
            contactName,
            contactPhone,
            supplierAlias,
            supplierCountry: 1,
            industry: industry?.id || '',
            supplierRegisteredName,
            supplierRfc,
            supplierType: vendorType,
            supplierWebsite,
        };

        const internationalVendor: CreateInternationalVendorReq = {
            accountBank,
            accountBankAddress,
            accountCountry: accountCountry.id,
            accountCurrency,
            accountNumber,
            accountState,
            accountSwift,
            contactEmail,
            contactName,
            contactPhone,
            supplierAddress: `${supplierStreet}/${supplierZipCode}`,
            supplierAlias,
            supplierCountry: supplierCountry ? supplierCountry.id : null,
            industry: industry?.id || '',
            supplierRegisteredName,
            supplierState,
            supplierTaxId,
            supplierType: vendorType,
            supplierWebsite,
        };

        const data = vendorType === 'national' ? nationalVendor : internationalVendor;

        const dataForm = new FormData();
        // eslint-disable-next-line no-restricted-syntax
        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                dataForm.append(`${key}`, `${data[key]}`);
            }
        }

        const noErrors = Object.values(errors).every((error) => !error);

        if (!checkRequiredFields() && noErrors) {
            setLoading(true);
            try {
                const res: any = await fairplayAPI.post(
                    `/v1/companies/${selectedCompany.company?.id}/suppliers`,
                    dataForm,
                );
                if (res.data) {
                    if (files.length > 0) {
                        const res2 = await uploadFiles(res.data.body.results.id);
                        if (res2) {
                            onModalOpen('success');
                        }
                    } else onModalOpen('success');
                    addGoogleTag(`${vendorType}-vendor-success`);
                }

                clearForm();
                setLoading(false);
            } catch (errResponse: ErrorObj | any) {
                setLoading(false);
                if (errResponse.type === 'form')
                    setErrors((prev) => ({ ...prev, ...errResponse.error }));
                else
                    showAlert(
                        errResponse?.error || 'Ocurrió un error con el servidor, intente más tarde',
                    );
            }
        } else showAlert('Existen campos obligatorios sin completar o algún error activo');
    };

    const onBlur = (target: keyof Errors, errorMsg: string) => {
        if (values[target]) {
            switch (target) {
                case 'accountClabe': {
                    const clabeError = validateClabe(values.accountClabe);
                    setErrors((prev) => ({ ...prev, accountClabe: clabeError }));
                    if (!clabeError) fetchClabe(values.accountClabe);
                    break;
                }
                case 'contactEmail': {
                    const emailError = validateEmail(values.contactEmail);
                    setErrors((prev) => ({ ...prev, contactEmail: emailError }));
                    break;
                }
                case 'supplierRfc': {
                    const rfcError = validateRFC(values.supplierRfc);
                    setErrors((prev) => ({ ...prev, supplierRfc: rfcError || '' }));
                    break;
                }
                case 'accountSwift': {
                    const swiftError = validateSWIFT(values.accountSwift);
                    setErrors((prev) => ({ ...prev, accountSwift: swiftError }));
                    if (!swiftError) fetchSWIFT(values.accountSwift);
                    break;
                }
                case 'supplierStreet':
                    setErrors((prev) => ({ ...prev, supplierStreet: '', supplierAddress: '' }));
                    break;
                case 'supplierZipCode':
                    setErrors((prev) => ({
                        ...prev,
                        supplierZipCode: '',
                        supplierAddress: '',
                    }));
                    break;
                default:
                    setErrors((prev) => ({ ...prev, [target]: '' }));
                    break;
            }
        } else {
            setErrors((prev) => ({ ...prev, [target]: errorMsg }));
        }
    };

    const onError = useCallback((target: keyof Errors, errorMsg: string) => {
        setErrors((prevState) => ({ ...prevState, [target]: errorMsg }));
    }, []);

    const onSelect = (event: ChangeEvent<{ value: unknown }>) => {
        setValues({ ...values, selectedYear: event.target.value as number });
    };

    const onModalClose = (target: string) => {
        setIsModalOpen({ ...isModalOpen, [target]: false });
        document.body.style.overflow = 'auto';
    };

    const onCountry = (value: Country) => {
        setValues({ ...values, supplierCountry: value });
    };

    const onFirstBuyChange = (event: ChangeEvent<HTMLInputElement>) => {
        setErrors((prev) => ({ ...prev, selectedYear: '', invoiceFile: '' }));
        setIsFirstBuy((event.target as HTMLInputElement).value);
    };

    useEffect(() => {
        const activeErrors = Object.entries(errors)
            .filter(([_, isActive]) => !!isActive)
            .map(([error]) => error);

        if (activeErrors.length > 0) {
            const newTabsData = tabsData.map((tab, index) => ({
                ...tab,
                hasError: STEPS_VALUES[index]?.some((value) => activeErrors.includes(value)),
            }));

            showAlert('Existen errores en el formulario', 'error', false);
            setTabsData(newTabsData);
        } else {
            hideAlert();
            setTabsData(DEFAULT_TABS_DATA);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    return (
        <>
            <SuccessModal isModalOpen={isModalOpen.success} onModalClose={onModalClose} />

            <CancelModal
                isModalOpen={isModalOpen.cancel}
                onModalClose={onModalClose}
                clearForm={clearForm}
                type={vendorType || 'international'}
            />

            <Grid container>
                <Grid
                    container
                    direction={{ xs: 'column-reverse', sm: 'row' }}
                    justifyContent="space-between"
                    alignItems={{ xs: 'flex-start', md: 'center' }}
                >
                    <Typography variant="h4" color="primary.main" fontWeight={500} mb="10px">
                        Nuevo
                        <Typography component="span" color="primary.dark" fontWeight={700}>
                            {' '}
                            proveedor{' '}
                            {
                                /* eslint-disable-next-line no-nested-ternary */
                                activeTab > 0
                                    ? vendorType === 'national'
                                        ? 'nacional'
                                        : 'internacional'
                                    : ''
                            }
                        </Typography>
                    </Typography>

                    {activeTab > 0 && (
                        <Grid
                            container={smDown}
                            item
                            justifyContent="flex-end"
                            alignItems="center"
                            mb={{ xs: '10px', sm: 0 }}
                        >
                            <LabelledIconBtn
                                color="primary"
                                variant="filled"
                                size="small"
                                label="Cancelar"
                                LabelProps={{
                                    fontSize: '14px',
                                    margin: '10px',
                                    forceBelow: 'sm',
                                }}
                                onClick={() => onModalOpen('cancel')}
                            >
                                <Close />
                            </LabelledIconBtn>
                        </Grid>
                    )}
                </Grid>
                <Grid item>
                    {activeTab === 0 && (
                        <Typography variant="subtitle2" color="primary.main">
                            Paso 1- Elige el tipo de proveedor que deseas dar de alta:
                        </Typography>
                    )}

                    {activeTab > 0 && activeTab < 4 && (
                        <Typography variant="subtitle2" color="primary.main">
                            Paso 2- Agrega los datos para el expediente del proveedor que deseas dar
                            de alta:
                        </Typography>
                    )}

                    {activeTab === 4 && (
                        <Typography variant="subtitle2" color="primary.main">
                            Paso 3- Información de interacciones con el proveedor:
                        </Typography>
                    )}
                </Grid>

                <Grid item xs={12} mt="30px">
                    <TabGroup
                        value={activeTab}
                        tabs={tabsData}
                        onChange={onTabChange}
                        sx={{
                            mb: '20px',
                        }}
                    >
                        <Step1
                            vendorType={vendorType}
                            onVendorTypeChange={onVendorTypeChange}
                            onStepChange={onTabChange}
                        />
                        <Step2
                            alert={alert}
                            vendorType={vendorType}
                            values={values}
                            onError={onError}
                            onValueChange={onValueChange}
                            onStepChange={onTabChange}
                            onBlur={onBlur}
                            errors={errors}
                            onCountry={onCountry}
                            onIndustrySelect={onIndustrySelect}
                        />
                        <Step3
                            alert={alert}
                            vendorType={vendorType}
                            values={values}
                            currencies={currencies}
                            onValueChange={onValueChange}
                            onStepChange={onTabChange}
                            onBlur={onBlur}
                            errors={errors}
                            loading={loading}
                        />
                        <Step4
                            alert={alert}
                            values={values}
                            onValueChange={onValueChange}
                            onStepChange={onTabChange}
                            onBlur={onBlur}
                            errors={errors}
                        />
                        <Step5
                            onStepChange={onTabChange}
                            files={files}
                            alert={alert}
                            loading={loading}
                            selectedYear={values.selectedYear}
                            isFirstBuy={isFirstBuy}
                            errors={errors}
                            onFirstBuyChange={onFirstBuyChange}
                            onFileChange={onFileChange}
                            onRegisterUser={onRegisterUser}
                            onSelect={onSelect}
                            onBlur={onBlur}
                        />
                    </TabGroup>
                </Grid>
            </Grid>
        </>
    );
};

export default AddVendor;
