import { useEffect, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Info, KeyboardArrowLeft } from '@mui/icons-material';
import { Collapse, Grid } from '@mui/material';
import { Status, StatusType } from 'common-components';
import { Alert, DefaultBtn, IconBtn, Skeleton, Tooltip, Typography } from '@fairplay2/ui';
import SessionContext from 'context/session/sessionContext';
import fairplayAPI from 'utils/api';
import { SHORT_READABLE_FORMAT, formatUtc } from 'utils/dates';
import { PaymentOrder } from 'components/dashboard/contracts/interfaces';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { canSeePayments } from 'components/dashboard/contracts/utils';
import { ColoredInput } from './styles';

interface AggregatedData {
    amount: null | number;
    paid: null | number;
    remainingAmount: null | number;
    salesAmount: null | number;
    status: string;
    isRevenueBased: boolean;
}

const INITIAL_AGGREGATED: AggregatedData = {
        amount: null,
        paid: null,
        remainingAmount: null,
        salesAmount: null,
        status: '',
        isRevenueBased: false,
    },
    STATUS_MAP: Record<string, [StatusType, string]> = {
        paid: ['approved', 'Pagado'],
        pending: ['pending', 'Pendiente'],
        'partially-paid': ['invalid', 'Adeudo'],
        due: ['invalid', 'Adeudo'],
    },
    STATUS_PRIORITY = ['paid', 'partially-paid', 'due', 'pending'];

export const prioritizeStatus = (prevStatus: string, newStatus: string) => {
    const fallbackTo = STATUS_PRIORITY.includes(prevStatus) ? prevStatus : '';

    return STATUS_PRIORITY.indexOf(newStatus) > STATUS_PRIORITY.indexOf(prevStatus)
        ? newStatus
        : fallbackTo;
};

export const aggregateOrders = (orders: PaymentOrder[]) =>
    orders.reduce<AggregatedData>((aggregated, order) => {
        if (!order) return aggregated;
        // All orders are expected to have the same
        //  salesAmount and isRevenueBased values.
        //  Here we just create an arbitrary fallback algorithm
        //  that uses the last non-faulty value (if any)
        //  in the edge-case that any of the orders contains faulty data.
        return {
            amount: (aggregated.amount ?? 0) + order.amount,
            paid: (aggregated.paid ?? 0) + order.paid,
            remainingAmount: (aggregated.remainingAmount ?? 0) + order.remainingAmount,
            salesAmount: aggregated.salesAmount || order.salesAmount,
            isRevenueBased: aggregated.isRevenueBased || order.isRevenueBased,
            status: prioritizeStatus(aggregated.status, order.status || ''),
        };
    }, INITIAL_AGGREGATED);

const PaymentOrdersDetail = () => {
    const { selectedCompany } = useContext(SessionContext),
        { push, location: { pathname } = {} } = useHistory(),
        { id: contractId, date } = useParams<{ id: string; date: string }>(),
        [contractName, setContractName] = useState(''),
        [error, setError] = useState(''),
        [aggregated, setAggregated] = useState<undefined | AggregatedData>(undefined);

    const onReturn = () => {
        sessionStorage.setItem('openPayments', 'default');
        push(`/app/financing/contract/detail/${contractId}`);
    };

    const handlesRedirect = () => {
        if (pathname?.includes('/app/financing/contract/detail/')) push('/app/financing/contract/');
    };

    useEffect(() => {
        if (!contractId || !selectedCompany.company?.id || !date) return;
        (async () => {
            try {
                const contractRes: any = await fairplayAPI.get(
                    `/v2/companies/${selectedCompany.company?.id}/contracts/${contractId}`,
                );

                if (!canSeePayments(contractRes.data.body.results)) return handlesRedirect();

                setContractName(contractRes.data.body.results.fairplayInternId);

                try {
                    const paymentsRes: any = await fairplayAPI.get(
                            `/payment-order/api/v1/payment_orders/${contractId}/${date}?page=1&page_size=10`,
                            {
                                baseService: 'PAYMENT_ORDERS',
                            },
                        ),
                        rawData = paymentsRes.data.results || [];

                    setAggregated(aggregateOrders(rawData));
                } catch (errResponse: ErrorObj | any) {
                    setError(errResponse.error || 'Se ha producido un error. Intente más tarde');
                    setAggregated(INITIAL_AGGREGATED);
                }
            } catch {
                handlesRedirect();
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractId, selectedCompany.company?.id, push, date, pathname]);

    return (
        <Grid container>
            <Grid item container xs={12} marginTop="10px" justifyContent="space-between">
                <Grid
                    item
                    container
                    xs="auto"
                    alignItems="center"
                    marginBottom={3}
                    marginRight={1}
                    flexWrap="nowrap"
                    maxWidth="100%"
                >
                    <IconBtn
                        variant="filled"
                        color="primary"
                        size="small"
                        sx={{
                            p: '0 !important',
                            svg: {
                                fontSize: '1.5em',
                            },
                        }}
                        onClick={onReturn}
                    >
                        <KeyboardArrowLeft />
                    </IconBtn>
                    <Typography
                        variant="h4"
                        fontWeight={700}
                        ml="15px"
                        display="flex"
                        alignItems="center"
                    >
                        {formatUtc(date || '', SHORT_READABLE_FORMAT)}{' '}
                        {contractName ? (
                            <>/ {contractName}</>
                        ) : (
                            <Skeleton variant="rectangular" width={100} height={24} />
                        )}
                    </Typography>
                </Grid>
                {!error && aggregated?.status !== '' && (
                    <Grid item marginBottom={3}>
                        {aggregated ? (
                            <Status
                                variant="standard"
                                status={STATUS_MAP[aggregated?.status][0]}
                                aria-label="Estatus del pago"
                            >
                                {STATUS_MAP[aggregated?.status][1]}
                            </Status>
                        ) : (
                            <Skeleton width={128} height={50} />
                        )}
                    </Grid>
                )}
            </Grid>
            <Grid item container direction="column">
                <Typography
                    variant="subtitle2"
                    component="h3"
                    color="primary.dark"
                    fontWeight={700}
                    mb={2.8}
                >
                    Información general
                </Typography>

                {!!aggregated?.isRevenueBased && (
                    <Grid item container spacing={3} mb={3}>
                        <Grid item xs={12} md={4}>
                            <ColoredInput
                                id="payment-associated-sales"
                                sx={{
                                    '.MuiTypography-label': {
                                        mb: '11px',
                                    },
                                }}
                                label={
                                    <>
                                        Ventas asociadas a tu pago
                                        <Tooltip
                                            color="paper"
                                            placement="bottom-end"
                                            title="Tu contrato es basado en ingresos por lo tanto, utilizamos el monto total de tus ventas del mismo periodo del mes anterior para calcular tus pagos."
                                            sx={{
                                                position: 'absolute',
                                                top: -3,
                                                right: 0,
                                            }}
                                        >
                                            <IconBtn color="primary">
                                                <Info fontSize="small" />
                                            </IconBtn>
                                        </Tooltip>
                                    </>
                                }
                                labelVariant="external"
                                value={aggregated.salesAmount ?? 'Información no disponible'}
                                disabled
                                variant="outlined"
                                type="neutral"
                            />
                        </Grid>
                    </Grid>
                )}
                <Grid item container spacing={3} data-testid="aggregated-totals">
                    <Grid item xs={12} md={4}>
                        <ColoredInput
                            id="payment-total"
                            label="Monto total del día a pagar"
                            labelVariant="external"
                            value={aggregated?.amount}
                            disabled
                            variant="outlined"
                            type="neutral"
                            sx={{
                                '.MuiTypography-label': {
                                    mb: '11px',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <ColoredInput
                            id="payment-total-paid"
                            label="Pagado"
                            labelVariant="external"
                            value={aggregated?.paid}
                            disabled
                            variant="outlined"
                            type="positive"
                            sx={{
                                '.MuiTypography-label': {
                                    mb: '11px',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <ColoredInput
                            id="payment-total-remaining"
                            label="Pendiente por pagar del día"
                            labelVariant="external"
                            value={aggregated?.remainingAmount}
                            disabled
                            variant="outlined"
                            type="negative"
                            sx={{
                                '.MuiTypography-label': {
                                    mb: '11px',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item marginLeft="auto">
                    <Collapse in={!!error}>
                        <Alert severity="error" sx={{ mt: 3 }}>
                            {error}
                        </Alert>
                    </Collapse>
                </Grid>
            </Grid>

            <Grid item xs={12} sm="auto" my="50px">
                <DefaultBtn fullWidth variant="outlined" size="small" onClick={onReturn}>
                    Volver a tus pagos
                </DefaultBtn>
            </Grid>
        </Grid>
    );
};

export default PaymentOrdersDetail;
