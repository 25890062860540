import { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Collapse, Grid, useMediaQuery, useTheme } from '@mui/material';
import { AccessTime } from '@mui/icons-material';
import { Modal, DefaultBtn, Typography, Alert, InterpunctLoader } from '@fairplay2/ui';
import { CurrencyInput } from '@fairplay2/ui-x-currencies';
import { useAlert } from 'utils/hooks';
import { formatAmount, toFormData } from 'utils/parsing';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { LimitCardSvg } from 'assets/SVGs';
import fairplayAPI from 'utils/api';
import { useIsAdmin } from '../hooks';
import { CreditLimitCardModalProps } from '../interfaces';

const validateNewCredit = (newCredit: number, maxForDisposition: number) => {
    if (newCredit === 0 || newCredit <= maxForDisposition) {
        return true;
    }
    return 'Esta cantidad supera el monto máximo del periodo';
};

export const CreditLimitCardModal: FC<CreditLimitCardModalProps> = ({
    open,
    cardId,
    alias,
    availableToday,
    onClose,
    selectedContractId,
    maxForDisposition,
}) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        {
            handleSubmit,
            formState: { errors, isValid },
            watch,
            control,
            reset,
        } = useForm<{ creditLimit: number }>({
            mode: 'onChange',
        }),
        [creditLimit] = watch(['creditLimit']),
        [loading, setLoading] = useState(false),
        [isUpdatedCredit, setIsUpdateCredit] = useState(false),
        { alert, hideAlert, showAlert } = useAlert(),
        remainingBalance = maxForDisposition - (creditLimit || 0),
        isAdmin = useIsAdmin();

    const onSubmit: SubmitHandler<{ creditLimit: number }> = async ({
        creditLimit: newCreditLimit,
    }) => {
        if (!isValid) return;
        hideAlert();
        setLoading(true);
        try {
            const data = toFormData({
                contract_id: selectedContractId,
                card_id: cardId!,
                request_type: 'update-credit-limit',
                credit_limit: String(newCreditLimit),
            });
            const res: any = await fairplayAPI.post('/cards/v1/card/create-request', data, {
                    baseService: 'CARDS',
                }),
                isAdminRes = res.data.body.results?.status_request.id === 'update_limit_approved';

            setIsUpdateCredit(true);
            showAlert(
                isAdminRes
                    ? 'Tu límite de crédito se ha actualizado exitosamente'
                    : 'La solicitud ha sido enviada',
                isAdminRes ? 'success' : 'info',
            );
        } catch (errResponse: ErrorObj | any) {
            showAlert(
                errResponse?.error ||
                    'No se pudo actualizar tu límite de crédito, por favor vuelve a intentarlo.',
            );
        } finally {
            setLoading(false);
        }
    };

    useEffect(
        () => () => {
            if (process.env.NODE_ENV === 'test') return;
            // In order to restart the state after close the modal
            setTimeout(() => {
                reset({});
                setIsUpdateCredit(false);
                hideAlert();
            }, 300);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <Modal
            aria-labelledby="new-credit-limit"
            disableBackdropClick
            disableEscapeKeyDown
            open={open}
            onClose={() => onClose(isUpdatedCredit)}
            maxWidth={670}
        >
            <Grid container>
                <Grid container justifyContent="center">
                    <LimitCardSvg />
                </Grid>
                <Typography variant="subtitle1" color="primary.main" mt="25px" lineHeight={1.4}>
                    ¿Cuál es el nuevo límite de crédito que quieres establecer en la tarjeta{' '}
                    <Typography component="span" color="primary.dark" fontWeight={700}>
                        {alias}
                    </Typography>
                    ?
                </Typography>

                <Typography variant="subtitle2" color="text.primary" fontWeight={500} mt="15px">
                    {isAdmin
                        ? 'Verifica el estatus en el listado de Tarjetas.'
                        : 'Recuerda que esta solicitud deberá ser aprobada por un Administrador autorizado. Verifica el estatus en el listado de Tarjetas.'}
                </Typography>

                <Typography
                    variant="subtitle2"
                    color="text.primary"
                    fontWeight={500}
                    mt="15px"
                    width="100%"
                >
                    Límite actual:{' '}
                    <Typography
                        component="span"
                        variant="subtitle2"
                        color="primary.main"
                        fontWeight={700}
                    >
                        MXN$ {formatAmount(availableToday?.amount || 0)}
                    </Typography>
                </Typography>

                <Typography
                    variant="subtitle2"
                    color="primary.dark"
                    component="label"
                    htmlFor="credit-limit"
                    fontWeight={700}
                    mt="25px"
                    width="100%"
                    id="new-credit-limit"
                >
                    Nuevo límite de crédito:
                </Typography>

                <Grid
                    container
                    item
                    component="form"
                    aria-labelledby="new-credit-limit"
                    onSubmit={handleSubmit(onSubmit)}
                    xs={12}
                >
                    <Grid
                        item
                        container
                        maxWidth={{ xs: '100%', sm: '380px' }}
                        alignItems="center"
                        columnGap={{ xs: '5px', sm: '16px' }}
                        flexWrap="nowrap"
                        mt="10px"
                    >
                        <Grid item width="auto" display={{ xs: 'none', sm: 'block' }}>
                            <Typography
                                variant="subtitle2"
                                color="primary.dark"
                                fontWeight={700}
                                textAlign="left"
                                minWidth="55px"
                                mt={errors.creditLimit ? '13px' : '32px'}
                            >
                                MXN $
                            </Typography>
                        </Grid>

                        <Grid item flexGrow={1}>
                            <Typography
                                variant="body1"
                                color="success.main"
                                fontWeight={700}
                                m="5px"
                                sx={{
                                    whiteSpace: { sm: 'normal', md: 'nowrap' },
                                }}
                            >
                                {`*Monto máximo del periodo MXN$ ${formatAmount(
                                    remainingBalance || 0,
                                )}`}
                            </Typography>
                            <Grid maxWidth={{ xs: '100%', md: '310px' }}>
                                <Controller
                                    name="creditLimit"
                                    control={control}
                                    rules={{
                                        required: 'Ingresa el monto límite',
                                        validate: (newCredit) =>
                                            validateNewCredit(newCredit, maxForDisposition),
                                    }}
                                    render={({ field: { onChange, onBlur, value, ref, name } }) => (
                                        <CurrencyInput
                                            id="credit-limit"
                                            inputProps={{
                                                maxLength: 13,
                                            }}
                                            name={name}
                                            placeholder="Ingresa la cantidad"
                                            error={errors.creditLimit?.message}
                                            thousandSeparator
                                            decimalScale={2}
                                            onValueChange={(values: any) =>
                                                onChange(values.floatValue)
                                            }
                                            onBlur={onBlur}
                                            value={value || ''}
                                            inputRef={ref}
                                            disabled={loading || isUpdatedCredit}
                                            startAdornment={null}
                                            allowNegative={false}
                                            sx={{
                                                '.MuiInputBase-input': {
                                                    textAlign: 'right',
                                                    fontSize: 15,
                                                    padding: '16px 20px',
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item container xs={12} justifyContent="flex-start">
                        <Collapse in={alert.open}>
                            <Alert
                                severity={alert.type}
                                sx={{ mt: '30px' }}
                                iconMapping={{ info: <AccessTime /> }}
                            >
                                {alert.message}
                            </Alert>
                        </Collapse>
                    </Grid>

                    <Grid container justifyContent="center" mt="30px">
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {loading ? (
                            <InterpunctLoader />
                        ) : isUpdatedCredit ? (
                            <Grid container justifyContent="center">
                                <DefaultBtn
                                    size="small"
                                    onClick={() => onClose(true)}
                                    fullWidth={smDown}
                                >
                                    Entendido
                                </DefaultBtn>
                            </Grid>
                        ) : (
                            <>
                                <DefaultBtn
                                    variant="outlined"
                                    size="small"
                                    onClick={() => onClose()}
                                    fullWidth={smDown}
                                    sx={{
                                        mr: { xs: 0, sm: '25px' },
                                        mb: { xs: '25px', sm: 0 },
                                    }}
                                >
                                    Cancelar
                                </DefaultBtn>
                                <DefaultBtn
                                    size="small"
                                    type="submit"
                                    fullWidth={smDown}
                                    disabled={!isValid}
                                >
                                    Solicitar
                                </DefaultBtn>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default CreditLimitCardModal;
