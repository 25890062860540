import { ComponentType } from 'react';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { styled } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { toReadableTimelessISO } from 'utils/dates';
import { CustomPickersDayProps, DailyPaymentOrdersData } from './interfaces';
import DailyPaymentOrdersPaper from './DailyPaymentOrdersPaper';

const CustomPickersDay = styled(
    ({ dayData: dailyData, currentDateTime, ...props }: CustomPickersDayProps) => {
        const timelessDate = new Date(props?.day);
        timelessDate.setUTCHours(0, 0, 0, 0);

        const pickersDayTime = timelessDate.getTime(),
            timeDelta = pickersDayTime - currentDateTime;

        return (
            <PickersDay
                // @ts-ignore
                component="div"
                disableRipple
                {...props}
                selected={false}
                disableHighlightToday={false}
                disabled={false}
                data-testid={`${timeDelta === 0 ? 'todays-' : ''}payment-calendarDay-card`}
                aria-label={toReadableTimelessISO(props?.day?.toISOString?.(), {
                    includeYear: true,
                })}
            >
                <DailyPaymentOrdersPaper
                    role="presentation"
                    data={dailyData as DailyPaymentOrdersData | null}
                    loading={false}
                    size="sm"
                    // eslint-disable-next-line no-nested-ternary
                    timeType={timeDelta === 0 ? 'today' : timeDelta > 0 ? 'future' : 'past'}
                    dateTitle={`${`${props?.day?.getUTCDate()}`.padStart(2, '0')}` || ''}
                />
            </PickersDay>
        );
    },
)<CustomPickersDayProps>(({ theme }) => ({
    borderRadius: 10,
    height: 'auto',
    width: '100%',
    fontFamily: ["'Montserrat'", 'sans-serif'].join(','),
    marginBottom: theme.spacing(1),
    '&.Mui-disabled': {
        opacity: 0.3,
    },
})) as ComponentType<CustomPickersDayProps>;

export default CustomPickersDay;
