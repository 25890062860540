import { useState } from 'react';
import { Typography, Table } from '@fairplay2/ui';
import { Grid, TableCell, TableRow } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import { getItemsAtPage } from 'utils/lists';
import { UserType } from '../../team/interfaces';

interface UsersListProps {
    activeOtpUsers: UserType[];
    loading: boolean;
}

const User = ({ id, fullName }: UserType) => (
    <TableRow key={id}>
        <TableCell>
            <Typography
                data-testid="userFullName"
                variant="body1"
                color="primary.dark"
                fontWeight={500}
            >
                {fullName}
            </Typography>
        </TableCell>
        <TableCell>
            <Grid container alignItems="center" wrap="nowrap">
                <CheckCircleOutline
                    sx={{
                        color: 'success.main',
                        mr: 1,
                    }}
                />
                <Typography variant="body1" color="success.main" fontWeight={700}>
                    Activo
                </Typography>
            </Grid>
        </TableCell>
    </TableRow>
);

export const UsersList = ({ activeOtpUsers, loading }: UsersListProps) => {
    const [page, setPage] = useState(0),
        [rowsPerPage, setRowsPerPage] = useState(5);

    return (
        <Table
            HeaderRow={
                <TableRow>
                    <TableCell>
                        <Typography variant="body1" color="text.primary">
                            Usuario
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body1" color="text.primary" noWrap>
                            Estatus
                        </Typography>
                    </TableCell>
                </TableRow>
            }
            currentItems={getItemsAtPage(activeOtpUsers, page, rowsPerPage)}
            skeletonColumns={2}
            loading={loading}
            pagination={{
                count: activeOtpUsers.length,
                page,
                rowsPerPage,
                onPageChange: (_, newPage) => setPage(newPage),
                onRowsPerPageChange: (e) => {
                    setRowsPerPage(parseInt(e.target.value, 10));
                    setPage(0);
                },
            }}
            emptyItemsMessage="No hemos encontrado usuarios vinculados"
        >
            {User}
        </Table>
    );
};

export default UsersList;
