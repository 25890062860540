import { FC } from 'react';
import { Modal } from '@fairplay2/ui';
import ConfigureAdvertising from '../wizard-advertising/Configure';
import { WizardProps } from '../wizard-advertising/interfaces';

const Wizard: FC<WizardProps> = ({ open = false, onClose, ...props }) => (
    <Modal disableBackdropClick open={open} onClose={onClose} maxWidth={1280} disableEscapeKeyDown>
        <ConfigureAdvertising onClose={onClose} wizard {...props} />
    </Modal>
);

export default Wizard;
