import { forwardRef } from 'react';
import {
    CancelOutlined,
    CheckCircleOutlined,
    WatchLaterOutlined,
    PauseCircleOutline,
    MoveToInbox,
    Restore,
} from '@mui/icons-material';
import { StyledAlert } from './Status.styles';
import { StatusProps, StatusType } from './Status.types';

const getStatusSeverity = (status: StatusType) => {
    switch (status) {
        case 'pending':
        case 'scheduled':
        case 'draft':
        case 'requested':
        case 'refunded':
        case 'canceled_refund':
        case '0':
            return 'info';
        case 'approved':
        case 'executed':
        case 'active':
        case 'finished':
        case 'signed':
        case '1':
            return 'success';
        case 'rejected':
        case 'cancelled':
        case 'canceled':
        case 'cancel':
        case 'returned':
        case 'denied':
        case 'declined':
        case 'invalid':
        case 'reauthorize':
        case '2':
            return 'error';
        case 'suspended':
        case 'disabled':
        case 'archived':
        case 'warning':
        case 'inactive':
        case 'blocked':
            return 'warning';
        default:
            return undefined;
    }
};

const getStatusIcon = (status: StatusType) => {
    switch (status) {
        case 'pending':
        case 'scheduled':
        case 'draft':
        case 'requested':
        case '0':
            return <WatchLaterOutlined />;
        case 'approved':
        case 'executed':
        case 'active':
        case 'finished':
        case 'signed':
        case '1':
            return <CheckCircleOutlined />;
        case 'rejected':
        case 'cancelled':
        case 'canceled':
        case 'cancel':
        case 'returned':
        case 'denied':
        case 'declined':
        case 'invalid':
        case '2':
            return <CancelOutlined />;
        case 'suspended':
        case 'disabled':
        case 'reauthorize':
        case 'inactive':
        case 'blocked':
            return <PauseCircleOutline />;
        case 'archived':
            return <MoveToInbox />;
        case 'refunded':
        case 'canceled_refund':
            return <Restore />;
        default:
            return undefined;
    }
};

export const Status = forwardRef<HTMLDivElement, StatusProps>(
    ({ children, status, variant = 'filled', role = 'status', ...props }, ref) => (
        <StyledAlert
            ref={ref}
            role={role}
            icon={status && getStatusIcon(status)}
            severity={getStatusSeverity(status)}
            variant={variant}
            {...props}
        >
            {children}
        </StyledAlert>
    ),
);
