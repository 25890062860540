import { FC, useState, useEffect, useContext, ChangeEvent, MouseEvent } from 'react';
import {
    Grid,
    MenuItem,
    useMediaQuery,
    useTheme,
    Collapse,
    TableCell,
    TableRow,
} from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import { PaperButton, Typography, Divider, Select, SearchInput, Alert } from '@fairplay2/ui';
import { Table } from 'common-components';
import fairplayAPI from 'utils/api';
import SessionContext from 'context/session/sessionContext';
import { LeavesWomanSvg } from 'assets/SVGs';
import { addGoogleTag } from 'utils/google-tag-manager';
import { useAlert } from 'utils/hooks';
import AddVendorModal from './components/AddVendorModal';
import DeleteVendorModal from './components/DeleteVendorModal';
import VendorsTableItem from './components/VendorsTableItem';
import { Vendor, Modals } from './interfaces';

const statusList: { [key: string]: string } = {
    pending: 'Pendiente',
    denied: 'Rechazado',
    approved: 'Aprobado',
    disabled: 'Deshabilitado',
};

const Vendors: FC = () => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        { selectedCompany } = useContext(SessionContext),
        [requestParams, setRequestParams] = useState(''),
        [status, setStatus] = useState(''),
        [search, setSearch] = useState(''),
        [vendors, setVendors] = useState<Vendor[]>([]),
        [loading, setLoading] = useState(true),
        [vendorToDelete, setVendorToDelete] = useState<Vendor | null>(null),
        [isModalOpen, setIsModalOpen] = useState<Modals>({
            addVendor: false,
            deleteVendor: false,
        }),
        { alert, hideAlert, showAlert } = useAlert(),
        [count, setCount] = useState(0),
        [page, setPage] = useState(0),
        [rowsPerPage, setRowsPerPage] = useState(5),
        [companyId, setCompanyId] = useState('');

    const resetTable = () => {
        setSearch('');
        setStatus('');
        setPage(0);
        setRowsPerPage(5);
        setCompanyId('');
    };

    const onChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const onSelect = (event: ChangeEvent<HTMLInputElement>) => {
        setStatus(event.target.value);
    };

    const onModalOpen = (target: string) => {
        setIsModalOpen({ ...isModalOpen, [target]: true });
    };

    const onModalClose = (target: string, success?: boolean) => {
        setIsModalOpen({ ...isModalOpen, [target]: false });
        if (target === 'deleteVendor' && success) {
            resetTable();
        }
        document.body.style.overflow = 'auto';
    };

    const onAddNewVendor = () => {
        onModalOpen('addVendor');
        addGoogleTag('add-new-vendor');
    };

    const onDeleteVendor = (vendor: Vendor) => {
        setVendorToDelete(vendor);
        onModalOpen('deleteVendor');
    };

    // Manages all table dependencies changes
    useEffect(() => {
        if (!companyId) return;
        hideAlert();

        (async () => {
            setLoading(true);
            try {
                const res: any = await fairplayAPI.get(
                    `/v1/companies/${companyId}/suppliers${requestParams}`,
                );
                const resCount = res.data.body.count;
                const resArr = res.data.body.results;
                const newCount = resCount || resArr.length;
                setCount(newCount);
                setVendors(resArr);
                setLoading(false);
            } catch {
                showAlert('No se pudieron cargar los proveedores, intente más tarde');
                setLoading(false);
            }
        })();
    }, [requestParams, companyId, hideAlert, showAlert]);

    // In order to reset the table just on the first render, assigns the company and contract ids to state.
    useEffect(() => {
        if (!selectedCompany.company) return;
        resetTable();
        setCompanyId(selectedCompany.company?.id);
    }, [selectedCompany, companyId]);

    return (
        <>
            {isModalOpen.addVendor && (
                <AddVendorModal isModalOpen={isModalOpen.addVendor} onModalClose={onModalClose} />
            )}

            {isModalOpen.deleteVendor && (
                <DeleteVendorModal
                    isModalOpen={isModalOpen.deleteVendor}
                    onModalClose={onModalClose}
                    vendorToDelete={vendorToDelete}
                />
            )}

            <Grid container>
                <Grid item xs={12} mb="30px" textAlign={{ xs: 'center', sm: 'left' }}>
                    <Typography variant="h4" color="primary.main">
                        Agrega un{' '}
                        <Typography component="span" color="primary.dark" fontWeight={700}>
                            nuevo proveedor
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item container xs={12} justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                    <PaperButton
                        iconAnimation="zoom"
                        variant="elevation"
                        role="button"
                        aria-label="Agrega un nuevo proveedor"
                        color="green"
                        icon={<AddCircle />}
                        onClick={onAddNewVendor}
                        sx={{
                            width: '214px',
                            height: '220px !important',
                            backgroundColor: 'background.secondary',
                        }}
                    >
                        <Grid container alignItems="flex-end" height="100%" p="20px">
                            <Grid item>
                                <Typography
                                    variant="subtitle1"
                                    color="primary.main"
                                    fontWeight={700}
                                    lineHeight={1.1}
                                    mb="5px"
                                >
                                    Agrega un nuevo proveedor
                                </Typography>
                                <Typography variant="body1" color="text.primary">
                                    Nacional o Internacional
                                </Typography>
                            </Grid>
                        </Grid>
                        {!smDown && (
                            <LeavesWomanSvg
                                style={{ position: 'absolute', top: 31, right: -103 }}
                            />
                        )}
                    </PaperButton>
                </Grid>
                <Grid item mt="35px">
                    <Typography variant="subtitle1" color="primary.main" mb="10px">
                        Directorio de proveedores
                    </Typography>
                    <Divider
                        variant="inset"
                        sx={{
                            height: '5px',
                        }}
                    />
                </Grid>

                <Grid
                    item
                    container
                    xs={12}
                    m="40px 0"
                    justifyContent={{ xs: 'center', sm: 'flex-start' }}
                    alignItems="center"
                >
                    <Grid
                        item
                        container
                        sm={12}
                        md={9}
                        alignItems="center"
                        justifyContent={{ xs: 'space-between', md: 'flex-start' }}
                        mb={{ xs: '20px', md: 0 }}
                    >
                        <Typography
                            id="vendor-filter-by-status"
                            htmlFor="status"
                            component="label"
                            color="primary.dark"
                            m={{ xs: '0 0 10px 0', md: '0 40px 0 0' }}
                        >
                            Filtrar listado por estatus:
                        </Typography>
                        <Select
                            id="vendor-all-status"
                            labelVariant="none"
                            value={status}
                            onChange={onSelect}
                            sx={{
                                width: { xs: '100%', md: '300px' },
                            }}
                            SelectProps={{
                                SelectDisplayProps: {
                                    'aria-labelledby': 'vendor-filter-by-status vendor-all-status',
                                },
                            }}
                        >
                            <MenuItem value="">Todos los estatus</MenuItem>
                            {Object.keys(statusList).map((stat) => (
                                <MenuItem key={stat} value={stat}>
                                    {statusList[stat]}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={3} justifyContent="flex-end">
                        <SearchInput
                            label="Busca por alias o RFC"
                            fullWidth
                            placeholder="Busca por alias o RFC"
                            value={search}
                            onChange={onSearch}
                        />
                    </Grid>
                </Grid>
                <Collapse in={alert.open}>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        item
                        xs={12}
                        mb="30px"
                    >
                        <Alert variant="filled" severity={alert.type}>
                            {alert.message}
                        </Alert>
                    </Grid>
                </Collapse>
                <Grid item container xs={12} justifyContent="center">
                    <Table
                        // eslint-disable-next-line react/no-unstable-nested-components
                        getTableItem={(vendor: Vendor, i: number) => (
                            <VendorsTableItem
                                vendor={vendor}
                                key={i}
                                onDeleteVendor={onDeleteVendor}
                            />
                        )}
                        currentItems={vendors}
                        columns={6}
                        loading={loading}
                        pagination={{
                            count,
                            page,
                            rowsPerPage,
                            onPageChange: onChangePage,
                            onRowsPerPageChange: onChangeRowsPerPage,
                        }}
                        externalFilters={{
                            status,
                            search,
                        }}
                        emptyItemsMessage="No hemos encontrado contratos disponibles"
                        onParamsQueryChange={setRequestParams}
                    >
                        <TableRow>
                            <TableCell>
                                <Typography color="text.primary">Alias</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography color="text.primary">RFC / TAX ID</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography color="text.primary">País</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography color="text.primary">Estatus</Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography color="text.primary">Información</Typography>
                            </TableCell>
                            <TableCell align="center" sx={{ width: 100 }}>
                                <Typography color="text.primary">Acciones</Typography>
                            </TableCell>
                        </TableRow>
                    </Table>
                </Grid>
            </Grid>
        </>
    );
};

export default Vendors;
