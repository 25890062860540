import { useState } from 'react';
import img from 'assets/img/connectors/meli_logo.png';
import fairplayAPI from 'utils/api';
import { toFormData } from 'utils/parsing';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { PlatformItem } from '../interfaces';
import BasePlatformItem from '../components/BasePlatformItem';
import Wizard from '../components/wizard-ecommerce';
import useCodeInterceptor from '../useCodeInterceptor';
import { useDataSources } from '../../context/DataSourcesContext';
import { ConnectorData, InterceptedParams, OnCodeInterception } from '../../interfaces';

const REDIRECTION_PARAMS = ['code'];

// eslint-disable-next-line react/prop-types
const MercadoLibre: PlatformItem = ({ notify }) => {
    const { baseAPI, dispatch } = useDataSources(),
        [loading, setLoading] = useState(false),
        [showWizard, setShowWizard] = useState<boolean | ConnectorData>(false);

    const onConnect = () => {
        setShowWizard(true);
    };

    const onReauth = (connector: ConnectorData) => {
        setShowWizard(connector);
    };

    const createConnector = async (
        { code }: InterceptedParams,
        { connector }: Parameters<OnCodeInterception>[1],
    ) => {
        setLoading(true);
        const data = toFormData({
                platform: 'mercado-libre',
                code,
            }),
            endpoint = `${baseAPI}${connector ? `/${connector}/reconnect` : ''}`;

        try {
            const res: any = await fairplayAPI.post(endpoint, data);
            dispatch({
                type: connector ? 'UPDATE' : 'ADD',
                payload: res?.data?.body?.results,
                onCommit: () => {
                    if (connector) notify('Se ha reconectado Mercado Libre con éxito', 'success');
                    else setShowWizard(res?.data?.body?.results);
                },
            });
        } catch (errResponse: ErrorObj | any) {
            let error = '';
            if (errResponse.status !== 500) {
                // Show single error from handleError or append form errors if needed
                if (errResponse.type === 'form') {
                    error = Object.values(errResponse?.error || {}).reduce<string>(
                        (acc, value) =>
                            Array.isArray(value) && value.length > 0 ? `${acc}${value[0]}\n` : acc,
                        error,
                    );
                } else if (errResponse.error) {
                    error = errResponse.error;
                }
            }
            notify(
                error ||
                    `Ocurrió un error al generar la conexión con Mercado libre, intente más tarde`,
                'error',
            );
        } finally {
            setLoading(false);
        }
    };

    const onCodeInterception: OnCodeInterception = (foundParams, metadata) => {
        if (loading) return false;

        createConnector(foundParams, metadata);
        return true;
    };

    useCodeInterceptor('mercado-libre', REDIRECTION_PARAMS, onCodeInterception);

    return (
        <BasePlatformItem
            picture={img}
            groupKey="mercado-libre"
            showLoader={loading}
            notify={notify}
            onConnect={onConnect}
            onReauth={onReauth}
        >
            <Wizard
                open={!!showWizard}
                groupKey="mercado-libre"
                element={typeof showWizard === 'boolean' ? null : showWizard}
                onClose={() => setShowWizard(false)}
            />
        </BasePlatformItem>
    );
};

export default MercadoLibre;
