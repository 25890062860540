import { FC } from 'react';
import { Grid } from '@mui/material';
import { Skeleton } from '@fairplay2/ui';

const VendorDetailSkeleton: FC = () => (
    <Grid container mt="10px" data-testid="vendor-detail-skeleton">
        <Grid item container xs={12} spacing={4}>
            <Grid item xs={12} sm={6}>
                <Skeleton variant="rectangular" height={50} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Skeleton variant="rectangular" height={50} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Skeleton variant="rectangular" height={50} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Skeleton variant="rectangular" height={50} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Skeleton variant="rectangular" height={50} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Skeleton variant="rectangular" height={50} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Skeleton variant="rectangular" height={50} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Skeleton variant="rectangular" height={50} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Skeleton variant="rectangular" height={50} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Skeleton variant="rectangular" height={50} />
            </Grid>

            <Grid item xs={12} sm={6}>
                <Skeleton variant="rectangular" height={50} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Skeleton variant="rectangular" height={50} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Skeleton variant="rectangular" height={50} />
            </Grid>
        </Grid>
        <Grid item container xs={12} justifyContent="flex-end" marginTop="20px">
            <Skeleton variant="rectangular" width={180} height={38} />
        </Grid>
    </Grid>
);

export default VendorDetailSkeleton;
