import { FC, useEffect, useState } from 'react';
import { Collapse, Grid, useMediaQuery, useTheme } from '@mui/material';
import { Alert, Modal, Typography } from '@fairplay2/ui';
import fairplayAPI from 'utils/api';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { useAlert } from 'utils/hooks';
import { CardInfoModalProps } from '../../interfaces';
import IframeCardSkeleton from './IframeCardSkeleton';
import IframeCard from './IframeCard';

const MODAL_STYLES = {
    '& .MuiDialog-paper': {
        margin: { xs: '10px', sm: '25px' },
        padding: { xs: '35px 15px', sm: '25px' },
        width: ['390px', 'auto'],
    },
    '& .MuiDialogContent-root': {
        padding: { xs: '25px 5px', sm: '25px' },
        display: 'flex',
        flexDirection: 'column',
        alignItems: { xs: 'center', sm: 'flex-start' },
    },
};

export const CardInfoModal: FC<CardInfoModalProps> = ({ onClose, cardId }) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        [showIframe, setShowIframe] = useState(false),
        [loading, setLoading] = useState(false),
        { alert, hideAlert, showAlert } = useAlert(),
        [{ secureToken, pomeloCardId }, setAuthInfoCard] = useState({
            secureToken: '',
            pomeloCardId: '',
        }),
        typeOfLayout = smDown ? 'list' : 'card';

    useEffect(() => {
        hideAlert();
        (async () => {
            setLoading(true);
            try {
                const res: any = await fairplayAPI.get(`/cards/v1/card/${cardId}/generate-token`, {
                        baseService: 'CARDS',
                    }),
                    { secure_token, pomelo_card_id } = res.data.body.results;

                setAuthInfoCard({
                    secureToken: secure_token,
                    pomeloCardId: pomelo_card_id,
                });
                setShowIframe(true);
                setLoading(false);
            } catch (errResponse: ErrorObj | any) {
                showAlert(
                    errResponse?.error ||
                        'No puedes usar tu tarjeta en este momento. Inténtalo más tarde.',
                    'error',
                    false,
                );
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal
            aria-labelledby="display-info-card"
            open={!!cardId}
            onClose={onClose}
            sx={MODAL_STYLES}
        >
            {!loading && (
                <Typography
                    variant="subtitle2"
                    fontWeight={500}
                    color={showIframe ? 'primary.main' : 'text.disabled'}
                    mb="15px"
                    id="display-info-card"
                >
                    Estos son los datos de tu tarjeta digital:
                </Typography>
            )}

            <Grid container flexDirection="column" alignItems={{ xs: 'center', sm: 'flex-start' }}>
                {loading ? (
                    <IframeCardSkeleton isMobile={smDown} />
                ) : (
                    showIframe && (
                        <>
                            {process.env.NODE_ENV === 'test' ? (
                                <div data-testid="iframe-card" />
                            ) : (
                                <IframeCard
                                    smDown={smDown}
                                    pomeloCardId={pomeloCardId}
                                    secureToken={secureToken}
                                    typeOfLayout={typeOfLayout}
                                />
                            )}

                            <Typography
                                variant="caption"
                                fontWeight={500}
                                color="text.primary"
                                maxWidth="320px"
                                mt="10px"
                            >
                                Cuida tu tarjeta. Por tu seguridad te recomendamos no hacer capturas
                                de pantalla.
                            </Typography>
                        </>
                    )
                )}

                <Grid container justifyContent="center" mt={alert.open ? '10px' : 'initial'}>
                    <Collapse in={alert.open}>
                        <Alert severity={alert.type} sx={{ maxWidth: '313px' }}>
                            {alert.message}
                        </Alert>
                    </Collapse>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default CardInfoModal;
