import { FC, ChangeEvent } from 'react';
import {
    Grid,
    useMediaQuery,
    useTheme,
    FormControl,
    MenuItem,
    Collapse,
    RadioGroup,
    FormControlLabel,
    FormHelperText,
} from '@mui/material';
import {
    DefaultBtn,
    Select,
    InterpunctLoader,
    Typography,
    Radio,
    Alert,
    FilePicker,
} from '@fairplay2/ui';
import { generateYearsArray } from 'utils/dates';
import { Step5Props } from '../interfaces';

const Step5: FC<Step5Props> = ({
    files,
    alert,
    loading,
    selectedYear,
    isFirstBuy,
    errors,
    onFirstBuyChange,
    onFileChange,
    onStepChange,
    onRegisterUser,
    onSelect,
    onBlur,
}) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Grid container spacing={4} mt="30px" justifyContent="center">
                <Grid item container xs={12} sm={4} alignItems="center">
                    <Typography variant="subtitle2" color="text.primary">
                        ¿Es la primera vez que le compras?
                    </Typography>
                </Grid>
                <Grid item container xs={12} sm={8} alignItems="center">
                    <FormControl component="fieldset">
                        <RadioGroup
                            value={isFirstBuy}
                            onChange={onFirstBuyChange}
                            sx={{ flexDirection: 'row' }}
                        >
                            <FormControlLabel
                                value="yes"
                                control={<Radio color="primary" />}
                                label="Si"
                                sx={{ pr: '20px' }}
                            />
                            <FormControlLabel
                                value="no"
                                control={<Radio color="primary" />}
                                label="No"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>

                {isFirstBuy === 'no' && (
                    <>
                        <Grid item container xs={12} justifyContent="flex-start">
                            <Grid item container xs={12} sm={4} alignItems="center">
                                <Typography variant="subtitle2" color="text.primary">
                                    Año de primera compra:
                                </Typography>
                            </Grid>
                            <Grid item container xs={12} sm={3} alignItems="center">
                                <Select
                                    id="selected-year"
                                    value={selectedYear}
                                    onChange={onSelect}
                                    error={errors.selectedYear}
                                    onBlur={() =>
                                        onBlur('selectedYear', 'Ingresa el año de primera compra')
                                    }
                                >
                                    <MenuItem disabled value={0}>
                                        Selecciona el año
                                    </MenuItem>
                                    {generateYearsArray(16).map((year) => (
                                        <MenuItem key={year} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="primary.main">
                                Por favor, compártenos 5 facturas de interacciones que hayas tenido
                                con el proveedor
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            spacing={4}
                            justifyContent={{ xs: 'center', sm: 'flex-start' }}
                        >
                            {Array(5)
                                .fill(0)
                                .map((_, i) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Grid item key={i}>
                                        <FilePicker
                                            name={`file-upload-${i}`}
                                            onFileChange={onFileChange}
                                            disabled={i !== 0 && !files[i - 1]}
                                            paperVariant="dashed"
                                            fileName={files[i] ? files[i].name : ''}
                                            accept={
                                                'image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                            }
                                            iconAnimation="up"
                                            AdditionalHelp="Comprobante de compra"
                                            sx={{
                                                width: 220,
                                                height: 220,
                                            }}
                                        />
                                        {i === 0 && !files[i - 1] && (
                                            <FormHelperText error={!!errors.invoiceFile}>
                                                {errors.invoiceFile}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                ))}
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid item container xs={12} justifyContent="flex-end" padding="10px 0px">
                <Collapse in={alert.open}>
                    <Alert sx={{ justifyContent: 'center' }} severity={alert.type}>
                        {alert.message}
                    </Alert>
                </Collapse>
            </Grid>

            <Grid container justifyContent="flex-end">
                {loading ? (
                    <InterpunctLoader />
                ) : (
                    <Grid container justifyContent="flex-end">
                        <DefaultBtn
                            fullWidth={smDown}
                            size="small"
                            variant="outlined"
                            onClick={(e: ChangeEvent<{}>) => onStepChange(e, 3)}
                            sx={{
                                mb: { xs: '10px', sm: 0 },
                            }}
                        >
                            Atrás
                        </DefaultBtn>
                        {isFirstBuy && (
                            <DefaultBtn
                                fullWidth={smDown}
                                size="small"
                                onClick={onRegisterUser}
                                sx={{
                                    mb: { xs: '20px', sm: 0 },
                                    ml: { xs: 0, sm: '20px' },
                                }}
                            >
                                Finalizar
                            </DefaultBtn>
                        )}
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default Step5;
