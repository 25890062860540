import { styled } from '@mui/material/styles';
import { Grid, lighten } from '@mui/material';
import { GridProps } from '@mui/system';

export const IconContainer = styled(Grid, {
    shouldForwardProp: (prop) => prop !== 'iconVariant',
})<GridProps & { color?: string; iconVariant?: 'filled' | 'outlined' }>(
    ({ color, theme, iconVariant }) => ({
        borderRadius: iconVariant === 'outlined' ? '' : '50%',
        width: iconVariant === 'outlined' ? 22 : 44,
        height: iconVariant === 'outlined' ? 'auto' : 44,
        backgroundColor:
            iconVariant === 'outlined' ? '' : lighten(color || theme.palette.secondary.main, 0.8),
        position: 'relative',
    }),
);
