import { FC } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { Typography } from '@fairplay2/ui';
import { ErrorContents, ErrorType, FileErrorItemProps } from '../../interfaces';

const keyDictionary = (key: ErrorType): string => {
    switch (key) {
        case 'date':
            return 'Fecha';
        case 'currency':
            return 'Divisa';
        case 'amount':
            return 'Monto';
        case 'concept':
            return 'Concepto';
        case 'supplierId':
            return 'Proveedor';
        case 'reference':
            return 'Referencia';
        case 'invoiceNumber':
            return 'Folio';
        default:
            return '';
    }
};

export const getErrorDescription = (errors: ErrorContents) =>
    Object.entries(errors).reduce((acc, [errorType, description]) => {
        if (!description) return acc;
        const itemString = `${keyDictionary(errorType as ErrorType)}: ${description}`;
        return `${acc}${acc ? '\n' : ''}${itemString}`;
    }, '');

const FileErrorItem: FC<FileErrorItemProps> = ({ error }) => (
    <TableRow>
        <TableCell sx={{ py: 4 }}>
            <Typography fontWeight={700} color="primary.main">
                Línea {error.row}
            </Typography>
        </TableCell>
        <TableCell sx={{ py: 4 }}>
            <Typography fontWeight={500} whiteSpace="pre-line" color="primary.dark">
                {getErrorDescription(error.errors)}
            </Typography>
        </TableCell>
    </TableRow>
);

export default FileErrorItem;
