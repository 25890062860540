import { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { useDisbursementValuesContext } from 'context/disbursements/DisbursementValuesContext';
import CreateDisbursementV1 from './index.v1';
import CreateDisbursement from './index';

const VersionController: FC = () => {
    const { enableDisbursementsV2Module } = useDisbursementValuesContext();

    // eslint-disable-next-line no-nested-ternary
    return typeof enableDisbursementsV2Module !== 'boolean' ? (
        <Redirect push to="/app/sources" />
    ) : enableDisbursementsV2Module ? (
        <CreateDisbursement />
    ) : (
        <CreateDisbursementV1 />
    );
};

export default VersionController;
