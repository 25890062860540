import { FC } from 'react';
import { Typography } from '@fairplay2/ui';
import { BaseModal } from 'common-components';
import { CartWarningSvg, GenericLostConnectionSvg } from 'assets/SVGs';
import { capitalize } from 'utils/formatting';
import { DisconnectConfirmationProps } from '../../interfaces';
import { getConnectorAlias } from '../../../utils';

export const Confirmation: FC<DisconnectConfirmationProps> = ({ item, sourceString, platform }) => {
    const isOrdersSource = sourceString === 'la fuente de órdenes';

    return (
        <>
            <BaseModal.Icon>
                {isOrdersSource ? <CartWarningSvg /> : <GenericLostConnectionSvg />}
            </BaseModal.Icon>
            <BaseModal.Title>
                {isOrdersSource
                    ? 'Se ha desconectado una tienda'
                    : 'Fuente desconectada exitosamente'}
            </BaseModal.Title>
            <BaseModal.Content>
                {isOrdersSource ? (
                    <>
                        <strong>
                            <Typography color="primary.main" component="span">
                                {item ? capitalize(getConnectorAlias(item)) : platform}
                            </Typography>{' '}
                            se ha desconectado
                        </strong>{' '}
                        de tus canales de venta.
                        {!item?.platform.includes('s3') &&
                            ' Ya no podrás visualizar esta información en tu tablero.'}
                    </>
                ) : (
                    <Typography variant="subtitle2" color="text.primary" gutterBottom>
                        {capitalize(sourceString)} <strong>{platform}</strong> ha sido desconectada
                        exitosamente.
                    </Typography>
                )}
            </BaseModal.Content>
            <BaseModal.Actions primaryLabel="Entendido" />
        </>
    );
};

export default Confirmation;
