import { FC } from 'react';
import { Grid } from '@mui/material';
import { Typography, DefaultInput } from '@fairplay2/ui';
import { formatAmount, formatPerc } from 'utils/parsing';
import { SHORT_READABLE_FORMAT, formatUtc } from 'utils/dates';

const formatMxnAmount = (amount: string) => `MXN $ ${formatAmount(amount)}`;

const ContractSpecs: FC<any> = ({ contractValues }) => (
    <Grid item container spacing={4} marginTop="10px" marginBottom="30px">
        <Grid item xs={12}>
            <Typography color="primary.dark" fontWeight={700}>
                Generales
            </Typography>
        </Grid>
        <Grid container alignItems="flex-end" item xs={12} sm={6} md={4}>
            <DefaultInput
                labelVariant="external"
                label="Creado por"
                disabled
                value={contractValues?.createdBy || ''}
            />
        </Grid>
        <Grid container alignItems="flex-end" item xs={12} sm={6} md={4}>
            <DefaultInput
                labelVariant="external"
                label="Fecha de creación"
                disabled
                value={formatUtc(contractValues?.created || '', SHORT_READABLE_FORMAT, {
                    fallbackString: '',
                })}
            />
        </Grid>
        <Grid container alignItems="flex-end" item xs={12} sm={6} md={4}>
            <DefaultInput
                labelVariant="external"
                label="Fecha de firma"
                disabled
                value={formatUtc(contractValues?.signatureDate || '', SHORT_READABLE_FORMAT, {
                    fallbackString: '',
                })}
            />
        </Grid>
        <Grid container alignItems="flex-end" item xs={12} sm={6} md={4}>
            <DefaultInput
                labelVariant="external"
                label="Fecha de activación"
                disabled
                value={formatUtc(contractValues?.activationDate || '', SHORT_READABLE_FORMAT, {
                    fallbackString: '',
                })}
            />
        </Grid>
        <Grid container alignItems="flex-end" item xs={12} sm={6} md={4}>
            <DefaultInput
                labelVariant="external"
                label="Tipo de financiamiento"
                disabled
                value={contractValues?.productType.text || ''}
            />
        </Grid>
        <Grid container alignItems="flex-end" item xs={12} sm={6} md={4}>
            <DefaultInput
                labelVariant="external"
                label="Divisa principal"
                disabled
                value={contractValues?.principalCurrency || ''}
            />
        </Grid>
        <Grid item xs={12}>
            <Typography color="primary.dark" fontWeight={700}>
                Términos de financiamiento
            </Typography>
        </Grid>
        <Grid item container xs={12} spacing={4}>
            <Grid container alignItems="flex-end" item xs={12} sm={6} md={4}>
                <DefaultInput
                    labelVariant="external"
                    label="Monto de financiamiento"
                    disabled
                    value={formatMxnAmount(contractValues?.quote.principalAmount) || ''}
                />
            </Grid>
        </Grid>
        <Grid container alignItems="flex-end" item xs={12} sm={6} md={4}>
            <DefaultInput
                labelVariant="external"
                label="Duración proyectada del financiamiento"
                disabled
                value={`${contractValues?.quote.projectedFinancingDays} días` || ''}
            />
        </Grid>
        <Grid container alignItems="flex-end" item xs={12} sm={6} md={4}>
            <DefaultInput
                labelVariant="external"
                label="Comisión por disposición %"
                disabled
                value={`${formatPerc(contractValues?.quote.disbursementFee)} %` || ''}
            />
        </Grid>
        <Grid container alignItems="flex-end" item xs={12} sm={6} md={4}>
            <DefaultInput
                labelVariant="external"
                label="Comisión por apertura %"
                disabled
                value={`${formatPerc(contractValues?.originationFee)} %` || ''}
            />
        </Grid>
        <Grid item xs={12}>
            <Typography color="primary.dark" fontWeight={700}>
                Términos de dispersión
            </Typography>
        </Grid>

        <Grid container alignItems="flex-end" item xs={12} sm={6} md={4}>
            <DefaultInput
                labelVariant="external"
                label="% Disposición máxima"
                disabled
                value={`${formatPerc(contractValues?.quote.maxDispositionPercentage)} %` || ''}
            />
        </Grid>
        <Grid container alignItems="flex-end" item xs={12} sm={6} md={4}>
            <DefaultInput
                labelVariant="external"
                label="Periodo de dispersión"
                disabled
                value={`${contractValues?.quote.disbursementPeriodMonths} meses` || ''}
            />
        </Grid>
        <Grid container alignItems="flex-end" item xs={12} sm={6} md={4}>
            <DefaultInput
                labelVariant="external"
                label="Fecha límite para dispersiones"
                disabled
                value={formatUtc(
                    contractValues?.disbursementPeriodEndDate || '',
                    SHORT_READABLE_FORMAT,
                    {
                        fallbackString: '',
                    },
                )}
            />
        </Grid>
        <Grid item xs={12}>
            <Typography color="primary.dark" fontWeight={700}>
                Términos de pago
            </Typography>
        </Grid>
        <Grid item container xs={12} spacing={4}>
            <Grid container alignItems="flex-end" item xs={12} sm={6} md={4}>
                <DefaultInput
                    labelVariant="external"
                    label="Modelo de pago"
                    disabled
                    value={contractValues?.quote.paymentModel?.text || ''}
                />
            </Grid>
        </Grid>
        <Grid container alignItems="flex-end" item xs={12} sm={6} md={4}>
            <DefaultInput
                labelVariant="external"
                label="Periodo de gracia"
                disabled
                value={`${contractValues?.quote.gracePeriodDays} días` || ''}
            />
        </Grid>
        <Grid container alignItems="flex-end" item xs={12} sm={6} md={4}>
            <DefaultInput
                labelVariant="external"
                label="Fecha de término periodo de gracia"
                disabled
                value={formatUtc(contractValues?.gracePeriodEndDate || '', SHORT_READABLE_FORMAT, {
                    fallbackString: '',
                })}
            />
        </Grid>
        <Grid container alignItems="flex-end" item xs={12} sm={6} md={4}>
            <DefaultInput
                labelVariant="external"
                label="Fecha inicio de pagos"
                disabled
                value={formatUtc(
                    contractValues?.paymentPeriodStartDate || '',
                    SHORT_READABLE_FORMAT,
                    {
                        fallbackString: '',
                    },
                )}
            />
        </Grid>
        <Grid container alignItems="flex-end" item xs={12} sm={6} md={4}>
            <DefaultInput
                labelVariant="external"
                label="Factor de reembolso"
                disabled
                value={`${formatPerc(contractValues?.quote.takeRate)} %` || ''}
            />
        </Grid>
        <Grid container alignItems="flex-end" item xs={12} sm={6} md={4}>
            <DefaultInput
                labelVariant="external"
                label="Pago mínimo diario"
                disabled
                value={formatMxnAmount(contractValues?.quote.minDailyPayment) || ''}
            />
        </Grid>
        <Grid container alignItems="flex-end" item xs={12} sm={6} md={4}>
            <DefaultInput
                labelVariant="external"
                label="Pago máximo diario"
                disabled
                value={formatMxnAmount(contractValues?.quote.maxDailyPayment) || ''}
            />
        </Grid>
        <Grid item xs={12}>
            <Typography color="primary.dark" fontWeight={700}>
                Condiciones del contrato
            </Typography>
        </Grid>
        <Grid container alignItems="flex-end" item xs={12} sm={6} md={4}>
            <DefaultInput
                labelVariant="external"
                label="Monto del Importe del pagaré"
                disabled
                value={formatMxnAmount(contractValues?.promissoryNoteAmount) || ''}
            />
        </Grid>
        <Grid container alignItems="flex-end" item xs={12} sm={6} md={4}>
            <DefaultInput
                labelVariant="external"
                label="GMV promedio"
                disabled
                value={formatMxnAmount(contractValues?.quote.avgGmv) || ''}
            />
        </Grid>
        <Grid container alignItems="flex-end" item xs={12} sm={6} md={4}>
            <DefaultInput
                labelVariant="external"
                label="Crecimiento promedio ingresos"
                disabled
                value={`${contractValues?.quote.avgRevenueGrowth} %` || ''}
            />
        </Grid>
        <Grid container alignItems="flex-end" item xs={12} sm={6} md={4}>
            <DefaultInput
                labelVariant="external"
                label="Mínimo GMV pactado"
                disabled
                value={formatMxnAmount(contractValues?.quote.minGmv) || ''}
            />
        </Grid>
    </Grid>
);

export default ContractSpecs;
