import {
    FC,
    Fragment,
    useState,
    useContext,
    useEffect,
    ChangeEvent,
    MouseEvent,
    useRef,
} from 'react';
import {
    Grid,
    TableRow,
    TableCell,
    TableSortLabel,
    MenuItem,
    Collapse,
    Divider,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import { Alert, Select, Typography, DefaultInput, IconBtn, Tooltip } from '@fairplay2/ui';
import { DatePicker, getBoundaryYears } from '@fairplay2/ui-x-dates';
import { CurrencyRange } from '@fairplay2/ui-x-currencies';
import { Table, getActiveFilters, SearchFilters } from 'common-components';
import SessionContext from 'context/session/sessionContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { OrderType } from 'utils/interfaces';
import fairplayAPI from 'utils/api';
import { ArrowDownward, ExpandMore, Info } from '@mui/icons-material';
import { useAlert } from 'utils/hooks/useAlert';
import { asSorting } from 'utils/lists';
import { toTimelessISOUtc, mimicDateAtCDMX } from 'utils/dates';
import { statusDic } from '../utils';
import { DisTableProps, DisbursementObj, FilterValuesObj } from '../../interfaces';
import DisbursementsTableItem from './DisbursementsTableItem';

const disbursementTypeArr = [
        { value: 'national', label: 'Nacional' },
        { value: 'international', label: 'Internacional' },
        { value: 'card', label: 'Tarjeta' },
    ],
    filterContainerStyle = {
        borderRadius: '40px',
        px: { xs: '10px', sm: '20px' },
        '&:hover': {
            backgroundColor: '#F6F6F9',
        },
        '.MuiInput-input': {
            cursor: 'pointer',
        },
    },
    DEFAULT_FILTERS = {
        unconfirmed: {
            selectedDayRange: {
                from: undefined,
                to: undefined,
            },
            rfc: '',
            type: '',
            status: '',
        } as FilterValuesObj,
        effective: {
            ordering: undefined as undefined | string,
            start_date: '',
            end_date: '',
            rfc: '',
            type: '',
            min_amount: NaN,
            max_amount: NaN,
            status: '',
        },
    };

const DisbursementsTable: FC<DisTableProps> = ({ selectedContract }) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        { selectedCompany } = useContext(SessionContext),
        [requestParams, setRequestParams] = useState(''),
        [page, setPage] = useState(0),
        [rowsPerPage, setRowsPerPage] = useState(5),
        [orderCriteria, setOrderCriteria] = useState<'date' | 'modified'>('date'),
        [order, setOrder] = useState<OrderType>('desc'),
        [loading, setLoading] = useState(true),
        [disbursements, setDisbursements] = useState<DisbursementObj[]>([]),
        [count, setCount] = useState(0),
        [selected, setSelected] = useState({
            companyId: '',
            contractId: '',
        }),
        [isFilterOff, setIsFilterOff] = useState(true),
        [activeFilters, setActiveFilters] = useState(0),
        [filterValues, setFilterValues] = useState(DEFAULT_FILTERS.unconfirmed),
        [sliderValue, setSliderValue] = useState<number[]>([]),
        [PickerProps] = useState(() => getBoundaryYears(mimicDateAtCDMX())),
        // externalFilters state necessary to avoid Table component filtering the data every time the user modifies an individual filter
        [externalFilters, setExternalFilters] = useState(DEFAULT_FILTERS.effective),
        waitForValidPagination = useRef(false),
        { alert, hideAlert, showAlert } = useAlert(),
        disbursementOptions = disbursementTypeArr.filter(
            (item) =>
                !(
                    item.value === 'international' &&
                    selectedContract?.productType === 'sales-advancement'
                ),
        );

    const onSortDate = (event: MouseEvent<HTMLElement>) => {
        let source: typeof orderCriteria | null = null;
        // eslint-disable-next-line no-restricted-syntax
        for (const element of event.nativeEvent.composedPath()) {
            const eventTrigger = (element as any).dataset?.criteria;
            if (eventTrigger) {
                source = eventTrigger;
                break;
            }
        }

        if (!source) return;
        if (source === orderCriteria) setOrder(order === 'asc' ? 'desc' : 'asc');
        else {
            setOrderCriteria(source);
            setOrder('desc');
        }
    };

    const onCurrencyRangeChange: typeof setSliderValue = (newValue) => {
        if (isFilterOff) setIsFilterOff(false);
        setSliderValue(newValue);
    };

    const onPageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        document.body.style.overflow = 'auto';
    };

    const onFiltersChange = (event: ChangeEvent<HTMLInputElement>) => {
        const filterName = event.target.name,
            newFilterValue = event.target.value;

        if (isFilterOff) setIsFilterOff(false);

        setFilterValues((prev) => ({
            ...prev,
            [filterName]: newFilterValue,
        }));
    };

    const onSelectedDay = (range: typeof filterValues['selectedDayRange']) =>
        onFiltersChange({
            target: { name: 'selectedDayRange', value: range },
            // Event interface expects `value` to be a string and needs more fields.
            //  Forcing type allows to reuse onFiltersChange logic
        } as any);

    const onFilterSearch = () => {
        const selectedDay = filterValues.selectedDayRange;

        const dateFilters = {
            start_date: selectedDay?.from ? toTimelessISOUtc(selectedDay.from) : '',
            end_date: selectedDay?.to ? toTimelessISOUtc(selectedDay.to) : '',
        };

        setActiveFilters(
            getActiveFilters({
                minMaxAmount: {
                    min_amount: sliderValue[0],
                    max_amount: sliderValue[1],
                },
                date: dateFilters,
                status: filterValues.status,
                type: filterValues.type,
                rfc: filterValues.rfc,
            }),
        );

        setExternalFilters({
            min_amount: sliderValue[0],
            max_amount: sliderValue[1],
            status: filterValues.status,
            type: filterValues.type,
            rfc: filterValues.rfc,
            ordering: asSorting(orderCriteria, order),
            ...dateFilters,
        });
    };

    const resetFilterValues = () => {
        setIsFilterOff(true);
        setFilterValues(DEFAULT_FILTERS.unconfirmed);
        setSliderValue([]);
        setOrder('desc');
        setOrderCriteria('date');
        setActiveFilters(0);
        setPage(0);
        setRowsPerPage(5);
        setExternalFilters(DEFAULT_FILTERS.effective);
    };

    useEffect(() => {
        if (!(selected.companyId && selected.contractId)) return;
        waitForValidPagination.current = true;
    }, [selected.companyId, selected.contractId]);

    useEffect(() => {
        if (!(selected.companyId && selected.contractId)) return;
        // TODO: Is it possible to improve Table component as to avoid having this extra check?
        if (waitForValidPagination.current) {
            waitForValidPagination.current = false;
            if (!requestParams.includes('page=1&')) return;
        }
        hideAlert();

        (async () => {
            setLoading(true);
            try {
                const res: any = await fairplayAPI.get(
                        `v3/companies/${selected.companyId}/contracts/${selected.contractId}/dispersions/list-with-cards${requestParams}`,
                    ),
                    resCount = res.data.body.count,
                    resArr = res.data.body.results,
                    newCount = resCount || resArr.length;

                setCount(newCount);
                setDisbursements(resArr);
                setLoading(false);
            } catch (errResponse: ErrorObj | any) {
                setLoading(false);
                showAlert(
                    errResponse?.error ||
                        'No se pudieron cargar las dispersiones, intente más tarde',
                );
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestParams, selected.companyId, selected.contractId]);

    // In order to reset the table just on the first render, assigns the company and contract ids to state.
    useEffect(() => {
        setLoading(true);
        if (selectedCompany.company?.id && selectedContract?.id) {
            resetFilterValues();
            setSelected({
                companyId: selectedCompany.company?.id,
                contractId: selectedContract.id,
            });
        }
    }, [selectedCompany.company?.id, selectedContract?.id]);

    return (
        <>
            <SearchFilters
                isFilterOff={isFilterOff}
                activeFilters={activeFilters}
                onFilterSearch={onFilterSearch}
                resetFilters={resetFilterValues}
                width="100%"
                marginBottom="10px"
                marginTop={smDown ? '10px' : '-15px'}
            >
                <Grid
                    item
                    container
                    minWidth="20%"
                    height="100%"
                    textAlign="center"
                    alignItems="center"
                    sx={filterContainerStyle}
                >
                    <DatePicker
                        variant="standard"
                        labelVariant="contained"
                        label="Fecha de solicitud"
                        // Would it be nice to have the value returned in difrerent formats?
                        mode="range"
                        placeholder="Desde - Hasta"
                        value={filterValues.selectedDayRange}
                        onChange={onSelectedDay}
                        inputAlignment="start"
                        PickerProps={PickerProps}
                    />
                </Grid>
                <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ color: 'background.dark' }}
                />
                <Grid
                    item
                    container
                    minWidth="19%"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                    sx={filterContainerStyle}
                >
                    <DefaultInput
                        id="rfcSearch"
                        name="rfc"
                        label="Alias / RFC / Tax ID"
                        labelVariant="contained"
                        value={filterValues.rfc}
                        onChange={onFiltersChange}
                        placeholder="¿De quién?"
                        variant="standard"
                    />
                </Grid>
                <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ color: 'background.dark' }}
                />
                <Grid
                    item
                    container
                    minWidth="12%"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                    sx={filterContainerStyle}
                >
                    <Select
                        value={filterValues.type}
                        onChange={onFiltersChange}
                        label="Tipo"
                        labelVariant="contained"
                        name="type"
                        variant="standard"
                    >
                        <MenuItem value="">Todos</MenuItem>
                        {disbursementOptions.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ color: 'background.dark' }}
                />
                <Grid
                    item
                    container
                    minWidth="20%"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                    sx={filterContainerStyle}
                >
                    <CurrencyRange
                        values={sliderValue}
                        setValues={onCurrencyRangeChange}
                        label="Monto (Con impuestos)"
                        max={10000000}
                        title="Define un rango del monto de las facturas"
                        name="Monto (Con impuestos)"
                        variant="standard"
                    />
                </Grid>
                <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ color: 'background.dark' }}
                />
                <Grid
                    item
                    container
                    minWidth="20%"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                    sx={filterContainerStyle}
                >
                    <Select
                        value={filterValues.status}
                        onChange={onFiltersChange}
                        name="status"
                        variant="standard"
                        labelVariant="contained"
                        label={
                            <Grid container>
                                <Typography variant="body2" color="text.primary" fontWeight={500}>
                                    Estatus
                                </Typography>
                                <Tooltip
                                    color="paper"
                                    placement="top-start"
                                    title={
                                        <Grid container maxWidth="175px">
                                            <Typography
                                                variant="body2"
                                                color="info.main"
                                                fontWeight={700}
                                                my="2px"
                                            >
                                                Solicitado:{' '}
                                                <Typography
                                                    color="text.primary"
                                                    fontWeight={500}
                                                    component="span"
                                                >
                                                    Has solicitado una dispersión.
                                                </Typography>
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="info.main"
                                                fontWeight={700}
                                                my="2px"
                                            >
                                                Reembolsado:{' '}
                                                <Typography
                                                    color="text.primary"
                                                    fontWeight={500}
                                                    component="span"
                                                >
                                                    El monto de la dispersión ha sido devuelto.
                                                </Typography>
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="success.main"
                                                fontWeight={700}
                                                my="2px"
                                            >
                                                Aprobado:{' '}
                                                <Typography
                                                    color="text.primary"
                                                    fontWeight={500}
                                                    component="span"
                                                >
                                                    Tu solicitud ha sido aprobada.
                                                </Typography>
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="success.main"
                                                fontWeight={700}
                                                my="2px"
                                            >
                                                Ejecutado:{' '}
                                                <Typography
                                                    color="text.primary"
                                                    fontWeight={500}
                                                    component="span"
                                                >
                                                    Tu dispersión fue realizada.
                                                </Typography>
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="error.main"
                                                fontWeight={700}
                                                my="2px"
                                            >
                                                Rechazado:{' '}
                                                <Typography
                                                    color="text.primary"
                                                    fontWeight={500}
                                                    component="span"
                                                >
                                                    Tu dispersión no pudo realizarse.
                                                </Typography>
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="error.main"
                                                fontWeight={700}
                                                my="2px"
                                            >
                                                Cancelado:{' '}
                                                <Typography
                                                    color="text.primary"
                                                    fontWeight={500}
                                                    component="span"
                                                >
                                                    Solicitaste la cancelación de la dispersión.
                                                </Typography>
                                            </Typography>
                                        </Grid>
                                    }
                                >
                                    <IconBtn
                                        color="primary"
                                        sx={{
                                            mt: '-10px',
                                        }}
                                    >
                                        <Info />
                                    </IconBtn>
                                </Tooltip>
                            </Grid>
                        }
                    >
                        <MenuItem value="">Mostrar todos</MenuItem>
                        {Object.entries(statusDic).map(([status, text]) => (
                            <MenuItem key={status} value={status}>
                                {text}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </SearchFilters>

            <Grid item container xs={12} justifyContent="flex-end">
                <Collapse in={alert.open}>
                    <Alert severity={alert.type} sx={{ mb: '15px' }}>
                        {alert.message}
                    </Alert>
                </Collapse>
            </Grid>

            <Grid item container xs={12} justifyContent="center">
                <Table
                    sx={{
                        marginBottom: 6.4,
                    }}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    getTableItem={(disbursement: DisbursementObj, i: number) => (
                        <DisbursementsTableItem
                            key={i}
                            disbursement={disbursement}
                            selectedCompany={selectedCompany.company}
                            contractId={selectedContract?.id}
                        />
                    )}
                    currentItems={disbursements}
                    columns={7}
                    loading={loading}
                    pagination={{
                        count,
                        page,
                        rowsPerPage,
                        onPageChange,
                        onRowsPerPageChange: onChangeRowsPerPage,
                    }}
                    externalFilters={{
                        ...externalFilters,
                        ...{ ordering: asSorting(orderCriteria, order) },
                    }}
                    emptyItemsMessage="No hemos encontrado dispersiones disponibles"
                    onParamsQueryChange={setRequestParams}
                >
                    <TableRow>
                        <TableCell
                            aria-sort={
                                // eslint-disable-next-line no-nested-ternary
                                orderCriteria === 'date'
                                    ? order === 'desc'
                                        ? 'descending'
                                        : 'ascending'
                                    : 'none'
                            }
                        >
                            <TableSortLabel
                                disabled={!disbursements.length}
                                active={orderCriteria === 'date'}
                                data-criteria="date"
                                direction={orderCriteria === 'date' ? order : 'desc'}
                                onClick={onSortDate}
                                IconComponent={
                                    orderCriteria === 'date' ? ArrowDownward : ExpandMore
                                }
                            >
                                <Typography component="span" color="text.primary">
                                    Solicitada
                                </Typography>
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary">Alias / RFC / Tax ID</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary">Monto con impuestos</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary">Tipo</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary">Estatus</Typography>
                        </TableCell>
                        <TableCell
                            aria-sort={
                                // eslint-disable-next-line no-nested-ternary
                                orderCriteria === 'modified'
                                    ? order === 'desc'
                                        ? 'descending'
                                        : 'ascending'
                                    : 'none'
                            }
                        >
                            <TableSortLabel
                                disabled={!disbursements.length}
                                active={orderCriteria === 'modified'}
                                direction={orderCriteria === 'modified' ? order : 'desc'}
                                data-criteria="modified"
                                onClick={onSortDate}
                                IconComponent={
                                    orderCriteria === 'modified' ? ArrowDownward : ExpandMore
                                }
                            >
                                <Typography component="span" color="text.primary">
                                    Actualización
                                </Typography>
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <Typography color="text.primary">Acciones</Typography>
                        </TableCell>
                    </TableRow>
                </Table>
            </Grid>
        </>
    );
};

export default DisbursementsTable;
