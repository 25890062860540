import {
    addDays,
    addWeeks,
    startOfMonth,
    startOfWeek,
    subDays,
    subMonths,
    setDate,
    getDaysInMonth,
} from 'date-fns';
import {
    formatUtc,
    generateMonthsArray,
    mimicDateAtCDMX,
    negateLocalOffset,
    toTimelessISO,
    toTimelessISOUtc,
} from 'utils/dates';
import { DailyPaymentOrdersData } from './detail/components/payments/interfaces';
import { Contract } from './interfaces';

export const getAcctNumberLastDigits = (acctNumber: string) => {
    if (!acctNumber || acctNumber === 'Método') return null;
    return acctNumber?.slice(Math.max(acctNumber.length - 4, 1));
};

// Functions to get month/week boundaries return and manage dates
//  using the local offset, so we first obtain cdmx date
//  and then artificially add the local offet
export const getDateCentralTime = (date: Date = new Date()) => {
    const timelessDate = mimicDateAtCDMX(date);
    timelessDate.setUTCHours(0);
    return negateLocalOffset(timelessDate);
};

export const getWeekDayDate = (
    weekDay: number,
    deltaWeeks?: number,
    referenceDate: Date = new Date(),
) => {
    if (deltaWeeks !== undefined) {
        const date = addWeeks(
            addDays(
                startOfWeek(getDateCentralTime(referenceDate), { weekStartsOn: 1 }),
                weekDay - 1,
            ),
            deltaWeeks,
        );

        return toTimelessISOUtc(date);
    }
    return '';
};

/**
 * @param {number} monthsInThePast - Number of months in the past from current date, 0 is current month while a negative number
 * is a future month. Defaults to `0` if no value provided.
 * @returns {object} - Object with start and end dates of the selected month minus monthsInThePast, the number of months in the past
 * and the dates of the current, last and next week. Dates are returned in 'yyyy-mm-dd' format.
 */
export const getSelectedMonthData = (monthsInThePast: number = 0) => {
    const currentDate = getDateCentralTime(),
        targetMonth = subMonths(currentDate, monthsInThePast),
        monthData = {
            startDate: toTimelessISOUtc(startOfMonth(targetMonth)),
            endDate: toTimelessISOUtc(setDate(targetMonth, getDaysInMonth(targetMonth))),
            monthsInThePast,
            currentWeekDays: [] as string[],
            lastWeekDays: [] as string[],
            nextWeekDays: [] as string[],
        };

    if (monthsInThePast === 0) {
        for (let i = 0; i < 7; i++) {
            monthData.currentWeekDays[i] = getWeekDayDate(i + 1, 0);
            monthData.lastWeekDays[i] = getWeekDayDate(i + 1, -1);
            monthData.nextWeekDays[i] = getWeekDayDate(i + 1, 1);
        }
    }
    return monthData;
};

export const getAdjacentDays = () => {
    const currentDate = mimicDateAtCDMX();
    return [
        formatUtc(subDays(currentDate, 1), 'yyyy-MM-dd'),
        formatUtc(addDays(currentDate, 1), 'yyyy-MM-dd'),
    ];
};

export const sanitize3Days = (
    { startDate, endDate }: Pick<ReturnType<typeof getSelectedMonthData>, 'startDate' | 'endDate'>,
    originalResponse: DailyPaymentOrdersData[],
) => {
    const sanitized3Days = new Array(3).fill(null);

    originalResponse.forEach((item) => {
        if (item.paymentDate === startDate) sanitized3Days[0] = item;
        else if (item.paymentDate === endDate) sanitized3Days[2] = item;
        else if (item.paymentDate > startDate && item.paymentDate < endDate)
            sanitized3Days[1] = item;
    });

    return sanitized3Days;
};

export const sanitizeWeek = (
    weekDates: string[],
    { startDate, endDate }: Pick<ReturnType<typeof getSelectedMonthData>, 'startDate' | 'endDate'>,
    originalResponse: DailyPaymentOrdersData[],
) => {
    const sanitizedWeek = new Array(weekDates.length).fill(null);
    let startSearchAtIndex = 0;

    weekDates.forEach((date, index) => {
        if (date < startDate || date > endDate) {
            sanitizedWeek[index] = false;
            return;
        }
        for (let i = startSearchAtIndex; i < originalResponse.length; i++) {
            if (originalResponse[i].paymentDate === date) {
                sanitizedWeek[index] = originalResponse[i];
                startSearchAtIndex = i + 1;
                break;
            }
        }
    });

    return sanitizedWeek;
};

export const sanitizeMonth = (
    { endDate }: Pick<ReturnType<typeof getSelectedMonthData>, 'endDate'>,
    originalResponse: DailyPaymentOrdersData[],
) => {
    const lastDay = Number(endDate.split('-')[2]),
        sanitizedMonth = new Array(lastDay).fill(null);

    let startSearchAtIndex = 0;

    for (let i = 0; i < lastDay; i++) {
        const day = i + 1;
        for (let j = startSearchAtIndex; j < originalResponse.length; j++) {
            const responseDay = Number(originalResponse[j].paymentDate.split('-')[2]);
            if (day === responseDay) {
                sanitizedMonth[i] = originalResponse[j];
                startSearchAtIndex = j + 1;
                break;
            }
        }
    }

    return sanitizedMonth;
};

// Optional chaining to prevent errors coming from API data
export const canSeePayments = (contract: Contract) => !!contract?.enablePaymentsModule;

/**
 * Generates an array of objects with a `data` prop with a break down of the month number(unitNumber), unitType(month, day, etc...) and year,
 * and a `label` prop with the month name in format MMMM yyyy, starting at today's date one month in the future and finishing at the given
 * string date in format yyyy-mm-dd.
 * @param {string} firstIncomeDate - The first income date of the contract in format yyyy-mm-dd. Defaults to current month if no value is provided.
 * @returns {Array} An array of objects with a `data` and `label` props.
 */
export const getMonthsList = (firstIncomeDate: string) => {
    const startAtDate = new Date(),
        // Some new contracts have no firstIncomeDate, so we fallback to current month
        fallbackFirstIncomeDate = firstIncomeDate || toTimelessISO();

    const [yearString, monthString, dayString] = fallbackFirstIncomeDate.split('-'),
        year = Number(yearString),
        month = Number(monthString),
        day = Number(dayString),
        minYearMonth = `${`${startAtDate.getFullYear()}`.padStart(2, '0')}-${`${
            startAtDate.getMonth() + 1
        }`.padStart(2, `0`)}`;

    // validate date format
    if (
        !/^\d{4}-\d{2}-\d{2}$/.test(fallbackFirstIncomeDate) ||
        Number.isNaN(year) ||
        Number.isNaN(month) ||
        month < 1 ||
        month > 12 ||
        day > 31 ||
        day < 0
    )
        throw new Error('Invalid date format');

    if (`${`${yearString}`.padStart(2, '0')}-${`${monthString}`.padStart(2, '0')}` > minYearMonth)
        throw new Error('The first income date cannot be in future months');

    startAtDate.setMonth(startAtDate.getMonth() + 1);

    return generateMonthsArray({ year, month }, startAtDate);
};

export const statusList = {
    0: 'Exitoso',
    999: 'Reembolsado',
    1: 'Rechazado',
};

export const parseStatus = (id: number) => {
    switch (id) {
        case 0:
            return {
                id: 0,
                status: 'active',
                text: statusList[0],
            };
        case 999:
            return {
                id: 999,
                status: 'refunded',
                text: statusList[999],
            };
        default:
            return {
                id: 1,
                status: 'denied',
                text: statusList[1],
            };
    }
};
