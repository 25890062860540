import { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { DefaultBtn, Typography, InterpunctLoader } from '@fairplay2/ui';
import { ManSittingSmartphoneSvg } from 'assets/SVGs';
import { useSetActiveStep } from 'components/customer-onboarding/context/activeStep-context';
import fairplayAPI from 'utils/api';
import { addGoogleTag } from 'utils/google-tag-manager';
import { getPlatformDisplayName } from 'components/dashboard/data-sources/utils';
import { ConfirmationProps } from './interfaces';

const Confirmation: FC<ConfirmationProps> = ({ onClose, groupKey }) => {
    const setActiveStep = useSetActiveStep(),
        [loading, setLoading] = useState(false),
        theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        { pathname } = useLocation(),
        platform = getPlatformDisplayName(groupKey);

    const onFinishOnboarding = async () => {
        setLoading(true);

        // TODO: Add alert on catch error
        try {
            await fairplayAPI.patch('/v1/users/update', { step: 3 });
            addGoogleTag('onboarding-connectors');
            setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
        } finally {
            setLoading(false);
            onClose();
        }
    };

    return (
        <>
            <Grid container justifyContent="center">
                <Grid item xs={12} sm={10} md={8} textAlign="center">
                    <ManSittingSmartphoneSvg style={{ maxWidth: 260, width: '100%' }} />
                </Grid>

                <Grid item xs={12} sm={10} md={8}>
                    <Typography variant="subtitle1" color="primary.main" mb="20px">
                        ¡Conexión de fuente de datos exitosa!
                    </Typography>

                    <Typography variant="subtitle2" color="text.primary" mb="30px">
                        La fuente de datos:{' '}
                        <Typography component="span" color="primary.dark" fontWeight={700}>
                            {platform}{' '}
                        </Typography>
                        ha sido conectada con éxito. Si tienes otras{' '}
                        {groupKey === 'google-ads' ||
                        groupKey === 'facebook-ads' ||
                        groupKey === 'google-analytics'
                            ? 'cuentas'
                            : 'tiendas'}{' '}
                        {platform} deberás realizar el mismo proceso con cada una.
                    </Typography>
                </Grid>
            </Grid>

            {pathname === '/app/onboarding' ? (
                <Grid item container xs={12} justifyContent={{ xs: 'center', sm: 'flex-end' }}>
                    {loading ? (
                        <InterpunctLoader />
                    ) : (
                        <>
                            <DefaultBtn
                                size="small"
                                variant="outlined"
                                onClick={onClose}
                                sx={{
                                    margin: '8px',
                                }}
                            >
                                Conectar otra fuente
                            </DefaultBtn>
                            <DefaultBtn
                                fullWidth={smDown}
                                size="small"
                                onClick={onFinishOnboarding}
                                sx={{
                                    margin: '8px',
                                }}
                            >
                                Finalizar
                            </DefaultBtn>
                        </>
                    )}
                </Grid>
            ) : (
                <Grid item container xs={12} justifyContent="center">
                    <DefaultBtn
                        fullWidth={smDown}
                        size="small"
                        onClick={onClose}
                        sx={{
                            margin: '8px',
                        }}
                    >
                        Cerrar
                    </DefaultBtn>
                </Grid>
            )}
        </>
    );
};

export default Confirmation;
