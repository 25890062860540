import { FC } from 'react';
import { getConnectorAlias, getPlatformDisplayName } from '../../utils';
import { ConnectorFormTitleProps } from '../interfaces';

const ConnectorFormTitle: FC<ConnectorFormTitleProps> = ({ groupKey, connector }) => {
    const displayName = getPlatformDisplayName(groupKey);

    return connector ? (
        <>
            {displayName} <strong>{getConnectorAlias(connector)}</strong>
        </>
    ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{displayName}</>
    );
};

export default ConnectorFormTitle;
