import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import img from 'assets/img/connectors/amazon-ico.png';
import fairplayAPI from 'utils/api';
import { toFormData } from 'utils/parsing';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { PlatformItem } from '../interfaces';
import BasePlatformItem from '../components/BasePlatformItem';
import Wizard from '../components/wizard-ecommerce';
import useCodeInterceptor from '../useCodeInterceptor';
import { useDataSources } from '../../context/DataSourcesContext';
import { ConnectorData, OnCodeInterception } from '../../interfaces';

const REDIRECTION_PARAMS = ['spapi_oauth_code', 'selling_partner_id'];

// eslint-disable-next-line react/prop-types
const Amazon: PlatformItem = ({ notify }) => {
    const { baseAPI, dispatch } = useDataSources(),
        [loading, setLoading] = useState(false),
        [showWizard, setShowWizard] = useState<boolean | ConnectorData>(false),
        { pathname } = useLocation(),
        isOnboardingRef = useRef(pathname === '/app/onboarding');

    const onConnect = () => {
        setShowWizard(true);
    };

    const onReauth = (connector: ConnectorData) => {
        setShowWizard(connector);
    };

    const onCodeInterception: OnCodeInterception = (
        { spapi_oauth_code, selling_partner_id },
        { connector, extra: { countryCode } = {} },
    ) => {
        // Redirection just began and react triggered a re-render that
        //  caused the hook to be executed
        if (loading) return false;

        setLoading(true);
        const data = toFormData({
                platform: 'amazon',
                code: spapi_oauth_code,
                selling_partner_id,
                country_code: countryCode || null,
                onboarding: isOnboardingRef?.current || null,
            }),
            endpoint = `${baseAPI}${connector ? `/${connector}/reconnect` : ''}`;

        (async () => {
            try {
                const res: any = await fairplayAPI.post(endpoint, data);
                dispatch({
                    type: connector ? 'UPDATE' : 'ADD',
                    payload: res?.data?.body?.results,
                    onCommit: () => {
                        if (connector) notify('Se ha reconectado Amazon con éxito', 'success');
                        else setShowWizard(res?.data?.body?.results);
                    },
                });
            } catch (errResponse: ErrorObj | any) {
                let error: string = '';
                if (errResponse.status !== 500) {
                    // Show single error from handleError or append form errors if needed
                    if (errResponse.type === 'form') {
                        // eslint-disable-next-line no-restricted-syntax
                        for (const value of Object.values(errResponse.error)) {
                            if (Array.isArray(value) && value.length > 0) error += `${value[0]}\n`;
                        }
                    } else if (errResponse.error) {
                        error = errResponse.error;
                    }
                }
                notify(
                    error ||
                        `Ocurrió un error al generar la conexión con Amazon, intente más tarde`,
                    'error',
                );
            } finally {
                setLoading(false);
            }
        })();

        return true;
    };

    useCodeInterceptor('amazon', REDIRECTION_PARAMS, onCodeInterception);

    return (
        <BasePlatformItem
            picture={img}
            groupKey="amazon"
            showLoader={loading}
            notify={notify}
            onConnect={onConnect}
            onReauth={onReauth}
        >
            <Wizard
                open={!!showWizard}
                groupKey="amazon"
                element={typeof showWizard === 'boolean' ? null : showWizard}
                onClose={() => setShowWizard(false)}
            />
        </BasePlatformItem>
    );
};

export default Amazon;
