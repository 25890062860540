import { FC, useEffect, useState } from 'react';
import { Modal, Typography } from '@fairplay2/ui';
import { getConnectorAlias } from 'components/dashboard/data-sources/utils';
import { HybridS3WizardModalProps } from '../../interfaces';
import ManualUploadWizard from './ManualUploadWizard';
import PlatformPicker from './PlatformPicker';
import S3Disclaimer from './S3Disclaimer';
import S3Wizard from './S3Wizard';

export const MODAL_TITLE_DIC = {
    s3: 'Conecta tu s3 Bucket AWS',
    'manual-upload': 'Crea un conector de carga manual',
};

const HybridWizardModal: FC<HybridS3WizardModalProps> = ({
    open,
    onClose,
    platform,
    setPlatform,
    element,
}) => {
    const [confirmS3Instructions, setConfirmS3Instructions] = useState(false),
        [awaitingOperation, setAwaitingOperation] = useState(false),
        skipS3Instructions = confirmS3Instructions || !!element;

    useEffect(() => {
        if (!open) return;
        setConfirmS3Instructions(false);
    }, [open]);

    return (
        <Modal
            disableBackdropClick
            open={open}
            onClose={onClose}
            disableEscapeKeyDown
            closeIconButton={!awaitingOperation}
            maxWidth={753}
            title={
                // eslint-disable-next-line no-nested-ternary
                element ? (
                    <>
                        Reconecta tu Bucket{' '}
                        <Typography component="span" color="inherit" fontWeight={700}>
                            {getConnectorAlias(element)}
                        </Typography>
                    </>
                ) : platform === 's3' && !skipS3Instructions ? undefined : (
                    MODAL_TITLE_DIC[platform as 's3'] || 'S3 Bucket / Carga manual'
                )
            }
            sx={{
                '& .MuiDialogTitle-root':
                    (platform === 's3' && skipS3Instructions) || platform === 'manual-upload'
                        ? { paddingBottom: 0, paddingTop: '20px' }
                        : {},
                '.MuiDialogContent-root': {
                    pt: platform === 's3' && !skipS3Instructions ? undefined : 0,
                },
            }}
        >
            {platform === undefined && <PlatformPicker onClose={onClose} onConfirm={setPlatform} />}
            {platform === 's3' && !skipS3Instructions && (
                <S3Disclaimer onClose={onClose} onConfirm={() => setConfirmS3Instructions(true)} />
            )}
            {platform === 's3' && skipS3Instructions && (
                <S3Wizard
                    setAwaitingOperation={setAwaitingOperation}
                    awaitingOperation={awaitingOperation}
                    onClose={onClose}
                    element={element as null}
                />
            )}
            {platform === 'manual-upload' && (
                <ManualUploadWizard
                    onClose={onClose}
                    awaitingOperation={awaitingOperation}
                    setAwaitingOperation={setAwaitingOperation}
                />
            )}
        </Modal>
    );
};

export default HybridWizardModal;
