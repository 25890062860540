import { FC, useEffect, useReducer, useContext } from 'react';
import { Grid } from '@mui/material';
import { Typography } from '@fairplay2/ui';
import sessionContext from 'context/session/sessionContext';
import { RequestsDictionary } from '../../interfaces';
import ChartWithFallback from '../../components/ChartWithFallback';
import { ClvCacInitialState, clvCacReducer } from '../clvcacReducer';
import { Card } from '../../components/Card';
import { TRADING_WARNING_C } from '../../utils/constants';
import { fetchData } from '../../utils/fetchData';
import { ClvCacAction } from '../interfaces';

const REQUESTS_DIC: RequestsDictionary<ClvCacAction['type']> = {
    newClients: {
        actionType: 'SET_NEW_CLIENTS',
        getUrl: (uuid) => `/v1/reports/cac/new_client/${uuid}`,
        errorValue: { warningC: TRADING_WARNING_C.insufficientData, options: [], isEmpty: true },
    },
    dailyCAC: {
        actionType: 'SET_DAILY_CAC',
        getUrl: (uuid) => `/v1/reports/cac/daily/${uuid}`,
        errorValue: { warningC: TRADING_WARNING_C.insufficientData, options: [], isEmpty: true },
    },
    promClient: {
        actionType: 'SET_PROM_CLIENT',
        getUrl: (uuid) => `/v1/reports/clv/avg_user/${uuid}`,
        errorValue: { warningC: TRADING_WARNING_C.insufficientData, options: [], isEmpty: true },
    },
    clv: {
        actionType: 'SET_CLV',
        getUrl: (uuid) => `/v1/reports/clv/${uuid}`,
        errorValue: { item: { warningC: TRADING_WARNING_C.insufficientData }, isEmpty: true },
    },
};

const ClvCac: FC = () => {
    const { selectedCompany } = useContext(sessionContext),
        [
            {
                chartData: { newClients, dailyCAC, promClient },
                clv,
            },
            dispatch,
        ] = useReducer(clvCacReducer, ClvCacInitialState);

    useEffect(() => {
        if (!selectedCompany.company?.id) return;
        dispatch({ type: 'RESTART_LOADING' });
        fetchData<ClvCacAction['type']>({
            companyId: selectedCompany.company.id,
            dispatch,
            requestsDic: REQUESTS_DIC,
        });
    }, [selectedCompany.company?.id]);

    return (
        <Grid container spacing={4} my="5px">
            <Grid item xs={12}>
                <Typography variant="h5" color="primary.dark" fontWeight={700} gutterBottom>
                    Costo de adquisición por cliente
                </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
                <ChartWithFallback
                    options={newClients.options}
                    title={newClients.title}
                    isEmpty={newClients.isEmpty}
                    loading={newClients.loading}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <ChartWithFallback
                    options={dailyCAC.options}
                    title={dailyCAC.title}
                    isEmpty={dailyCAC.isEmpty}
                    loading={dailyCAC.loading}
                />
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h5" color="primary.dark" fontWeight={700} gutterBottom>
                    Valor del tiempo de vida del cliente
                </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
                <Card item={clv.item} title={clv.title} symbol={clv.symbol} loading={clv.loading} />
            </Grid>

            <Grid item xs={12} md={6}>
                <ChartWithFallback
                    options={promClient.options}
                    title={promClient.title}
                    isEmpty={promClient.isEmpty}
                    loading={promClient.loading}
                />
            </Grid>
        </Grid>
    );
};

export default ClvCac;
