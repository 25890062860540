import { ChangeEvent, FC, useCallback, useContext, useMemo, useState } from 'react';
import { Collapse, Grid, useMediaQuery, useTheme } from '@mui/material';
import { CreditCard } from '@mui/icons-material';
import {
    Modal,
    DefaultBtn,
    Typography,
    InterpunctLoader,
    Alert,
    TabGroup,
    TabData,
} from '@fairplay2/ui';
import sessionContext from 'context/session/sessionContext';
import { useAlert } from 'utils/hooks';
import fairplayAPI from 'utils/api';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { AlertType } from 'utils/interfaces';
import { CardApproval, CardApprovalsModalProps } from '../interfaces';
import { ALERT_MESSAGES } from '../constants';
import ApprovalContents from './ApprovalContents';

export const CardApprovalsModal: FC<CardApprovalsModalProps> = ({
    open,
    onClose,
    cardApprovals,
}) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        customMQuery = useMediaQuery('(max-width:436px)'),
        { user } = useContext(sessionContext),
        { alert, hideAlert, showAlert } = useAlert(),
        [loading, setLoading] = useState(false),
        [activeTab, setActiveTab] = useState(0),
        [confirmedApproval, setConfirmedApproval] = useState(false),
        tabsData = useMemo<TabData[]>(
            () =>
                cardApprovals.map((approval, i) => ({
                    label: `${i + 1}. Solicitud`,
                    icon: <CreditCard />,
                    panelId: approval.id,
                    disabled: activeTab !== i,
                })),
            [cardApprovals, activeTab],
        ),
        isUpdateCredit = cardApprovals[activeTab]?.requested_type === 'update-credit-limit',
        isCreateCard = cardApprovals[activeTab]?.requested_type === 'create-card',
        isCancelCard = cardApprovals[activeTab]?.requested_type === 'cancel-card';

    const onTabChange = useCallback((_: ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    }, []);

    const onActionSelection = async (
        newStatus: 'approved' | 'declined',
        cardApproval: CardApproval,
    ) => {
        hideAlert();
        setLoading(true);
        try {
            await fairplayAPI.put(
                `/cards/v1/card/update-request`,
                {
                    card_request_id: cardApproval.id,
                    approve: newStatus === 'approved',
                },
                {
                    baseService: 'CARDS',
                },
            );

            setConfirmedApproval(true);

            const alertType = ALERT_MESSAGES[newStatus]?.alertType || '',
                alertMessage = ALERT_MESSAGES[newStatus]?.[cardApproval.requested_type] || '';

            if (alertType && alertMessage) showAlert(alertMessage, alertType as AlertType, false);
        } catch (errResponse: ErrorObj | any) {
            showAlert(
                errResponse?.error ||
                    `No se pudo ${
                        newStatus === 'approved' ? 'aprobar' : 'declinar'
                    } la solicitud, por favor vuelve a intentarlo.`,
            );
        } finally {
            setLoading(false);
        }
    };

    const onStepChange = () => {
        hideAlert();

        if (Array.isArray(tabsData) && activeTab + 1 < tabsData.length) {
            setActiveTab((prev) => prev + 1);
        } else if (Array.isArray(tabsData)) return onClose();

        setConfirmedApproval(false);
    };

    return (
        <Modal
            aria-labelledby="user requests-quantity"
            disableBackdropClick
            disableEscapeKeyDown
            closeIconButton={false}
            open={open}
            onClose={onClose}
            maxWidth={720}
        >
            <Grid container flexDirection="column">
                <Typography variant="subtitle1" color="primary.main" fontWeight={500} id="user">
                    ¡Hola{' '}
                    <Typography component="span" color="primary.dark" fontWeight={700}>
                        {user?.firstName} {user?.lastName}
                    </Typography>
                    !
                </Typography>
                <Typography
                    variant="subtitle1"
                    color="primary.main"
                    fontWeight={500}
                    id="requests-quantity"
                >
                    Tienes{' '}
                    <Typography component="span" color="primary.dark" fontWeight={700}>
                        {tabsData.length}{' '}
                    </Typography>
                    solicitudes por resolver.
                </Typography>
                <Grid item xs={12} mt="10px">
                    <TabGroup
                        value={activeTab}
                        onChange={onTabChange}
                        tabs={tabsData}
                        tabDirection="column"
                        width="600px"
                        maxWidth="100%"
                        minHeight="230px"
                        TabsProps={{
                            variant: cardApprovals.length > 4 ? 'scrollable' : 'standard',
                            centered: cardApprovals.length < 4,
                        }}
                        sx={{
                            '.MuiTabs-root': {
                                width: '100%',
                            },
                        }}
                    >
                        {cardApprovals.map((approval) => (
                            <ApprovalContents key={approval.id} approval={approval} />
                        ))}
                    </TabGroup>
                </Grid>

                <Grid item container xs={12} justifyContent="flex-start">
                    <Collapse in={alert.open}>
                        <Alert severity={alert.type} sx={{ mb: '30px' }}>
                            {alert.message}
                        </Alert>
                    </Collapse>
                </Grid>

                <Grid
                    container
                    justifyContent="center"
                    rowSpacing="30"
                    columnSpacing="25px"
                    flexDirection={{ xs: 'column', sm: 'row' }}
                >
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {loading ? (
                        <Grid item container justifyContent="center" mt="25px">
                            <InterpunctLoader />
                        </Grid>
                    ) : confirmedApproval ? (
                        <Grid item>
                            <DefaultBtn size="small" fullWidth={smDown} onClick={onStepChange}>
                                Entendido
                            </DefaultBtn>
                        </Grid>
                    ) : (
                        <>
                            <Grid item>
                                <DefaultBtn
                                    variant="outlined"
                                    size="small"
                                    fullWidth={smDown}
                                    onClick={() =>
                                        onActionSelection('declined', cardApprovals[activeTab])
                                    }
                                >
                                    Declinar solicitud
                                </DefaultBtn>
                            </Grid>
                            <Grid item>
                                <DefaultBtn
                                    size="small"
                                    fullWidth={smDown}
                                    onClick={() =>
                                        onActionSelection('approved', cardApprovals[activeTab])
                                    }
                                    sx={{
                                        height:
                                            isUpdateCredit && customMQuery
                                                ? 'auto !important'
                                                : '38px',
                                        lineHeight:
                                            isUpdateCredit && customMQuery ? '1.30' : '1,75',
                                    }}
                                >
                                    {/* eslint-disable-next-line no-nested-ternary */}
                                    {cardApprovals.length && isCreateCard
                                        ? 'Aprobar tarjeta'
                                        : isUpdateCredit
                                        ? 'Aprobar nuevo límite de crédito'
                                        : isCancelCard && 'Aprobar cancelación'}
                                </DefaultBtn>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </Modal>
    );
};

export default CardApprovalsModal;
