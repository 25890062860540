import { FC, useContext, useEffect, useState } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { DefaultBtn, InterpunctLoader, Modal, Typography } from '@fairplay2/ui';
import SessionContext from 'context/session/sessionContext';
import { GeneralErrorSvg } from 'assets/SVGs';

const TEXT_DIC: Record<'fatal' | 'general', { msg: string; btn: string }> = {
    fatal: {
        msg: 'Hubo un problema al cargar la información y debemos cerrar tu sesión. Por favor, vuelve a intentarlo más tarde.',
        btn: 'Entendido',
    },
    general: {
        msg: 'Ocurrió un problema al cargar la información. Es necesario recargar la página para solucionar este problema.',
        btn: 'Sí, recargar la página',
    },
};

const GeneralErrorsModal: FC = () => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        { logout, errors, selectedCompany } = useContext(SessionContext),
        [isOpen, setIsOpen] = useState(false),
        [isLoading, setIsLoading] = useState(false),
        { fatal, general } = TEXT_DIC;

    const onClose = () => {
        if (errors.isFatal) return logout();
        setIsOpen(false);
    };

    const onErrorResolutionClick = () => {
        setIsLoading(true);
        if (errors.isFatal) return logout();
        window.location.reload();
    };

    // TODO: Check if error.messages triggers side effects
    useEffect(() => {
        setIsOpen(Object.values(errors.messages).some((error) => !!error));
    }, [selectedCompany.company?.id, errors.messages]);

    return (
        <Modal
            aria-labelledby="general-error"
            open={isOpen}
            disableBackdropClick
            disableEscapeKeyDown
            onClose={onClose}
            closeIconButton={!isLoading}
            maxWidth={500}
        >
            <Grid container flexDirection="column">
                <Grid container justifyContent="center" mb="20px">
                    <GeneralErrorSvg width="110px" />
                </Grid>
                <Typography variant="subtitle2" color="primary.main" mb="10px" id="general-error">
                    Se ha producido un error
                </Typography>

                <Typography variant="subtitle2" color="text.primary">
                    {errors.isFatal ? fatal.msg : general.msg}
                </Typography>

                <Grid container justifyContent="center" mt="30px">
                    {isLoading ? (
                        <InterpunctLoader />
                    ) : (
                        <DefaultBtn
                            size="small"
                            onClick={onErrorResolutionClick}
                            fullWidth={smDown}
                        >
                            {errors.isFatal ? fatal.btn : general.btn}
                        </DefaultBtn>
                    )}
                </Grid>
            </Grid>
        </Modal>
    );
};

export default GeneralErrorsModal;
