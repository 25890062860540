import { FC, useState, useEffect, useRef } from 'react';
import {
    ExpandMore,
    ChevronRight,
    CheckBoxOutlineBlankOutlined,
    CheckBox,
} from '@mui/icons-material';
import { TreeView } from '@mui/x-tree-view';
import { Modal, DefaultBtn, Alert, Skeleton, Typography } from '@fairplay2/ui';
import { DefaultTreeItem } from 'common-components';
import fairplayAPI from 'utils/api';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { Collapse } from '@mui/material';
import { useAlert } from 'utils/hooks';
import { IndustriesModalProps, Industry } from '../interfaces';

const MAX_LEVEL = 2;

const extractNodeIdData = (nodeId: string): string[] => nodeId.split(' - ');

export const customExpand = (expandedNodes: string[], value: string, naics22: string): string[] => {
    if (!expandedNodes.length) return [value];

    const parentNodeCode = extractNodeIdData(expandedNodes[0])[3],
        // TODO: Implement parentIndustry logic to ensure node visual relationship & avoid edge case
        // Second OR parameter necessary for 'Comercio al por menor' subindustries naics22 edge case
        isChildNode =
            naics22.includes(parentNodeCode) || (parentNodeCode === '44' && naics22.includes('45'));

    if (!isChildNode) return [value];

    const newExpandedNodes = [...expandedNodes],
        isNodeExpanded = expandedNodes.indexOf(value);

    if (isNodeExpanded !== -1) {
        newExpandedNodes.splice(isNodeExpanded, 1);
        return newExpandedNodes;
    }

    if (expandedNodes.length === MAX_LEVEL) newExpandedNodes.pop();
    newExpandedNodes.push(value);
    return newExpandedNodes;
};

export const getNodeId = (industry: Partial<Industry> | null, forceLeaf?: boolean) =>
    `${industry?.id || ''} - ${!industry?.subIndustries?.length || !!forceLeaf} - ${
        industry?.description
    } - ${industry?.naics22}`;

const IndustriesModal: FC<IndustriesModalProps> = ({
    currentSelection,
    isModalOpen,
    onModalClose,
    onIndustrySelect,
    onConfirm: onConfirmProp,
    source = 'supplier',
    transitionDuration,
}) => {
    const [industries, setIndustries] = useState<Industry[] | undefined>(undefined),
        [expandedNodes, setExpandedNodes] = useState<string[]>([]),
        fetchedIndustriesRef = useRef(false),
        { alert, showAlert } = useAlert();

    const onCancel = () => {
        setExpandedNodes([]);
        onIndustrySelect({}, null);
        onModalClose();
    };

    const onConfirm = () => {
        onModalClose();
        onConfirmProp?.();
    };

    const onNodeSelect = (event: object, value: string) => {
        const [id, isLeaf, description, naics22] = extractNodeIdData(value),
            newSelection = {
                id,
                description,
                naics22,
            };

        if (isLeaf !== 'true') {
            setExpandedNodes((prevExpandedNodes) =>
                customExpand(prevExpandedNodes, value, naics22),
            );
            return;
        }
        if (newSelection.id === currentSelection?.id || !newSelection.id) return;

        onIndustrySelect(event, newSelection);
    };

    const fetchIndustries = async () => {
        try {
            const res: any = await fairplayAPI.get('/v1/industries/');
            setIndustries(res.data);
            fetchedIndustriesRef.current = true;
            if (!res.data.length) showAlert('No se encontraron industrias.', 'info', false);
        } catch (errResponse: ErrorObj | any) {
            setIndustries([]);
            showAlert(
                errResponse?.error || 'Ocurrió un error con el servidor, intente más tarde',
                'error',
                false,
            );
        }
    };

    const renderTree = (industry: Industry, level = 0) => (
        <DefaultTreeItem
            key={industry.id}
            nodeId={getNodeId(industry, level === MAX_LEVEL)}
            label={industry.description}
            icon={
                // eslint-disable-next-line no-nested-ternary
                level === MAX_LEVEL || !industry.subIndustries?.length ? (
                    currentSelection?.id === industry.id ? (
                        <CheckBox />
                    ) : (
                        <CheckBoxOutlineBlankOutlined />
                    )
                ) : null
            }
            // For testing purposes, MUI does not attach the event listener
            //  to the treeitem itself, but to the first child node
            ContentProps={{ role: 'button' }}
        >
            {Array.isArray(industry.subIndustries) && level < MAX_LEVEL
                ? industry.subIndustries.map((subIndustry) => renderTree(subIndustry, level + 1))
                : null}
        </DefaultTreeItem>
    );

    useEffect(() => {
        if (isModalOpen && !fetchedIndustriesRef.current) fetchIndustries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalOpen]);

    return (
        <Modal
            transitionDuration={transitionDuration}
            open={isModalOpen}
            onClose={onCancel}
            disableBackdropClick
            maxWidth={710}
            actions={
                <DefaultBtn
                    size="small"
                    onClick={onConfirm}
                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                    disabled={!currentSelection}
                >
                    Confirmar
                </DefaultBtn>
            }
            title={`Por favor selecciona la industria que mejor describa la actividad de tu ${
                source === 'supplier' ? 'proveedor' : 'empresa'
            }`}
            sx={{
                '& .MuiDialog-paper': {
                    padding: {
                        xs: '18px',
                        sm: '48px 18px 20px',
                    },
                },
                '& .MuiDialogContent-root': {
                    padding: '3px 20px 0',
                    mb: 2,
                },
                '& .MuiDialogTitle-root': {
                    p: '16px 20px 0',
                },
            }}
        >
            <Typography color="primary.dark" variant="subtitle1" mb="15px">
                {source === 'supplier'
                    ? 'Si tiene más de una, elige la que representa su negocio principal. '
                    : 'Si tienes más de una, elige la que representa tu negocio principal.'}
            </Typography>
            {/* eslint-disable-next-line no-nested-ternary */}
            {!industries ? (
                Array(6)
                    .fill(0)
                    .map((_, i) => (
                        <Skeleton
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                            height={29}
                            variant="text"
                            sx={{ transform: 'scale(1, 0.90)', margin: '5px 0' }}
                        />
                    ))
            ) : industries.length ? (
                <TreeView
                    selected={getNodeId(currentSelection)}
                    onNodeSelect={onNodeSelect}
                    defaultCollapseIcon={<ExpandMore />}
                    defaultExpandIcon={<ChevronRight />}
                    aria-label="Industrias"
                    expanded={expandedNodes}
                >
                    {industries?.map((industry) => renderTree(industry))}
                </TreeView>
            ) : (
                // 'info' severity should only appear in development environment, it
                //  means that backend returned a valid response, but the array is empty
                <Collapse in={alert.open}>
                    <Alert severity={alert.type}>{alert.message}</Alert>
                </Collapse>
            )}
        </Modal>
    );
};

export default IndustriesModal;
