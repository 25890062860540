import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import img from 'assets/img/connectors/WooCommerce.png';
import fairplayAPI from 'utils/api';
import { toFormData } from 'utils/parsing';
import { PlatformItem } from '../interfaces';
import BasePlatformItem from '../components/BasePlatformItem';
import useCodeInterceptor from '../useCodeInterceptor';
import { useDataSources } from '../../context/DataSourcesContext';
import { ConnectorData, OnCodeInterception } from '../../interfaces';
import { dataSourceRedirection } from '../../utils';
import ConnectorForm from './ConnectorForm';

const REDIRECTION_PARAMS = ['success'];

// eslint-disable-next-line react/prop-types
const Woocommerce: PlatformItem = ({ notify }) => {
    const { baseAPI, dispatch } = useDataSources(),
        [showForm, setShowForm] = useState(false),
        {
            push,
            location: { pathname },
        } = useHistory(),
        isOnboardingRef = useRef(pathname === '/app/onboarding');

    const onCodeInterception: OnCodeInterception = ({ success }) => {
        if (success && isOnboardingRef.current) push('/app/sources');
        dispatch({ type: 'FOCUS', payload: 'woocommerce' });
        return true;
    };

    const onReauth = async (connector: ConnectorData) => {
        const data = toFormData({
            platform: 'woocommerce-v2',
            storeUrl: connector.storeUrl,
        });

        const res: any = await fairplayAPI.post(`${baseAPI}/${connector.id}/reconnect`, data);
        dataSourceRedirection.create(res.data.body.results.redirectUrl, {
            from: 'woocommerce',
            connector: connector.id,
        });

        return res;
    };

    useCodeInterceptor('woocommerce', REDIRECTION_PARAMS, onCodeInterception);

    return (
        <BasePlatformItem
            picture={img}
            groupKey="woocommerce"
            notify={notify}
            onConnect={() => setShowForm(true)}
            onReauth={onReauth}
        >
            <ConnectorForm open={showForm} onClose={() => setShowForm(false)} />
        </BasePlatformItem>
    );
};

export default Woocommerce;
