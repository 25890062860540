import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { Alert, Grid, TableCell, TableRow } from '@mui/material';
import { EditableInput, Typography } from '@fairplay2/ui';
import { useAlert } from 'utils/hooks';
import { AlertProps } from 'utils/interfaces';
import { getTotalByConnectorValue } from 'components/dashboard/analytics/utils/utils';
import { ERROR_STATUS } from 'components/dashboard/data-sources/utils';
import SessionContext from 'context/session/sessionContext';
import { validateIsNotEmpty } from 'utils/validation';
import fairplayAPI from 'utils/api';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { TotalsByConnectorTableItemProps } from './interfaces';

const renderDisplayValue = (alert: AlertProps, tabValue: string, summaries: any, key: string) =>
    alert.open && alert.type !== 'success'
        ? ''
        : getTotalByConnectorValue(tabValue, summaries, key);

const TotalsByConnectorTableItem: FC<TotalsByConnectorTableItemProps> = ({
    alias,
    fss,
    id,
    onAliasEdited,
    showAliasAlert,
    status,
    summaries,
    tabValue,
}) => {
    const { selectedCompany } = useContext(SessionContext),
        { alert, showAlert, hideAlert } = useAlert(),
        [prevAlias, setPrevAlias] = useState(alias),
        [loading, setLoading] = useState(false),
        [aliasValue, setAliasValue] = useState(alias),
        [error, setError] = useState<string | undefined>();

    // TODO: Unify alias edition logic in shared hook with ViewConnections modal
    const onCancelAliasEdition = () => {
        setAliasValue(prevAlias);
        setError(undefined);
    };

    const onAliasChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setAliasValue(value);
        setError(validateIsNotEmpty(value, 'El alias no puede estar vacío'));
    };

    const onAliasEdit = async () => {
        setLoading(true);
        try {
            // TODO: Use useDataSources to extract BASE_API
            await fairplayAPI.patch(
                `v2/companies/${selectedCompany.company?.id}/connectors/${id}`,
                {
                    alias: aliasValue,
                    alias_edited_by_user: true,
                },
            );

            setPrevAlias(aliasValue);
            showAliasAlert('Alias de la tienda actualizado exitosamente', 'success');
            // FIXME: Alias is not getting updated in data sources context
            onAliasEdited?.();
        } catch (errResponse: ErrorObj | any) {
            onCancelAliasEdition();
            const { error: newError, type } = errResponse;
            if (type === 'form') showAliasAlert(newError.alias[0]);
            else showAliasAlert(typeof newError === 'string' ? newError : newError[0]);
        } finally {
            setLoading(false);
        }
    };

    // TODO: Refactor or extract logic to avoid useEffect and prevent double renders. Alerts are static once data is received
    useEffect(() => {
        hideAlert();
        const areAllValuesNull = Object.values(summaries).every((obj) =>
            Object.values(obj).every((value) => value === null),
        );

        if (ERROR_STATUS.includes(status)) return showAlert('Error de conexión', 'error', false);

        if (status === 'pending' || fss === 'no-orders-status' || fss === 'collecting-data')
            return showAlert('Procesando datos', 'info', false);

        if (fss === 'inactive-connector')
            return showAlert('No hay datos suficientes', 'warning', false);

        if (areAllValuesNull) return showAlert('No hay datos registrados', 'warning', false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, fss, showAlert, alert.open]);

    return (
        <TableRow>
            <TableCell sx={{ width: '200px' }}>
                <Grid container minWidth="180px" maxWidth="300px">
                    <EditableInput
                        value={aliasValue}
                        loading={loading}
                        placeholder="Alias de la tienda"
                        type="text"
                        size="small"
                        error={error}
                        confirmButtonLabel="Confirmar nuevo alias"
                        editButtonLabel="Editar alias"
                        disableConfirmButton={aliasValue === prevAlias}
                        onConfirm={onAliasEdit}
                        onChange={onAliasChange}
                        onCancel={onCancelAliasEdition}
                    />
                </Grid>
            </TableCell>
            <TableCell sx={alert.open ? { width: '1px', whiteSpace: 'nowrap' } : undefined}>
                <Grid container alignItems="center" justifyContent="end" flexWrap="nowrap">
                    {alert.open && (
                        <Alert
                            severity={alert.type}
                            sx={{ width: 'fit-content' }}
                            role="presentation"
                        >
                            {alert.message}
                        </Alert>
                    )}
                    <Typography
                        color="primary.dark"
                        fontWeight={500}
                        textAlign="right"
                        ml={alert.open ? 0 : 2}
                    >
                        {renderDisplayValue(alert, tabValue, summaries, 'validTotal')}
                    </Typography>
                </Grid>
            </TableCell>
            <TableCell>
                <Typography color="primary.dark" fontWeight={500} textAlign="right">
                    {renderDisplayValue(alert, tabValue, summaries, 'invalidTotal')}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography color="primary.dark" fontWeight={500} textAlign="right">
                    {renderDisplayValue(alert, tabValue, summaries, 'returnedTotal')}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography color="primary.dark" fontWeight={500} textAlign="right">
                    {renderDisplayValue(alert, tabValue, summaries, 'total')}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

export default TotalsByConnectorTableItem;
