import { FC, useState, useEffect, useContext } from 'react';
import { Visibility, FileCopy } from '@mui/icons-material';
import SessionContext from 'context/session/sessionContext';
import fairplayAPI from 'utils/api';
import { Grid, TableRow, TableCell } from '@mui/material';
import { Typography, DefaultInput, Tooltip, IconBtn, Skeleton } from '@fairplay2/ui';
import { Table } from 'common-components';
import { InvoicesProps } from '../interfaces';

const openInvoice = (url: string) => {
    if (url) window.open(url, '_blank'); // open invoice in a new window
};

const Invoices: FC<InvoicesProps> = ({ vendorId }) => {
    const { selectedCompany } = useContext(SessionContext),
        [firstPurchaseYear, setFirstPurchaseYear] = useState(''),
        [invoicesUrls, setInvoicesUrls] = useState<string[]>([]),
        [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!selectedCompany.company?.id || !vendorId) return;

        const fetchInvoices = async () => {
            const res: any = await fairplayAPI.get(
                `/v1/companies/${selectedCompany.company?.id}/suppliers/${vendorId}/invoices`,
            );
            const invoices = res.data.body.results[0];

            if (res.data.body.results.length > 0) {
                const newInvoicesUrls = [];

                // eslint-disable-next-line no-restricted-syntax
                for (const key in invoices) {
                    if (key.includes('invoice') && invoices[key]) {
                        newInvoicesUrls.push(invoices[key] as string);
                    }
                }
                setFirstPurchaseYear(invoices.firstPurchaseYear);
                setInvoicesUrls(newInvoicesUrls);
            }
            setLoading(false);
        };

        fetchInvoices();
    }, [vendorId, selectedCompany.company?.id]);

    return (
        <>
            <Grid container item xs={12} alignItems="center" spacing={4} sx={{ mt: 1.25 }}>
                <Grid item>
                    <Typography
                        component="label"
                        variant="body1"
                        color="text.primary"
                        mr="20px"
                        htmlFor="first-purchase-year"
                    >
                        Año de primera compra:
                    </Typography>
                </Grid>
                <Grid item>
                    {loading ? (
                        <Skeleton
                            variant="rectangular"
                            height={50}
                            width={230}
                            data-testid="vendor-invoices-skeleton"
                        />
                    ) : (
                        <DefaultInput
                            id="first-purchase-year"
                            fullWidth={false}
                            disabled
                            value={firstPurchaseYear}
                        />
                    )}
                </Grid>
            </Grid>
            <Table
                // eslint-disable-next-line react/no-unstable-nested-components
                getTableItem={(url: any, i: number) => (
                    <TableRow key={url}>
                        <TableCell>
                            <Grid container alignItems="center">
                                <FileCopy color="secondary" />
                                <Typography
                                    variant="body1"
                                    color="primay.main"
                                    fontWeight={700}
                                    ml="10px"
                                >{`Factura #${i + 1}`}</Typography>
                            </Grid>
                        </TableCell>
                        <TableCell align="center">
                            <Tooltip title="Ver documento" placement="right-start" distance={0}>
                                <IconBtn
                                    variant="outlined"
                                    onClick={() => openInvoice(url)}
                                    color="secondary"
                                >
                                    <Visibility />
                                </IconBtn>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                )}
                currentItems={invoicesUrls}
                loading={loading}
                columns={2}
                skeletonRows={2}
                emptyItemsMessage="No hemos encontrado facturas disponibles"
                sx={{ maxWidth: 600, boxShadow: 'none' }}
            >
                <TableRow>
                    <TableCell sx={{ px: 0 }}>
                        <Typography variant="body1" color="text.primary">
                            Nombre de la factura
                        </Typography>
                    </TableCell>
                    <TableCell align="center">
                        <Typography variant="body1" color="text.primary">
                            Acciones
                        </Typography>
                    </TableCell>
                </TableRow>
            </Table>
        </>
    );
};

export default Invoices;
