import CryptoJS from 'crypto-js';
import { format } from 'utils/dates';
import { isValidParamValue } from 'utils/handleParams';

/**
 * Generates a string that can be used as a message in hash functions.
 * It excludes values whith an unreliable or meaningless string representation.
 * To achieve this, it relies on {@link isValidParamValue} as url queries
 * have the same limitations. File objects are an exception, and are included
 * using their name and lastModified fields.
 *
 * @param values Values to consider in the generated string.
 * @returns Message to hash.
 */
export const asIdempotencyToken = (values: any[]): string =>
    values
        .reduce((prev, current) => {
            if (current instanceof File) {
                return prev + current.name;
            }
            if (isValidParamValue(current)) return prev + current;
            return prev;
        }, '')
        .concat(format(undefined, 'd-M-yyyy-H:mm'))
        .replace(/\s/g, '');

/**
 * Generates a hash from a FormData or a plain object.
 *
 * @param data Dataset to use as a reference to generate the hash.
 * @returns The hash.
 */
export const getIdempotency = (data: FormData | { [key: string]: any }): CryptoJS.lib.WordArray => {
    const values = data instanceof FormData ? data.values() : Object.values(data);
    return CryptoJS.MD5(asIdempotencyToken([...values]));
};
