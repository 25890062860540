import { FC } from 'react';
import { Collapse, Grid, useMediaQuery, useTheme } from '@mui/material';
import {
    Checkbox,
    Alert,
    DefaultBtn,
    Typography,
    DefaultInput,
    InterpunctLoader,
    NoResults,
} from '@fairplay2/ui';
import { ConfirmProps } from '../../interfaces.v1';

interface WorkingConfirmationProps extends ConfirmProps {
    selectedAccount?: { [key: string]: any };
    supplier: any;
}

const WorkingCapitalDetail: FC<WorkingConfirmationProps> = ({
    selectedAccount = {},
    supplier,
    alert,
    onStepChange,
    loading,
    onConfirmBankAccount,
    bankAccountConfirmation,
}) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Grid container spacing={4}>
                {Object.keys(selectedAccount).length > 0 && (
                    <>
                        <Grid item xs={12} mt="20px">
                            <Typography variant="subtitle1" color="primary.dark" fontWeight={500}>
                                Estos datos bancarios asociados al proveedor{' '}
                                <Typography
                                    component="span"
                                    variant="subtitle1"
                                    color="primary.main"
                                    fontWeight={700}
                                >
                                    {supplier.registeredName}{' '}
                                </Typography>
                                ¿Son correctos?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DefaultInput
                                labelVariant="external"
                                label={selectedAccount.swift ? 'Código SWIFT:' : 'CLABE:'}
                                id="disbursement-bankaccount-confirmation"
                                disabled
                                value={
                                    selectedAccount.swift
                                        ? selectedAccount.swift
                                        : selectedAccount.clabe
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DefaultInput
                                labelVariant="external"
                                label="Banco:"
                                id="disbursement-bank-confirmation"
                                disabled
                                value={selectedAccount.bank || ''}
                            />
                        </Grid>
                        {selectedAccount.swift && (
                            <>
                                <Grid item xs={12} md={6}>
                                    <DefaultInput
                                        labelVariant="external"
                                        label="Número de cuenta:"
                                        id="disbursement-account-confirmation"
                                        disabled
                                        value={selectedAccount.accountNumber || ''}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DefaultInput
                                        labelVariant="external"
                                        label="País del banco:"
                                        id="disbursement-bank-country-confirmation"
                                        disabled
                                        value={selectedAccount.country?.name || ''}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DefaultInput
                                        labelVariant="external"
                                        label="Ciudad del banco:"
                                        id="disbursement-bank-city-confirmation"
                                        disabled
                                        value={selectedAccount.state || ''}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} md={6}>
                            <DefaultInput
                                labelVariant="external"
                                label="Divisa:"
                                id="disbursement-currency-confirmation"
                                disabled
                                value={selectedAccount.accountCurrency?.text || ''}
                            />
                        </Grid>
                        <Grid item xs={12} display="flex">
                            <Checkbox
                                checked={bankAccountConfirmation}
                                id="disbursement-confirmation"
                                onClick={onConfirmBankAccount}
                                name="supplier-info"
                            />
                            <Typography
                                htmlFor="disbursement-confirmation"
                                component="label"
                                variant="label"
                                m="5px"
                            >
                                Confirmo que los datos bancarios asociados al proveedor son
                                correctos
                            </Typography>
                        </Grid>
                        {Object.keys(supplier).length > 0 && !supplier.bankAccounts?.length && (
                            <Grid item xs={12}>
                                <NoResults>
                                    No hemos encontrado cuentas bancarias asociadas
                                </NoResults>
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
            <Grid item container xs={12} justifyContent="flex-start">
                <Collapse in={alert.open}>
                    <Alert severity={alert.type}>{alert.message}</Alert>
                </Collapse>
            </Grid>
            <Grid item container xs={12} justifyContent="flex-end" mt="35px" mb="20px">
                {loading ? (
                    <Grid
                        item
                        container
                        xs={12}
                        justifyContent={{ xs: 'center', sm: 'flex-end' }}
                        data-testid="working-capital-loader"
                    >
                        <InterpunctLoader />
                    </Grid>
                ) : (
                    <>
                        <DefaultBtn
                            variant="outlined"
                            fullWidth={smDown}
                            sx={{
                                mb: { xs: '10px', sm: 0 },
                            }}
                            size="small"
                            onClick={(e) => onStepChange(e, 0)}
                        >
                            Atrás
                        </DefaultBtn>
                        <DefaultBtn
                            fullWidth={smDown}
                            sx={{
                                mb: { xs: '20px', sm: 0 },
                                ml: { xs: 0, sm: '20px' },
                            }}
                            size="small"
                            disabled={!bankAccountConfirmation}
                            type="submit"
                        >
                            Solicitar
                        </DefaultBtn>
                    </>
                )}
            </Grid>
        </>
    );
};

export default WorkingCapitalDetail;
