import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import { WomanCelebratingSvg } from 'assets/SVGs';
import { Modal, DefaultBtn, Typography } from '@fairplay2/ui';
import { SuccessModalPropsV1 } from '../../interfaces.v1';

const SuccessModal: FC<SuccessModalPropsV1> = ({ isModalOpen, reference, onModalClose }) => {
    const history = useHistory();

    const onClick = () => {
        onModalClose('success');
        history.push('/app/disbursements/transactions');
    };

    return (
        <Modal disableBackdropClick open={isModalOpen} onClose={onClick} maxWidth={524}>
            <Grid container>
                <Grid container item xs={12} justifyContent="center">
                    <WomanCelebratingSvg />
                </Grid>
                <Typography variant="subtitle1" color="primary.main" mt="20px">
                    ¡Dispersión solicitada!
                </Typography>
                {reference ? (
                    <Grid item xs={12} marginTop="20px">
                        <Typography color="text.primary" fontWeight={500}>
                            La dispersión con la{' '}
                            <Typography component="span" color="primary.dark" fontWeight={700}>
                                Referencia número {reference.toString()}
                            </Typography>{' '}
                            se ha solicitado con éxito. Puedes verificar su estatus en el listado de
                            dispersiones.
                        </Typography>
                    </Grid>
                ) : (
                    <Grid item xs={12} marginTop="20px">
                        <Typography color="text.primary" fontWeight={500}>
                            Las dispersiones se han solicitado con éxito. <br />
                            Puedes verificar los estatus en el listado de dispersiones.
                        </Typography>
                    </Grid>
                )}
                <Grid container item xs={12} justifyContent="center">
                    <DefaultBtn size="small" onClick={onClick} sx={{ mx: '10px', mt: '20px' }}>
                        Entendido
                    </DefaultBtn>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default SuccessModal;
