export const validateClabe = (clabe: string): string => {
    const isValid = clabe.length === 18 && /^\d+$/.test(clabe);

    if (clabe.length !== 18) {
        return 'Clabe inválida, debe tener 18 digitos';
    }
    if (!isValid) {
        return 'Clabe inválida';
    }

    return '';
};

export const validateEmail = (email: string): string => {
    const re = /\S+@\S+\.\S+/;

    if (re.test(email)) {
        return '';
    }
    return 'Email no válido';
};

export const validateRFC = (rfc: string): string => {
    if (rfc.length === 12 || rfc.length === 13) return '';
    return 'RFC no válido';
};

export const validateSWIFT = (swift: string): string => {
    const isRightLength = swift.length === 8 || swift.length === 11;
    const re = /[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?/i;

    if (re.test(swift) && isRightLength) return '';

    return 'Swift no válido';
};

export const validateUrl = (url: string): string => {
    const re =
        /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

    if (re.test(url)) return '';

    return 'URL no válido, debe incluir  HTTP o HTTPs';
};

export const validateZipCode = (zipCode: string): string => {
    const patt = /^[0-9]{5}$/;

    if (patt.test(zipCode)) return '';

    return 'Código postal inválido';
};

export const validateTel = (phone: string): string => {
    const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    if (phone.match(phoneno)) return '';

    return 'Ingresa un número válido';
};

export const validateIsNotEmpty = (text: string, error: string): string =>
    text.trim().length === 0 ? error : '';

export const validateValueDoesNotExist = (value: any) =>
    value === undefined || value === null || JSON.stringify(value) === '{}';

// TODO create another function to valide only numbers
export const validateOnlyDigits = (value: string) => {
    const regex = /^$|^\d+$/;
    return regex.test(value);
};

export const validateAlphanumeric = (value: string, withSpaces: boolean = false) => {
    const regex = withSpaces ? /^[a-zA-Z0-9\s]*$/ : /^[\w]*$/;
    return regex.test(value);
};

export const isEnglishWords = (value: string) => {
    const regex = /^[a-zA-Z0-9\s]*$/;
    return regex.test(value);
};

export const phoneRules = {
    regex: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
    message: 'Ingresa un número válido',
};

export const validatesFinancingModules = (
    path: any,
    enabledContracts: boolean,
    enabledCards: boolean,
) => {
    const financingModulesPermission = !(
            !enabledContracts &&
            (path.includes('/financing') || path.includes('/disbursements/transactions'))
        ),
        cardsModulePermission = !(!enabledCards && path.includes('/disbursements/cards'));

    return {
        financingModulesPermission,
        cardsModulePermission,
    };
};

export const hasAnyTruthyProperty = (obj: { [key: string]: any }) =>
    Object.values(obj).some((value) => !!value);

export const isNotEmptyError = (amountError?: string) =>
    !!amountError && amountError !== 'Campo requerido';

export const zipCodeRules = {
    regex: /^[0-9]{5}$/,
    message: 'Código postal inválido',
};
