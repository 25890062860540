import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { BaseModal, BaseModalProps } from 'common-components';
import { CartAddSvg } from 'assets/SVGs';

const AddConnectionModal: FC<Omit<BaseModalProps, 'onConfirm'>> = ({ onCancel, open }) => {
    const { push } = useHistory();

    return (
        <BaseModal
            open={open}
            onClose={onCancel}
            onPrimaryAction={() => push('/app/sources')}
            onSecondaryAction={onCancel}
        >
            <BaseModal.Icon>
                <CartAddSvg />
            </BaseModal.Icon>
            <BaseModal.Title>Agregar tienda</BaseModal.Title>
            <BaseModal.Content>
                Para agregar una nueva tienda te enviaremos a la sección{' '}
                <strong>Fuentes de datos</strong> por lo que saldrás del tablero Desempeño
                comercial.
            </BaseModal.Content>
            <BaseModal.Actions secondaryLabel="Cancelar" primaryLabel="Continuar" />
        </BaseModal>
    );
};

export default AddConnectionModal;
