import { FC } from 'react';
import { Grid } from '@mui/material';
import { Typography, ToggleBtn, NationalIcon, InternationalIcon } from '@fairplay2/ui';
import { SelectAccountTypeProps } from '../interfaces';

const SelectAccountType: FC<SelectAccountTypeProps> = ({ accountType, onToggle }) => (
    <Grid container item xs={12} justifyContent="space-evenly">
        <ToggleBtn
            sx={{
                width: { xs: '180px', md: '250px' },
                height: { xs: '180px', md: '220px' },
                mt: '10px',
            }}
            value="national"
            selected={accountType === 'national'}
            onClick={() => onToggle('national')}
        >
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <NationalIcon height="75px" />
                <Typography
                    aria-label="Cuenta bancaria nacional"
                    variant="subtitle1"
                    color="primary.main"
                    mt="10px"
                >
                    Cuenta bancaria
                    <br />
                    nacional
                </Typography>
            </Grid>
        </ToggleBtn>
        <ToggleBtn
            sx={{
                width: { xs: '180px', md: '250px' },
                height: { xs: '180px', md: '220px' },
                mt: '10px',
            }}
            value="international"
            selected={accountType === 'international'}
            onClick={() => onToggle('international')}
        >
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <InternationalIcon height="75px" />
                <Typography
                    aria-label="Cuenta bancaria internacional"
                    variant="subtitle1"
                    color="primary.main"
                    mt="15px"
                >
                    Cuenta bancaria internacional
                </Typography>
            </Grid>
        </ToggleBtn>
    </Grid>
);

export default SelectAccountType;
