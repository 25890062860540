import { useCallback, useEffect, useState } from 'react';
import { Grid, useMediaQuery, useTheme, Fade, Drawer } from '@mui/material';
import { Search, AutoFixHigh, Close } from '@mui/icons-material/';
import { DefaultBtn, IconBtn, Paper, Typography } from '@fairplay2/ui';
import { SearchFiltersProps } from './SearchFilters.types';

export const getActiveFilters = (values: Object) => {
    let count = 0;
    Object.values(values).forEach((value) => {
        if (typeof value === 'number')
            throw new Error('values types must be either string or object');

        if (value) {
            if (typeof value === 'string' && value.trim().length !== 0) count += 1;
            if (
                typeof value === 'object' &&
                Object.values(value).every(
                    (currentValue: any) => currentValue !== undefined && currentValue?.length !== 0,
                )
            )
                count += 1;
        }
    });

    return count;
};

export const SearchFilters = ({
    children,
    onFilterSearch,
    isFilterOff,
    resetFilters,
    width = '100%',
    marginTop = 0,
    marginBottom = 0,
    ariaLabel = 'Filtros búsqueda',
    activeFilters,
    variant = 'contained',
    allowSearch = false,
}: SearchFiltersProps) => {
    const theme = useTheme(),
        mdDown = useMediaQuery(theme.breakpoints.down('md')),
        [drawerIsOpen, setDrawerIsOpen] = useState(false);

    const toggleDrawer = useCallback(() => {
        setDrawerIsOpen((isOpen) => !isOpen);
        if (activeFilters === 0 && drawerIsOpen) resetFilters();
    }, [activeFilters, drawerIsOpen, resetFilters]);

    const onDrawerFilterSearch = () => {
        onFilterSearch();
        setDrawerIsOpen(false);
    };

    useEffect(() => {
        if (!mdDown && drawerIsOpen) toggleDrawer();
    }, [drawerIsOpen, mdDown, toggleDrawer]);

    return (
        <Grid container maxWidth={width} marginTop={marginTop} marginBottom={marginBottom}>
            <Drawer open={drawerIsOpen} anchor="bottom" data-testid="filters-drawer">
                <Grid container padding={2} direction="column" wrap="nowrap">
                    <Grid item alignSelf="flex-end">
                        <IconBtn
                            onClick={toggleDrawer}
                            color="primary"
                            variant="filled"
                            aria-label="Cerrar filtros"
                        >
                            <Close fontSize="small" />
                        </IconBtn>
                    </Grid>
                    <Grid item container direction="column" wrap="nowrap" gap={5} padding={4}>
                        <Grid item container component="form" aria-label={ariaLabel}>
                            <Grid container item xs={12} wrap="nowrap" direction="column">
                                {children}
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            container
                            wrap="nowrap"
                            gap="16px"
                            justifyContent={['space-between', 'flex-end']}
                        >
                            <Grid item>
                                <DefaultBtn
                                    sx={{
                                        '&.MuiButton-root': {
                                            minWidth: '105px',
                                        },
                                    }}
                                    size="small"
                                    variant="outlined"
                                    onClick={resetFilters}
                                >
                                    Limpiar
                                </DefaultBtn>
                            </Grid>
                            <Grid item>
                                <DefaultBtn
                                    sx={{
                                        '&.MuiButton-root': {
                                            minWidth: '105px',
                                        },
                                    }}
                                    size="small"
                                    aria-label="Aplicar filtros"
                                    onClick={onDrawerFilterSearch}
                                    disabled={variant === 'transparent' && !allowSearch}
                                >
                                    Aplicar
                                </DefaultBtn>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Drawer>
            {/* eslint-disable-next-line no-nested-ternary */}
            {mdDown ? (
                <Grid item container direction="row" alignItems="center" gap={2}>
                    <Grid item container xs wrap="nowrap" justifyContent={[undefined, 'flex-end']}>
                        {activeFilters > 0 ? (
                            <DefaultBtn
                                variant="text"
                                onClick={resetFilters}
                                startIcon={<AutoFixHigh />}
                                disabled={isFilterOff}
                            >
                                Borrar filtros
                            </DefaultBtn>
                        ) : (
                            <Typography color="text.primary">Filtrar por</Typography>
                        )}
                    </Grid>
                    <Grid
                        item
                        container
                        xs="auto"
                        spacing={1}
                        justifyContent="flex-end"
                        alignItems="center"
                        wrap="nowrap"
                    >
                        {activeFilters !== 0 && (
                            <Grid item>
                                <Paper variant="elevation" sx={{ bgcolor: 'background.secondary' }}>
                                    <Typography color="secondary.main" padding="4px">
                                        {`(${activeFilters})`}
                                    </Typography>
                                </Paper>
                            </Grid>
                        )}
                        <Grid item>
                            <IconBtn
                                onClick={toggleDrawer}
                                color="secondary"
                                variant={activeFilters === 0 ? 'outlined' : 'filled'}
                                disableRipple={false}
                                aria-label="Abrir filtros"
                            >
                                <Search />
                            </IconBtn>
                        </Grid>
                    </Grid>
                </Grid>
            ) : variant === 'contained' ? (
                <>
                    <Grid container paddingRight={4} justifyContent="flex-end">
                        <Fade in={!isFilterOff}>
                            <DefaultBtn
                                variant="text"
                                onClick={resetFilters}
                                startIcon={<AutoFixHigh />}
                                disabled={isFilterOff}
                                sx={{ textTransform: 'none !important' }}
                            >
                                Borrar filtros
                            </DefaultBtn>
                        </Fade>
                    </Grid>
                    <Grid
                        container
                        border={{ md: `1px ${theme.palette.auxiliary.main} solid` }}
                        borderRadius="40px"
                        height="75px"
                        maxWidth="100vw"
                        component="form"
                        aria-label={ariaLabel}
                    >
                        <Grid
                            container
                            item
                            xs={12}
                            wrap="nowrap"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            {children}
                            <Grid
                                item
                                container
                                minWidth="8%"
                                height="100%"
                                justifyContent="flex-end"
                                alignItems="center"
                                borderRadius="50%"
                            >
                                <IconBtn
                                    onClick={onFilterSearch}
                                    color="secondary"
                                    disabled={isFilterOff}
                                    variant={isFilterOff ? 'none' : 'filled'}
                                    aria-label="Aplicar filtros"
                                    sx={{
                                        width: '73px',
                                        height: '73px',
                                        transition: 'all 0.2s ease-in-out',
                                    }}
                                >
                                    <Search />
                                </IconBtn>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Grid container maxWidth="100vw" component="form" aria-label={ariaLabel}>
                    <Grid
                        container
                        item
                        xs={12}
                        gap={{ md: '10px', lg: '20px' }}
                        wrap="nowrap"
                        justifyContent="flex-start"
                        alignItems="end"
                    >
                        {children}
                        <Grid position="relative">
                            <Grid item position="absolute" top="-90%" left="-110%">
                                <Fade in={!isFilterOff}>
                                    <DefaultBtn
                                        variant="text"
                                        onClick={resetFilters}
                                        startIcon={<AutoFixHigh />}
                                        disabled={isFilterOff}
                                        sx={{ textTransform: 'none !important' }}
                                    >
                                        Borrar filtros
                                    </DefaultBtn>
                                </Fade>
                            </Grid>
                            <DefaultBtn
                                onClick={onFilterSearch}
                                disabled={!allowSearch}
                                aria-label="Aplicar filtros"
                                sx={{
                                    transition: 'all 0.2s ease-in-out',
                                    marginBottom: '4px',
                                }}
                            >
                                Aplicar
                            </DefaultBtn>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};
