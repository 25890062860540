import { useCallback, useContext, useState } from 'react';
import sessionContext from 'context/session/sessionContext';
import fairplayAPI from 'utils/api';
import { toFormData } from 'utils/parsing';
import { captureException } from 'utils/error-handler';

const useNewFeatureModal = () => {
    const { updateUser } = useContext(sessionContext),
        [loading, setLoading] = useState(false),
        [showNewFeature, setShowNewFeature] = useState(false);

    const onConfirmNewFeature = useCallback(async () => {
        setLoading(true);
        try {
            const data = toFormData({
                showNewFeature: false,
            });
            const res: any = await fairplayAPI.patch('/v1/users/update', data);
            updateUser(res.data.body.results);
        } catch (error) {
            captureException(error);
        } finally {
            sessionStorage.setItem('newFeatureShown', 'true');
            setLoading(false);
            setShowNewFeature(false);
        }
    }, [updateUser]);

    return { loading, onConfirmNewFeature, showNewFeature, setShowNewFeature };
};

export default useNewFeatureModal;
