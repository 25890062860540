import { FC } from 'react';
import TableProvider from '../../../state/TableContext';
import { CardParamsProps } from '../../interfaces';
import { DEFAULT_TRANSIT_TRANSACTION_FILTERS } from '../../../constants';
import TransitTransactionsTable from './TransitTransactionsTable';
import TransitTransactionFilters from './TransitTransactionFilters';

export const TransitTransactionsWidget: FC<CardParamsProps> = ({ cardId }) => (
    <TableProvider initialData={DEFAULT_TRANSIT_TRANSACTION_FILTERS}>
        <TransitTransactionFilters />
        <TransitTransactionsTable cardId={cardId} />
    </TableProvider>
);

export default TransitTransactionsWidget;
