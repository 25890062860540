import { ChangeEvent, FC } from 'react';
import { Divider, Grid, useMediaQuery, useTheme } from '@mui/material';
import { DefaultInput } from '@fairplay2/ui';
import { SearchFilters, getActiveFilters } from 'common-components';
import { asSorting } from 'utils/lists';
import { toTimelessISOUtc } from 'utils/dates';
import { CurrencyRange } from '@fairplay2/ui-x-currencies';
import { DatePicker } from '@fairplay2/ui-x-dates';
import { FILTER_CONTAINER_STYLE } from '../../../constants';
import { TransactionFilterObj } from '../../../interfaces';
import { useTableContext } from '../../../state/TableContext';

const TransitTransactionFilters: FC = () => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        {
            filterValues,
            setFilterValues,
            currencyRange,
            isFilterOff,
            setIsFilterOff,
            activeFilters,
            setActiveFilters,
            setExternalFilters,
            orderCriteria,
            order,
            resetFilterValues,
            onCurrencyRangeChange,
        } = useTableContext();

    const onFilterSearch = () => {
        const selectedDay = filterValues.selectedDayRange,
            dateFilters = {
                start_date: selectedDay?.from ? toTimelessISOUtc(selectedDay.from) : '',
                end_date: selectedDay?.to ? toTimelessISOUtc(selectedDay.to) : '',
            };

        setActiveFilters(
            getActiveFilters({
                date: dateFilters,
                merchant_name: filterValues.merchant_name,
                minMaxAmount: {
                    min_amount: currencyRange[0],
                    max_amount: currencyRange[1],
                },
            }),
        );

        setExternalFilters({
            ...dateFilters,
            merchant_name: filterValues.merchant_name,
            min_amount: currencyRange[0],
            max_amount: currencyRange[1],
            ordering: asSorting(orderCriteria, order),
            status: 'pending',
        });
    };

    const onFiltersChange = (event: ChangeEvent<HTMLInputElement>) => {
        const filterName = event.target.name,
            newFilterValue = event.target.value;

        if (isFilterOff) setIsFilterOff(false);

        setFilterValues((prev: TransactionFilterObj) => ({
            ...prev,
            [filterName]: newFilterValue,
        }));
    };

    const onSelectedDay = (range: typeof filterValues['selectedDayRange']) =>
        onFiltersChange({
            target: { name: 'selectedDayRange', value: range },
            // Event interface expects `value` to be a string and needs more fields.
            //  Forcing type allows to reuse onFiltersChange logic
        } as any);

    return (
        <SearchFilters
            isFilterOff={isFilterOff}
            activeFilters={activeFilters}
            onFilterSearch={onFilterSearch}
            resetFilters={resetFilterValues}
            width="100%"
            marginTop={smDown ? '10px' : 0}
        >
            <Grid
                item
                container
                minWidth="30%"
                height="100%"
                textAlign="center"
                alignItems="center"
                sx={FILTER_CONTAINER_STYLE}
            >
                <DatePicker
                    variant="standard"
                    labelVariant="contained"
                    label="Fecha de solicitud"
                    mode="range"
                    placeholder="Desde - Hasta"
                    value={filterValues.selectedDayRange}
                    onChange={onSelectedDay}
                    inputAlignment="start"
                />
            </Grid>
            <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ color: 'background.dark' }}
            />
            <Grid
                item
                container
                minWidth="30%"
                height="100%"
                justifyContent="center"
                alignItems="center"
                sx={FILTER_CONTAINER_STYLE}
            >
                <DefaultInput
                    name="merchant_name"
                    label="Proveedor / Tienda"
                    labelVariant="contained"
                    value={filterValues.merchant_name}
                    onChange={onFiltersChange}
                    placeholder="¿De quién?"
                    variant="standard"
                />
            </Grid>
            <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ color: 'background.dark' }}
            />
            <Grid
                item
                container
                minWidth="30%"
                height="100%"
                justifyContent="center"
                alignItems="center"
                sx={FILTER_CONTAINER_STYLE}
            >
                <CurrencyRange
                    values={currencyRange}
                    setValues={onCurrencyRangeChange}
                    label="Monto transacciones"
                    max={10000000}
                    title="Define un rango del monto de las transacciones"
                    name="transactions_amount"
                    variant="standard"
                />
            </Grid>
        </SearchFilters>
    );
};

export default TransitTransactionFilters;
