import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, MenuItem, capitalize } from '@mui/material';
import { SearchInput, Typography, Divider, Loader, ProfileData, useDashboard } from '@fairplay2/ui';
import fairplayAPI from 'utils/api';
import sessionContext from 'context/session/sessionContext';
import { StyledMenuList } from '../styles';
import { NoCompaniesFound } from './NoCompaniesFound';

export const CompanyPicker: FC = () => {
    const { companies, totalCompanies, selectedCompany, updateCompany } =
            useContext(sessionContext),
        { setPopoverAnchor } = useDashboard(),
        { replace } = useHistory(),
        [loading, setLoading] = useState(false),
        [companiesList, setCompaniesList] = useState<any[]>([]),
        [searchValue, setSearchValue] = useState('');

    const onSearchChange = (event: ChangeEvent<HTMLInputElement>) =>
        setSearchValue(event.target.value);

    const onCompanyPick = () => {
        setSearchValue('');
        setPopoverAnchor(null);
    };

    const onChangeCompany = async (company: any) => {
        if (!selectedCompany.company) return;
        if (company.company.id !== selectedCompany.company?.id) {
            await updateCompany(company);

            replace('/app/sources');
            onCompanyPick();
        }
    };

    useEffect(() => {
        let searchTimeout: ReturnType<typeof setTimeout>;
        if (searchValue && searchValue.trim().length !== 1) {
            const fetchSearch = async () => {
                setLoading(true);
                const res: any = await fairplayAPI.get(`/v1/companies/search?text=${searchValue}`);
                setLoading(false);
                setCompaniesList(res.data.body.results.slice(0, 30));
            };
            searchTimeout = setTimeout(() => fetchSearch(), 1000);
        } else setCompaniesList(companies.slice(0, 5));

        return () => clearInterval(searchTimeout);
    }, [searchValue, companies]);

    return (
        <>
            <Box p={2} pt={0}>
                <Typography variant="body1" color="text.primary" fontWeight={500} mb="10px">
                    Tienes{' '}
                    <Typography component="span" color="primary.main" fontWeight={700}>
                        {totalCompanies}
                    </Typography>{' '}
                    empresas en lista. <br />
                    Buscar a:
                </Typography>
                <SearchInput
                    label="Nombre de la empresa"
                    fullWidth
                    placeholder="Nombre de la empresa"
                    value={searchValue}
                    onChange={onSearchChange}
                />
            </Box>

            {/* eslint-disable-next-line no-nested-ternary */}
            {loading ? (
                <Box p={2}>
                    <Loader />
                </Box>
            ) : selectedCompany.company?.id && companiesList.length > 0 ? (
                <StyledMenuList>
                    {companiesList.map((companyObj) => (
                        <MenuItem
                            key={companyObj.company.id}
                            disabled={companyObj.company.id === selectedCompany.company?.id}
                            onClick={() => onChangeCompany(companyObj)}
                            sx={{
                                py: '11px',
                            }}
                        >
                            <ProfileData
                                size="sm"
                                overrideAvatarSize={50}
                                fallbackTo="company"
                                src={companyObj.company.avatar || ''}
                                name={
                                    companyObj.company.businessName ||
                                    companyObj.company.registeredName ||
                                    ''
                                }
                                avatarAlt="logo"
                                legend={capitalize(companyObj.role || '')}
                                nameEllipsis
                            />
                            <Divider sx={{ mt: '15px', color: 'background.dark' }} />
                        </MenuItem>
                    ))}
                </StyledMenuList>
            ) : (
                <NoCompaniesFound />
            )}
        </>
    );
};

export default CompanyPicker;
