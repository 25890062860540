import { FC } from 'react';
import { Typography } from '@fairplay2/ui';
import { Grid } from '@mui/material';
import { QRCodeCanvas } from 'qrcode.react';
import { InvoiceDetailProps } from '../../interfaces';

const CFDIdetail: FC<InvoiceDetailProps> = ({ invoiceValues }) => (
    <Grid container data-testid="cfdi-container">
        <Grid item xs={12} md={7} marginTop={5}>
            <Typography variant="body2" color="text.primary" fontWeight={500}>
                Sello digital del CFDI
            </Typography>
            <Typography
                variant="subtitle2"
                color="primary.main"
                fontWeight={500}
                sx={{ wordBreak: 'break-all' }}
            >
                {invoiceValues?.stampCfd}
            </Typography>
            <Typography variant="body2" color="text.primary" fontWeight={500} mt="10px">
                Sello del SAT
            </Typography>
            <Typography
                variant="subtitle2"
                color="primary.main"
                fontWeight={500}
                sx={{ wordBreak: 'break-all' }}
            >
                {invoiceValues?.stampSat}
            </Typography>
            <Typography variant="body2" color="text.primary" fontWeight={500} mt="10px">
                Número de serie del certificado del SAT
            </Typography>
            <Typography
                variant="subtitle2"
                color="primary.main"
                fontWeight={500}
                sx={{ wordBreak: 'break-all' }}
            >
                {invoiceValues?.satCertificateNumber}
            </Typography>
            <Typography variant="body2" color="text.primary" fontWeight={500} mt="10px">
                Fecha y hora de certificación
            </Typography>
            <Typography variant="subtitle2" color="primary.main" fontWeight={500}>
                {invoiceValues?.stampDate}
            </Typography>
        </Grid>
        <Grid
            container
            item
            xs={12}
            md={5}
            marginTop={5}
            justifyContent={{ xs: 'center', md: 'flex-end' }}
        >
            {invoiceValues?.qrInvoiceSat && (
                <QRCodeCanvas size={230} fgColor="#303879" value={invoiceValues?.qrInvoiceSat} />
            )}
        </Grid>
    </Grid>
);

export default CFDIdetail;
