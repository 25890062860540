import { FC, useState, useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { Grid, LinearProgress, CircularProgress } from '@mui/material';
import { Modal, Typography, Alert } from '@fairplay2/ui';
import { useBlockReload } from 'utils/hooks';
import { LoadingModalProps } from '../../interfaces';

const LoadingModal: FC<LoadingModalProps> = ({ isModalOpen }) => {
    const [progress, setProgress] = useState(0);

    useBlockReload();

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 95 ? prevProgress : prevProgress + 2));
        }, 100);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <>
            <Prompt
                when
                message="¡Atención! Salir de la página ahora podría causar errores en el pago de las dispersiones. ¿Estás seguro?"
            />
            <Modal
                open={isModalOpen}
                closeIconButton={false}
                disableBackdropClick
                sx={{
                    '.MuiDialog-paper': { pt: 0 },
                    '.MuiDialogContent-root': { overflowX: 'hidden' },
                }}
            >
                <Grid container justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1" color="primary.main" my={3}>
                        Procesando las dispersiones....
                    </Typography>
                    <CircularProgress size={30} />
                </Grid>
                <LinearProgress
                    value={progress}
                    variant="determinate"
                    sx={{
                        height: 10,
                        borderRadius: 5,
                    }}
                />
                <Typography variant="body2" color="text.primary" align="right">{`${Math.round(
                    progress,
                )}%`}</Typography>
                <Alert severity="warning" sx={{ mt: 2 }}>
                    <Typography color="text.primary" fontWeight={500}>
                        Por favor espera mientras se procesa tu solicitud. <br />
                        No actualices la página ni des clic para regresar.
                        <br />
                        Esto puede tomar unos minutos.
                    </Typography>
                </Alert>
            </Modal>
        </>
    );
};

export default LoadingModal;
