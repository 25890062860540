import { FC, useState, ChangeEvent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import { Typography, Divider, IconBtn, Tooltip, Select, DefaultBtn } from '@fairplay2/ui';
import { formatAmount } from 'utils/parsing';
import { Event } from '@mui/icons-material';
import { MONTHS } from 'utils/dates';
import { capitalize } from 'utils/formatting';
import { getAcctNumberLastDigits } from '../../utils';
import { MonthlyDirectDebitProps } from '../../interfaces';

const MonthlyDirectDebit: FC<MonthlyDirectDebitProps> = ({
    contractId,
    totalMonthCharges,
    clientAccountNumber,
    receiverBank,
}) => {
    const [selectedMonth, setSelectedMonth] = useState<any>(''),
        [monthOptions, setMonthOptions] = useState<any>([]),
        history = useHistory(),
        mdUp = useMediaQuery(useTheme().breakpoints.up('md')),
        // At least one direct debit is found
        hasDirectDebits = !!clientAccountNumber;

    const onSelect = (event: ChangeEvent<HTMLInputElement>) => setSelectedMonth(event.target.value);

    const onOpenFullCalendar = () => {
        sessionStorage.setItem('openPayments', 'calendar');
        history.push(`/app/financing/contract/detail/${contractId}`);
    };

    const goToDetail = () => {
        sessionStorage.setItem('openPayments', 'default');
        history.push(`/app/financing/contract/detail/${contractId}`);
    };

    useEffect(() => {
        if (totalMonthCharges?.length) {
            setSelectedMonth(totalMonthCharges[0]);
            setMonthOptions(totalMonthCharges);
        }
    }, [totalMonthCharges]);

    return (
        <>
            <Grid
                item
                container
                minWidth="225px"
                maxWidth={{ xs: '100%', md: '270px' }}
                mt={{ xs: '35px', md: 0 }}
                minHeight="200px"
                alignItems="flex-end"
                data-testid="monthly-direct-debit"
            >
                {hasDirectDebits && (
                    <Grid item xs={9}>
                        <Grid item width="fit-content" marginTop="-50px" paddingTop="6px">
                            <Select
                                labelVariant="contained"
                                label="Pagado en"
                                value={selectedMonth}
                                fullWidth
                                onChange={onSelect}
                                variant="standard"
                            >
                                <MenuItem value="" disabled>
                                    Seleccionar
                                </MenuItem>
                                {monthOptions.map((charges: any, index: any) => (
                                    // TODO: Validate interface to avoid using index
                                    // eslint-disable-next-line react/no-array-index-key
                                    <MenuItem value={charges} key={index} sx={{ pr: '20px' }}>
                                        {`${capitalize(MONTHS[Number(charges?.month) - 1])} ${
                                            charges?.year
                                        }`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                )}
                {mdUp && (
                    <Grid
                        item
                        container
                        justifyContent="flex-end"
                        xs={3}
                        marginLeft="auto"
                        mt={hasDirectDebits ? undefined : '3px'}
                    >
                        <Grid item>
                            <Tooltip title="Ver calendario" placement="top">
                                <IconBtn
                                    color="secondary"
                                    variant="outlined"
                                    aria-label="Ver calendario"
                                    onClick={onOpenFullCalendar}
                                >
                                    <Event />
                                </IconBtn>
                            </Tooltip>
                        </Grid>
                    </Grid>
                )}

                {hasDirectDebits ? (
                    <>
                        <Grid item xs={4}>
                            <Typography variant="subtitle1" color="primary.main">
                                MXN$
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="subtitle1" color="secondary.main">
                                {formatAmount(selectedMonth?.total)}
                            </Typography>
                        </Grid>

                        <Grid item height="fit-content" xs={12}>
                            <Divider
                                variant="fullWidth"
                                sx={{
                                    color: 'background.dark',
                                    borderBottomWidth: 'inherit',
                                    mt: '1px',
                                    height: '4px',
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} marginTop="10px" marginBottom={2}>
                            <Typography variant="body1" color="text.primary" mr="20px">
                                Cuenta débito asociada
                            </Typography>
                        </Grid>

                        <Grid item xs={7}>
                            <Typography variant="subtitle1" color="primary.main">
                                {receiverBank}
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="subtitle1" color="secondary.main">
                                *{getAcctNumberLastDigits(clientAccountNumber)}
                            </Typography>
                        </Grid>

                        <Grid item height="fit-content" xs={12}>
                            <Divider
                                variant="fullWidth"
                                sx={{
                                    color: 'background.dark',
                                    borderBottomWidth: 'inherit',
                                    mt: '1px',
                                    height: '4px',
                                }}
                            />
                        </Grid>
                    </>
                ) : (
                    <Grid
                        item
                        xs={12}
                        paddingY={3}
                        marginBottom={1}
                        marginTop={2}
                        sx={{ border: '4px solid #4A3FD11A', borderWidth: '4px 0' }}
                    >
                        <Typography color="text.primary">
                            Consulta cómo has ido pagando y próximos pagos de tu financiamiento.
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Grid
                item
                container
                justifyContent={{
                    xs: 'center',
                    md: 'flex-end',
                }}
                marginTop={2}
                xs={12}
            >
                <DefaultBtn size="small" onClick={goToDetail}>
                    Ver todos tus pagos
                </DefaultBtn>
            </Grid>
        </>
    );
};

export default MonthlyDirectDebit;
