import { useEffect, useRef } from 'react';

export const useBlockReload = (initiallyBlocked: boolean = true) => {
    // Using refs instead of stateful data to avoid
    //  adding and removing event listeners all the time
    const currentlyBlocked = useRef(initiallyBlocked);

    const { current: changeBlockedStatus } = useRef((newStatus: boolean) => {
        currentlyBlocked.current = newStatus;
    });

    useEffect(() => {
        const listener = (e: BeforeUnloadEvent) => {
            if (!currentlyBlocked.current) return;
            e.preventDefault();
            e.returnValue = '';
        };

        window.addEventListener('beforeunload', listener);

        return () => {
            window.removeEventListener('beforeunload', listener);
        };
    }, []);

    return { changeBlockedStatus };
};
