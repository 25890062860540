import { FC, useContext, useState, useEffect, useRef, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { Collapse, Grid, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import sessionContext from 'context/session/sessionContext';
import { Select, Typography, DefaultBtn, LabelledIconBtn, Skeleton, Alert } from '@fairplay2/ui';
import { Close } from '@mui/icons-material';
import fairplayAPI from 'utils/api';
import { contractPrintableName } from 'utils/formatting';
import { useAlert } from 'utils/hooks/useAlert';
import { ContractProps } from '../../interfaces.v1';
import { HydratedContract } from '../../../../../contracts/interfaces';

const InvoiceContract: FC<ContractProps> = ({
    currentContractId = '',
    onStepChange,
    onContractChange,
    onModalOpen,
}) => {
    const { selectedCompany } = useContext(sessionContext),
        theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        isFirstRenderRef = useRef(true),
        [contracts, setContracts] = useState<any[] | undefined>(undefined),
        { alert, hideAlert, showAlert } = useAlert(),
        { push } = useHistory();

    const onSelectContract = (event: ChangeEvent<HTMLInputElement>) => {
        const contract = contracts?.find((item) => item.id === event.target.value);
        hideAlert();
        onContractChange(contract);
        if (!['sales-advancement', 'working-capital'].includes(contract?.productType)) {
            showAlert(
                'No se puede continuar con la dispersión. El contrato seleccionado no tiene tipo definido, favor de contactar al equipo de Customer Success para poder continuar realizando dispersiones a este contrato.',
            );
        }
    };

    useEffect(() => {
        if (!selectedCompany.company?.id) return;
        let isMounted = true;
        if (isFirstRenderRef.current) {
            isFirstRenderRef.current = false;

            (async () => {
                const res: any = await fairplayAPI.get(
                    `/v2/companies/${selectedCompany.company?.id}/contracts?dispersions_allowed=true&page_size=200`,
                );

                if (!isMounted) return;
                const v1Contracts = res.data.body.results.filter(
                    (contract: HydratedContract) => !contract.enableDisbursementsV2Module,
                );
                setContracts(v1Contracts);
            })();
        } else {
            push(`/app/disbursements/transactions`);
        }
        return () => {
            isMounted = false;
        };
    }, [selectedCompany, push]);

    return (
        <Grid container spacing={4}>
            <Grid
                item
                container
                direction={{ xs: 'column-reverse', sm: 'row' }}
                justifyContent="space-between"
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                marginTop="5px"
                wrap="nowrap"
            >
                <Typography variant="h4" color="primary.main" mb="10px">
                    Solicitud de dispersión{' '}
                    <Typography component="span" color="primary.dark" fontWeight={700}>
                        Factura única
                    </Typography>
                </Typography>
                <Grid
                    container={smDown}
                    item
                    justifyContent="flex-end"
                    alignItems="center"
                    mb={{ xs: '5px', sm: 0 }}
                >
                    <LabelledIconBtn
                        variant="filled"
                        color="primary"
                        size="small"
                        label="Cancelar"
                        LabelProps={{
                            fontSize: '14px',
                            margin: '10px',
                            forceBelow: 'sm',
                        }}
                        onClick={() => onModalOpen('cancel')}
                    >
                        <Close />
                    </LabelledIconBtn>
                </Grid>
            </Grid>
            <Grid item xs={12} pt={0}>
                <Typography variant="subtitle2" color="primary.main">
                    Agrega los datos de la transferencia a realizar:
                </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                {!!contracts && (
                    <Select
                        labelVariant="external"
                        label="Contrato"
                        value={currentContractId}
                        onChange={onSelectContract}
                    >
                        <MenuItem value="" selected disabled>
                            Elige un contrato activo
                        </MenuItem>
                        {contracts?.length ? (
                            contracts?.map((contract: any) => (
                                <MenuItem
                                    key={contract.id}
                                    value={contract.id}
                                >{`Contrato ${contractPrintableName(contract)}`}</MenuItem>
                            ))
                        ) : (
                            <MenuItem value="" disabled>
                                No hay contratos activos registrados
                            </MenuItem>
                        )}
                    </Select>
                )}
                {!contracts && (
                    <>
                        <Typography variant="label">Contrato</Typography>
                        <Skeleton height={53} data-testid="contracts-skeleton" />
                    </>
                )}
            </Grid>
            {/* <Grid item xs={12} md={6} lg={4}>
                    <p className={`${classes.label}`}>Saldo disponible del periodo:</p>
                    <DefaultInputField
                        id='total'
                        disabled
                        value="$"
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <p className={`${classes.label}`}>Monto por dispersar:</p>
                    <DefaultInputField
                        id='available'
                        disabled
                        value="$"
                    />
                </Grid> */}
            <Grid item container direction="column">
                <Collapse in={alert.open}>
                    <Grid item md={8} lg={6}>
                        <Alert severity={alert.type}>{alert.message}</Alert>
                    </Grid>
                </Collapse>
                <Collapse in={!currentContractId}>
                    <Grid item container xs={6} mt="50px" textAlign="left">
                        <DefaultBtn
                            aria-hidden={!!currentContractId}
                            variant="outlined"
                            fullWidth={smDown}
                            sx={{
                                mb: { xs: '10px', sm: 0 },
                            }}
                            size="small"
                            onClick={(e) => onStepChange(e, 0)}
                        >
                            Atrás
                        </DefaultBtn>
                    </Grid>
                </Collapse>
            </Grid>
        </Grid>
    );
};

export default InvoiceContract;
