import { FC } from 'react';
import { Box, TableContainer } from '@mui/material';
import { Typography } from '@fairplay2/ui';
import { toTimelessISO } from 'utils/dates';
import DailyPaymentOrdersPaper from './DailyPaymentOrdersPaper';
import { WeeklyPaymentsGridProps } from './interfaces';

const weekDays = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

const WeeklyPaymentsSummary: FC<WeeklyPaymentsGridProps> = ({
    loading,
    'data-testid': testid,
    dailyDataArr,
    dates,
}) => {
    const today = toTimelessISO();

    return (
        <TableContainer data-testid={testid} role="grid">
            <Box role="row" display="grid" gridTemplateColumns="repeat(7, 141px)" columnGap="7px">
                {weekDays.map((weekDay) => (
                    <Typography
                        display="inline-block"
                        padding={0}
                        fontSize={13}
                        color="text.primary"
                        key={weekDay}
                        role="columnheader"
                        width={143}
                        textTransform="uppercase"
                    >
                        {weekDay}
                    </Typography>
                ))}
            </Box>
            <Box
                data-testid={testid ? `${testid}-list` : undefined}
                display="grid"
                role="row"
                gridTemplateColumns="repeat(7, 141px)"
                marginTop="10px"
                columnGap="7px"
            >
                {weekDays.map((_, index) =>
                    dailyDataArr[index] === false && !loading ? (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={index} role="gridcell" />
                    ) : (
                        <DailyPaymentOrdersPaper
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            data={dailyDataArr[index] || null}
                            size="sm"
                            loading={loading}
                            timeType={
                                // eslint-disable-next-line no-nested-ternary
                                dates[index] < today
                                    ? 'past'
                                    : dates[index] > today
                                    ? 'future'
                                    : 'today'
                            }
                            role="gridcell"
                            dateTitle={dates[index]?.split('-')?.[2] || ''}
                        />
                    ),
                )}
            </Box>
        </TableContainer>
    );
};

export default WeeklyPaymentsSummary;
