import { FC, useContext, useState, useEffect, ChangeEvent } from 'react';
import { useTheme, useMediaQuery, Grid } from '@mui/material';
import { DefaultBtn, NoResults } from '@fairplay2/ui';
import SessionContext from 'context/session/sessionContext';
import fairplayAPI from 'utils/api';
import AccountsData from './components/AccountData';
import NewAccountModal from './components/NewAccountModal';
import ManageAccountsModal from './components/ManageAccountsModal';
import DeleteModal from './components/DeleteModal';
import ConfirmModal from './components/ConfirmModal';
import { Account, Modals } from './interfaces';
import AccountsDataSkeleton from './components/AccountDataSkeleton';

const Accounts: FC = () => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        { selectedCompany } = useContext(SessionContext),
        [loading, setLoading] = useState(true),
        [accounts, setAccounts] = useState<Account[]>([]),
        [selectedAccount, setSelectedAccount] = useState<number | null>(null),
        [toDeleteAccount, setToDeleteAccount] = useState<Account | null>(null),
        [modalOpen, setModalOpen] = useState<Modals>({
            newAccount: false,
            manageAccounts: false,
            delete: false,
            confirm: false,
        });

    const fetchAccounts = async () => {
        setLoading(true);
        const res: any = await fairplayAPI.get(
            `/v1/companies/${selectedCompany.company?.id}/accounts`,
        );
        setAccounts(res.data.body.results);
        setLoading(false);
    };

    const findDefault = () => accounts.findIndex((account) => account.status?.id === 'primary');

    const onOpenModal = (modal: string) => {
        if (modal === 'manageAccounts') setSelectedAccount(findDefault());

        const newModals: Modals = {
            newAccount: false,
            manageAccounts: false,
            delete: false,
            confirm: false,
        };

        newModals[modal] = true;

        setModalOpen(newModals);
    };

    const onCloseModal = (modal: string) => setModalOpen({ ...modalOpen, [modal]: false });

    const onSelectAccount = (_: ChangeEvent<HTMLInputElement>, index: number) =>
        setSelectedAccount(index);

    const onDelete = (account: Account) => {
        setToDeleteAccount(account);
        setModalOpen({ ...modalOpen, manageAccounts: false, delete: true });
    };

    useEffect(() => {
        if (selectedCompany && Object.keys(selectedCompany).length !== 0) fetchAccounts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany]);

    useEffect(() => {
        setSelectedAccount(findDefault());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accounts]);

    return (
        <>
            <Grid container spacing={4} mt={0}>
                {loading ? (
                    <AccountsDataSkeleton />
                ) : (
                    accounts.map((account, i) => (
                        <AccountsData key={account.id} account={account} index={i} />
                    ))
                )}
                {accounts.length === 0 && !loading && (
                    <NoResults>No hay cuentas bancarias registradas</NoResults>
                )}

                <Grid container item justifyContent="flex-end">
                    {accounts.length > 0 && (
                        <DefaultBtn
                            fullWidth={smDown}
                            size="small"
                            variant="outlined"
                            onClick={() => onOpenModal('manageAccounts')}
                            sx={{
                                mr: { xs: 0, sm: '10px' },
                            }}
                        >
                            Administrar cuentas
                        </DefaultBtn>
                    )}
                    <DefaultBtn
                        fullWidth={smDown}
                        sx={{
                            mt: { xs: '20px', sm: 0 },
                        }}
                        size="small"
                        onClick={() => onOpenModal('newAccount')}
                    >
                        Agregar cuenta
                    </DefaultBtn>
                </Grid>
            </Grid>
            {modalOpen.newAccount && (
                <NewAccountModal
                    open={modalOpen.newAccount}
                    onClose={onCloseModal}
                    fetchAccounts={fetchAccounts}
                />
            )}
            {modalOpen.manageAccounts && (
                <ManageAccountsModal
                    open={modalOpen.manageAccounts}
                    onClose={onCloseModal}
                    selectedAccount={selectedAccount}
                    accounts={accounts}
                    onSelectAccount={onSelectAccount}
                    onDelete={onDelete}
                    fetchAccounts={fetchAccounts}
                />
            )}
            {modalOpen.delete && (
                <DeleteModal
                    open={modalOpen.delete}
                    account={toDeleteAccount}
                    onClose={onCloseModal}
                    fetchAccounts={fetchAccounts}
                    onOpenModal={onOpenModal}
                />
            )}
            {modalOpen.confirm && (
                <ConfirmModal
                    open={modalOpen.confirm}
                    onClose={onCloseModal}
                    account={toDeleteAccount}
                />
            )}
        </>
    );
};

export default Accounts;
