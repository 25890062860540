import fairplayAPI from 'utils/api';
import { ErrorObj } from 'utils/error-handler';
import { AlertType } from 'utils/interfaces';
import { sanitizeMonth, sanitizeWeek } from '../../../utils';
import { DirectDebitsTransaction } from '../../../interfaces';
import { ContractGeneralDebt, WeekType, WeeklyPaymentsGridProps } from './interfaces';

export const fetchContractGeneralDebt = async (
    contractId: string | undefined,
): Promise<ContractGeneralDebt> => {
    const response: any = await fairplayAPI.get(`/payment-order/api/v1/general_due/${contractId}`, {
        baseService: 'PAYMENT_ORDERS',
    });

    return response?.data?.results;
};

const fetchGroupedPaymentOrders = async (
    contractId: string | undefined,
    type: WeekType | 'month',
    monthLimits: { startDate: string; endDate: string },
    dates?: string[],
): Promise<WeeklyPaymentsGridProps['dailyDataArr']> => {
    const startDate =
            type === 'month' ? monthLimits.startDate : dates?.[0] || monthLimits.startDate,
        endDate = type === 'month' ? monthLimits.endDate : dates?.[6] || startDate;

    const res: any = await fairplayAPI.get(
            `/payment-order/api/v1/payment_orders/${contractId}?start_date=${startDate}&end_date=${endDate}&page=1&page_size=40`,
            { baseService: 'PAYMENT_ORDERS' },
        ),
        results = res.data.results || [];

    return type === 'month'
        ? sanitizeMonth(monthLimits, results)
        : sanitizeWeek(dates as string[], monthLimits, results);
};

export const buildQuery = (
    contractId: string | undefined,
    type: WeekType | 'month',
    monthLimits: { startDate: string; endDate: string },
    showOrdersAlert: (msg: string, type?: AlertType, autoHide?: boolean) => void,
    dates?: string[],
) => ({
    queryKey: ['groupedPaymentOrders', contractId, type, monthLimits, dates],
    queryFn: () => fetchGroupedPaymentOrders(contractId, type, monthLimits, dates),
    enabled: !(type !== 'month' && !dates?.length),
    staleTime: 180000,
    cacheTime: 180000,
    onError: (errResponse: ErrorObj | any) => {
        showOrdersAlert(
            errResponse?.error ||
                `No se pudieron cargar las órdenes de pago de${
                    // eslint-disable-next-line no-nested-ternary
                    type === 'current'
                        ? ' esta semana'
                        : // eslint-disable-next-line no-nested-ternary
                        type === 'last'
                        ? ' la semana pasada'
                        : type === 'next'
                        ? ' la siguiente semana'
                        : 'l mes'
                }, intente más tarde`,
        );
    },
});

export const fetchDirectDebits = async (
    selectedCompanyId: string | undefined,
    contractId: string | undefined,
    requestParams: string,
): Promise<{
    count: number;
    directDebits: DirectDebitsTransaction[];
}> => {
    const res: any = await fairplayAPI.get(
            `/v1/companies/${selectedCompanyId}/contracts/${contractId}/direct-debits${requestParams}`,
        ),
        resCount = res.data.body.count,
        resArr = res.data.body.results,
        newCount = resCount || resArr.length;

    return { count: newCount, directDebits: res.data.body.results };
};
