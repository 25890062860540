import { FC, useState, useContext, useEffect, MouseEvent } from 'react';
import { Grid, Collapse, useTheme, useMediaQuery } from '@mui/material';
import { Modal, Alert, InterpunctLoader, DefaultBtn } from '@fairplay2/ui';
import SessionContext from 'context/session/sessionContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import fairplayAPI from 'utils/api';
import { useAlert } from 'utils/hooks';
import { toFormData } from 'utils/parsing';
import { ManageAccountsModalProps, BankAccount, Mode } from '../interfaces';
import AccountsList from './AccountsList';
import DeleteAccount from './DeleteAccount';

const ManageAccountsModal: FC<ManageAccountsModalProps> = ({
    open,
    accounts,
    vendorId,
    vendorName,
    onClose,
    fetchAccounts,
}) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('md')),
        { selectedCompany } = useContext(SessionContext),
        [loading, setLoading] = useState(false),
        { alert, showAlert } = useAlert(),
        [mode, setMode] = useState<Mode>('list'),
        [primaryAccount, setPrimaryAccount] = useState<number | null>(null),
        [toDeleteAccount, setToDeleteAccount] = useState<BankAccount | null>(null);

    const onSaveAccount = async () => {
        if (primaryAccount !== null && vendorId) {
            setLoading(true);
            try {
                await fairplayAPI.patch(
                    `/v1/companies/${selectedCompany.company?.id}/suppliers/${vendorId}/accounts/${accounts[primaryAccount].id}`,
                    toFormData({ status: 'default' }),
                );
                setLoading(false);
                fetchAccounts();
                showAlert('Las cuentas han sido actualizadas exitosamente', 'success');
            } catch (errResponse: ErrorObj | any) {
                setLoading(false);
                if (errResponse.type === 'form') {
                    const errors = Object.values(errResponse.error);
                    showAlert(errors[0] as string);
                } else showAlert(errResponse.error);
            }
        }
    };

    const onDeleteAccount = async () => {
        if (toDeleteAccount !== null && vendorId) {
            setLoading(true);
            try {
                await fairplayAPI.delete(
                    `/v1/companies/${selectedCompany.company?.id}/suppliers/${vendorId}/accounts/${toDeleteAccount.id}`,
                );
                setLoading(false);
                await fetchAccounts();
                setMode('success');
            } catch (errResponse: ErrorObj | any) {
                setLoading(false);
                if (errResponse.type === 'form') {
                    const errors = Object.values(errResponse.error);
                    showAlert(errors[0] as string);
                } else showAlert(errResponse.error);
            }
        }
    };

    const onSelectAccount = (event: MouseEvent<unknown>, index: number) => {
        setPrimaryAccount(index);
    };

    const onDelete = (account: BankAccount) => {
        setToDeleteAccount(account);
        setMode('delete');
    };

    useEffect(() => {
        setPrimaryAccount(accounts.findIndex((account) => account.status?.id === 'primary'));
    }, [accounts]);

    return (
        <Modal open={open} onClose={onClose} maxWidth={mode === 'list' ? 980 : 680}>
            <Grid container>
                {mode === 'list' ? (
                    <AccountsList
                        accounts={accounts}
                        primaryAccount={primaryAccount}
                        onSelectAccount={onSelectAccount}
                        onDelete={onDelete}
                    />
                ) : (
                    <DeleteAccount account={toDeleteAccount} mode={mode} vendorName={vendorName} />
                )}
                <Grid item container xs={12} justifyContent="center" mt="20px">
                    <Collapse in={alert.open}>
                        <Alert severity={alert.type}>{alert.message}</Alert>
                    </Collapse>
                </Grid>

                <Grid
                    item
                    container
                    xs={12}
                    justifyContent={mode !== 'list' ? 'center' : 'flex-end'}
                    mt="10px"
                >
                    {loading ? (
                        <InterpunctLoader />
                    ) : (
                        <>
                            {mode !== 'success' && (
                                <DefaultBtn
                                    size="small"
                                    variant="outlined"
                                    fullWidth={smDown}
                                    onClick={() => (mode === 'list' ? onClose() : setMode('list'))}
                                    sx={{
                                        mt: '10px',
                                    }}
                                >
                                    {/*    eslint-disable-next-line no-nested-ternary */}
                                    {mode === 'list'
                                        ? 'Cancelar'
                                        : mode === 'delete'
                                        ? 'Ok, ¡no!'
                                        : ''}
                                </DefaultBtn>
                            )}
                            <DefaultBtn
                                size="small"
                                sx={{
                                    ml: { xs: 0, sm: '20px' },
                                    mt: '10px',
                                }}
                                fullWidth={smDown}
                                onClick={() =>
                                    // eslint-disable-next-line no-nested-ternary
                                    mode === 'list'
                                        ? onSaveAccount()
                                        : mode === 'delete'
                                        ? onDeleteAccount()
                                        : onClose()
                                }
                            >
                                {/* eslint-disable-next-line no-nested-ternary */}
                                {mode === 'list'
                                    ? 'Guardar'
                                    : mode === 'delete'
                                    ? 'Sí, eliminar'
                                    : 'Entendido'}
                            </DefaultBtn>
                        </>
                    )}
                </Grid>
            </Grid>
        </Modal>
    );
};

export default ManageAccountsModal;
