import { Typography } from '@fairplay2/ui';
import { Grid } from '@mui/material';
import { OTP, OTPInputProps } from 'common-components';

export const Step4 = (props: OTPInputProps) => (
    <Grid container gap={2}>
        <Grid item xs={12}>
            <Typography variant="subtitle2" fontWeight={500}>
                Para validar tu aplicación, genera un código de un solo uso e introdúcelo a
                continuación.
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <OTP {...props} />
        </Grid>
    </Grid>
);

export default Step4;
