import { FC, ChangeEvent, useState } from 'react';
import { Grid } from '@mui/material';
import { ShoppingBag, Attribution, Insights, MultilineChart } from '@mui/icons-material';
import { Typography, TabGroup, TabData } from '@fairplay2/ui';
import { addGoogleTag } from 'utils/google-tag-manager';
import { WithGTM } from 'utils/interfaces';
import Sales from './components/Sales';
import Projections from './components/Projections';
import Trends from './components/Trends';
import ClvCac from './components/ClvCac';

const labelsArr: WithGTM<TabData>[] = [
    {
        label: 'Ventas',
        icon: <ShoppingBag />,
        _gtmTag: 'analytics-business-performance-transactions',
    },
    {
        label: 'CAC-CLV',
        icon: <Attribution />,
        _gtmTag: 'analytics-business-performance-cac-clv',
    },
    {
        label: 'Tendencias',
        icon: <Insights />,
        _gtmTag: 'analytics-business-performance-trends',
    },
    {
        label: 'Proyecciones',
        icon: <MultilineChart />,
        _gtmTag: 'analytics-business-performance-projections',
    },
];

const Trading: FC = () => {
    const [step, setStep] = useState(0);

    const onStepChange = (_: ChangeEvent<{}>, newValue: number) => {
        setStep(newValue);
        // eslint-disable-next-line no-underscore-dangle
        addGoogleTag(labelsArr[newValue]._gtmTag);
    };

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Typography variant="h4" color="primary.main" gutterBottom>
                    Analíticos /{' '}
                    <Typography component="span" color="primary.dark" fontWeight={700}>
                        Desempeño comercial
                    </Typography>
                </Typography>
            </Grid>

            <TabGroup item xs={12} tabs={labelsArr} onChange={onStepChange} value={step}>
                <Sales />
                <ClvCac />
                <Trends />
                <Projections />
            </TabGroup>
        </Grid>
    );
};

export default Trading;
