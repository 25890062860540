import { Grid, styled } from '@mui/material';

export const StyledCheckboxListItem = styled(Grid)(({ theme }) => ({
    padding: '10px 15px',
    backgroundColor: theme.palette.background.dark,
    borderBottom: '1px solid white',
    wordBreak: 'break-word',
    '&:last-child': {
        border: 'none',
    },
    '& .right-text': {
        justifyContent: {
            justifyContent: 'start',
            [theme.breakpoints.up('sm')]: {
                justifyContent: 'center',
            },
        },
    },
})) as typeof Grid;
