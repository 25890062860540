import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, styled } from '@mui/material';
import { PriorityHigh } from '@mui/icons-material';
import { Notification, NotificationProps } from '@fairplay2/ui';
import { noop } from 'utils/constants';
import { AlertProps } from 'utils/interfaces';
import { ConnectorData, DataSourcePlatform } from '../../interfaces';
import { useDataSources } from '../../context/DataSourcesContext';
import { BasePlatformItemProps } from '../interfaces';
import Reauthorization from './Reauthorize';
import PlatformCard from './PlatformCard';
import PlatformActions from './PlatformActions';

export const prioritizeNotification = (
    fss: DataSourcePlatform['fss'],
    reauth: DataSourcePlatform['reauth'],
    pathname: string,
): {
    message: string;
    severity: AlertProps['type'];
    type: 'fss' | 'reauth';
} | null => {
    if (reauth?.length)
        return {
            message: reauth.length > 1 ? 'Reautorizaciones requeridas' : 'Reautorización requerida',
            severity: 'error',
            type: 'reauth',
        };
    if (fss?.editable.size && pathname !== '/app/onboarding')
        return {
            message: 'Clasificar estatus de órdenes',
            severity: 'warning',
            type: 'fss',
        };
    return null;
};

const PlatformNotification = styled(Notification)({
    position: 'absolute !important' as 'absolute',
    zIndex: 1,
    minWidth: 'calc(100% + 50px)',
    whiteSpace: 'nowrap',
    left: 0,
    top: -16,
});

const SEVERITY_CONFIGS: Record<string, Partial<NotificationProps>> = {
    error: {
        icon: <PriorityHigh />,
        animation: 'flash',
    },
};

const BasePlatformItem: FC<BasePlatformItemProps> = ({
    groupKey,
    picture,
    notification: notificationOverride,
    children,
    overrideCardTitle,
    ...props
}) => {
    const { platforms, dispatch } = useDataSources(),
        { fss, reauth } = platforms[groupKey] || { fss: null },
        {
            push,
            location: { pathname },
        } = useHistory(),
        { type: defaultNotificationType, ...notificationParts } = prioritizeNotification(
            fss,
            reauth,
            pathname,
        ) || {
            type: null,
        },
        [modalConfig, setModalConfig] = useState<{
            connector: ConnectorData;
            open: boolean;
        }>({
            connector: { id: '', platform: '' as 'amazon' } as ConnectorData,
            open: false,
        });

    const fssOnClick = (e: any) => {
        if (!fss) return;

        push(`sources/status-orders/${e.target.value}`);
    };

    const reauthOnClick = () => {
        if (!reauth) return;

        // TODO: When implemented, add validation of user preference
        //   to not display the dialog again
        if (reauth.length > 1) dispatch({ type: 'FOCUS', payload: groupKey });
        else
            setModalConfig({
                connector: reauth[0],
                open: true,
            });
    };

    const onModalClose = () => setModalConfig((prev) => ({ ...prev, open: false }));

    const defaultNotification: Exclude<typeof notificationOverride, undefined> =
            defaultNotificationType
                ? {
                      onClick: defaultNotificationType === 'fss' ? fssOnClick : reauthOnClick,
                      ...(notificationParts as any),
                  }
                : null,
        notification = props.showLoader ? null : notificationOverride || defaultNotification;
    if (notification) notification.severity = notification.severity || 'warning';

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            position="relative"
            data-testid={`connector-item-${groupKey.replace(/-/g, '')}`}
        >
            {!!notification && (
                <PlatformNotification
                    severity={notification.severity}
                    variant="outlined"
                    buttonValue={groupKey}
                    onClick={notification.onClick}
                    {...(SEVERITY_CONFIGS[notification.severity!] || {})}
                >
                    {notification.message}
                </PlatformNotification>
            )}
            <PlatformCard picture={picture} groupKey={groupKey} title={overrideCardTitle} />

            <PlatformActions platformGroup={groupKey} {...props}>
                {children}
                {defaultNotificationType === 'reauth' && (
                    <Reauthorization
                        connector={modalConfig.connector}
                        onClose={onModalClose}
                        open={modalConfig.open}
                        onReauth={props.onReauth || noop}
                    />
                )}
            </PlatformActions>
        </Grid>
    );
};

export default BasePlatformItem;
