import { FocusEvent, useCallback, useContext, useEffect, useState } from 'react';
import { Collapse, Grid } from '@mui/material';
import { Accordion, HorizontalStepper, QRCodeData } from 'common-components';
import { Typography, Alert } from '@fairplay2/ui';
import { useAlert, useToggle, useOtpStatus } from 'utils/hooks';
import fairplayAPI from 'utils/api';
import SessionContext from 'context/session/sessionContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { UserType } from '../team/interfaces';
import { UsersList } from './lib/UsersList';
import { Success } from './lib/Success';
import { Step1 } from './lib/Step1';
import { Step2 } from './lib/Step2';
import { Step3 } from './lib/Step3';
import { Step4 } from './lib/Step4';
import { UnlinkUserModal } from './lib/UnlinkUserModal';
import { UserOtpStatus } from './lib/UserOtpStatus';

const steps = [
    'Conoce la verificación de 2 pasos',
    'Descarga la app',
    'Vincula tu app',
    'Valida tu app',
];

export const Security = () => {
    const {
            getUser,
            loading: sessionLoading,
            setLoading: setSessionLoading,
            selectedCompany,
            user,
        } = useContext(SessionContext),
        { isUserOtpVerified } = useOtpStatus(),
        [activeStep, setActiveStep] = useState(0),
        [loading, setLoading] = useState(false),
        [otpUsersLoading, setOtpUsersLoading] = useState(false),
        [qrData, setQrData] = useState<QRCodeData>({ altValue: '', value: '' }),
        [qrDataError, setQrDataError] = useState(''),
        [otp, setOtp] = useState(''),
        [otpError, setOtpError] = useState(''),
        [isAccordionOpen, toggleAccordion] = useToggle(!isUserOtpVerified),
        [isUnlinkUserModalOpen, toggleUnlinkUserModal] = useToggle(),
        [activeOtpUsers, setActiveOtpUsers] = useState<UserType[]>([]),
        { alert: verifyOtpAlert, hideAlert: hideOtpAlert, showAlert: showOtpAlert } = useAlert(),
        { alert: activeOtpUsersAlert, showAlert: showActiveOtpUsersAlert } = useAlert();

    const onOtpBlur = (ev: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (otp || ev.target.value) return setOtpError('');
        setOtpError('El código es requerido.');
    };

    const getActiveOtpUsers = useCallback(async () => {
        setOtpUsersLoading(true);
        try {
            const res: any = await fairplayAPI.get(
                `/v1/companies/${selectedCompany.company?.id}/collaborators`,
            );
            // TODO: use endpoint filters to get only active users when available
            setActiveOtpUsers(
                res.data.body.results.filter((otpUser: UserType) => otpUser?.otpVerified),
            );
        } catch (errResponse: ErrorObj | any) {
            showActiveOtpUsersAlert(
                errResponse?.error || 'Ocurrió un error al obtener los usuarios vinculados.',
            );
        } finally {
            setOtpUsersLoading(false);
        }
    }, [selectedCompany.company?.id, showActiveOtpUsersAlert]);

    const getOtpQr = async () => {
        try {
            // TODO: change to be res results only when `v3/2fa-service/google/otp/generate` is corrected to be a GET request
            const res: any = await fairplayAPI.post('v3/2fa-service/google/otp/generate');
            setQrData({
                // eslint-disable-next-line camelcase
                value: res.data.body.results.otpauth_url,
                altValue: res.data.body.results.base32,
            } as QRCodeData);
        } catch {
            setQrDataError(
                'Ocurrió un error al obtener el código QR, por favor refresca la página.',
            );
        }
    };

    const onStep3Forward = async (token: string) => {
        hideOtpAlert();

        if (otp.length === 0) return setOtpError('El código es requerido.');
        if (otp.length !== 6) return setOtpError('El código debe tener 6 dígitos.');

        setLoading(true);
        try {
            await fairplayAPI.post('v3/2fa-service/google/otp/verify', {
                token,
            });
            setActiveStep(4);
            await getUser();
            await getActiveOtpUsers();
        } catch {
            showOtpAlert('El código es inválido, inténtalo de nuevo.');
        } finally {
            setOtp('');
            setOtpError('');
            setLoading(false);
            setSessionLoading(false);
        }
    };

    const onStepForward = () => {
        if (activeStep === 3) return onStep3Forward(otp);
        if (activeStep === 4) return toggleAccordion();

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const onStepBackward = () => {
        setActiveStep((prevActiveStep) => {
            if (prevActiveStep === 3) {
                setOtp('');
                setOtpError('');
            }
            return prevActiveStep > 0 ? prevActiveStep - 1 : prevActiveStep;
        });
    };

    const onChangeAccordion = () => {
        toggleAccordion();
        if (otp) setOtp('');
        if (otpError) setOtpError('');
    };

    const onConfirmUnlinkUser = async () => {
        try {
            await getActiveOtpUsers();
            await getOtpQr();
            await getUser();
        } finally {
            setSessionLoading(false);
        }
    };

    useEffect(() => {
        getOtpQr();
    }, []);

    useEffect(() => {
        getActiveOtpUsers();
    }, [getActiveOtpUsers]);

    useEffect(() => {
        if (isAccordionOpen) setActiveStep(0);
    }, [isAccordionOpen]);

    return (
        <Grid container gap={4} mb={5}>
            <UnlinkUserModal
                open={isUnlinkUserModalOpen}
                onClose={toggleUnlinkUserModal}
                onConfirmUnlink={onConfirmUnlinkUser}
            />
            <Grid item xs={12}>
                <Accordion
                    title={
                        isUserOtpVerified
                            ? 'Vincula aquí otra aplicación de autenticación.'
                            : 'Sigue estos pasos para vincular tu aplicación de autenticación.'
                    }
                    expanded={isAccordionOpen}
                    onChange={onChangeAccordion}
                >
                    <HorizontalStepper
                        activeStep={activeStep}
                        stepsTitles={steps}
                        loading={loading}
                        onStepForward={onStepForward}
                        onStepBackward={onStepBackward}
                        hasCompletedScreen
                    >
                        <Step1 />
                        <Step2 />
                        <Step3 qrData={qrData} error={qrDataError} />
                        <Grid item>
                            <Step4
                                value={otp}
                                setValue={setOtp}
                                error={otpError}
                                onBlur={onOtpBlur}
                            />
                            <Grid item xs={12} mt="20px">
                                <Collapse in={verifyOtpAlert.open}>
                                    <Alert
                                        aria-label="Mensaje de error de verificación código OTP"
                                        severity={verifyOtpAlert.type}
                                        sx={{ width: 'fit-content' }}
                                    >
                                        {verifyOtpAlert.message}
                                    </Alert>
                                </Collapse>
                            </Grid>
                        </Grid>
                        <Success />
                    </HorizontalStepper>
                </Accordion>
            </Grid>
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <Typography
                        variant="subtitle1"
                        fontWeight={500}
                        color="primary.dark"
                        lineHeight={1.5}
                        mb={1}
                    >
                        Usuario con aplicaciones de autenticación vinculadas
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <UserOtpStatus
                        loading={sessionLoading}
                        disabled={sessionLoading || !isUserOtpVerified}
                        onUnlinkUser={toggleUnlinkUserModal}
                        user={user}
                    />
                </Grid>
            </Grid>
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" fontWeight={500} color="primary.dark">
                        Listado de usuarios vinculados
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="subtitle2"
                        fontWeight={500}
                        color="primary.main"
                        maxWidth={700}
                    >
                        Aquí puedes consultar los usuarios de tu equipo que ya han vinculado una
                        aplicación de autenticación para esta empresa.
                    </Typography>
                </Grid>
                <Grid item container xs={12}>
                    <Collapse
                        in={activeOtpUsersAlert.open}
                        sx={{ width: '100%', maxWidth: '700px' }}
                    >
                        <Alert
                            aria-label="Mensaje de error de listado de usuarios vinculados"
                            severity={activeOtpUsersAlert.type}
                            sx={{ my: 1, width: 'fit-content', ml: 'auto' }}
                        >
                            {activeOtpUsersAlert.message}
                        </Alert>
                    </Collapse>
                </Grid>
                <Grid item xs={12} md={7}>
                    <UsersList activeOtpUsers={activeOtpUsers} loading={otpUsersLoading} />
                </Grid>
            </Grid>
        </Grid>
    );
};
