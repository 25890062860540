import { Typography } from '@fairplay2/ui';
import {
    Accordion as MuiAccordion,
    AccordionSummary,
    AccordionDetails,
    styled,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { AccordionProps } from './Accordion.types';

export const StyledAccordion = styled(MuiAccordion)(({ theme }) => ({
    padding: '13px 33px',
    backgroundColor: theme.palette.background.default,
    borderRadius: '20px !important',
    boxShadow: 'none',
    '& .MuiAccordionSummary-expandIconWrapper': {
        color: 'secondary.main',
    },
    '& .MuiAccordionDetails-root': {
        paddingLeft: '13px',
        paddingRight: '13px',
        marginTop: '16px',
    },
    [theme.breakpoints.down('md')]: {
        padding: 0,
    },
}));

export const Accordion = ({ children, title, ...accordionProps }: AccordionProps) => (
    <StyledAccordion {...accordionProps}>
        <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography color="primary.main" fontWeight={500}>
                {title}
            </Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
);

export default Accordion;
