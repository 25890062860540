import { FC } from 'react';
import { useHistory } from 'react-router';
import { Grid, TableRow, TableCell } from '@mui/material';
import { Download, Visibility } from '@mui/icons-material';
import { Status, StatusType } from 'common-components';
import { Typography, IconBtn, Tooltip } from '@fairplay2/ui';
import { formatAmount } from 'utils/parsing';
import { DisTableItemPropsV1 } from '../../../interfaces';
import { downloadSlip, statusDic } from '../../utils';

const DisbursementsTableItem: FC<DisTableItemPropsV1> = ({ disbursement, selectedCompanyId }) => {
    const history = useHistory();

    return (
        <TableRow>
            <TableCell sx={{ minWidth: 100 }}>
                <Typography fontWeight={700} color="primary.main">
                    {disbursement.date}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontWeight={500} color="primary.dark">
                    {disbursement.supplier?.alias}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontWeight={500} color="primary.dark">
                    {disbursement?.concept}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontWeight={700} color="primary.main">
                    {`${disbursement.currency?.id ? disbursement.currency?.id : ''}
                            ${formatAmount(disbursement?.amount)}`}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontWeight={500} color="primary.dark">
                    {disbursement.type === 'fairplay-card' ? 'Tarjeta Fairplay' : 'Factura única'}
                </Typography>
            </TableCell>
            <TableCell>
                {disbursement.status?.id && (
                    <Status
                        variant="outlined"
                        role="presentation"
                        status={disbursement.status!.id as StatusType}
                    >
                        {statusDic[disbursement.status!.id]}
                    </Status>
                )}
            </TableCell>
            <TableCell align="center">
                <Grid container justifyContent="space-evenly">
                    <Tooltip title="Ver detalle" placement="top" distance={0}>
                        <IconBtn
                            color="secondary"
                            variant="outlined"
                            onClick={() =>
                                history.push(
                                    `/app/disbursements/transactions/detail/${disbursement.contract.id}:${disbursement.id}`,
                                )
                            }
                        >
                            <Visibility />
                        </IconBtn>
                    </Tooltip>
                    <Tooltip title="Descargar comprobante" placement="top" distance={0}>
                        {/*
                            Span necessary to avoid MUI 'Tolltip containing disable button' warning.
                            Manually moving accesible label to button component.
                        */}
                        <span aria-label={undefined}>
                            <IconBtn
                                aria-label="Descargar comprobante"
                                color="secondary"
                                variant="outlined"
                                onClick={() =>
                                    downloadSlip(
                                        selectedCompanyId,
                                        disbursement.contract?.id,
                                        disbursement.id,
                                    )
                                }
                                disabled={
                                    disbursement.status?.id !== 'executed' ||
                                    !disbursement.bankTransferStatementFile
                                }
                            >
                                <Download />
                            </IconBtn>
                        </span>
                    </Tooltip>
                </Grid>
            </TableCell>
        </TableRow>
    );
};

export default DisbursementsTableItem;
