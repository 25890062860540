import { FC } from 'react';
import { Typography, Skeleton } from '@fairplay2/ui';
import { Grid, useMediaQuery, useTheme, Divider } from '@mui/material';
import { ExpensesDetailContainer, InvoiceDetailContainer, Container } from '../../styles';

const FiscalDetailSkeleton: FC = () => {
    const theme = useTheme(),
        mdDown = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid container data-testid="fiscal-detail-skeleton">
            <Container container item xs={12} md={11}>
                <Grid item container xs={12}>
                    <Grid item xs={6} sm={2}>
                        <Skeleton variant="circular" width={90} height={90} />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                            mt: { xs: 2, sm: 0 },
                            order: { xs: 2, sm: 1 },
                            ml: { xs: 0, lg: '-15px' },
                        }}
                    >
                        <Skeleton width="50%" height={24} />
                        <Skeleton width="25%" height={24} sx={{ mt: '5px' }} />
                        <Skeleton width="40%" height={24} sx={{ mt: '5px' }} />
                    </Grid>
                    <Grid item textAlign="right" xs={6} sm={2} sx={{ order: { xs: 1, sm: 2 } }}>
                        <Skeleton width={100} height={24} />
                        <Skeleton width={75} height={24} sx={{ mt: '5px' }} />
                    </Grid>
                </Grid>
                <InvoiceDetailContainer item container xs={12}>
                    <Grid item xs={12} md={6}>
                        <Typography
                            variant="subtitle2"
                            color="primary.contrastText"
                            fontWeight={500}
                        >
                            ID Factura
                        </Typography>
                        <Skeleton width="90%" height={24} sx={{ bgcolor: 'primary.dark' }} />
                        <Grid container alignItems="center" sx={{ my: 3 }}>
                            <Skeleton width={100} height={65} sx={{ bgcolor: 'primary.dark' }} />
                        </Grid>
                        <Typography
                            variant="subtitle2"
                            color="primary.contrastText"
                            fontWeight={500}
                            mt="10px"
                        >
                            Emitida:{' '}
                            <Skeleton width={125} height={24} sx={{ bgcolor: 'primary.dark' }} />
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            color="primary.contrastText"
                            fontWeight={500}
                            mt="10px"
                        >
                            Certificada:{' '}
                            <Skeleton width={125} height={24} sx={{ bgcolor: 'primary.dark' }} />
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            color="primary.contrastText"
                            fontWeight={500}
                            mt="10px"
                        >
                            Cancelada:{' '}
                            <Skeleton width={125} height={24} sx={{ bgcolor: 'primary.dark' }} />
                        </Typography>
                    </Grid>
                    {mdDown && (
                        <Divider sx={{ my: 3, borderColor: 'text.disabled', width: '100%' }} />
                    )}
                    <Grid item xs={12} md={6}>
                        <Typography
                            variant="subtitle2"
                            color="primary.contrastText"
                            fontWeight={500}
                        >
                            Facturada a
                        </Typography>
                        <Skeleton
                            width="80%"
                            height={24}
                            sx={{ mt: '30px', bgcolor: 'primary.dark' }}
                        />
                        <Skeleton
                            width="70%"
                            height={24}
                            sx={{ mt: '30px', bgcolor: 'primary.dark' }}
                        />
                        <Skeleton
                            width="85%"
                            height={24}
                            sx={{ mt: '30px', bgcolor: 'primary.dark' }}
                        />
                        <Skeleton
                            width="90%"
                            height={24}
                            sx={{ mt: '5px', bgcolor: 'primary.dark' }}
                        />
                        <Skeleton
                            width="70%"
                            height={24}
                            sx={{ mt: '30px', bgcolor: 'primary.dark' }}
                        />
                    </Grid>
                </InvoiceDetailContainer>
                {/* table */}
                <Typography variant="subtitle1" color="primary.main" fontWeight={500}>
                    Conceptos
                </Typography>
                <Grid item container xs={12} justifyContent="center">
                    <Skeleton width="95%" height={150} />
                </Grid>
                <Grid container sx={{ my: 2 }}>
                    <Typography variant="subtitle2" color="text.primary" fontWeight={500} mr="10px">
                        Exportación
                    </Typography>
                    <Skeleton width={100} height={24} />
                </Grid>
                <ExpensesDetailContainer item xs={12} md={6}>
                    <Skeleton width="80%" height={24} />
                    <Skeleton width="85%" height={24} sx={{ mt: '5px' }} />
                    <Skeleton width="90%" height={24} sx={{ mt: '5px' }} />
                    <Skeleton width="95%" height={24} sx={{ mt: '5px' }} />
                </ExpensesDetailContainer>
                <Grid item xs={12} md={6} textAlign="right" sx={{ mt: { xs: 3, md: 0 } }}>
                    <Grid container paddingLeft={{ xs: 0, md: '50px' }}>
                        <Grid item textAlign="left" xs={4}>
                            <Typography variant="body1" color="text.primary" fontWeight={500}>
                                Subtotal
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" color="secondary.main" fontWeight={500}>
                                $
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Skeleton width={100} height={24} />
                        </Grid>
                    </Grid>
                    <Grid container marginTop="10px" paddingLeft={{ xs: 0, md: '50px' }}>
                        <Grid item textAlign="left" xs={4}>
                            <Typography variant="body1" color="text.primary" fontWeight={500}>
                                Descuento
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" color="secondary.main" fontWeight={500}>
                                $
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Skeleton width={100} height={24} />
                        </Grid>
                    </Grid>
                    <Grid container marginTop="10px" paddingLeft={{ xs: 0, md: '50px' }}>
                        <Grid item textAlign="left" xs={4}>
                            <Typography variant="body1" color="text.primary" fontWeight={500}>
                                Impuestos
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" color="secondary.main" fontWeight={500}>
                                $
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Skeleton width={100} height={24} />
                        </Grid>
                    </Grid>
                    <Divider
                        sx={{
                            margin: '24px 0 8px 8px',
                            borderColor: 'text.disabled',
                            width: '100%',
                        }}
                    />
                    <Grid container paddingLeft={{ xs: 0, md: '50px' }}>
                        <Grid item textAlign="left" xs={6}>
                            <Typography variant="subtitle2" color="text.primary" fontWeight={500}>
                                Monto total
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Skeleton width="90%" height={24} />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    );
};

export default FiscalDetailSkeleton;
