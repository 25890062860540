/* eslint-disable import/no-unresolved, react/jsx-filename-extension, import/extensions */
// TODO: Separate into different files (Store, Context & Reducer)
import { useContext, createContext, useReducer, useEffect } from 'react';
import SessionContext from 'context/session/sessionContext';
import fairplayAPI from 'utils/api';
import { monetaryDecimalToInteger } from 'utils/currency';
import {
    getTotalCurrenciesAmountSum,
    get4MonthsWeekends,
} from 'components/dashboard/disbursment/transactions/create-disbursement/utils';
import {
    SINGLE_DISBURSEMENT_VALUES,
    RESET_VALUES,
    SET_CONTRACT,
    SET_SUPPLIERS,
    SET_DISBURSEMENT_STEP,
    SET_DISBURSEMENT_PERIOD,
    SET_PRESELECTED_DATE,
    SET_LOCATION,
    SET_ALERT,
    SET_CART_DATA,
    SET_MIN_DISBURSEMENT_DATE,
    SET_MAX_DISBURSEMENT_DATE,
    SET_PERIOD_AVAILABLE_BALANCE,
    SET_TOTAL_REQUESTED_AMOUNT,
    SET_SUPPLIERS_COUNT,
    SET_EXCHANGE_RATES,
    SET_PREV_TOTAL_REQUESTED_AMOUNT,
    SET_FORM_COPY,
    SET_DISBURSEMENT_COMPANY_ID,
    SET_VALUES,
    // TODO: Delete line below when disbursement V1 is deprecated
    SET_IS_NEW_VERSION,
    SET_IS_AMOUNT_VALID,
    SET_FORMS_STEP,
    SET_DISABLED_DISB_DAYS,
} from './types';
import { getSavedCartData } from './utils';

const dynamicDisabledDays = get4MonthsWeekends();

export const initialState = {
    selectedContract: '',
    suppliers: [],
    suppliersCount: 0,
    disbursementStep: 0,
    disbursementLocation: '',
    disbursementPeriod: 'current',
    preSelectedDisbursementDate: undefined,
    periodAvailableBalance: 0,
    totalRequestedAmount: {},
    exchangeRates: {},
    minDisbursementDate: undefined,
    maxDisbursementDate: undefined,
    disabledDisbursementDays: [],
    disbursementAlert: { msg: '', severity: 'error' },
    disbursementCompanyId: '',
    formCopy: SINGLE_DISBURSEMENT_VALUES,
    // Previous requested amount needed in order to avoid summing all cart items amounts in every update
    prevTotalRequestedAmount: {},
    cartData: [],
    // TODO: Delete line below when disbursement V1 is deprecated
    enableDisbursementsV2Module: null,
    isAmountValid: true,
    formsStep: 0,
};

export const DisbursementValuesContext = createContext();

const DisbursementValuesReducer = (state, action) => {
    switch (action.type) {
        case SET_VALUES:
            return action.value;

        case RESET_VALUES:
            if (action.value)
                return {
                    ...initialState,
                    disbursementLocation: action.value,
                    // TODO: Delete line below when disbursement V1 is deprecated
                    enableDisbursementsV2Module: state.enableDisbursementsV2Module,
                };
            return {
                ...initialState,
                // TODO: Delete line below when disbursement V1 is deprecated
                enableDisbursementsV2Module: state.enableDisbursementsV2Module,
            };

        case SET_CONTRACT:
            return { ...state, selectedContract: action.value };

        case SET_SUPPLIERS:
            return { ...state, suppliers: action.value };

        case SET_DISBURSEMENT_STEP: {
            let newStep = action.value;
            if (action.value === 'backward') newStep = state.disbursementStep - 1;
            else if (action.value === 'forward') newStep = state.disbursementStep + 1;

            return {
                ...state,
                disbursementStep: newStep,
            };
        }

        case SET_DISBURSEMENT_PERIOD:
            return { ...state, disbursementPeriod: action.value };

        case SET_PRESELECTED_DATE:
            return { ...state, preSelectedDisbursementDate: action.value };

        case SET_LOCATION:
            return { ...state, disbursementLocation: action.value };

        case SET_ALERT:
            return !action.value.msg && !state.disbursementAlert.msg
                ? state
                : { ...state, disbursementAlert: action.value };

        case SET_CART_DATA:
            return {
                ...state,
                cartData: action.value,
            };

        case SET_PERIOD_AVAILABLE_BALANCE:
            return {
                ...state,
                periodAvailableBalance: action.value,
            };

        case SET_MIN_DISBURSEMENT_DATE:
            return {
                ...state,
                minDisbursementDate: action.value,
            };

        case SET_MAX_DISBURSEMENT_DATE:
            return {
                ...state,
                maxDisbursementDate: action.value,
            };

        case SET_TOTAL_REQUESTED_AMOUNT:
            return {
                ...state,
                totalRequestedAmount: action.value,
            };

        case SET_EXCHANGE_RATES:
            return {
                ...state,
                exchangeRates: action.value,
            };

        case SET_PREV_TOTAL_REQUESTED_AMOUNT:
            return {
                ...state,
                prevTotalRequestedAmount: action.value,
            };

        case SET_FORM_COPY:
            return {
                ...state,
                formCopy: action.value,
            };

        case SET_DISBURSEMENT_COMPANY_ID:
            return {
                ...state,
                disbursementCompanyId: action.value,
            };

        case SET_SUPPLIERS_COUNT: {
            const newCount = new Set(
                action.value
                    .filter((cartItem) => !!cartItem.supplierData?.id)
                    .map((cartItem) => cartItem.supplierData?.id),
            ).size;

            return {
                ...state,
                suppliersCount: newCount,
            };
        }
        // TODO: Delete when disbursement V1 is removed
        case SET_IS_NEW_VERSION:
            return {
                ...state,
                enableDisbursementsV2Module: action.value,
            };

        case SET_IS_AMOUNT_VALID:
            return {
                ...state,
                isAmountValid: action.value,
            };

        case SET_FORMS_STEP:
            return {
                ...state,
                formsStep: action.value,
            };

        case SET_DISABLED_DISB_DAYS:
            return {
                ...state,
                disabledDisbursementDays: [...dynamicDisabledDays, ...action.value],
            };

        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

// eslint-disable-next-line react/prop-types
export const DisbursementValuesProvider = ({ children }) => {
    const [state, dispatch] = useReducer(DisbursementValuesReducer, initialState),
        { selectedCompany, user, setError } = useContext(SessionContext);

    const setCartData = (value) => {
        dispatch({ type: SET_CART_DATA, value });
    };

    const setDisbursementAlert = (value) => {
        dispatch({ type: SET_ALERT, value });
    };

    const setPeriodAvailableBalance = (value) => {
        dispatch({ type: SET_PERIOD_AVAILABLE_BALANCE, value });
    };

    const setDisbursementLocation = (value) => {
        dispatch({ type: SET_LOCATION, value });
    };

    const setDisbursementSuppliers = (value) => {
        dispatch({ type: SET_SUPPLIERS, value });
        if (state.disbursementAlert.msg)
            setDisbursementAlert({
                msg: '',
                severity: 'success',
            });
    };

    const setTotalRequestedAmount = (value) => {
        dispatch({ type: SET_TOTAL_REQUESTED_AMOUNT, value });
    };

    const setIsAmountValid = (value) => {
        dispatch({ type: SET_IS_AMOUNT_VALID, value });
    };

    const setSuppliersCount = (value) => {
        dispatch({ type: SET_SUPPLIERS_COUNT, value });
    };

    const setDisbursementCompanyId = (value) => {
        dispatch({ type: SET_DISBURSEMENT_COMPANY_ID, value });
    };

    const setPreselectedDate = (value) => {
        dispatch({ type: SET_PRESELECTED_DATE, value });
    };

    const setDisbursementStep = (value) => {
        dispatch({ type: SET_DISBURSEMENT_STEP, value });
    };

    const setDisbursementValues = (value) => {
        dispatch({ type: SET_VALUES, value });
    };

    const resetDisbursementValues = (value = null) => {
        dispatch({ type: RESET_VALUES, value });
    };

    const setDisbursementPeriod = (value) => {
        dispatch({ type: SET_DISBURSEMENT_PERIOD, value });
    };

    const setDisbursementContract = (value) => {
        dispatch({ type: SET_CONTRACT, value });
    };

    const setMinDisbursementDate = (value) => {
        dispatch({ type: SET_MIN_DISBURSEMENT_DATE, value });
    };

    const setMaxDisbursementDate = (value) => {
        dispatch({ type: SET_MAX_DISBURSEMENT_DATE, value });
    };

    const setExchangeRates = (value) => {
        dispatch({ type: SET_EXCHANGE_RATES, value });
    };

    const setPrevTotalRequestedAmount = (value) => {
        dispatch({ type: SET_PREV_TOTAL_REQUESTED_AMOUNT, value });
    };

    const setFormCopy = (value) => {
        // TODO: Validate when form copy should get updated
        dispatch({ type: SET_FORM_COPY, value });
    };

    // TODO: Delete function when disbursement V1 is deprecated
    const setEnableDisbursementsV2Module = (value) => {
        dispatch({ type: SET_IS_NEW_VERSION, value });
    };

    const setFormsStep = (value) => {
        dispatch({ type: SET_FORMS_STEP, value });
    };

    const setDisabledDisbursementDays = (value) => {
        dispatch({ type: SET_DISABLED_DISB_DAYS, value });
    };

    const addDisbToCart = () => {
        const newCartData = [...state.cartData],
            newCartIndex = newCartData.length,
            isPreviousFormSubmited = newCartData[newCartIndex - 1]?.id_dispersion;

        newCartData.push({
            ...SINGLE_DISBURSEMENT_VALUES,
            label: `Dispersión ${newCartIndex + 1}`,
            disabled: !isPreviousFormSubmited,
            dispersion_date: state.preSelectedDisbursementDate,
        });
        setCartData(newCartData);
    };

    const removeDisbFormCart = () => {
        setTotalRequestedAmount(state.prevTotalRequestedAmount);
        setIsAmountValid(true);

        const newCartData = [...state.cartData];
        newCartData.splice(state.formsStep, 1);
        for (let i = state.formsStep; i < newCartData.length; i++)
            newCartData[i].label = `Dispersión ${i + 1}`;
        setCartData(newCartData);
        setSuppliersCount(newCartData);

        setDisbursementAlert({ msg: 'Dispersión eliminada', severity: 'success' });
        setTimeout(
            () =>
                setDisbursementAlert({
                    msg: '',
                    severity: 'success',
                }),
            2000,
        );

        return newCartData;
    };

    const discardDisbFormChange = () => {
        const restoredCartData = [...state.cartData],
            restoredCartItemCurrency = state.formCopy.currency.toLowerCase(),
            restoredTotalRequestedAmount = {
                ...state.prevTotalRequestedAmount,
                [restoredCartItemCurrency]:
                    state.prevTotalRequestedAmount[restoredCartItemCurrency] +
                    monetaryDecimalToInteger(state.formCopy.amount),
            };

        restoredCartData[state.formsStep] = state.formCopy;

        setIsAmountValid(true);
        setTotalRequestedAmount(restoredTotalRequestedAmount);
        setSuppliersCount(restoredCartData);
        setCartData(restoredCartData);
    };

    const setDisbursementConfiguration = (
        disbursementCount,
        isDatePreselected,
        disbursementCompanyId,
    ) => {
        setDisbursementCompanyId(disbursementCompanyId);
        const newCartData = [...state.cartData];

        if (!isDatePreselected) setPreselectedDate(undefined);

        for (let i = 0; i < disbursementCount; i++) {
            newCartData.push({
                ...SINGLE_DISBURSEMENT_VALUES,
                ...{
                    label: `Dispersión ${i + 1}`,
                    disabled: !!i,
                    dispersion_date: isDatePreselected
                        ? state.preSelectedDisbursementDate
                        : undefined,
                },
            });
        }
        setCartData(newCartData);
    };

    const getSuppliers = async () => {
        /* eslint-disable consistent-return */
        if (!selectedCompany.company?.id || !state.disbursementLocation) return;
        try {
            const res = await fairplayAPI.get(
                `/v1/companies/${selectedCompany.company?.id}/suppliers?status=approved&page_size=200`,
            );
            const suppliersData = res.data.body.results,
                locationSuppliersData = suppliersData.filter(
                    (supplier) => supplier.type === state.disbursementLocation,
                );

            if (locationSuppliersData.length === 0) {
                setDisbursementAlert({
                    msg: 'No existen proveedores aprobados',
                    severity: 'warning',
                });
                return [];
            }
            setDisbursementSuppliers(locationSuppliersData);

            return locationSuppliersData;
        } catch (errResponse) {
            setDisbursementAlert({
                msg:
                    errResponse?.error ||
                    'Ha ocurrido un error y no se pudieron obtener los proveedores.',
                severity: 'error',
            });
            return [];
        }
        /* eslint-enable consistent-return */
    };

    const getSelectedCompanyBank = async (contractId = null) => {
        try {
            const companyCurrencyRes = await fairplayAPI.get(
                    `/v2/companies/${selectedCompany.company?.id}/contracts/${
                        state.selectedContract?.id || contractId
                    }`,
                ),
                companyBankRes = await fairplayAPI.get(
                    `/v1/companies/${selectedCompany.company?.id}/accounts`,
                ),
                currency = companyCurrencyRes.data.body.results.principalCurrency,
                totalBankAccounts = companyBankRes.data.body.count,
                bankAccounts =
                    totalBankAccounts > 0
                        ? [
                              {
                                  ...companyBankRes.data.body.results?.find(
                                      (bank) => bank?.status?.id === 'primary',
                                  ),
                                  accountCurrency: { id: currency },
                              },
                          ]
                        : [],
                disbReceiverData = { ...selectedCompany.company, bankAccounts };

            setDisbursementSuppliers(disbReceiverData);

            return disbReceiverData;
        } catch (errResponse) {
            setDisbursementAlert({
                msg:
                    errResponse?.error ||
                    'Ocurrió un error obteniendo las cuentas bancarias, intente más tarde',
                severity: 'error',
            });
            return [];
        }
    };

    const recoverCartData = async () => {
        if (!state.cartData.length) {
            // Disbursement general values recovery
            const initialCartValues = {
                ...initialState,
                enableDisbursementsV2Module: state.enableDisbursementsV2Module,
            };

            const savedCartRes = await fairplayAPI.get(`/v1/cart/${user.id}`, {
                    baseService: 'DISBURSEMENT_CART',
                }),
                savedCartData = savedCartRes.data.results;

            // TODO: Fix supplier vs selectedContract race condition
            const suppliersData =
                savedCartData.product_type === 'working-capital'
                    ? await getSuppliers()
                    : await getSelectedCompanyBank(savedCartData.contract_id);

            const cartData = getSavedCartData(initialCartValues, savedCartData, suppliersData);

            setDisbursementValues(cartData);
        } else {
            setDisbursementStep(1);
        }
    };

    const fetchDisbursementCart = async () => {
        try {
            const res = await fairplayAPI.get(`/v1/cart/${user?.id}`, {
                baseService: 'DISBURSEMENT_CART',
            });
            const disbursementLocation = res.data.results.national ? 'Nacional' : 'Internacional',
                companyId = res.data.results.company_id;
            setDisbursementCompanyId(companyId);
            setDisbursementLocation(disbursementLocation);
        } catch (errResponse) {
            if (errResponse.status !== 404) {
                setError({
                    isFatal: false,
                    messages: {
                        cart:
                            errResponse.error ||
                            'Tuvimos un problema con tu dispersión. Crea una nueva',
                    },
                });
            }
        }
    };

    useEffect(() => {
        if (user) fetchDisbursementCart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, selectedCompany.company?.id]);

    return (
        <DisbursementValuesContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                state,
                selectedContract: state.selectedContract,
                suppliers: state.suppliers,
                disbursementStep: state.disbursementStep,
                disbursementLocation: state.disbursementLocation,
                disbursementPeriod: state.disbursementPeriod,
                preSelectedDisbursementDate: state.preSelectedDisbursementDate,
                minDisbursementDate: state.minDisbursementDate,
                maxDisbursementDate: state.maxDisbursementDate,
                disbursementAlert: state.disbursementAlert,
                periodAvailableBalance: state.periodAvailableBalance,
                cartData: state.cartData,
                totalRequestedAmount: state.totalRequestedAmount,
                suppliersCount: state.suppliersCount,
                exchangeRates: state.exchangeRates,
                formCopy: state.formCopy,
                disbursementCompanyId: state.disbursementCompanyId,
                prevTotalRequestedAmount: state.prevTotalRequestedAmount,
                totalRequestedAmountSum: getTotalCurrenciesAmountSum(
                    state.totalRequestedAmount,
                    state.exchangeRates,
                ),
                // TODO: Delete line below when disbursement V1 is deprecated
                enableDisbursementsV2Module: state.enableDisbursementsV2Module,
                isAmountValid: state.isAmountValid,
                formsStep: state.formsStep,
                disabledDisbursementDays: state.disabledDisbursementDays,
                dispatch,
                setCartData,
                setPreselectedDate,
                setDisbursementStep,
                addDisbToCart,
                removeDisbFormCart,
                setDisbursementAlert,
                setDisbursementLocation,
                setDisbursementSuppliers,
                resetDisbursementValues,
                setDisbursementContract,
                setDisbursementPeriod,
                setDisbursementConfiguration,
                setMinDisbursementDate,
                setMaxDisbursementDate,
                setPeriodAvailableBalance,
                setTotalRequestedAmount,
                setSuppliersCount,
                discardDisbFormChange,
                setExchangeRates,
                setPrevTotalRequestedAmount,
                setFormCopy,
                recoverCartData,
                getSuppliers,
                getSelectedCompanyBank,
                // TODO: Delete line below when disbursement V1 is deprecated
                setEnableDisbursementsV2Module,
                setIsAmountValid,
                setFormsStep,
                setDisabledDisbursementDays,
            }}
        >
            {children}
        </DisbursementValuesContext.Provider>
    );
};

export const useDisbursementValuesContext = () => {
    const context = useContext(DisbursementValuesContext);
    if (context === undefined)
        throw new Error(
            'useDisbursementValuesContext must be used within a DisbursementValuesProvider',
        );

    return context;
};
