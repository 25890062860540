import { FC } from 'react';
import { Typography, IconBtn, Divider, Tooltip } from '@fairplay2/ui';
import { Grid, useTheme, useMediaQuery } from '@mui/material';
import { CheckCircleOutline, Info, HighlightOff } from '@mui/icons-material';
import { TIME_READABLE_FORMAT, format } from 'utils/dates';
import { capitalize } from 'utils/formatting';
import { InvoiceDetailProps } from '../../interfaces';
import { InvoiceDetailContainer } from '../../styles';

const InvoiceDetail: FC<InvoiceDetailProps> = ({ invoiceValues }) => {
    const theme = useTheme(),
        mdDown = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <InvoiceDetailContainer item container xs={12}>
            <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="primary.contrastText" fontWeight={500}>
                    ID Factura
                </Typography>
                <Typography
                    variant="subtitle2"
                    color="primary.contrastText"
                    fontWeight={700}
                    mt="10px"
                >
                    {invoiceValues?.folioFiscal}
                </Typography>
                <Grid container alignItems="center" sx={{ my: 3 }}>
                    {invoiceValues?.status === 'VIGENTE' ? (
                        <CheckCircleOutline sx={{ color: theme.palette.secondary.main }} />
                    ) : (
                        <HighlightOff sx={{ color: theme.palette.secondary.main }} />
                    )}

                    <Typography
                        variant="subtitle2"
                        color="secondary.main"
                        fontWeight={500}
                        mr="20px"
                    >
                        {' '}
                        {invoiceValues?.status && capitalize(invoiceValues?.status)}
                    </Typography>
                    {invoiceValues?.isPaid && (
                        <>
                            <CheckCircleOutline sx={{ color: theme.palette.secondary.main }} />
                            <Typography variant="subtitle2" color="secondary.main" fontWeight={500}>
                                {' '}
                                Pagada
                            </Typography>
                        </>
                    )}
                </Grid>
                <Typography
                    variant="subtitle2"
                    color="primary.contrastText"
                    fontWeight={500}
                    mt="10px"
                >
                    Emitida:{' '}
                    <Typography
                        component="span"
                        variant="subtitle2"
                        color="primary.contrastText"
                        fontWeight={700}
                    >
                        {format(invoiceValues?.issuedAt || '', TIME_READABLE_FORMAT)}
                    </Typography>
                </Typography>
                <Typography
                    variant="subtitle2"
                    color="primary.contrastText"
                    fontWeight={500}
                    mt="10px"
                >
                    Certificada:{' '}
                    <Typography
                        component="span"
                        variant="subtitle2"
                        color="primary.contrastText"
                        fontWeight={700}
                    >
                        {format(invoiceValues?.certifiedAt || '', TIME_READABLE_FORMAT)}
                        {invoiceValues?.pac && (
                            <Tooltip
                                title={
                                    <Grid item marginLeft="-25px">
                                        <Typography
                                            variant="body2"
                                            fontWeight={700}
                                            color="secondary.main"
                                        >
                                            PAC:
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            fontWeight={700}
                                            color="secondary.main"
                                        >
                                            {invoiceValues?.pac}
                                        </Typography>
                                    </Grid>
                                }
                                variant="text"
                                placement="right-start"
                                color="info"
                            >
                                <IconBtn color="secondary" aria-label="PAC de facturación">
                                    <Info />
                                </IconBtn>
                            </Tooltip>
                        )}
                    </Typography>
                </Typography>
                <Typography
                    variant="subtitle2"
                    color="primary.contrastText"
                    fontWeight={500}
                    mt="10px"
                >
                    Cancelada:{' '}
                    <Typography
                        component="span"
                        variant="subtitle2"
                        color="primary.contrastText"
                        fontWeight={700}
                    >
                        {invoiceValues?.cancellationStatus
                            ? invoiceValues?.cancellationStatus
                            : invoiceValues?.canceledAt}
                    </Typography>
                </Typography>
            </Grid>
            {mdDown && (
                <Grid item xs={12}>
                    <Divider
                        variant="fullWidth"
                        sx={{
                            color: 'primary.contrastText',
                            mt: '25px',
                            mb: '25px',
                        }}
                    />
                </Grid>
            )}
            <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="primary.contrastText" fontWeight={500}>
                    Facturada a
                </Typography>
                <Typography
                    variant="subtitle2"
                    color="primary.contrastText"
                    fontWeight={700}
                    mt="10px"
                >
                    {invoiceValues?.receiver?.name}
                </Typography>
                <Typography
                    variant="subtitle2"
                    color="primary.contrastText"
                    fontWeight={700}
                    mt="10px"
                >
                    {invoiceValues?.receiver?.rfc}
                </Typography>
                <Typography
                    variant="subtitle2"
                    color="primary.contrastText"
                    fontWeight={700}
                    mt="10px"
                >
                    {invoiceValues?.receiver?.taxRegime}
                </Typography>
            </Grid>
        </InvoiceDetailContainer>
    );
};

export default InvoiceDetail;
