import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import { ArrowForward, CompareArrows, Refresh, RequestQuote } from '@mui/icons-material';
import { Loader, Tooltip } from '@fairplay2/ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { useDisbursementValuesContext } from 'context/disbursements/DisbursementValuesContext';
import sessionContext from 'context/session/sessionContext';
import { SettingsIconBtn, StyledBadge, topbarTooltipStyles } from '../styles';
import { UserSettingsButton } from './UserSettingsButton';

export const TopBarActions = () => {
    const { push } = useHistory(),
        { pathname } = useLocation(),
        { selectedCompany } = useContext(sessionContext),
        {
            recoverCartData,
            disbursementLocation,
            disbursementCompanyId,
            enableDisbursementsV2Module,
            setEnableDisbursementsV2Module,
        } = useDisbursementValuesContext(),
        [loading, setLoading] = useState(false),
        [error, setIsTooltipOpen] = useState(false),
        isCompanyPendingCart = disbursementCompanyId === selectedCompany.company?.id,
        isNewVersionSelected =
            enableDisbursementsV2Module ||
            JSON.parse(sessionStorage.getItem('selectedContract') || '{}')
                .enableDisbursementsV2Module;

    const handleRecoverCart = async () => {
        if (error) setIsTooltipOpen(false);
        setLoading(true);
        try {
            await recoverCartData();
            push('/app/disbursements/transactions/create');
        } catch (errResponse: ErrorObj | any) {
            setIsTooltipOpen(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        /** This condition is necessary becasue when the user refreshes the browser and the selected contract
                already exists in the sessionStorage,  it's important to update the context.enableDisbursementsV2Module
                to display the correct disbursement version */
        if (!enableDisbursementsV2Module && isNewVersionSelected)
            setEnableDisbursementsV2Module(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany.company?.id]);

    return (
        <>
            <Grid item>
                <UserSettingsButton />
            </Grid>
            {isCompanyPendingCart &&
                !!disbursementLocation &&
                pathname !== '/app/disbursement/create' &&
                // TODO: Delete conditional below when disbursement V1 is deprecated
                isNewVersionSelected && (
                    <Grid item>
                        {loading ? (
                            <Loader size={25} />
                        ) : (
                            <Tooltip
                                title={
                                    error ? (
                                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                        <div onClick={handleRecoverCart} role="button" tabIndex={0}>
                                            ¡Algo salió mal! Intenta otra vez por favor
                                            <Refresh className="tooltip-icon" aria-hidden />
                                        </div>
                                    ) : (
                                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                        <div onClick={handleRecoverCart} role="button" tabIndex={0}>
                                            Retomar solicitud de dispersión pendiente
                                            <ArrowForward className="tooltip-icon" aria-hidden />
                                        </div>
                                    )
                                }
                                open={error || undefined}
                                overrideStyles={topbarTooltipStyles(error)}
                            >
                                <SettingsIconBtn
                                    error={error}
                                    onClick={handleRecoverCart}
                                    sx={{ svg: { fontSize: '1.3em' } }}
                                >
                                    <StyledBadge badgeContent={<CompareArrows aria-hidden />}>
                                        <RequestQuote
                                            fontSize="inherit"
                                            titleAccess="Retomar solicitud de dispersión pendiente"
                                        />
                                    </StyledBadge>
                                </SettingsIconBtn>
                            </Tooltip>
                        )}
                    </Grid>
                )}
        </>
    );
};

export default TopBarActions;
