import { FC } from 'react';
import { Grid } from '@mui/material';
import { Modal, DefaultBtn, Typography } from '@fairplay2/ui';
import { ManSittingPlantTwoSvg } from 'assets/SVGs';
import { ConfirmModalProps } from '../interfaces';

const ConfirmModal: FC<ConfirmModalProps> = ({ open, onClose, account }) => (
    <Modal open={open} maxWidth={680} onClose={() => onClose('confirm')}>
        <Grid container>
            <Grid container justifyContent="center">
                <ManSittingPlantTwoSvg />
            </Grid>
            <Typography variant="subtitle1" color="primary.main" mt="30px">
                ¡Se ha eliminado la cuenta asociada!
            </Typography>
            <Typography variant="subtitle2" color="text.primary" fontWeight={500} mt="10px">
                Has eliminado exitosamente la cuenta bancaria asociada:{' '}
                <Typography component="span" color="primary.dark" fontWeight={700}>
                    {account?.clabe}{' '}
                    <Typography component="span" color="text.primary" fontWeight={500}>
                        de
                    </Typography>{' '}
                    {account?.company}
                </Typography>{' '}
            </Typography>
            <Grid container justifyContent="center" marginTop="20px">
                <DefaultBtn size="small" onClick={() => onClose('confirm')}>
                    Entendido
                </DefaultBtn>
            </Grid>
        </Grid>
    </Modal>
);

export default ConfirmModal;
