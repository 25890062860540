import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AttachFile, CheckCircleOutlined } from '@mui/icons-material';
import { Typography, TabGroup, TabData } from '@fairplay2/ui';
import SessionContext from 'context/session/sessionContext';
import fairplayAPI from 'utils/api';
import { toFormData } from 'utils/parsing';
import { Grid } from '@mui/material';
import { useOtpStatus } from 'utils/hooks';
import { useFile } from '../context/file-context';
import { DisbursementObj } from '../interfaces';
import ListErrors from './components/ListErrors';
import Step1 from './components/Step1';
import Step2 from './components/Step2';

const MassDisbursement: FC = () => {
    const { selectedCompany } = useContext(SessionContext),
        { isOtpRequired } = useOtpStatus(),
        [activeStep, setActiveStep] = useState(0),
        { push } = useHistory(),
        isFirstRender = useRef(true),
        [disbursements, setDisbursements] = useState<DisbursementObj[]>([]),
        [contractId, setContractId] = useState<string>(''),
        [otp, setOtp] = useState(''),
        {
            state: { file, errors: fileErrors },
            dispatch,
        } = useFile(),
        [tabs, setTabs] = useState<TabData[]>([
            {
                label: 'Cargar archivo',
                icon: <AttachFile />,
                disabled: true,
                hasError: false,
            },
            {
                label: 'Confirmación',
                icon: <CheckCircleOutlined />,
                disabled: true,
                hasError: false,
            },
        ]);

    const returnToFileUpload = () => {
        setActiveStep(0);
        const tabsCopy = [...tabs];
        tabsCopy[1].hasError = false;
        setTabs(tabsCopy);
    };

    const onFirstSubmit = (newDisbursements: any) => {
        setDisbursements(newDisbursements);
        setActiveStep(1);
    };

    const onSubmit = async (confirmedDisbursement: boolean) => {
        const data = toFormData({
            ...(file && { file }),
            ...(confirmedDisbursement && {
                confirmation: 'true',
                ...(isOtpRequired && { token: otp }),
            }),
        });

        const res: any = await fairplayAPI.post(
            `/v2/companies/${selectedCompany.company?.id}/contracts/${contractId}/dispersions/massive`,
            data,
            { useIdempotency: true },
        );
        if (activeStep === 0 && res.data.body.results.dispersions.length) {
            onFirstSubmit(res.data.body.results.dispersions);
        }
        return res;
    };

    useEffect(() => {
        if (fileErrors.length) {
            setActiveStep(1);
            const tabsCopy = [...tabs];
            tabsCopy[1].hasError = true;
            setTabs(tabsCopy);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileErrors]);

    useEffect(() => {
        if (!selectedCompany?.company) return;
        if (!isFirstRender.current) {
            dispatch({ type: 'upload' });
            push(`/app/disbursements/transactions`);
        }
        isFirstRender.current = false;
    }, [selectedCompany, push, dispatch]);

    return (
        <Grid container spacing={4} mb="20px">
            <Grid item xs={12} m="30px 0 0 0">
                <Typography variant="h4" color="primary.main">
                    Solicitud de{' '}
                    <Typography component="span" color="primary.dark" fontWeight={700}>
                        Dispersión múltiple
                    </Typography>
                </Typography>
                <Typography variant="subtitle1" color="primary.main" mt="20px">
                    Por favor confirma
                    {activeStep === 0
                        ? ' el archivo y contrato seleccionado'
                        : ' los datos de las dispersiones que se realizarán'}
                </Typography>
            </Grid>

            <TabGroup tabs={tabs} value={activeStep} item>
                <Step1 contractId={contractId} setContractId={setContractId} onSubmit={onSubmit} />
                {fileErrors.length > 0 ? (
                    <ListErrors fileErrors={fileErrors} returnToFileUpload={returnToFileUpload} />
                ) : (
                    <Step2
                        disbursements={disbursements}
                        onSubmit={onSubmit}
                        returnToFileUpload={returnToFileUpload}
                        otpValue={otp}
                        setOtpValue={setOtp}
                    />
                )}
            </TabGroup>
        </Grid>
    );
};

export default MassDisbursement;
