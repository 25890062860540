import { BasicContractFields } from 'components/dashboard/contracts/interfaces';
// TODO: Find another place for this util
import { Serie, ChartFactoryProps, ChartFactory } from './ChartFactory';

export const capitalize = (str: string): string =>
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const numberWithCommas = (num: number) =>
    Number(num.toFixed(2))
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const contractPrintableName = (
    contract?: Omit<BasicContractFields, 'id' | 'status'> | null,
) => {
    if (contract?.fairplayInternId) return contract.fairplayInternId;
    if (contract?.opportunityId) return `#${contract.opportunityId}`;
    return '';
};

export const snakeToCamel = (str: string) =>
    str
        .split('_')
        .map((word, i) => (i === 0 ? word : capitalize(word)))
        .join('');

/**
 * Censors a given string by replacing all characters in the middle with asterisks
 * @param str The string to censor
 * @returns The censored string
 */
export const censorWord = (str: string) => {
    if (str.length >= 2) return str[0] + '*'.repeat(str.length - 2) + str.slice(-1);
    return str;
};

/**
 * Censors a given email address by replacing all characters in the middle of the username and domain with asterisks
 * @param email The email address to be censored
 * @returns The censored email address
 */
export const censorEmail = (email: string) => {
    const arr = email.split('@');
    return email ? `${censorWord(arr[0])}@${censorWord(arr[1])}` : email;
};

export { type Serie, type ChartFactoryProps, ChartFactory };
