import { createContext, useReducer, useContext, ReactNode, FC } from 'react';
import { FileState, FileAction, FileContextProps } from '../interfaces';

const initialState: FileState = { file: null, errors: [] };

const FileContext = createContext<FileContextProps | undefined>(undefined);

const fileReducer = (state: FileState, action: FileAction): FileState => {
    switch (action.type) {
        case 'upload': {
            return { ...state, file: action.payload || null, errors: [] };
        }
        case 'invalidate': {
            return { ...state, file: null, errors: action.payload || [] };
        }
        default: {
            return state;
        }
    }
};

const FileProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(fileReducer, initialState);
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const value = { state, dispatch };
    return <FileContext.Provider value={value}>{children}</FileContext.Provider>;
};

const useFile = (): FileContextProps => {
    const context = useContext(FileContext);
    if (context === undefined) {
        throw new Error('useFile must be used within a FileProvider');
    }
    return context;
};

export { FileProvider, useFile };
