import { FC } from 'react';
import { Grid, Collapse } from '@mui/material';
import { Alert, Typography } from '@fairplay2/ui';
import { useTableContext } from '../../state/TableContext';
import { CardsTableProps } from '../../interfaces';
import CardFilters from './CardFilters';
import CardsTable from './CardsTable';

export const RequestedCardsWidget: FC<CardsTableProps> = ({
    selectedCompanyId,
    selectedContractId,
    maxForDisposition,
}) => {
    const { alert } = useTableContext();

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="subtitle1" color="primary.dark">
                    Listado de tarjetas solicitadas.
                </Typography>
                <Typography variant="subtitle2" color="primary.main">
                    Verifica aquí el estatus de tus tarjetas.
                </Typography>
            </Grid>

            <Grid item container xs={12} justifyContent="flex-end" mb={alert.open ? '20px' : ''}>
                <Collapse in={alert.open}>
                    <Alert severity={alert.type}>{alert.message}</Alert>
                </Collapse>
            </Grid>

            <CardFilters />

            <CardsTable
                selectedCompanyId={selectedCompanyId}
                selectedContractId={selectedContractId}
                maxForDisposition={maxForDisposition}
            />
        </>
    );
};

export default RequestedCardsWidget;
