import { numberWithCommas } from 'utils/formatting';
import { TRADING_WARNING_C } from '../utils/constants';
import { BaseState } from '../interfaces';
import { baseReducer } from '../baseReducer';
import { FormattedItem, RawItem, KPIsCardsKeys, KPIsAction } from './interfaces';

export const validateData = (obj: RawItem): FormattedItem => {
    // Modify data to show only 2 decimals and commas
    const newObj = {
        current: '',
        last: '',
        percentage: {
            value: 0,
            amount: '',
        },
        warningC: '',
        warningL: '',
    };
    if (obj.current === 0 || obj.current === null) newObj.warningC = TRADING_WARNING_C.noOrders;
    else newObj.current = numberWithCommas(obj.current);

    if (obj.last === 0 || obj.last === null) newObj.warningL = TRADING_WARNING_C.noOrders;
    else newObj.last = numberWithCommas(obj.last);

    newObj.percentage.amount = numberWithCommas(obj.percentage.amount);
    newObj.percentage.value = Number(obj.percentage.value.toFixed(2));

    return newObj;
};

const initialCardData = {
    adSpend: {
        item: {
            current: '',
            last: '',
            percentage: {
                value: 0,
                amount: '0',
            },
            warningC: '',
            warningL: '',
        },
        title: 'Inversión en publicidad',
        total: null,
        loading: true,
        symbol: '$',
        perc: '',
    },
    impressions: {
        item: {
            current: '',
            last: '',
            percentage: {
                value: 0,
                amount: '0',
            },
            warningC: '',
            warningL: '',
        },
        title: 'Impresiones totales',
        total: null,
        loading: true,
        symbol: '',
        perc: '',
    },
    clicks: {
        item: {
            current: '',
            last: '',
            percentage: {
                value: 0,
                amount: '0',
            },
            warningC: '',
            warningL: '',
        },
        title: 'Clicks totales',
        total: null,
        loading: true,
        symbol: '',
        perc: '',
    },
    sessions: {
        item: {
            current: '',
            last: '',
            percentage: {
                value: 0,
                amount: '0',
            },
            warningC: '',
            warningL: '',
        },
        title: 'Sesiones totales',
        total: null,
        loading: true,
        symbol: '',
        perc: '',
    },
    orders: {
        item: {
            current: '',
            last: '',
            percentage: {
                value: 0,
                amount: '0',
            },
            warningC: '',
            warningL: '',
        },
        title: 'Cantidad de órdenes',
        total: null,
        loading: true,
        symbol: '',
        perc: '',
    },
    gvm: {
        item: {
            current: '',
            last: '',
            percentage: {
                value: 0,
                amount: '0',
            },
            warningC: '',
            warningL: '',
        },
        title: 'Ventas totales',
        total: null,
        loading: true,
        symbol: '$',
        perc: '',
    },
    ticket: {
        item: {
            current: '',
            last: '',
            percentage: {
                value: 0,
                amount: '0',
            },
            warningC: '',
            warningL: '',
        },
        title: 'Ticket promedio',
        total: null,
        loading: true,
        symbol: '$',
        perc: '',
    },
    roas: {
        item: {
            current: '',
            last: '',
            percentage: {
                value: 0,
                amount: '0',
            },
            warningC: '',
            warningL: '',
        },
        title: 'ROAS',
        total: null,
        loading: true,
        symbol: '',
        perc: '',
    },
    cost: {
        item: {
            current: '',
            last: '',
            percentage: {
                value: 0,
                amount: '0',
            },
            warningC: '',
            warningL: '',
        },
        title: 'Costo por conversión',
        total: null,
        loading: true,
        symbol: '$',
        perc: '',
    },
    rate: {
        item: {
            current: '',
            last: '',
            percentage: {
                value: 0,
                amount: '0',
            },
            warningC: '',
            warningL: '',
        },
        title: 'Porcentaje de conversión',
        total: null,
        loading: true,
        symbol: '',
        perc: '%',
    },
};

export const KPIsInitialState: BaseState<never, KPIsCardsKeys> = {
    chartData: {},
    metrics: initialCardData,
};

export const KPIsReducer = (state: BaseState<never, KPIsCardsKeys>, action: KPIsAction) => {
    switch (action.type) {
        case 'SET_ADSPEND_CARD':
            return baseReducer(state, {
                type: 'SET_METRIC',
                payload: {
                    key: 'adSpend',
                    value: { item: validateData(action.payload.value) },
                },
            }) as BaseState<never, KPIsCardsKeys>;
        case 'SET_IMPRESSIONS_CARD':
            return baseReducer(state, {
                type: 'SET_METRIC',
                payload: {
                    key: 'impressions',
                    value: { item: validateData(action.payload.value) },
                },
            }) as BaseState<never, KPIsCardsKeys>;
        case 'SET_CLICKS_CARD':
            return baseReducer(state, {
                type: 'SET_METRIC',
                payload: {
                    key: 'clicks',
                    value: { item: validateData(action.payload.value) },
                },
            }) as BaseState<never, KPIsCardsKeys>;
        case 'SET_SESSIONS_CARD':
            return baseReducer(state, {
                type: 'SET_METRIC',
                payload: {
                    key: 'sessions',
                    value: { item: validateData(action.payload.value) },
                },
            }) as BaseState<never, KPIsCardsKeys>;
        case 'SET_ORDERS_CARD':
            return baseReducer(state, {
                type: 'SET_METRIC',
                payload: {
                    key: 'orders',
                    value: { item: validateData(action.payload.value) },
                },
            }) as BaseState<never, KPIsCardsKeys>;
        case 'SET_GVM_CARD':
            return baseReducer(state, {
                type: 'SET_METRIC',
                payload: {
                    key: 'gvm',
                    value: { item: validateData(action.payload.value) },
                },
            }) as BaseState<never, KPIsCardsKeys>;
        case 'SET_TICKET_CARD':
            return baseReducer(state, {
                type: 'SET_METRIC',
                payload: {
                    key: 'ticket',
                    value: { item: validateData(action.payload.value) },
                },
            }) as BaseState<never, KPIsCardsKeys>;
        case 'SET_ROAS_CARD':
            return baseReducer(state, {
                type: 'SET_METRIC',
                payload: {
                    key: 'roas',
                    value: { item: validateData(action.payload.value) },
                },
            }) as BaseState<never, KPIsCardsKeys>;
        case 'SET_COST_CARD':
            return baseReducer(state, {
                type: 'SET_METRIC',
                payload: {
                    key: 'cost',
                    value: { item: validateData(action.payload.value) },
                },
            }) as BaseState<never, KPIsCardsKeys>;
        case 'SET_RATE_CARD':
            return baseReducer(state, {
                type: 'SET_METRIC',
                payload: {
                    key: 'rate',
                    value: { item: validateData(action.payload.value) },
                },
            }) as BaseState<never, KPIsCardsKeys>;
        default:
            return baseReducer(state, action) as BaseState<never, KPIsCardsKeys>;
    }
};
