import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SessionContext from 'context/session/sessionContext';
import { ErrorObj } from 'utils/error-handler';
import fairplayAPI from 'utils/api';
import { CardDetail } from '../interfaces';

const CARDS_DIRECTORY_ROUTE = '/app/disbursements/cards';

export const useGetCardDetail = (cardId: string) => {
    const { push, location } = useHistory(),
        { selectedCompany } = useContext(SessionContext),
        isFirstRender = useRef(true),
        [error, setError] = useState<ErrorObj | null>(null),
        [loading, setLoading] = useState(true),
        [cardValues, setCardValues] = useState<CardDetail>({} as CardDetail);

    useEffect(() => {
        if (!selectedCompany?.company) return;
        if (!isFirstRender.current) {
            push('/app/sources');
            return;
        }

        isFirstRender.current = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany.company?.id, push]);

    useEffect(() => {
        (async () => {
            if (selectedCompany.company?.id && cardId) {
                // Run the hideAlert outside the hook
                setError(null);
                setLoading(true);
                try {
                    const res: any = await fairplayAPI.get(`/cards/v1/card/${cardId}/detail`, {
                            baseService: 'CARDS',
                        }),
                        card = res.data.body.results || {};

                    setCardValues((prev) => ({
                        ...prev,
                        alias: card.alias,
                        status: card.status_card,
                        creditLimit: card.credit_limit,
                        fairplayInternId: card.contract_fairplay_intern_id,
                        availableToday: card.available_today,
                        requestedPerPeriod: card.requested_per_period,
                        holdAmount: card.hold_amount,
                    }));
                } catch (e: ErrorObj | any) {
                    setError(e);
                    if (
                        e?.status === 404 &&
                        location.pathname.includes('/app/disbursements/cards/detail/')
                    )
                        push(CARDS_DIRECTORY_ROUTE);
                } finally {
                    setLoading(false);
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany.company?.id, cardId]);

    return { cardValues, error, loading };
};

export default useGetCardDetail;
