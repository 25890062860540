import { FC } from 'react';
import { Grid } from '@mui/material';
import { Avatar, Paper, Typography } from '@fairplay2/ui';
import { GroupedPlatformKey } from '../../interfaces';
import { getPlatformDisplayName } from '../../utils';

interface Props {
    picture: string;
    groupKey: GroupedPlatformKey;
    title?: string;
}

const PlatformCard: FC<Props> = ({ picture, groupKey, title }) => (
    <Paper
        variant="outlined"
        sx={{
            bgcolor: 'background.default',
            width: 214,
            height: '214px !important',
            mb: '25px',
        }}
    >
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
        >
            <Avatar size={98} fallbackTo="company" src={picture} aria-hidden />
            <Typography
                variant="body1"
                color="text.primary"
                fontWeight={500}
                mt="15px"
                data-testid="platform-card-title"
            >
                {title || getPlatformDisplayName(groupKey)}
            </Typography>
        </Grid>
    </Paper>
);

export default PlatformCard;
