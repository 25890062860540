import { numberWithCommas, ChartFactory } from 'utils/formatting';
import { baseReducer } from '../baseReducer';
import { TRADING_WARNING_C } from '../utils/constants';
import { ClvCacAction, ClvCacState, ClvCacChartKeys } from './interfaces';

const initialChartData = {
    newClients: {
        title: 'Clientes nuevos',
        isEmpty: true,
        loading: true,
        options: {
            series: [
                {
                    type: 'spline',
                    color: '#9689F2',
                    name: 'Clientes nuevos',
                    data: [],
                },
            ],
            yTitle: 'Clientes nuevos',
        },
    },
    dailyCAC: {
        title: 'Costo de adquisicion de clientes por mes',
        isEmpty: true,
        loading: true,
        options: {
            series: [
                {
                    type: 'spline',
                    color: '#9689F2',
                    name: 'Costo de adquisición por cliente',
                    data: [],
                },
            ],
            yTitle: 'CAC mensual',
            valuePrefix: '$', // eslint-disable-next-line no-template-curly-in-string
            yLabels: '${value}',
        },
    },
    promClient: {
        title: 'Promedio de ingreso por usuario',
        isEmpty: true,
        loading: true,
        options: {
            series: [
                {
                    type: 'spline',
                    color: '#9689F2',
                    name: 'Promedio de ingreso por usuario',
                    data: [],
                },
            ],
            yTitle: 'Promedio de ingreso por usuario',
            valuePrefix: '$', // eslint-disable-next-line no-template-curly-in-string
            yLabels: '${value}',
        },
    },
};

const initialClvState = {
    item: {},
    title: 'Gasto potencial por cliente',
    symbol: '$',
    isEmpty: true,
    loading: true,
};

export const validateData = (obj: any) => {
    // Modify data to show only 2 decimals and commas
    let isEmpty = false;
    const item = { ...obj };
    if (obj.clvs === 0 || obj.clvs === null) {
        isEmpty = true;
        item.warningC = TRADING_WARNING_C.insufficientData;
    } else {
        item.total = numberWithCommas(obj.clvs);
    }

    return { item, isEmpty };
};

export const getResults = ({
    data,
    name,
    variable,
}: {
    data: any;
    name: 'newClients' | 'dailyCAC' | 'promClient';
    variable: string;
}) => {
    if (data.length > 0) {
        const values = [];
        for (let i = 0; i < data.length; i++) {
            const date = data[i].month.split('T')[0];
            values.push([Date.parse(date), data[i][variable]]);
        }

        return {
            isEmpty: false,
            options: ChartFactory({
                ...initialChartData[name].options,
                series: [
                    {
                        ...initialChartData[name].options.series[0],
                        data: values,
                    },
                ],
            }),
        };
    }
    return { options: {}, isEmpty: true };
};

export const ClvCacInitialState: ClvCacState = {
    chartData: initialChartData,
    clv: initialClvState,
};

export const clvCacReducer = (state: ClvCacState, action: ClvCacAction) => {
    switch (action.type) {
        case 'SET_NEW_CLIENTS':
            return baseReducer<ClvCacChartKeys>(state, {
                type: 'SET_CHART',
                payload: {
                    key: 'newClients',
                    value: getResults({
                        data: action.payload.value,
                        name: 'newClients',
                        variable: 'newClientSum',
                    }),
                },
            }) as ClvCacState;
        case 'SET_DAILY_CAC':
            return baseReducer<ClvCacChartKeys>(state, {
                type: 'SET_CHART',
                payload: {
                    key: 'dailyCAC',
                    value: getResults({
                        data: action.payload.value,
                        name: 'dailyCAC',
                        variable: 'daylyCac',
                    }),
                },
            }) as ClvCacState;
        case 'SET_PROM_CLIENT':
            return baseReducer<ClvCacChartKeys>(state, {
                type: 'SET_CHART',
                payload: {
                    key: 'promClient',
                    value: getResults({
                        data: action.payload.value,
                        name: 'promClient',
                        variable: 'arpus',
                    }),
                },
            }) as ClvCacState;
        case 'SET_CLV': {
            if (action.payload.value.item?.warningC) {
                return {
                    ...state,
                    clv: {
                        ...state.clv,
                        item: action.payload.value.item,
                        isEmpty: action.payload.value.isEmpty,
                        loading: false,
                    },
                } as ClvCacState;
            }
            const clv = validateData(action.payload.value);
            return {
                ...state,
                clv: {
                    ...state.clv,
                    item: clv.item,
                    isEmpty: clv.isEmpty,
                    loading: false,
                },
            } as ClvCacState;
        }
        default:
            return baseReducer(state, action) as ClvCacState;
    }
};
