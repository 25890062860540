import { AxiosRequestConfig } from 'axios';

export const IMAGE_CONFIG = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
};

export const FILE_CONFIG: AxiosRequestConfig = {
    responseType: 'arraybuffer',
};

export const BLOB_FILE_CONFIG: AxiosRequestConfig = {
    headers: {
        Accept: '*/*',
        'Access-Control-Allow-Origin': '*',
        'Cache-Control': 'no-cache',
    },
    responseType: 'blob',
};
