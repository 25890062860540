import { CountryAmazon } from './interfaces';

export const AMAZON_COUNTRIES: CountryAmazon[] = [
    {
        countryCode: 'CA',
        name: 'Canadá',
        region: 'us-east-1',
    },
    {
        countryCode: 'US',
        name: 'Estados Unidos de América',
        region: 'us-east-1',
    },
    {
        countryCode: 'MX',
        name: 'México',
        region: 'us-east-1',
    },
    {
        countryCode: 'BR',
        name: 'Brasil',
        region: 'us-east-1',
    },
    {
        countryCode: 'ES',
        name: 'España',
        region: 'eu-west-1',
    },
    {
        countryCode: 'UK',
        name: 'Reino Unido',
        region: 'eu-west-1',
    },
    {
        countryCode: 'FR',
        name: 'Francia',
        region: 'eu-west-1',
    },
    {
        countryCode: 'BE',
        name: 'Bélgica',
        region: 'eu-west-1',
    },
    {
        countryCode: 'NL',
        name: 'Países Bajos',
        region: 'eu-west-1',
    },
    {
        countryCode: 'DE',
        name: 'Alemania',
        region: 'eu-west-1',
    },
    {
        countryCode: 'IT',
        name: 'Italia',
        region: 'eu-west-1',
    },
    {
        countryCode: 'SE',
        name: 'Suecia',
        region: 'eu-west-1',
    },
    {
        countryCode: 'PL',
        name: 'Polonia',
        region: 'eu-west-1',
    },
    {
        countryCode: 'EG',
        name: 'Egipto',
        region: 'eu-west-1',
    },
    {
        countryCode: 'TR',
        name: 'Turquía',
        region: 'eu-west-1',
    },
    {
        countryCode: 'SA',
        name: 'Arabia Saudita',
        region: 'eu-west-1',
    },
    {
        countryCode: 'AE',
        name: 'Emiratos Árabes Unidos',
        region: 'eu-west-1',
    },
    {
        countryCode: 'IN',
        name: 'India',
        region: 'eu-west-1',
    },
    {
        countryCode: 'SG',
        name: 'Singapur',
        region: 'us-west-2',
    },
    {
        countryCode: 'AU',
        name: 'Australia',
        region: 'us-west-2',
    },
    {
        countryCode: 'JP',
        name: 'Japón',
        region: 'us-west-2',
    },
];
