import { FC, useState, useContext } from 'react';
import { Grid, Collapse } from '@mui/material';
import { Modal, DefaultBtn, InterpunctLoader, Typography, Alert } from '@fairplay2/ui';
import { ManTrashcanSvg, ManTrashcanTwoSvg } from 'assets/SVGs';
import SessionContext from 'context/session/sessionContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import fairplayAPI from 'utils/api';
import { useAlert } from 'utils/hooks';
import { DeleteVendorModalProps } from '../interfaces';

const DeleteVendorModal: FC<DeleteVendorModalProps> = ({
    isModalOpen,
    onModalClose,
    vendorToDelete,
}) => {
    const { selectedCompany } = useContext(SessionContext),
        [step, setStep] = useState(1),
        [loading, setLoading] = useState(false),
        { alert, hideAlert, showAlert } = useAlert();

    const deleteVendor = async () => {
        hideAlert();
        if (vendorToDelete) {
            setLoading(true);
            try {
                await fairplayAPI.delete(
                    `/v1/companies/${selectedCompany.company?.id}/suppliers/${vendorToDelete.id}`,
                );
                setStep(2);
                setLoading(false);
            } catch (errResponse: ErrorObj | any) {
                setLoading(false);
                showAlert(errResponse?.error || 'No se pudo eliminar el proveedor');
            }
        }
    };

    return (
        <Modal
            aria-labelledby="delete-vendor-modal-heading"
            open={isModalOpen}
            onClose={() => onModalClose('deleteVendor')}
            maxWidth={524}
        >
            {step === 1 && (
                <Grid container>
                    <Grid container item xs={12} justifyContent="center">
                        <ManTrashcanSvg />
                    </Grid>
                    <Typography
                        id="delete-vendor-modal-heading"
                        variant="subtitle1"
                        color="primary.main"
                    >
                        ¿Estás seguro de eliminar este proveedor?
                    </Typography>

                    <Grid item marginTop="15px">
                        <Typography color="text.primary" fontWeight={500}>
                            Estás a punto de eliminar a:
                            <br />
                            <Typography component="span" color="primary.dark" fontWeight={700}>
                                {vendorToDelete?.alias}
                            </Typography>{' '}
                            de tu lista de proveedores.
                            <br /> Si lo quieres recuperar deberás volver a agregarlo.
                        </Typography>
                    </Grid>
                    <Grid item container xs={12} justifyContent="center">
                        <Collapse in={alert.open}>
                            <Alert severity={alert.type}>{alert.message}</Alert>
                        </Collapse>
                    </Grid>
                    <Grid container item xs={12} justifyContent="center" marginTop="20px">
                        {loading ? (
                            <InterpunctLoader />
                        ) : (
                            <>
                                <DefaultBtn
                                    size="small"
                                    variant="outlined"
                                    onClick={() => onModalClose('deleteVendor')}
                                    sx={{ mx: '10px', mt: '10px' }}
                                >
                                    Ok, no
                                </DefaultBtn>
                                <DefaultBtn
                                    size="small"
                                    onClick={deleteVendor}
                                    sx={{ mx: '10px', mt: '10px' }}
                                >
                                    Sí, eliminarlo
                                </DefaultBtn>
                            </>
                        )}
                    </Grid>
                </Grid>
            )}
            {step === 2 && (
                <Grid container>
                    <Grid container justifyContent="center">
                        <ManTrashcanTwoSvg />
                    </Grid>
                    <Typography variant="subtitle1" color="primary.main">
                        ¡Se ha eliminado el proveedor!
                    </Typography>

                    <Grid item marginTop="15px">
                        <Typography color="text.primary" fontWeight={500}>
                            Has eliminado exitosamente a:
                            <br />
                            <Typography component="span" color="primary.dark" fontWeight={700}>
                                {vendorToDelete?.alias}
                            </Typography>{' '}
                            de tu lista de proveedores. <br /> Si lo agregas nuevamente deberá ser
                            aprobado.
                        </Typography>
                    </Grid>
                    <Grid container justifyContent="center">
                        <DefaultBtn
                            size="small"
                            onClick={() => onModalClose('deleteVendor', true)}
                            sx={{ mt: '30px' }}
                        >
                            Listo
                        </DefaultBtn>
                    </Grid>
                </Grid>
            )}
        </Modal>
    );
};

export default DeleteVendorModal;
