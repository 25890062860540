import { FC, useState, useContext, ChangeEvent, FocusEvent } from 'react';
import { useTheme, useMediaQuery, Grid, Collapse } from '@mui/material';
import {
    Modal,
    DefaultInput,
    DefaultBtn,
    InterpunctLoader,
    Alert,
    Skeleton,
    Typography,
} from '@fairplay2/ui';
import SessionContext from 'context/session/sessionContext';
import fairplayAPI from 'utils/api';
import { validateClabe } from 'utils/validation';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj, getMainError } from 'utils/error-handler';
import { useAlert } from 'utils/hooks';
import { toFormData } from 'utils/parsing';
import { NewAccountModalProps, NewAccountForm, NewAccountErrors } from '../interfaces';

const NewAccountModal: FC<NewAccountModalProps> = ({ open, onClose, fetchAccounts }) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        { selectedCompany } = useContext(SessionContext),
        [values, setValues] = useState<NewAccountForm>({
            accountHolder: '',
            clabe: '',
            accountNumber: '',
            bank: '',
        }),
        [errors, setErrors] = useState<NewAccountErrors>({
            accountHolder: '',
            clabe: '',
            accountNumber: '',
            bank: '',
        }),
        errorsMsg: NewAccountErrors = {
            accountHolder: 'Ingresa el alias de la cuenta',
            clabe: 'Ingresa la clabe',
        },
        [loading, setLoading] = useState({
            clabe: false,
            submit: false,
        }),
        { alert, showAlert } = useAlert();

    const fetchClabe = async (clabe: string) => {
        setValues({ ...values, bank: '' });
        setLoading({ ...loading, clabe: true });
        try {
            const res: any = await fairplayAPI.get(`/v1/clabe?clabe=${clabe}`, {
                baseService: 'BANK_INFORMATION',
            });
            const accountNum = clabe.slice(6, 17);
            setValues({ ...values, bank: res.data.bank, accountNumber: accountNum });
            setLoading({ ...loading, clabe: false });
        } catch (errResponse: ErrorObj | any) {
            setLoading({ ...loading, clabe: false });
            if (errResponse.error)
                setErrors({
                    ...errors,
                    clabe: getMainError(errResponse.error),
                });
        }
    };

    const allValuesFilled = () => {
        let result = true;
        const newErrors: NewAccountErrors = { ...errors };

        // eslint-disable-next-line no-restricted-syntax
        for (const value in values) {
            if (!values[value]) {
                newErrors[value] = errorsMsg[value];
                result = false;
            }
        }

        setErrors(newErrors);
        return result;
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name } = event.target;
        const { value } = event.target;
        if (name === 'clabe' && !value)
            setValues({ ...values, bank: '', accountNumber: '', [name]: value });
        else setValues({ ...values, [name]: value });
    };

    const onBlur = (event: FocusEvent<HTMLInputElement>) => {
        const { name } = event.target;
        const { value } = event.target;

        if (value) {
            if (name === 'clabe') {
                setValues({ ...values, bank: '', accountNumber: '' });
                const clabeError = validateClabe(value);
                setErrors({ ...errors, clabe: clabeError });
                if (!clabeError) fetchClabe(value);
            } else setErrors({ ...errors, [name]: '' });
        } else setErrors({ ...errors, [name]: errorsMsg[name] });
    };

    const onSubmit = async () => {
        if (allValuesFilled()) {
            setLoading({ ...loading, submit: true });
            const data = toFormData({
                accountHolder: values.accountHolder,
                clabe: values.clabe,
                accountNumber: values.accountNumber,
                bank: values.bank,
            });

            try {
                await fairplayAPI.post(
                    `/v1/companies/${selectedCompany.company?.id}/accounts`,
                    data,
                );
                onClose('newAccount');
                setLoading({ ...loading, submit: false });
                fetchAccounts();
            } catch (errResponse: ErrorObj | any) {
                setLoading({ ...loading, submit: false });
                if (errResponse.type === 'form') {
                    if (errResponse?.error?.accounts)
                        return showAlert(errResponse.error.accounts?.[0]);
                    setErrors((prev) => ({ ...prev, ...errResponse.error }));
                } else showAlert(errResponse?.error);
            }
        }
    };

    return (
        <Modal
            title="Agrega una cuenta bancaria"
            open={open}
            maxWidth={524}
            onClose={() => onClose('newAccount')}
            sx={{ '.MuiDialogContent-root': { paddingTop: 0 } }}
        >
            <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                    <DefaultInput
                        id="accountHolder"
                        name="accountHolder"
                        label="Alias de la cuenta bancaria"
                        labelVariant="external"
                        value={values.accountHolder}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={errors.accountHolder}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Typography component="label" variant="label" htmlFor="clabe">
                            CLABE
                        </Typography>
                    </Grid>
                    {loading.clabe ? (
                        <Grid
                            item
                            xs={12}
                            justifyContent="center"
                            alignItems="center"
                            minHeight={50}
                        >
                            <Skeleton height={53} data-testid="clabe-skeleton" />
                        </Grid>
                    ) : (
                        <DefaultInput
                            id="clabe"
                            name="clabe"
                            value={values.clabe}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={errors.clabe}
                            inputProps={{ maxLength: 18 }}
                        />
                    )}
                </Grid>
                <Grid item xs={12}>
                    <DefaultInput
                        id="accountNumber"
                        label="Número de cuenta"
                        labelVariant="external"
                        disabled
                        value={values.accountNumber}
                        error={errors.accountNumber}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DefaultInput
                        id="bank"
                        label="Banco"
                        labelVariant="external"
                        disabled
                        value={values.bank}
                        error={errors.bank}
                    />
                </Grid>
                <Collapse in={alert.open}>
                    <Grid item container xs={12} marginTop="30px">
                        <Alert severity={alert.type}>{alert.message}</Alert>
                    </Grid>
                </Collapse>
                <Grid item container xs={12} justifyContent="center" marginTop="20px">
                    {loading.submit ? (
                        <InterpunctLoader height={48} />
                    ) : (
                        <>
                            <DefaultBtn
                                fullWidth={smDown}
                                sx={{
                                    mr: { xs: 0, sm: '20px' },
                                }}
                                size="small"
                                variant="outlined"
                                onClick={() => onClose('newAccount')}
                            >
                                Cancelar
                            </DefaultBtn>
                            <DefaultBtn
                                fullWidth={smDown}
                                sx={{
                                    mt: { xs: '20px', sm: 0 },
                                }}
                                size="small"
                                onClick={onSubmit}
                            >
                                Agregar
                            </DefaultBtn>
                        </>
                    )}
                </Grid>
            </Grid>
        </Modal>
    );
};

export default NewAccountModal;
