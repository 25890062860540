import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@fairplay2/ui';
import { BaseModal } from 'common-components';
import { ConnectionErrorSvg } from 'assets/SVGs';
import { ModalWithNamesProps } from '../interfaces';

const ErrorConnectionModal: FC<Omit<ModalWithNamesProps, 'onConfirm'>> = ({
    onCancel,
    open,
    storeNames,
}) => {
    const { push } = useHistory();

    return (
        <BaseModal
            open={open}
            onClose={onCancel}
            onPrimaryAction={() => push('/app/sources')}
            onSecondaryAction={onCancel}
        >
            <BaseModal.Icon>
                <ConnectionErrorSvg />
            </BaseModal.Icon>
            <BaseModal.Title>Se ha producido un error</BaseModal.Title>
            <BaseModal.Content>
                Verifica la conexión de{' '}
                {storeNames.map((store, index) => (
                    <Typography key={store} color="primary.main" component="strong">
                        {store}
                        {index < storeNames.length - 1 ? ', ' : ' '}
                    </Typography>
                ))}
                en la sección <strong>Fuentes de datos &gt; Órdenes.</strong>
            </BaseModal.Content>
            <BaseModal.Actions secondaryLabel="Ignorar" primaryLabel="Verificar" />
        </BaseModal>
    );
};

export default ErrorConnectionModal;
