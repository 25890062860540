export interface Serie {
    color: string;
    type?: string;
    name: string;
    data: any[];
    colors?: string[];
}

export interface ChartFactoryProps {
    series: Serie[];
    xTitle?: string;
    xCategories?: any;
    valuePrefix?: string;
    valueSuffix?: string;
    chartType?: string;
    zoomType?: string;
    yMin?: number;
    yLabels?: any;
    yTitle?: string;
    yPlotLines?: any;
    pointFormat?: string;
    plotOptions?: any;
}

const plot = {
    series: {
        marker: {
            enabled: false,
        },
    },
    pie: {
        animation: {
            duration: 500,
            easing: 'easeOutBounce',
        },
    },
};

// Function to create a chart's option object
export const ChartFactory = ({
    series,
    valuePrefix = '',
    valueSuffix,
    chartType,
    zoomType = 'xy',
    xTitle,
    xCategories,
    yMin,
    yLabels,
    yTitle,
    yPlotLines,
    pointFormat,
    plotOptions = plot,
}: ChartFactoryProps) => {
    const options = {
        chart: {
            ...(chartType && { type: chartType }),
            zoomType,
            style: {
                fontFamily: "'Montserrat', sans-serif",
            },
        },
        title: {
            text: '',
        },
        xAxis: {
            title: {
                text: xTitle || '',
            },
            type: 'datetime',
            ...(xCategories && { categories: xCategories }),
        },
        yAxis: {
            ...(yMin !== undefined && { min: yMin }),
            ...(yLabels && {
                labels: {
                    format: yLabels,
                },
            }),
            title: {
                text: yTitle || '',
            },
            ...(yPlotLines && { plotLines: yPlotLines }),
        },
        plotOptions,
        tooltip: {
            pointFormat,
            ...(pointFormat && { pointFormat }),
            valuePrefix,
            ...(valueSuffix && { valueSuffix }),
            shared: true,
        },
        legend: {
            itemStyle: {
                fontWeight: 'normal',
                fontSize: '14px',
            },
        },
        series,
    };

    return JSON.parse(JSON.stringify(options));
};
