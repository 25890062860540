import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { DefaultBtn, Typography } from '@fairplay2/ui';
import { Grid } from '@mui/material';
import SimpleStepper from 'components/customer-onboarding/components/SimpleStepper';
import { WelcomePageProps } from 'auth/interfaces';

const steps = [
    'Crea tu perfil de administrador',
    'Crea tu perfil empresarial',
    'Proporciona tus datos fiscales',
    'Conecta una fuente de órdenes',
];

const WelcomePage: FC<WelcomePageProps> = ({ returnToLogin }) => {
    const history = useHistory();

    return (
        <Grid item container>
            <Typography
                variant="h4"
                paragraph
                fontWeight={400}
                color="primary.contrastText"
                mt="35px"
                mb={0}
                maxWidth="332px"
            >
                ¡Crear tu cuenta es completamente gratis!
            </Typography>
            <Typography variant="h4" fontWeight={700} color="primary.contrastText" mb="20px">
                Hazlo en 4 pasos:
            </Typography>

            <SimpleStepper steps={steps} showActiveSteps={false} hideOnSmallScreens={false} />

            <Grid item container mt={3} xs={12}>
                <DefaultBtn
                    sx={{
                        width: '260px',
                    }}
                    color="mixed"
                    fullWidth
                    onClick={() => {
                        history.push('/app/onboarding');
                    }}
                >
                    Crear mi cuenta
                </DefaultBtn>
            </Grid>
            <Typography
                variant="body2"
                color="primary.contrastText"
                fontWeight={700}
                mb="20px"
                mt={2}
            >
                ¿Ya tienes una cuenta?{' '}
                <Typography
                    component="span"
                    color="secondary.main"
                    fontWeight={700}
                    onClick={returnToLogin}
                    sx={{
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    }}
                >
                    Inicia sesión aquí
                </Typography>
            </Typography>
        </Grid>
    );
};

export default WelcomePage;
