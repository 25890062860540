import { FC } from 'react';
import { Typography } from '@fairplay2/ui';
import { WomanCelebratingSvg } from 'assets/SVGs';

const SuccessCreate: FC = () => (
    <>
        <WomanCelebratingSvg />
        <Typography variant="subtitle1" color="primary.main" mt="20px">
            ¡Agregaste una cuenta bancaria al proveedor!
        </Typography>
        <Typography variant="subtitle2" color="text.primary" mt="10px">
            El proveedor asociado a esta cuenta recibirá automáticamente las dispersiones
            solicitadas en la cuenta establecida como principal. Puedes cambiar la cuenta principal
            en el botón <Typography color="primary.A400">Administrar cuentas</Typography>.
        </Typography>
    </>
);

export default SuccessCreate;
