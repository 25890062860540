import { FC } from 'react';
import { Grid } from '@mui/material';
import { ModalIconProps } from './interfaces';

const ModalIcon: FC<ModalIconProps> = ({ children }) => (
    <Grid item container justifyContent="center" mb="25px">
        {children}
    </Grid>
);

export default ModalIcon;
