import { FC, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { hotjar } from 'react-hotjar';
import { QueryClientProvider } from 'react-query';
import Routes from 'router/Routes';
import { GlobalStyles } from '@mui/material';
import { FairplayThemeProvider } from '@fairplay2/ui';
import { ReactQueryDevtools } from 'react-query/devtools';
// TODO: Why isn't CRA 4 recognizing the mapped exports?
import AvatarCompanyEmpty from '@fairplay2/ui/assets/svg/graphics/AvatarCompanyEmpty.svg';
import AvatarUserEmpty from '@fairplay2/ui/assets/svg/graphics/AvatarUserEmpty.svg';
import { DatePickerStyles } from '@fairplay2/ui-x-dates';
import SessionProvider from 'context/session/SessionProvider';
import { FileProvider } from 'components/dashboard/disbursment/transactions/context/file-context';
import { ActiveStepProvider } from 'components/customer-onboarding/context/activeStep-context';
import { DataSourcesProvider } from 'components/dashboard/data-sources/context/DataSourcesContext';
import { DisbursementValuesProvider } from 'context/disbursements/DisbursementValuesContext';
import fairplayAPI, { queryClient } from 'utils/api';
import { MaintenancePage } from 'layouts';
import { globalStyles } from './globalStyles';

const App: FC = () => {
    const [maintenance, setMaintenance] = useState<any>({});

    useEffect(() => {
        (async () => {
            const response: any = await fairplayAPI.get(`/v1/common/maintenance`, {
                authorization: false,
            });
            setMaintenance(response.data.body.results);
        })();
        /* istanbul ignore next */
        if (
            process.env.NODE_ENV === 'production' &&
            process.env.REACT_APP_HOTJAR_SITE_ID &&
            process.env.REACT_APP_HOTJAR_SNIPPET_VERSION
        ) {
            hotjar.initialize(
                Number(process.env.REACT_APP_HOTJAR_SITE_ID),
                Number(process.env.REACT_APP_HOTJAR_SNIPPET_VERSION),
            );
        }
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <Router basename={process.env.PUBLIC_URL}>
                <SessionProvider>
                    <DataSourcesProvider>
                        <FairplayThemeProvider
                            fallbackAvatars={{
                                company: AvatarCompanyEmpty,
                                user: AvatarUserEmpty,
                            }}
                        >
                            <GlobalStyles styles={globalStyles} />
                            <DatePickerStyles />
                            <FileProvider>
                                <ActiveStepProvider>
                                    <DisbursementValuesProvider>
                                        {Object.keys(maintenance).length > 0 &&
                                        maintenance.is_active ? (
                                            <MaintenancePage
                                                startDate={maintenance.start_date}
                                                endDate={maintenance.finish_date}
                                            />
                                        ) : (
                                            <Routes />
                                        )}
                                    </DisbursementValuesProvider>
                                </ActiveStepProvider>
                            </FileProvider>
                        </FairplayThemeProvider>
                    </DataSourcesProvider>
                </SessionProvider>
            </Router>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
};

export default App;
