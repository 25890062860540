import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import backgroundImage from 'assets/img/bg_inicio.jpg';

export const Background = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    minHeight: '100vh',
    position: 'relative',
}));

export const SliderContainer = styled(Grid)({
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'contain',
    backgroundPosition: '50% 100%',
});
