import { FC, useContext, useState, useCallback, ChangeEvent } from 'react';
import { Grid, Collapse } from '@mui/material';
import { Typography, TabGroup, Alert, TabData } from '@fairplay2/ui';
import SessionContext from 'context/session/sessionContext';
import AvatarPaper from 'components/dashboard/company/components/AvatarPaper';
import { ReceiptOutlined } from '@mui/icons-material/';
import PlatformActions from 'components/dashboard/data-sources/platforms/components/PlatformActions';
import PlatformCard from 'components/dashboard/data-sources/platforms/components/PlatformCard';
import SatModal from 'components/dashboard/data-sources/platforms/sat/ConnectorForm';
import SATimg from 'assets/img/connectors/SAT.png';
import Disconnect from 'components/dashboard/data-sources/platforms/components/disconnect-item/Disconnect';
import { useAlert } from 'utils/hooks';
import { useDataSources } from '../data-sources/context/DataSourcesContext';
import useDualForm from '../data-sources/platforms/useDualForm';
import { ConnectorData } from '../data-sources/interfaces';
import FiscalTable from './components/InvoicesList/FiscalTable';

const tabsData: TabData[] = [
    {
        label: 'Recibidas',
        icon: <ReceiptOutlined />,
    },
    {
        label: 'Emitidas',
        icon: <ReceiptOutlined />,
    },
];

const FiscalHub: FC = () => {
    const { selectedCompany } = useContext(SessionContext),
        { platforms } = useDataSources(),
        { alert, showAlert } = useAlert(),
        [activeTab, setActiveTab] = useState(0),
        { showForm, connector, onConnect, closeForm, onReauth } = useDualForm(),
        [disconnectTarget, setDisconnectTarget] = useState<ConnectorData | null>(null),
        isActive = platforms?.sat?.connectors?.[0]?.status === 'active';

    const onStepChange = useCallback((event: ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    }, []);

    const closeConnect = () => {
        document.body.style.overflow = 'auto';
        closeForm();
    };

    const closeDisconnect = () => {
        document.body.style.overflow = 'auto';
        setDisconnectTarget(null);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h4" color="primary.main">
                    Facturas{' '}
                    <Typography component="span" color="primary.dark" fontWeight={700}>
                        {selectedCompany.company?.businessName}
                    </Typography>
                </Typography>
            </Grid>

            <AvatarPaper onAlert={showAlert} />
            <Grid container mt="10px">
                <Collapse in={alert.open}>
                    <Alert severity={alert.type}>{alert.message}</Alert>
                </Collapse>
            </Grid>

            {isActive ? (
                <TabGroup
                    TabsProps={{
                        sx: {
                            m: {
                                xs: '30px 0 0',
                                md: '30px 0 0 -20px',
                            },
                        },
                    }}
                    value={activeTab}
                    tabs={tabsData}
                    onChange={onStepChange}
                >
                    <FiscalTable invoiceType="receiver" />
                    <FiscalTable invoiceType="issuer" />
                </TabGroup>
            ) : (
                <Grid container marginTop={5} marginBottom={5}>
                    <Grid
                        item
                        container
                        justifyContent={{ xs: 'center', md: 'flex-start' }}
                        xs={12}
                        md={4}
                        lg={3}
                    >
                        <PlatformCard picture={SATimg} groupKey="sat" />
                    </Grid>
                    <Grid item xs={12} md={8} lg={9} paddingLeft={{ xs: 0, md: '10px' }}>
                        <Typography mb="15px" variant="h4" color="primary.main">
                            No vayas de una plataforma a otra
                        </Typography>
                        <Typography mb="20px" variant="subtitle1" color="primary.dark">
                            Gestiona aquí tu información fiscal ¡Tus datos están protegidos!
                        </Typography>
                        <Typography mb="25px" variant="subtitle2" color="text.primary">
                            <Typography component="span" color="primary.main">
                                En este módulo podrás ver tus Facturas recibidas y Facturas
                                emitidas.
                                <br />
                            </Typography>
                            Además filtrarlas por RFC, periodo de facturación, tipo de pago, monto y
                            estatus de la factura. Agregar tus proveedores no registrados y ver el
                            detalle de tus comprobantes.
                        </Typography>
                        <Grid
                            container
                            spacing={4}
                            justifyContent={{ xs: 'center', md: 'flex-start' }}
                        >
                            <Grid item>
                                <PlatformActions
                                    onDisconnect={setDisconnectTarget}
                                    onConnect={onConnect}
                                    onReauth={onReauth}
                                    platformGroup="sat"
                                >
                                    <SatModal
                                        open={showForm}
                                        onClose={closeConnect}
                                        connector={connector}
                                    />
                                    <Disconnect
                                        platform="sat"
                                        open={!!disconnectTarget}
                                        onCancel={closeDisconnect}
                                        onClose={closeDisconnect}
                                        element={platforms?.sat?.connectors?.[0] || null}
                                    />
                                </PlatformActions>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default FiscalHub;
