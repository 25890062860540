import { useContext } from 'react';
import sessionContext from 'context/session/sessionContext';

export const useOtpStatus = () => {
    const { globalSettings, user } = useContext(sessionContext);

    return {
        isUserOtpVerified: user?.otpVerified,
        isUserOtpEnabled: user?.otpEnabled,
        isOtpRequired: globalSettings?.otpRequired,
        isOtpEnabled: globalSettings?.otpModalEnabled,
    };
};
