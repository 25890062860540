import { FC, lazy, Suspense } from 'react';
import { Skeleton } from '@fairplay2/ui';
import { ChartProps } from './interfaces';

const Chart = lazy(() => import(/* webpackChunkName: 'Chart' */ './Chart'));

const ChartWithFallback: FC<ChartProps> = ({
    options,
    title,
    isEmpty,
    loading,
    warning,
    tooltip,
}) => (
    <Suspense
        fallback={
            <>
                <Skeleton variant="text" height={40} />
                <Skeleton variant="rectangular" height={283} />
            </>
        }
    >
        <Chart
            options={options}
            title={title}
            isEmpty={isEmpty}
            loading={loading}
            warning={warning}
            tooltip={tooltip}
        />
    </Suspense>
);

export default ChartWithFallback;
