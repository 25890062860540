import { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Collapse, DialogTitle, Grid } from '@mui/material';
import { ManParagraphLeavesSvg } from 'assets/SVGs';
import { Modal, DefaultBtn, Typography, InterpunctLoader, Alert } from '@fairplay2/ui';
import { addGoogleTag } from 'utils/google-tag-manager';
import { useDisbursementValuesContext } from 'context/disbursements/DisbursementValuesContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import fairplayAPI from 'utils/api';
import SessionContext from 'context/session/sessionContext';
import { useAlert } from 'utils/hooks/useAlert';
import { ModalProps } from 'utils/interfaces';
import ConfirmCancelDisbursement from '../../create-disbursement/components/modals/ConfirmCancelDisbursement';

const PendingDisbModal: FC<ModalProps> = ({ open, onClose }) => {
    const history = useHistory(),
        { user } = useContext(SessionContext),
        { disbursementLocation, resetDisbursementValues, recoverCartData } =
            useDisbursementValuesContext(),
        [loading, setLoading] = useState(false),
        { alert, hideAlert, showAlert } = useAlert(),
        [showCancelDisbursement, setShowCancelDisbursement] = useState(true);

    const onNewDisbursement = async () => {
        setLoading(true);
        try {
            await fairplayAPI.delete(`/v1/cart/${user?.id}`, { baseService: 'DISBURSEMENT_CART' });
            resetDisbursementValues();
            onClose();
            addGoogleTag('disbursement-new');
        } catch (errResponse: ErrorObj | any) {
            setLoading(false);
            showAlert(
                errResponse?.error || 'Ocurrió un error creando la dispersión, intente más tarde',
            );
        }
    };

    const onRecoverCart = async () => {
        setLoading(true);
        hideAlert();
        try {
            await recoverCartData();
            history.push('/app/disbursements/transactions/create');
        } catch (errResponse: ErrorObj | any) {
            showAlert(
                errResponse?.error || 'Ocurrió un error retomando la dispersión, intente más tarde',
            );
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!open) return;
        setLoading(false);
        return () => {
            setShowCancelDisbursement(true);
            hideAlert();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <Modal
            disableBackdropClick
            open={open}
            maxWidth={680}
            onClose={loading ? undefined : onClose}
        >
            {showCancelDisbursement ? (
                <Grid container>
                    <Grid container item xs={12} justifyContent="center">
                        <ManParagraphLeavesSvg />
                    </Grid>
                    <Grid item xs={12} mt="20px">
                        <Typography
                            variant="subtitle1"
                            color="primary.main"
                            component={DialogTitle}
                            p={0}
                        >
                            Tienes un proceso de solicitud pendiente
                        </Typography>
                        <Typography color="text.primary" mt="15px">
                            Dejaste incompleta una solicitud de{' '}
                            <Typography component="span" color="primary.dark" fontWeight={700}>
                                Dispersión {disbursementLocation}
                            </Typography>
                        </Typography>
                        <Typography color="primary.main" fontWeight={500} mt="20px">
                            Si cancelas la solicitud no se podrá recuperar ¿Quieres retomarla?
                        </Typography>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Collapse in={alert.open}>
                            <Alert severity={alert.type} sx={{ mt: 3 }}>
                                {alert.message}
                            </Alert>
                        </Collapse>
                    </Grid>
                    <Grid container item xs={12} justifyContent="center" mt="20px">
                        {loading ? (
                            <Grid item mx="10px" mt="15px">
                                <InterpunctLoader data-testid="interpunct-loader-pending-disb" />
                            </Grid>
                        ) : (
                            <>
                                <DefaultBtn
                                    size="small"
                                    variant="outlined"
                                    onClick={() => setShowCancelDisbursement(false)}
                                    sx={{ mx: '10px', mt: '10px' }}
                                >
                                    Cancelar solicitud
                                </DefaultBtn>
                                <DefaultBtn
                                    size="small"
                                    onClick={onRecoverCart}
                                    sx={{ mx: '10px', mt: '10px' }}
                                >
                                    Sí, retomarla
                                </DefaultBtn>
                            </>
                        )}
                    </Grid>
                </Grid>
            ) : (
                <ConfirmCancelDisbursement
                    disbursementStep={0}
                    onConfirm={onNewDisbursement}
                    loading={loading}
                    alert={alert}
                />
            )}
        </Modal>
    );
};

export default PendingDisbModal;
