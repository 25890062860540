import { Chip, styled } from '@mui/material';

const FSSChip = styled(Chip)({
    cursor: 'inherit',
    maxWidth: '100%',
    flexDirection: 'row-reverse',
    '.MuiChip-icon': {
        fontSize: '1.2857em',
        marginRight: 12,
        marginLeft: 0,
    },
});

export default FSSChip;
