import fairplayAPI from 'utils/api';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { Gmv, GmvGrowth, GmvPerformance, MinGmv } from './interfaces';

export const getGmv = async (selectedContractId: string): Promise<Gmv[]> => {
    try {
        const response: any = await fairplayAPI.get(
            `/v1/reports/projections/performance_time/graph?contract_id=${selectedContractId}`,
        );
        return response?.data?.body?.results;
    } catch (errResponse: ErrorObj | any) {
        return Promise.reject(errResponse?.error || 'Ocurrió un error, intente más tarde');
    }
};

export const getMinGmv = async (selectedContractId: string): Promise<MinGmv> => {
    try {
        const response: any = await fairplayAPI.get(
            `/v1/reports/projections/mingmv?contract_id=${selectedContractId}`,
        );
        return response?.data?.body?.results;
    } catch (errResponse: ErrorObj | any) {
        return Promise.reject(errResponse?.error || 'Ocurrió un error, intente más tarde');
    }
};

// export const getProjectedGmv = async (selectedContractId: any): Promise<any> => {
//     try {
//         const response: any = await fairplayAPI.get(
//             `/v1/reports/projections/performance_time?contract_id=${selectedContractId}`,
//         );
//         return response?.data?.body?.results
//     } catch (errResponse: ErrorObj | any) {
//         return Promise.reject(errResponse?.error || 'Ocurrió un error, intente más tarde');
//     }
// };

// export const getExpectedGrowth = async (selectedContractId: any): Promise<any> => {
//     try {
//         const response: any = await fairplayAPI.get(
//             `/v1/reports/projections/performance_time?contract_id=${selectedContractId}`,
//         );
//         return response?.data?.body?.results
//     } catch (errResponse: ErrorObj | any) {
//         return Promise.reject(errResponse?.error || 'Ocurrió un error, intente más tarde');
//     }
// };

export const getGmvPerformance = async (selectedContractId: string): Promise<GmvPerformance> => {
    try {
        const response: any = await fairplayAPI.get(
            `/v1/reports/projections/performance_gmv?contract_id=${selectedContractId}`,
        );
        return response?.data?.body?.results;
    } catch (errResponse: ErrorObj | any) {
        return Promise.reject(errResponse?.error || 'Ocurrió un error, intente más tarde');
    }
};

export const getGmvGrowth = async (selectedContractId: string): Promise<GmvGrowth> => {
    try {
        const response: any = await fairplayAPI.get(
            `/v1/reports/projections/real_gmv?contract_id=${selectedContractId}`,
        );
        return response?.data?.body?.results;
    } catch (errResponse: ErrorObj | any) {
        return Promise.reject(errResponse?.error || 'Ocurrió un error, intente más tarde');
    }
};
