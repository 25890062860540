import { useState, FocusEvent } from 'react';
import { DefaultBtn, InterpunctLoader, Modal, Typography, Alert } from '@fairplay2/ui';
import { Collapse, Grid } from '@mui/material';
import { DisbursementsSvg } from 'assets/SVGs';
import { OTP } from 'common-components';
import { ConfirmCancelMultipleDisbursementsProps } from '../../interfaces';

export const ConfirmCancelMultipleDisbursements = ({
    open,
    onClose,
    loading,
    onConfirm,
    error,
    setValue,
    value,
}: ConfirmCancelMultipleDisbursementsProps) => {
    const [otpError, setOtpError] = useState('');

    const onBlur = (ev: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (value || ev.target.value) return setOtpError('');
        setOtpError('El código es requerido.');
    };

    const onConfirmModal = () => {
        setOtpError('');

        if (value.length === 0) return setOtpError('El código es requerido.');
        if (value.length !== 6) return setOtpError('El código debe tener 6 dígitos.');

        onConfirm?.();
    };

    return (
        <Modal
            open={open}
            maxWidth={583}
            onClose={() => {
                onClose?.();
                setOtpError('');
            }}
        >
            <Grid container>
                <Grid container item xs={12} justifyContent="center">
                    <DisbursementsSvg />
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="subtitle1"
                        color="primary.dark"
                        fontWeight={700}
                        mt="20px"
                        lineHeight={1.5}
                    >
                        Para autorizar tus dispersiones abre tu aplicación de autenticación, genera
                        un código de un solo uso e introdúcelo a continuación:
                    </Typography>
                </Grid>
                <Grid item xs={12} mt="5px">
                    <Grid item container xs={12} mt="20px" justifyContent="center">
                        <OTP value={value} setValue={setValue} error={otpError} onBlur={onBlur} />
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" mt={error ? '15px' : 0}>
                    <Collapse in={!!error}>
                        <Alert severity="error" aria-label="Error realizando la dispersión">
                            {error}
                        </Alert>
                    </Collapse>
                </Grid>
                <Grid container item xs={12} justifyContent="center" mt="15px">
                    {loading ? (
                        <Grid item mt="15px">
                            <InterpunctLoader />
                        </Grid>
                    ) : (
                        <>
                            <DefaultBtn
                                size="small"
                                variant="outlined"
                                onClick={onClose}
                                sx={{ mx: '10px', mt: '10px' }}
                            >
                                Regresar
                            </DefaultBtn>
                            <DefaultBtn
                                size="small"
                                onClick={onConfirmModal}
                                sx={{ mx: '10px', mt: '10px' }}
                            >
                                Autorizar
                            </DefaultBtn>
                        </>
                    )}
                </Grid>
            </Grid>
        </Modal>
    );
};
