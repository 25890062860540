import {
    ConnectorData,
    DataSourcePlatform,
    GroupedPlatformKey,
    RawPlatformKey,
} from '../interfaces';
import { DataSourcesState, PartialConnectorData } from './interfaces';

/**
 *
 * @param {*} initialSet Object containing entries in the form {platformName: connectors},
 *                  representing any previously modified platforms outside of these
 *                  methods.
 * @returns
 */
export const modifyLocalDataSources = (initialSet = {} as DataSourcesState['raw']) => {
    let stagedChanges: typeof initialSet | undefined = { ...initialSet };

    const getCurrentValueOf = (platform: RawPlatformKey) =>
        stagedChanges ? stagedChanges[platform] || initialSet[platform] : undefined;

    return {
        /**
         *  Returns all the staged changes.
         *  After this method is called, no additional changes can
         *  be performed by this object.
         */
        FINISH() {
            // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/naming-convention
            const _staged = { ...stagedChanges } as typeof initialSet;
            stagedChanges = undefined;
            return _staged;
        },
        ADD(connector: ConnectorData) {
            const currentValue = getCurrentValueOf(connector.platform);

            if (
                !currentValue ||
                currentValue.some((item: ConnectorData) => item.id === connector.id)
            )
                return;

            // eslint-disable-next-line no-param-reassign
            (stagedChanges as typeof initialSet)[connector.platform as RawPlatformKey] = [
                ...currentValue,
                connector,
            ];
        },
        UPDATE(connector: PartialConnectorData) {
            const currentValue = getCurrentValueOf(connector.platform);
            if (!currentValue?.length) return;

            // eslint-disable-next-line no-param-reassign
            (stagedChanges as typeof initialSet)[connector.platform as RawPlatformKey] =
                currentValue.map((item: ConnectorData) => {
                    if (connector.id !== item.id) return item;
                    return {
                        ...item,
                        ...connector,
                    };
                });
        },
        REMOVE(connector: PartialConnectorData) {
            const currentValue = getCurrentValueOf(connector.platform);
            if (!currentValue?.length) return;

            const resultingArray = currentValue.filter(
                (item: ConnectorData) => connector.id !== item.id,
            );

            if (resultingArray.length !== currentValue.length) {
                // eslint-disable-next-line no-param-reassign
                (stagedChanges as typeof initialSet)[connector.platform as RawPlatformKey] =
                    resultingArray;
            }
        },
    };
};

/**
 * Marks the raw data sources set as invalid but keeps it as
 * a fallback mechanism in case an error occurs.
 */
export const invalidateRawSet = (state: DataSourcesState): DataSourcesState => ({
    ...state,
    operationQueue: [],
    // Using an array to invalidate current state but
    //  keep it in case the update fails
    raw: [state.raw] as any,
});

/**
 * Returns formatted platform name, simplified for app usages.
 *
 * @param rawKey Original platform name as provided by backend.
 * @returns Array containing [groupKey, displayName].
 *          `groupedKey` is an internal id to simplify operations,
 *          `displayName` is the string that will be displayed to the user.
 */
export const groupPlatformKey = (rawKey: RawPlatformKey): GroupedPlatformKey => {
    switch (rawKey) {
        case 'tienda-nube':
            return 'tiendanube';
        case 'fads':
        case 'fads-v2':
            return 'facebook-ads';
        case 'google-analytics-v4':
        case 'woocommerce-v2':
        case 'magento-v2':
        case 'google-ads-v2':
            return rawKey.split('-').slice(0, -1).join('-') as GroupedPlatformKey;
        case 's3connector':
        case 's3-manual-upload':
            return 's3';
        default:
            return rawKey;
    }
};

/**
 *
 * @returns `true` if editable, `false` if readOnly,
 *          `null` if connector does not have FSS
 */
export const isFSSEditable = (connector: ConnectorData) => {
    if (
        connector.status !== 'active' ||
        !connector.fss ||
        ![
            'shopify',
            'magento-v2',
            'woocommerce-v2',
            // ,'prestashop'
            'amazon',
            'vtex',
            'mercado-libre',
            'tienda-nube',
            'walmart',
            // 'liverpool',
        ].includes(connector.platform)
    )
        return null;
    if (
        connector.fss ===
        'El conector se encuentra en proceso de recolección de datos, intenta mas tarde!'
    )
        return false;
    if (typeof connector.fss === 'string' || connector.fss.has_new_status === undefined)
        return null;

    return connector.fss.has_new_status;
};

/**
 * Transforms a set of connectors to provide FSS-related data.
 *
 * @param seed               FSS data from other platforms in the same group.
 * @param rawConnectors      Connectors object provided by backend API.
 * @param groupedPlatformKey Platform group, for internal management.
 * @returns                  Transformed connectors set.
 */
export const getFSSData = (
    seed: DataSourcePlatform['fss'],
    rawConnectors: ConnectorData[],
    groupedPlatformKey: GroupedPlatformKey,
): DataSourcePlatform['fss'] => {
    let fssData: DataSourcePlatform['fss'] = seed;

    // eslint-disable-next-line no-restricted-syntax
    for (const connector of rawConnectors) {
        const canEditFSS = isFSSEditable(connector);

        if (fssData) {
            // Make sure that connectors that previously
            //  qualified for any of the two sets are reseted
            //  and only re-added if they still meet the conditions
            fssData.editable.delete(connector.id);
            fssData.readOnly.delete(connector.id);
            if (fssData.defaultConnectorId === connector.id) fssData.defaultConnectorId = '';
        }

        if (canEditFSS === null) continue;

        if (!fssData)
            fssData = {
                defaultConnectorId: '',
                editable: new Map<string, ConnectorData & { platformGroup: GroupedPlatformKey }>(),
                readOnly: new Map<string, ConnectorData & { platformGroup: GroupedPlatformKey }>(),
            };

        const alteredConnector: ConnectorData & { platformGroup: GroupedPlatformKey } = {
            ...connector,
            platformGroup: groupedPlatformKey,
        };

        if (canEditFSS) {
            if (!fssData.defaultConnectorId) fssData.defaultConnectorId = connector.id;
            fssData.editable.set(connector.id, alteredConnector);
        } else fssData.readOnly.set(connector.id, alteredConnector);
    }

    return fssData;
};

export const INITIAL_FORMATTED_DATA_SOURCES: Record<
    GroupedPlatformKey,
    DataSourcePlatform | undefined
> = {
    magento: undefined,
    shopify: undefined,
    walmart: undefined,
    // liverpool: undefined,
    tiendanube: undefined,
    amazon: undefined,
    'google-ads': undefined,
    'google-analytics': undefined,
    'facebook-ads': undefined,
    sat: undefined,
    // prestashop: undefined,
    paypal: undefined,
    stripe: undefined,
    clip: undefined,
    vtex: undefined,
    'mercado-libre': undefined,
    woocommerce: undefined,
    s3: undefined,
};

export const getFormattedDataSources = (
    rawData: Partial<Record<RawPlatformKey, ConnectorData[]>>,
) =>
    Object.entries(rawData || {}).reduce(
        (acc, [rawKey, connectors] = ['', []]) => {
            const groupedKey = groupPlatformKey(rawKey as RawPlatformKey);
            if (!Object.keys(acc).includes(groupedKey)) return acc;

            acc[groupedKey] = {
                reauth: connectors.reduce((reauth, connector) => {
                    if (connector.status === 'reauthorize') reauth.push(connector);
                    return reauth;
                }, acc[groupedKey]?.reauth || []),
                grouped: [...(acc[groupedKey]?.grouped || []), rawKey as RawPlatformKey],
                connectors: [...(acc[groupedKey]?.connectors || []), ...connectors],
                fss: getFSSData(acc[groupedKey]?.fss || null, connectors, groupedKey),
            };

            return acc;
        },
        { ...INITIAL_FORMATTED_DATA_SOURCES },
    );

/**
 * Returns first connector with highest priority status.
 *
 * @param connnectors        Array with multiple connectors.
 * @param priority           Array of statuses where the first position represents
 *                           the highest priority and the last position represents
 *                           the lowest.
 * @param platform           String to rename the connectors platform.
 * @returns                  An array containing a single connector.
 *                           Empty array if no matches are found.
 *
 */
export const getPriorityConnector = (
    connectors: ConnectorData[],
    priority: string[],
    platform?: RawPlatformKey,
) => {
    const priorityConnector = priority.reduce<ConnectorData | undefined>((result, status) => {
        if (result) return result;

        const connector = connectors.find((item) => item?.status === status);

        return connector && platform ? { ...connector, platform } : connector;
    }, undefined);

    return priorityConnector ? [priorityConnector] : [];
};
