import { FC } from 'react';
import { Typography } from '@fairplay2/ui';
import { BaseModal } from 'common-components';
import { CartSvg } from 'assets/SVGs';
import { ModalWithNamesProps } from '../interfaces';

const AlmostReadyConnectionModal: FC<ModalWithNamesProps> = ({ onConfirm, open, storeNames }) => (
    <BaseModal open={open} onClose={onConfirm} onPrimaryAction={onConfirm}>
        <BaseModal.Icon>
            <CartSvg />
        </BaseModal.Icon>
        <BaseModal.Title>¡Casi listo! Identifica tus tiendas.</BaseModal.Title>
        <BaseModal.Content>
            Procesamos con éxito los datos de&nbsp;
            {storeNames.map((store, index) => (
                <Typography key={store} color="primary.main" component="strong">
                    {store}
                    {index < storeNames.length - 1 ? ', ' : '. '}
                </Typography>
            ))}
            Asignamos un <strong>alias</strong> default a cada tienda, te recomendamos
            personalizarlo para que las identifiques fácilmente dentro de Insights.
        </BaseModal.Content>
        <BaseModal.Actions primaryLabel="Entendido" />
    </BaseModal>
);

export default AlmostReadyConnectionModal;
