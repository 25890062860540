import { Typography } from '@fairplay2/ui';
import { Grid, useTheme, useMediaQuery } from '@mui/material';
import { SuccessSvg } from 'assets/SVGs';

export const Success = () => {
    const theme = useTheme(),
        mdDown = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid container>
            <Grid item xs={12}>
                <SuccessSvg width={mdDown ? '82px' : '125px'} />
            </Grid>
            <Grid item xs={12} mt={2}>
                <Typography variant="subtitle2" fontWeight={700} color="primary.dark">
                    ¡Listo! Tu aplicación de autenticación se vinculó correctamente.
                </Typography>
                <Typography variant="subtitle2" fontWeight={500}>
                    Revisa el estatus de tu usuario en el listado de usuarios registrados.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Success;
