import { FC } from 'react';
import { Grid } from '@mui/material';
import { DefaultBtn, Typography } from '@fairplay2/ui';
import { ManShowingLaptopSvg } from 'assets/SVGs';
import { ChangePasswordConfirmationProps } from '../interfaces';

const Success: FC<ChangePasswordConfirmationProps> = ({ onClose }) => (
    <Grid container direction="column" rowSpacing={4} alignContent="center">
        <Grid item xs={12} marginTop="25px" textAlign="center">
            <ManShowingLaptopSvg />
        </Grid>

        <Grid item xs={12} maxWidth={502}>
            <Typography
                variant="subtitle1"
                component="h4"
                color="primary.main"
                lineHeight={1.19}
                mb="15px"
            >
                ¡Cambio de contraseña exitoso!
            </Typography>
            <Typography
                variant="subtitle2"
                component="p"
                color="text.primary"
                fontWeight={500}
                maxWidth={441}
                lineHeight={1.19}
            >
                La próxima vez que inicies sesión en la plataforma de Fairplay, recuerda utilizar la
                nueva contraseña establecida.
            </Typography>
        </Grid>

        <Grid item xs={12} textAlign="center" marginBottom="20px">
            <DefaultBtn size="small" onClick={onClose}>
                Entendido
            </DefaultBtn>
        </Grid>
    </Grid>
);

export default Success;
