import { FC, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Collapse, Grid, useMediaQuery, useTheme } from '@mui/material';
import { AccessTime } from '@mui/icons-material';
import {
    Modal,
    DefaultBtn,
    Typography,
    DefaultInput,
    Alert,
    InterpunctLoader,
} from '@fairplay2/ui';
import { useAlert } from 'utils/hooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { CancelledCardSvg } from 'assets/SVGs';
import fairplayAPI from 'utils/api';
import { toFormData } from 'utils/parsing';
import { useIsAdmin } from '../hooks';
import { CardModalProps } from '../interfaces';

export const CancelCardModal: FC<CardModalProps> = ({
    open,
    cardId,
    alias,
    onClose,
    selectedContractId,
}) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        {
            handleSubmit,
            formState: { errors, isValid },
            register,
            reset,
            watch,
        } = useForm<{ cancellationReason: string }>({
            mode: 'onChange',
            values: {
                cancellationReason: '',
            },
        }),
        { cancellationReason } = watch(),
        [loading, setLoading] = useState(false),
        [isCancelled, setIsCancelled] = useState(false),
        { alert, hideAlert, showAlert } = useAlert(),
        isAdmin = useIsAdmin();

    const onSubmit: SubmitHandler<{ cancellationReason: string }> = async (_) => {
        if (!isValid) return;
        hideAlert();
        setLoading(true);
        try {
            const formData = toFormData({
                contract_id: selectedContractId,
                card_id: cardId!,
                request_type: 'cancel-card',
                cancel_reason: cancellationReason,
            });
            const res: any = await fairplayAPI.post('/cards/v1/card/create-request', formData, {
                    baseService: 'CARDS',
                }),
                isAdminRes = res.data.body.results?.status_request.id === 'cancellation_approved';

            setIsCancelled(true);
            showAlert(
                isAdminRes ? 'La tarjeta se ha cancelado' : 'La solicitud ha sido enviada',
                isAdminRes ? 'success' : 'info',
            );
        } catch (errResponse: ErrorObj | any) {
            showAlert(
                errResponse?.error ||
                    'No se pudo cancelar la tarjeta, por favor vuelve a intentarlo.',
            );
        } finally {
            setLoading(false);
        }
    };

    useEffect(
        () => () => {
            if (process.env.NODE_ENV === 'test') return;
            // In order to restart the state after close the modal
            setTimeout(() => {
                reset({});
                setIsCancelled(false);
                hideAlert();
            }, 300);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <Modal
            aria-labelledby="cancel-card"
            disableBackdropClick
            disableEscapeKeyDown
            open={open}
            onClose={() => onClose(isCancelled)}
            maxWidth={670}
        >
            <Grid container>
                <Grid container justifyContent="center">
                    <CancelledCardSvg />
                </Grid>
                <Typography variant="subtitle1" color="primary.main" mt="25px" lineHeight={1.4}>
                    ¿Estás seguro de que quieres cancelar la tarjeta{' '}
                    <Typography component="span" color="primary.dark" fontWeight={700}>
                        {alias}
                    </Typography>
                    ?
                </Typography>

                <Typography variant="subtitle2" color="text.primary" fontWeight={500} mt="15px">
                    {isAdmin
                        ? 'Verifica el estatus en el listado de Tarjetas.'
                        : 'Recuerda que esta solicitud deberá ser aprobada por un Administrador autorizado. Verifica el estatus en el listado de Tarjetas.'}
                </Typography>

                <Typography
                    variant="subtitle2"
                    color="primary.dark"
                    component="label"
                    htmlFor="cancellation-reason"
                    fontWeight={700}
                    mt="15px"
                    mb="10px"
                    width="100%"
                    id="cancel-card"
                >
                    ¿Por qué quieres cancelar la tarjeta?
                </Typography>

                <Grid
                    item
                    component="form"
                    aria-labelledby="cancel-card"
                    onSubmit={handleSubmit(onSubmit)}
                    xs={12}
                >
                    <Grid item xs={12}>
                        <DefaultInput
                            id="cancellation-reason"
                            type="text"
                            placeholder="Explica el motivo de cancelación"
                            error={errors.cancellationReason?.message}
                            disabled={loading || isCancelled}
                            {...register('cancellationReason', {
                                required: 'Ingresa el motivo de cancelación',
                            })}
                        />
                    </Grid>

                    <Grid item container xs={12} justifyContent="flex-start">
                        <Collapse in={alert.open}>
                            <Alert
                                severity={alert.type}
                                sx={{ mt: '30px' }}
                                iconMapping={{ info: <AccessTime /> }}
                            >
                                {alert.message}
                            </Alert>
                        </Collapse>
                    </Grid>

                    <Grid container justifyContent="center" mt="30px">
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {loading ? (
                            <InterpunctLoader />
                        ) : isCancelled ? (
                            <Grid container justifyContent="center">
                                <DefaultBtn
                                    size="small"
                                    onClick={() => onClose(true)}
                                    fullWidth={smDown}
                                >
                                    Entendido
                                </DefaultBtn>
                            </Grid>
                        ) : (
                            <>
                                <DefaultBtn
                                    variant="outlined"
                                    size="small"
                                    onClick={() => onClose()}
                                    fullWidth={smDown}
                                    sx={{
                                        mr: { xs: 0, sm: '25px' },
                                        mb: { xs: '25px', sm: 0 },
                                    }}
                                >
                                    Conservar tarjeta
                                </DefaultBtn>
                                <DefaultBtn
                                    size="small"
                                    type="submit"
                                    fullWidth={smDown}
                                    disabled={!isValid}
                                >
                                    Cancelar tarjeta
                                </DefaultBtn>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default CancelCardModal;
