import { FC, FormEvent, useEffect, useState, ChangeEvent } from 'react';
import {
    Modal,
    Alert,
    InterpunctLoader,
    DefaultBtn,
    PasswordInput,
    Typography,
} from '@fairplay2/ui';
import { Grid, Collapse, useMediaQuery, useTheme } from '@mui/material';
import { ManSittingPlanSvg } from 'assets/SVGs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import fairplayAPI from 'utils/api';
import { toFormData } from 'utils/parsing';
import {
    ChangePasswordInputContainer,
    ConfirmPasswordInput,
    PasswordButtonsContainer,
} from '../../styles';
import { ChangePasswordForm, ChangePasswordErrors, ChangePasswordProps } from '../interfaces';
import Success from './Confirmation';

const ChangePassword: FC<ChangePasswordProps> = ({ open, onClose }) => {
    const [confirmation, setConfirmation] = useState(false),
        [loading, setLoading] = useState(false),
        [values, setValues] = useState<ChangePasswordForm>({
            password: '',
            password2: '',
            oldPassword: '',
        }),
        [errors, setErrors] = useState<ChangePasswordErrors>({
            password: '',
            password2: '',
            oldPassword: '',
            other: '',
        }),
        mdUp = useMediaQuery(useTheme().breakpoints.up('md'));

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (errors.other) setErrors({ ...errors, other: '' });
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    const onChangeInput = (prop: keyof ChangePasswordForm, errorMsg: string) => {
        if ((prop === 'password' || prop === 'password2') && values[prop].length < 8) {
            setErrors({
                ...errors,
                [prop]: 'La contraseña debe contener al menos 8 caracteres',
            });
            return;
        }
        if (values[prop]) {
            setErrors({ ...errors, [prop]: '' });
            return;
        }

        setErrors({ ...errors, [prop]: errorMsg });
    };

    const clearForm = () => {
        setValues({ ...values, password: '', password2: '', oldPassword: '' });
        setErrors({ password: '', password2: '', oldPassword: '', other: '' });
    };

    const closeModal = () => {
        clearForm();
        onClose();
    };

    const onSubmitForm = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (values.password !== values.password2) {
            setErrors({ ...errors, password2: 'La contraseña no coincide' });
            return;
        }

        setErrors({ ...errors, password2: '' });
        // Check if there isn't an error
        if (!Object.values(errors).some((x) => x !== null && x !== '')) {
            setLoading(true);
            const data = toFormData({
                password: values.password,
                password2: values.password2,
                oldPassword: values.oldPassword,
            });

            try {
                await fairplayAPI.put('/v1/users/reset_password/change', data);
                setConfirmation(true);
                clearForm();
            } catch (errResponse: ErrorObj | any) {
                if (errResponse?.type === 'form')
                    setErrors((prev) => ({ ...prev, ...errResponse.error }));
                else if (errResponse?.error) setErrors({ ...errors, other: errResponse.error });
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (!open && confirmation) {
            setTimeout(() => setConfirmation(false), 200);
        }
    }, [open, confirmation]);

    return (
        <Modal
            open={open}
            onClose={closeModal}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth={648}
            fullWidth
        >
            {confirmation ? (
                <Success onClose={closeModal} />
            ) : (
                <Grid
                    container
                    rowSpacing={4}
                    direction="column"
                    component="form"
                    id="change-password"
                    onSubmit={onSubmitForm}
                >
                    <Typography variant="subtitle1" color="primary.main" mt="28px">
                        Cambia tu contraseña
                    </Typography>

                    <Grid item container>
                        <Grid item container xs={12} md={6} direction="column">
                            <ChangePasswordInputContainer item>
                                <PasswordInput
                                    id="password" // Necessary due to Material UI not linking helper text
                                    name="password"
                                    label="Nueva contraseña"
                                    value={values.password}
                                    forceHiddenText={loading}
                                    required
                                    InputLabelProps={{
                                        required: false,
                                    }}
                                    onChange={onChange}
                                    onBlur={() =>
                                        onChangeInput('password', 'Ingresa la contraseña')
                                    }
                                    placeholder="Nueva contraseña"
                                    error={errors.password}
                                />
                            </ChangePasswordInputContainer>
                            <ChangePasswordInputContainer item>
                                <PasswordInput
                                    id="password2" // Necessary due to Material UI not linking helper text
                                    name="password2"
                                    label="Confirmar contraseña"
                                    value={values.password2}
                                    forceHiddenText={loading}
                                    required
                                    InputLabelProps={{
                                        required: false,
                                    }}
                                    onChange={onChange}
                                    onBlur={() =>
                                        onChangeInput(
                                            'password2',
                                            'Ingresa la contraseña nuevamente',
                                        )
                                    }
                                    placeholder="Confirmar contraseña"
                                    error={errors.password2}
                                />
                            </ChangePasswordInputContainer>
                        </Grid>
                        {mdUp && (
                            <Grid item md={6} textAlign="center">
                                <ManSittingPlanSvg />
                            </Grid>
                        )}
                    </Grid>

                    <Grid container item xs={12} paddingBottom={0} direction="column">
                        <Typography variant="subtitle1" color="primary.main" gutterBottom>
                            Confirma tu identidad
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            component="p"
                            color="text.primary"
                            fontWeight={500}
                            lineHeight={1.1875}
                            mt="25px"
                            mb="6px"
                        >
                            Por favor ingresa tu actual contraseña para que podamos confirmar que
                            deseas realizar el cambio.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} pt={0}>
                        <Collapse in={errors.other !== ''}>
                            <Alert
                                severity="error"
                                sx={{
                                    mt: '15px',
                                }}
                            >
                                {errors.other}
                            </Alert>
                        </Collapse>
                    </Grid>

                    <Grid item container xs={12} alignItems="center" columnSpacing={2}>
                        <Grid item xs={12} md={6}>
                            <ConfirmPasswordInput
                                id="oldPassword" // Necessary due to Material UI not linking helper text
                                name="oldPassword"
                                label="Contraseña actual"
                                value={values.oldPassword}
                                forceHiddenText={loading}
                                required
                                InputLabelProps={{
                                    required: false,
                                }}
                                onChange={onChange}
                                onBlur={() =>
                                    onChangeInput('oldPassword', 'Ingresa la contraseña actual')
                                }
                                placeholder="Contraseña actual"
                                error={errors.oldPassword}
                            />
                        </Grid>
                        <PasswordButtonsContainer item container xs={12} md={6}>
                            {loading ? (
                                <InterpunctLoader />
                            ) : (
                                <>
                                    <DefaultBtn
                                        variant="outlined"
                                        size="small"
                                        onClick={closeModal}
                                    >
                                        Cancelar
                                    </DefaultBtn>
                                    <DefaultBtn size="small" type="submit">
                                        Enviar
                                    </DefaultBtn>
                                </>
                            )}
                        </PasswordButtonsContainer>
                    </Grid>
                </Grid>
            )}
        </Modal>
    );
};

export default ChangePassword;
