import { FC, ChangeEvent, FocusEvent, useState, FormEvent } from 'react';
import { Grid, Collapse, useTheme, useMediaQuery } from '@mui/material';
import {
    Modal,
    Typography,
    DefaultBtn,
    DefaultInput,
    PasswordInput,
    InterpunctLoader,
    Alert,
} from '@fairplay2/ui';
import fairplayAPI from 'utils/api';
import { useAlert } from 'utils/hooks';
import { toFormData } from 'utils/parsing';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj, simplifyFormErrors } from 'utils/error-handler';
import { ConnectorProps, WalmartForm, WalmartErrors } from '../interfaces';
import Confirmation from '../components/Confirmation';
import { useDataSources } from '../../context/DataSourcesContext';
import ConnectorFormTitle from '../components/ConnectorFormTitle';

const EMPTY_VALUE_ERRORS: WalmartErrors = {
        clientId: 'Ingresa el client ID',
        clientSecret: 'Ingresa el client secret',
    },
    DEFAULT_ERROR_VALUES: WalmartErrors = {
        clientId: '',
        clientSecret: '',
    },
    DEFAULT_FORM_VALUES: WalmartForm = {
        clientId: '',
        clientSecret: '',
        showPassword: false,
    };

export const WalmartModal: FC<ConnectorProps> = ({ open, connector, onClose }) => {
    const { dispatch, baseAPI } = useDataSources(),
        theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        [values, setValues] = useState<WalmartForm>(DEFAULT_FORM_VALUES),
        [errors, setErrors] = useState<WalmartErrors>(DEFAULT_ERROR_VALUES),
        { alert, hideAlert, showAlert } = useAlert(),
        [confirmation, setConfirmation] = useState(false),
        [loading, setLoading] = useState(false);

    const onChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
        hideAlert();
        setValues((prev) => ({ ...prev, [name]: value }));
    };

    const onInputError = (e: FocusEvent<HTMLInputElement>) => {
        const name = e.target.name as keyof WalmartErrors;
        setErrors((prevState) => ({
            ...prevState,
            [name]: values[name] ? '' : EMPTY_VALUE_ERRORS[name],
        }));
    };

    const clearForm = () => {
        setValues(DEFAULT_FORM_VALUES);
        setErrors(DEFAULT_ERROR_VALUES);
        hideAlert();
    };

    const onConnect = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        hideAlert();

        if (!Object.values(errors).some((x) => x !== null && x !== '')) {
            setLoading(true);
            const data = toFormData({
                    platform: 'walmart',
                    client_id: values.clientId,
                    client_secret: values.clientSecret,
                    service_name: 'Walmart Marketplace',
                    market: 'MX',
                }),
                endpoint = `${baseAPI}${connector ? `/${connector.id}/reconnect` : ''}`;

            try {
                const res: any = await fairplayAPI.post(endpoint, data);
                setConfirmation(true);
                dispatch({
                    type: connector ? 'UPDATE' : 'ADD',
                    payload: res.data.body.results,
                });
            } catch (errResponse: ErrorObj | any) {
                if (errResponse.type === 'form') {
                    if (errResponse.error?.credentials_error)
                        showAlert(errResponse.error.credentials_error);
                    else
                        setErrors((prev) => ({
                            ...prev,
                            ...simplifyFormErrors(errResponse.error, true),
                        }));
                } else {
                    showAlert(
                        errResponse?.error ||
                            'No se pudo realizar la conexión, verifique sus credenciales e intente nuevamente',
                    );
                }
            } finally {
                setLoading(false);
            }
        }
    };

    const closeModal = () => {
        clearForm();
        setConfirmation(false);
        onClose();
    };

    return (
        <Modal
            disableBackdropClick
            open={open}
            onClose={closeModal}
            maxWidth={640}
            disableEscapeKeyDown
            title={!confirmation && <ConnectorFormTitle groupKey="walmart" connector={connector} />}
            sx={{
                '.MuiDialogContent-root': {
                    pt: 0,
                },
            }}
        >
            {confirmation ? (
                <Confirmation item="Walmart" onClose={closeModal} />
            ) : (
                <Grid container spacing={4} component="form" onSubmit={onConnect}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" color="text.primary" mb="20px">
                            Para que podamos conectar tu tienda con nuestra plataforma, es necesario
                            que nos proporciones la siguiente información. Consulta nuestra guía{' '}
                            <a
                                target="_blank"
                                href="https://guides.getfairplay.com/es-es/knowledgebase/article/KA-01044"
                                rel="noopener noreferrer"
                                style={{ color: '#405BDD' }}
                            >
                                <strong>aquí</strong>
                            </a>
                            .
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <DefaultInput
                            id="walmart-client-id"
                            name="clientId"
                            labelVariant="external"
                            label="Client ID"
                            type="text"
                            value={values.clientId}
                            onChange={onChange}
                            onBlur={onInputError}
                            error={errors.clientId}
                            required
                            InputLabelProps={{ required: false }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <PasswordInput
                            id="walmart-client-secret"
                            name="clientSecret"
                            labelVariant="external"
                            label="Client Secret"
                            forceHiddenText={loading}
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.clientSecret}
                            onChange={onChange}
                            onBlur={onInputError}
                            error={errors.clientSecret}
                            required
                            InputLabelProps={{ required: false }}
                            titleAccess={{
                                show: 'Mostrar Client secret',
                                hide: 'Ocultar Client secret',
                            }}
                        />
                    </Grid>

                    <Grid item container xs={12} justifyContent="right" paddingTop="0 !important">
                        <Collapse in={alert.open}>
                            <Grid container item xs={12}>
                                <Alert severity={alert.type} sx={{ mt: 3, mb: -2 }}>
                                    {alert.message}
                                </Alert>
                            </Grid>
                        </Collapse>
                    </Grid>

                    <Grid
                        item
                        container
                        xs={12}
                        justifyContent={{ xs: 'center', sm: 'flex-end' }}
                        marginTop={1.25}
                    >
                        {loading ? (
                            <InterpunctLoader />
                        ) : (
                            <>
                                <DefaultBtn
                                    fullWidth={smDown}
                                    size="small"
                                    variant="outlined"
                                    onClick={closeModal}
                                >
                                    Cancelar
                                </DefaultBtn>
                                <DefaultBtn
                                    fullWidth={smDown}
                                    size="small"
                                    type="submit"
                                    sx={{ ml: { xs: 0, sm: '20px' }, mt: { xs: '20px', sm: 0 } }}
                                >
                                    Conectar
                                </DefaultBtn>
                            </>
                        )}
                    </Grid>
                </Grid>
            )}
        </Modal>
    );
};

export default WalmartModal;
