import { useCallback, useRef, useState } from 'react';
import { AlertProps, AlertType } from 'utils/interfaces';

export const useAlert = () => {
    const [alert, setAlert] = useState<AlertProps>({
            message: '',
            open: false,
            type: 'error',
        }),
        alertTimeout = useRef<ReturnType<typeof setTimeout>>();

    const clearCurrentTimeout = () => {
        if (alertTimeout.current) {
            clearTimeout(alertTimeout.current);
            alertTimeout.current = undefined;
        }
    };

    const hideAlert = useCallback(() => {
        clearCurrentTimeout();
        setAlert((prev) => (prev.open ? { ...prev, open: false } : prev));
    }, []);

    const showAlert = useCallback(
        (msg: string, type: AlertType = 'error', autoHide = true) => {
            clearCurrentTimeout();
            setAlert({
                message: msg,
                type,
                open: true,
            });

            if (autoHide) {
                const globalTimeout = Number(process.env.REACT_APP_ALERT_TIMEOUT);
                alertTimeout.current = setTimeout(
                    hideAlert,
                    globalTimeout || globalTimeout === 0 ? globalTimeout : 7000,
                );
            }
        },
        [hideAlert],
    );

    return {
        alert,
        showAlert,
        hideAlert,
    };
};
