import { FC } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { ManSittingPlanSvg } from 'assets/SVGs';
import { Modal, DefaultBtn, Typography } from '@fairplay2/ui';
import { ModalProps } from 'utils/interfaces';

const LegalRepresentativeConfirmationModal: FC<ModalProps> = ({ open, onClose, onConfirm }) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        mdDown = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Modal
            disableBackdropClick
            disableEscapeKeyDown
            open={open}
            onClose={onClose}
            closeIconButton={false}
            maxWidth={583}
            sx={{
                '.MuiDialog-paper': {
                    minHeight: '454px',
                    p: 5,
                },
                '.MuiDialogContent-root': {
                    padding: '20px 24px',
                },
            }}
        >
            <Grid container>
                <Grid item container xs={12} justifyContent="center">
                    <ManSittingPlanSvg />
                </Grid>
                <Grid item xs={12} marginTop={4}>
                    <Typography color="text.primary" fontWeight={500}>
                        ¿Eres el{' '}
                        <Typography color="primary.dark" component="span" fontWeight={700}>
                            representante legal de la empresa
                        </Typography>
                        ?
                    </Typography>
                </Grid>
                <Grid item xs={12} marginTop={2}>
                    <Typography color="text.primary" fontWeight={400}>
                        Esta información es importante en caso de querer acceder a un
                        financiamiento.
                    </Typography>
                </Grid>
                <Grid item container justifyContent="center" xs={12} marginTop={3} gap={2}>
                    <DefaultBtn
                        size="small"
                        variant="outlined"
                        onClick={onClose}
                        fullWidth={mdDown}
                        sx={{
                            '&.MuiButton-root': {
                                minWidth: { md: '83px' },
                            },
                        }}
                    >
                        No
                    </DefaultBtn>

                    <DefaultBtn size="small" onClick={onConfirm} fullWidth={mdDown}>
                        {smDown ? 'Sí' : 'Soy el representante legal'}
                    </DefaultBtn>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default LegalRepresentativeConfirmationModal;
