import { FC, lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { QueryStatsRounded, HistoryRounded, LineAxisRounded } from '@mui/icons-material';
import { Typography, Skeleton } from '@fairplay2/ui';
import { CardKpi, IconText } from 'common-components';
import { useProjectionsQueries } from '../../hooks';

const Chart = lazy(
    () =>
        import(
            // eslint-disable-next-line import/extensions
            /* webpackChunkName: 'Chart' */ 'common-components/data-indicators/chart/Chart.component'
        ),
);

const Projections: FC = () => {
    const { id: selectedContractId }: any = useParams(),
        theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        mdDown = useMediaQuery(theme.breakpoints.down('md')),
        { datapointsRequest, performanceRequest, growthRequest } = useProjectionsQueries(
            selectedContractId,
            theme,
        );

    return (
        <Grid container mb="51px" mt={1} spacing={3}>
            <Grid item xs={12}>
                <Typography variant="subtitle1" color="primary.main">
                    Métricas de desempeño de tu contrato
                </Typography>
            </Grid>
            <Grid item container spacing={5} xs={12} direction={{ xs: 'row', sm: 'row-reverse' }}>
                <Grid item container xs={12} md={6} lg={5} xl={4} spacing={2} direction="column">
                    {/* <Grid item>
                        <Typography color="primary.main" variant="subtitle2">
                            Tu crecimiento al: <b>28/11/2022</b>
                        </Typography>
                    </Grid>
                    <Grid item flexGrow={1}>
                        <CardKpi
                            titleProps={{
                                children: 'Crecimiento esperado',
                                icon: <InsightsRounded color="secondary" />,
                            }}
                            growthIndicatorProps={growthIndicatorsProps.expectedGrowth}
                            status={growthIndicatorsStatus.expectedGrowth}
                            reloadConfig={
                                errors.expectedGrowth
                                    ? { onReload: reloadExpectedGrowth }
                                    : undefined
                            }
                            helperText="Es el crecimiento mensual promedio esperado de tus ventas sin considerar la estacionalidad."
                            loading={!!loading.expectedGrowth}
                        />
                    </Grid> */}
                    {!performanceRequest.isError && (
                        <Grid item flexGrow={1}>
                            {performanceRequest.data.previousMonth ? (
                                <Typography color="primary.main" variant="subtitle2" component="h3">
                                    Indicadores de: <b>{performanceRequest.data.previousMonth}</b>
                                </Typography>
                            ) : (
                                <Skeleton width="50%" height="25px" />
                            )}
                        </Grid>
                    )}
                    <Grid item>
                        <CardKpi
                            data-testid="gmv-performance-indicator"
                            titleProps={{
                                children: 'Desempeño GMV',
                                icon: <QueryStatsRounded color="secondary" />,
                            }}
                            growthIndicatorProps={performanceRequest.data?.props}
                            status={performanceRequest.data?.status}
                            reloadConfig={
                                performanceRequest.isError
                                    ? { onReload: performanceRequest.refetch }
                                    : undefined
                            }
                            helperText="Es el desempeño de tus ventas del mes anterior respecto a tus ventas mínimas mensuales pactadas en tu contrato."
                            loading={!!performanceRequest.isFetching}
                        />
                    </Grid>
                    <Grid item flexGrow={1}>
                        <CardKpi
                            data-testid="gmv-growth-indicator"
                            titleProps={{
                                children: 'Crecimiento GMV',
                                icon: <LineAxisRounded color="secondary" />,
                            }}
                            growthIndicatorProps={growthRequest.data?.props}
                            reloadConfig={
                                growthRequest.isError
                                    ? { onReload: growthRequest.refetch }
                                    : undefined
                            }
                            helperText="Es el crecimiento de tus ventas respecto al mismo periodo del año anterior."
                            loading={!!growthRequest.isFetching}
                        >
                            <IconText
                                iconVariant="outlined"
                                icon={<HistoryRounded color="secondary" />}
                                variant="body2"
                                fontWeight={400}
                            >
                                Vs. Mismo mes del año anterior
                            </IconText>
                        </CardKpi>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={7} xl={8}>
                    <Suspense fallback={<Skeleton variant="rectangular" height={347} />}>
                        <Chart
                            options={datapointsRequest.data}
                            // eslint-disable-next-line no-nested-ternary
                            height={smDown ? '400px' : mdDown ? '500px' : '100%'}
                            isEmpty={false}
                            loading={!!datapointsRequest.isFetching}
                            reloadConfig={
                                datapointsRequest.isError
                                    ? { onReload: datapointsRequest.refetch }
                                    : undefined
                            }
                        />
                    </Suspense>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Projections;
