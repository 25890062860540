import { FC, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Collapse } from '@mui/material';
import { Typography, Alert, DefaultBtn } from '@fairplay2/ui';
import fairplayAPI from 'utils/api';
import { useDisbursementValuesContext } from 'context/disbursements/DisbursementValuesContext';
import SessionContext from 'context/session/sessionContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { PhonelinkSetup } from '@mui/icons-material';
import { useOtpStatus } from 'utils/hooks';
import { DisbursementProps, ContractMetadata } from '../interfaces';
import { formatPeriodsData, canContractDisburse, getContractInfo } from '../../utils';
import DisbursementsTable from './components/DisbursementsTable';
import DisbursementOptions from './components/DisbursementOptions';
import SelectContract from './components/SelectContract';

const Disbursement: FC<DisbursementProps> = ({
    contracts,
    selectedContract,
    alert,
    hideAlert,
    showAlert,
    onContractSelect,
}) => {
    const history = useHistory(),
        { setEnableDisbursementsV2Module } = useDisbursementValuesContext(),
        { errors } = useContext(SessionContext),
        { isOtpRequired, isUserOtpVerified } = useOtpStatus(),
        [contractInfo, setContractInfo] = useState<ContractMetadata | null | undefined>(undefined),
        effectiveAlert = errors.messages.cart
            ? { open: true, message: errors.messages.cart, type: 'error' as 'error' }
            : alert,
        { canDisburse, alertMessage } = canContractDisburse(
            selectedContract,
            contractInfo,
            'disbursements',
        ),
        canUserDisburse = !isOtpRequired || !!isUserOtpVerified;

    useEffect(() => {
        const fetchData = async () => {
            // TODO: Delete line below when disbursement V1 is deprecated
            setEnableDisbursementsV2Module(true);
            setContractInfo(undefined);
            hideAlert();

            try {
                const periodsRes: any = await fairplayAPI.get(
                        `/v3/disbursement-periods?contract_id=${selectedContract.id}`,
                        {
                            baseService: 'PERIODS_LOANBOOK',
                        },
                    ),
                    { currentPeriod } = formatPeriodsData(periodsRes.data.body.results);

                // Avoid call the available endpoint if the contract has no periods
                if (currentPeriod === null)
                    return setContractInfo(getContractInfo(selectedContract, currentPeriod, null));

                const availableRes: any = await fairplayAPI.get(
                        `/v1/${selectedContract.id}/available?start_date=${currentPeriod.start_date}`,
                        {
                            baseService: 'AVAILABLE_LOANBOOK',
                        },
                    ),
                    {
                        body: { results: availableResults },
                    } = availableRes.data;

                setContractInfo(getContractInfo(selectedContract, currentPeriod, availableResults));
            } catch (errResponse: ErrorObj | any) {
                showAlert(errResponse?.error || 'Ocurrió un error, intente de nuevo más tarde.');
                setContractInfo(null);
            }
        };

        if (!selectedContract?.disbursementAllowed || selectedContract.status?.id === 'suspended') {
            // when the selected contract does not allow disbursement
            // this simulates a request to the backend to show the skeleton in SelectContract.tsx
            setContractInfo(undefined);
            setTimeout(() => {
                setContractInfo(null);
            }, 500);
            return;
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contracts, selectedContract]);

    return (
        <Grid container data-testid="disbursements-v2">
            <Grid item xs={12} mb="20px" textAlign="left">
                <Typography variant="h4" color="primary.main" mt="10px">
                    Solicita una{' '}
                    <Typography component="span" color="primary.dark" fontWeight={700}>
                        dispersión nueva
                    </Typography>
                </Typography>
            </Grid>
            {/* TODO: extract OTP-related code to a smaller component */}
            {isOtpRequired && (
                <Grid item container xs={12} mb="15px" spacing={1} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Alert
                            aria-label="Aviso de OTP"
                            severity="warning"
                            sx={{ maxWidth: '568px' }}
                        >
                            {isUserOtpVerified
                                ? 'A partir de ahora, usa tu aplicación de autenticación para solicitar dispersiones'
                                : 'Para realizar dispersiones es necesario que cuentes con tu aplicación de autenticación vinculada.'}
                        </Alert>
                    </Grid>
                    {!isUserOtpVerified && (
                        <Grid item xs={12} md={6}>
                            <DefaultBtn
                                variant="text"
                                onClick={() => {
                                    history.push('/app/company/security');
                                }}
                            >
                                <PhonelinkSetup sx={{ mr: 1 }} />
                                Vincular mi aplicación de autenticación
                            </DefaultBtn>
                        </Grid>
                    )}
                </Grid>
            )}

            <SelectContract
                contracts={contracts}
                selectedContractId={selectedContract.id}
                onContractSelect={onContractSelect}
                contractInfo={contractInfo}
                title="Selecciona el contrato para dispersar o para consultar su historial"
                canContractDisburse={canDisburse && canUserDisburse}
                alertMessage={alertMessage}
            />

            <DisbursementOptions
                hideAlert={hideAlert}
                showAlert={showAlert}
                selectedContract={selectedContract}
                loading={contractInfo === undefined}
                canContractDisburse={canDisburse && canUserDisburse}
            />

            <Grid item container xs={12} justifyContent="flex-end">
                <Collapse in={effectiveAlert.open}>
                    <Alert severity={effectiveAlert.type} sx={{ mt: 1 }}>
                        {effectiveAlert.message}
                    </Alert>
                </Collapse>
            </Grid>

            <Grid item xs={12} marginTop="30px">
                <Typography variant="subtitle2" color="primary.main">
                    Consulta el historial y estatus de las dispersiones solicitadas
                </Typography>
            </Grid>

            <DisbursementsTable selectedContract={selectedContract} />
        </Grid>
    );
};

export default Disbursement;
