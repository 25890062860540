import { useState, FC, FocusEvent, ChangeEvent, FormEvent, useEffect } from 'react';
import { Grid, Collapse, MenuItem } from '@mui/material';
import { PhoneAndroidOutlined, MailOutlined } from '@mui/icons-material';
import {
    Typography,
    Alert,
    InterpunctLoader,
    DefaultInput,
    Checkbox,
    Select,
    PasswordInput,
    ProfileData,
} from '@fairplay2/ui';
import { ReCaptcha } from 'common-components';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { validateTel } from 'utils/validation';
import fairplayAPI, { setSession } from 'utils/api';
import { useAlert, useRecaptcha } from 'utils/hooks';
import { toFormData } from 'utils/parsing';
import {
    UserCardContainer,
    UserCard,
    UserCardSvg,
    OnboardingFormContainer,
    CreateAccountButton,
    OnboardingFormItem,
} from '../../styles';
import { CreateUserProps, CreateUserForm, CreateUserFormError } from '../../interfaces';

export const DEFAULT_COMPANY_NAME = 'una organización';

const ONBLUR_EMPTY_ERRORS: CreateUserFormError = {
    firstName: 'Ingresa tu nombre',
    lastName: 'Ingresa tu apellido',
    email: '',
    password: 'Ingresa la contraseña',
    password2: 'Ingresa la contraseña nuevamente',
    phone: 'Ingresa tu número Telefónico',
    companyPosition: 'Ingresa tu puesto dentro de la empresa',
};

const CreateUser: FC<CreateUserProps> = ({
    onNext,
    token,
    companyName = DEFAULT_COMPANY_NAME,
    email,
}) => {
    const [loading, setLoading] = useState(false),
        [checked, setChecked] = useState(false),
        [avatarFile, setAvatarFile] = useState(''),
        { alert, hideAlert, showAlert } = useAlert(),
        { onLoadRecaptcha, resetRecaptcha, recaptchaIsLoading, recaptchaRef } = useRecaptcha(),
        [companyPositions, setCompanyPositions] = useState([]),
        [values, setValues] = useState<CreateUserForm>({
            firstName: '',
            lastName: '',
            email,
            password: '',
            password2: '',
            phone: '',
            companyPosition: '',
        }),
        [errors, setErrors] = useState<CreateUserFormError>({
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            password2: '',
            phone: '',
            companyPosition: '',
        });

    const setFormErrors = (key: keyof CreateUserForm, data: string) => {
        setErrors((prev) => ({ ...prev, [`${key}`]: data }));
    };

    const onChangeImage = (event: any) => {
        setAvatarFile(event.target.files[0]);
    };

    const onCheck = (event: ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const onBlur = (event: FocusEvent<HTMLInputElement>) => {
        if (values[event.target.name as keyof CreateUserForm]) {
            switch (event.target.name) {
                case 'phone': {
                    const phoneError = validateTel(values.phone);
                    setFormErrors('phone', phoneError);
                    break;
                }
                case 'password':
                    if (values.password.length < 8)
                        setFormErrors(
                            'password',
                            'La contraseña debe contener al menos 8 caracteres',
                        );
                    else setFormErrors('password', '');
                    break;
                case 'companyPosition':
                    if (!values.companyPosition)
                        setFormErrors('companyPosition', ONBLUR_EMPTY_ERRORS.companyPosition);
                    else setFormErrors('companyPosition', '');
                    break;
                default:
                    setErrors((prev) => ({ ...prev, [event.target.name]: '' }));
                    break;
            }
        } else {
            setErrors((prev) => ({
                ...prev,
                [event.target.name]:
                    ONBLUR_EMPTY_ERRORS[event.target.name as keyof CreateUserFormError],
            }));
        }
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        const recaptchaValue = recaptchaRef.current?.getValue();

        e.preventDefault();
        setErrors({ ...errors, password2: '' });
        hideAlert();

        if (!values.companyPosition)
            return setErrors({ ...errors, companyPosition: ONBLUR_EMPTY_ERRORS.companyPosition });
        if (!recaptchaValue) return showAlert('Es necesario completar el reCAPTCHA');

        let validForm = true;

        // Check if passwords are the same
        if (values.password !== values.password2) {
            setErrors({ ...errors, password2: 'La contraseña no coincide' });
            validForm = false;
        }
        // Check terms and conditions
        if (!checked) {
            showAlert('Es necesario aceptar los Términos y condiciones.');
            validForm = false;
        }
        if (!validForm) return;
        // Check if there isn't an error
        if (!Object.values(errors).some((x) => x !== null && x !== '')) {
            setLoading(true);
            const data = toFormData({
                ...values,
                g_recaptcha_response: recaptchaValue,
                token,
            });

            if (avatarFile) data.append('avatar', avatarFile);

            try {
                const res: any = await fairplayAPI.post('/v1/users/register', data, {
                    authorization: false,
                });
                setSession(res.data.body.results.token);

                try {
                    await fairplayAPI.post('/v1/users/terms', { accepted: 'True' });
                    onNext(values.firstName);
                } catch {
                    showAlert('No se registraron los términos y condiciones');
                }
            } catch (errResponse: ErrorObj | any) {
                if (errResponse.type === 'form')
                    setErrors((prev) => ({ ...prev, ...errResponse.error }));
                else showAlert(errResponse.error);
            } finally {
                setLoading(false);
                resetRecaptcha();
            }
        }
    };

    const onSelect = (event: ChangeEvent<HTMLInputElement>) => {
        hideAlert();
        if (errors.companyPosition) setFormErrors('companyPosition', '');
        setValues((prev) => ({ ...prev, companyPosition: event.target.value }));
    };

    useEffect(() => {
        (async () => {
            try {
                const res: any = await fairplayAPI.get('/v2/company-positions', {
                    authorization: false,
                });
                setCompanyPositions(res.data.body.results);
            } catch (errResponse: ErrorObj | any) {
                showAlert(errResponse?.error);
            }
        })();
    }, [showAlert]);

    return (
        <Grid p={['55px 0', '55px 30px', '55px 0', '45px 80px 0px']}>
            <Typography variant="h3" component="h1" color="secondary.main">
                Hola,{' '}
                <Typography color="primary.dark" component="span">
                    bienvenido
                </Typography>
            </Typography>
            <Typography
                variant="body1"
                mt="10px"
                mb="36px"
                lineHeight={1.71}
                fontWeight={700}
                maxWidth="630px"
                color="primary.dark"
            >
                ¡Te invitaron a ser un Fairplayer dentro de{' '}
                <Typography
                    color={companyName === DEFAULT_COMPANY_NAME ? 'inherit' : 'primary.main'}
                    component="span"
                >
                    {companyName}
                </Typography>
                ! Por favor, completa los siguientes campos para crear tu cuenta y tener acceso
                instantáneo a la plataforma.
            </Typography>
            <UserCardContainer>
                <UserCard>
                    <ProfileData
                        src={avatarFile ? URL.createObjectURL(avatarFile as any) : ''}
                        name={`${values.firstName || 'Nombre (s)'} ${
                            values.lastName || 'Apellidos'
                        }`}
                        legend="Agrega una foto de perfil"
                        size="lg"
                        nameEllipsis={false}
                        AvatarInputProps={{ onChange: onChangeImage }}
                    />
                </UserCard>
                <UserCardSvg />
            </UserCardContainer>
            <Grid
                container
                id="register-user"
                spacing={2}
                direction="column"
                alignItems="flex-end"
                component="form"
                onSubmit={onSubmit}
            >
                <OnboardingFormContainer container columnSpacing={8} rowSpacing={2.4}>
                    <OnboardingFormItem item xs={12} md={6}>
                        <DefaultInput
                            label="Nombre (s)"
                            name="firstName"
                            type="text"
                            placeholder="Nombre (s)"
                            value={values.firstName}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={errors.firstName ? errors.firstName : ''}
                            required
                        />
                    </OnboardingFormItem>
                    <OnboardingFormItem item xs={12} md={6}>
                        <DefaultInput
                            label="Apellido (s)"
                            name="lastName"
                            type="text"
                            placeholder="Apellido (s)"
                            value={values.lastName}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={errors.lastName ? errors.lastName : ''}
                            required
                        />
                    </OnboardingFormItem>
                    <OnboardingFormItem item xs={12} md={6}>
                        <DefaultInput
                            label="Número de teléfono"
                            name="phone"
                            type="phone"
                            placeholder="Número de teléfono"
                            value={values.phone}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={errors.phone ? errors.phone : ''}
                            required
                            startAdornment={<PhoneAndroidOutlined />}
                        />
                    </OnboardingFormItem>
                    <OnboardingFormItem item xs={12} md={6}>
                        <DefaultInput
                            label="Email"
                            name="email"
                            type="email"
                            placeholder="Email"
                            value={values.email}
                            sx={{
                                fontSize: '16px',
                            }}
                            disabled
                            startAdornment={<MailOutlined />}
                        />
                    </OnboardingFormItem>
                    <OnboardingFormItem item xs={12} md={6}>
                        <PasswordInput
                            label="Contraseña"
                            name="password"
                            value={values.password}
                            placeholder="Contraseña"
                            required
                            onChange={onChange}
                            onBlur={onBlur}
                            error={errors.password ? errors.password : ''}
                            sx={{
                                fontSize: '16px',
                            }}
                            forceHiddenText={loading}
                        />
                    </OnboardingFormItem>

                    <OnboardingFormItem item xs={12} md={6}>
                        <PasswordInput
                            label="Confirmar contraseña"
                            name="password2"
                            placeholder="Confirmar contraseña"
                            value={values.password2}
                            required
                            onChange={onChange}
                            onBlur={onBlur}
                            error={errors.password2 ? errors.password2 : ''}
                            sx={{
                                fontSize: '16px',
                            }}
                            forceHiddenText={loading}
                        />
                    </OnboardingFormItem>
                    <OnboardingFormItem item xs={12} md={6}>
                        <Select
                            label="Puesto dentro de la empresa"
                            name="companyPosition"
                            error={errors.companyPosition}
                            disabled={!companyPositions.length}
                            value={values.companyPosition}
                            onChange={onSelect}
                            onBlur={onBlur}
                        >
                            <MenuItem value="" disabled>
                                Puesto dentro de la empresa
                            </MenuItem>
                            {companyPositions.length > 0 &&
                                companyPositions.map((option: any) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </OnboardingFormItem>
                </OnboardingFormContainer>

                <Grid item container xs={12} sm={12} wrap="nowrap" alignItems="flex-start">
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <Checkbox checked={checked} onChange={onCheck} />
                    <Grid
                        item
                        marginLeft={1}
                        sx={{
                            '& a': {
                                color: 'inherit',
                                textDecoration: 'none',
                            },
                        }}
                    >
                        <Typography variant="body1" color="text.primary" mt="2px">
                            Confirmo que entiendo y acepto los{' '}
                            <Typography component="span" color="primary.main">
                                <a
                                    target="_blank"
                                    href="https://getfairplay.com/terminos-y-condiciones/"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecoration: 'underline',
                                    }}
                                >
                                    Términos y condiciones
                                </a>{' '}
                            </Typography>
                            de Fairplay Insights, así como el{' '}
                            <Typography component="span" color="primary.main">
                                <a
                                    target="_blank"
                                    href="https://getfairplay.com/aviso-de-privacidad/"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecoration: 'underline',
                                    }}
                                >
                                    Aviso de privacidad
                                </a>{' '}
                            </Typography>
                            y cláusula de medios electrónicos tales como NIP.
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item container xs={12} md={11} justifyContent="flex-end" mt="5px">
                    <Grid
                        item
                        container
                        justifyContent={['center', 'flex-end']}
                        mb={alert.open ? '' : '20px'}
                    >
                        <ReCaptcha
                            ref={recaptchaRef}
                            isLoading={recaptchaIsLoading}
                            onLoad={onLoadRecaptcha}
                        />
                    </Grid>
                    <Collapse in={alert.open}>
                        <Grid item xs={12} my={alert.open ? '20px' : ''} width="300px">
                            <Alert variant="filled" severity={alert.type}>
                                {alert.message}
                            </Alert>
                        </Grid>
                    </Collapse>
                </Grid>

                {loading ? (
                    <Grid item mt={{ md: 1.6 }}>
                        <InterpunctLoader />
                    </Grid>
                ) : (
                    <CreateAccountButton
                        disabled={loading || !checked}
                        color="primary"
                        type="submit"
                    >
                        Crear cuenta
                    </CreateAccountButton>
                )}
            </Grid>
        </Grid>
    );
};

export default CreateUser;
