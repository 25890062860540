import { FC } from 'react';
import { Grid } from '@mui/material';
import { Typography } from '@fairplay2/ui';
import { ModalContentProps } from './interfaces';

const ModalContent: FC<ModalContentProps> = ({ children, ...props }) => (
    <Grid
        item
        container
        component={Typography}
        color="text.primary"
        display="block"
        fontWeight={500}
        variant="subtitle2"
        mb="30px"
        {...props}
    >
        {children}
    </Grid>
);

export default ModalContent;
