import { Grid, StepConnector, StepLabel, StepLabelProps, Stepper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DefaultBtn, Paper, Typography } from '@fairplay2/ui';
import { PaperProps } from '@fairplay2/ui/components/paper/Paper.types';
import { WomanGearAvatar } from 'assets/SVGs';
import { RightColumnContainerProps } from '../interfaces';

export const CustomConnector = styled(StepConnector)(({ theme }) => ({
    '&.MuiStepConnector-alternativeLabel': {
        top: 10,
        left: 'calc(-50% + 12px)',
        right: 'calc(50% + 12px)',
    },
    '& .MuiStepConnector-line': {
        borderColor: theme.palette.secondary.main,
        borderTopWidth: 2,
        borderRadius: 1,
        width: '100%',
    },
}));

export const MobileCircleStep = styled('div')(({ theme }) => ({
    width: 20,
    height: 20,
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%',
    position: 'relative',
    padding: 0,
    '&:after': {
        transform: 'translate(-50%, -50%)',
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '50%',
        position: 'absolute',
        content: '""',
        height: '60%',
        width: '60%',
        left: '50%',
        top: '50%',
    },
}));

export const MobileInactiveCircleStep = styled('div')(({ theme }) => ({
    width: 20,
    height: 20,
    padding: 0,
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%',
    position: 'relative',
}));

export const UserCardContainer = styled('div')(({ theme }) => ({
    position: 'relative',
    [theme.breakpoints.up('md')]: {
        width: 'calc(100% - 65px)',
        minWidth: 400,
    },
}));

export const UserCard = styled(Paper)<PaperProps>(({ theme }) => ({
    padding: '20px 25px',
    backgroundColor: theme.palette.background.dark,
    borderColor: 'auxiliary.light',
    boxShadow: 'none',
    [theme.breakpoints.up('md')]: {
        paddingRight: 110,
    },
}));

export const UserCardSvg = styled(WomanGearAvatar)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up('md')]: {
        display: 'block',
        height: 'auto',
        position: 'absolute',
        right: -90,
        top: -20,
        width: 188,
    },
}));

export const OnboardingFormContainer = styled(Grid)(({ theme }) => ({
    width: `calc(100% + ${theme.spacing(6)})`,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
        position: 'relative',
        marginBottom: theme.spacing(1),
        '&::before': {
            content: "' '",
            display: 'block',
            height: `calc(100% - ${theme.spacing(6)})`,
            width: 1,
            backgroundColor: theme.palette.primary.light,
            position: 'absolute',
            left: `calc(50% + ${theme.spacing(4)})`,
            top: theme.spacing(4),
            opacity: 0.3,
        },
    },
}));

export const OnboardingFormItem = styled(Grid)(({ theme }) => ({
    paddingTop: theme.spacing(4),
}));

export const CreateAccountButton = styled(DefaultBtn)(({ theme }) => ({
    width: '100%',
    minWidth: 315,
    [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(1.6),
        maxWidth: 294,
    },
}));

export const Terms = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        fontSize: 12,
    },
    '& a': {
        color: 'inherit',
        textDecoration: 'none',
    },
}));

export const LoginButton = styled(DefaultBtn)(({ theme }) => ({
    marginTop: theme.spacing(4),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(1.6),
        maxWidth: 294,
    },
}));

export const HideGridOnSm = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        display: 'none',
    },
}));

export const BiColumnContainer = styled(Grid)(({ theme }) => ({
    position: 'relative',
    minHeight: '100%',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
    },
}));

export const LeftColumnContainer = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    padding: 16,
    paddingBottom: 20,
    [theme.breakpoints.up('sm')]: {
        padding: 40,
    },
    [theme.breakpoints.up('md')]: {
        position: 'relative',
        paddingBottom: 200,
    },
}));

export const RightColumnContainer = styled(Grid, {
    shouldForwardProp: (prop) => prop !== 'activeStep',
})<RightColumnContainerProps>(({ theme, activeStep, style }) => ({
    backgroundColor: theme.palette.background.dark,
    overflow: 'auto',
    padding: activeStep === 3 ? '65px 80px' : '45px 80px 40px',
    [theme.breakpoints.down('md')]: {
        flexGrow: 1,
        flexWrap: 'nowrap',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        padding: '0 30px 175px',
    },
    [theme.breakpoints.down('sm')]: {
        paddingBottom: '130px',
    },
    ...style,
}));

export const MobileStepper = styled(Stepper)({
    '& .MuiStepLabel-iconContainer': {
        padding: 0,
    },
});

export const DeskStepLabel = styled(StepLabel, {
    shouldForwardProp: (prop) => prop !== 'hideOnSmallScreens',
})<StepLabelProps & { hideOnSmallScreens?: boolean }>(({ theme, hideOnSmallScreens }) => ({
    margin: '3px 0',
    '& .MuiStepLabel-label': {
        color: '#CCCCCC !important',
        ...(hideOnSmallScreens && {
            '@media (max-width: 600px)': {
                display: 'none',
            },
        }),
    },
    '& .Mui-active': {
        color: `${theme.palette.secondary.main} !important`,
    },
}));
