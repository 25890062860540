export const RESET_VALUES = 'reset-values',
    SET_CONTRACT = 'select-contract',
    SET_SUPPLIERS = 'set-suppliers',
    SET_DISBURSEMENT_STEP = 'set-disbursementStep',
    SET_DISBURSEMENT_PERIOD = 'set-disbursementPeriod',
    SET_TOTAL_REQUESTED_AMOUNT = 'set-total-requestedAmount',
    SET_PRESELECTED_DATE = 'set-preSelectedDisbursementDate',
    SET_LOCATION = 'set-disbursementLocation',
    SET_ALERT = 'set-disbursementAlert',
    SET_MIN_DISBURSEMENT_DATE = 'set-minDisbursementDate',
    SET_MAX_DISBURSEMENT_DATE = 'set-maxDisbursementDate',
    SET_PERIOD_AVAILABLE_BALANCE = 'set-periodAvailableBalance',
    SET_EXCHANGE_RATES = 'set-exchangeRates',
    SET_CART_DATA = 'set-cartData',
    SET_PREV_TOTAL_REQUESTED_AMOUNT = 'set-prevTotalRequestedAmount',
    SET_DISBURSEMENT_COMPANY_ID = 'set-disbursementCompanyId',
    SET_VALUES = 'set-values',
    SET_FORM_COPY = 'set-formCopy',
    SET_SUPPLIERS_COUNT = 'set-suppliersCount',
    // TODO: Delete line below when disbursement V1 is deprecated
    SET_IS_NEW_VERSION = 'set-is-new-version',
    SET_FORMS_STEP = 'set-forms-step',
    SET_IS_AMOUNT_VALID = 'set-is-amount-valid',
    SET_DISABLED_DISB_DAYS = 'set-disabled-disb-days';

// TODO: Subdivide structure to separate form data & metadata
export const SINGLE_DISBURSEMENT_VALUES = {
    confirmed: true,
    dispersion_date: null,
    concept: '',
    supplierAccount: '',
    amount: 0,
    reference: '',
    supplierData: null,
    file: null,
    fileName: '',
    currency: '',
    id_dispersion: '',
    label: 'Dispersión 1',
    // Property necessary to avoid user to change form through tabs.
    disabled: true,
};
