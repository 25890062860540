import { forwardRef } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Grid } from '@mui/material';
import { Info, CheckCircleOutlined } from '@mui/icons-material';
import { IconBtn, Paper, Tooltip, Typography } from '@fairplay2/ui';
import FSSChip from './FSSChip';
import { StatusTypeBoxProps } from './interfaces';

const StatusTypeBox = forwardRef<HTMLDivElement, StatusTypeBoxProps>(
    ({ item = true, type, title, helpText, items, inConfirmation, ...props }, ref) => (
        <Grid ref={ref} item={item} {...props} data-testid={`${type}-statuses-box`}>
            <Paper
                variant="outlined"
                sx={{ padding: 2.5, bgcolor: 'background.default', borderColor: 'primary.A400' }}
            >
                <Typography
                    variant="subtitle2"
                    color="primary.dark"
                    fontWeight={700}
                    component="div"
                    textAlign="center"
                    borderBottom={1}
                    borderColor="primary.A400"
                    paddingBottom={1}
                    mb="20px"
                >
                    {title}
                    {!!helpText && (
                        <Tooltip
                            variant="outlined"
                            color="info"
                            placement="bottom-end"
                            title={helpText}
                            overrideStyles={{ maxWidth: 175 }}
                        >
                            <IconBtn
                                aria-label="¿Qué significa esta categoría?"
                                color="info"
                                sx={{
                                    fontSize: 0.8,
                                    float: 'right',
                                    marginTop: '-5px',
                                }}
                            >
                                <Info />
                            </IconBtn>
                        </Tooltip>
                    )}
                </Typography>
                <Droppable direction="vertical" droppableId={`${type}-statuses`} type="ORDERSTATUS">
                    {(provided) => (
                        <Grid
                            container
                            direction="column"
                            alignItems="flex-start"
                            minHeight={198}
                            spacing={2}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            role="listbox"
                        >
                            {items.map((nestedItem, index) => (
                                <Draggable
                                    key={nestedItem}
                                    isDragDisabled={inConfirmation}
                                    draggableId={`${type}-statuses-${nestedItem.replaceAll(
                                        ' ',
                                        '_',
                                    )}`}
                                    index={index}
                                >
                                    {(nestedProvided) => (
                                        <Grid
                                            item
                                            maxWidth="100% !important"
                                            ref={nestedProvided.innerRef}
                                            {...nestedProvided.draggableProps}
                                            {...nestedProvided.dragHandleProps}
                                            role="option"
                                            aria-disabled={inConfirmation}
                                        >
                                            <FSSChip
                                                color="primary"
                                                label={nestedItem}
                                                data-testid="fss-chip"
                                                icon={
                                                    inConfirmation ? (
                                                        <CheckCircleOutlined />
                                                    ) : undefined
                                                }
                                                variant={inConfirmation ? 'filled' : 'outlined'}
                                            />
                                        </Grid>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Grid>
                    )}
                </Droppable>
            </Paper>
        </Grid>
    ),
);

export default StatusTypeBox;
