import { FC, useEffect, useReducer, useContext } from 'react';
import { Grid, Collapse, Box } from '@mui/material';
import { Typography, Alert } from '@fairplay2/ui';
import sessionContext from 'context/session/sessionContext';
import { useAlert } from 'utils/hooks';
import { RequestsDictionary } from '../../interfaces';
import ChartWithFallback from '../../components/ChartWithFallback';
import { TRADING_WARNING_C } from '../../utils/constants';
import { fetchData } from '../../utils/fetchData';
import { ProjectionsInitialState, ProjectionsReducer } from '../projectionsReducer';
import { ProjectionsAction } from '../interfaces';

const REQUESTS_DIC: RequestsDictionary<ProjectionsAction['type']> = {
    prophet: {
        actionType: 'SET_PROPHET',
        getUrl: (uuid: string) => `/v1/reports/projections/prophet/${uuid}`,
        errorValue: { warningC: TRADING_WARNING_C.insufficientData, options: [], isEmpty: true },
    },
};

const Projections: FC = () => {
    const { selectedCompany } = useContext(sessionContext),
        [{ chartData }, dispatch] = useReducer(ProjectionsReducer, ProjectionsInitialState),
        { alert, ...rest } = useAlert();

    useEffect(() => {
        if (!selectedCompany.company?.id) return;
        dispatch({ type: 'RESTART_LOADING' });
        fetchData<ProjectionsAction['type']>({
            companyId: selectedCompany.company.id,
            dispatch,
            requestsDic: REQUESTS_DIC,
            alertFunc: rest,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany.company?.id]);

    return (
        <Grid container mb={4}>
            <Grid item xs={12} mt={2}>
                <Typography variant="subtitle2" color="primary.main">
                    ¿Cómo serán tus ventas en los próximos meses?
                </Typography>
                <Typography variant="body1" color="text.secondary" mb="20px">
                    Ventas proyectadas por mes
                </Typography>
                <Collapse in={alert.open}>
                    <Box display="inline-block">
                        <Alert severity={alert.type} sx={{ mb: 1 }}>
                            {alert.message}
                        </Alert>
                    </Box>
                </Collapse>
                {Object.entries(chartData).map(
                    ([key, { options, title, isEmpty, loading }]: any) => (
                        <ChartWithFallback
                            key={key}
                            options={options}
                            title={title}
                            isEmpty={isEmpty}
                            loading={loading}
                        />
                    ),
                )}
            </Grid>
        </Grid>
    );
};

export default Projections;
