import { FC, useContext, useState, ChangeEvent, MouseEvent, useEffect, useCallback } from 'react';
import {
    Grid,
    TableRow,
    TableCell,
    TableSortLabel,
    Collapse,
    MenuItem,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Alert, Typography, Select, Divider, DefaultInput } from '@fairplay2/ui';
import { CurrencyRange } from '@fairplay2/ui-x-currencies';
import { DatePicker, getBoundaryYears } from '@fairplay2/ui-x-dates';
import { SearchFilters, Table, getActiveFilters } from 'common-components';
import { OrderType, OrderDic, DateRange } from 'utils/interfaces';
import SessionContext from 'context/session/sessionContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import fairplayAPI from 'utils/api';
import { useAlert } from 'utils/hooks';
import { toTimelessISOUtc, mimicDateAtCDMX } from 'utils/dates';
import { FilterItemContainer } from '../../styles';
import { FiscalTableProps } from '../../interfaces';
import FiscalTableItem from './FiscalTableItem';

const ORDER_DIC: OrderDic = {
        asc: 'created',
        desc: '-created',
    },
    paymentArr = ['PPD', 'PUE'],
    statusArr = ['VIGENTE', 'CANCELADO'],
    InputLabelProps = {
        sx: {
            fontWeight: 500,
            fontSize: 12,
        },
    };

const FiscalTable: FC<FiscalTableProps> = ({ invoiceType }) => {
    const { selectedCompany } = useContext(SessionContext),
        { alert, hideAlert, showAlert } = useAlert(),
        [requestParams, setRequestParams] = useState(''),
        [order, setOrder] = useState<OrderType>('desc'),
        [loading, setLoading] = useState(true),
        [count, setCount] = useState(0),
        [selectedDayRange, setSelectedDayRange] = useState<DateRange | undefined>({
            from: undefined,
            to: undefined,
        }),
        [currencyRange, setCurrencyRange] = useState<number[]>([]),
        [rfcSelected, setRfcSelected] = useState(''),
        [page, setPage] = useState(0),
        [paymentSelected, setPaymentSelected] = useState(''),
        [status, setStatus] = useState<string>(''),
        [rowsPerPage, setRowsPerPage] = useState(5),
        [searchValues, setSearchValues] = useState({
            //  if is_issuer = false, it means the users is receiving invoices, issuer information (vendor's data) will be given by backend
            //  if is_issuer = true, it means the user is issuing the invoices, receiver information will be given by backend

            is_issuer: invoiceType === 'receiver' ? 'false' : 'true',
            payment_type: '',
            monto_min: 0,
            monto_max: 10000000,
            start_date: '',
            end_date: '',
            status: '',
            rfc: '',
            ordering: ORDER_DIC[order],
        }),
        [invoices, setInvoices] = useState<any[]>([]),
        [isFilterOff, setIsFilterOff] = useState(true),
        [activeFilters, setActiveFilters] = useState(0),
        [PickerProps] = useState(() => getBoundaryYears(mimicDateAtCDMX())),
        theme = useTheme(),
        mdDown = useMediaQuery(theme.breakpoints.down('md'));

    const onSearch = () => {
        const dateFilters = {
            start_date: selectedDayRange?.from ? toTimelessISOUtc(selectedDayRange.from) : '',
            end_date: selectedDayRange?.to ? toTimelessISOUtc(selectedDayRange.to) : '',
        };

        setActiveFilters(
            getActiveFilters({
                payment_type: paymentSelected,
                minMaxAmount: {
                    monto_min: currencyRange[0],
                    monto_max: currencyRange[1],
                },
                date: dateFilters,
                status,
                rfc: rfcSelected,
            }),
        );
        setSearchValues({
            is_issuer: invoiceType === 'receiver' ? 'false' : 'true',
            payment_type: paymentSelected,
            monto_min: currencyRange[0],
            monto_max: currencyRange[1],
            status,
            rfc: rfcSelected,
            ordering: ORDER_DIC[order],
            ...dateFilters,
        });
    };

    const onSort = () => {
        const isAsc = order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
    };

    const onChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        document.body.style.overflow = 'auto';
    };

    const onPaymentSelect = (e: ChangeEvent<HTMLInputElement>) => {
        setPaymentSelected(e.target.value);
        if (isFilterOff) setIsFilterOff(false);
    };

    const onStatusSelect = (e: ChangeEvent<HTMLInputElement>) => {
        setStatus(e.target.value);
        if (isFilterOff) setIsFilterOff(false);
    };

    const onRfcSelect = (e: ChangeEvent<HTMLInputElement>) => {
        setRfcSelected(e.target.value);
        if (isFilterOff) setIsFilterOff(false);
    };

    const resetTableFilters = () => {
        setSelectedDayRange({
            from: undefined,
            to: undefined,
        });
        setRfcSelected('');
        setPaymentSelected('');
        setCurrencyRange([]);
        setStatus('');

        setSearchValues({
            is_issuer: invoiceType === 'receiver' ? 'false' : 'true',
            payment_type: '',
            monto_min: 0,
            monto_max: 10000000,
            start_date: '',
            end_date: '',
            status: '',
            rfc: '',
            ordering: ORDER_DIC.desc,
        });

        setOrder('desc');
        setPage(0);
        setRowsPerPage(5);
        setIsFilterOff(true);
    };

    const onCalendarChange = (range: DateRange | undefined) => {
        setSelectedDayRange(range);
        setIsFilterOff(false);
    };

    const onCurrencyRangeChange: typeof setCurrencyRange = (newRangeValue) => {
        setIsFilterOff(false);
        setCurrencyRange(newRangeValue);
    };

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            if (requestParams) {
                const res: any = await fairplayAPI.get(
                        `/v2/companies/${selectedCompany?.company?.id}/invoices${requestParams}`,
                    ),
                    resCount = res.data.body.count,
                    resArr = res.data.body.results,
                    newCount = resCount || resArr.length;
                setCount(newCount);
                setInvoices(resArr);
                setLoading(false);
            }
        } catch (errResponse: ErrorObj | any) {
            if (errResponse?.status !== 404)
                showAlert('No se pudieron cargar las facturas, intente más tarde');
            setLoading(false);
        }
    }, [requestParams, selectedCompany.company?.id, showAlert]);

    useEffect(() => {
        if (!selectedCompany.company?.id) return;
        hideAlert();
        fetchData();
    }, [requestParams, selectedCompany.company?.id, invoiceType, fetchData, hideAlert]);

    useEffect(() => {
        resetTableFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany.company?.id]);

    return (
        <>
            <SearchFilters
                isFilterOff={isFilterOff}
                activeFilters={activeFilters}
                onFilterSearch={onSearch}
                resetFilters={resetTableFilters}
                width="100%"
                marginTop={mdDown ? '40px' : '-10px'}
                marginBottom="32px"
                ariaLabel="Filtros facturas"
            >
                <FilterItemContainer
                    item
                    container
                    minWidth="20%"
                    height="100%"
                    textAlign="center"
                    alignItems="center"
                    borderRadius="40px"
                    padding={{ xs: '20px', md: '0 20px' }}
                >
                    <DatePicker
                        variant="standard"
                        labelVariant="contained"
                        label="Fecha de emisión"
                        mode="range"
                        placeholder="Desde - Hasta"
                        value={selectedDayRange}
                        onChange={onCalendarChange}
                        inputAlignment="start"
                        PickerProps={PickerProps}
                    />
                </FilterItemContainer>

                <Divider
                    orientation={mdDown ? 'horizontal' : 'vertical'}
                    variant={mdDown ? 'fullWidth' : 'middle'}
                    sx={{
                        margin: { xs: '10px 0', md: '8px 0' },
                        color: 'background.dark',
                    }}
                    flexItem
                />
                <FilterItemContainer
                    item
                    container
                    minWidth="19%"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="40px"
                    padding={{ xs: '20px', md: '0 20px' }}
                >
                    <DefaultInput
                        variant="standard"
                        id="rfcSearch"
                        label="RFC"
                        labelVariant="contained"
                        value={rfcSelected}
                        onChange={onRfcSelect}
                        placeholder="¿De quién?"
                    />
                </FilterItemContainer>
                <Divider
                    orientation={mdDown ? 'horizontal' : 'vertical'}
                    variant={mdDown ? 'fullWidth' : 'middle'}
                    sx={{
                        margin: { xs: '10px 0', md: '8px 0' },
                        color: 'background.dark',
                    }}
                    flexItem
                />
                <FilterItemContainer
                    item
                    container
                    minWidth="12%"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="40px"
                    padding={{ xs: '20px', md: '0 20px' }}
                >
                    <Select
                        value={paymentSelected}
                        onChange={onPaymentSelect}
                        label="Método pago"
                        labelVariant="contained"
                        variant="standard"
                        InputLabelProps={InputLabelProps}
                        fullWidth
                    >
                        <MenuItem value="" disabled>
                            Pago
                        </MenuItem>
                        {paymentArr.map((paymentType) => (
                            <MenuItem key={paymentType} value={paymentType}>
                                {paymentType}
                            </MenuItem>
                        ))}
                    </Select>
                </FilterItemContainer>
                <Divider
                    orientation={mdDown ? 'horizontal' : 'vertical'}
                    variant={mdDown ? 'fullWidth' : 'middle'}
                    sx={{
                        margin: { xs: '10px 0', md: '8px 0' },
                        color: 'background.dark',
                    }}
                    flexItem
                />
                <FilterItemContainer
                    item
                    container
                    minWidth="20%"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="40px"
                    padding={{ xs: '20px', md: '0 20px' }}
                >
                    <CurrencyRange
                        values={currencyRange}
                        setValues={onCurrencyRangeChange}
                        label="Monto (Con impuestos)"
                        labelVariant="contained"
                        variant="standard"
                        title="Define un rango del monto de las facturas"
                        step={2500}
                        max={300000}
                    />
                </FilterItemContainer>

                <Divider
                    orientation={mdDown ? 'horizontal' : 'vertical'}
                    variant={mdDown ? 'fullWidth' : 'middle'}
                    sx={{
                        margin: { xs: '10px 0', md: '8px 0' },
                        color: 'background.dark',
                    }}
                    flexItem
                />
                <FilterItemContainer
                    item
                    container
                    minWidth="20%"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="40px"
                    padding={{ xs: '20px', md: '0 20px' }}
                >
                    <Select
                        value={status}
                        onChange={onStatusSelect}
                        label="Estatus"
                        labelVariant="contained"
                        variant="standard"
                        InputLabelProps={InputLabelProps}
                        fullWidth
                    >
                        <MenuItem value="" disabled>
                            Todos los estatus
                        </MenuItem>
                        {statusArr.map((stat) => (
                            <MenuItem key={stat} value={stat}>
                                {stat}
                            </MenuItem>
                        ))}
                    </Select>
                </FilterItemContainer>
            </SearchFilters>
            <Grid item container xs={12} justifyContent="flex-end">
                <Collapse in={alert.open}>
                    <Alert severity={alert.type} sx={{ mb: '15px' }}>
                        {alert.message}
                    </Alert>
                </Collapse>
            </Grid>
            <Typography color="primary.dark" fontWeight={500}>
                {invoiceType === 'receiver'
                    ? 'Estas son las facturas que recibes de tus proveedores'
                    : 'Estas son las facturas que emites a tus clientes'}
            </Typography>
            <Table
                // eslint-disable-next-line react/no-unstable-nested-components
                getTableItem={(invoice: any, i: number) => (
                    <FiscalTableItem
                        invoice={invoice}
                        invoiceType={invoiceType}
                        setAlert={showAlert}
                        key={i}
                    />
                )}
                currentItems={invoices}
                columns={7}
                loading={loading}
                pagination={{
                    count,
                    page,
                    rowsPerPage,
                    onPageChange: onChangePage,
                    onRowsPerPageChange: onChangeRowsPerPage,
                }}
                externalFilters={{ ...searchValues, ...{ ordering: ORDER_DIC[order] } }}
                emptyItemsMessage="No hemos encontrado facturas registradas"
                onParamsQueryChange={setRequestParams}
            >
                <TableRow>
                    <TableCell>
                        <TableSortLabel
                            active={invoices.length > 0}
                            direction={order}
                            onClick={onSort}
                            aria-label={`Organizar por fecha ${
                                order === 'desc' ? 'ascendente' : 'descendente'
                            }`}
                        >
                            <Typography color="text.primary" component="span">
                                Fecha
                            </Typography>
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <Typography color="text.primary">
                            {invoiceType === 'receiver' ? 'Emisor' : 'Receptor'}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography color="text.primary">Método pago</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography color="text.primary" component="span">
                            Monto c/ impuestos
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography color="text.primary">Estatus</Typography>
                    </TableCell>
                    <TableCell align="center">
                        <Typography color="text.primary">Acciones</Typography>
                    </TableCell>
                    <TableCell sx={{ width: 100 }}>
                        <Typography color="text.primary">
                            {invoiceType === 'receiver' ? 'Pagada' : 'Cobrada'}
                        </Typography>
                    </TableCell>
                </TableRow>
            </Table>
        </>
    );
};

export default FiscalTable;
