import { FC, useState, useContext } from 'react';
import { useTheme, useMediaQuery, Grid, Collapse } from '@mui/material';
import { DefaultBtn, Modal, InterpunctLoader, Typography, Alert } from '@fairplay2/ui';
import { ManTrashcanSvg, ManTrashcanTwoSvg } from 'assets/SVGs';
import sessionContext from 'context/session/sessionContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import fairplayAPI from 'utils/api';
import { useAlert } from 'utils/hooks';
import { DeleteDocModalProps } from '../interfaces';

const DeleteDocModal: FC<DeleteDocModalProps> = ({ open, selectedDocument, onClose }) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        { selectedCompany } = useContext(sessionContext),
        [hasBeenDeleted, setHasBeenDeleted] = useState(false),
        [loading, setLoading] = useState(false),
        { alert, hideAlert, showAlert } = useAlert();

    const onDelete = async () => {
        hideAlert();
        if (selectedDocument) {
            setLoading(true);
            try {
                await fairplayAPI.delete(
                    `/v1/companies/${selectedCompany.company?.id}/documents/${selectedDocument.id}`,
                );
                setLoading(false);
                setHasBeenDeleted(true);
            } catch (errResponse: ErrorObj | any) {
                setLoading(false);
                let errors: any;
                if (errResponse.type === 'form')
                    errors =
                        typeof errResponse?.error === 'object'
                            ? Object.values(errResponse?.error)[0]
                            : errResponse?.error;

                showAlert(
                    errors ||
                        errResponse?.error ||
                        'Ha ocurrido un error eliminando el documento, intente más tarde',
                );
            }
        }
    };

    const onCloseModal = () => {
        setHasBeenDeleted(false);
        onClose(hasBeenDeleted);
    };

    return (
        <Modal open={open} onClose={onCloseModal} maxWidth={680}>
            {hasBeenDeleted ? (
                <Grid container>
                    <Grid container justifyContent="center">
                        <ManTrashcanTwoSvg />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="subtitle1" color="primary.main" mt="20px">
                            ¡Documento eliminado!
                        </Typography>
                        <Typography variant="subtitle2" color="text.primary" mt="20px">
                            El documento{' '}
                            <Typography component="span" color="primary.dark" fontWeight={700}>
                                {selectedDocument?.documentType.name}
                            </Typography>{' '}
                            ha sido eliminado exitosamente.
                        </Typography>
                    </Grid>

                    <Grid container justifyContent="center" mt="40px">
                        <DefaultBtn size="small" onClick={onCloseModal}>
                            Entendido
                        </DefaultBtn>
                    </Grid>
                </Grid>
            ) : (
                <Grid container>
                    <Grid container justifyContent="center">
                        <ManTrashcanSvg />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" color="primary.main" mt="10px">
                            ¿Deseas realmente eliminar este documento?
                        </Typography>
                        <Typography variant="subtitle2" color="text.primary" mt="20px">
                            Estás a punto de eliminar el documento{' '}
                            <Typography component="span" color="primary.dark" fontWeight={700}>
                                {selectedDocument?.documentType &&
                                    selectedDocument?.documentType.name}
                            </Typography>{' '}
                            de tus documentos legales.
                            <br /> Si se elimina, no se podrá recuperar.
                        </Typography>
                    </Grid>
                    <Grid container justifyContent="center" mt="20px">
                        <Collapse in={alert.open}>
                            <Alert severity={alert.type}>{alert.message}</Alert>
                        </Collapse>
                    </Grid>
                    <Grid container justifyContent="center" mt="20px">
                        {loading ? (
                            <InterpunctLoader data-testid="delete-legal-doc-interpunct-loader" />
                        ) : (
                            <>
                                <DefaultBtn
                                    fullWidth={smDown}
                                    size="small"
                                    variant="outlined"
                                    onClick={onCloseModal}
                                    sx={{
                                        mb: { xs: '20px', sm: 0 },
                                    }}
                                >
                                    Ok, no
                                </DefaultBtn>
                                <DefaultBtn
                                    fullWidth={smDown}
                                    size="small"
                                    onClick={onDelete}
                                    sx={{
                                        ml: { sx: 0, sm: '20px' },
                                    }}
                                >
                                    Sí, eliminar
                                </DefaultBtn>
                            </>
                        )}
                    </Grid>
                </Grid>
            )}
        </Modal>
    );
};

export default DeleteDocModal;
