import { forwardRef } from 'react';
import { Grid } from '@mui/material';
import { Typography } from '@fairplay2/ui';
import { IconContainer } from './IconText.styles';
import { IconTextProps } from './IconText.types';

export const IconText = forwardRef<HTMLDivElement, IconTextProps>(
    (
        { icon, children, color, variant = 'body1', fontWeight = 700, iconVariant = 'filled' },
        ref,
    ) => (
        <Grid container ref={ref} position="relative" alignItems="center" gap={2}>
            <IconContainer item container color={color} iconVariant={iconVariant}>
                <Grid
                    item
                    sx={
                        iconVariant === 'filled'
                            ? { position: 'absolute', left: 11, top: 11 }
                            : undefined
                    }
                >
                    {icon}
                </Grid>
            </IconContainer>
            <Grid item maxWidth={158}>
                <Typography
                    position={iconVariant === 'filled' ? 'absolute' : undefined}
                    variant={variant}
                    color="text.primary"
                    fontWeight={fontWeight}
                    left={60}
                    top={12}
                >
                    {children}
                </Typography>
            </Grid>
        </Grid>
    ),
);
