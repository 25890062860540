import { FC, Fragment } from 'react';
import { Grid } from '@mui/material';
import { DefaultInput, Divider, Tooltip, Typography } from '@fairplay2/ui';
import { Stars } from '@mui/icons-material';
import { BankAccountDataProps } from '../interfaces';

const BankAccountData: FC<BankAccountDataProps> = ({ account, i }) => (
    <Fragment key={account.id}>
        {i > 0 && (
            <Grid item xs={12}>
                <Divider sx={{ color: 'text.disabled' }} data-testid="separador" />
            </Grid>
        )}
        <Grid item xs={12} sm={6}>
            <Grid container alignItems="center" position="relative">
                <Typography
                    component="label"
                    color="text.primary"
                    mb="6px"
                    variant="body1"
                    fontWeight={500}
                    htmlFor={`vendor-bankaccount-${i}`}
                >
                    {account.swift ? 'Código SWIFT:' : 'CLABE:'}
                </Typography>
                {account.status.id === 'primary' && (
                    <Tooltip
                        title="Esta es la cuenta principal"
                        placement="right"
                        style={{
                            position: 'absolute',
                            left: account.swift ? '113px' : '62px',
                            bottom: '5px',
                        }}
                    >
                        <Stars color="secondary" />
                    </Tooltip>
                )}
            </Grid>

            <DefaultInput
                id={`vendor-bankaccount-${i}`}
                labelVariant="none"
                disabled
                value={account.swift ? account.swift : account.clabe}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <DefaultInput
                id={`vendor-bank-${i}`}
                label="Banco:"
                labelVariant="external"
                disabled
                value={account.bank || ''}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <DefaultInput
                id={`vendor-account-${i}`}
                label="Número de cuenta:"
                labelVariant="external"
                disabled
                value={account.accountNumber || ''}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <DefaultInput
                id={`vendor-currency-${i}`}
                label="Divisa:"
                labelVariant="external"
                disabled
                value={account.accountCurrency?.text || ''}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <DefaultInput
                id={`vendor-bank-country-${i}`}
                label="País del banco:"
                labelVariant="external"
                disabled
                value={account.country?.name || ''}
            />
        </Grid>
    </Fragment>
);

export default BankAccountData;
