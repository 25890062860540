import { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
/* Login */
import Signin from 'auth';
/* Public Routes */
import ChangePassword from 'auth/components/ChangePassword';
import CustomerOnboarding from 'components/customer-onboarding';
/* 404 Not Found */
import { NotFoundPage } from 'layouts';
/* Dashboard Routes */
import DataSources from 'components/dashboard/data-sources';
import FiscalDetail from 'components/dashboard/fiscal-hub/components/InvoiceDetail/FiscalDetail';
import FiscalHub from 'components/dashboard/fiscal-hub';
import ManualUpload from 'components/dashboard/data-sources/manual-upload';
import MultipleStatuses from 'components/dashboard/data-sources/orders-status/MultipleStatuses';
import Company from 'components/dashboard/company';
import User from 'components/dashboard/user';
import RegisterUser from 'components/customer-onboarding/components/invited-user/index';
/* Disbursement */
import DisbursementDetail from 'components/dashboard/disbursment/transactions/directory/components/DisbursementDetail';
import MassDisbursement from 'components/dashboard/disbursment/transactions/massive-upload/index';
// TODO: Replace URLs when disbursement V1 is deprecated
import DirectoryVersionController from 'components/dashboard/disbursment/transactions/directory/VersionController';
import CreationVersionController from 'components/dashboard/disbursment/transactions/create-disbursement/VersionController';
import { Cards, CardDetail } from 'components/dashboard/disbursment/cards';
/* Analytics */
import KPIs from 'components/dashboard/analytics/KPIs/index';
// import Marketing from 'components/dashboard/analytics/marketing/index';
import Trading from 'components/dashboard/analytics/trading';
/* Account statement */
import ContractsList from 'components/dashboard/contracts/list';
import ContractDetail from 'components/dashboard/contracts/detail';
import PaymentOrdersDetail from 'components/dashboard/contracts/detail/components/payments/PaymentOrdersDetail';
import Vendors from 'components/dashboard/vendors/directory';
import AddVendor from 'components/dashboard/vendors/add-vendor';
import VendorProfile from 'components/dashboard/vendors/profile';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
/* Scroll To Top */
import ScrollToTop from './ScrollToTop';

const Routes: FC = () => (
    <>
        <ScrollToTop />

        <Switch>
            <PublicRoute exact path="/" component={Signin} />
            <PublicRoute exact path="/:token" component={Signin} />
            <PublicRoute path="/reset/:id" component={ChangePassword} />
            <PublicRoute exact path="/onboarding/user/:token" component={RegisterUser} />
            <PublicRoute exact path="/app/onboarding" component={CustomerOnboarding} />
            <PublicRoute
                exact
                path="/app/onboarding/activate/:token"
                component={CustomerOnboarding}
            />
            <PrivateRoute exact path="/app/sources" component={DataSources} />
            <PrivateRoute exact path="/app/fiscal" component={FiscalHub} />
            <PrivateRoute exact path="/app/user/edit" component={User} />
            <PrivateRoute exact path="/app/fiscal/detail/:id" component={FiscalDetail} />
            <PrivateRoute exact path="/app/sources/upload" component={ManualUpload} />
            <PrivateRoute
                exact
                /**
                 * @param platformKey Key from {@link GroupedPlatformKey}
                 */
                path="/app/sources/status-orders/:platformKey/:targetConnector?"
                component={MultipleStatuses}
            />
            <PrivateRoute
                exact
                path="/app/disbursements/transactions/create"
                component={CreationVersionController}
            />

            <PrivateRoute
                exact
                path="/app/disbursements/transactions"
                component={DirectoryVersionController}
            />
            <PrivateRoute
                exact
                path="/app/disbursements/transactions/create/file"
                component={MassDisbursement}
            />
            <PrivateRoute
                exact
                path="/app/disbursements/transactions/detail/:id"
                component={DisbursementDetail}
            />

            <PrivateRoute exact path="/app/disbursements/cards" component={Cards} />
            <PrivateRoute exact path="/app/disbursements/cards/detail/:id" component={CardDetail} />

            <PrivateRoute exact path="/app/company/:tab?" component={Company} />
            <PrivateRoute path="/dashboard/kpis" component={KPIs} />
            <PrivateRoute path="/dashboard/trading" component={Trading} />
            {/* The marketing module will be temporarily disabled */}
            {/* <PrivateRoute path="/dashboard/marketing" component={Marketing} /> */}

            <PrivateRoute exact path="/app/financing/contract" component={ContractsList} />
            <PrivateRoute
                exact
                path="/app/financing/contract/detail/:id"
                component={ContractDetail}
            />
            <PrivateRoute
                exact
                path="/app/financing/contract/detail/:id/payments/:date"
                component={PaymentOrdersDetail}
            />
            <PrivateRoute exact path="/app/financing/vendors" component={Vendors} />
            <PrivateRoute exact path="/app/financing/vendors/add-vendor" component={AddVendor} />
            <PrivateRoute
                exact
                path="/app/financing/vendors/detail/:id"
                component={VendorProfile}
            />
            <Route path="*" component={NotFoundPage} />
        </Switch>
    </>
);

export default Routes;
