import { FC } from 'react';
import { Typography } from '@fairplay2/ui';
import { Grid, Skeleton } from '@mui/material';
import { ArrowDropUp, ArrowDropDown, Warning } from '@mui/icons-material';
import { formatAmount } from 'utils/parsing';
import { StyledPaperButton } from './CardTab.styles';
import { CardTabGrowthIndicatorProps, CardTabProps } from './interfaces';

export const GrowthIndicator: FC<CardTabGrowthIndicatorProps> = ({
    indicator = 'below',
    insuficientData,
    // eslint-disable-next-line no-nested-ternary
    color = insuficientData ? 'error.main' : indicator === 'over' ? 'success.main' : 'error.main',
    percentage,
}) => (
    <Grid item>
        <Typography
            variant="subtitle2"
            color={color}
            fontWeight={500}
            sx={{
                '.MuiSvgIcon-root': {
                    mb: '-4px',
                    ml: '-2px',
                    mr: '5px',
                },
            }}
        >
            {insuficientData ? (
                '- - - - %'
            ) : (
                <>
                    {indicator === 'over' ? (
                        <ArrowDropUp titleAccess="positivo" />
                    ) : (
                        <ArrowDropDown titleAccess="negativo" />
                    )}
                    {`${percentage}%`}
                </>
            )}
        </Typography>
    </Grid>
);

const CardTab: FC<CardTabProps> = ({
    emptyData,
    indicator,
    insuficientData,
    isActive,
    isNumber = true,
    loading,
    onClick,
    percentage,
    status,
    title,
    value,
}) =>
    loading ? (
        <Grid container>
            <Skeleton
                data-testid="card-tab-skeleton"
                width="100%"
                height={156}
                sx={{ borderRadius: '10px 10px 0 0' }}
            />
        </Grid>
    ) : (
        <StyledPaperButton
            variant="outlined"
            onClick={onClick}
            isActive={isActive}
            emptyData={emptyData}
        >
            <Grid item container direction="column">
                <Grid item mb="10px">
                    <Typography
                        variant="subtitle2"
                        color="primary.main"
                        textAlign="start"
                        fontWeight={700}
                    >
                        {title}
                    </Typography>
                </Grid>
                {emptyData ? (
                    <Grid container mt="4px" gap="11px" justifyContent="center" alignItems="center">
                        <Warning color="primary" sx={{ fontSize: '19px' }} />
                        <Typography variant="subtitle2" color="primary.main" fontWeight={500}>
                            Procesando datos
                        </Typography>
                    </Grid>
                ) : (
                    <>
                        <Grid
                            item
                            mb="14px"
                            container
                            alignItems="end"
                            justifyContent="space-between"
                        >
                            <Typography variant="subtitle2" color="text.primary" fontWeight={700}>
                                {status}
                            </Typography>
                            <Typography variant="h4" color="primary.main" fontWeight={500}>
                                {`${isNumber ? '$' : ''} ${formatAmount(value)}`}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            direction="row"
                            alignItems="end"
                            justifyContent="space-between"
                        >
                            <Typography
                                variant="subtitle2"
                                fontSize="10px"
                                color="text.primary"
                                fontWeight={500}
                            >
                                Crecimiento vs año anterior
                            </Typography>
                            <GrowthIndicator
                                insuficientData={insuficientData}
                                indicator={indicator}
                                percentage={percentage}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </StyledPaperButton>
    );

export default CardTab;
