import { FairplayTheme } from '@fairplay2/ui';
import { ORDERS_PLATFORMS } from 'components/dashboard/data-sources/platforms/constants';
import { subDays } from 'date-fns';
import { generateYearsArray } from 'utils/dates';
import { mimicDateAtCDMX } from '../../../../utils/dates/index';
import { SummariesKeys } from '../trading/interfaces';

export const TRADING_WARNING_C = {
    insufficientData: 'Cantidad de datos insuficientes',
    noOrders: 'No hay órdenes registradas',
};

export const MONTHS_DIC = {
    '': 'Mes',
    all: 'Todos',
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
};

export const STATUS_DIC = {
    '': 'Estatus',
    valid: 'Válido',
    invalid: 'Inválido',
    returned: 'Devoluciones',
    total: 'Total',
};

export const YEARS = {
    '': 'Año',
    ...generateYearsArray(3).reduce((acc, value) => {
        acc[value] = value.toString();
        return acc;
    }, {} as Record<string, string>),
};

export const TODAY_DATE = mimicDateAtCDMX();

export const TODAY_MINUS_90 = subDays(TODAY_DATE, 89);

export const SUMMARY_KEYS: Record<SummariesKeys, string> = {
    gmv: 'Ventas',
    orders: 'Órdenes',
    average: 'Ticket Promedio',
};

export const TABS_KEYS: Record<string, string> = {
    sales: 'Ventas',
    orders: 'Órdenes',
    averageTicket: 'Ticket Promedio',
};

export const CHART_MARKERS_SYMBOLS = ['circle', 'diamond', 'square', 'triangle', 'triangle-down'];

export const COLORS_DIC = [
    FairplayTheme.palette.secondary.main,
    FairplayTheme.palette.primary.A400!,
    '#2DAEFE',
    '#6B8ABC',
    '#FE6A35',
    '#DE1A65',
    '#5D75EB',
    '#F5C625',
    '#8938DE',
    '#00E8FC',
];

export const VALID_CHANNELS = ORDERS_PLATFORMS.filter((platform) => platform !== 's3');
