import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, TableRow, TableCell } from '@mui/material';
import { AssignmentInd, Info, Visibility, Delete } from '@mui/icons-material';
import { Typography, IconBtn, Tooltip } from '@fairplay2/ui';
import { Status, StatusType } from 'common-components';
import { VendorsTableItemProps } from '../interfaces';

const VendorsTableItem: FC<VendorsTableItemProps> = ({ vendor, onDeleteVendor }) => {
    const history = useHistory();

    return (
        <TableRow key={vendor.id}>
            <TableCell>
                <Grid container alignItems="center" minWidth={180}>
                    <AssignmentInd color="secondary" />
                    <Typography fontWeight={700} color="primary.main" ml="15px">
                        {vendor.alias}
                    </Typography>
                </Grid>
            </TableCell>
            <TableCell>
                <Typography color="primary.dark" fontWeight={500}>
                    {vendor.taxId}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography color="primary.dark" fontWeight={500}>
                    {vendor.country && vendor.country.name}
                </Typography>
            </TableCell>
            <TableCell aria-label="Estatus">
                <Status
                    variant="outlined"
                    role="presentation"
                    status={vendor.status!.id as StatusType}
                >
                    {vendor.status!.text}
                </Status>
            </TableCell>
            <TableCell align="center" aria-label={`Información ${vendor.comments}`}>
                {vendor.comments && (
                    <Tooltip placement="top" title={vendor.comments} distance={0} color="primary">
                        <Info sx={{ color: 'info.main' }} />
                    </Tooltip>
                )}
            </TableCell>
            <TableCell align="center" aria-label="Acciones">
                <Grid container justifyContent="space-evenly" wrap="nowrap" minWidth="90px">
                    <Tooltip title="Ver detalle" placement="top" distance={0}>
                        <IconBtn
                            variant="outlined"
                            aria-label={`ver detalle ${vendor.alias}`}
                            color="secondary"
                            onClick={() =>
                                history.push(`/app/financing/vendors/detail/${vendor.id}`)
                            }
                        >
                            <Visibility />
                        </IconBtn>
                    </Tooltip>
                    <Tooltip title="Eliminar" placement="top" distance={0}>
                        <IconBtn
                            variant="outlined"
                            aria-label={`eliminar ${vendor.alias}`}
                            color="secondary"
                            onClick={() => onDeleteVendor(vendor)}
                        >
                            <Delete />
                        </IconBtn>
                    </Tooltip>
                </Grid>
            </TableCell>
        </TableRow>
    );
};

export default VendorsTableItem;
