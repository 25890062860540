import { Grid } from '@mui/material';
import { Skeleton } from '@fairplay2/ui';

const NewCardSkeleton = () => (
    <Grid container>
        <Grid item xs={12} mb="30px" maxWidth={['390px', '460px']}>
            <Skeleton height={10} />
        </Grid>
        <Grid container justifyContent={['center', 'flex-start']} columnGap="25px" rowGap="25px">
            <Grid item width={['300px', '350px']} height={['190px', '220px']}>
                <Skeleton height="100%" />
            </Grid>
            <Grid item flexGrow={1}>
                <Grid container rowSpacing="30px" maxWidth={['100%', '360px']}>
                    <Grid item xs={12}>
                        <Grid item xs={12} mb="10px">
                            <Skeleton width={220} height={10} />
                        </Grid>
                        <Grid item width={['100%', '360px']}>
                            <Skeleton height={52} />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid item xs={12} mb="10px">
                            <Skeleton width={220} height={10} />
                        </Grid>
                        <Grid item width={['100%', '360px']}>
                            <Skeleton height={52} />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} container justifyContent="flex-end">
                        <Grid item width={['100%', '140px']}>
                            <Skeleton height={38} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
);

export default NewCardSkeleton;
