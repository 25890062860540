import { FC, forwardRef, useContext, useEffect, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useTheme, useMediaQuery, Grid } from '@mui/material';
import {
    DashboardLayout as FpDashboardLayout,
    DashboardLinkProps,
    DashboardNavItemType,
    Typography,
} from '@fairplay2/ui';
import { captureException } from '@sentry/react';
import sessionContext from 'context/session/sessionContext';
import { useHotjarWatcher } from 'utils/hooks';
import { dataSourceRedirection } from 'components/dashboard/data-sources/utils';
import { addGoogleTag } from 'utils/google-tag-manager';
// Reusable modal for new feature releases. Always import as NewFeatureModal
import { PresentationModal as NewFeatureModal } from 'components/dashboard/analytics/trading/components/PresentationModal';
import { CardApprovalsModal, useIsAdmin } from 'components/dashboard/disbursment/cards';
import fairplayAPI from 'utils/api';
import { capitalize } from 'utils/formatting';
import useNewFeatureModal from 'utils/hooks/useNewFeatureModal';
import { OtpModalNotice } from 'components/dashboard/company/feature-security';
import {
    CompanyPicker,
    NavbarFooter,
    FAQsBanner,
    WelcomeModal,
    TopBarActions,
    UserSettingsButton,
} from './components';
import { DashboardLayoutProps } from './interfaces';
import { getAvailableMenuItems } from './utils';
import ShowErrorMsgModal from './components/GeneralErrorsModal';

const CustomLink = forwardRef<HTMLAnchorElement, DashboardLinkProps>(
    ({ passHref, ...props }, ref) => <NavLink {...props} ref={ref} />,
);

const onItemClick = (item: DashboardNavItemType) => {
    if (item.meta?.gtmTag) addGoogleTag(item.meta.gtmTag);
};

export const DashboardLayout: FC<DashboardLayoutProps> = ({ children }) => {
    const { pathname, search: locationSearch } = useLocation(),
        { replace } = useHistory(),
        smUp = useMediaQuery(useTheme().breakpoints.up('sm')),
        [items, setItems] = useState<DashboardNavItemType[]>([]),
        [showWelcome, setShowWelcome] = useState(false),
        {
            loading: loadingNewFeature,
            onConfirmNewFeature,
            showNewFeature,
            setShowNewFeature,
        } = useNewFeatureModal(),
        {
            logout,
            loading,
            user,
            getUser,
            selectedCompany: {
                company: {
                    id: companyId = undefined,
                    businessName = undefined,
                    rfc = undefined,
                    avatar = undefined,
                } = {},
                enabledCards = undefined,
                enabledContracts = undefined,
                role = undefined,
            },
            errors,
        } = useContext(sessionContext),
        companyRole = role ? capitalize(role) : role,
        [cardApprovals, setCardApprovals] = useState([]),
        isCardsAdmin = useIsAdmin();

    const onWelcomeClose = () => {
        sessionStorage.setItem('popupShown', 'true');
        setShowWelcome(false);
    };

    const closeApprovalsModal = () => {
        setCardApprovals([]);
    };

    useEffect(() => {
        setItems(getAvailableMenuItems(enabledContracts || false, enabledCards || false));
    }, [enabledCards, enabledContracts]);

    useEffect(() => {
        if (user?.id) {
            if (user!.step < 4) {
                const onboardingPath = '/app/onboarding',
                    { to } = dataSourceRedirection.readMetadata() || {},
                    // Only needed if the user is returning from a platform
                    //  that does not allow multiple redirection urls
                    //  and takes the user to /app/data-sources.
                    //  We then need to preserve the search query provided by
                    //  said platform.
                    search = to === onboardingPath ? locationSearch : undefined;

                replace({
                    search,
                    pathname: onboardingPath,
                });

                if (to === pathname) dataSourceRedirection.confirmLocalRedirection();
            }
        } else {
            getUser();
        }
        if (!sessionStorage.getItem('popupShown') && user?.registerNew) setShowWelcome(true);
        if (!sessionStorage.getItem('newFeatureShown') && user?.showNewFeature)
            setShowNewFeature(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id, user?.step]);

    useHotjarWatcher();

    useEffect(() => {
        const fetchCardApprovals = async () => {
            try {
                const res: any = await fairplayAPI.get(
                    `/cards/v1/card/${companyId}/list-pending-request?page_number=1&page_size=50&ordering=-create_at`,
                    {
                        baseService: 'CARDS',
                    },
                );
                setCardApprovals(res.data.body.results);
            } catch (error) {
                /* istanbul ignore next */
                if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_DSN) {
                    captureException(error);
                } else {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
            }
        };

        if (isCardsAdmin && enabledCards && companyId) {
            fetchCardApprovals();
        }
    }, [companyId, isCardsAdmin, enabledCards]);

    return (
        <FpDashboardLayout
            env={process.env.REACT_APP_ENV_TYPE! as 'development'}
            projectName="INSIGHTS"
            user={{
                // eslint-disable-next-line no-nested-ternary
                name: user
                    ? `${user.firstName} ${user.lastName}`
                    : errors.messages.user
                    ? ''
                    : undefined,
                shortName: user?.firstName,
                legend: companyRole,
                legendWithAction: (
                    <Grid item container alignItems="center" mt="2px">
                        <UserSettingsButton size="sm" />
                        <Typography variant="body1" fontWeight={500} color="primary.dark">
                            {companyRole}
                        </Typography>
                    </Grid>
                ),
                // eslint-disable-next-line no-nested-ternary
                picture: user ? user.avatar || '' : errors.messages.user ? '' : undefined,
            }}
            company={{
                name: businessName || '',
                legend: rfc || '',
                popoverLegend: companyRole,
                picture: companyId ? avatar || '' : '',
            }}
            menuItems={items}
            currentPath={pathname}
            onLogOut={logout}
            Link={CustomLink}
            loading={loading}
            TopbarPopoverContents={<CompanyPicker />}
            TopbarActions={<TopBarActions />}
            Footer={<NavbarFooter />}
            mobileNavbarHeight={154}
            onMenuItemClick={onItemClick}
            logoLink="/app/sources"
        >
            {children}
            {smUp && <FAQsBanner />}

            <ShowErrorMsgModal />

            <WelcomeModal open={showWelcome} onClose={onWelcomeClose} />

            {/* Reusable modal for new feature releases */}
            <NewFeatureModal
                open={showNewFeature}
                loading={loadingNewFeature}
                onConfirm={onConfirmNewFeature}
            />

            <CardApprovalsModal
                open={cardApprovals.length > 0}
                onClose={closeApprovalsModal}
                cardApprovals={cardApprovals}
            />

            <OtpModalNotice />
        </FpDashboardLayout>
    );
};

export default DashboardLayout;
