import { FC, useContext, useState, useEffect, ChangeEvent, MouseEvent } from 'react';
import {
    Grid,
    MenuItem,
    useMediaQuery,
    useTheme,
    Collapse,
    TableRow,
    TableCell,
} from '@mui/material';
import { Table, SearchFilters, getActiveFilters } from 'common-components';
import { Typography, Divider, Alert, Select, DefaultInput, Tooltip, IconBtn } from '@fairplay2/ui';
import SessionContext from 'context/session/sessionContext';
import fairplayAPI from 'utils/api';
import { Info } from '@mui/icons-material';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { useAlert } from 'utils/hooks/useAlert';
import { Contract } from '../interfaces';
import { canSeePayments } from '../utils';
import ContractsTableItem from './components/ContractsTableItem';
import ActiveContractsList from './components/ActiveContractsList';

const statusSearchList = {
        Firmado: 'signed',
        Activo: 'active',
        Terminado: 'finished',
        Cancelado: 'cancelled',
        Suspendido: 'suspended',
    } as any,
    filterContainerStyle = {
        borderRadius: '40px',
        px: { xs: '10px', sm: '20px' },
        '&:hover': {
            backgroundColor: '#F6F6F9',
        },
    };

const ContractsList: FC = () => {
    const theme = useTheme(),
        mdDown = useMediaQuery(theme.breakpoints.down('md')),
        { selectedCompany } = useContext(SessionContext),
        [requestParams, setRequestParams] = useState(''),
        [activeContracts, setActiveContracts] = useState<number | null>(null),
        [contracts, setContracts] = useState<Contract[]>([]),
        [status, setStatus] = useState(''),
        [search, setSearch] = useState(''),
        [filterSearch, setFilterSearch] = useState({
            status: '',
            search: '',
        }),
        [activeFilters, setActiveFilters] = useState(0),
        [page, setPage] = useState(0),
        [rowsPerPage, setRowsPerPage] = useState(5),
        [count, setCount] = useState(0),
        [loading, setLoading] = useState(true),
        { alert, hideAlert, showAlert } = useAlert(),
        [isFilterOff, setIsFilterOff] = useState(true);

    const onSelect = (event: ChangeEvent<HTMLInputElement>) => {
        setStatus(event.target.value);
        if (isFilterOff) setIsFilterOff(false);
    };

    const onChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        document.body.style.overflow = 'auto';
    };

    const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
        if (isFilterOff) setIsFilterOff(false);
    };

    const filterActive = (arr: Contract[]): number =>
        arr.filter((contract) => contract?.status?.id === 'active').length;

    const onFilterSearch = () => {
        const filters = {
            status: statusSearchList[status],
            search,
        };
        setActiveFilters(getActiveFilters(filters));
        setFilterSearch(filters);
    };

    const resetTable = () => {
        setSearch('');
        setStatus('');
        setPage(0);
        setRowsPerPage(5);
        setActiveContracts(null);
        setFilterSearch({
            status: '',
            search: '',
        });
        setActiveFilters(0);
        setIsFilterOff(true);
    };

    // Manages all table dependencies changes
    useEffect(() => {
        if (!selectedCompany.company?.id) return;
        hideAlert();

        if (requestParams) {
            (async () => {
                setLoading(true);
                try {
                    const res: any = await fairplayAPI.get(
                        `/v2/companies/${selectedCompany.company?.id}/contracts${requestParams}`,
                    );
                    const resCount = res.data.body.count;
                    const resArr = res.data.body.results;
                    const newCount = resCount || resArr.length;
                    if (activeContracts === null) {
                        setActiveContracts(filterActive(resArr));
                    }
                    setCount(newCount);
                    setContracts(resArr);
                    setLoading(false);
                } catch (errResponse: ErrorObj | any) {
                    showAlert(
                        errResponse?.error ||
                            'No se pudieron cargar los contratos, intente más tarde',
                    );
                    setLoading(false);
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestParams, selectedCompany.company?.id]);

    // In order to reset the table just on the first render, assigns the company and contract ids to state.
    useEffect(() => {
        if (selectedCompany.company) resetTable();
    }, [selectedCompany]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h4" color="primary.main" mt="10px">
                    Contratos{' '}
                    <Typography component="span" color="primary.dark" fontWeight={700}>
                        {selectedCompany.company?.businessName}
                    </Typography>
                </Typography>
            </Grid>
            <ActiveContractsList />
            <Grid item>
                <Typography
                    variant="subtitle1"
                    color="primary.main"
                    mt={{ xs: '35px', md: 0 }}
                    mb="10px"
                >
                    Listado de contratos
                </Typography>
                <Divider variant="inset" sx={{ height: '5px' }} />
            </Grid>
            <Grid item container justifyContent={{ xs: 'center', md: 'flex-start' }}>
                <SearchFilters
                    isFilterOff={isFilterOff}
                    activeFilters={activeFilters}
                    onFilterSearch={onFilterSearch}
                    resetFilters={resetTable}
                    width={mdDown ? '100%' : '50%'}
                    marginTop={mdDown ? '30px' : '-15px'}
                    marginBottom="10px"
                >
                    <Grid
                        item
                        container
                        minWidth="48%"
                        height="100%"
                        justifyContent="center"
                        alignItems="center"
                        borderRadius="40px"
                        p={{ xs: '20px', md: '0 20px' }}
                        sx={filterContainerStyle}
                    >
                        <DefaultInput
                            id="contractSearch"
                            labelVariant="contained"
                            label="Número de contrato"
                            value={search}
                            onChange={onSearch}
                            placeholder="Ingresa el número"
                            variant="standard"
                        />
                    </Grid>
                    <Divider
                        orientation={mdDown ? 'horizontal' : 'vertical'}
                        variant={mdDown ? 'fullWidth' : 'middle'}
                        flexItem
                        sx={{
                            color: 'background.dark',
                            m: { xs: '10px 0', md: '8px 0' },
                        }}
                    />
                    <Grid
                        item
                        container
                        minWidth="38%"
                        height="100%"
                        justifyContent="center"
                        alignItems="center"
                        borderRadius="40px"
                        p={{ xs: '20px', sm: '0 20px' }}
                        sx={filterContainerStyle}
                    >
                        <Select
                            value={status}
                            onChange={onSelect}
                            labelVariant="contained"
                            label={
                                <Grid container flexDirection="row">
                                    <Typography
                                        variant="body2"
                                        color="text.primary"
                                        fontWeight={500}
                                    >
                                        Estatus
                                    </Typography>
                                    <Tooltip
                                        color="paper"
                                        title={
                                            <Grid container>
                                                <Typography
                                                    variant="body2"
                                                    color="success.main"
                                                    fontWeight={700}
                                                    marginY="2px"
                                                >
                                                    Firmado:{' '}
                                                    <Typography
                                                        color="text.primary"
                                                        fontWeight={500}
                                                        component="span"
                                                    >
                                                        Ya puedes programar dispersiones.
                                                    </Typography>
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="success.main"
                                                    fontWeight={700}
                                                    marginY="2px"
                                                >
                                                    Activo:{' '}
                                                    <Typography
                                                        color="text.primary"
                                                        fontWeight={500}
                                                        component="span"
                                                    >
                                                        Has realizado tu primera dispersión.
                                                    </Typography>
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="info.main"
                                                    fontWeight={700}
                                                    marginY="2px"
                                                >
                                                    Terminado:{' '}
                                                    <Typography
                                                        color="text.primary"
                                                        fontWeight={500}
                                                        component="span"
                                                    >
                                                        Abonaste tu financiamiento en su totalidad.
                                                    </Typography>
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="error.main"
                                                    fontWeight={700}
                                                    marginY="2px"
                                                >
                                                    Cancelado:{' '}
                                                    <Typography
                                                        color="text.primary"
                                                        fontWeight={500}
                                                        component="span"
                                                    >
                                                        No podrás volver a dispersar.
                                                    </Typography>
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="warning.main"
                                                    fontWeight={700}
                                                    marginY="2px"
                                                >
                                                    Suspendido:{' '}
                                                    <Typography
                                                        color="text.primary"
                                                        fontWeight={500}
                                                        component="span"
                                                    >
                                                        Tus dispersiones están detenidas
                                                        temporalmente.
                                                    </Typography>
                                                </Typography>
                                            </Grid>
                                        }
                                        placement="right-start"
                                    >
                                        <IconBtn
                                            color="primary"
                                            sx={{
                                                mt: '-10px',
                                            }}
                                        >
                                            <Info />
                                        </IconBtn>
                                    </Tooltip>
                                </Grid>
                            }
                            variant="standard"
                        >
                            <MenuItem value="">Mostrar todos</MenuItem>
                            {Object.keys(statusSearchList).map((stat) => (
                                <MenuItem value={stat} key={stat}>
                                    {stat}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </SearchFilters>
            </Grid>
            <Grid container pt="10px">
                <Collapse in={alert.open}>
                    <Alert variant="filled" severity={alert.type}>
                        {alert.message}
                    </Alert>
                </Collapse>
            </Grid>
            <Grid item xs={12}>
                <Table
                    // eslint-disable-next-line react/no-unstable-nested-components
                    getTableItem={(contract: Contract, i: number) => (
                        <ContractsTableItem contract={contract} key={i} notify={showAlert} />
                    )}
                    currentItems={contracts}
                    columns={7}
                    loading={loading}
                    pagination={{
                        count,
                        page,
                        rowsPerPage,
                        onPageChange: onChangePage,
                        onRowsPerPageChange: onChangeRowsPerPage,
                    }}
                    externalFilters={filterSearch}
                    emptyItemsMessage="No hemos encontrado contratos disponibles"
                    onParamsQueryChange={setRequestParams}
                >
                    <TableRow>
                        <TableCell>
                            <Typography color="text.primary" noWrap>
                                # Contrato
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary" noWrap>
                                Monto financiado
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary" noWrap>
                                Límite mensual
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary" noWrap>
                                Estatus
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary" noWrap>
                                Información adicional
                            </Typography>
                        </TableCell>
                        <TableCell align="center">
                            <Typography color="text.primary" noWrap>
                                Acciones
                            </Typography>
                        </TableCell>
                    </TableRow>
                </Table>
            </Grid>
            {!loading && contracts.some(canSeePayments) && (
                <Alert role="note" severity="info" sx={{ mr: { sm: 6 }, mt: 1, maxWidth: '568px' }}>
                    Al descargar el estado o reporte de movimientos, podrás revisar todos los
                    movimientos de tu financiamiento hasta el día hábil inmediato anterior.
                </Alert>
            )}
        </Grid>
    );
};

export default ContractsList;
