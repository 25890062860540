import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, FairplayLogoGraphic } from '@fairplay2/ui';
import { Footer } from 'common-components';
import { Grid } from '@mui/material';
import { BiColumnContainer, LeftColumnContainer, RightColumnContainer } from '../styles';
import { BicolumnProps } from '../interfaces';
import { useActiveStep } from '../context/activeStep-context';
import SimpleStepper from './SimpleStepper';

export const BicolumnLayout: FC<BicolumnProps> = ({ steps, children }) => {
    const activeStep = useActiveStep();

    return (
        <BiColumnContainer container>
            <LeftColumnContainer item container sm={12} md={4} justifyContent="center">
                <Grid
                    item
                    container
                    xs={12}
                    md={8}
                    xl={7}
                    height="fit-content"
                    marginTop={{ xs: 0, md: '30%' }}
                >
                    <Grid item xs={12}>
                        <RouterLink to="/">
                            <FairplayLogoGraphic width={201} />
                        </RouterLink>
                        <Typography
                            variant="subtitle2"
                            color="secondary.main"
                            fontWeight={700}
                            mt="-10px"
                        >
                            INSIGHTS
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        sx={{
                            display: {
                                xs: 'none',
                                sm: 'none',
                                md: 'flex',
                            },
                        }}
                    >
                        <Typography
                            fontSize="24px"
                            paragraph
                            fontWeight={400}
                            color="primary.contrastText"
                            mt="35px"
                            mb="20px"
                            maxWidth="332px"
                            lineHeight="initial"
                        >
                            Analiza el desempeño de tus ventas, obtén un financiamiento y gestiónalo
                            aquí.
                        </Typography>
                        <Typography
                            fontSize="12px"
                            variant="body2"
                            color="primary.contrastText"
                            fontWeight={700}
                            mb="20px"
                        >
                            ¿Ya tienes una cuenta?{' '}
                            <RouterLink style={{ textDecoration: 'none' }} to="/">
                                <Typography
                                    fontSize="inherit"
                                    component="span"
                                    color="secondary.main"
                                    fontWeight={700}
                                >
                                    Inicia sesión aquí
                                </Typography>
                            </RouterLink>
                        </Typography>
                        {!window.location.pathname.includes('/onboarding/user/') && (
                            <SimpleStepper steps={steps} />
                        )}
                    </Grid>
                    <Footer />
                </Grid>
            </LeftColumnContainer>
            <RightColumnContainer
                item
                container
                md={8}
                justifyContent="center"
                alignItems="flex-start"
                activeStep={activeStep}
            >
                {children}
            </RightColumnContainer>
        </BiColumnContainer>
    );
};
