import { FC, useContext, useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Collapse, Grid, useMediaQuery, useTheme } from '@mui/material';
import { Info, KeyboardArrowLeft } from '@mui/icons-material';
import { Alert, DefaultInput, IconBtn, Tooltip, Typography } from '@fairplay2/ui';
import { CurrencyInput } from '@fairplay2/ui-x-currencies';
import { useAlert } from 'utils/hooks';
import { formatAmount } from 'utils/parsing';
import SessionContext from 'context/session/sessionContext';
import { FairplayCard } from '../components';
import { CardDetailSkeleton, CardDetailTabs, CardInfoModal } from './components';
import { useGetCardDetail } from './hooks';

export const CardDetail: FC = () => {
    const smUp = useMediaQuery(useTheme().breakpoints.up('sm')),
        { selectedCompany } = useContext(SessionContext),
        { push, replace } = useHistory(),
        isFirstRender = useRef(true),
        { id: cardId } = useParams<{ id: string }>(),
        { alert, hideAlert, showAlert } = useAlert(),
        {
            cardValues: {
                alias,
                creditLimit,
                status,
                fairplayInternId,
                availableToday,
                requestedPerPeriod,
                holdAmount,
            },
            error,
            loading,
        } = useGetCardDetail(cardId),
        [displayCardInfoModal, setDisplayCardInfoModal] = useState(false);

    // Shows an alert in case there is an error obtaining the cards detail
    useEffect(() => {
        hideAlert();

        if (error) {
            showAlert(
                error?.error || 'No se pudo obtener los datos del contrato, Intente más tarde',
            );
        } else if (status?.id === 'blocked')
            showAlert('Contrato suspendido. Ponte en contacto con nosotros.', 'warning', false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, status]);

    useEffect(() => {
        if (!selectedCompany.company?.id) return;
        if (!isFirstRender.current) {
            replace('/app/sources');
            return;
        }

        isFirstRender.current = false;
    }, [selectedCompany.company?.id, replace]);

    return (
        <>
            {displayCardInfoModal && (
                <CardInfoModal onClose={() => setDisplayCardInfoModal(false)} cardId={cardId} />
            )}

            <Grid container marginTop="10px">
                <IconBtn
                    variant="filled"
                    color="primary"
                    size="small"
                    onClick={() => push('/app/disbursements/cards')}
                    sx={{
                        svg: { fontSize: '1.5em !important' },
                        p: '0 !important',
                    }}
                >
                    <KeyboardArrowLeft titleAccess="Regresar a tarjetas solicitadas" />
                </IconBtn>
                <Typography variant="h4" color="primary.main" ml="15px">
                    Detalle de{' '}
                    <Typography component="span" color="primary.dark" fontWeight={700}>
                        tarjeta
                    </Typography>
                </Typography>
            </Grid>

            {smUp && (
                <Typography color="primary.main" marginTop="30px">
                    Estos son los movimientos de tu tarjeta Fairplay
                </Typography>
            )}

            <Grid item container xs={12} justifyContent="flex-end">
                <Collapse in={alert.open}>
                    <Alert severity={alert.type} sx={{ mt: 1 }}>
                        {alert.message}
                    </Alert>
                </Collapse>
            </Grid>

            <Grid
                container
                mt="25px"
                alignItems={{ xs: 'center', sm: 'flex-start' }}
                flexDirection={{ xs: 'column', md: 'row' }}
                columnGap={{ xs: 0, md: '25px' }}
                rowGap={{ xs: '25px', sm: '30px' }}
            >
                <Grid item sx={{ width: { xs: '300px', sm: '350px' } }}>
                    <FairplayCard
                        alias={alias}
                        creditLimit={creditLimit}
                        status={status}
                        loading={loading}
                        readMode
                        onOpenInfoModal={() => setDisplayCardInfoModal(true)}
                    />
                </Grid>

                {loading ? (
                    <CardDetailSkeleton />
                ) : (
                    <Grid item container flexGrow={1} flexBasis={0}>
                        <Grid
                            container
                            columnSpacing={{ sm: '20px' }}
                            rowSpacing={{ xs: '25px', md: '30px' }}
                            maxWidth={{ xs: '100%', md: '560px' }}
                            minWidth={{ xs: '100%', md: '390px' }}
                        >
                            <Grid item xs={12} sm={6}>
                                <DefaultInput
                                    id="associated-contract"
                                    type="text"
                                    label="Contrato asociado"
                                    labelVariant="external"
                                    value={`Contrato ${fairplayInternId || ''}`}
                                    disabled
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                {!availableToday?.amount ? (
                                    <DefaultInput
                                        type="text"
                                        label="Disponible hoy"
                                        labelVariant="external"
                                        defaultValue="Sin disponible"
                                        disabled
                                    />
                                ) : (
                                    <CurrencyInput
                                        disabled
                                        id="available-today"
                                        label="Disponible hoy"
                                        labelVariant="external"
                                        value={formatAmount(availableToday?.amount ?? 0)}
                                        inputAlignment="right"
                                        thousandSeparator
                                        sx={(theme) => ({
                                            '.MuiInputAdornment-root': {
                                                svg: {
                                                    color: theme.palette.primary.main,
                                                },
                                            },
                                        })}
                                    />
                                )}
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <CurrencyInput
                                    disabled
                                    id="requested-per-period"
                                    label="Solicitado en el periodo"
                                    labelVariant="external"
                                    value={formatAmount(requestedPerPeriod?.amount ?? 0)}
                                    inputAlignment="right"
                                    thousandSeparator
                                    sx={(theme) => ({
                                        '.MuiInputAdornment-root': {
                                            svg: {
                                                color: theme.palette.primary.main,
                                            },
                                        },
                                    })}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} alignSelf="flex-end">
                                <CurrencyInput
                                    disabled
                                    id="hold-amount"
                                    label={
                                        <Typography
                                            variant="label"
                                            component="label"
                                            htmlFor="hold-amount"
                                        >
                                            Monto congelado
                                            <Tooltip
                                                aria-label="Información del monto congelado"
                                                variant="outlined"
                                                color="paper"
                                                placement="top-start"
                                                distance={5}
                                                size="big"
                                                title={
                                                    <Grid container maxWidth="170px">
                                                        <Typography
                                                            variant="body2"
                                                            color="text.primary"
                                                            fontWeight={500}
                                                        >
                                                            Es el dinero que está en proceso de
                                                            pago, transferencia o cobro y que se
                                                            bloqueará para asegurar la cantidad y
                                                            hacer la operación.
                                                        </Typography>
                                                    </Grid>
                                                }
                                            >
                                                <IconBtn
                                                    color="primary"
                                                    sx={{
                                                        mt: '-8px',
                                                        position: 'absolute',
                                                    }}
                                                >
                                                    <Info />
                                                </IconBtn>
                                            </Tooltip>
                                        </Typography>
                                    }
                                    labelVariant="external"
                                    value={formatAmount(holdAmount?.amount ?? 0)}
                                    inputAlignment="right"
                                    thousandSeparator
                                    sx={(theme) => ({
                                        '.MuiInputAdornment-root': {
                                            svg: {
                                                color: theme.palette.primary.main,
                                            },
                                        },
                                    })}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>

            <CardDetailTabs cardId={cardId} />
        </>
    );
};

export default CardDetail;
