import { FC, useState, useEffect } from 'react';
import { Grid, useMediaQuery, useTheme, Collapse } from '@mui/material';
import { DefaultInput, DefaultBtn, Autocomplete, Alert, Skeleton } from '@fairplay2/ui';
import fairplayAPI from 'utils/api';
import { Step2Props, Country } from '../interfaces';
import IndustriesModal from './IndustriesModal';

const Step2: FC<Step2Props> = ({
    alert,
    vendorType,
    values,
    errors,
    onError,
    onValueChange,
    onStepChange,
    onBlur,
    onCountry,
    onIndustrySelect,
}) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        [isModalOpen, setIsModalOpen] = useState(false),
        [loading, setLoading] = useState(true),
        [countries, setCountries] = useState<Country[]>([]),
        lengthWarning = `*Usar ${
            vendorType === 'national' ? 'una razón social' : 'un nombre legal'
        } de más de 40 caracteres puede demorar las transacciones`;

    const onModalOpen = () => {
        setIsModalOpen(true);
    };

    const onModalClose = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        onError('supplierCountry', '');

        const fetchCountries = async () => {
            try {
                const res: any = await fairplayAPI.get('/v1/locations/countries');
                const filteredCountries = res.data.body.results.filter(
                    (country: Country) => country.id !== 1,
                );
                setCountries(filteredCountries);
                setLoading(false);
            } catch {
                onError('supplierCountry', 'Hubo un error al cargar los países');
                setLoading(false);
            }
        };

        if (vendorType === 'international') fetchCountries();
    }, [onError, vendorType]);

    return (
        <>
            <Grid container spacing={4} mt="30px">
                <Grid item xs={12} sm={6}>
                    <DefaultInput
                        labelVariant="contained"
                        id="supplier-alias"
                        name="supplierAlias"
                        label="Alias:*"
                        placeholder="Elige un nombre para el proveedor"
                        value={values.supplierAlias}
                        onChange={onValueChange}
                        onBlur={() => onBlur('supplierAlias', 'Ingresa el alias')}
                        error={errors.supplierAlias}
                    />
                </Grid>

                {vendorType === 'national' ? (
                    <Grid item xs={12} sm={6}>
                        <DefaultInput
                            labelVariant="contained"
                            id="supplier-rfc"
                            name="supplierRfc"
                            label="R.F.C.:*"
                            placeholder="Ej. NL161205363."
                            value={values.supplierRfc.toUpperCase()}
                            onChange={onValueChange}
                            onBlur={() => onBlur('supplierRfc', 'Ingresa el R.F.C.')}
                            error={errors.supplierRfc}
                        />
                    </Grid>
                ) : (
                    <Grid item xs={12} sm={6}>
                        <DefaultInput
                            labelVariant="contained"
                            id="supplier-tax-id"
                            name="supplierTaxId"
                            label="TAX ID:*"
                            placeholder="Ej. NL161205363."
                            value={values.supplierTaxId}
                            onChange={onValueChange}
                            onBlur={() => onBlur('supplierTaxId', 'Ingresa el TAX ID')}
                            error={errors.supplierTaxId}
                        />
                    </Grid>
                )}

                <Grid item xs={12} sm={6}>
                    <DefaultInput
                        labelVariant="contained"
                        id="supplier-registered-name"
                        name="supplierRegisteredName"
                        label={vendorType === 'national' ? 'Razón social:*' : 'Nombre legal:*'}
                        placeholder="Ej. Mi tienda S. de R.L. de C.V."
                        value={values.supplierRegisteredName}
                        onChange={onValueChange}
                        onBlur={() =>
                            onBlur(
                                'supplierRegisteredName',
                                `Ingresa ${
                                    vendorType === 'national'
                                        ? 'la razón social:'
                                        : 'el nombre legal:'
                                }`,
                            )
                        }
                        error={
                            errors.supplierRegisteredName ||
                            (values.supplierRegisteredName.length > 40 ? lengthWarning : '')
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DefaultInput
                        labelVariant="contained"
                        id="supplier-website"
                        name="supplierWebsite"
                        label="Sitio web:"
                        placeholder="www.mitienda.com"
                        value={values.supplierWebsite}
                        onChange={onValueChange}
                        onBlur={() => onBlur('supplierWebsite', '')}
                        error={errors.supplierWebsite}
                    />
                </Grid>

                {vendorType === 'international' && (
                    <>
                        <Grid item xs={12} sm={6}>
                            {loading ? (
                                <Skeleton
                                    width="100%"
                                    height="53px"
                                    data-testid="countries-skeleton-loader"
                                />
                            ) : (
                                <Autocomplete
                                    labelVariant="contained"
                                    id="supplier-country"
                                    name="supplierCountry"
                                    disabled={countries.length === 0}
                                    options={countries}
                                    getOptionLabel={(option) => option.name}
                                    value={values.supplierCountry}
                                    onChange={(_: any, newValue: any) => {
                                        onCountry(newValue);
                                    }}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    label="País:*"
                                    placeholder="Seleccionar país"
                                    onBlur={() => onBlur('supplierCountry', 'Ingresa el País')}
                                    error={errors.supplierCountry}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DefaultInput
                                labelVariant="contained"
                                id="supplier-state"
                                name="supplierState"
                                label="Ciudad:*"
                                placeholder="Escribe la ciudad"
                                value={values.supplierState}
                                onChange={onValueChange}
                                onBlur={() => onBlur('supplierState', 'Ingresa la ciudad')}
                                error={errors.supplierState}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DefaultInput
                                labelVariant="contained"
                                id="supplier-zip-code"
                                name="supplierZipCode"
                                label="C.P.:*"
                                placeholder="Escribe el código postal"
                                value={values.supplierZipCode}
                                onChange={onValueChange}
                                onBlur={() => onBlur('supplierZipCode', 'Ingresa la código Postal')}
                                error={errors.supplierZipCode + errors.supplierAddress}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DefaultInput
                                labelVariant="contained"
                                id="supplier-street"
                                name="supplierStreet"
                                label="Vialidad y número:*"
                                placeholder="Calle, avenida, etc. y número"
                                value={values.supplierStreet}
                                onChange={onValueChange}
                                onBlur={() =>
                                    onBlur('supplierStreet', 'Ingresa la vialidad y número')
                                }
                                error={errors.supplierStreet + errors.supplierAddress}
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={12} sm={6}>
                    <DefaultInput
                        labelVariant="contained"
                        id="supplier-industry"
                        name="industry"
                        label="Industria:*"
                        placeholder="Selecciona la industria"
                        value={values.industry?.description || ''}
                        onClick={onModalOpen}
                        onBlur={() => onBlur('industry', 'Ingresa la Industria')}
                        error={errors.industry}
                        sx={{ mb: '10px' }}
                    />
                </Grid>
            </Grid>
            <Grid item container xs={12} justifyContent="flex-end" padding="10px 0px">
                <Collapse sx={{ width: { xs: '100%', sm: 'initial' } }} in={alert.open}>
                    <Alert sx={{ justifyContent: 'center' }} severity={alert.type}>
                        {alert.message}
                    </Alert>
                </Collapse>
            </Grid>
            <Grid container justifyContent="flex-end">
                <DefaultBtn
                    fullWidth={smDown}
                    size="small"
                    variant="outlined"
                    onClick={(e) => onStepChange(e, 0)}
                    sx={{
                        mb: { xs: '10px', sm: 0 },
                    }}
                >
                    Atrás
                </DefaultBtn>
                <DefaultBtn
                    fullWidth={smDown}
                    size="small"
                    onClick={(e) => onStepChange(e, 2)}
                    sx={{
                        mb: { xs: '20px', sm: 0 },
                        ml: { xs: 0, sm: '20px' },
                    }}
                >
                    Continuar
                </DefaultBtn>
            </Grid>
            {isModalOpen && (
                <IndustriesModal
                    currentSelection={values.industry}
                    isModalOpen={isModalOpen}
                    onIndustrySelect={onIndustrySelect}
                    onModalClose={onModalClose}
                />
            )}
        </>
    );
};

export default Step2;
