import { Box, Grid } from '@mui/material';
import { Typography } from '@fairplay2/ui';

export const NoCompaniesFound = () => (
    <Box p={2}>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item>
                <svg
                    width="63"
                    height="63"
                    viewBox="0 0 63 63"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M53.55 37.8H50.4C48.6675 37.8 47.25 39.2175 47.25 40.95V50.4C47.25 52.1325 48.6675 53.55 50.4 53.55C52.1325 53.55 53.55 52.1325 53.55 50.4V44.1C58.779 44.1 63 39.879 63 34.65C63 29.421 58.779 25.2 53.55 25.2V22.05C53.55 9.8595 43.6905 0 31.5 0C19.3095 0 9.45 9.8595 9.45 22.05V25.2C4.221 25.2 0 29.421 0 34.65C0 39.879 4.221 44.1 9.45 44.1H9.6705C11.1195 54.747 20.223 62.9685 31.2795 63H56.7C58.4325 63 59.85 61.803 59.85 60.0705C59.85 58.338 58.4325 56.9205 56.7 56.9205H31.2795C22.68 56.7945 15.75 49.77 15.75 41.1705C15.75 41.139 15.7185 41.1075 15.7185 41.0445C15.7185 41.013 15.75 40.9815 15.75 40.95C15.75 39.2175 14.3325 37.8 12.6 37.8H9.45C7.7175 37.8 6.3 36.3825 6.3 34.65C6.3 32.9175 7.7175 31.5 9.45 31.5H12.6C14.3325 31.5 15.75 30.0825 15.75 28.35V22.05C15.75 13.356 22.806 6.3 31.5 6.3C40.194 6.3 47.25 13.356 47.25 22.05V28.35C47.25 30.0825 48.6675 31.5 50.4 31.5H53.55C55.2825 31.5 56.7 32.9175 56.7 34.65C56.7 36.3825 55.2825 37.8 53.55 37.8Z"
                        fill="black"
                        fillOpacity="0.2"
                    />
                    <path
                        d="M25.2 25.2004C26.9397 25.2004 28.35 23.7901 28.35 22.0504C28.35 20.3107 26.9397 18.9004 25.2 18.9004C23.4603 18.9004 22.05 20.3107 22.05 22.0504C22.05 23.7901 23.4603 25.2004 25.2 25.2004Z"
                        fill="black"
                        fillOpacity="0.2"
                    />
                    <path
                        d="M37.8 25.2004C39.5397 25.2004 40.95 23.7901 40.95 22.0504C40.95 20.3107 39.5397 18.9004 37.8 18.9004C36.0603 18.9004 34.65 20.3107 34.65 22.0504C34.65 23.7901 36.0603 25.2004 37.8 25.2004Z"
                        fill="black"
                        fillOpacity="0.2"
                    />
                    <path
                        d="M37.8 37.8C37.8 34.335 34.965 31.5 31.5 31.5C28.035 31.5 25.2 34.335 25.2 37.8V44.1H37.8V37.8Z"
                        fill="black"
                        fillOpacity="0.2"
                    />
                </svg>
            </Grid>

            <Grid item xs>
                <Typography variant="body1" color="primary.main" fontWeight={700} mb="5px">
                    Nada por aquí
                </Typography>
                <Typography variant="body1" color="text.primary" fontWeight={700}>
                    No hemos encontrado la empresa que buscas. Intenta con otra búsqueda.
                </Typography>
            </Grid>
        </Grid>
    </Box>
);

export default NoCompaniesFound;
