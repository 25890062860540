import { FC, useEffect, useState, useRef } from 'react';
import { Collapse, Grid, TableCell, TableRow, TableSortLabel } from '@mui/material';
import { ArrowDownward, ExpandMore } from '@mui/icons-material';
import { Typography, Alert } from '@fairplay2/ui';
import { Table } from 'common-components';
import fairplayAPI from 'utils/api';
import { asSorting } from 'utils/lists';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { useTableContext } from '../../../state/TableContext';
import { ApprovalRequest } from '../../../interfaces';
import { CardParamsProps } from '../../interfaces';
import ApprovalRequestItem from './ApprovalRequestItem';

const ApprovalRequestsTable: FC<CardParamsProps> = ({ cardId }) => {
    const {
            externalFilters,
            orderCriteria,
            order,
            page,
            setPage,
            rowsPerPage,
            requestParams,
            setRequestParams,
            resetFilterValues,
            onChangeRowsPerPage,
            onSortDate,
            hideAlert,
            showAlert,
            alert,
        } = useTableContext(),
        [loading, setLoading] = useState(true),
        [approvalRequests, setApprovalRequests] = useState<ApprovalRequest[]>([]),
        [count, setCount] = useState(0),
        waitForValidPagination = useRef(false);

    // reset filters and wait for requestParams to be full
    useEffect(() => {
        if (cardId) {
            resetFilterValues();
            waitForValidPagination.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardId]);

    useEffect(() => {
        if (!cardId) return;

        // TODO: Is it possible to improve Table component as to avoid having this extra check?
        if (waitForValidPagination.current) {
            waitForValidPagination.current = false;
            if (!requestParams.includes('page=1&')) return;
        }

        hideAlert();

        (async () => {
            setLoading(true);
            try {
                const res: any = await fairplayAPI.get(
                        `/cards/v1/card/list-request${requestParams}`,
                        {
                            baseService: 'CARDS',
                        },
                    ),
                    resArr = res.data.body?.results || [],
                    resCount = res.data.body?.count || resArr.length;

                setCount(resCount);
                setApprovalRequests(resArr);
            } catch (errResponse: ErrorObj | any) {
                showAlert(
                    errResponse?.error ||
                        'No se pudieron cargar las solicitudes, intente más tarde',
                );
            } finally {
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestParams, cardId]);

    return (
        <>
            <Grid item mt="10px" container xs={12} justifyContent="flex-end">
                <Collapse in={alert.open}>
                    <Alert severity={alert.type} sx={{ mb: '15px' }}>
                        {alert.message}
                    </Alert>
                </Collapse>
            </Grid>

            <Grid item container xs={12} justifyContent="center">
                <Table
                    sx={{
                        marginBottom: 4,
                    }}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    getTableItem={(approvalRequest: ApprovalRequest) => (
                        <ApprovalRequestItem
                            key={approvalRequest.id}
                            approvalRequest={approvalRequest}
                        />
                    )}
                    currentItems={approvalRequests}
                    columns={3}
                    loading={loading}
                    pagination={{
                        count,
                        page,
                        rowsPerPage,
                        onPageChange: (_, newPage) => setPage(newPage),
                        onRowsPerPageChange: onChangeRowsPerPage,
                    }}
                    externalFilters={{
                        ...externalFilters,
                        ...{ ordering: asSorting(orderCriteria, order) },
                    }}
                    emptyItemsMessage="No hemos encontrado solicitudes disponibles"
                    onParamsQueryChange={setRequestParams}
                >
                    <TableRow>
                        <TableCell
                            aria-sort={
                                // eslint-disable-next-line no-nested-ternary
                                orderCriteria === 'created_at'
                                    ? order === 'desc'
                                        ? 'descending'
                                        : 'ascending'
                                    : 'none'
                            }
                        >
                            <TableSortLabel
                                disabled={!approvalRequests.length}
                                active={orderCriteria === 'created_at'}
                                data-criteria="created_at"
                                direction={orderCriteria === 'created_at' ? order : 'desc'}
                                onClick={onSortDate}
                                IconComponent={
                                    orderCriteria === 'created_at' ? ArrowDownward : ExpandMore
                                }
                            >
                                <Typography component="span" color="text.primary">
                                    Solicitada
                                </Typography>
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary">Solicitó</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary">Estatus</Typography>
                        </TableCell>
                    </TableRow>
                </Table>
            </Grid>
        </>
    );
};

export default ApprovalRequestsTable;
