import { FC, useEffect, useState } from 'react';
import { DialogTitle, Grid } from '@mui/material';
import { DefaultBtn, Modal, Typography } from '@fairplay2/ui';
import { WomanFoldersSvg, WomanFoldersTwoSvg } from 'assets/SVGs';
import { UploadModalProps } from '../interfaces';
import FileUploader from './FileUploader';

const UploadModal: FC<UploadModalProps> = ({ open, closeModal, alertData, file, setFile }) => {
    const [loading, setLoading] = useState(false),
        [confirmation, setConfirmation] = useState(false);

    const onClose = () => {
        setFile(null);
        alertData.hideAlert();
        closeModal(confirmation);
    };

    useEffect(() => {
        if (!open) return;
        setConfirmation(false);
    }, [open]);

    return (
        <Modal
            open={open}
            onClose={closeModal}
            disableBackdropClick
            disableEscapeKeyDown
            closeIconButton={!loading}
            maxWidth={606}
        >
            <Grid container justifyContent="center">
                <Grid container justifyContent="center">
                    {confirmation ? <WomanFoldersSvg /> : <WomanFoldersTwoSvg />}
                </Grid>

                {confirmation ? (
                    <>
                        <Typography
                            component={DialogTitle}
                            variant="subtitle1"
                            color="primary.main"
                            mt="30px"
                            width="100%"
                            lineHeight={1.1875}
                            padding={0}
                        >
                            ¡Éxito! Archivo cargado
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            color="text.primary"
                            mt="15px"
                            mb="30px"
                            width="100%"
                            lineHeight={1.1875}
                        >
                            Podrás verificar el estatus del archivo en el historial de órdenes.
                        </Typography>
                        <Grid item xs={12} sm="auto">
                            <DefaultBtn size="small" fullWidth onClick={onClose}>
                                Entendido
                            </DefaultBtn>
                        </Grid>
                    </>
                ) : (
                    <FileUploader
                        file={file}
                        setFile={setFile}
                        onCancel={onClose}
                        onComplete={() => setConfirmation(true)}
                        awaitingOperation={loading}
                        setAwaitingOperation={setLoading}
                        alertData={alertData}
                        Instructions={
                            <Typography
                                component={DialogTitle}
                                variant="subtitle1"
                                color="primary.main"
                                mt="20px"
                                mb="10px"
                                textAlign="center"
                                width="100%"
                                padding={0}
                            >
                                ¿Subir este archivo?
                            </Typography>
                        }
                    />
                )}
            </Grid>
        </Modal>
    );
};

export default UploadModal;
