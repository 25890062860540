import { Grid } from '@mui/material';
import { Typography, DefaultBtn } from '@fairplay2/ui';
import { HackmetrixLogoSvg, HackmetrixLogoColorSvg } from 'assets/SVGs';
import logoNYCE from 'assets/img/logo_nyce.png';
import logoIQNET from 'assets/img/logo_iqnet.png';
import { CertificatesProps } from './Certificates.types';

// TODO: Unify with utils/files createFile
const openLink = (url: string) => {
    const link = document.createElement('a');
    link.style.display = 'none';
    link.target = '_blank';
    link.rel = 'noreferrer';
    link.href = url;
    link.click();
    link.remove();
};

export const Certificates = ({ useCompact, ...props }: CertificatesProps) => (
    <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        bgcolor={useCompact ? 'transparent' : 'background.dark'}
        width="max-content"
        p={useCompact ? 0 : 1.42}
        borderRadius="5px"
        {...props}
        sx={{
            position: 'absolute',
            bottom: '45px',
            // Forcing scale as Chrome does not allow to render
            //  font-sizes below 10px
            transform: 'scale(0.7)',
            ...(props.sx || {}),
        }}
    >
        {useCompact ? (
            <Grid container justifyContent="center" alignItems="center" mb="3px">
                <Typography
                    variant="body2"
                    color="primary.contrastText"
                    mr={1}
                    fontSize={11.42}
                    display="inline-block"
                    fontFamily="Helvetica"
                >
                    Sitio verificado por
                </Typography>
                <HackmetrixLogoSvg width={85.25} title="Hackmetrix" height={16.9} />
            </Grid>
        ) : (
            <Grid container justifyContent="center" alignItems="center" columnSpacing={2} mb={1}>
                <Grid item mb="-3px">
                    <HackmetrixLogoColorSvg title="Logo Hackmetrix" width={137.41} height={27.22} />
                </Grid>
                <Grid item>
                    <DefaultBtn
                        variant="text"
                        onClick={() =>
                            openLink(
                                'https://fairplay-cdn.s3.us-west-2.amazonaws.com/certificates/IQNET_2023.pdf',
                            )
                        }
                        sx={{ p: 0 }}
                    >
                        <img
                            src={logoIQNET}
                            alt="Certificado IQNET ISO/IEC 27001:2013"
                            width={65}
                        />
                    </DefaultBtn>
                </Grid>
                <Grid item mb={-0.6}>
                    <DefaultBtn
                        variant="text"
                        onClick={() =>
                            openLink(
                                'https://fairplay-cdn.s3.us-west-2.amazonaws.com/certificates/NYCE_2023.pdf',
                            )
                        }
                        sx={{ p: 0 }}
                    >
                        <img
                            src={logoNYCE}
                            alt="Certificado NYCE NMX-l-27001-NYCE-2015 / ISO/IEC 27001:2013"
                            width={77.14}
                        />
                    </DefaultBtn>
                </Grid>
            </Grid>
        )}
        <Typography
            fontSize={useCompact ? 10 : 11.42}
            color={useCompact ? 'primary.contrastText' : 'black'}
            textAlign="center"
            lineHeight={1.6}
            fontFamily="Helvetica"
            mt="5px"
        >
            NMX-l-27001-NYCE-2015 / ISO/IEC 27001:2013 MX-2023CRI-256 <br />
            Sistema de Gestión de Seguridad de la Información
        </Typography>
    </Grid>
);
