import { FC, Fragment } from 'react';
import { Grid } from '@mui/material';
import { Divider, Skeleton } from '@fairplay2/ui';

const BankAccountsSkeleton: FC = () => (
    <Grid container>
        <Grid container item xs={12} justifyContent="flex-end">
            <Skeleton variant="rectangular" width={183.34} height={38} sx={{ mt: '10px' }} />
            <Skeleton
                variant="rectangular"
                width={146.77}
                height={38}
                sx={{
                    mt: '10px',
                    ml: { xs: 0, sm: '20px' },
                }}
            />
        </Grid>
        <Grid container item spacing={4} marginTop="20px" data-testid="vendor-account-skeleton">
            {[1, 2].map((_, i) => (
                <Fragment key={_}>
                    {i > 0 && (
                        <Grid item xs={12}>
                            <Divider sx={{ color: 'background.dark' }} />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                        <Skeleton variant="rectangular" height={50} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Skeleton variant="rectangular" height={50} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Skeleton variant="rectangular" height={50} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Skeleton variant="rectangular" height={50} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Skeleton variant="rectangular" height={50} />
                    </Grid>
                </Fragment>
            ))}
        </Grid>
    </Grid>
);

export default BankAccountsSkeleton;
