import { FC, ChangeEvent, FormEvent, useState, FocusEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Collapse, useTheme, useMediaQuery } from '@mui/material';
import {
    Modal,
    Typography,
    DefaultBtn,
    DefaultInput,
    InterpunctLoader,
    Alert,
} from '@fairplay2/ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { AlertProps } from 'utils/interfaces';
import { dataSourceRedirection } from 'components/dashboard/data-sources/utils';
import fairplayAPI from 'utils/api';
import { toFormData } from 'utils/parsing';
import { useDataSources } from '../../context/DataSourcesContext';
import ConnectorFormTitle from '../components/ConnectorFormTitle';
import { ConnectorProps, WooCommerceForm } from '../interfaces';

const EMPTY_VALUE_ERRORS: WooCommerceForm = {
    storeUrl: 'Ingresa la url de la tienda',
};

const WooCommerce: FC<Omit<ConnectorProps, 'connector'>> = ({ open, onClose }) => {
    const { baseAPI } = useDataSources(),
        { pathname } = useLocation(),
        theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        [values, setValues] = useState<WooCommerceForm>({
            storeUrl: '',
        }),
        [errors, setErrors] = useState<WooCommerceForm>({
            storeUrl: '',
        }),
        [loading, setLoading] = useState(false),
        [alert, setAlert] = useState<AlertProps>({
            message: '',
            type: 'error',
            open: false,
        });

    const closeErrorAlert = () => setAlert({ ...alert, open: false });

    const showErrorAlert = (error: string, type: AlertProps['type']) =>
        setAlert({ message: error, type, open: true });

    const onChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
        closeErrorAlert();
        setValues((prev) => ({ ...prev, [name]: value }));
    };

    const onBlur = (e: FocusEvent<HTMLInputElement>) => {
        const name = e.target.name as keyof WooCommerceForm;
        setErrors((prevState) => ({
            ...prevState,
            [name]: values[name] ? '' : EMPTY_VALUE_ERRORS[name],
        }));
    };

    const clearForm = () => {
        setValues((prev) => ({ ...prev, storeUrl: '' }));
        setErrors({ storeUrl: '' });
        setAlert((prev) => ({ ...prev, open: false }));
    };

    const onConnect = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        closeErrorAlert();

        const data = toFormData({
            platform: 'woocommerce-v2',
            storeUrl: values.storeUrl,
            ...(pathname === '/app/onboarding' && { onboarding: 'true' }),
        });
        let msg =
            'No se pudo realizar la conexión, verifique sus credenciales e intente nuevamente';
        if (baseAPI) {
            try {
                const res: any = await fairplayAPI.post(baseAPI, data);
                dataSourceRedirection.create(res.data.body.results.redirectUrl, {
                    from: 'woocommerce',
                });
            } catch (errResponse: ErrorObj | any) {
                if (errResponse.type === 'form')
                    setErrors((prev) => ({ ...prev, ...errResponse.error }));
                else {
                    if (errResponse?.error) msg = errResponse.error;
                    showErrorAlert(msg, 'error');
                }
                setLoading(false);
            }
        } else showErrorAlert(msg, 'error');
    };

    const onCloseModal = () => {
        document.body.style.overflow = '';
        clearForm();
        onClose();
    };
    return (
        <Modal
            disableBackdropClick
            open={open}
            onClose={onCloseModal}
            maxWidth={640}
            disableEscapeKeyDown
            title={<ConnectorFormTitle groupKey="woocommerce" />}
            sx={{
                '.MuiDialogContent-root': {
                    pt: 0,
                },
            }}
        >
            <Grid container spacing={4} component="form" onSubmit={onConnect}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="text.primary">
                        Para que podamos conectar tu tienda con nuestra plataforma, es necesario que
                        nos proporciones la siguiente información.
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <DefaultInput
                        id="woocommerce-store-url"
                        name="storeUrl"
                        labelVariant="external"
                        label="URL de la tienda"
                        type="text"
                        value={values.storeUrl}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={errors.storeUrl}
                        required
                        InputLabelProps={{ required: false }}
                    />
                </Grid>

                <Grid item container xs={12} justifyContent="center" paddingTop="0 !important">
                    <Collapse in={alert.open}>
                        <Grid container item xs={12}>
                            <Alert severity={alert.type} sx={{ mt: 3, mb: -2 }}>
                                {alert.message}
                            </Alert>
                        </Grid>
                    </Collapse>
                </Grid>

                <Grid
                    item
                    container
                    xs={12}
                    justifyContent={{ xs: 'center', sm: 'flex-end' }}
                    marginTop={1.25}
                >
                    {loading ? (
                        <InterpunctLoader />
                    ) : (
                        <>
                            <DefaultBtn
                                fullWidth={smDown}
                                size="small"
                                variant="outlined"
                                onClick={onCloseModal}
                            >
                                Cancelar
                            </DefaultBtn>
                            <DefaultBtn
                                fullWidth={smDown}
                                size="small"
                                type="submit"
                                sx={{ ml: { xs: 0, sm: '20px' }, mt: { xs: '20px', sm: 0 } }}
                            >
                                Conectar
                            </DefaultBtn>
                        </>
                    )}
                </Grid>
            </Grid>
        </Modal>
    );
};

export default WooCommerce;
