import { FC, Fragment } from 'react';
import { Grid } from '@mui/material';
import { Skeleton } from '@fairplay2/ui';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ThemeProvider } from '@mui/material/styles';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { addDays } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import { noop } from 'utils/constants';
import { mimicDateAtCDMX, toTimelessISOUtc } from 'utils/dates';
import { MonthlyPaymentsGridProps, WeeklyPaymentsGridProps } from './interfaces';
import CustomPickersDay from './CustomPickersDay';
import { calendarTheme } from './styles';

const weekDays: Record<string, string> = {
    lu: 'Lunes',
    ma: 'Martes',
    mi: 'Miércoles',
    ju: 'Jueves',
    vi: 'Viernes',
    sá: 'Sábado',
    do: 'Domingo',
};

const datePickerStaticFunctions = {
    onChange: noop,
    // eslint-disable-next-line react/jsx-no-useless-fragment
    renderInput: () => <></>,
    dayOfWeekFormatter: (day: string) => weekDays[day],
};

const getPaymentDate = (day: Date | null, monthlyData: WeeklyPaymentsGridProps['dailyDataArr']) => {
    if (day && monthlyData) {
        const dayData = monthlyData[day.getUTCDate() - 1];
        if (typeof dayData === 'object' && dayData !== null) {
            return dayData.paymentDate;
        }
    }
    return '';
};

const MonthlyPaymentsGrid: FC<MonthlyPaymentsGridProps> = ({ monthlyData, loading, startDate }) => {
    const forwardedDate = toTimelessISOUtc(addDays(new Date(startDate), 2)),
        currentDateTime = mimicDateAtCDMX().setUTCHours(0, 0, 0, 0);

    const getDayData = (day: Date | null) => {
        const dataPaymentDate =
                day != null && monthlyData ? getPaymentDate(day, monthlyData) : null,
            calendarCurrentDate = day ? toTimelessISOUtc(day) : null;

        if (dataPaymentDate === calendarCurrentDate && monthlyData) {
            const sameDateData = monthlyData.find((item) =>
                item ? item.paymentDate === calendarCurrentDate : false,
            );

            return sameDateData || null;
        }
        return null;
    };

    const customDayCard = (
        day: Date,
        selectedDate: any,
        pickersDayProps: PickersDayProps<Date>,
    ) => {
        const dayData = getDayData(day);

        return (
            <Fragment key={day?.getTime()}>
                {loading ? (
                    <Skeleton
                        width={146}
                        height={130}
                        data-testid="payment-calendarDay-skeleton"
                        sx={{ margin: '4px' }}
                    />
                ) : (
                    <CustomPickersDay
                        {...pickersDayProps}
                        dayData={dayData}
                        currentDateTime={currentDateTime}
                    />
                )}
            </Fragment>
        );
    };

    return (
        <Grid container>
            <Grid item xs={12} sx={{ overflowX: 'auto' }}>
                <ThemeProvider theme={calendarTheme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                        <StaticDatePicker
                            reduceAnimations
                            readOnly
                            disableFuture
                            views={['day']}
                            displayStaticWrapperAs="desktop"
                            value={forwardedDate}
                            renderDay={customDayCard}
                            {...datePickerStaticFunctions}
                        />
                    </LocalizationProvider>
                </ThemeProvider>
            </Grid>
        </Grid>
    );
};

export default MonthlyPaymentsGrid;
