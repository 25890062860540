// TODO: Remove and implement this logic at session-level context
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { hotjar } from 'react-hotjar';
import { captureException } from '@sentry/react';
import fairplayAPI from 'utils/api';
import sessionContext from 'context/session/sessionContext';
import { Contract, ContractWithPeriodValues } from 'components/dashboard/contracts/interfaces';
import { HotjarAttributes } from 'utils/interfaces';

export const getUserType = (contracts: Contract[]) => {
    const { hasActive, hasFinished, hasSuspended, hasCancelled } = contracts.reduce(
        (acc, { status }) => {
            if (status?.id === 'active') acc.hasActive = true;
            else if (status?.id === 'finished') acc.hasFinished = true;
            else if (status?.id === 'cancelled') acc.hasCancelled = true;
            else if (status?.id === 'suspended') acc.hasSuspended = true;

            return acc;
        },
        { hasActive: false, hasFinished: false, hasCancelled: false, hasSuspended: false },
    );

    if (hasActive && hasFinished) return 'renovation';
    if (hasActive) return 'client';
    if (hasFinished) return 'ex-client';
    if (hasSuspended || hasCancelled) return 'paused-client';
};

export const getContractPeriods = async (
    contracts: ContractWithPeriodValues[],
    companyId: string,
) => {
    try {
        const promises = contracts.map(async (contract) => {
            const res: any = await fairplayAPI.get(
                    `/v2/companies/${companyId}/contracts/${contract.id}`,
                ),
                modifiedContract = { ...contract },
                contractDetail = res.data?.body?.results;

            if (contractDetail?.periodInformation?.isStartingPeriod)
                modifiedContract.isStartingPeriod = true;
            else if (contractDetail?.periodInformation?.isEndingPeriod)
                modifiedContract.isEndingPeriod = true;

            modifiedContract.created = contractDetail.created;

            return modifiedContract;
        });
        return await Promise.all(promises);
    } catch (error) {
        throw new Error(`Ocurrio un error al envíar los atributos ${error}`);
    }
};

const selectContract = (contracts: ContractWithPeriodValues[]) => {
    const sortedContracts = contracts.sort((a, b) => {
            const dateA = new Date(a.created!);
            const dateB = new Date(b.created!);
            return dateB.getTime() - dateA.getTime();
        }),
        activeContract = sortedContracts.find((contract) => contract.status?.id === 'active');

    return activeContract || sortedContracts[0];
};

export const sendAttributesToHotjar = async (
    companyId: string,
    businessName: string,
    role: string,
    user: any,
) => {
    try {
        if ([companyId, businessName, role, user?.id, user?.email].every(Boolean)) {
            const res: any = await fairplayAPI.get(`/v2/companies/${companyId}/contracts`),
                contracts = res.data?.body?.results;

            const userAttributes: HotjarAttributes = {
                companyName: businessName,
                idCompany: companyId,
                userRole: role,
                email: user.email,
                renovation: false,
                client: false,
                exClient: false,
                pausedClient: false,
                freemium: false,
                isStartingPeriod: false,
                isEndingPeriod: false,
                contractId: '',
                cse: '',
            };

            if (Array.isArray(contracts) && contracts.length > 0) {
                const contractsWithPeriods = await getContractPeriods(contracts, companyId),
                    userType = getUserType(contracts),
                    selectedContract = selectContract(contractsWithPeriods);

                if (userType === 'renovation') userAttributes.renovation = true;
                else if (userType === 'client') userAttributes.client = true;
                else if (userType === 'ex-client') userAttributes.exClient = true;
                else if (userType === 'paused-client') userAttributes.pausedClient = true;

                const userAttributesWithPeriods = {
                    ...userAttributes,
                    contractId: selectedContract.fairplayInternId,
                    isStartingPeriod: selectedContract.isStartingPeriod || false,
                    isEndingPeriod: selectedContract.isEndingPeriod || false,
                    cse: selectedContract?.cse || '',
                };

                hotjar.identify(user.id, userAttributesWithPeriods);
            } else {
                userAttributes.freemium = true;
                hotjar.identify(user.id, {
                    ...userAttributes,
                });
            }
        }
    } catch (error) {
        /* istanbul ignore next */
        if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_DSN) {
            captureException(error);
        } else {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    }
};

export const useHotjarWatcher = () => {
    const { pathname } = useLocation(),
        { user, selectedCompany } = useContext(sessionContext);

    useEffect(() => {
        if (hotjar.initialized()) {
            hotjar.stateChange(window.location.href);
            sendAttributesToHotjar(
                selectedCompany.company?.id || '',
                selectedCompany.company?.businessName || '',
                selectedCompany.role || '',
                user,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedCompany.company?.id,
        selectedCompany.company?.businessName,
        user?.email,
        user?.id,
        pathname,
    ]);
};

export default useHotjarWatcher;
