import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Collapse, Grid } from '@mui/material';
import { CreditCard, AddCard } from '@mui/icons-material';
import { Alert, type StrictTabData, Typography, TabPanel, Tabs } from '@fairplay2/ui';
import SessionContext from 'context/session/sessionContext';
import { useAlert } from 'utils/hooks/useAlert';
import SelectContract from '../transactions/directory/components/SelectContract';
import { canContractDisburse, getStorageContract } from '../utils';
import { HydratedContract } from '../../contracts/interfaces';
import { NewCard, RequestedCardsWidget } from './components';
import TableProvider from './state/TableContext';
import { useGetAvailableAndPeriods, useGetContracts } from './hooks';
import { DEFAULT_CARD_FILTERS } from './constants';

const SELECT_TITLES = [
    'Selecciona el contrato asociado con la tarjeta o para consultar su saldo disponible',
    'Selecciona el contrato que se asociará con la tarjeta o para consultar su saldo disponible',
];

const CardHeaderTitle = ({ step }: { step: number }) => (
    <Grid item xs={12} textAlign="left" mb="20px" mt="10px">
        <Typography variant="h4" color="primary.main">
            {step === 1 ? 'Solicitar una' : 'Administra tus'}{' '}
            <Typography component="span" color="primary.dark" fontWeight={700}>
                {step === 1 ? 'nueva tarjeta' : 'tarjetas solicitadas'}
            </Typography>
        </Typography>
    </Grid>
);

const updateTabDisabledStatus = (tabs: StrictTabData[], enableNewCardTab: boolean) =>
    tabs.map((tab) =>
        tab.label === 'Tarjeta nueva' ? { ...tab, disabled: !enableNewCardTab } : tab,
    );

export const Cards: FC = () => {
    const { selectedCompany } = useContext(SessionContext),
        { replace } = useHistory(),
        isFirstRender = useRef(true),
        [step, setStep] = useState(0),
        { alert, hideAlert, showAlert } = useAlert(),
        [labelsArray, setLabelsArray] = useState<StrictTabData[]>([
            {
                label: 'Tarjetas solicitadas',
                icon: <CreditCard />,
                panelId: 'requested-cards',
            },
            {
                label: 'Tarjeta nueva',
                icon: <AddCard />,
                disabled: true,
                panelId: 'new-card',
            },
        ]),
        { contracts, error: errorContracts } = useGetContracts(),
        [selectedContract, setSelectedContract] = useState<HydratedContract | undefined>(undefined),
        maxForDisposition = Number(selectedContract?.maxDispositionValue) || 0,
        { contractInfo, error: errorInfo } = useGetAvailableAndPeriods(selectedContract),
        { canDisburse, alertMessage } = canContractDisburse(
            selectedContract,
            contractInfo,
            'cards',
        );

    const onContractSelect = (contractId: string) => {
        const newSelectedContract =
            contracts.find((contract) => contract.id === contractId) || contracts[0];

        setSelectedContract(newSelectedContract);

        // Update the session storage contract
        if (contracts.length > 0)
            sessionStorage.setItem('selectedContract', JSON.stringify(newSelectedContract));
    };

    useEffect(() => {
        const savedContract = getStorageContract(),
            contractExists = contracts.find((contract) => contract.id === savedContract?.id);

        setSelectedContract(contractExists || contracts[0]);
    }, [contracts]);

    // Shows an alert in case there is an error obtaining the contracts and the available/periods
    useEffect(() => {
        hideAlert();

        const errorMessage = errorContracts || errorInfo;
        if (errorMessage) {
            showAlert(
                errorMessage?.error || 'No se pudieron obtener los contratos, Intente más tarde',
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorInfo, errorContracts]);

    useEffect(() => {
        if (!selectedCompany.company?.id) return;
        if (!isFirstRender.current) {
            replace('/app/sources');
            return;
        }

        isFirstRender.current = false;
    }, [selectedCompany.company?.id, replace]);

    useEffect(() => {
        if (selectedContract?.disbursementAllowed === undefined || contractInfo === undefined)
            return;

        const enableNewCardTab = !!contractInfo && selectedContract.disbursementAllowed;

        if (!enableNewCardTab) {
            setStep(0);
        }

        setLabelsArray((prev) => updateTabDisabledStatus(prev, enableNewCardTab));
    }, [selectedContract?.disbursementAllowed, contractInfo]);

    return (
        <Grid container position="relative">
            <CardHeaderTitle step={step} />

            <Grid item xs={12} ml={['-5px', '', '-21px']} mb="25px">
                <Tabs
                    tabs={labelsArray}
                    value={step}
                    onChange={(_, newValue) => setStep(newValue)}
                />
            </Grid>

            <SelectContract
                contracts={contracts}
                selectedContractId={selectedContract?.id || ''}
                onContractSelect={onContractSelect}
                contractInfo={contractInfo}
                title={SELECT_TITLES[step]}
                canContractDisburse={canDisburse}
                alertMessage={alertMessage}
            />

            <Grid item container xs={12} justifyContent="flex-end">
                <Collapse in={alert.open}>
                    <Alert severity={alert.type} sx={{ mt: 1 }}>
                        {alert.message}
                    </Alert>
                </Collapse>
            </Grid>

            <Grid item xs={12} mt={['30px', '40px']}>
                <TabPanel id="requested-cards" active={step === 0}>
                    <TableProvider
                        initialData={DEFAULT_CARD_FILTERS}
                        periodAvailable={contractInfo?.period_total_available ?? undefined}
                    >
                        <RequestedCardsWidget
                            selectedCompanyId={selectedCompany.company?.id || ''}
                            selectedContractId={selectedContract?.id || ''}
                            maxForDisposition={maxForDisposition}
                        />
                    </TableProvider>
                </TabPanel>
                <TabPanel id="new-card" active={step === 1}>
                    <NewCard
                        selectedContractId={selectedContract?.id || ''}
                        loading={contractInfo === undefined}
                        onComplete={() => setStep(0)}
                        showAlert={showAlert}
                        maxForDisposition={maxForDisposition}
                    />
                </TabPanel>
            </Grid>
        </Grid>
    );
};

export default Cards;
