import { FC } from 'react';
import { Typography, Skeleton } from '@fairplay2/ui';
import { Grid } from '@mui/material';

const INPUT_TITLES = [
    'País:',
    'Código Postal:',
    'Estado:',
    'Alcaldía / Municipio:',
    'Colonia:',
    'Calle / Vialidad:',
    'Número exterior:',
    'Número interior:',
];

const CompanyDataSkeleton: FC = () => (
    <>
        {Array(8)
            .fill(0)
            .map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Typography
                        variant="body1"
                        color="text.primary"
                        fontWeight={500}
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        mb="5px"
                    >
                        {INPUT_TITLES[index]}
                    </Typography>
                    <Skeleton variant="rectangular" width="100%" height={53} />
                </Grid>
            ))}
    </>
);

export default CompanyDataSkeleton;
