import { FC, useEffect, useState } from 'react';
import { Visibility } from '@mui/icons-material';
import { CSSInterpolation, Grid, styled } from '@mui/material';
import { DefaultBtn, InterpunctLoader } from '@fairplay2/ui';
import { Status as BaseStatus } from 'common-components';
import ViewModal from 'components/dashboard/data-sources/platforms/components/view-connections';
import { useDataSources } from '../../context/DataSourcesContext';
import { GroupedPlatformKey } from '../../interfaces';
import { PlatformActionsProps } from '../interfaces';
import { STATUS_MAP } from '../constants';

const itemStyles: CSSInterpolation = {
    boxSizing: 'border-box',
    minWidth: '200px !important',
    marginBottom: 5,
};

const Button = styled(DefaultBtn)(itemStyles);
const Status = styled(BaseStatus)(itemStyles);

const PlatformActions: FC<PlatformActionsProps> = ({
    showLoader,
    onConnect,
    onDisconnect,
    onReauth,
    platformGroup,
    children,
}) => {
    const { platforms, dispatch, focusOn } = useDataSources(),
        connectors = platforms?.[platformGroup]?.connectors,
        status =
            onDisconnect !== undefined
                ? (connectors?.[0]?.status as keyof typeof STATUS_MAP)
                : undefined,
        [showConnectors, setShowConnectors] = useState(false),
        ConnectButton = (
            <Button size="small" onClick={onConnect}>
                Conectar fuente
            </Button>
        );

    useEffect(() => {
        if (!focusOn || focusOn !== platformGroup) return;

        dispatch({ type: 'FOCUS', payload: null });
        setShowConnectors(true);
    }, [dispatch, focusOn, platformGroup]);

    useEffect(() => {
        if (!connectors?.length) setShowConnectors(false);
    }, [connectors?.length]);

    return (
        <>
            {showLoader ? (
                <Grid container justifyContent="center" alignItems="center" mt="25px" height={38}>
                    <InterpunctLoader />
                </Grid>
            ) : (
                !!connectors &&
                (onDisconnect !== undefined ? (
                    <>
                        {!!connectors.length && !!status && (
                            <Status
                                status={status}
                                variant="filled"
                                sx={{ justifyContent: 'center !important' }}
                            >
                                {STATUS_MAP[status]}
                            </Status>
                        )}
                        {!connectors.length && ConnectButton}
                        {!!connectors.length &&
                        (status === 'active' || status === 'invalid') &&
                        !!onDisconnect ? (
                            <DefaultBtn variant="text" onClick={() => onDisconnect(connectors[0])}>
                                Desconectar
                            </DefaultBtn>
                        ) : (
                            status === 'reauthorize' && (
                                <DefaultBtn
                                    variant="text"
                                    color="error"
                                    onClick={() => onReauth?.(connectors[0])}
                                >
                                    Reautorizar conexión
                                </DefaultBtn>
                            )
                        )}
                    </>
                ) : (
                    <>
                        {ConnectButton}
                        {!!connectors.length && (
                            <DefaultBtn
                                variant="text"
                                startIcon={<Visibility />}
                                onClick={() => setShowConnectors(true)}
                            >
                                Ver conexiones
                            </DefaultBtn>
                        )}
                        {showConnectors && (
                            <ViewModal
                                open={showConnectors}
                                onClose={() => setShowConnectors(false)}
                                platform={platformGroup as GroupedPlatformKey}
                                onReauth={onReauth}
                            />
                        )}
                    </>
                ))
            )}
            {children}
        </>
    );
};

export default PlatformActions;
