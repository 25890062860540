import { FC } from 'react';
import { Chip, Divider, Typography } from '@fairplay2/ui';
import { Grid } from '@mui/material';
import { MONTHS_DIC } from 'components/dashboard/analytics/utils/constants';
import { AccumulatedHeaderProps } from './interfaces';

const AccumulatedHeader: FC<AccumulatedHeaderProps> = ({ category, period, isEmpty, months }) => (
    <>
        <Grid
            item
            container
            direction="column"
            bgcolor={isEmpty ? 'white' : 'background.paper'}
            padding="25px 30px"
            borderRadius="10px 10px 0 0"
            border={isEmpty ? '1px solid' : 'none'}
            borderColor={isEmpty ? 'background.paper' : 'none'}
        >
            <Typography
                variant="subtitle2"
                color="primary.main"
                textAlign="start"
                fontSize="18px"
                fontWeight={900}
            >
                Acumulado de {category}
            </Typography>
            <Grid item container alignItems="center" gap={2}>
                <Typography
                    variant="subtitle2"
                    color="text.primary"
                    textAlign="start"
                    fontWeight={500}
                >
                    Total de {category} en el periodo que seleccionaste
                </Typography>
                {!isEmpty && (
                    <>
                        {period && <Chip color="info" label={period} />}
                        {months &&
                            (months.includes('all') ? (
                                <Chip color="info" label="Todos los meses" />
                            ) : (
                                months.map((month) => (
                                    <Chip
                                        key={month}
                                        color="info"
                                        label={MONTHS_DIC[month as keyof typeof MONTHS_DIC]}
                                    />
                                ))
                            ))}
                    </>
                )}
            </Grid>
        </Grid>
        <Divider
            sx={{
                borderRadius: '0px',
                width: '100%',
                height: '5px',
                ...(isEmpty && {
                    backgroundColor: 'background.paper',
                    borderColor: 'background.paper',
                }),
            }}
        />
    </>
);

export default AccumulatedHeader;
