import { FC, useEffect, useContext, useState, ChangeEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Divider, useMediaQuery, useTheme, Collapse } from '@mui/material';
import { FolderShared, Receipt, MonetizationOn, KeyboardArrowLeft } from '@mui/icons-material/';
import { Alert, IconBtn, Typography, Skeleton, Tabs, TabPanel, StrictTabData } from '@fairplay2/ui';
import fairplayAPI from 'utils/api';
import SessionContext from 'context/session/sessionContext';
import { LeavesWomanThreeSvg } from 'assets/SVGs';
import { format } from 'utils/dates';
import { useAlert } from 'utils/hooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { Params, Vendor } from './interfaces';
import VendorDetail from './components/VendorDetail';
import BankAccounts from './components/BankAccounts';
import Invoices from './components/Invoices';
import { StyledStatus } from './styles';

const tabsData: StrictTabData[] = [
        { label: 'Expediente', icon: <FolderShared />, panelId: 'suppliers-tabpanel-0' },
        { label: 'Datos bancarios', icon: <MonetizationOn />, panelId: 'suppliers-tabpanel-1' },
        { label: 'Facturas', icon: <Receipt />, panelId: 'suppliers-tabpanel-1' },
    ],
    titles = [
        'Información general del proveedor',
        'Cuentas bancarias asociadas al proveedor',
        'Documentación de interacciones con el proveedor',
    ],
    WIDGET_ID = 'vendor-detail-tabs';

const VendorProfile: FC = () => {
    const params = useParams<Params>(),
        history = useHistory(),
        theme = useTheme(),
        mdDown = useMediaQuery(theme.breakpoints.down('md')),
        { selectedCompany } = useContext(SessionContext),
        [loading, setLoading] = useState(true),
        [vendor, setVendor] = useState<Vendor | null>(null),
        [activeTab, setActiveTab] = useState(0),
        { alert, hideAlert, showAlert } = useAlert();

    const fetchVendor = async () => {
        hideAlert();
        setLoading(true);
        try {
            const res: any = await fairplayAPI.get(
                `/v1/companies/${selectedCompany.company?.id}/suppliers/${params.id}`,
            );
            setVendor(res.data.body.results);
            setLoading(false);
        } catch (errorRes: ErrorObj | any) {
            setLoading(false);
            if (errorRes.status === 404) {
                history.push('/app/financing/vendors');
            } else {
                showAlert(errorRes.error);
            }
        }
    };

    const onStepChange = (event: ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    };

    useEffect(() => {
        if (!selectedCompany.company) return;
        fetchVendor();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, selectedCompany]);

    return (
        <Grid container>
            <Grid item container xs={12} sm mt="30px">
                <Grid item container xs={12} alignItems="center" wrap="nowrap">
                    <IconBtn
                        aria-label="Regresa a proveedores"
                        variant="filled"
                        color="primary"
                        size="small"
                        sx={{
                            p: '0 !important',
                            svg: {
                                fontSize: '1.5em',
                            },
                        }}
                        onClick={() => history.push('/app/financing/vendors')}
                    >
                        <KeyboardArrowLeft />
                    </IconBtn>
                    <Typography variant="h4" color="primary.main" fontWeight={500} ml="15px">
                        Expediente de Proveedor{' '}
                        {!loading && (
                            <Typography component="span" color="primary.dark" fontWeight={700}>
                                {vendor && vendor.alias}
                            </Typography>
                        )}
                    </Typography>
                    {loading && (
                        <Skeleton
                            width={100}
                            height={24}
                            sx={{
                                ml: '20px',
                            }}
                        />
                    )}
                </Grid>
                <Grid item xs={12} mt="40px" id={WIDGET_ID}>
                    <Tabs value={activeTab} tabs={tabsData} onChange={onStepChange} />
                </Grid>
            </Grid>
            {!mdDown && (
                <Grid item container xs={2} justifyContent="flex-end">
                    <LeavesWomanThreeSvg />
                </Grid>
            )}
            <Grid
                item
                container
                xs={12}
                alignItems="center"
                justifyContent="space-between"
                mt="20px"
            >
                <Grid item width="calc(100% - 300px)">
                    <Divider sx={{ color: 'background.dark' }} />
                </Grid>
                {loading ? (
                    <Grid item>
                        <Skeleton
                            width={280}
                            height={24}
                            sx={{
                                ml: '20px',
                            }}
                        />
                    </Grid>
                ) : (
                    <Grid item>
                        <Typography variant="body1" color="text.primary">
                            Fecha de alta:{' '}
                            <Typography
                                component="span"
                                variant="subtitle2"
                                color="primary.dark"
                                fontWeight={700}
                                textTransform="uppercase"
                            >
                                {format(vendor?.created || '', 'd MMMM yyyy')}
                            </Typography>
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Grid item container xs={12} justifyContent="space-between" spacing={2} mt="10px">
                <Grid item xs={12} sm={12} md>
                    <Typography variant="subtitle1" color="primary.main">
                        {titles[activeTab]}
                    </Typography>
                    {activeTab === 1 && (
                        <Typography variant="body2" fontWeight={700} color="#F57C00F0">
                            *Puedes asociar máximo cinco cuentas bancarias por proveedor.
                            <br />
                            Para eliminarlas o cambiar la cuenta principal usa el botón Administrar
                            cuentas.
                        </Typography>
                    )}
                </Grid>
                <Grid item container xs={12} sm={12} md justifyContent="flex-end">
                    {loading ? (
                        <Skeleton variant="rectangular" width={120} height={40} />
                    ) : (
                        <StyledStatus
                            aria-label="Status del proveedor"
                            variant="standard"
                            status={vendor ? vendor.status?.id : 'disabled'}
                        >
                            {vendor?.status?.text}
                        </StyledStatus>
                    )}
                </Grid>
            </Grid>
            <Collapse in={alert.open}>
                <Grid item container xs={12} sm={6} md m="10px 0">
                    <Alert aria-label="Error proveedor" severity={alert.type}>
                        {alert.message}
                    </Alert>
                </Grid>
            </Collapse>
            {vendor?.comments && activeTab === 0 && (
                <Grid item container xs={12} sm={6} md m="10px 0">
                    <Alert aria-label="Comentarios proveedor" severity="info">
                        {vendor?.comments}
                    </Alert>
                </Grid>
            )}
            <TabPanel id={tabsData[0].panelId!} active={activeTab === 0}>
                <VendorDetail vendor={vendor} loadingPage={loading} fetchVendor={fetchVendor} />
            </TabPanel>
            <TabPanel id={tabsData[1].panelId!} active={activeTab === 1}>
                <BankAccounts vendorId={vendor?.id} vendorName={vendor?.registeredName} />
            </TabPanel>
            <TabPanel id={tabsData[2].panelId!} active={activeTab === 2}>
                <Invoices vendorId={vendor?.id} />
            </TabPanel>
        </Grid>
    );
};

export default VendorProfile;
