import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, DialogTitle } from '@mui/material';
import { DefaultBtn, Modal, Typography } from '@fairplay2/ui';
import { AlertSvg } from 'assets/SVGs';
import { useToggle, useOtpStatus } from 'utils/hooks';

export const OtpModalNotice = () => {
    const history = useHistory(),
        { isOtpEnabled, isOtpRequired, isUserOtpVerified } = useOtpStatus(),
        [isModalOpen, toggleModal] = useToggle(),
        showOtpModal = !isUserOtpVerified && isOtpEnabled;

    useEffect(() => {
        if (showOtpModal && !sessionStorage.getItem('otpModalShown')) {
            toggleModal();
            sessionStorage.setItem('otpModalShown', 'true');
        }
    }, [showOtpModal, toggleModal]);

    return (
        <Modal open={isModalOpen} maxWidth={720} onClose={toggleModal}>
            <Grid container>
                <Grid container item xs={12} justifyContent="center">
                    <AlertSvg />
                </Grid>
                <Grid item xs={12} mt="20px">
                    <Typography
                        component={DialogTitle}
                        variant="subtitle1"
                        color="primary.main"
                        fontWeight={700}
                        lineHeight={1.5}
                    >
                        {isOtpRequired
                            ? 'Es necesario que cuentes con una aplicación de autenticación para realizar dispersiones.'
                            : 'Próximamente será necesario que cuentes con una aplicación de autenticación para realizar dispersiones.'}
                    </Typography>
                </Grid>
                <Grid item xs={12} mt={1}>
                    <Typography variant="subtitle1" color="text.primary" fontWeight={500}>
                        ¿Ya cuentas con una aplicación de autenticación vinculada a Insights?
                    </Typography>
                </Grid>

                <Grid container item xs={12} justifyContent="center" mt="15px">
                    <DefaultBtn
                        size="small"
                        variant="outlined"
                        onClick={toggleModal}
                        sx={{ mx: '10px', mt: '10px' }}
                    >
                        Tengo una app vinculada
                    </DefaultBtn>
                    <DefaultBtn
                        size="small"
                        onClick={() => {
                            toggleModal();
                            history.push('/app/company/security');
                        }}
                        sx={{ mx: '10px', mt: '10px' }}
                    >
                        Vincular una app
                    </DefaultBtn>
                </Grid>
            </Grid>
        </Modal>
    );
};
