import { FC } from 'react';
import { useTheme, useMediaQuery, Grid } from '@mui/material';
import { Typography } from '@fairplay2/ui';
import { Certificates } from 'common-components';
import { NavbarFooterContainer, NavbarFooterLink } from '../styles';

export const NavbarFooter: FC = () => {
    const theme = useTheme(),
        lgUp = useMediaQuery(theme.breakpoints.up('lg')),
        smDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <NavbarFooterContainer container justifyContent="center">
            <Grid item xs={12} mb={{ xs: -0.5, lg: 0 }} display={{ lg: 'table' }} mt={{ sm: -4 }}>
                <Certificates
                    useCompact={!lgUp}
                    sx={{
                        position: 'static',
                        ...(!lgUp ? { margin: '0 auto', width: 'auto' } : {}),
                    }}
                />
            </Grid>
            <Typography
                variant="caption"
                color="primary.contrastText"
                fontWeight={700}
                component={NavbarFooterLink}
                target="_blank"
                href="https://getfairplay.com/terminos-y-condiciones/"
                rel="noopener noreferrer"
            >
                Términos y condiciones
            </Typography>

            {(smDown || lgUp) && (
                <Typography
                    variant="caption"
                    color="primary.contrastText"
                    fontWeight={700}
                    ml="10px"
                    mr="10px"
                    aria-hidden
                >
                    |
                </Typography>
            )}

            <Typography
                variant="caption"
                color="primary.contrastText"
                fontWeight={700}
                component={NavbarFooterLink}
                target="_blank"
                href="https://getfairplay.com/aviso-de-privacidad/"
                rel="noopener noreferrer"
            >
                Aviso de privacidad
            </Typography>
        </NavbarFooterContainer>
    );
};

export default NavbarFooter;
