import { FC } from 'react';
import { IframeCardProps } from '../../interfaces';

const getIframeSrc = (pomeloCardId: string, secureToken: string, typeOfLayout: string): string => {
    const baseUrl =
        process.env.REACT_APP_ENV_TYPE === 'development'
            ? 'secure-data-web-stage.pomelo'
            : 'secure-data-web.pomelo';

    return `https://${baseUrl}.la/v1/${pomeloCardId}?auth=${secureToken}&layout=${typeOfLayout}&locale=es&field_list=pan,expiration,codes&styles=${process.env.REACT_APP_POMELO_STYLES}`;
};

const IframeCard: FC<IframeCardProps> = ({ smDown, pomeloCardId, secureToken, typeOfLayout }) => (
    <iframe
        data-testid="iframe-card"
        title="Muestra números de la tarjeta"
        src={getIframeSrc(pomeloCardId, secureToken, typeOfLayout)}
        allow="clipboard-write"
        style={{
            border: 'none',
            overflow: 'hidden',
            height: smDown ? '278px' : '223px',
            width: smDown ? '100%' : '364px',
        }}
    />
);

export default IframeCard;
