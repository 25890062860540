import { styled } from '@mui/material/styles';
import { Typography } from '@fairplay2/ui';
import { StyledTypographyProps } from './interfaces';

export const StyledTypography = styled(Typography, {
    shouldForwardProp: (prop: string) => prop !== 'tagColor',
})<StyledTypographyProps>(({ tagColor }) => ({
    ':before': {
        backgroundColor: tagColor,
        borderRadius: '50%',
        content: '""',
        display: 'inline-block',
        height: '10px',
        marginRight: '4px',
        width: '10px',
    },
}));
