import { Theme } from '@mui/material';
import { GrowthIndicatorProps } from 'common-components/data-indicators/growth-indicator/GrowthIndicator.types';
import { ChartFactory } from 'utils/formatting';
import { formatUtc } from 'utils/dates';
import { Status } from 'utils/interfaces';
import { Gmv, Indicator, RawChartDatapoint } from './interfaces';
import {
    getGmv,
    getGmvGrowth,
    getGmvPerformance,
    getMinGmv,
    // getExpectedGrowth
} from './api';

export const defaultGrowthIndicatorProp: GrowthIndicatorProps = {
    indicator: 'over',
    percentage: 0,
    children: '',
};

export const formatDataForCharting = (data: RawChartDatapoint) => [
    Date.parse(data.date),
    data.value,
];

export const formatGmvDataPoint = (data: Gmv) =>
    formatDataForCharting({ date: data.date, value: data.gmv });

export const formatMinGmvDataPoint = (minGmv: number, data: Gmv) =>
    formatDataForCharting({
        date: data.date,
        value: minGmv,
    });

// export const formatProjectedGmvDataPoint = (data: ProjectedGmv) =>
//     formatDataForCharting({
//         date: data.date,
//         value: data.projected_gmv,
//     });

export const updateIndicatorData =
    <T extends any>(indicator: Indicator, data: T) =>
    (currentProps: any) => ({
        ...currentProps,
        [indicator]: data,
    });

export const getIndicatorStatus = (type: Indicator, percentage: number | undefined) => {
    let indicatorProps: GrowthIndicatorProps = defaultGrowthIndicatorProp,
        indicatorStatus: Status | undefined;

    if (percentage !== undefined) {
        // if (type === 'expectedGrowth') {
        //     indicatorProps = {
        //         indicator: expectedGrowth > 0.1 ? 'over' : 'below',
        //         percentage: parseFloat(expectedGrowth?.toFixed(2)) || 0,
        //         children: expectedGrowth <= 0.1 ? 'Abajo' : 'Arriba',
        //         color: expectedGrowth > 0.1 && expectedGrowth <= 0.8 ? 'warning' : undefined,
        //     };

        //     indicatorStatus =
        //         expectedGrowth > 0.8
        //             ? undefined
        //             : {
        //                   type:
        //                       expectedGrowth > 0.1 && expectedGrowth <= 0.8
        //                           ? 'warning'
        //                           : 'rejected',
        //                   message:
        //                       expectedGrowth > 0.1 && expectedGrowth <= 0.8
        //                           ? 'Tu crecimiento esperado está en el límite'
        //                           : 'Renovación no autorizada',
        //               };
        // }

        if (type === 'gmvPerformance') {
            indicatorProps = {
                indicator: percentage >= 0 ? 'over' : 'below',
                percentage: parseFloat(percentage?.toFixed(2)) || 0,
                children: 'Vs. Mínimo GMV',
                color: percentage === 0 ? 'warning' : undefined,
            };

            indicatorStatus =
                percentage > 0
                    ? undefined
                    : {
                          type: 'warning',
                          message:
                              percentage === 0
                                  ? 'Tu desempeño está al límite'
                                  : 'Contrato suspendido / Dispersiones bloqueadas',
                      };
        }

        if (type === 'gmvGrowth') {
            indicatorProps = {
                indicator: percentage >= 0 ? 'over' : 'below',
                percentage: parseFloat(percentage?.toFixed(2)) || 0,
                children: percentage < 0 ? 'Abajo' : 'Arriba',
                color: percentage === 0 ? 'warning' : undefined,
            };
        }
    }

    return {
        indicatorProps,
        indicatorStatus,
    };
};

export const getChartData = async (selectedContractId: string, theme: Theme) => {
    const [gmv, minGmv] = await Promise.all([
            getGmv(selectedContractId),
            getMinGmv(selectedContractId),
            // getProjectedGmv(selectedContractId),
        ]),
        gmvChartOptions = ChartFactory({
            series: [
                {
                    color: theme.palette.auxiliary.main,
                    name: 'GMV (Ventas)',
                    data: gmv?.map(formatGmvDataPoint),
                },
                {
                    color: '#90ED7D',
                    name: 'Mínimo GMV',
                    data: gmv?.map((data) => formatMinGmvDataPoint(minGmv.min_gmv, data)),
                },
                // {
                //     color: '#18ADE3',
                //     name: 'GMV proyectado',
                //     data: projectedGmv,
                // },
            ],
            yTitle: 'Monto ventas totales en pesos mexicanos',
            xTitle: 'Duración del contrato',
            valuePrefix: '$',
        });

    return gmvChartOptions;
};

export const getGmvPerformanceData = async (selectedContractId: string) => {
    const res = await getGmvPerformance(selectedContractId),
        { indicatorProps, indicatorStatus } = getIndicatorStatus(
            'gmvPerformance',
            res.last_month_gmv,
        );

    return {
        props: indicatorProps,
        status: indicatorStatus,
        previousMonth: formatUtc(res.previous_month || '', 'LLLL', { fallbackString: '' }),
    };
};

export const getGmvGrowthData = async (selectedContractId: string) => {
    const res = await getGmvGrowth(selectedContractId),
        { indicatorProps, indicatorStatus } = getIndicatorStatus('gmvGrowth', res.growth_yoy);

    return {
        props: indicatorProps,
        status: indicatorStatus,
    };
};

// export const getExpectedGrowthData = async (selectedContractId: string) => {
//     const res = await getGmvGrowth(selectedContractId),
//         { indicatorProps, indicatorStatus } = getIndicatorStatus('expectedGrowth', res.growth);

//         return { expectedGrowth: {
//             props: indicatorProps,
//             status: indicatorStatus,
//         };
//     };
// };
