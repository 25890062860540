import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { dataSourceRedirection } from '../utils';
import { GroupedPlatformKey, InterceptedParams, OnCodeInterception } from '../interfaces';
import { useDataSources } from '../context/DataSourcesContext';

/**
 * Has a dependency over DataSourcesContext
 * to check for baseAPI. If not present, no actions will
 * be performed.
 */
const useCodeInterceptor = (
    platformKey: GroupedPlatformKey,
    queryParams: string[],
    onCodeInterception: OnCodeInterception,
) => {
    const { search } = useLocation(),
        { baseAPI } = useDataSources();

    useEffect(() => {
        if (!baseAPI) return;
        const params = new URLSearchParams(search),
            foundParams: InterceptedParams = {},
            { from, extra, connector } = dataSourceRedirection.readMetadata() || {};

        if (from !== platformKey) return;

        // eslint-disable-next-line no-restricted-syntax
        for (const key of queryParams) {
            const value = params.get(key);
            if (value) foundParams[key] = value;
        }

        if (!Object.keys(foundParams).length) return;

        if (onCodeInterception(foundParams, { connector, extra })) dataSourceRedirection.complete();
    }, [baseAPI, onCodeInterception, platformKey, queryParams, search]);
};

export default useCodeInterceptor;
