import { FC } from 'react';
import { Grid } from '@mui/material';
import { Replay, ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import { Typography, Paper } from '@fairplay2/ui';
import { CardKpiProps } from '../interfaces';

const CardKpi: FC<CardKpiProps> = ({ item, title, symbol, perc }) => (
    <Grid item xs={12} sm={6} md={4}>
        <Paper
            variant="outlined"
            sx={{
                bgcolor: 'background.default',
                padding: '20px',
                minHeight: { xs: 'auto', sm: '205px', lg: 'auto' },
            }}
        >
            <Typography variant="subtitle2" color="text.primary" fontWeight={700}>
                {title}
            </Typography>
            <Grid item container direction="column" alignItems="flex-start">
                {item.warningC ? (
                    <Typography variant="body1" color="primary.main" my={2}>
                        No hay órdenes registradas
                    </Typography>
                ) : (
                    <Typography variant="h4" color="primary.main" fontWeight={400} my="15px">
                        {symbol && symbol}
                        {item.current}
                        {perc && perc}
                    </Typography>
                )}
                {item.percentage && (
                    <Typography
                        variant="body1"
                        color={item.percentage.value >= 0 ? 'secondary.main' : 'error.main'}
                        fontWeight={700}
                    >
                        {!item.warningC &&
                            (item.percentage.value >= 0 ? (
                                <ArrowDropUp
                                    sx={{
                                        marginBottom: '-7px',
                                    }}
                                />
                            ) : (
                                <ArrowDropDown
                                    sx={{
                                        marginBottom: '-7px',
                                    }}
                                />
                            ))}

                        {item.percentage.value}
                        {`% (${symbol || ''}${item.percentage.amount})`}
                    </Typography>
                )}
                <Typography variant="body1" color="primary.main">
                    {item.warningL
                        ? 'Sin periodo registrado'
                        : `Periodo pasado ${symbol || ''}${item.last}${perc || ''}`}
                </Typography>
            </Grid>
            <Typography variant="caption" color="primary.main" fontWeight={500} mt="15px">
                <Replay
                    sx={{
                        color: 'primary.main',
                        fontSize: 12,
                        marginBottom: '-3px',
                    }}
                />{' '}
                justo ahora
            </Typography>
        </Paper>
    </Grid>
);

export default CardKpi;
