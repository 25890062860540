import { ChangeEvent, FC, useState, useEffect, useRef, lazy, Suspense } from 'react';
import { useQueries, useQuery } from 'react-query';
import { Grid, useTheme, useMediaQuery, Collapse, MenuItem, Table } from '@mui/material';
import { /* Info, */ Visibility, VisibilityOff } from '@mui/icons-material';
import { TextColorDescription } from 'common-components';
import {
    Tabs,
    TabPanel,
    Typography,
    Alert,
    DefaultInput,
    DefaultBtn,
    // IconBtn,
    // Tooltip,
    Skeleton,
    Select,
    StrictTabData,
    TableSkeleton,
} from '@fairplay2/ui';
import { ColorDescription } from 'common-components/text-color-description/TextColorDescription.types';
import { getMonthsList, getSelectedMonthData } from 'components/dashboard/contracts/utils';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { format, formatUtc, SHORT_READABLE_FORMAT } from 'utils/dates';
import { useAlert } from 'utils/hooks';
// import { formatAmount } from 'utils/parsing';
import { contractIdProp } from '../../../interfaces';
import { buildQuery, fetchContractGeneralDebt } from './api';
import WeeklyPaymentsGrid from './WeeklyPaymentsGrid';
import MonthlyPaymentsGrid from './MonthlyPaymentsGrid';
import { ColoredInput } from './styles';

const DirectDebitTable = lazy(
    () => import(/* webpackChunkName: 'DirectDebitTable' */ './DirectDebitTable'),
);

const tabsLabels = ['Pasada', 'Actual', 'Próxima', 'Todo el mes'],
    colorDescriptions: ColorDescription[] = [
        { text: 'Monto pagado', color: 'auxiliary.main' },
        { text: 'Monto total', color: 'primary.dark', variant: 'filled' },
        { text: 'Monto del día', color: 'secondary.main' },
        { text: 'Monto por pagar', color: 'primary.main' },
        { text: 'Pendiente de pago', color: 'error.main' },
    ];

const ContractPayments: FC<contractIdProp> = ({ contractId }) => {
    const theme = useTheme(),
        mdDown = useMediaQuery(theme.breakpoints.down('md')),
        todayDateFormatted = format(undefined, SHORT_READABLE_FORMAT, { fallbackString: '' }),
        { alert: ordersAlert, showAlert: showOrdersAlert, hideAlert: hideOrdersAlert } = useAlert(),
        { alert: debtAlert, showAlert: showDebtAlert, hideAlert: hideDebtAlert } = useAlert(),
        [activeTab, setActiveTab] = useState(1),
        [debitsTableIsOpen, setDebitsTableIsOpen] = useState(false),
        // [tooltipIsOpen, setTooltipIsOpen] = useState(false),
        [selectedMonthData, setSelectedMonthData] = useState(getSelectedMonthData),
        effectiveTabs: StrictTabData[] = tabsLabels
            .map((label, index) => ({
                label,
                disabled:
                    selectedMonthData.monthsInThePast !== 0 ||
                    (selectedMonthData.startDate >= selectedMonthData.currentWeekDays[0] &&
                        index === 0) ||
                    (selectedMonthData.endDate <= selectedMonthData.currentWeekDays[6] &&
                        index === 2),
                panelId: `payments-tabpanel-${index}`,
            }))
            .filter((_, i) => !mdDown || (mdDown && i !== 3)),
        monthLimits = {
            startDate: selectedMonthData.startDate,
            endDate: selectedMonthData.endDate,
        };
    const { data: contractGeneralDebt, isFetching: isDebtFetching } = useQuery({
            queryKey: ['contractGeneralDebt', contractId],
            queryFn: () => fetchContractGeneralDebt(contractId),
            staleTime: 180000,
            cacheTime: 180000,
            onError: (errResponse: ErrorObj | any) => {
                showDebtAlert(
                    errResponse?.error ||
                        'No se pudo obtener la información requerida, intente más tarde',
                );
            },
        }),
        firstIncomeDate = formatUtc(contractGeneralDebt?.firstIncomeDate, SHORT_READABLE_FORMAT, {
            fallbackString: '',
        }),
        pickableMonths = contractGeneralDebt
            ? getMonthsList(contractGeneralDebt.firstIncomeDate)
            : [];

    const queries = useQueries([
            buildQuery(contractId, 'month', monthLimits, showOrdersAlert),
            buildQuery(
                contractId,
                'last',
                monthLimits,
                showOrdersAlert,
                selectedMonthData.lastWeekDays,
            ),
            buildQuery(
                contractId,
                'current',
                monthLimits,
                showOrdersAlert,
                selectedMonthData.currentWeekDays,
            ),
            buildQuery(
                contractId,
                'next',
                monthLimits,
                showOrdersAlert,
                selectedMonthData.nextWeekDays,
            ),
        ]),
        isOrderFetching = queries.some((query) => query.isFetching),
        [monthlyData, lastWeekData, currentWeekData, nextWeekData] = queries;

    const onMonthChange = (e: ChangeEvent) => {
        // needs -1 to match the index of the array with one month in the future
        const monthsInThePast = Number((e.target as any).value) - 1,
            monthData = getSelectedMonthData(monthsInThePast);
        setSelectedMonthData(monthData);
        // Forces month view on past months
        if (monthsInThePast !== 0) {
            setActiveTab(3);
        } else if (monthData.startDate >= monthData.currentWeekDays[0]) {
            setActiveTab((prev) => (prev === 0 ? 1 : prev));
        } else if (monthData.endDate <= monthData.currentWeekDays[6]) {
            setActiveTab((prev) => (prev === 2 ? 1 : prev));
        }
    };

    const onTabChange = useRef((event: ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    });

    const toggleDebitsTableIsOpen = () => {
        setDebitsTableIsOpen((prev) => !prev);
    };

    useEffect(() => {
        if (isOrderFetching) hideOrdersAlert();
        if (isDebtFetching) hideDebtAlert();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOrderFetching, isDebtFetching]);

    useEffect(() => {
        if (!mdDown) return;
        setSelectedMonthData((prev) =>
            prev.monthsInThePast !== 0 ? getSelectedMonthData(0) : prev,
        );
        setActiveTab((prev) => (prev === 3 ? 1 : prev));
    }, [mdDown]);

    useEffect(() => {
        if (sessionStorage.getItem('openPayments') === 'calendar') {
            sessionStorage.removeItem('openPayments');
            setActiveTab(3);
        }
    }, []);

    return (
        <Grid container marginTop="30px" gap={4}>
            <Grid item container xs={12} gap={1}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" color="primary.main">
                        Información general de tu contrato
                    </Typography>
                </Grid>
                <Grid item container xs={12} justifyContent="flex-end">
                    <Collapse in={debtAlert.open}>
                        <Alert severity={debtAlert.type}>{debtAlert.message}</Alert>
                    </Collapse>
                </Grid>
                <Grid item container xs={12} gap={1.5}>
                    {isDebtFetching ? (
                        <>
                            <Grid item xs={12} sm={5.5} md={3.5}>
                                <Typography variant="label">Primera domiciliación:</Typography>
                                <Skeleton
                                    data-testid="firstIncomeDate-skeleton"
                                    variant="rectangular"
                                    width="100%"
                                    height={48}
                                />
                            </Grid>
                            <Grid item xs={12} sm={5.5} md={3.5}>
                                <Typography variant="label">Has pagado al:</Typography>
                                <Skeleton
                                    data-testid="totalPaidAmount-skeleton"
                                    variant="rectangular"
                                    height={48}
                                />
                            </Grid>
                            <Grid item xs={12} sm={5.5} md={3.5}>
                                <Typography variant="label">Pendiente por pagar:</Typography>
                                <Skeleton
                                    data-testid="duePayment-skeleton"
                                    variant="rectangular"
                                    height={48}
                                />
                            </Grid>
                            <Grid item xs={12} sm={5.5} md={3.5}>
                                <Typography variant="label">Saldo a favor:</Typography>
                                <Skeleton
                                    data-testid="incomesUnassigned-skeleton"
                                    variant="rectangular"
                                    height={48}
                                />
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={12} sm={5.5} md={3.5}>
                                <DefaultInput
                                    label="Primera domiciliación:"
                                    labelVariant="external"
                                    id="firstIncomeDate"
                                    type="text"
                                    value={firstIncomeDate || ''}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={5.5} md={3.5}>
                                <ColoredInput
                                    id="totalPaidAmount"
                                    label={
                                        <>
                                            Has pagado al:
                                            <Typography
                                                color="primary.main"
                                                fontWeight={700}
                                                component="span"
                                            >
                                                {todayDateFormatted}
                                            </Typography>
                                        </>
                                    }
                                    labelVariant="external"
                                    value={contractGeneralDebt?.totalPaidAmount || ''}
                                    disabled
                                    variant="outlined"
                                    type="positive"
                                    sx={{
                                        '.MuiTypography-label': {
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            gap: 1,
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={5.5} md={3.5} position="relative">
                                {/* <Typography
                                        variant="label"
                                        component="label"
                                        htmlFor="duePayment"
                                    >
                                        {!!contractGeneralDebt?.duePaymentPast &&
                                            !!contractGeneralDebt?.duePaymentFuture && (
                                                <Tooltip
                                                    aria-label="Ver desglose de pago"
                                                    color="paper"
                                                    placement="bottom-end"
                                                    sx={{
                                                        position: 'absolute',
                                                        top: -3,
                                                        right: 0,
                                                    }}
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    onOpen={() => setTooltipIsOpen(true)}
                                                    onClose={() => setTooltipIsOpen(false)}
                                                    open={tooltipIsOpen}
                                                    disableTouchListener={!mdDown}
                                                    title={
                                                        <Grid container direction="column" gap={1}>
                                                            <Grid item>Órdenes totales:</Grid>
                                                            <Grid item alignSelf="flex-end">
                                                                {`MXN $ ${formatAmount(
                                                                    contractGeneralDebt?.duePaymentPast,
                                                                )}`}
                                                            </Grid>
                                                            <Grid item>
                                                                Total pendientes de pago:
                                                            </Grid>
                                                            <Grid item alignSelf="flex-end">
                                                                <Typography
                                                                    color={
                                                                        contractGeneralDebt?.duePaymentFuture ===
                                                                        0
                                                                            ? 'secondary.main'
                                                                            : 'error.main'
                                                                    }
                                                                >
                                                                    {`MXN $ ${formatAmount(
                                                                        contractGeneralDebt?.duePaymentFuture,
                                                                    )}`}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                >
                                                    <IconBtn
                                                        color="primary"
                                                        onClick={() => setTooltipIsOpen(true)}
                                                    >
                                                        <Info fontSize="small" />
                                                    </IconBtn>
                                                </Tooltip>
                                            )}
                                    </Typography> */}
                                <ColoredInput
                                    id="duePayment"
                                    label="Pendiente por pagar:"
                                    labelVariant="external"
                                    value={contractGeneralDebt?.totalDueAmount || ''}
                                    disabled
                                    variant="outlined"
                                    type="neutral"
                                />
                            </Grid>
                            <Grid item xs={12} sm={5.5} md={3.5}>
                                <ColoredInput
                                    id="incomesUnassigned"
                                    label="Saldo a favor:"
                                    labelVariant="external"
                                    value={contractGeneralDebt?.incomesUnassigned || 0}
                                    disabled
                                    variant="outlined"
                                    type="neutral"
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" color="primary.main">
                        Tus pagos
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" color="text.primary">
                        Consulta cómo has ido pagando y próximos pagos de tu financiamiento
                    </Typography>
                </Grid>
                <Alert severity="info" sx={{ maxWidth: '568px', mt: '10px' }}>
                    Importante: Recuerda que la información de los pagos del día y próximos, se
                    actualiza diariamente a partir de mediodía.
                </Alert>
                <Grid item container xs={12} justifyContent="flex-end">
                    <Collapse in={ordersAlert.open}>
                        <Alert severity={ordersAlert.type}>{ordersAlert.message}</Alert>
                    </Collapse>
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    marginTop="10px"
                    marginBottom="28px"
                    overflow="hidden"
                >
                    {!mdDown && (
                        <Grid item>
                            <Select
                                value={
                                    pickableMonths.length
                                        ? // needs to be +1 to match the index of the array with one month in the future
                                          selectedMonthData.monthsInThePast + 1
                                        : ''
                                }
                                fullWidth
                                onChange={onMonthChange}
                                variant="standard"
                            >
                                <MenuItem value="" disabled>
                                    Mes
                                </MenuItem>
                                {pickableMonths.map(({ label }, index) => (
                                    <MenuItem value={index} key={label}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    )}
                    <Grid item marginLeft={{ xs: 0, md: '30px' }} marginRight="20px">
                        <Typography variant="body1" color="text.primary">
                            Selecciona la semana
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Tabs
                            value={activeTab}
                            tabs={effectiveTabs}
                            onChange={onTabChange.current}
                            disabled
                            sx={{
                                margin: '0 !important',
                            }}
                            indicatorStyles={{ expand: true, height: '3px' }}
                        />
                    </Grid>
                </Grid>
                <TabPanel id={effectiveTabs[0].panelId!} active={activeTab === 0}>
                    <WeeklyPaymentsGrid
                        dailyDataArr={lastWeekData.data || []}
                        dates={selectedMonthData.lastWeekDays}
                        loading={lastWeekData.isFetching}
                        data-testid="last-week-payments"
                    />
                </TabPanel>
                <TabPanel id={effectiveTabs[1].panelId!} active={activeTab === 1}>
                    <WeeklyPaymentsGrid
                        dailyDataArr={currentWeekData.data || []}
                        dates={selectedMonthData.currentWeekDays}
                        loading={currentWeekData.isFetching}
                        data-testid="current-week-payments"
                    />
                </TabPanel>
                <TabPanel id={effectiveTabs[2].panelId!} active={activeTab === 2}>
                    <WeeklyPaymentsGrid
                        dailyDataArr={nextWeekData.data || []}
                        dates={selectedMonthData.nextWeekDays}
                        loading={nextWeekData.isFetching}
                        data-testid="next-week-payments"
                    />
                </TabPanel>
                {!mdDown && (
                    <TabPanel id={effectiveTabs[3].panelId!} active={activeTab === 3}>
                        <MonthlyPaymentsGrid
                            monthlyData={monthlyData.data || []}
                            loading={monthlyData.isFetching}
                            startDate={selectedMonthData.startDate}
                        />
                    </TabPanel>
                )}
                <Grid item container mt="20px" mb={4} gap={1}>
                    <Grid item xs={12}>
                        <TextColorDescription descriptions={colorDescriptions} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" color="text.primary">
                            *Las domiciliaciones de sábado, domingo y días festivos se verán
                            reflejadas en el siguiente día hábil.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" color="text.primary">
                            *Todos los montos están reflejados en pesos mexicanos.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    item
                    mb={debitsTableIsOpen ? '4px' : '32px'}
                    xs={12}
                    sx={{ transition: 'margin-bottom 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' }}
                >
                    <DefaultBtn
                        variant="text"
                        startIcon={
                            debitsTableIsOpen ? (
                                <Visibility data-testid="table-visibility-on" />
                            ) : (
                                <VisibilityOff data-testid="table-visibility-off" />
                            )
                        }
                        onClick={toggleDebitsTableIsOpen}
                        sx={{ textTransform: 'none !important' }}
                    >
                        {debitsTableIsOpen
                            ? 'Ocultar historial de domiciliaciones'
                            : 'Ver historial de domiciliaciones'}
                    </DefaultBtn>
                </Grid>

                <Collapse
                    in={debitsTableIsOpen}
                    sx={{ '&.MuiCollapse-entered': { overflow: 'auto' } }}
                >
                    <Suspense
                        fallback={
                            <Grid item xs={12}>
                                <Grid container item xs={5} mb="30px" spacing="30px">
                                    <Skeleton variant="rectangular" height={60} />
                                </Grid>
                                <Table width="100%">
                                    <tbody>
                                        <TableSkeleton columns={4} rows={3} />
                                    </tbody>
                                </Table>
                            </Grid>
                        }
                    >
                        <DirectDebitTable contractId={contractId} isHidden={!debitsTableIsOpen} />
                    </Suspense>
                </Collapse>
                {/* )} */}
            </Grid>
        </Grid>
    );
};

export default ContractPayments;
