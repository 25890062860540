import { FC } from 'react';
import { FairplayLogoGraphic } from '@fairplay2/ui';
import MastercardLogo from 'assets/img/mastercard-logo.png';
import { StyledCard } from '../styles';
import { CardBackgroundProps } from '../interfaces';

const CardBackground: FC<CardBackgroundProps> = ({ children }) => (
    <StyledCard>
        <FairplayLogoGraphic aria-hidden className="FpInsights-FpCardLogo" />

        {children}

        <img
            src={MastercardLogo}
            alt="Logo mastercard"
            className="FpInsights-FpCardMasterCardLogo"
        />
    </StyledCard>
);

export default CardBackground;
