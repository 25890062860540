export const AWS_REGIONS = [
    {
        name: 'US East (Ohio)',
        regionCode: 'us-east-2',
    },
    {
        name: 'US East (N. Virginia)',
        regionCode: 'us-east-1',
    },
    {
        name: 'US West (N. California)',
        regionCode: 'us-west-1',
    },
    {
        name: 'US West (Oregon)',
        regionCode: 'us-west-2',
    },
    {
        name: 'Africa (Cape Town)',
        regionCode: 'af-south-1',
    },
    {
        name: 'Asia Pacific (Hong Kong)',
        regionCode: 'ap-east-1',
    },
    {
        name: 'Asia Pacific (Hyderabad)',
        regionCode: 'ap-south-2',
    },
    {
        name: 'Asia Pacific (Jakarta)',
        regionCode: 'ap-southeast-3',
    },
    {
        name: 'Asia Pacific (Melbourne)',
        regionCode: 'ap-southeast-4',
    },
    {
        name: 'Asia Pacific (Mumbai)',
        regionCode: 'ap-south-1',
    },
    {
        name: 'Asia Pacific (Osaka)',
        regionCode: 'ap-northeast-3',
    },
    {
        name: 'Asia Pacific (Seoul)',
        regionCode: 'ap-northeast-2',
    },
    {
        name: 'Asia Pacific (Singapore)',
        regionCode: 'ap-southeast-1',
    },
    {
        name: 'Asia Pacific (Sydney)',
        regionCode: 'ap-southeast-2',
    },
    {
        name: 'Asia Pacific (Tokyo)',
        regionCode: 'ap-northeast-1',
    },
    {
        name: 'Canada (Central)',
        regionCode: 'ca-central-1',
    },
    {
        name: 'Europe (Frankfurt)',
        regionCode: 'eu-central-1',
    },
    {
        name: 'Europe (Ireland)',
        regionCode: 'eu-west-1',
    },
    {
        name: 'Europe (London)',
        regionCode: 'eu-west-2',
    },
    {
        name: 'Europe (Milan)',
        regionCode: 'eu-south-1',
    },
    {
        name: 'Europe (Paris)',
        regionCode: 'eu-west-3',
    },
    {
        name: 'Europe (Spain)',
        regionCode: 'eu-south-2',
    },
    {
        name: 'Europe (Stockholm)',
        regionCode: 'eu-north-1',
    },
    {
        name: 'Europe (Zurich)',
        regionCode: 'eu-central-2',
    },
    {
        name: 'Middle East (Bahrain)',
        regionCode: 'me-south-1',
    },
    {
        name: 'Middle East (UAE)',
        regionCode: 'me-central-1',
    },
    {
        name: 'South America (São Paulo)',
        regionCode: 'sa-east-1',
    },
];
