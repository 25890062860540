import { ChangeEventHandler, FocusEventHandler, FC } from 'react';
import { Grid, MenuItem } from '@mui/material';
import { Select, DefaultInput, DefaultBtn } from '@fairplay2/ui';
import { S3Form, SyncSteppedFormStepProps } from '../../interfaces';
import { AWS_REGIONS } from '../constants';

const EMPTY_ERRORS = {
    bucket: 'Ingresa el nombre del bucket',
    awsRegion: 'Selecciona una región',
};

const S3BucketForm: FC<SyncSteppedFormStepProps<S3Form>> = ({
    data,
    errors,
    setErrors,
    setData,
    onCancel,
    onSubmit,
}) => {
    const blockNextStep = !!errors.bucket || !!errors.awsRegion || !data.bucket || !data.awsRegion;

    const onInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (data.id) return;
        const { name: key, value } = e.target;

        setData((prev) => ({ ...prev, [key]: value }));
        setErrors((prev) => ({ ...prev, [key]: value ? '' : EMPTY_ERRORS[key as 'bucket'] }));
    };

    const onInputBlur: FocusEventHandler<HTMLInputElement> = (e) => {
        if (data.id) return;

        const { name: key, value } = e.target;
        setErrors((prev) => ({ ...prev, [key]: value ? '' : EMPTY_ERRORS[key as 'bucket'] }));
    };

    return (
        <Grid container direction="column" maxWidth={390} mx="auto" rowSpacing={3}>
            <Grid item>
                <DefaultInput
                    id="s3-bucket-name"
                    labelVariant="external"
                    label="Ingresa el nombre del s3 Bucket"
                    name="bucket"
                    placeholder="Cómo se llama tu Bucket"
                    value={data.bucket}
                    error={errors.bucket}
                    onChange={onInputChange}
                    onBlur={onInputBlur}
                />
            </Grid>
            <Grid item>
                <Select
                    id="s3-bucket-region"
                    labelVariant="external"
                    label="Región del Bucket (AWS Region)"
                    name="awsRegion"
                    error={errors.awsRegion}
                    value={data.awsRegion}
                    onChange={onInputChange}
                >
                    <MenuItem value="" disabled>
                        Selecciona la región
                    </MenuItem>
                    {AWS_REGIONS.map(({ regionCode, name }) => (
                        <MenuItem value={regionCode} key={regionCode}>
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid
                container
                rowSpacing={2}
                columnSpacing={[0, 3]}
                width={[undefined, 'auto']}
                ml="auto"
                mt={2}
                justifyContent="flex-end"
            >
                <Grid item xs={12} sm={5}>
                    <DefaultBtn size="small" fullWidth variant="outlined" onClick={onCancel}>
                        Cancelar
                    </DefaultBtn>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <DefaultBtn size="small" fullWidth onClick={onSubmit} disabled={blockNextStep}>
                        Siguiente
                    </DefaultBtn>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default S3BucketForm;
