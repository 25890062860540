import { AlertProps } from './interfaces';

export const noop = () => {};

export const Identity = <T>(value: T) => value;

export const ALERT_INITIAL_VALUE = { open: false, message: '', type: 'error' } as AlertProps;

export const PRESIGNED_URL = 'https://fairplay--cdn-development.s3.amazonaws.com/:path*';

export const RAW_PLATFORM_KEYS = [
    'shopify',
    'magento-v2',
    'woocommerce-v2',
    'google-analytics',
    'google-ads',
    'google-ads-v2',
    'google-analytics-v4',
    'fads',
    'fads-v2',
    'sat',
    'amazon',
    'paypal',
    'stripe',
    'clip',
    'vtex',
    'mercado-libre',
    'tienda-nube',
    'walmart',
    // 'liverpool',
    's3connector',
    's3-manual-upload',
] as const;
