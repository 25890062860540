import { FC, useState } from 'react';
import { Collapse, Grid } from '@mui/material';
import { Alert, DefaultBtn, InterpunctLoader, Modal, Typography } from '@fairplay2/ui';
import { WomanAndManFacebookSvg } from 'assets/SVGs';
import { ErrorObj } from 'utils/error-handler';
import { useAlert } from 'utils/hooks';
import { ReauthorizeModalProps } from '../interfaces';
import { getConnectorAlias } from '../../utils';

export const REAUTH_MESSAGE = (
    <Typography component="p" role="note" variant="subtitle2" color="text.primary" lineHeight={1.5}>
        En Fairplay nos esforzamos en crear una mejor experiencia para nuestros clientes.
        <br />
        Para poder brindarte un funcionamiento adecuado en la operación de la plataforma y por la
        seguridad de tu información;{' '}
        <Typography component="span" color="primary.main" fontWeight={500}>
            es necesario que
        </Typography>{' '}
        cada cierto tiempo{' '}
        <Typography component="span" color="primary.main" fontWeight={500}>
            re&#8209;autorices la conexión que Fairplay Insights tiene con tus fuentes de datos.
        </Typography>{' '}
        Esta acción se te solicitará cuando así lo requieran tus conexiones.
    </Typography>
);

const Reauthorization: FC<ReauthorizeModalProps> = ({
    open,
    onClose,
    onReauth: onReauthProp,
    connector,
}) => {
    const [loading, setLoading] = useState(false),
        { alert, showAlert } = useAlert();

    const onReauth = () => {
        const response = onReauthProp?.(connector);
        if (typeof response === 'object' && typeof response.then === 'function') {
            setLoading(true);
            response.catch((errResponse: ErrorObj | any) => {
                setLoading(false);
                if (errResponse?.type === 'form') {
                    showAlert(
                        Object.values<any>(errResponse.error).reduce((acc, field) => {
                            const currentError =
                                typeof field === 'string' ? field : field.join(', ');
                            return acc ? `${acc}, ${currentError}` : currentError;
                        }, ''),
                    );
                } else showAlert(errResponse?.error || 'Ocurrió un error, intente mas tarde');
            });
        } else onClose();
    };
    return (
        <Modal
            disableBackdropClick
            open={open}
            onClose={onClose}
            disableEscapeKeyDown
            maxWidth={801}
            title={
                <>
                    Reautoriza la conexión de{' '}
                    <strong>
                        {connector.platform === 'mercado-libre'
                            ? connector.email
                            : getConnectorAlias(connector)}
                    </strong>
                </>
            }
            sx={{
                '.MuiDialogContent-root': {
                    pt: 0,
                },
            }}
        >
            <Grid container spacing={4}>
                <Grid item xs={12} textAlign="center" marginTop={3}>
                    <WomanAndManFacebookSvg style={{ maxWidth: '100%' }} />
                </Grid>

                <Grid item xs={12}>
                    {REAUTH_MESSAGE}
                </Grid>

                <Grid item xs={12} sx={{ margin: '0 auto', pt: '0 !important' }}>
                    <Collapse in={alert.open}>
                        <Alert severity={alert.type} sx={{ mt: 4 }}>
                            {alert.message}
                        </Alert>
                    </Collapse>
                </Grid>

                <Grid item xs={12} textAlign="center">
                    {loading ? (
                        <InterpunctLoader />
                    ) : (
                        <DefaultBtn onClick={onReauth} size="small" sx={{ paddingX: 3 }}>
                            Reconectar fuente
                        </DefaultBtn>
                    )}
                </Grid>
            </Grid>
        </Modal>
    );
};

export default Reauthorization;
