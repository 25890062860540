import { FC, useState, ChangeEvent, useContext, FocusEvent } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { Grid, Collapse, DialogTitle } from '@mui/material';
import { SuccessSvg, DisbursementsSvg } from 'assets/SVGs';
import SessionContext from 'context/session/sessionContext';
import {
    Modal,
    DefaultBtn,
    Typography,
    InterpunctLoader,
    Alert,
    DefaultInput,
} from '@fairplay2/ui';
import { ModalProps } from 'utils/interfaces';
import fairplayAPI from 'utils/api';
import { ErrorObj } from 'utils/error-handler'; // eslint-disable-line
import { useDisbursementValuesContext } from 'context/disbursements/DisbursementValuesContext';
import {
    monetaryDecimalToInteger,
    monetaryIntegerToDecimal,
    multiplyMonetaryIntegers,
} from 'utils/currency';
import { SHORT_READABLE_FORMAT, localFormat } from 'utils/dates';
import { useAlert } from 'utils/hooks/useAlert';
import { useBlockReload, useRecaptcha, useOtpStatus } from 'utils/hooks';
import { toFormData } from 'utils/parsing';
import { OTP, ReCaptcha } from 'common-components';
import { ExchangeRatesProps, Form } from '../../interfaces';

const getTotalRequestedAmountSumConfirmed = (
    totalRequestedAmountSum: number,
    exchangeRates: ExchangeRatesProps,
    cartData: Array<Form>,
) => {
    let newSum = totalRequestedAmountSum;
    cartData.forEach((item: Form) => {
        if (!item.confirmed)
            newSum -=
                item.currency === 'mxn'
                    ? monetaryDecimalToInteger(item.amount)
                    : multiplyMonetaryIntegers(
                          monetaryDecimalToInteger(item.amount),
                          exchangeRates[item.currency]?.rate,
                      );
    });
    return newSum;
};

const CreateConfirmationModal: FC<ModalProps> = ({ open, onClose }) => {
    const { user } = useContext(SessionContext),
        { isOtpRequired } = useOtpStatus(),
        {
            cartData,
            disbursementPeriod,
            maxDisbursementDate,
            exchangeRates,
            periodAvailableBalance,
            resetDisbursementValues,
            totalRequestedAmountSum,
        } = useDisbursementValuesContext(),
        [confirmation, setConfirmation] = useState(false),
        [loading, setLoading] = useState(false),
        [otp, setOtp] = useState(''),
        [otpError, setOtpError] = useState(''),
        [password, setPassword] = useState(''),
        [error, setError] = useState(''),
        { onLoadRecaptcha, resetRecaptcha, recaptchaIsLoading, recaptchaRef } = useRecaptcha(),
        history = useHistory(),
        totalDisbursements = cartData.length,
        confirmedDisbursements = cartData.filter((cartItem: Form) => !!cartItem.confirmed).length,
        totalRequestedAmountSumConfirmed = getTotalRequestedAmountSumConfirmed(
            totalRequestedAmountSum,
            exchangeRates,
            cartData,
        ),
        { alert, hideAlert, showAlert } = useAlert(),
        { changeBlockedStatus } = useBlockReload(false);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        hideAlert();
        setPassword(event.target.value);
    };

    const onBlur = (ev: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (password) return setError('');
        if (isOtpRequired && (otp || ev.target.value)) return setOtpError('');

        setError('Ingresa la contraseña');
        setOtpError('El código es requerido.');
    };

    const onCloseModal = () => {
        if (confirmation) {
            resetDisbursementValues();
            history.push('/app/disbursements/transactions');
        }
        onClose();
    };

    const confirmDisbursement = async (data: Record<string, any>) => {
        setLoading(true);
        changeBlockedStatus(true);
        try {
            await fairplayAPI.post(`/v1/cart/${user?.id}/confirm`, data, {
                useIdempotency: true,
                baseService: 'DISBURSEMENT_CART',
            });
            setConfirmation(true);
        } catch (errResponse: ErrorObj | any) {
            showAlert(
                errResponse?.error ||
                    'Ocurrió un error creando las dispersiones, intente más tarde',
            );
        } finally {
            setLoading(false);
            resetRecaptcha();
            changeBlockedStatus(false);
        }
    };

    const authorizeUsingPassword = () => {
        if (!password) return setError('Ingresa la contraseña');
        const recaptchaValue = recaptchaRef.current?.getValue();

        if (recaptchaValue) {
            const data = toFormData({
                email: user?.email,
                password,
                g_recaptcha_response: recaptchaValue,
            });

            confirmDisbursement(data);
        } else return showAlert('Es necesario completar el reCAPTCHA');
    };

    const authorizeUsingOtp = () => {
        setOtpError('');
        if (otp.length === 0) return setOtpError('El código es requerido.');
        if (otp.length !== 6) return setOtpError('El código debe tener 6 dígitos.');

        const data = toFormData({
            /* eslint-disable-next-line camelcase */
            token: otp,
        });
        confirmDisbursement(data);
    };

    const handleAuthorize = () => {
        hideAlert();

        if (isOtpRequired) authorizeUsingOtp();
        else authorizeUsingPassword();
    };

    return (
        <>
            <Prompt
                when={loading}
                message="¡Atención! Salir de la página ahora podría causar errores en el pago de las dispersiones. ¿Estás seguro?"
            />
            <Modal
                disableBackdropClick
                open={open}
                onClose={loading ? undefined : onCloseModal}
                closeIconButton={!loading}
                maxWidth={583}
            >
                {confirmation ? (
                    <Grid container>
                        <Grid container item xs={12} justifyContent="center" mt="10px">
                            <SuccessSvg />
                        </Grid>
                        <Grid item xs={12} mt="10px">
                            <Typography
                                variant="subtitle1"
                                color="primary.main"
                                component={DialogTitle}
                                p={0}
                            >
                                ¡Dispersiones solicitadas exitosamente!
                            </Typography>
                        </Grid>
                        <Grid item xs={12} mt="20px">
                            <Typography variant="subtitle1" fontWeight={700} lineHeight={1.5}>
                                {/* TODO: Verify if this copy needs to be the same as OTP copy  */}
                                Has solicitado{' '}
                                <Typography component="span" color="primary.main" fontWeight={700}>
                                    {confirmedDisbursements} dispersiones.{' '}
                                </Typography>
                                Confirma su estatus en el listado de dispersiones.
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} justifyContent="center" mt="40px">
                            <DefaultBtn size="small" onClick={onCloseModal}>
                                Entendido
                            </DefaultBtn>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container>
                        <Grid container item xs={12} justifyContent="center">
                            <DisbursementsSvg />
                        </Grid>
                        <Grid item xs={12} mt="20px">
                            <Typography
                                variant="subtitle1"
                                color="text.primary"
                                component={DialogTitle}
                                p={0}
                                lineHeight={1.5}
                                fontWeight={500}
                            >
                                Hiciste una lista de{' '}
                                <Typography component="span" fontWeight={700} color="primary.dark">
                                    {totalDisbursements} dispersiones{' '}
                                </Typography>
                                y{' '}
                                <Typography component="span" fontWeight={700} color="primary.dark">
                                    confirmaste {confirmedDisbursements}
                                </Typography>{' '}
                                ¿Es correcto?
                            </Typography>
                            {isOtpRequired ? (
                                <Typography
                                    variant="subtitle2"
                                    fontWeight={700}
                                    mt="30px"
                                    color="primary.dark"
                                >
                                    Si es correcto y deseas autorizar, abre tu aplicación de
                                    autenticación y genera un código de un solo uso. A continuación
                                    introdúcelo aquí:
                                </Typography>
                            ) : (
                                <Typography
                                    variant="subtitle2"
                                    fontWeight={700}
                                    mt="30px"
                                    color="primary.dark"
                                >
                                    Si es correcto y quieres autorizar solo {confirmedDisbursements}{' '}
                                    introduce tu contraseña
                                </Typography>
                            )}
                        </Grid>
                        {isOtpRequired ? (
                            <Grid
                                item
                                container
                                xs={12}
                                mt="20px"
                                justifyContent="center"
                                width="fit-content"
                                mb={alert.open ? '15px' : ''}
                            >
                                <OTP
                                    value={otp}
                                    setValue={setOtp}
                                    error={otpError}
                                    onBlur={onBlur}
                                />
                            </Grid>
                        ) : (
                            <>
                                <Grid container item xs={12} justifyContent="center" mt="25px">
                                    <DefaultInput
                                        name="password"
                                        placeholder="Introduce tu contraseña"
                                        value={password}
                                        type="password"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        error={error}
                                        sx={{ maxWidth: '400px' }}
                                        label="Introduce tu contraseña"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    container
                                    justifyContent="center"
                                    mt="20px"
                                    mb={alert.open ? '15px' : ''}
                                    sx={{
                                        transform: {
                                            lg: 'scale(0.9)',
                                            xl: 'scale(1)',
                                        },
                                    }}
                                >
                                    <ReCaptcha
                                        ref={recaptchaRef}
                                        isLoading={recaptchaIsLoading}
                                        onLoad={onLoadRecaptcha}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid container justifyContent="center">
                            <Collapse in={alert.open}>
                                <Alert
                                    severity={alert.type}
                                    aria-label="Error realizando la dispersión"
                                >
                                    {alert.message}
                                </Alert>
                            </Collapse>
                        </Grid>
                        <Grid container item xs={12} justifyContent="center" mt="15px">
                            {loading ? (
                                <Grid item sx={{ mx: '10px', mt: '15px' }}>
                                    <InterpunctLoader />
                                </Grid>
                            ) : (
                                <>
                                    <DefaultBtn
                                        size="small"
                                        variant="outlined"
                                        onClick={onCloseModal}
                                        sx={{ m: '10px' }}
                                    >
                                        Editar lista
                                    </DefaultBtn>
                                    <DefaultBtn
                                        size="small"
                                        disabled={!confirmedDisbursements}
                                        onClick={handleAuthorize}
                                        sx={{ m: '10px' }}
                                    >
                                        Autorizar
                                    </DefaultBtn>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                color="text.primary"
                                fontWeight={500}
                                mt="10px"
                                role="alert"
                                aria-label="Confirmación de dispersión"
                                lineHeight={1.5}
                            >
                                Esta solicitud{' '}
                                <Typography component="span" fontWeight={700} color="primary.main">
                                    dispersará un total de MXN${' '}
                                    {monetaryIntegerToDecimal(totalRequestedAmountSumConfirmed)}
                                </Typography>{' '}
                                del fondo del{' '}
                                <Typography component="span" fontWeight={700} color="primary.main">
                                    Periodo {disbursementPeriod === 'current' ? '1' : '2'}.
                                </Typography>{' '}
                                Aún tienes un{' '}
                                <Typography component="span" fontWeight={700} color="primary.dark">
                                    saldo disponible
                                </Typography>{' '}
                                de{' '}
                                <Typography component="span" fontWeight={700} color="primary.dark">
                                    MXN${' '}
                                    {monetaryIntegerToDecimal(
                                        periodAvailableBalance - totalRequestedAmountSumConfirmed,
                                    )}{' '}
                                </Typography>
                                que puedes{' '}
                                <Typography component="span" fontWeight={700} color="primary.dark">
                                    utilizar antes{' '}
                                </Typography>
                                de:{' '}
                                <Typography component="span" fontWeight={700} color="primary.dark">
                                    {localFormat(maxDisbursementDate || '', SHORT_READABLE_FORMAT, {
                                        fallbackString: '',
                                    })}
                                </Typography>
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Modal>
        </>
    );
};

export default CreateConfirmationModal;
