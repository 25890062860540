import { ChartFactory } from 'utils/formatting';
import { BaseState } from '../interfaces';
import { baseReducer } from '../baseReducer';
import { TrendsAction, TrendsChartKeys } from './interfaces';

const initialChartData = {
    distWeekly: {
        title: 'Distribución por día de la semana',
        isEmpty: true,
        loading: true,
        options: {
            series: [
                {
                    type: 'spline',
                    color: '#9689F2',
                    name: 'Distribución por día',
                    data: [],
                },
            ],
            xCategories: [],
            valueSuffix: '%',
            yLabels: '{value}%',
        },
    },
    distMonthly: {
        title: 'Distribución por mes',
        isEmpty: true,
        loading: true,
        options: {
            series: [
                {
                    type: 'spline',
                    color: '#9689F2',
                    name: 'Promedio',
                    data: [],
                },
            ],
            yPlotLines: [
                {
                    color: '#00D146',
                    value: 0,
                    width: 2,
                    dashStyle: 'longdash',
                },
            ],
            valueSuffix: '%',
            yLabels: '{value}%',
        },
    },
    trendMonthly: {
        title: 'Tendencia mensual',
        isEmpty: true,
        loading: true,
        options: {
            series: [
                {
                    type: 'spline',
                    color: '#9689F2',
                    name: 'Tendencia en ventas',
                    data: [],
                },
            ],
            valuePrefix: '$',
            // eslint-disable-next-line no-template-curly-in-string
            yLabels: '${value}',
        },
    },
};

export const TrendsInitialState: BaseState<TrendsChartKeys> = {
    chartData: initialChartData,
};

export const getDistWeekly = (data: any) => {
    if (data.length > 0) {
        const values = [],
            dates = [];
        for (let i = 0; i < data.length; i++) {
            values.push(data[i].pweekly);
            dates.push(data[i].day);
        }

        return {
            isEmpty: false,
            options: ChartFactory({
                ...initialChartData.distWeekly.options,
                series: [
                    {
                        ...initialChartData.distWeekly.options.series[0],
                        data: values,
                    },
                ],
                xCategories: dates,
            }),
        };
    }
    return { options: {}, isEmpty: true };
};

export const getDistMonthly = (data: any) => {
    if (data.length > 0) {
        const values = [];
        for (let i = 0; i < data.length; i++) {
            const date = data[i].month.split('T')[0];
            values.push([Date.parse(date), data[i].average]);
        }

        return {
            isEmpty: false,
            options: ChartFactory({
                ...initialChartData.distMonthly.options,
                series: [
                    {
                        ...initialChartData.distMonthly.options.series[0],
                        data: values,
                    },
                ],
            }),
        };
    }
    return { options: {}, isEmpty: true };
};

export const getTrendMonthly = (data: any) => {
    if (data.length > 0) {
        const values = [];
        for (let i = 0; i < data.length; i++) {
            const date = data[i].month.split('T')[0];
            values.push([Date.parse(date), data[i].trend]);
        }
        return {
            isEmpty: false,
            options: ChartFactory({
                ...initialChartData.trendMonthly.options,
                series: [
                    {
                        type: 'spline',
                        color: '#9689F2',
                        name: 'Tendencia en ventas',
                        data: values,
                    },
                ],
            }),
        };
    }
    return { options: {}, isEmpty: true };
};

export const TrendsReducer = (state: BaseState<TrendsChartKeys>, action: TrendsAction) => {
    switch (action.type) {
        case 'SET_DIST_WEEKLY':
            return baseReducer<TrendsChartKeys, never>(state, {
                type: 'SET_CHART',
                payload: { key: 'distWeekly', value: getDistWeekly(action.payload.value) },
            });
        case 'SET_DIST_MONTHLY':
            return baseReducer<TrendsChartKeys, never>(state, {
                type: 'SET_CHART',
                payload: { key: 'distMonthly', value: getDistMonthly(action.payload.value) },
            });
        case 'SET_TREND_MONTHLY':
            return baseReducer<TrendsChartKeys, never>(state, {
                type: 'SET_CHART',
                payload: { key: 'trendMonthly', value: getTrendMonthly(action.payload.value) },
            });
        default:
            return baseReducer(state, action);
    }
};
