import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Modal, DefaultBtn, Typography } from '@fairplay2/ui';
import { ManParagraphTwoSvg } from 'assets/SVGs';
import { addGoogleTag } from 'utils/google-tag-manager';
import { AddVendorModalProps } from '../interfaces';

const AddVendorModal: FC<AddVendorModalProps> = ({ isModalOpen, onModalClose }) => {
    const history = useHistory();

    const onStart = () => {
        document.body.style.overflow = 'auto';
        history.push('/app/financing/vendors/add-vendor');
        addGoogleTag('add-new-vendor-start');
    };

    return (
        <Modal open={isModalOpen} onClose={() => onModalClose('addVendor')} maxWidth={524}>
            <Grid container>
                <Grid container justifyContent="center">
                    <ManParagraphTwoSvg />
                </Grid>
                <Typography variant="subtitle1" color="primary.main" mt="10px">
                    ¿A quién le pagamos?
                </Typography>
                <Grid item>
                    <Typography variant="subtitle2" color="text.primary" fontWeight={700} mt="10px">
                        Agrega proveedores en solo 3 pasos:
                        <ol>
                            <li>- Elige el tipo de proveedor</li>
                            <li>- Llena los datos de su expediente</li>
                            <li>- Agrega facturas</li>
                        </ol>
                    </Typography>

                    <Typography variant="subtitle2" color="text.primary" fontWeight={500}>
                        Es necesario tener preparados los datos bancarios y al menos 5 facturas
                        (solo si ya le has pagado antes). ¿Estás listo? da clic en el botón.
                    </Typography>
                </Grid>
                <Grid container justifyContent="center">
                    <DefaultBtn size="small" onClick={onStart} sx={{ mt: '20px' }}>
                        Comenzar
                    </DefaultBtn>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default AddVendorModal;
