import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Typography, FullscreenLayout } from '@fairplay2/ui';
import { StoreGhostSvg } from 'assets/SVGs';
import { TitleContainer } from './styles';

export const NotFoundCompaniesPage: FC = () => (
    <FullscreenLayout
        alignItems="center"
        justifyContent="center"
        sx={{
            backgroundColor: 'primary.A400',
            padding: 2,
            '& #store-ghost': {
                width: '100%',
                height: 'auto',
                zIndex: 9,
                maxWidth: 515,
            },
        }}
    >
        <Grid
            container
            item
            direction="column"
            maxWidth={780}
            height="100%"
            wrap="nowrap"
            padding={2}
            spacing={2}
        >
            <Grid container item position="relative" justifyContent="center" spacing={2}>
                <TitleContainer item xs={12}>
                    <Typography variant="h2" color="primary.contrastText">
                        ¡Oh no!
                    </Typography>
                </TitleContainer>
                <Grid item>
                    <StoreGhostSvg id="store-ghost" />
                </Grid>
            </Grid>
            <Grid item>
                <Typography variant="h3" color="primary.contrastText">
                    Nada por aquí…
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="h5" color="primary.contrastText" fontWeight={700}>
                    Lo sentimos, tu perfil de usuario no forma parte del equipo de la empresa que
                    buscas. Por favor verifica con el administrador de la cuenta que aún tienes
                    acceso o envía un correo a{' '}
                    <a href="mailto:cusu@getfairplay.com" style={{ color: 'white' }}>
                        cusu@getfairplay.com
                    </a>
                    , nuestro equipo se comunicará lo más pronto posible para ayudarte.
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="h5" color="primary.contrastText" fontWeight={700} mb="20px">
                    Si deseas regresar al inicio da click{' '}
                    <Typography
                        variant="h5"
                        color="secondary.main"
                        fontWeight={700}
                        component={Link}
                        to="/"
                    >
                        aquí.
                    </Typography>
                </Typography>
            </Grid>
        </Grid>
    </FullscreenLayout>
);
