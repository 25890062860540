import { FC, useState, useContext } from 'react';
import { Collapse, DialogTitle, Grid } from '@mui/material';
import { ManParagraphSvg } from 'assets/SVGs';
import { Modal, DefaultBtn, Typography, InterpunctLoader, Alert } from '@fairplay2/ui';
import { useDisbursementValuesContext } from 'context/disbursements/DisbursementValuesContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import fairplayAPI from 'utils/api';
import SessionContext from 'context/session/sessionContext';
import { useAlert } from 'utils/hooks/useAlert';
import { isNotEmptyError } from 'utils/validation';
import { getFormStatus, getFormData, getAmountValidation, onPresignedUrlUpload } from '../../utils';
import { ConfirmChangesModalProps } from '../../interfaces';

const ConfirmChangesModal: FC<ConfirmChangesModalProps> = ({
    open,
    onClose,
    onFormStepChange,
    index,
    errors,
}) => {
    const { user } = useContext(SessionContext),
        {
            cartData,
            setCartData,
            setDisbursementAlert,
            discardDisbFormChange,
            selectedContract,
            exchangeRates,
            prevTotalRequestedAmount,
            periodAvailableBalance,
            disbursementPeriod,
            formCopy,
            totalRequestedAmountSum,
        } = useDisbursementValuesContext(),
        [loading, setLoading] = useState(false),
        { alert, hideAlert, showAlert } = useAlert();

    const onDiscard = () => {
        const cartItemCurrency = formCopy.currency.toLowerCase(),
            amountValidation = getAmountValidation(
                formCopy.amount,
                cartItemCurrency,
                exchangeRates,
                prevTotalRequestedAmount,
                periodAvailableBalance,
            );

        if (!amountValidation.available) {
            showAlert(
                amountValidation.exchangeError ||
                    `La cantidad anterior supera el monto disponible del periodo ${
                        disbursementPeriod === 'current' ? 1 : 2
                    }`,
            );
            return;
        }
        discardDisbFormChange(index);

        onClose();
        onFormStepChange();
    };

    const onFormUpdate = async () => {
        hideAlert();
        const formStatus = getFormStatus(
            selectedContract.productType,
            cartData[index],
            !!errors.amount,
        );

        if (!formStatus.valid) {
            showAlert(
                isNotEmptyError(errors.amount)
                    ? errors.amount
                    : 'Hay campos pendientes de completar, revisa el formulario',
            );
            return;
        }
        setLoading(true);
        const data = getFormData(
            { ...cartData[index], totalRequestedAmountSum },
            selectedContract.productType,
        );

        try {
            if (cartData[index].fileName === formCopy.fileName) {
                data.delete('file_name');
            }

            const res: any = await fairplayAPI.put(
                `/v1/cart/${user?.id}/items/${cartData[index]?.id_dispersion}`,
                data,
                {
                    baseService: 'DISBURSEMENT_CART',
                },
            );

            const presignedUrl = res.data.results.presigned_url;
            const { file } = cartData[index];

            await onPresignedUrlUpload(presignedUrl, file, () => {
                const fallbackCartData = [...cartData];

                fallbackCartData[index].file = null;
                fallbackCartData[index].fileName = '';

                setCartData(fallbackCartData);
            });

            // TODO: validate if this alerts is necessary
            setDisbursementAlert({ msg: 'Dispersión actualizada', severity: 'success' });
            setTimeout(
                () =>
                    setDisbursementAlert({
                        msg: '',
                        severity: 'success',
                    }),
                2000,
            );
            onFormStepChange();

            onClose();
        } catch (errResponse: ErrorObj | any) {
            showAlert(
                errResponse?.error || 'Ocurrió un error enviando la información, intente más tarde',
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            disableBackdropClick
            open={open}
            onClose={loading ? undefined : onClose}
            maxWidth={524}
        >
            <Grid container>
                <Grid container item xs={12} justifyContent="center">
                    <ManParagraphSvg />
                </Grid>
                <Grid item xs={12} mt="10px">
                    <Typography
                        variant="subtitle1"
                        color="primary.main"
                        component={DialogTitle}
                        p={0}
                    >
                        ¿Deseas guardar los cambios?
                    </Typography>
                    <Typography color="text.primary" fontWeight={500} mt="1px">
                        Hiciste cambios en esta dispersión,{' '}
                        <Typography component="span" color="primary.dark" fontWeight={700}>
                            da clic en guardar para conservarlos.
                        </Typography>
                    </Typography>
                </Grid>
                <Grid container justifyContent="center">
                    <Collapse in={alert.open}>
                        <Alert severity={alert.type} sx={{ mt: 3 }}>
                            {alert.message}
                        </Alert>
                    </Collapse>
                </Grid>
                <Grid container item xs={12} justifyContent="center" mt="20px">
                    {loading ? (
                        <Grid item sx={{ mx: '10px', mt: '15px' }}>
                            <InterpunctLoader />
                        </Grid>
                    ) : (
                        <>
                            <DefaultBtn
                                size="small"
                                variant="outlined"
                                onClick={onDiscard}
                                sx={{ mx: '10px', mt: '10px' }}
                            >
                                Descartar
                            </DefaultBtn>
                            <DefaultBtn
                                size="small"
                                onClick={onFormUpdate}
                                sx={{ mx: '10px', mt: '10px' }}
                            >
                                Guardar
                            </DefaultBtn>
                        </>
                    )}
                </Grid>
            </Grid>
        </Modal>
    );
};

export default ConfirmChangesModal;
