import { FC, useState, useEffect } from 'react';
import { Collapse, Grid } from '@mui/material';
import { Modal, DefaultBtn, Alert } from '@fairplay2/ui';
import { ConnectorData, GroupedPlatformKey } from 'components/dashboard/data-sources/interfaces';
import { getPlatformDisplayName } from 'components/dashboard/data-sources/utils';
import { useDataSources } from 'components/dashboard/data-sources/context/DataSourcesContext';
import { groupPlatformKey } from 'components/dashboard/data-sources/context/utils';
import { useAlert } from 'utils/hooks';
import Disconnect from '../disconnect-item/Disconnect';
import { ViewModalProps } from '../../interfaces';
import ConfigureAdvertising from '../wizard-advertising/Configure';
import { REAUTH_MESSAGE } from '../Reauthorize';
import ConnectionItem from './ConnectionItem';

export const getPendingText = (platform: GroupedPlatformKey) => {
    if (['google-ads', 'google-analytics', 'facebook-ads'].includes(platform)) return 'Configurar';
    if (platform === 'woocommerce') return 'Validar conexión';
    return '';
};

const ViewModal: FC<ViewModalProps> = ({ open = false, platform, onClose, onReauth }) => {
    const { platforms } = useDataSources(),
        { connectors, reauth } = platforms?.[platform as GroupedPlatformKey] || {},
        { alert, showAlert } = useAlert(),
        [showDisconnect, setShowDisconnect] = useState(false),
        [showConfig, setShowConfig] = useState(false),
        [itemSelected, setItemSelected] = useState<ConnectorData | null>(null),
        pendingText = getPendingText(platform as GroupedPlatformKey);

    const onCloseModal = () => {
        setShowDisconnect(false);
        setShowConfig(false);
        onClose();
    };

    const openDisconnect = (item: any) => {
        setItemSelected(item);
        setShowDisconnect(true);
    };

    const onPending = (item: ConnectorData) => {
        if (pendingText === 'Configurar') {
            setItemSelected(item);
            setShowConfig(true);
        }
    };

    const getProps = (item: ConnectorData) => ({
        onDisconnect: openDisconnect,
        onPendingText: pendingText,
        onPending: item.platform !== 'woocommerce-v2' ? onPending : undefined,
        onReauth,
        notifyError: showAlert,
        onCloseModal,
    });

    useEffect(() => {
        setShowDisconnect(false);
    }, [platform, connectors]);

    return (
        <Modal
            disableBackdropClick
            title={
                !showConfig && !showDisconnect ? (
                    <>
                        Conexiones existentes de{' '}
                        <strong>{getPlatformDisplayName(platform as GroupedPlatformKey)}</strong>
                    </>
                ) : (
                    ''
                )
            }
            open={open}
            onClose={onCloseModal}
            maxWidth={platform.includes('google') && showConfig ? 1280 : 980}
            disableEscapeKeyDown
            sx={{
                '.MuiDialogContent-root': {
                    pt: 0,
                },
            }}
        >
            {/* eslint-disable-next-line no-nested-ternary */}
            {showDisconnect ? (
                <Disconnect
                    open={showDisconnect}
                    onCancel={() => setShowDisconnect(false)}
                    onClose={onCloseModal}
                    platform={platform}
                    element={itemSelected}
                />
            ) : showConfig ? (
                <ConfigureAdvertising
                    element={itemSelected}
                    onClose={onCloseModal}
                    onCancel={() => setShowConfig(false)}
                    groupKey={groupPlatformKey(itemSelected?.platform || 'google-ads')}
                />
            ) : (
                <Grid container spacing={4} paddingTop="20px">
                    {!!reauth?.length && (
                        <Grid item xs={12} marginBottom={2}>
                            {REAUTH_MESSAGE}
                        </Grid>
                    )}
                    <Grid item xs={12} role="list" paddingTop="2px !important">
                        {connectors?.map((connector) => (
                            <ConnectionItem
                                key={connector.id}
                                item={connector}
                                {...getProps(connector)}
                            />
                        ))}
                    </Grid>
                    <Grid item xs={12} sm={9} md={6} sx={{ margin: '0 auto' }}>
                        <Collapse in={alert.open}>
                            <Alert severity={alert.type} sx={{ mb: 4 }}>
                                {alert.message}
                            </Alert>
                        </Collapse>
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: 'center', pt: '0px !important' }}>
                        <DefaultBtn size="small" variant="outlined" onClick={onCloseModal}>
                            Cerrar
                        </DefaultBtn>
                    </Grid>
                </Grid>
            )}
        </Modal>
    );
};

export default ViewModal;
