import { FC } from 'react';
import { Grid } from '@mui/material';
import { Skeleton } from '@fairplay2/ui';
import { IframeCardSkeletonProps } from '../../interfaces';
import CardBackground from '../../components/CardBackground';

const IframeCardSkeleton: FC<IframeCardSkeletonProps> = ({ isMobile }) => (
    <Grid
        container
        data-testid="skeleton-info-card"
        justifyContent={{ xs: 'center', sm: 'flex-start' }}
    >
        {isMobile ? (
            <Grid
                bgcolor="primary.dark"
                width="260px"
                height="240px"
                borderRadius="10px"
                padding="30px 20px"
            >
                {Array(3)
                    .fill(null)
                    .map((_, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Grid item key={index}>
                            <Skeleton
                                variant="text"
                                width={120}
                                sx={{ bgcolor: 'primary.main', mb: '5px' }}
                            />
                            <Skeleton
                                variant="text"
                                width={170}
                                sx={{ bgcolor: 'primary.main', mb: '20px' }}
                            />
                        </Grid>
                    ))}
            </Grid>
        ) : (
            <Grid width="350px">
                <CardBackground>
                    <Grid container justifyContent="center">
                        <Grid item position="absolute" top={73} width="250px">
                            <Skeleton height={35} width={250} sx={{ bgcolor: 'primary.main' }} />
                        </Grid>
                        <Grid
                            item
                            container
                            justifyContent="center"
                            position="absolute"
                            top={120}
                            width="250px"
                        >
                            <Skeleton
                                height={35}
                                width={100}
                                sx={{ bgcolor: 'primary.main', mr: '20px' }}
                            />
                            <Skeleton height={35} width={100} sx={{ bgcolor: 'primary.main' }} />
                        </Grid>
                    </Grid>
                </CardBackground>
            </Grid>
        )}
    </Grid>
);

export default IframeCardSkeleton;
