import { FC } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { Typography } from '@fairplay2/ui';
import { SHORT_READABLE_FORMAT, formatUtc } from 'utils/dates';
import { ApprovalRequestItemProps } from '../../../interfaces';

const ApprovalRequestItem: FC<ApprovalRequestItemProps> = ({ approvalRequest }) => (
    <TableRow>
        <TableCell sx={{ minWidth: 90 }}>
            <Typography color="primary.dark">
                {formatUtc(approvalRequest?.created || '', SHORT_READABLE_FORMAT, {
                    fallbackString: '',
                })}
            </Typography>
        </TableCell>
        <TableCell>
            <Typography color="primary.dark">
                {approvalRequest?.requested_by?.name || ''}
            </Typography>
        </TableCell>
        <TableCell>
            <Typography fontWeight={700} color="primary.main">
                {approvalRequest?.status_request.text || ''}
            </Typography>
        </TableCell>
    </TableRow>
);

export default ApprovalRequestItem;
