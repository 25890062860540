import { ChangeEvent, FC, useState, FocusEvent, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Collapse, Grid, MenuItem } from '@mui/material';
import { DefaultInput, Select, Typography, Autocomplete, FilePicker } from '@fairplay2/ui';
import { CurrencyInput } from '@fairplay2/ui-x-currencies';
import { DatePicker } from '@fairplay2/ui-x-dates';
import { validateFileSize } from 'utils/files';
import { useDisbursementValuesContext } from 'context/disbursements/DisbursementValuesContext';
import { monetaryDecimalToInteger, monetaryIntegerToDecimal } from 'utils/currency';
import { DisbursementFormProps, Errors, EmptyValueErrors } from '../interfaces';
import { getAmountValidation, getMinDisbursementDate } from '../utils';
import { SINGLE_DISBURSEMENT_ERRORS } from '../constants';

const EMPTY_VALUE_ERRORS: EmptyValueErrors = {
    concept: 'Ingresa el concepto de la transferencia',
    supplierAccount: 'Selecciona la cuenta bancaria',
    amount: 'Ingresa el monto a pagar',
    reference: 'Ingresa la referencia de la transferencia',
};

const DisbursementForm: FC<DisbursementFormProps> = ({
    errors,
    setErrors,
    index,
    prevCartLength: prevCartLengthRef,
}) => {
    const {
            cartData,
            setCartData,
            selectedContract,
            suppliers,
            minDisbursementDate,
            maxDisbursementDate,
            disabledDisbursementDays,
            disbursementAlert,
            setDisbursementAlert,
            totalRequestedAmount,
            setTotalRequestedAmount,
            periodAvailableBalance,
            setSuppliersCount,
            exchangeRates,
            prevTotalRequestedAmount,
            setPrevTotalRequestedAmount,
            totalRequestedAmountSum,
            disbursementPeriod,
            setIsAmountValid,
            isAmountValid,
        } = useDisbursementValuesContext(),
        [tooltips, setTooltips] = useState({
            concept: false,
            reference: false,
            amount: false,
        }),
        // TODO: Fix supplier vs selectedContract race condition
        disbReceiverData =
            selectedContract.productType === 'working-capital'
                ? cartData[index].supplierData
                : suppliers,
        [minDisbDate] = useState(() =>
            disbursementPeriod === 'current' ? getMinDisbursementDate() : minDisbursementDate,
        );

    const onBlur = (event: FocusEvent<HTMLInputElement>) => {
        const inputName = event.target?.name;
        if (!errors[inputName])
            setErrors((prevState: Errors) => ({
                ...prevState,
                [inputName]: cartData[index][inputName] ? '' : EMPTY_VALUE_ERRORS[inputName],
            }));

        if (inputName === 'supplierAccount') return;

        setTooltips({ ...tooltips, [inputName]: false });
    };

    const onChange = (event: ChangeEvent<HTMLInputElement | any>) => {
        if (disbursementAlert.msg)
            setDisbursementAlert({
                msg: '',
                severity: 'success',
            });
        const inputName = event?.target?.name || 'dispersion_date',
            value = event?.target ? event.target.value : event,
            newCartData = [...cartData];

        setErrors({ ...errors, [inputName]: '' });

        switch (inputName) {
            case 'concept':
                newCartData[index] = {
                    ...cartData[index],
                    concept: value.replace(/[^\w\s]/gi, ''),
                };
                setTooltips({ ...tooltips, concept: true });
                break;
            case 'reference': {
                const newReference = value.replace(/\D/, '');

                setTooltips({ ...tooltips, reference: true });

                if (newReference.length > 7) break;

                newCartData[index] = {
                    ...cartData[index],
                    reference: newReference,
                };
                break;
            }
            case 'amount': {
                setTooltips({ ...tooltips, amount: true });
                const newValue = value ? Number(value.replace(/,/g, '')) : 0;

                newCartData[index] = {
                    ...cartData[index],
                    amount: newValue,
                };

                const currency = cartData[index].currency.toLowerCase(),
                    newAmount = getAmountValidation(
                        newValue,
                        currency,
                        exchangeRates,
                        prevTotalRequestedAmount,
                        periodAvailableBalance,
                    );

                if (newAmount.available && !isAmountValid) setIsAmountValid(true);
                if (!newAmount.available) {
                    setTooltips({ ...tooltips, amount: false });
                    setErrors((prevState: any) => ({
                        ...prevState,
                        amount:
                            newAmount?.exchangeError ||
                            `Esta cantidad supera el monto disponible del periodo ${
                                disbursementPeriod === 'current' ? 1 : 2
                            }`,
                    }));
                    setIsAmountValid(false);
                }

                setTotalRequestedAmount(newAmount.value);

                break;
            }
            default:
                newCartData[index] = {
                    ...cartData[index],
                    [inputName]: value,
                };
                break;
        }
        setCartData(newCartData);
    };

    const onDisbReceiverData = (newValue: any) => {
        if (disbursementAlert.msg)
            setDisbursementAlert({
                msg: '',
                severity: 'success',
            });
        setErrors({ ...errors, supplierData: '', supplierAccount: '', amount: '' });
        const newCartData = [...cartData];
        newCartData[index] = {
            ...cartData[index],
            supplierData: newValue,
            supplierAccount: '',
            amount: 0,
            currency: '',
        };

        setIsAmountValid(true);
        setTotalRequestedAmount(prevTotalRequestedAmount);
        setSuppliersCount(newCartData);
        setCartData(newCartData);
    };

    const onDisbReceiverBankAcct = (event: ChangeEvent<HTMLInputElement>) => {
        if (disbursementAlert.msg)
            setDisbursementAlert({
                msg: '',
                severity: 'success',
            });
        setErrors({ ...errors, supplierAccount: '' });
        const newValue: any = event.target.value,
            newCartData = [...cartData];
        newCartData[index] = {
            ...cartData[index],
            supplierAccount: newValue,
            currency: newValue?.accountCurrency?.id.toLowerCase(),
            amount: 0,
        };

        setTotalRequestedAmount(prevTotalRequestedAmount);
        setCartData(newCartData);
    };

    const onFileChange = (newFile: File | null, target: HTMLInputElement | null) => {
        if (disbursementAlert.msg)
            setDisbursementAlert({
                msg: '',
                severity: 'success',
            });

        if (!newFile) {
            const newCartData = [...cartData];
            newCartData[index] = { ...cartData[index], file: null, fileName: '' };
            setCartData(newCartData);

            return;
        }

        const fileError = validateFileSize(newFile, 15, 'El tamaño máximo de archivo es 15MB');
        if (fileError) return setErrors({ ...errors, file: fileError });

        setErrors({ ...errors, file: '', fileName: '' });
        const newCartData = [...cartData];
        newCartData[index] = {
            ...cartData[index],
            // TODO: Unify file and fileName variables into one
            file: newFile,
            fileName: newFile?.name,
        };
        setCartData(newCartData);

        if (target) {
            // eslint-disable-next-line no-param-reassign
            target.value = '';
        }
    };

    const enableTabSelection = () => {
        // Enables just new tabs added, not the first one
        if (!index) return;
        const newCartData = [...cartData];
        newCartData[index] = { ...cartData[index], disabled: false };
        setCartData(newCartData);
    };

    useEffect(() => {
        setErrors(SINGLE_DISBURSEMENT_ERRORS);
        // Condition to avoid Form first configuration (code below) to run, when a new item is added to cart
        if (!!prevCartLengthRef.current && cartData.length > prevCartLengthRef.current) {
            prevCartLengthRef.current = cartData.length;
            return;
        }

        if (disbursementAlert.severity === 'error')
            setDisbursementAlert({
                msg: '',
                severity: 'success',
            });

        // Sets user own data for sales advancement contracts only. This code is executed every time a form is used, so this validation prevents re-assigning supplierData to it if it already has it.
        if (
            selectedContract.productType === 'sales-advancement' &&
            !cartData[index]?.supplierData
        ) {
            const newCartData = [...cartData];
            newCartData[index] = {
                ...cartData[index],
                supplierData: suppliers,
                disabled: false,
            };
            setCartData(newCartData);
        } else enableTabSelection();

        const cartItemCurrency = cartData[index].currency.toLowerCase();
        let cartItemPreviousReqstdAmount = {};
        if (cartData[index].amount)
            cartItemPreviousReqstdAmount = {
                [cartItemCurrency]:
                    totalRequestedAmount[cartItemCurrency] -
                    monetaryDecimalToInteger(cartData[index].amount),
            };
        setPrevTotalRequestedAmount({
            ...totalRequestedAmount,
            ...cartItemPreviousReqstdAmount,
        });

        prevCartLengthRef.current = cartData.length;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartData.length]);

    useEffect(() => {
        // Checks if form contains amount errors when rendering
        if (periodAvailableBalance) {
            const cartItemCurrency = cartData[index].currency.toLowerCase(),
                amountValidation = getAmountValidation(
                    cartData[index].amount,
                    cartItemCurrency,
                    exchangeRates,
                    prevTotalRequestedAmount,
                    periodAvailableBalance,
                );

            if (!amountValidation.available) {
                setTooltips({ ...tooltips, amount: false });
                setErrors((prevState: any) => ({
                    ...prevState,
                    amount:
                        amountValidation?.exchangeError ||
                        `Esta cantidad supera el monto disponible del periodo ${
                            disbursementPeriod === 'current' ? 1 : 2
                        }`,
                }));
            } else setErrors(SINGLE_DISBURSEMENT_ERRORS);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [periodAvailableBalance, exchangeRates, prevTotalRequestedAmount]);

    return (
        <Grid item container xs={12} md={6} rowSpacing={2} order={{ md: 2, xs: 3 }}>
            <Grid item xs={12}>
                <Typography variant="body2" color="success.main" fontWeight={700} role="alert">
                    *Recuerda solicitar pagos con mínimo un día de anticipación.
                </Typography>
                <DatePicker
                    id="disbursement-date"
                    label="Fecha de pago"
                    mode="single"
                    placeholder="Selecciona la fecha de pago"
                    displayIcon
                    error={errors.dispersion_date}
                    value={cartData[index].dispersion_date}
                    // Callback signature does not match but onChange
                    //  is used as it performs shared logic for all inputs
                    // @ts-ignore
                    onChange={onChange}
                    inputAlignment="start"
                    disabledDays={disabledDisbursementDays}
                    PickerProps={{
                        fromDate: minDisbDate,
                        toDate: maxDisbursementDate,
                    }}
                />
            </Grid>
            {selectedContract.productType === 'working-capital' && (
                <Grid item xs={12}>
                    <Typography
                        variant="body2"
                        color="success.main"
                        fontWeight={700}
                        sx={{ '& a': { color: 'success.main' } }}
                        aria-label="Agregar proveedor"
                        role="note"
                    >
                        *Si el proveedor no aparece en la lista{' '}
                        <Link to="/app/financing/vendors/add-vendor">
                            <Typography component="span" color="success.main" fontWeight={700}>
                                agrégalo aquí
                            </Typography>
                        </Link>
                    </Typography>
                    <Autocomplete
                        id="disbursement-vendor"
                        name="supplierName"
                        options={suppliers}
                        getOptionLabel={(option: any) =>
                            `${option.alias} - ${option.registeredName}`
                        }
                        value={cartData[index].supplierData}
                        isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                        onChange={(_, newValue: any) => onDisbReceiverData(newValue)}
                        placeholder="Selecciona el proveedor"
                        error={errors.supplierData}
                        TextFieldProps={{
                            helperText:
                                cartData[index].supplierData?.registeredName?.length > 40
                                    ? '*Una razón social de más de 40 caracteres puede demorar la dispersión más de 24hrs'
                                    : '',
                        }}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <DefaultInput
                    label="Concepto de la transferencia"
                    id="disbursement-concept"
                    name="concept"
                    value={cartData[index].concept || ''}
                    type="text"
                    placeholder="¿Cuál es el concepto de la transferencia?"
                    onChange={onChange}
                    onBlur={onBlur}
                    error={errors.concept}
                    inputProps={{
                        maxLength: 40,
                    }}
                />
                <Collapse in={tooltips.concept}>
                    <Typography
                        variant="caption"
                        color="text.primary"
                        role="note"
                        aria-label="Concepto caracteres validos"
                    >
                        *Puede incluir números y letras pero no debe contener caracteres especiales.
                    </Typography>
                </Collapse>
            </Grid>
            <Grid item xs={12}>
                <DefaultInput
                    label="Referencia de la transferencia"
                    id="disbursement-reference"
                    name="reference"
                    value={cartData[index].reference || ''}
                    type="text"
                    placeholder="Referencia de la transferencia (Máximo 7 caracteres)"
                    onChange={onChange}
                    onBlur={onBlur}
                    error={errors.reference}
                />
                <Collapse in={tooltips.reference}>
                    <Typography
                        variant="caption"
                        color="text.primary"
                        role="note"
                        aria-label="Referencia caracteres validos"
                    >
                        *Puede incluir únicamente números
                    </Typography>
                </Collapse>
            </Grid>
            <Grid item xs={12}>
                <Select
                    id="disbursement-account"
                    name="supplierAccount"
                    value={cartData[index].supplierAccount || ''}
                    onChange={onDisbReceiverBankAcct}
                    onBlur={onBlur}
                    error={errors.supplierAccount}
                    disabled={
                        selectedContract.productType === 'working-capital'
                            ? !cartData[index].supplierData
                            : false
                    }
                    label="Cuenta proveedor"
                >
                    <MenuItem value="" disabled>
                        Selecciona la cuenta de depósito
                    </MenuItem>
                    {/* TODO: Delete second optional chaining when supplier vs selectedContract race condition is fixed */}
                    {disbReceiverData?.bankAccounts?.map((account: any) => (
                        <MenuItem value={account} key={account.id}>
                            {account.swift
                                ? `${account.swift} - ${account.accountNumber} - ${account.bank}`
                                : `${account.clabe} - ${account.bank}`}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item container alignItems="center" xs={12}>
                <Grid item xs={2}>
                    <Typography
                        variant="h5"
                        fontWeight={700}
                        textAlign="center"
                        color="primary.dark"
                    >
                        {cartData[index].supplierAccount?.accountCurrency?.id}$
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Collapse in={tooltips.amount}>
                        <Typography variant="body2" color="success.main" fontWeight={700}>
                            Todavía puedes solicitar $
                            {monetaryIntegerToDecimal(
                                periodAvailableBalance - totalRequestedAmountSum,
                            )}
                        </Typography>
                    </Collapse>
                    <CurrencyInput
                        inputProps={{ maxLength: 13 }}
                        label="Monto de la transferencia"
                        id="disbursement-amount"
                        name="amount"
                        placeholder="0.00"
                        value={cartData[index].amount || ''}
                        onChange={onChange}
                        error={errors.amount}
                        onBlur={onBlur}
                        thousandSeparator
                        disabled={!cartData[index].supplierAccount}
                        decimalScale={2}
                        allowNegative={false}
                        startAdornment={null}
                        sx={{
                            '.MuiInputBase-input': {
                                textAlign: 'right',
                                fontSize: 23,
                                padding: '10px 20px',
                            },
                        }}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    variant="body2"
                    color="success.main"
                    fontWeight={700}
                    component="label"
                    htmlFor="transfer-support-file"
                    display="block"
                >
                    Adjunta el soporte de la transferencia (factura, orden de compra, etc)
                </Typography>
                <FilePicker
                    id="transfer-support-file"
                    onFileChange={onFileChange}
                    fileName={cartData[index].file?.name || cartData[index].fileName}
                    accept={'image/*,.pdf'}
                    paperVariant="dashed"
                    variant="compact"
                    aria-describedby="transfer-support-file-error"
                />
                {/* TODO: Unify file and fileName variables into one */}
                {!!errors.file && (
                    <Typography
                        variant="caption"
                        color="error.main"
                        id="transfer-support-file-error"
                    >
                        {errors.file}
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
};

export default DisbursementForm;
