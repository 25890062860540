import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import { DefaultBtn, Typography } from '@fairplay2/ui';
import { CartSvg } from 'assets/SVGs';

const EmptyState: FC = () => {
    const { push } = useHistory();
    return (
        <Grid container justifyContent="center" ml={4} mt="167px">
            <Grid container justifyContent="center" maxWidth="579px !important">
                <Grid item xs={4} sm={2}>
                    <CartSvg />
                </Grid>
                <Grid item xs={8} sm={10} pl={{ xs: 0, sm: 4 }}>
                    <Typography variant="h4" color="primary.main" mb="15px">
                        Nada por aquí
                    </Typography>
                    <Typography
                        variant="body1"
                        fontSize="16px"
                        fontWeight={500}
                        color="text.primary"
                        maxWidth="465px"
                        mb="30px"
                    >
                        No hemos encontrado datos que podamos obtener de tus canales de venta. Para
                        comenzar, conecta una tienda.
                    </Typography>
                    <DefaultBtn onClick={() => push('/app/sources')}>Conectar tienda</DefaultBtn>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EmptyState;
