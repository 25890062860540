import { Reducer } from 'react';
import { HybridS3WizardModalAction, HybridS3WizardModalState } from '../interfaces';
import { ConnectorData } from '../../interfaces';

export const wizardModalReducer: Reducer<HybridS3WizardModalState, HybridS3WizardModalAction> = (
    prev,
    action,
) => {
    switch (action.type) {
        case 'CLOSE_WIZARD':
            return {
                ...prev,
                showWizard: false,
            };
        case 'OPEN_PICKER':
            return {
                showWizard: true,
                platform: undefined,
                reauthTarget: null,
            };
        case 'OPEN_S3':
            return {
                showWizard: true,
                platform: 's3',
                reauthTarget: null,
            };
        case 'OPEN_MANUAL_UPLOAD':
            return {
                showWizard: true,
                platform: 'manual-upload',
                reauthTarget: null,
            };
        case 'CHANGE_PLATFORM':
            return { ...prev, platform: action.payload };
        case 'REAUTH_S3':
            return {
                showWizard: true,
                platform: 's3',
                reauthTarget: action.payload,
            };
        default:
            return prev;
    }
};

export const getActivePlatform = (groupedConnectors?: ConnectorData[]) =>
    groupedConnectors?.reduce?.<undefined | 's3connector' | 's3-manual-upload'>(
        (currentChoice, item) => {
            if (currentChoice === 's3connector') return currentChoice;
            return item.platform as 's3connector' | 's3-manual-upload';
        },
        undefined,
    );
