import { DashboardNavItemType } from '@fairplay2/ui';
import {
    AssessmentOutlined,
    Language,
    AccountBalanceWalletOutlined,
    StoreOutlined,
    MonetizationOnOutlined,
    ReceiptLongOutlined,
    // HomeOutlined,
} from '@mui/icons-material';

const itemsV1: DashboardNavItemType[] = [
    // {
    //     url: '/app/sources',
    //     icon: <HomeOutlined />,
    //     title: 'Inicio',
    // },
    {
        url: '/dashboard',
        icon: <AssessmentOutlined />,
        title: 'Analíticos',
        children: [
            {
                title: 'KPIs',
                url: 'kpis',
                meta: {
                    gtmTag: 'analytics-kpis',
                },
            },
            {
                title: 'Desempeño comercial',
                url: 'trading',
                meta: {
                    gtmTag: 'analytics-business-performance-transactions',
                },
            },
            // The marketing module will be temporarily disabled
            // {
            //     title: 'Marketing digital',
            //     url: 'marketing',
            //     meta: {
            //         gtmTag: 'analytics-digital-marketing-advertising',
            //     },
            // },
        ],
    },
    {
        url: '/app/sources',
        icon: <Language />,
        title: 'Fuentes de datos',
    },
    {
        url: '/app/fiscal',
        icon: <ReceiptLongOutlined />,
        title: 'Gestión fiscal',
    },
];

const itemsV2: DashboardNavItemType[] = [
    {
        url: '/app/company',
        icon: <StoreOutlined />,
        title: 'Empresa',
    },
];

const defaultItemsV2Disb: DashboardNavItemType[] = [
    {
        url: '/app/disbursements',
        icon: <MonetizationOnOutlined />,
        title: 'Dispersiones',
    },
];

const itemsV2Finance: DashboardNavItemType[] = [
    {
        url: '/app/financing',
        icon: <AccountBalanceWalletOutlined />,
        title: 'Financiamiento',
        children: [
            {
                title: 'Contratos',
                url: 'contract',
            },
            {
                title: 'Proveedores',
                url: 'vendors',
            },
        ],
    },
];

export const getAvailableMenuItems = (isContractsEnabled: boolean, isCardsEnabled: boolean) => {
    const menuItems = [...itemsV1, ...itemsV2],
        itemsV2Disb = { ...defaultItemsV2Disb[0] },
        v2DisbChildren = [
            {
                title: 'Transacciones',
                url: 'transactions',
            },
        ],
        cardsModule = {
            title: 'Tarjetas',
            url: 'cards',
        };

    if (isCardsEnabled) v2DisbChildren.push(cardsModule);
    if (isContractsEnabled) {
        itemsV2Disb.children = v2DisbChildren;
        menuItems.splice(3, 0, itemsV2Disb, itemsV2Finance[0]);
    }

    return menuItems;
};
