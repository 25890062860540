import { FC, useState, useContext, useEffect, ChangeEvent, MouseEvent } from 'react';
import { Grid, TableRow, TableCell, useMediaQuery, useTheme } from '@mui/material';
import { Table } from 'common-components';
import { Checkbox, DefaultBtn, Typography, Loader } from '@fairplay2/ui';
import SessionContext from 'context/session/sessionContext';
import { useDisbursementValuesContext } from 'context/disbursements/DisbursementValuesContext';
import fairplayAPI from 'utils/api';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { Form, CartData } from '../interfaces';
import CreateConfirmationModal from './modals/CreateConfirmationModal';
import DisbursementConfirmationTableItem from './DisbursementConfirmationTableItem';

const hasConfirmedDisbursements = (cartData: CartData[]) =>
    cartData.some((item: CartData) => !!item.confirmed);

const DisbursementConfirmationTable: FC = () => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        { selectedCompany, user } = useContext(SessionContext),
        {
            cartData,
            setCartData,
            selectedContract,
            disbursementLocation,
            disbursementAlert,
            setDisbursementAlert,
        } = useDisbursementValuesContext(),
        [page, setPage] = useState(0),
        [rowsPerPage, setRowsPerPage] = useState(5),
        [loading, setLoading] = useState({ table: false, checkAll: false }),
        [disbursements, setDisbursements] = useState<CartData[]>([]),
        [count, setCount] = useState(0),
        [checkAll, setCheckAll] = useState(true),
        [openModal, setOpenModal] = useState(false),
        [requestParams, setRequestParams] = useState('');

    const onPageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        document.body.style.overflow = 'auto';
    };

    const fetchData = async () => {
        try {
            if (requestParams) {
                setLoading((prev) => ({
                    ...prev,
                    table: true,
                }));
                const res: any = await fairplayAPI.get(
                    `/v1/cart/${user?.id}/items${requestParams}`,
                    {
                        baseService: 'DISBURSEMENT_CART',
                    },
                );
                const resCount = res.data.count,
                    resArr = res.data.results.items,
                    newCount = resCount || resArr.length;
                setDisbursements(resArr);
                setCount(newCount);
            }
        } catch (errResponse: ErrorObj | any) {
            setDisbursementAlert({
                msg:
                    errResponse?.error ||
                    'Ocurrió un error recuperando las dispersiones, intente más tarde',
                severity: 'error',
            });
        } finally {
            setLoading((prev) => ({
                ...prev,
                table: false,
            }));
        }
    };

    const toggleAllConfirmations = async () => {
        setLoading((prev) => ({
            ...prev,
            checkAll: true,
        }));
        setDisbursementAlert({ msg: '', severity: 'success' });

        try {
            const newConfirmed = !checkAll;
            await fairplayAPI.put(
                `/v1/cart/${user?.id}/items?confirmed=${newConfirmed}`,
                {},
                {
                    baseService: 'DISBURSEMENT_CART',
                },
            );

            setCheckAll(newConfirmed);
            // Toggle individual cart item confirmations
            setCartData(
                cartData.map((cartItem: Form) => {
                    if (cartItem.confirmed !== newConfirmed)
                        return {
                            ...cartItem,
                            confirmed: newConfirmed,
                        };
                    return cartItem;
                }),
            );
        } catch (errResponse: ErrorObj | any) {
            setDisbursementAlert({
                msg:
                    errResponse?.error ||
                    'Ocurrió un error confirmando la dispersión, intente más tarde',
                severity: 'error',
            });
        } finally {
            setLoading((prev) => ({
                ...prev,
                checkAll: false,
            }));
        }
    };

    useEffect(() => {
        if (!selectedCompany.company?.id) return;
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestParams, selectedCompany.company?.id]);

    return (
        <>
            {openModal && (
                <CreateConfirmationModal open={openModal} onClose={() => setOpenModal(false)} />
            )}

            <Typography fontWeight={700} color="primary.dark">
                Revisa y confirma las transferencias que deseas solicitar{' '}
                {selectedContract.productType === 'sales-advancement' && 'a tus cuentas'}
            </Typography>
            <Grid item container xs={12} justifyContent="center">
                <Table
                    // eslint-disable-next-line react/no-unstable-nested-components
                    getTableItem={(disbursement: any, index: number) => (
                        <DisbursementConfirmationTableItem
                            key={disbursement?.pk}
                            index={page > 0 ? index + page * 5 : index}
                            disbursement={disbursement}
                            loadingCheckAll={loading.checkAll}
                        />
                    )}
                    currentItems={disbursements}
                    columns={7}
                    loading={loading.table}
                    pagination={{
                        count,
                        page,
                        rowsPerPage,
                        onPageChange,
                        onRowsPerPageChange: onChangeRowsPerPage,
                    }}
                    emptyItemsMessage="No hemos encontrado dispersiones agregadas"
                    onParamsQueryChange={setRequestParams}
                >
                    <TableRow>
                        <TableCell>
                            <Typography component="span" color="text.primary">
                                Pagarse el
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary">
                                {selectedContract.productType === 'working-capital'
                                    ? 'Alias /'
                                    : 'Cliente /'}{' '}
                                {disbursementLocation === 'Internacional' ? 'TAX ID' : 'RFC'}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary">Monto a dispersar</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary">Cuenta depósito</Typography>
                        </TableCell>
                        {disbursementLocation === 'Internacional' && (
                            <TableCell>
                                <Typography color="text.primary">País/Cd cuenta</Typography>
                            </TableCell>
                        )}
                        <TableCell>
                            <Grid container wrap="nowrap" alignItems="center">
                                <Typography component="span" color="text.primary" mr={2}>
                                    Confirmar
                                </Typography>
                                {loading.checkAll ? (
                                    <Grid container width={60}>
                                        <Loader size={20} data-testid="loader-disb-cart-item" />
                                    </Grid>
                                ) : (
                                    <Checkbox
                                        onChange={toggleAllConfirmations}
                                        checked={checkAll}
                                    />
                                )}
                            </Grid>
                        </TableCell>
                    </TableRow>
                </Table>
            </Grid>

            <Grid item container xs={12} mt={3} justifyContent="flex-end">
                <DefaultBtn
                    fullWidth={smDown}
                    disabled={!!disbursementAlert.msg || !hasConfirmedDisbursements(cartData)}
                    onClick={() => setOpenModal(true)}
                >
                    Solicitar
                </DefaultBtn>
            </Grid>
        </>
    );
};

export default DisbursementConfirmationTable;
