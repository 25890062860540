import { FC, useEffect, useState } from 'react';
import { SelectRangeEventHandler } from 'react-day-picker';
import { Typography, DefaultBtn } from '@fairplay2/ui';
import { DatePicker, getBoundaryYears } from '@fairplay2/ui-x-dates';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { ManSittingLaptopSvg } from 'assets/SVGs';
import { getMonthRangeData, mimicDateAtCDMX } from 'utils/dates';
import { FiltersProps, Dates } from '../interfaces';

const initSelectedDayRange = () => ({
    init: getMonthRangeData(1),
    end: getMonthRangeData(2),
});

const Filters: FC<FiltersProps> = ({ applyFilters, forceApplyOn }) => {
    const theme = useTheme(),
        mdUp = useMediaQuery(theme.breakpoints.up('md')),
        [selectedDayRange, setSelectedDayRange] = useState<Dates>(initSelectedDayRange),
        [PickerProps] = useState(() => getBoundaryYears(mimicDateAtCDMX()));

    const onCalendarChange =
        (prop: keyof Dates): SelectRangeEventHandler =>
        (range) => {
            setSelectedDayRange({ ...selectedDayRange, [prop]: range });
        };

    const onClick = () => {
        applyFilters(selectedDayRange);
    };

    useEffect(() => {
        if (forceApplyOn === undefined) return;
        onClick();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceApplyOn]);

    return (
        <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            my={{ xs: '20px', md: 0 }}
        >
            <Grid item xs={12} md={3}>
                <DatePicker
                    labelVariant="contained"
                    label="Desde - Hasta"
                    mode="range"
                    placeholder="Selecciona un rango"
                    displayIcon
                    value={selectedDayRange.init}
                    onChange={onCalendarChange('init')}
                    PickerProps={PickerProps}
                />
            </Grid>

            <Grid container item xs={12} md={5} justifyContent="space-between" alignItems="center">
                <Grid item xs={12} md={4} sx={{ textAlign: 'center', my: { xs: 0, md: '20px' } }}>
                    <Typography variant="subtitle2" color="primary.main" mx="10px">
                        Comparar con
                    </Typography>
                </Grid>

                <Grid item xs>
                    <DatePicker
                        labelVariant="contained"
                        label="Desde - Hasta"
                        mode="range"
                        placeholder="Selecciona un rango"
                        displayIcon
                        value={selectedDayRange.end}
                        onChange={onCalendarChange('end')}
                        PickerProps={PickerProps}
                    />
                </Grid>
            </Grid>

            <Grid item xs textAlign="center" mt={{ xs: '20px', md: 0 }}>
                <DefaultBtn
                    onClick={onClick}
                    size="small"
                    variant="contained"
                    disabled={
                        !selectedDayRange.init.from ||
                        !selectedDayRange.init.to ||
                        !selectedDayRange.end.from ||
                        !selectedDayRange.end.to
                    }
                >
                    Filtrar
                </DefaultBtn>
            </Grid>

            {mdUp && (
                <Grid item xs={1} sx={{ textAlign: 'center' }}>
                    <ManSittingLaptopSvg style={{ maxWidth: '65px' }} />
                </Grid>
            )}
        </Grid>
    );
};

export default Filters;
