import { styled } from '@mui/material/styles';
// eslint-disable-next-line import/extensions
import { Status } from 'common-components/status/Status.component';
import { StatusProps } from 'common-components/status/Status.types';

export const StyledStatus = styled(Status, {
    shouldForwardProp: (prop) => prop !== 'shouldReload',
})<StatusProps & { shouldReload?: boolean }>(({ shouldReload }) => ({
    '& .MuiAlert-message': {
        maxWidth: shouldReload ? '182px' : '164px',
        whiteSpace: 'normal',
        fontSize: '12px',
    },
}));
