import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { handleError } from 'utils/error-handler';
import { getIdempotency } from './idempotency';
import { queryClient } from './queryClient';
import { FairplayAxiosInstance, FairplayRequestConfig, PrivateRequestConfig } from './interfaces';

const REFRESH_URL = `${process.env.REACT_APP_API_URL}/v1/users/token/refresh`,
    ACCESS_TOKEN_KEY = 'FPstorage-Token',
    REFRESH_TOKEN_KEY = 'FPstorage-Refresh',
    COMMON_DEFAULTS = { headers: { Accept: 'application/json' } },
    fairplayAPI = axios.create(COMMON_DEFAULTS) as FairplayAxiosInstance,
    // Different instance to avoid appending other interceptors
    //  and handle redirection to login page
    refreshAPI = axios.create(COMMON_DEFAULTS);

fairplayAPI.defaults.headers.post['Content-Type'] = 'application/json';
refreshAPI.defaults.headers.post['Content-Type'] = 'application/json';

type TokenKey = typeof ACCESS_TOKEN_KEY | typeof REFRESH_TOKEN_KEY;

const getToken = (key: TokenKey) => sessionStorage.getItem(key);

const setToken = (key: TokenKey, value: string) => sessionStorage.setItem(key, value);

export const setSession = ({ access, refresh }: { access: string; refresh: string }) => {
    setToken(ACCESS_TOKEN_KEY, access);
    setToken(REFRESH_TOKEN_KEY, refresh);
};

export const hasActiveSession = () => {
    const accessToken = getToken(ACCESS_TOKEN_KEY);
    return accessToken && accessToken !== 'null' && accessToken !== 'undefined';
};

export const endSession = (useNativeRedirect = false) => {
    window.localStorage.clear();
    localStorage.clear();
    sessionStorage.clear();
    // Remove all queries to avoid showing stale data
    queryClient.removeQueries();
    // TODO: Display a message in login explaining why the session was ended
    if (useNativeRedirect) window.location.assign('/');
};

const refreshAuthLogic = async () => {
    try {
        const tokenRefreshResponse: any = await refreshAPI.post(REFRESH_URL, {
            refresh: getToken(REFRESH_TOKEN_KEY),
        });
        const token = tokenRefreshResponse.data.access;
        setToken(ACCESS_TOKEN_KEY, token);

        return await Promise.resolve();
    } catch (errResponse) {
        return Promise.reject(errResponse);
    }
};

const onFailedRefresh = () => {
    endSession(true);
    return Promise.resolve();
};

createAuthRefreshInterceptor(fairplayAPI as FairplayAxiosInstance, refreshAuthLogic, {
    shouldRefresh(error) {
        // Ignore requests that directly specified no authorization
        //  and those that provide a custom token, as there is no guarantee
        //  that said token can or should be refreshed
        return (
            error.response?.status === 401 &&
            (error.config as FairplayRequestConfig).authorization === true
        );
    },
});

createAuthRefreshInterceptor(refreshAPI, onFailedRefresh, {
    statusCodes: [401, 422, 403],
});

fairplayAPI.interceptors.request.use(
    ({
        authorization: originalAuthorization = true,
        useIdempotency: originalUseIdempotency = false,
        baseURL: originalBaseURL,
        baseService,
        isOriginalRequest = true,
        ...config
    }: PrivateRequestConfig) => {
        // eslint-disable-next-line no-param-reassign
        if (!config.headers) config.headers = {};

        // Prevents idempotency and authorization to get recalculated
        //   in retried requests if custom headers were originally provided
        const useIdempotency =
                isOriginalRequest && config.headers['Idempotency-Key']
                    ? false
                    : originalUseIdempotency,
            authorization =
                isOriginalRequest && config.headers.Authorization
                    ? config.headers.Authorization
                    : originalAuthorization;

        let baseURL = originalBaseURL;

        if (!baseURL && isOriginalRequest) {
            // Not assigning baseURL directly in case that, due to some dev
            //  error, baseService gets an invalid value, or in case
            //  new endpoints that don't follow this naming pattern are added
            //  to env variables
            switch (baseService) {
                case 'BANK_INFORMATION':
                case 'DISBURSEMENT_CART':
                case 'PERIODS_LOANBOOK':
                case 'AVAILABLE_LOANBOOK':
                case 'PAYMENT_ORDERS':
                case 'CARDS':
                    baseURL = process.env[`REACT_APP_${baseService}_URL`];
                    break;
                default:
                    baseURL = process.env.REACT_APP_API_URL;
                    break;
            }
        }

        if (authorization)
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization =
                typeof authorization === 'string'
                    ? authorization
                    : `Bearer ${getToken(ACCESS_TOKEN_KEY)}`;

        if (useIdempotency && config.data)
            // eslint-disable-next-line no-param-reassign
            config.headers['Idempotency-Key'] = `${getIdempotency(
                // When reattempting a request that uses a plain object instead of FormData,
                //  axios has already transformed that object to a string, so we need to
                //  parse it to recalculate idempotency
                typeof config.data === 'string' ? JSON.parse(config.data) : config.data,
            )}`;

        return {
            ...config,
            isOriginalRequest: false,
            authorization,
            baseURL,
            useIdempotency,
        };
    },
);

fairplayAPI.interceptors.response.use(undefined, (error) => {
    // For some reason, we are getting back an already formatted error
    // Making sure the error is the original one provided by axios
    //  TODO: Make a safer runtime check by using a custom Error class
    //    instead of plain objects
    const formattedError = error.config ? handleError(error) : error;
    if (formattedError.status === 429) endSession(true);
    return Promise.reject(formattedError);
});

export const simulateBackendRequest = (response: any, shouldFail?: boolean) =>
    new Promise((resolve, reject) => {
        const shouldFailValue = shouldFail ?? Math.random() < 0.5;

        setTimeout(() => {
            if (shouldFailValue) reject(new Error('Error en la solicitud al backend'));
            else resolve(response);
        }, 1000);
    });

export { REFRESH_URL, queryClient };

export default fairplayAPI;
