import { FC, useState, useEffect, useContext, useReducer } from 'react';
import { Grid, useTheme, useMediaQuery, Collapse } from '@mui/material';
import { DatePicker } from '@fairplay2/ui-x-dates';
import { Typography, DefaultBtn, Radio, Alert, Counter, InterpunctLoader } from '@fairplay2/ui';
import { useDisbursementValuesContext } from 'context/disbursements/DisbursementValuesContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import SessionContext from 'context/session/sessionContext';
import fairplayAPI from 'utils/api';
import { localFormat, TIMELESS_ISO_FORMAT } from 'utils/dates';
import { monetaryIntegerToDecimal } from 'utils/currency';
import { getMinDisbursementDate } from '../utils';

const DisbursementConfiguration: FC = () => {
    const theme = useTheme(),
        mdDown = useMediaQuery(theme.breakpoints.down('md')),
        { selectedCompany, user, errors } = useContext(SessionContext),
        {
            disbursementPeriod,
            preSelectedDisbursementDate,
            setPreselectedDate,
            disabledDisbursementDays,
            minDisbursementDate,
            maxDisbursementDate,
            disbursementAlert,
            setDisbursementConfiguration,
            periodAvailableBalance,
            selectedContract,
            setDisbursementAlert,
            setDisbursementStep,
            disbursementLocation,
            getSelectedCompanyBank,
            getSuppliers,
        } = useDisbursementValuesContext(),
        [loading, setLoading] = useState(false),
        [isDatePreselected, toggleDatePreselected] = useReducer(
            (currentValue: boolean) => !currentValue,
            true,
        ),
        [disbursementCount, setDisbursementCount] = useState(1),
        [currentPeriodMinDisbDate] = useState(() => getMinDisbursementDate()),
        minDisbDate =
            disbursementPeriod === 'current' ? currentPeriodMinDisbDate : minDisbursementDate,
        alert = errors.messages.holidays
            ? { open: true, message: errors.messages.holidays, type: 'error' as 'error' }
            : { open: false, message: '', type: 'error' as 'error' };

    const onDisbursementCreation = async () => {
        setLoading(true);
        if (disbursementAlert.msg)
            setDisbursementAlert({
                msg: '',
                severity: 'success',
            });
        const data = {
            contract_id: selectedContract.id,
            national: disbursementLocation === 'Nacional',
            company_id: selectedCompany.company?.id,
            item_type: 'dispersion',
            payment_type: 'fixed-payment',
            available_for_disposition: parseFloat(
                monetaryIntegerToDecimal(periodAvailableBalance, false),
            ),
            total_amount: 0,
            total_items: disbursementCount,
            metadata_cart: {},
            period: disbursementPeriod === 'current' ? 1 : 2,
            min_disbursement_date: localFormat(minDisbursementDate || '', TIMELESS_ISO_FORMAT, {
                fallbackString: '',
            }),
            max_disbursement_date: localFormat(maxDisbursementDate || '', TIMELESS_ISO_FORMAT, {
                fallbackString: '',
            }),
            pre_selected_disbursement_date: localFormat(
                preSelectedDisbursementDate || '',
                TIMELESS_ISO_FORMAT,
                { fallbackString: '' },
            ),
        };
        try {
            await fairplayAPI.post(`/v1/cart/${user?.id}`, data, {
                baseService: 'DISBURSEMENT_CART',
            });
            setDisbursementConfiguration(
                disbursementCount,
                isDatePreselected,
                selectedCompany.company?.id,
            );
            setDisbursementStep('forward');
        } catch (errResponse: ErrorObj | any) {
            setDisbursementAlert({
                msg:
                    errResponse?.error ||
                    'Ocurrió un error creando las dispersiones, intente más tarde',
                severity: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setPreselectedDate(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disbursementPeriod]);

    useEffect(() => {
        // TODO: Fix supplier vs selectedContract race condition
        if (!selectedContract) return;

        if (selectedContract.productType === 'sales-advancement') getSelectedCompanyBank();
        else getSuppliers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedContract.id, selectedContract.productType]);

    return (
        <>
            <Collapse in={alert.open} sx={{ mt: '20px', width: '100%' }}>
                <Grid container paddingBottom="20px">
                    <Grid item xs={12} md={6}>
                        <Alert severity={alert.type}>{alert.message}</Alert>
                    </Grid>
                </Grid>
            </Collapse>

            <Grid item container xs={12} mt={{ xs: '25px', md: '20px' }}>
                <Grid item container rowSpacing={2} mb={4}>
                    <Typography
                        variant="subtitle2"
                        color={errors.messages.holidays ? 'text.disabled' : 'primary.main'}
                        pt={0}
                    >
                        ¿Cuántas dispersiones necesitas?
                    </Typography>
                    <Grid item xs={12}>
                        <Counter
                            value={disbursementCount}
                            min={1}
                            onValueUpdate={setDisbursementCount}
                            aria-label="número de dispersiones"
                            disabled={!!disbursementAlert.msg || !!errors.messages.holidays}
                            width="250px"
                            height="50px"
                        />
                    </Grid>

                    <Collapse in={disbursementCount > 1} data-testid="disbursement-date-selectors">
                        <Typography variant="subtitle2" color="primary.main" mt="40px" mb="20px">
                            ¿Cuándo quieres que se realicen las dispersiones?
                        </Typography>
                        <Grid item container alignItems="center" xs={12}>
                            <Radio
                                id="date-per-disbursement"
                                checked={!isDatePreselected}
                                onClick={toggleDatePreselected}
                                sx={{ padding: '0 10px 0 0' }}
                                disabled={!!disbursementAlert.msg}
                            />
                            <Typography
                                component="label"
                                htmlFor="date-per-disbursement"
                                variant="subtitle2"
                                color={!isDatePreselected ? 'primary.main' : 'text.primary'}
                                fontWeight={!isDatePreselected ? 700 : 500}
                            >
                                Elegir fecha por cada dispersión
                            </Typography>
                            <Grid item xs={12} md={6}>
                                <Collapse in={!isDatePreselected}>
                                    <Grid
                                        role="note"
                                        aria-label="Fecha por dispersión"
                                        container
                                        alignItems="center"
                                        wrap="nowrap"
                                        margin={{ xs: '15px 0', md: '0 40px' }}
                                        minHeight="20px"
                                    >
                                        <Alert
                                            role="presentation"
                                            variant="filled"
                                            severity="warning"
                                            sx={{
                                                width: 'fit-content',
                                                '.MuiAlert-icon': {
                                                    mr: '0px',
                                                    color: 'warning.contrastText',
                                                },
                                            }}
                                        />
                                        <Typography
                                            variant="body1"
                                            color="warning.main"
                                            fontWeight={700}
                                            ml={1}
                                        >
                                            Recuerda que todas las fechas deberán estar comprendidas
                                            dentro del mismo periodo de dispersión
                                        </Typography>
                                    </Grid>
                                </Collapse>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems="center" xs={12}>
                            <Radio
                                id="date-for-all-disbursements"
                                checked={isDatePreselected}
                                onClick={toggleDatePreselected}
                                sx={{ padding: '0 10px 0 0' }}
                                disabled={!!disbursementAlert.msg}
                            />
                            <Typography
                                component="label"
                                htmlFor="date-for-all-disbursements"
                                variant="subtitle2"
                                color={isDatePreselected ? 'primary.main' : 'text.primary'}
                                fontWeight={isDatePreselected ? 700 : 500}
                            >
                                Todas las dispersiones el mismo día
                            </Typography>
                            <Grid item xs={12} md={6}>
                                <Collapse in={isDatePreselected}>
                                    <Grid
                                        role="note"
                                        aria-label="Fecha para todas las dispersiones"
                                        container
                                        alignItems="center"
                                        wrap="nowrap"
                                        margin={{ xs: '15px 0', md: '0 40px' }}
                                        minHeight="20px"
                                    >
                                        <Alert
                                            role="presentation"
                                            variant="filled"
                                            severity="warning"
                                            sx={{
                                                width: 'fit-content',
                                                '.MuiAlert-icon': {
                                                    mr: '0px',
                                                    color: 'warning.contrastText',
                                                },
                                            }}
                                        />
                                        <Typography
                                            variant="body1"
                                            color="warning.main"
                                            fontWeight={700}
                                            ml={2}
                                        >
                                            Solicita dispersiones dentro de las fechas del periodo
                                            actual o programa dispersiones para el siguiente periodo
                                        </Typography>
                                    </Grid>
                                </Collapse>
                            </Grid>
                            <Grid item xs={8} md={5} lg={4} mt="10px">
                                <Collapse in={isDatePreselected}>
                                    <DatePicker
                                        label="Selecciona la fecha"
                                        mode="single"
                                        placeholder="Selecciona la fecha"
                                        value={preSelectedDisbursementDate}
                                        onChange={setPreselectedDate}
                                        inputAlignment="start"
                                        disabled={!!disbursementAlert.msg}
                                        disabledDays={disabledDisbursementDays}
                                        displayIcon
                                        PickerProps={{
                                            fromDate: minDisbDate,
                                            toDate: maxDisbursementDate,
                                            captionLayout: 'buttons',
                                        }}
                                    />
                                </Collapse>
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>

                {!!periodAvailableBalance && (
                    <Grid item container justifyContent={{ xs: 'center', md: 'flex-end' }} xs={12}>
                        {loading ? (
                            <InterpunctLoader data-testid="interpunct-loader-disbursement-configuration" />
                        ) : (
                            <DefaultBtn
                                fullWidth={mdDown}
                                disabled={!!disbursementAlert.msg || !!errors.messages.holidays}
                                onClick={onDisbursementCreation}
                            >
                                Usar saldo periodo{' '}
                                {disbursementPeriod === 'current' ? 'actual' : 'siguiente'}
                            </DefaultBtn>
                        )}
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default DisbursementConfiguration;
