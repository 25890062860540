import { ChangeEvent, FC, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { DefaultBtn, DefaultInput, InterpunctLoader, Typography } from '@fairplay2/ui';
import { isEnglishWords } from 'utils/validation';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj, setFormErrors } from 'utils/error-handler';
import fairplayAPI from 'utils/api';
import { CurrencyInput } from '@fairplay2/ui-x-currencies';
import { formatAmount } from 'utils/parsing';
import { CardFields, NewCardFormProps } from '../../interfaces';
import { FairplayCard } from '../FairplayCard';
import ConfirmRequestCardModal from './ConfirmRequestCardModal';

const validateCredit = (amount: number, maxForDisposition: number) => {
    if (amount === 0 || amount <= maxForDisposition) {
        return true;
    }
    return `Estás eligiendo un monto superior al máximo del periodo. Elige un monto menor a ${formatAmount(
        maxForDisposition,
    )}`;
};

const NewCardForm: FC<NewCardFormProps> = ({
    onComplete,
    showAlert,
    selectedContractId,
    maxForDisposition,
}) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        {
            handleSubmit,
            formState: { errors, isValid },
            watch,
            control,
            setError,
        } = useForm<CardFields>({
            mode: 'onChange',
        }),
        [alias, creditLimit] = watch(['alias', 'creditLimit']),
        [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false),
        [loading, setLoading] = useState(false),
        [isAdmin, setIsAdmin] = useState(false);

    const onSubmit: SubmitHandler<CardFields> = async ({
        alias: newAlias,
        creditLimit: newCreditLimit,
    }) => {
        if (!isValid) return;

        const data = {
            contract_id: selectedContractId,
            request_type: 'create-card',
            alias: newAlias,
            credit_limit: newCreditLimit,
            card_type: 'VIRTUAL',
        };

        setLoading(true);

        try {
            const res: any = await fairplayAPI.post('/cards/v1/card/create-request', data, {
                baseService: 'CARDS',
            });
            setIsConfirmModalOpen(true);
            setIsAdmin(res.data?.body.results.status_request.id === 'creation_approved');
        } catch (errResponse: ErrorObj | any) {
            if (errResponse?.type === 'form')
                setFormErrors<CardFields>(errResponse?.error, setError);
            else
                showAlert(
                    errResponse?.error || 'No se pudo solicitar la tarjeta, intente más tarde',
                );
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <ConfirmRequestCardModal
                open={isConfirmModalOpen}
                onClose={() => {
                    setIsConfirmModalOpen(false);
                    onComplete();
                }}
                alias={alias!}
                isAdmin={isAdmin}
            />

            <Grid container>
                <Grid item xs={12} textAlign="left">
                    <Typography variant="subtitle2" color="primary.main" mb="10px" id="create-card">
                        Proporciona los datos para solicitar tu tarjeta nueva
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        color="text.primary"
                        mb={['20px', '30px']}
                        id="create-card"
                    >
                        Monto máximo para dispersar por periodo:{' '}
                        <Typography component="span" color="primary.main" fontWeight={700}>
                            ${formatAmount(maxForDisposition)}
                        </Typography>
                        .
                    </Typography>
                </Grid>

                <Grid
                    container
                    justifyContent={['center', 'flex-start']}
                    columnGap="25px"
                    rowGap="20px"
                >
                    <Grid item sx={{ width: { xs: '300px', sm: '350px' } }}>
                        <FairplayCard
                            alias={alias}
                            creditLimit={{ amount: creditLimit! as number, currency: 'MXN' }}
                        />
                    </Grid>

                    <Grid item flexGrow={1}>
                        <Grid
                            container
                            rowSpacing="30px"
                            direction="column"
                            maxWidth={['100%', '370px']}
                            component="form"
                            onSubmit={handleSubmit(onSubmit)}
                            aria-labelledby="create-card"
                        >
                            <Grid item>
                                <Controller
                                    name="alias"
                                    control={control}
                                    rules={{
                                        required: 'Ingresa el alias',
                                    }}
                                    render={({
                                        field: { onChange, onBlur, value: prevValue, ref, name },
                                    }) => (
                                        <DefaultInput
                                            label="Define un alias para tu tarjeta:"
                                            labelVariant="external"
                                            name={name}
                                            type="text"
                                            placeholder="Ej. Pagos Google"
                                            error={errors.alias?.message}
                                            inputProps={{ maxLength: 22 }}
                                            onChange={({
                                                target: { value },
                                            }: ChangeEvent<HTMLInputElement>) =>
                                                onChange(isEnglishWords(value) ? value : prevValue)
                                            }
                                            onBlur={onBlur}
                                            value={prevValue || ''}
                                            inputRef={ref}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item>
                                <Typography
                                    variant="label"
                                    component="label"
                                    htmlFor="limit-amount"
                                >
                                    Establece el monto límite de la tarjeta:
                                </Typography>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    alignItems="center"
                                    columnGap="16px"
                                    flexWrap="nowrap"
                                >
                                    <Grid item width="auto">
                                        <Typography
                                            variant="h5"
                                            fontWeight={700}
                                            textAlign="left"
                                            color="primary.dark"
                                            mt={errors.creditLimit ? '-19px' : 0}
                                        >
                                            MXN$
                                        </Typography>
                                    </Grid>

                                    <Grid item flexGrow={1}>
                                        <Controller
                                            name="creditLimit"
                                            control={control}
                                            rules={{
                                                required: 'Ingresa el monto límite',
                                                validate: (newCreditLimit) =>
                                                    validateCredit(
                                                        newCreditLimit,
                                                        maxForDisposition,
                                                    ),
                                            }}
                                            render={({
                                                field: { onChange, onBlur, value, ref, name },
                                            }) => (
                                                <CurrencyInput
                                                    labelVariant="none"
                                                    id="limit-amount"
                                                    name={name}
                                                    placeholder="Ej. 50,000"
                                                    value={value}
                                                    inputProps={{ maxLength: 13 }}
                                                    onValueChange={(values: any) =>
                                                        onChange(values.floatValue)
                                                    }
                                                    error={errors.creditLimit?.message}
                                                    onBlur={onBlur}
                                                    decimalScale={2}
                                                    allowNegative={false}
                                                    startAdornment={null}
                                                    inputRef={ref}
                                                    sx={{
                                                        '.MuiInputBase-input': {
                                                            textAlign: 'right',
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item container justifyContent="flex-end">
                                {loading ? (
                                    <InterpunctLoader />
                                ) : (
                                    <DefaultBtn
                                        fullWidth={smDown}
                                        size="small"
                                        type="submit"
                                        disabled={!isValid}
                                    >
                                        Solicitar
                                    </DefaultBtn>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default NewCardForm;
