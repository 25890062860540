import { FC, useState } from 'react';
import { Typography, IconBtn } from '@fairplay2/ui';
import { Table } from 'common-components';
import { Grid, TableRow } from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material/';
import { StyledCell, StyledMediumCell, StyledSmallCell } from '../../styles';
import InvoiceDetailTableItem from './InvoiceDetailTableItem';

const InvoiceDetailTable: FC<any> = ({ conceptDetail }) => {
    const [conceptDetailRows, setConceptDetailRows] = useState<any[]>(
        conceptDetail?.length > 1 ? conceptDetail?.slice(0, 1) : conceptDetail,
    );

    const collapseTable = () => {
        setConceptDetailRows((prev) =>
            prev?.length > 1 ? conceptDetail?.slice(0, 1) : conceptDetail,
        );
    };

    return (
        <>
            <Typography variant="subtitle1" color="primary.main" fontWeight={500}>
                Conceptos
            </Typography>
            <Grid item container xs={12} justifyContent="center">
                <Table
                    sx={{
                        boxShadow: 'none',
                    }}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    getTableItem={(detailRows: any, i: number) => (
                        <InvoiceDetailTableItem conceptDetailRows={detailRows} key={i} />
                    )}
                    currentItems={conceptDetailRows}
                    columns={5}
                    emptyItemsMessage="No hemos encontrado conceptos disponibles"
                >
                    <TableRow>
                        <StyledSmallCell>
                            <Typography color="text.primary">Cantidad</Typography>
                        </StyledSmallCell>
                        <StyledSmallCell>
                            <Typography color="text.primary">Unidades</Typography>
                        </StyledSmallCell>
                        <StyledCell>
                            <Typography color="text.primary">Descripción</Typography>
                        </StyledCell>
                        <StyledCell align="right">
                            <Typography color="text.primary">Valor unitario</Typography>
                        </StyledCell>
                        <StyledMediumCell align="right">
                            <Typography color="text.primary">Importe</Typography>
                        </StyledMediumCell>
                    </TableRow>
                </Table>
            </Grid>
            {conceptDetail?.length > 1 && (
                <Grid container justifyContent="flex-end" alignItems="center" marginTop={2}>
                    <Typography variant="body1" fontWeight={700} color="primary.main" mr="10px">
                        {conceptDetailRows?.length > 1 ? 'Ver menos' : 'Ver todo'}
                    </Typography>
                    <IconBtn
                        aria-label={
                            conceptDetailRows?.length > 1
                                ? 'Colapsar tabla facturas'
                                : 'Expandir tabla facturas'
                        }
                        variant="filled"
                        color="primary"
                        size="small"
                        onClick={collapseTable}
                        sx={{ p: '0 !important', svg: { fontSize: '1.5em' } }}
                    >
                        {conceptDetailRows?.length > 1 ? (
                            <KeyboardArrowUp />
                        ) : (
                            <KeyboardArrowDown />
                        )}
                    </IconBtn>
                </Grid>
            )}
        </>
    );
};

export default InvoiceDetailTable;
