import { FC, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { Public, OpenInBrowser, CheckCircle } from '@mui/icons-material';
import { TabGroup, Modal, TabData } from '@fairplay2/ui';
import { getConnectorAlias, getPlatformDisplayName } from 'components/dashboard/data-sources/utils';
import { WizardProps } from './interfaces';
import Confirmation from './Confirmation';
import Step1 from './Step1';

const TABS_DATA: TabData[] = [
    { label: '1. Ubicación', icon: <Public />, disabled: true },
    { label: '2. Autorizar', icon: <OpenInBrowser />, disabled: true, panelId: null },
    { label: '3. Confirmar', icon: <CheckCircle />, disabled: true },
];

const WizardEcommerce: FC<WizardProps> = ({ open, groupKey, onClose, element }) => {
    const [step, setStep] = useState(0);

    useEffect(() => {
        if (!open) return;
        // Show step 1 if element === null (new connector) or in reauthorize status
        if (element && element?.status !== 'reauthorize') setStep(2);
        else setStep(0);
    }, [element, open]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            maxWidth={980}
            disableBackdropClick
            title={
                element?.status === 'reauthorize' ? (
                    <>
                        Reconecta tu tienda{' '}
                        <strong>
                            {groupKey === 'mercado-libre'
                                ? element.email
                                : getConnectorAlias(element)}
                        </strong>
                    </>
                ) : (
                    `Conecta tu tienda ${getPlatformDisplayName(groupKey)}`
                )
            }
            disableEscapeKeyDown
            sx={{
                '& .MuiDialogTitle-root': { paddingBottom: 0, paddingTop: '20px' },
                '.MuiDialogContent-root': {
                    pt: 0,
                },
            }}
        >
            <Grid container>
                <TabGroup
                    tabs={TABS_DATA}
                    value={step}
                    tabDirection="column"
                    TabsProps={{
                        variant: 'fullWidth',
                    }}
                    sx={{
                        '.MuiTabs-root': {
                            m: '20px 0px 30px 0px',
                            width: '100%',
                        },
                        '.FpTabPanel-root': {
                            mb: 0,
                        },
                    }}
                >
                    <Step1 groupKey={groupKey} connector={element} />
                    <Confirmation onClose={onClose} groupKey={groupKey} />
                </TabGroup>
            </Grid>
        </Modal>
    );
};

export default WizardEcommerce;
