import { DefaultBtn, GhostGreyGraphic, Paper, ProfileData, Typography } from '@fairplay2/ui';
import { Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import { UserState } from 'context/session/interfaces';

interface UserOtpStatusProps extends Pick<UserState, 'user'> {
    onUnlinkUser: () => void;
    loading: boolean;
    disabled: boolean;
}

const UserCard = styled(Paper)(({ theme }) => ({
    padding: '25px',
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.background.dark,
    boxShadow: 'none',
    maxWidth: '540px',
}));

export const UserOtpStatus = ({ user, loading, disabled, onUnlinkUser }: UserOtpStatusProps) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <UserCard>
            {disabled && !loading ? (
                <Grid container alignItems="center" gap={2}>
                    <Grid item>
                        <GhostGreyGraphic width="95px" />
                    </Grid>
                    <Grid item xs>
                        <Typography
                            variant="subtitle2"
                            fontWeight={500}
                            color="primary.main"
                            lineHeight={1.5}
                            maxWidth={350}
                        >
                            Tu usuario no tiene ninguna aplicación de autenticación vinculada a
                            Insights.
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid container alignItems="center" gap={4}>
                    <ProfileData
                        src={loading ? undefined : ''}
                        name={loading ? undefined : ''}
                        legend={
                            loading ? undefined : (
                                <Grid container direction="column">
                                    <Grid item width="100%">
                                        <Typography
                                            variant="subtitle2"
                                            fontWeight={700}
                                            color="primary.dark"
                                        >
                                            {`${user?.firstName} ${user?.lastName}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item width="100%">
                                        <Typography
                                            variant="subtitle2"
                                            fontWeight={500}
                                            color="primary.main"
                                            ellipsis
                                        >
                                            {user?.email}
                                        </Typography>
                                    </Grid>
                                    <Grid item width="100%">
                                        <Typography variant="subtitle2" fontWeight={500} ellipsis>
                                            Administrador
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )
                        }
                        size="md"
                        GridProps={{
                            item: true,
                            xs: 12,
                            maxWidth: '355px !important',
                        }}
                    />
                    {!loading && (
                        <Grid item xs={12} sm="auto" sx={{ ml: 'auto' }}>
                            <DefaultBtn
                                variant="outlined"
                                size="small"
                                onClick={onUnlinkUser}
                                fullWidth={smDown}
                            >
                                Desvincular
                            </DefaultBtn>
                        </Grid>
                    )}
                </Grid>
            )}
        </UserCard>
    );
};

export default UserOtpStatus;
