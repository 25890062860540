import { FC, useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Collapse, Grid } from '@mui/material';
import { WarningAmberOutlined } from '@mui/icons-material';
import { Alert, DefaultBtn, InterpunctLoader, Skeleton, Typography } from '@fairplay2/ui';
import { ErrorObj } from 'utils/error-handler';
import { moveBetweenLists } from 'utils/lists';
import { useAlert } from 'utils/hooks';
import StatusTypeBox from './StatusTypeBox';
import { MappedStatusOrders, StatusOrderType, StatusesDragDropProps } from './interfaces';

const StatusesDragDrop: FC<StatusesDragDropProps> = ({
    statuses,
    onContinue,
    onCancel,
    onAdjust,
    onConfirm,
    readOnlyData,
}) => {
    const [inConfirmation, setInConfirmation] = useState(false),
        [loading, setLoading] = useState(false),
        [mappedStatuses, setMappedStatuses] = useState<MappedStatusOrders>({
            valid: [],
            invalid: [],
        }),
        { alert, hideAlert, showAlert } = useAlert(),
        isReadOnly = readOnlyData === undefined ? undefined : !!readOnlyData;

    const moveStatus = ({ destination, source }: any) => {
        if (destination === null) return;
        const sourceKey = source.droppableId.replace('-statuses', ''),
            destinationKey = destination.droppableId.replace('-statuses', ''),
            [sourceList, destinationList] = moveBetweenLists(
                mappedStatuses[sourceKey as StatusOrderType],
                source.index,
                mappedStatuses[destinationKey as StatusOrderType],
                destination.index,
            );

        setMappedStatuses((prev) => ({
            ...prev,
            [sourceKey]: sourceList,
            [destinationKey]: destinationList,
        }));
    };

    const onLocalConfirm = () => {
        if (inConfirmation) {
            setLoading(true);
            onConfirm({
                valid: mappedStatuses.valid,
                invalid: mappedStatuses.invalid,
            })
                .catch((errResponse: ErrorObj | any) =>
                    showAlert(
                        errResponse?.error ||
                            'Ocurrió un error al clasificar tus estatus, intente más tarde.',
                    ),
                )
                .finally(() => setLoading(false));
        } else {
            setInConfirmation(true);
            onContinue?.();
        }
    };

    const onLocalCancel = () => {
        if (inConfirmation) {
            setInConfirmation(false);
            onAdjust?.();
            return;
        }
        onCancel();
    };

    useEffect(() => {
        setInConfirmation(false);
        hideAlert();

        if (statuses === undefined) return;

        setMappedStatuses({
            valid: statuses?.valid || [],
            invalid: statuses?.invalid || [],
        });
    }, [hideAlert, statuses]);

    return (
        <DragDropContext onDragEnd={moveStatus}>
            <Typography
                color={inConfirmation || isReadOnly ? 'primary.dark' : 'primary.main'}
                fontWeight={700}
                mb="11px"
                mt="20px"
                lineHeight={1.11}
            >
                {/* eslint-disable-next-line no-nested-ternary */}
                {typeof readOnlyData === 'object' ? (
                    <>
                        Esta es la clasificación, fue realizada{' '}
                        <Typography color="primary.main" component="span">
                            {readOnlyData.date}
                        </Typography>
                        {!!readOnlyData.contract && (
                            <>
                                {' '}
                                y está asociada al contrato{' '}
                                <Typography color="primary.main" component="span">
                                    {readOnlyData.contract}
                                </Typography>
                            </>
                        )}
                    </>
                ) : // eslint-disable-next-line no-nested-ternary
                isReadOnly || isReadOnly === undefined ? (
                    <Skeleton
                        variant="text"
                        height={32}
                        width="100%"
                        data-testid="fss-text-skeleton"
                    />
                ) : inConfirmation ? (
                    '¡Ya casi está listo! ¿Estás seguro de que están en la columna correcta?'
                ) : (
                    'Verifica que cada uno esté en la columna correspondiente según su aporte al GMV, si está en la columna equivocada puedes arrastrarlo a la otra.'
                )}
            </Typography>
            {inConfirmation && (
                <Typography
                    color="warning.main"
                    component="div"
                    role="alert"
                    fontWeight={700}
                    display="flex"
                    alignItems="center"
                >
                    <WarningAmberOutlined sx={{ mr: 1.4 }} />
                    <span>Una vez confirmados no podrás editar los estatus de órdenes.</span>
                </Typography>
            )}
            <Grid container spacing={4} marginTop={0} maxWidth={{ md: '600px' }}>
                {statuses !== undefined ? (
                    <>
                        <StatusTypeBox
                            xs={12}
                            md={6}
                            type="valid"
                            title="Es una venta"
                            items={mappedStatuses.valid}
                            inConfirmation={isReadOnly || inConfirmation}
                        />
                        <StatusTypeBox
                            xs={12}
                            title="NO es una venta"
                            type="invalid"
                            md={6}
                            items={mappedStatuses.invalid}
                            inConfirmation={isReadOnly || inConfirmation}
                        />
                    </>
                ) : (
                    [0, 1].map((item) => (
                        <Grid item key={item} xs={12} md={6} data-testid="fss-column-skeleton">
                            <Skeleton width="100%" height={200} />
                        </Grid>
                    ))
                )}

                <Grid item xs={12} paddingTop="0 !important">
                    <Collapse in={inConfirmation && alert.open}>
                        <Alert
                            severity={alert.type}
                            sx={{ mt: 4 }}
                            aria-label="Error en la petición"
                        >
                            {alert.message}
                        </Alert>
                    </Collapse>
                </Grid>
                <Grid
                    item
                    container
                    justifyContent="flex-end"
                    spacing={2}
                    marginTop={-1}
                    marginBottom={3}
                >
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {loading ? (
                        <Grid item xs={12} md="auto" textAlign="center">
                            <InterpunctLoader />
                        </Grid>
                    ) : // eslint-disable-next-line no-nested-ternary
                    isReadOnly ? (
                        <Grid item xs={12} md="auto">
                            <DefaultBtn
                                color="primary"
                                variant="outlined"
                                size="small"
                                fullWidth
                                onClick={onLocalCancel}
                            >
                                Salir de clasificación
                            </DefaultBtn>
                        </Grid>
                    ) : isReadOnly === false ? (
                        <>
                            <Grid item xs={12} md="auto">
                                <DefaultBtn
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    onClick={onLocalCancel}
                                    data-testid="fss-cancel-btn"
                                >
                                    {inConfirmation ? 'Ajustarlos' : 'Lo haré más tarde'}
                                </DefaultBtn>
                            </Grid>
                            <Grid item xs={12} md="auto">
                                <DefaultBtn
                                    color="primary"
                                    size="small"
                                    fullWidth
                                    onClick={onLocalConfirm}
                                    data-testid="fss-confirm-btn"
                                >
                                    {inConfirmation ? 'Sí, confirmar' : 'Continuar'}
                                </DefaultBtn>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} md={6}>
                            <Skeleton height={38} width="100%" data-testid="fss-actions-skeleton" />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </DragDropContext>
    );
};

export default StatusesDragDrop;
