import { FC } from 'react';
import { Grid, useMediaQuery, useTheme, Collapse } from '@mui/material';
import { DefaultInput, DefaultBtn, Alert } from '@fairplay2/ui';
import { Step4Props } from '../interfaces';

const Step4: FC<Step4Props> = ({ alert, values, errors, onValueChange, onStepChange, onBlur }) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Grid container spacing={4} mt="30px">
                <Grid item xs={12} sm={6}>
                    <DefaultInput
                        labelVariant="contained"
                        id="contact-name"
                        name="contactName"
                        label="Nombre:*"
                        placeholder="Cómo se llama tu contacto con el proveedor"
                        value={values.contactName}
                        onChange={onValueChange}
                        onBlur={() => onBlur('contactName', 'Ingresa el nombre')}
                        error={errors.contactName}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DefaultInput
                        labelVariant="contained"
                        id="contact-email"
                        name="contactEmail"
                        label="E-mail:*"
                        placeholder="email@midominio.com"
                        value={values.contactEmail}
                        onChange={onValueChange}
                        onBlur={() => onBlur('contactEmail', 'Ingresa la e-mail')}
                        error={errors.contactEmail}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DefaultInput
                        labelVariant="contained"
                        id="contact-phone"
                        name="contactPhone"
                        label="Teléfono:*"
                        placeholder="Número a 10 dígitos"
                        value={values.contactPhone}
                        onChange={onValueChange}
                        onBlur={() => onBlur('contactPhone', 'Ingresa el teléfono')}
                        error={errors.contactPhone}
                    />
                </Grid>
            </Grid>
            <Grid item container xs={12} justifyContent="flex-end" padding="10px 0px">
                <Collapse sx={{ width: { xs: '100%', sm: 'initial' } }} in={alert.open}>
                    <Alert sx={{ justifyContent: 'center' }} severity={alert.type}>
                        {alert.message}
                    </Alert>
                </Collapse>
            </Grid>
            <Grid container justifyContent="flex-end">
                <DefaultBtn
                    fullWidth={smDown}
                    size="small"
                    variant="outlined"
                    onClick={(e) => onStepChange(e, 2)}
                    sx={{
                        mb: { xs: '10px', sm: 0 },
                    }}
                >
                    Atrás
                </DefaultBtn>
                <DefaultBtn
                    fullWidth={smDown}
                    size="small"
                    onClick={(e) => onStepChange(e, 4)}
                    sx={{
                        mb: { xs: '20px', sm: 0 },
                        ml: { xs: 0, sm: '20px' },
                    }}
                >
                    Continuar
                </DefaultBtn>
            </Grid>
        </>
    );
};

export default Step4;
