import { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, useMediaQuery, useTheme, Collapse, DialogTitle } from '@mui/material';
import { DefaultBtn, Typography, InterpunctLoader, Alert } from '@fairplay2/ui';
import { useSetActiveStep } from 'components/customer-onboarding/context/activeStep-context';
import { addGoogleTag } from 'utils/google-tag-manager';
import { ConnectionSuccessSvg } from 'assets/SVGs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import fairplayAPI from 'utils/api';
import { ConnectConfirmationProps as ConfirmationProps } from '../interfaces';

const Confirmation: FC<ConfirmationProps> = ({
    onClose,
    item,
    title,
    description,
    allowMultipleConnectors = true,
}) => {
    const setActiveStep = useSetActiveStep(),
        [loading, setLoading] = useState(false),
        theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        { pathname } = useLocation(),
        [error, setError] = useState('');

    const onFinishOnboarding = async () => {
        setLoading(true);

        try {
            await fairplayAPI.patch('/v1/users/update', { step: 4 });
            addGoogleTag('onboarding-connectors');
            onClose();
            setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
        } catch (errResponse: ErrorObj | any) {
            setError(errResponse?.error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Grid container>
            <Grid container justifyContent="center" mt="35px" mb="30px">
                <ConnectionSuccessSvg />
            </Grid>

            <Grid item xs={12}>
                <Typography
                    component={DialogTitle}
                    variant="subtitle1"
                    color="primary.main"
                    padding={0}
                    mb="20px"
                >
                    {title || '¡Conexión exitosa!'}
                </Typography>

                <Typography variant="subtitle2" color="text.primary" mb="30px">
                    Se ha {title ? 'enviado la solicitud de conexión' : 'conectado'} exitosamente a{' '}
                    <strong>{item}</strong> como fuente de datos.
                </Typography>

                {description && (
                    <Typography variant="subtitle2" color="text.primary" mb="30px">
                        {description}
                    </Typography>
                )}
            </Grid>

            <Grid item container xs={12} justifyContent={{ xs: 'center', sm: 'end' }}>
                <Collapse in={!!error}>
                    <Grid item xs={12} mb="10px">
                        <Alert severity="error">{error}</Alert>
                    </Grid>
                </Collapse>
            </Grid>

            {allowMultipleConnectors && pathname === '/app/onboarding' ? (
                <Grid item xs={12} textAlign={{ xs: 'center', sm: 'end' }}>
                    {loading ? (
                        <InterpunctLoader />
                    ) : (
                        <>
                            <DefaultBtn
                                fullWidth={smDown}
                                size="small"
                                variant="outlined"
                                onClick={onClose}
                                sx={{
                                    m: '8px',
                                }}
                            >
                                Conectar otra fuente
                            </DefaultBtn>
                            <DefaultBtn
                                fullWidth={smDown}
                                size="small"
                                onClick={onFinishOnboarding}
                                sx={{
                                    m: '8px',
                                }}
                            >
                                Finalizar
                            </DefaultBtn>
                        </>
                    )}
                </Grid>
            ) : (
                <Grid item xs={12} textAlign="center">
                    <DefaultBtn
                        fullWidth={smDown}
                        size="small"
                        onClick={onClose}
                        sx={{
                            margin: '8px',
                        }}
                    >
                        Cerrar
                    </DefaultBtn>
                </Grid>
            )}
        </Grid>
    );
};

export default Confirmation;
