import { FC } from 'react';
import { Typography, Divider } from '@fairplay2/ui';
import { Grid } from '@mui/material';
import { formatAmount } from 'utils/parsing';
import { ExpensesDetailContainer } from '../../styles';
import { InvoiceDetailProps } from '../../interfaces';

const InvoiceExpenses: FC<InvoiceDetailProps> = ({ invoiceValues }) => (
    <>
        <Grid container sx={{ my: 2 }}>
            <Typography variant="subtitle2" color="text.primary" fontWeight={500} mr="10px">
                Exportación
            </Typography>
            {/* <Typography variant="subtitle2" color="primary.main" fontWeight={500}>
                    Definitiva
                </Typography> */}
        </Grid>
        <ExpensesDetailContainer item xs={12} md={6} data-testid="expenses-detail">
            <Typography variant="subtitle2" color="primary.main" fontWeight={500}>
                {invoiceValues?.typeMethodDisplay}
                <br />
                {invoiceValues?.usageMethodDisplay}
                <br />
                {invoiceValues?.paymentMethodDisplay}
                <br />
                {invoiceValues?.paymentTypeDisplay}
            </Typography>
        </ExpensesDetailContainer>
        <Grid item xs={12} md={6} textAlign="right" mt={{ xs: '3px', md: 0 }} role="table">
            <Grid container paddingLeft={{ xs: 0, md: '50px' }} role="row">
                <Grid item textAlign="left" xs={4} role="cell">
                    <Typography variant="body1" color="text.primary" fontWeight={500}>
                        Subtotal
                    </Typography>
                </Grid>
                <Grid item xs={4} role="cell">
                    <Typography variant="subtitle2" color="secondary.main" fontWeight={500}>
                        $
                    </Typography>
                </Grid>
                <Grid item xs={4} role="cell">
                    <Typography variant="body1" color="primary.main" fontWeight={700}>
                        {formatAmount(invoiceValues?.subtotal)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container marginTop="10px" paddingLeft={{ xs: 0, md: '50px' }} role="row">
                <Grid item textAlign="left" xs={4} role="cell">
                    <Typography variant="body1" color="text.primary" fontWeight={500}>
                        Descuento
                    </Typography>
                </Grid>
                <Grid item xs={4} role="cell">
                    <Typography variant="subtitle2" color="secondary.main" fontWeight={500}>
                        $
                    </Typography>
                </Grid>
                <Grid item xs={4} role="cell">
                    <Typography variant="body1" color="primary.main" fontWeight={700}>
                        {formatAmount(invoiceValues?.discount)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container marginTop="10px" paddingLeft={{ xs: 0, md: '50px' }} role="row">
                <Grid item textAlign="left" xs={4} role="cell">
                    <Typography variant="body1" color="text.primary" fontWeight={500}>
                        Impuestos
                    </Typography>
                </Grid>
                <Grid item xs={4} role="cell">
                    <Typography variant="subtitle2" color="secondary.main" fontWeight={500}>
                        $
                    </Typography>
                </Grid>
                <Grid item xs={4} role="cell">
                    <Typography variant="body1" color="primary.main" fontWeight={700}>
                        {formatAmount(invoiceValues?.tax)}
                    </Typography>
                </Grid>
            </Grid>
            <Divider
                variant="fullWidth"
                sx={{
                    color: 'text.disabled',
                    m: '25px 0 10px 25px',
                }}
            />
            <Grid container paddingLeft={{ xs: 0, md: '50px' }} role="row">
                <Grid item textAlign="left" xs={6} role="cell">
                    <Typography variant="subtitle2" color="text.primary" fontWeight={500}>
                        Monto total
                    </Typography>
                </Grid>
                <Grid item xs={6} role="cell">
                    <Typography variant="subtitle2" color="primary.main" fontWeight={700}>
                        <Typography
                            component="span"
                            variant="subtitle2"
                            color="secondary.main"
                            fontWeight={700}
                        >
                            {invoiceValues?.currency}{' '}
                        </Typography>
                        {formatAmount(invoiceValues?.total)}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </>
);

export default InvoiceExpenses;
