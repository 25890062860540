import {
    FC,
    Fragment,
    useState,
    useContext,
    useEffect,
    ChangeEvent,
    FormEvent,
    useCallback,
} from 'react';
import { Redirect } from 'react-router-dom';
import { addGoogleTag } from 'utils/google-tag-manager';
import sessionContext from 'context/session/sessionContext';
import fairplayAPI from 'utils/api';
import { Grid } from '@mui/material';
import { validateFileSize } from 'utils/files';
import { useAlert } from 'utils/hooks/useAlert';
import { localFormat, TIMELESS_ISO_FORMAT } from 'utils/dates';
import { get4MonthsWeekends } from 'components/dashboard/disbursment/transactions/create-disbursement/utils';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { FormV1, ErrorsV1 } from './interfaces.v1';
import SalesAdvancement from './components/v1/SalesAdvancement';
import SalesAdvancementDetail from './components/v1/SalesAdvancementDetail';
import WorkingCapital from './components/v1/WorkingCapital';
import WorkingCapitalDetail from './components/v1/WorkingCapitalDetail';
import DisbursementType from './components/v1/DisbursementType';
import InvoiceContract from './components/v1/InvoiceContract';
import SuccessModal from './components/v1/SuccessModal';
import CancelModal from './components/v1/CancelModal';
import { getHolidaysDates, getMinDisbursementDate } from './utils';
import { getFormStatusV1, getAmountError } from './utils.v1';

type DisbType = 'invoice' | 'card' | '';

const SUPPLIERS_ENDPOINT_ERROR = 'Ha ocurrido un error y no se pudieron obtener los proveedores.';

const CreateDisbursement: FC = () => {
    const SessionContext = useContext(sessionContext),
        { selectedCompany } = SessionContext,
        [loading, setLoading] = useState(false),
        [disbursementType, setDisbursementType] = useState<DisbType>(''),
        [selectedContract, setSelectedContract] = useState<any>(undefined),
        [supplier, setSupplier] = useState<any>({}),
        [suppliers, setSuppliers] = useState<any>(undefined),
        [supplierName, setSupplierName] = useState<string>(''),
        [bankAccount, setBankAccount] = useState<any>(undefined),
        // [companyCurrencies, setCompanyCurrencies] = useState<any>([]),
        [step, setStep] = useState(0),
        [isModalOpen, setIsModalOpen] = useState({
            success: false,
            cancel: false,
        }),
        [tooltips, setTooltips] = useState({
            concept: false,
            reference: true,
        }),
        [inputValue, setInputValue] = useState(''),
        [values, setValues] = useState<FormV1>({
            invoiceFile: null,
            amount: null,
            currency: 'MXN',
            concept: '',
            reference: '',
            date: undefined,
            type: 'unique-invoice',
            bankAccountConfirmation: false,
            supplierId: '',
            supplierAccount: '',
        }),
        [errors, setErrors] = useState<ErrorsV1>({
            invoiceFile: '',
            amount: '',
            currency: '',
            concept: '',
            reference: '',
            date: '',
            type: '',
            bankAccountConfirmation: '',
            supplierId: '',
            supplierAccount: '',
        }),
        { alert, hideAlert, showAlert } = useAlert(),
        [date, setDate] = useState<Date | undefined>(),
        [disabledDays, setDisabledDays] = useState<Date[]>([]);

    const getSuppliers = useCallback(async () => {
        if (!selectedCompany.company?.id) return;

        try {
            const res: any = await fairplayAPI.get(
                `/v1/companies/${selectedCompany.company?.id}/suppliers?status=approved&page_size=200`,
            );
            if (res.data.body.count > 0) {
                const auxArray: any[] = [];
                res.data.body.results.forEach((supp: { alias: string; registeredName: string }) => {
                    auxArray.push(`${supp.alias} - ${supp.registeredName}`);
                });
                setSuppliers(res.data.body.results);
                setErrors((prev) => ({
                    ...prev,
                    supplierId: '',
                }));
            } else {
                setErrors((prev) => ({
                    ...prev,
                    supplierId: 'No existen proveedores aprobados',
                }));
                setSuppliers([]);
                setInputValue('');
            }
        } catch (errResponse: ErrorObj | any) {
            setErrors((prev) => ({
                ...prev,
                supplierId: errResponse?.error || SUPPLIERS_ENDPOINT_ERROR,
            }));
            setSuppliers([]);
            setInputValue('');
        }
    }, [selectedCompany.company?.id]);

    const getSelectedCompanyBank = async (contractId: string) => {
        if (!contractId) return;

        try {
            const contractRes: any = await fairplayAPI.get(
                `/v2/companies/${selectedCompany.company?.id}/contracts/${contractId}`,
            );

            if (contractRes.data.body.results.bankInformation) {
                setBankAccount({
                    clabe: contractRes.data.body.results.bankInformation.clabe,
                    bank: contractRes.data.body.results.bankInformation.bankName,
                });
            } else {
                const accountsRes: any = await fairplayAPI.get(
                    `/v1/companies/${selectedCompany.company?.id}/accounts`,
                );

                let hasAccount = false;
                // eslint-disable-next-line no-restricted-syntax
                for (const account of accountsRes.data.body.results) {
                    if (account.status.id === 'primary') {
                        setBankAccount({
                            clabe: account.clabe,
                            bank: account.bank,
                        });
                        hasAccount = true;
                        break;
                    }
                }
                if (!hasAccount) throw Error();
            }
        } catch {
            setBankAccount({});
        }
    };

    const clearForm = () => {
        setValues({
            invoiceFile: null,
            amount: null,
            currency: 'MXN',
            concept: '',
            reference: '',
            date: undefined,
            type: 'unique-invoice',
            bankAccountConfirmation: false,
            supplierId: '',
            supplierAccount: '',
        });
        setErrors({
            invoiceFile: '',
            amount: '',
            currency: '',
            concept: '',
            reference: '',
            date: '',
            type: '',
            bankAccountConfirmation: '',
            supplierId:
                errors.supplierId === SUPPLIERS_ENDPOINT_ERROR ? SUPPLIERS_ENDPOINT_ERROR : '',
            supplierAccount: '',
        });
        setTooltips({
            reference: false,
            concept: false,
        });
        hideAlert();
        // clear selected supplier
        setSupplierName('');
        setSupplier({});
    };

    // TODO: check if this code is going to be used
    /* const getSelectedCompanyCurrencies = () => {
        get(`/v1/companies/${selectedCompany.company?.id}/currencies`).then((res: any) => {
            if (res.data.body.results.currencies) {
                setCompanyCurrencies(res.data.body.results.currencies);
            }
        });
    }; */

    const onDisbursementTypeChange = (type: DisbType) => {
        setDisbursementType(type);
        if (type === 'invoice') {
            setValues({ ...values, type: 'unique-invoice' });
        } else if (type === 'card') {
            setValues({ ...values, type: 'fairplay-card' });
        }
    };

    const onContractChange = (contract: any) => {
        clearForm();
        setSelectedContract(contract);
        if (contract?.productType === 'sales-advancement') getSelectedCompanyBank(contract.id);
    };

    const onSelectChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    const onConfirmBankAccount = () => {
        setValues({ ...values, bankAccountConfirmation: !values.bankAccountConfirmation });
    };

    const onChangeInput = (prop: keyof FormV1, errorMsg: string) => {
        if (values[prop] || supplierName) {
            setErrors({ ...errors, [prop]: prop === 'amount' ? getAmountError(values[prop]) : '' });
        } else setErrors({ ...errors, [prop]: errorMsg });

        if (prop === 'reference') setTooltips({ ...tooltips, reference: false });
        if (prop === 'concept') setTooltips({ ...tooltips, concept: false });
    };

    const onFileChange = (file: File | null) => {
        if (!file) return setValues({ ...values, invoiceFile: null });

        const fileError = validateFileSize(file, 5, 'El máximo de archivo es 5MB');
        if (fileError) setErrors({ ...errors, invoiceFile: fileError });
        else setValues({ ...values, invoiceFile: file });
    };

    const onStepChange = (event: ChangeEvent<{}>, newValue: number) => {
        setStep(newValue);
        if (newValue === 1 && disbursementType === 'invoice') {
            addGoogleTag('disbursement-invoice-start');
        }
    };

    const onModalOpen = (target: string) => {
        setIsModalOpen({ ...isModalOpen, [target]: true });
    };

    const onModalClose = (target: string) => {
        setIsModalOpen({ ...isModalOpen, [target]: false });
        document.body.style.overflow = 'auto';
    };

    const onChange = (prop: keyof FormV1) => (event: ChangeEvent<HTMLInputElement> | any) => {
        setErrors({ ...errors, [prop]: '' });
        const value = event?.target ? event.target.value : event;

        switch (prop) {
            case 'concept':
                setValues({ ...values, concept: value.replace(/[^\w\s]/gi, '') });
                setTooltips({ ...tooltips, concept: true });
                break;
            case 'reference':
                setValues({ ...values, reference: value.replace(/\D/, '') });
                setTooltips({ ...tooltips, reference: true });
                break;
            case 'amount':
                setValues({ ...values, amount: value });
                break;
            default:
                setValues({ ...values, [prop]: value });
                break;
        }
    };

    const onDateSelect = (newDate: Date | undefined) => {
        // Forcing onChange to recognize date object
        onChange('date')(newDate as unknown as any);
    };

    const onAutocompleteChange = (newValue: any) => {
        if (newValue) {
            setSupplierName(`${newValue.alias} - ${newValue.registeredName}`);
            setSupplier(newValue);
            setValues({ ...values, supplierId: newValue.id, supplierAccount: '' });
        }
    };

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        hideAlert();
        const formStatus = getFormStatusV1(selectedContract?.productType || '', values);

        // Check if there isn't an error
        if (formStatus.valid) {
            setLoading(true);
            const data = new FormData();

            // eslint-disable-next-line no-restricted-syntax
            for (const [key, value] of Object.entries(values)) {
                if (value) {
                    if (selectedContract?.productType === 'sales-advancement' && key === 'currency')
                        continue;
                    if (key === 'supplierAccount') data.append(key, value.id);
                    else if (key === 'date')
                        data.append(
                            key,
                            localFormat(value || '', TIMELESS_ISO_FORMAT, { fallbackString: '' }),
                        );
                    else data.append(key, value);
                }
            }

            try {
                await fairplayAPI.post(
                    `/v2/companies/${selectedCompany.company?.id}/contracts/${selectedContract?.id}/dispersions`,
                    data,
                    { useIdempotency: true },
                );
                setLoading(false);
                onModalOpen('success');
                addGoogleTag('disbursement-success');
            } catch (errResponse: ErrorObj | any) {
                setLoading(false);
                if (errResponse.type === 'form') {
                    setErrors((prev) => ({ ...prev, ...errResponse.error }));
                    showAlert('Hay campos pendientes de completar, revisa el formulario');
                } else
                    showAlert(
                        errResponse?.error || 'No se pudo realizar la peticion, intente nuevamente',
                    );
            }
        } else {
            setErrors((prev) => ({
                ...prev,
                ...formStatus.errors,
            }));
            showAlert('Hay campos pendientes de completar, revisa el formulario');
        }
    };

    const fetchHolidaysDates = async () => {
        try {
            const dates = await getHolidaysDates();
            setDisabledDays([...get4MonthsWeekends(), ...dates]);
        } catch (errResponse: ErrorObj | any) {
            showAlert(
                errResponse?.error || 'Ocurrió un error creando la dispersion, intente más tarde',
                'error',
                false,
            );
        }
    };

    useEffect(() => {
        if (step === 0 || disbursementType !== 'invoice') return;
        // reset values:
        setDate(getMinDisbursementDate());
        fetchHolidaysDates();
        // Get available suppliers
        getSuppliers();
        // Get company currencies
        // getSelectedCompanyCurrencies();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, disbursementType, getSuppliers]);

    const commonFormProps = {
            onChangeInput,
            onChange,
            onFileChange,
            onDateSelect,
            values,
            errors,
            disabledDays,
            date,
            tooltips,
            fileName: values.invoiceFile?.name,
        },
        commonDetailProps = {
            alert,
            loading,
            onConfirmBankAccount,
            onStepChange,
            bankAccountConfirmation: values.bankAccountConfirmation,
        };

    return (
        <>
            {isModalOpen.success && (
                <SuccessModal
                    isModalOpen={isModalOpen.success}
                    reference={values.reference}
                    onModalClose={onModalClose}
                />
            )}

            {isModalOpen.cancel && (
                <CancelModal
                    isModalOpen={isModalOpen.cancel}
                    disbursementType={disbursementType}
                    onModalClose={onModalClose}
                />
            )}

            <Grid container spacing={4} data-testid="create-disbursement-v1">
                <Grid item xs={12} mb="20px">
                    {step === 0 && (
                        <DisbursementType
                            disbursementType={disbursementType}
                            onDisbursementTypeChange={onDisbursementTypeChange}
                            onStepChange={onStepChange}
                        />
                    )}
                    {step === 1 && disbursementType === 'invoice' && (
                        <>
                            <InvoiceContract
                                currentContractId={selectedContract?.id}
                                onContractChange={onContractChange}
                                onStepChange={onStepChange}
                                onModalOpen={onModalOpen}
                            />
                            {!!selectedContract?.productType && (
                                <form
                                    id={`${selectedContract.productType}-disbursement`}
                                    onSubmit={onSubmit}
                                >
                                    {selectedContract.productType === 'sales-advancement' ? (
                                        <>
                                            <SalesAdvancement
                                                selectedCompany={selectedCompany}
                                                {...commonFormProps}
                                            />
                                            <SalesAdvancementDetail
                                                bankAccount={bankAccount}
                                                {...commonDetailProps}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <WorkingCapital
                                                onSelectChange={onSelectChange}
                                                onAutocompleteChange={onAutocompleteChange}
                                                supplier={supplier}
                                                suppliers={suppliers}
                                                inputValue={inputValue}
                                                setInputValue={setInputValue}
                                                {...commonFormProps}
                                            />
                                            {Object.keys(supplier).length > 0 && (
                                                <WorkingCapitalDetail
                                                    selectedAccount={values.supplierAccount}
                                                    supplier={supplier}
                                                    {...commonDetailProps}
                                                />
                                            )}
                                        </>
                                    )}
                                </form>
                            )}
                        </>
                    )}
                    {step === 1 && disbursementType === 'card' && (
                        <Redirect to="/app/financing/cards" />
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default CreateDisbursement;
