import { FC, useState } from 'react';
import { Grid } from '@mui/material';
import { PlaylistAddCheck, OpenInBrowser, CheckCircle, ArrowBackIos } from '@mui/icons-material';
import { Typography, TabGroup, DefaultBtn, TabData } from '@fairplay2/ui';
import Confirmation from '../wizard-ecommerce/Confirmation';
import ConfigureGoogle from './ConfigureGoogle';
import ConfigureFB from './ConfigureFB';
import { ConfigureProps } from './interfaces';

const TABS_DATA: TabData[] = [
    { label: '1. Permisos', icon: <OpenInBrowser />, disabled: true, panelId: null },
    { label: '2. Configurar', icon: <PlaylistAddCheck /> },
    { label: '3. Confirmar', icon: <CheckCircle />, disabled: true },
];

const ConfigureAdvertising: FC<ConfigureProps> = ({
    element,
    onCancel,
    onClose,
    wizard,
    groupKey,
}) => {
    const [activeTab, setActiveTab] = useState(1),
        isAnalyticsConnector = element.platform?.includes('analytics');

    const onConfirm = () => setActiveTab(2);

    return (
        <Grid container>
            {!wizard && (
                <Grid item xs={12} textAlign="left">
                    <DefaultBtn
                        variant="text"
                        onClick={onCancel}
                        sx={{ textDecoration: 'none !important' }}
                    >
                        <ArrowBackIos fontSize="inherit" /> Regresar a conexiones
                    </DefaultBtn>
                </Grid>
            )}

            <Grid item xs={12}>
                {isAnalyticsConnector ? (
                    <Typography variant="subtitle1" color="primary.main">
                        Conecta tu cuenta de Google Analytics
                    </Typography>
                ) : (
                    <Typography variant="subtitle1" color="primary.main">
                        Conecta tus cuentas de{' '}
                        {groupKey?.includes('google') ? 'Google' : 'Facebook'}
                    </Typography>
                )}
            </Grid>

            <TabGroup
                tabs={TABS_DATA}
                tabDirection="column"
                value={activeTab}
                TabsProps={{ variant: 'fullWidth' }}
                display="column"
                item
                xs={12}
                sx={{
                    '.MuiTabs-root': {
                        width: '100%',
                        m: '20px 0px 30px 0px',
                    },
                    '.FpTabPanel-root': {
                        mb: 0,
                    },
                }}
            >
                {groupKey?.includes('google') ? (
                    <ConfigureGoogle element={element} onConfirm={onConfirm} onClose={onClose} />
                ) : (
                    <ConfigureFB element={element} onConfirm={onConfirm} onClose={onClose} />
                )}
                <Confirmation groupKey={groupKey} onClose={onClose} />
            </TabGroup>
        </Grid>
    );
};

export default ConfigureAdvertising;
