import {
    Language,
    FolderShared,
    MonetizationOn,
    PermContactCalendar,
    Receipt,
} from '@mui/icons-material';
import { TabData } from '@fairplay2/ui';
import { StepsValues } from './interfaces';

export const DEFAULT_TABS_DATA: TabData[] = [
    { label: 'Proveedor', icon: <Language />, hasError: false },
    { label: 'Generales', icon: <FolderShared />, hasError: false },
    { label: 'Bancarios', icon: <MonetizationOn />, hasError: false },
    { label: 'Contacto', icon: <PermContactCalendar />, hasError: false },
    { label: 'Facturas', icon: <Receipt />, hasError: false },
];

export const STEPS_VALUES: StepsValues = {
    1: [
        'supplierAlias',
        'supplierRfc',
        'supplierTaxId',
        'supplierRegisteredName',
        'supplierWebsite',
        'supplierCountry',
        'industry',
        'supplierStreet',
        'supplierZipCode',
        'supplierState',
        'supplierAddress',
    ],
    2: [
        'accountClabe',
        'accountSwift',
        'accountBank',
        'accountNumber',
        'accountCountry',
        'accountState',
        'accountCurrency',
    ],
    3: ['contactName', 'contactEmail', 'contactPhone'],
    4: ['invoiceFile', 'selectedYear'],
};

const BASE_REQUIRED_FIELDS: string[] = [
    'supplierAlias',
    'supplierRegisteredName',
    'industry',
    'accountCurrency',
    'contactName',
    'contactEmail',
    'contactPhone',
    'accountBank',
];

export const NATIONAL_REQUIRED_FIELDS: string[] = [
    ...BASE_REQUIRED_FIELDS,
    'supplierRfc',
    'accountClabe',
];

export const INTERNATIONAL_REQUIRED_FIELDS: string[] = [
    ...BASE_REQUIRED_FIELDS,
    'supplierTaxId',
    'supplierCountry',
    'supplierStreet',
    'supplierZipCode',
    'supplierState',
    'accountSwift',
    'accountNumber',
    'accountCountry',
    'accountState',
];
