import { FC } from 'react';
import { Skeleton } from '@fairplay2/ui';
import { Grid } from '@mui/material';

const ActiveContractsListSkeleton: FC<any> = () => (
    <Grid item container xs={12} marginTop="15px" data-testid="activeContractsList-skeleton">
        <Grid
            item
            container
            xs={12}
            md={8}
            marginBottom={2}
            justifyContent={{ xs: 'center', md: 'flex-start' }}
        >
            <Skeleton width={205} height={200} sx={{ m: '0 5px 5px 0' }} />
            <Skeleton width={205} height={200} sx={{ m: '0 5px 5px 0' }} />
            <Skeleton width={205} height={200} sx={{ m: '0 5px 5px 0' }} />
        </Grid>
        <Grid
            item
            container
            xs={12}
            md={4}
            justifyContent={{ xs: 'center', md: 'flex-end' }}
            pr={{ xs: 0, lg: '65px' }}
            maxHeight="250px"
        >
            <Grid item container justifyContent={{ xs: 'center', md: 'flex-end' }} xs={12}>
                <Skeleton sx={{ width: { xs: '100%', md: '270px' } }} height={200} />
            </Grid>
            <Grid item container justifyContent={{ xs: 'center', md: 'flex-end' }} xs={12}>
                <Skeleton width={200} height={35} sx={{ mt: '15px' }} />
            </Grid>
        </Grid>
    </Grid>
);

export default ActiveContractsListSkeleton;
