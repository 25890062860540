import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import img from 'assets/img/connectors/FacebookAds.png';
import fairplayAPI from 'utils/api';
import { ErrorObj } from 'utils/error-handler';
import { toFormData } from 'utils/parsing';
import { dataSourceRedirection } from '../../utils';
import BasePlatformItem from '../components/BasePlatformItem';
import { PlatformItem } from '../interfaces';
import useCodeInterceptor from '../useCodeInterceptor';
import { useDataSources } from '../../context/DataSourcesContext';
import Wizard from '../components/wizard-advertising';
import { ConnectorData, OnCodeInterception } from '../../interfaces';

const REDIRECTION_PARAMS = ['code'];

const getFbUrl = (pathname: string) => {
    const fbUrl =
            pathname === '/app/onboarding'
                ? process.env.REACT_APP_FB_URL_ONBOARDING
                : process.env.REACT_APP_FB_URL,
        redirectionUrl = `https://www.facebook.com/dialog/oauth?client_id=${process.env.REACT_APP_FB_CLIENT_ID}&redirect_uri=${fbUrl}&scope=ads_read,email`;
    return redirectionUrl;
};

// eslint-disable-next-line react/prop-types
const FacebookAds: PlatformItem = ({ notify }) => {
    const { baseAPI, dispatch } = useDataSources(),
        [loading, setLoading] = useState(false),
        [showWizard, setShowWizard] = useState<boolean | ConnectorData>(false),
        { pathname } = useLocation();

    const onConnect = () => {
        setLoading(true);
        dataSourceRedirection.create(getFbUrl(pathname), {
            from: 'facebook-ads',
        });
    };

    const onReauth = ({ id }: ConnectorData) => {
        dataSourceRedirection.create(getFbUrl(pathname), { from: 'facebook-ads', connector: id });
        return Promise.resolve();
    };

    const createConnector = async (code: string, redirectUri: string) => {
        const data = toFormData({
            platform: 'fads-v2',
            code,
            redirect_uri: redirectUri,
        });

        const response: any = await fairplayAPI.post(baseAPI, data);
        const connector = response.data.body.results;
        dispatch({
            type: 'ADD',
            payload: connector,
            onCommit: () => {
                setShowWizard(connector);
                setLoading(false);
            },
        });

        return response;
    };

    const reauthConnector = async (id: string, code: string, redirectUri: string) => {
        const data = toFormData({
            code,
            redirect_uri: redirectUri,
        });

        const response: any = await fairplayAPI.patch(`${baseAPI}/${id}`, data);
        dispatch({
            type: 'UPDATE',
            payload: response.data.body.results,
            onCommit: () => {
                notify('Se ha reconectado Facebook Ads con éxito', 'success');
                setLoading(false);
            },
        });

        return response;
    };

    const onCodeInterception: OnCodeInterception = (foundParams, metadata) => {
        // Redirection just began and react triggered a re-render that
        //  caused the hook to be executed
        if (loading) return false;
        setLoading(true);

        // send new params to back, accessToken will be generated by its side
        const { code } = foundParams,
            redirectUri =
                pathname === '/app/onboarding'
                    ? process.env.REACT_APP_FB_URL_ONBOARDING!
                    : process.env.REACT_APP_FB_URL!,
            promise = metadata.connector
                ? reauthConnector(metadata.connector, code, redirectUri)
                : createConnector(code, redirectUri);

        promise.catch((errResponse: ErrorObj | any) => {
            notify(
                errResponse?.error ||
                    'Ocurrió un error al generar la conexión con Facebook, intente más tarde',
                'error',
            );
            setLoading(false);
        });

        return true;
    };

    useCodeInterceptor('facebook-ads', REDIRECTION_PARAMS, onCodeInterception);

    return (
        <BasePlatformItem
            picture={img}
            groupKey="facebook-ads"
            showLoader={loading}
            notify={notify}
            onConnect={onConnect}
            onReauth={onReauth}
        >
            <Wizard
                open={!!showWizard}
                element={showWizard}
                onClose={() => setShowWizard(false)}
                groupKey="facebook-ads"
            />
        </BasePlatformItem>
    );
};

export default FacebookAds;
