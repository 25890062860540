import { FC, useState, useEffect, useContext, useCallback, useRef } from 'react';
import { Grid, Collapse } from '@mui/material';
import { ArrowDownward } from '@mui/icons-material';
import { InterpunctLoader, Alert, Skeleton, Typography } from '@fairplay2/ui';
import fairplayAPI from 'utils/api';
import SessionContext from 'context/session/sessionContext';
import { useAlert } from 'utils/hooks';
import InviteUser from './components/InviteUser';
import User from './components/User';
import DeleteUserModal from './components/DeleteUserModal';
import { UserType } from './interfaces';

const CompanyTeam: FC = () => {
    const { selectedCompany } = useContext(SessionContext),
        [companyId, setCompanyId] = useState(''),
        [users, setUsers] = useState<UserType[]>([]),
        [loading, setLoading] = useState(true),
        { alert, hideAlert, showAlert } = useAlert(),
        [page, setPage] = useState(1),
        [isLastPage, setIsLastPage] = useState(false),
        [lastElement, setLastElement] = useState<any>(null),
        [isModalOpen, setIsModalOpen] = useState(false),
        [selectedUser, setSelectedUser] = useState<UserType | null>(null),
        observer = useRef<any>(
            new IntersectionObserver((entries) => {
                const first = entries[0];
                if (first.isIntersecting) setPage((prev) => prev + 1);
            }),
        );

    const onOpenModal = () => setIsModalOpen(true);

    const onCloseModal = () => setIsModalOpen(false);

    const onDeleteUser = (user: UserType) => {
        onOpenModal();
        setSelectedUser(user);
    };

    const triggerFetchUsers = useCallback(() => setCompanyId(''), []);

    useEffect(() => {
        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe?.(currentElement);
        }

        return () => {
            if (currentElement) currentObserver.unobserve?.(currentElement);
        };
    }, [lastElement]);

    useEffect(() => {
        hideAlert();
        if (companyId && !isLastPage) {
            const getUsers = async () => {
                setLoading(true);
                try {
                    const res: any = await fairplayAPI.get(
                        `/v1/companies/${companyId}/collaborators?page=${page}&page_size=10`,
                    );
                    setUsers((prev) => [...prev, ...res.data.body.results]);
                    setIsLastPage(!res.data.body.links?.next);
                    setLoading(false);
                } catch {
                    setLoading(false);
                    showAlert(
                        'Hubo un error al obtener los miembros del equipo, intente más tarde',
                    );
                }
            };
            getUsers();
        }
    }, [companyId, page, isLastPage, hideAlert, showAlert]);

    useEffect(() => {
        setUsers([]);
        setPage(1);
        setIsLastPage(false);

        if (selectedCompany.company?.id) {
            setCompanyId(selectedCompany.company?.id);
        }
    }, [selectedCompany, companyId]);

    return (
        <>
            <Grid container spacing={4}>
                <Grid container item xs={12}>
                    <Collapse in={alert.open}>
                        <Alert severity={alert.type}>{alert.message}</Alert>
                    </Collapse>
                </Grid>
                <Grid container item spacing={4}>
                    <Grid item xs={12} sm={12} md={6}>
                        <InviteUser />
                    </Grid>
                </Grid>

                <Grid container item spacing={4}>
                    {users.length === 0 &&
                        loading &&
                        Array(5)
                            .fill(0)
                            .map((_, i) => (
                                <Grid
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={i}
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    data-testid="team-member-skeleton"
                                >
                                    <Skeleton height={220} />
                                </Grid>
                            ))}
                    {users.map((user) => (
                        <Grid key={user.id} item xs={12} sm={12} md={6}>
                            <User user={user} onDeleteUser={onDeleteUser} />
                        </Grid>
                    ))}

                    <Grid
                        ref={setLastElement}
                        container
                        justifyContent="center"
                        alignItems="center"
                        height="80px"
                    >
                        {loading ? (
                            <InterpunctLoader />
                        ) : (
                            !isLastPage && (
                                <Grid container alignItems="center" justifyContent="center">
                                    <ArrowDownward color="secondary" />
                                    <Typography
                                        variant="subtitle1"
                                        color="secondary.main"
                                        fontWeight={500}
                                        ml={2}
                                    >
                                        Cargar más
                                    </Typography>
                                </Grid>
                            )
                        )}
                    </Grid>
                </Grid>
            </Grid>

            {isModalOpen && (
                <DeleteUserModal
                    open={isModalOpen}
                    selectedUser={selectedUser}
                    onClose={onCloseModal}
                    triggerFetchUsers={triggerFetchUsers}
                />
            )}
        </>
    );
};

export default CompanyTeam;
