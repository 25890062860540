import { GroupedPlatformKey } from '../interfaces';
import { PlatformItem } from './interfaces';
import tiendanube from './tiendanube';
import shopify from './shopify';
import walmart from './walmart';
// import liverpool from './liverpool';
import magento from './magento';
import woocommerce from './woocommerce';
import vtex from './vtex';
import amazon from './amazon';
import mercadolibre from './mercado-libre';
import facebookads from './facebook-ads';
import googleads from './google-ads';
import googleanalytics from './google-analytics';
import paypal from './paypal';
import stripe from './stripe';
import clip from './clip';
import sat from './sat';
import s3 from './s3';

const components: Record<GroupedPlatformKey, PlatformItem> = {
    tiendanube,
    shopify,
    magento,
    woocommerce,
    vtex,
    amazon,
    'mercado-libre': mercadolibre,
    walmart,
    // liverpool,
    'facebook-ads': facebookads,
    'google-ads': googleads,
    'google-analytics': googleanalytics,
    paypal,
    stripe,
    clip,
    sat,
    s3,
};

export default components;
