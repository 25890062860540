import { ChangeEvent, FC } from 'react';
import { Divider, Grid, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import { Info } from '@mui/icons-material';
import { IconBtn, Select, Tooltip, Typography, DefaultInput } from '@fairplay2/ui';
import { SearchFilters, getActiveFilters } from 'common-components';
import { asSorting } from 'utils/lists';
import { toTimelessISOUtc } from 'utils/dates';
import { DatePicker } from '@fairplay2/ui-x-dates';
import { useTableContext } from '../../../state/TableContext';
import { RequestsFilterObj } from '../../../interfaces';
import { FILTER_CONTAINER_STYLE, APPROVAL_REQUEST_STATUS } from '../../../constants';

const ApprovalRequestFilters: FC = () => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        {
            filterValues,
            setFilterValues,
            isFilterOff,
            setIsFilterOff,
            activeFilters,
            setActiveFilters,
            setExternalFilters,
            orderCriteria,
            order,
            resetFilterValues,
        } = useTableContext();

    const onFilterSearch = () => {
        const selectedDay = filterValues.selectedDayRange,
            dateFilters = {
                start_date: selectedDay?.from ? toTimelessISOUtc(selectedDay.from) : '',
                end_date: selectedDay?.to ? toTimelessISOUtc(selectedDay.to) : '',
            };

        setActiveFilters(
            getActiveFilters({
                date: dateFilters,
                requested_by: filterValues.requested_by,
                status: filterValues.status,
            }),
        );

        setExternalFilters((externalFilters: Object) => ({
            ...externalFilters,
            ...{
                ...dateFilters,
                requested_by: filterValues.requested_by,
                status: filterValues.status,
                ordering: asSorting(orderCriteria, order),
            },
        }));
    };

    const onFiltersChange = (event: ChangeEvent<HTMLInputElement>) => {
        const filterName = event.target.name,
            newFilterValue = event.target.value;

        if (isFilterOff) setIsFilterOff(false);

        setFilterValues((prev: RequestsFilterObj) => ({
            ...prev,
            [filterName]: newFilterValue,
        }));
    };

    const onSelectedDay = (range: typeof filterValues['selectedDayRange']) =>
        onFiltersChange({
            target: { name: 'selectedDayRange', value: range },
            // Event interface expects `value` to be a string and needs more fields.
            //  Forcing type allows to reuse onFiltersChange logic
        } as any);

    return (
        <SearchFilters
            isFilterOff={isFilterOff}
            activeFilters={activeFilters}
            onFilterSearch={onFilterSearch}
            resetFilters={resetFilterValues}
            width="100%"
            marginTop={smDown ? '10px' : 0}
        >
            <Grid
                item
                container
                minWidth="30%"
                height="100%"
                textAlign="center"
                alignItems="center"
                sx={FILTER_CONTAINER_STYLE}
            >
                <DatePicker
                    variant="standard"
                    labelVariant="contained"
                    label="Fecha de solicitud"
                    mode="range"
                    placeholder="Desde - Hasta"
                    value={filterValues.selectedDayRange}
                    onChange={onSelectedDay}
                    inputAlignment="start"
                />
            </Grid>
            <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ color: 'background.dark' }}
            />
            <Grid
                item
                container
                minWidth="30%"
                height="100%"
                justifyContent="center"
                alignItems="center"
                sx={FILTER_CONTAINER_STYLE}
            >
                <DefaultInput
                    name="requested_by"
                    label="Solicitante"
                    labelVariant="contained"
                    value={filterValues.requested_by}
                    onChange={onFiltersChange}
                    placeholder="¿De quién?"
                    variant="standard"
                />
            </Grid>
            <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ color: 'background.dark' }}
            />
            <Grid
                item
                container
                minWidth="30%"
                height="100%"
                justifyContent="center"
                alignItems="center"
                sx={FILTER_CONTAINER_STYLE}
            >
                <Select
                    value={filterValues.status}
                    onChange={onFiltersChange}
                    name="status"
                    variant="standard"
                    labelVariant="contained"
                    label={
                        <Grid container>
                            <Typography variant="body2" color="text.primary" fontWeight={500}>
                                Estatus
                            </Typography>
                            <Tooltip
                                color="paper"
                                placement="top-start"
                                distance={5}
                                title={
                                    <Grid container maxWidth="300px">
                                        <Typography
                                            variant="body2"
                                            color="info.main"
                                            fontWeight={700}
                                            my="2px"
                                        >
                                            Cancelación declinada:{' '}
                                            <Typography
                                                color="text.primary"
                                                fontWeight={500}
                                                component="span"
                                            >
                                                La cancelación de esta tarjeta no fue aprobada.
                                            </Typography>
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="info.main"
                                            fontWeight={700}
                                            my="2px"
                                        >
                                            Cancelación aprobada:{' '}
                                            <Typography
                                                color="text.primary"
                                                fontWeight={500}
                                                component="span"
                                            >
                                                La cancelación de esta tarjeta fue aprobada.
                                            </Typography>
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="info.main"
                                            fontWeight={700}
                                            my="2px"
                                        >
                                            Actualización de límite declinado:{' '}
                                            <Typography
                                                color="text.primary"
                                                fontWeight={500}
                                                component="span"
                                            >
                                                La modificación del límite de crédito no fue
                                                aprobada.
                                            </Typography>
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="info.main"
                                            fontWeight={700}
                                            my="2px"
                                        >
                                            Actualización de límite aprobada:{' '}
                                            <Typography
                                                color="text.primary"
                                                fontWeight={500}
                                                component="span"
                                            >
                                                La modificación del límite de crédito fue aprobada.
                                            </Typography>
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="info.main"
                                            fontWeight={700}
                                            my="2px"
                                        >
                                            Creación aprobada:{' '}
                                            <Typography
                                                color="text.primary"
                                                fontWeight={500}
                                                component="span"
                                            >
                                                La creación de esta tarjeta fue aprobada.
                                            </Typography>
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="info.main"
                                            fontWeight={700}
                                            my="2px"
                                        >
                                            Creación declinada:{' '}
                                            <Typography
                                                color="text.primary"
                                                fontWeight={500}
                                                component="span"
                                            >
                                                La creación de esta tarjeta fue declinada.
                                            </Typography>
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="info.main"
                                            fontWeight={700}
                                            my="2px"
                                        >
                                            Alias actualizado:{' '}
                                            <Typography
                                                color="text.primary"
                                                fontWeight={500}
                                                component="span"
                                            >
                                                El alias de esta tarjeta fue actualizado.
                                            </Typography>
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="info.main"
                                            fontWeight={700}
                                            my="2px"
                                        >
                                            Pendiente:{' '}
                                            <Typography
                                                color="text.primary"
                                                fontWeight={500}
                                                component="span"
                                            >
                                                Solicitud pendiente.
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                }
                            >
                                <IconBtn
                                    color="primary"
                                    sx={{
                                        mt: '-10px',
                                    }}
                                >
                                    <Info />
                                </IconBtn>
                            </Tooltip>
                        </Grid>
                    }
                >
                    <MenuItem value="">Mostrar todos</MenuItem>
                    {Object.entries(APPROVAL_REQUEST_STATUS).map(([status, text]) => (
                        <MenuItem key={status} value={status}>
                            {text}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
        </SearchFilters>
    );
};

export default ApprovalRequestFilters;
