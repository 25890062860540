import { FC, useContext, useState } from 'react';
import { TableCell, TableRow, Grid } from '@mui/material';
import { Description, Delete, GetApp, Update } from '@mui/icons-material';
import { Typography, Tooltip, IconBtn, Loader } from '@fairplay2/ui';
import { Status, StatusType } from 'common-components';
import SessionContext from 'context/session/sessionContext';
import { format } from 'utils/dates';
import { downloadFile } from 'utils/files';
import { ErrorObj } from 'utils/error-handler'; // eslint-disable-line @typescript-eslint/no-unused-vars
import { DocTableItemProps, Document } from '../interfaces';

const statusMap: Record<number, StatusType> = {
    1: 'pending',
    2: 'pending',
    3: 'rejected',
    4: 'approved',
};

const DocTableItem: FC<DocTableItemProps> = ({
    document,
    onUpdateDocument,
    onDeleteDocument,
    showAlert,
}) => {
    const { selectedCompany } = useContext(SessionContext),
        [loading, setLoading] = useState(false);

    const onDownloadDocument = async (doc: Document) => {
        setLoading(true);
        try {
            const url = `/v1/companies/${selectedCompany.company?.id}/documents/${doc.id}/download`;
            await downloadFile(
                'application/pdf',
                `${doc.documentType.name}.${doc.fileUrl.split('.').pop()?.split('?')[0]}`,
                url,
            );
        } catch (errResponse: ErrorObj | any) {
            showAlert(
                errResponse?.error || 'Hubo un error al descargar el archivo, intente más tarde',
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <TableRow data-testid="files-manager-row">
            <TableCell>
                <Grid container alignItems="center" wrap="nowrap">
                    <Grid item>
                        <Description color="secondary" />
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" color="primary.main" fontWeight={700} ml="5px">
                            {document.documentType.name}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Status
                    variant="outlined"
                    role="presentation"
                    status={statusMap[document.status?.id]}
                >
                    {document?.status?.text}
                </Status>
            </TableCell>
            <TableCell>
                <Typography variant="body1" color="primary.dark" fontWeight={500} noWrap>
                    {format(document.uploadDate, 'd-MMM-yyyy')}
                </Typography>
            </TableCell>
            <TableCell align="center">
                <Tooltip title="Descargar" placement="top" distance={0}>
                    <IconBtn
                        color="secondary"
                        variant="outlined"
                        onClick={() => (loading ? undefined : onDownloadDocument(document))}
                    >
                        {loading ? (
                            <Loader data-testid="legal-doc-download-loader" size={24} />
                        ) : (
                            <GetApp />
                        )}
                    </IconBtn>
                </Tooltip>
                {selectedCompany.role === 'administrador' && (
                    <Tooltip title="Eliminar" placement="top" distance={0}>
                        <IconBtn
                            color="secondary"
                            variant="outlined"
                            onClick={() => onDeleteDocument(document)}
                            disabled={statusMap[document.status?.id] === 'approved'}
                            sx={{ ml: '10px' }}
                        >
                            <Delete />
                        </IconBtn>
                    </Tooltip>
                )}
                {document.status?.id === 3 && (
                    <Tooltip title="Actualizar" placement="top" distance={0}>
                        <IconBtn
                            color="secondary"
                            variant="outlined"
                            onClick={() => onUpdateDocument(document)}
                            sx={{ ml: '10px' }}
                        >
                            <Update />
                        </IconBtn>
                    </Tooltip>
                )}
            </TableCell>
        </TableRow>
    );
};

export default DocTableItem;
