import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Paper, PaperProps, PasswordInput, PasswordInputProps } from '@fairplay2/ui';
import { ManGearAvatar } from 'assets/SVGs';

export const UserCard = styled(Paper)<PaperProps>(({ theme }) => ({
    padding: '20px 25px',
    [theme.breakpoints.up('md')]: {
        paddingRight: 110,
    },
}));

export const UserCardSvg = styled(ManGearAvatar)({
    width: 188,
    height: 'auto',
    position: 'absolute',
    top: 15,
    right: -90,
});

export const ButtonsContainer = styled(Grid)(({ theme }) => ({
    display: 'grid',
    marginBottom: 30,
    gridGap: 16,
    [theme.breakpoints.up('sm')]: {
        marginBottom: 0,
        gridTemplateColumns: 'auto auto',
        justifyContent: 'flex-end',
        gridGap: 25,
    },
    '&.MuiGrid-root': {
        '& > *': {
            minWidth: 115,
        },
    },
}));

export const ChangePasswordInputContainer = styled(Grid)({
    marginTop: 32,
    '&:first-of-type': {
        marginTop: 19,
    },
});

export const ConfirmPasswordInput = styled(PasswordInput)<PasswordInputProps>(({ theme }) => ({
    marginBottom: 16,
    [theme.breakpoints.up('md')]: {
        marginBottom: 0,
    },
}));

export const PasswordButtonsContainer = styled(Grid)(({ theme }) => ({
    display: 'grid',
    marginBottom: 30,
    gridGap: 16,
    [theme.breakpoints.up('sm')]: {
        marginBottom: 0,
        gridTemplateColumns: 'auto auto',
        justifyContent: 'flex-end',
        gridGap: 25,
    },
    '&.MuiGrid-root': {
        '& > *': {
            minWidth: 96,
        },
    },
}));
