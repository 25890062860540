import { Collapse, Grid } from '@mui/material';
import { Typography, Alert } from '@fairplay2/ui';
import { QRCode, QRCodeData } from 'common-components';

interface Step3Props {
    qrData: QRCodeData;
    error?: string;
}

export const Step3 = ({ qrData, error }: Step3Props) => (
    <Grid container>
        <Grid item xs={12}>
            <Typography variant="subtitle2" fontWeight={500}>
                Vincula tu aplicación a Insights escaneando el QR o introduciendo el siguiente
                código
            </Typography>
        </Grid>
        <Grid item xs={12} mt="-2px">
            <QRCode {...qrData} />
        </Grid>
        <Grid item xs={12} mt={2}>
            <Collapse in={!!error}>
                <Alert
                    aria-label="Mensaje de error de código QR"
                    severity="error"
                    sx={{ width: 'fit-content' }}
                >
                    {error}
                </Alert>
            </Collapse>
        </Grid>
    </Grid>
);

export default Step3;
