import { FC, useContext, useState, useCallback, ChangeEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Collapse } from '@mui/material';
import {
    LocationOn,
    FolderSpecial,
    PersonAdd,
    MonetizationOn,
    Security as SecurityIcon,
} from '@mui/icons-material';
import { Typography, Alert, TabGroup, TabData } from '@fairplay2/ui';
import SessionContext from 'context/session/sessionContext';
import { useAlert } from 'utils/hooks';
import AvatarPaper from './components/AvatarPaper';
import EditCompanyName from './components/EditCompanyName';
import CompanyData from './profile';
import Accounts from './accounts';
import CompanyDocuments from './documents';
import CompanyTeam from './team';
import EditIndustry from './components/EditIndustry';
import { Security } from './feature-security';

const TABS_DATA: TabData[] = [
    {
        label: 'Perfil',
        icon: <LocationOn />,
        panelId: 'profile',
    },
    {
        label: 'Datos bancarios',
        icon: <MonetizationOn />,
        panelId: 'bank-data',
    },
    {
        label: 'Documentos legales',
        icon: <FolderSpecial />,
        panelId: 'legal-documents',
    },
    {
        label: 'Equipo',
        icon: <PersonAdd />,
        panelId: 'team',
    },
    {
        label: 'Seguridad',
        icon: <SecurityIcon />,
        panelId: 'security',
    },
];

const Company: FC = () => {
    const { selectedCompany: { company } = {} as any } = useContext(SessionContext),
        { tab }: any = useParams(),
        { alert, hideAlert, showAlert } = useAlert(),
        [activeTab, setActiveTab] = useState(0);

    const onTabChange = useCallback((_: ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    }, []);

    useEffect(() => {
        if (!company?.id) return;
        hideAlert();
    }, [hideAlert, company?.id]);

    useEffect(() => {
        if (tab) {
            const index = TABS_DATA.findIndex(({ panelId }) => panelId === tab);
            setActiveTab((prev) => index ?? prev);
        }
    }, [tab]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h4" component="h2" color="primary.main">
                    Empresa{' '}
                    <Typography component="span" color="primary.dark" fontWeight={700}>
                        {company?.businessName}
                    </Typography>
                </Typography>
            </Grid>

            <AvatarPaper onAlert={showAlert} />

            <Grid container marginTop="10px">
                <Collapse in={alert.open}>
                    {/* Adding aria-hidden since testing-library does not recognize the collapsed styles (it only reads inline styles) */}
                    <Alert severity={alert.type} aria-hidden={!alert.open}>
                        {alert.message}
                    </Alert>
                </Collapse>
            </Grid>
            <Grid container spacing={4} sx={{ marginTop: 0 }} alignItems="flex-end">
                <EditCompanyName onAlert={showAlert} xs={12} sm={6} lg={4} />
                <EditIndustry onAlert={showAlert} xs={12} sm={6} lg={7} />
            </Grid>

            <TabGroup
                TabsProps={{
                    sx: {
                        margin: { xs: '30px 0 20px', sm: '30px 0 20px', md: '30px 0 20px -20px' },
                    },
                }}
                value={activeTab}
                tabs={TABS_DATA}
                onChange={onTabChange}
            >
                <CompanyData />
                <Accounts />
                <CompanyDocuments />
                <CompanyTeam />
                <Security />
            </TabGroup>
        </Grid>
    );
};

export default Company;
