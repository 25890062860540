import { FC, useState, useContext } from 'react';
import { useTheme, useMediaQuery, Grid, Collapse } from '@mui/material';
import { DefaultBtn, Modal, InterpunctLoader, Alert, Typography } from '@fairplay2/ui';
import { ManSittingPlanSvg, ManSittingPlantTwoSvg } from 'assets/SVGs';
import SessionContext from 'context/session/sessionContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import fairplayAPI from 'utils/api';
import { useAlert } from 'utils/hooks';
import { DeleteUserModalProps } from '../interfaces';

const DeleteUserModal: FC<DeleteUserModalProps> = ({
    open,
    onClose,
    selectedUser,
    triggerFetchUsers,
}) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        { user, selectedCompany } = useContext(SessionContext),
        [userHasBeenDeleted, setUserHasBeenDeleted] = useState(false),
        [loading, setLoading] = useState(false),
        { alert, hideAlert, showAlert } = useAlert();

    const onDelete = async () => {
        hideAlert();
        if (selectedUser) {
            if (user?.id === selectedUser.id)
                showAlert('No puedes eliminar tu cuenta de una organización');
            else {
                setLoading(true);
                const data = new FormData();
                data.append('user_id', selectedUser.id);

                try {
                    await fairplayAPI.delete(
                        `/v1/companies/${selectedCompany.company?.id}/collaborators`,
                        {
                            data,
                        },
                    );
                    setUserHasBeenDeleted(true);
                    triggerFetchUsers();
                } catch (errResponse: ErrorObj | any) {
                    showAlert(
                        errResponse?.error || 'No se pudo eliminar el usuario, intente más tarde',
                    );
                } finally {
                    setLoading(false);
                }
            }
        }
    };

    const closeModal = () => {
        setUserHasBeenDeleted(false);
        onClose();
    };

    return (
        <Modal open={open} onClose={closeModal}>
            {userHasBeenDeleted ? (
                <Grid container>
                    <Grid container justifyContent="center">
                        <ManSittingPlantTwoSvg style={{ width: '250px' }} />
                    </Grid>
                    <Typography variant="subtitle1" color="primary.main" mt="30px">
                        Usuario eliminado
                    </Typography>
                    <Typography variant="subtitle2" color="text.primary" fontWeight={500} mt="15px">
                        Se ha eliminado exitosamente a{' '}
                        <Typography component="span" color="primary.dark" fontWeight={700}>
                            {selectedUser?.fullName}
                        </Typography>
                        . Ya no podrá realizar cambios ni ver los movimientos de tu organización.
                    </Typography>

                    <Grid container justifyContent="center" mt="40px">
                        <DefaultBtn size="small" onClick={onClose}>
                            Listo
                        </DefaultBtn>
                    </Grid>
                </Grid>
            ) : (
                <Grid container>
                    <Grid container justifyContent="center">
                        <ManSittingPlanSvg />
                    </Grid>

                    <Typography variant="subtitle1" color="primary.main" mt="30px">
                        ¡Hey! ¿Estás seguro?
                    </Typography>
                    <Typography variant="subtitle2" color="text.primary" fontWeight={500} mt="15px">
                        Estás a punto de eliminar a{' '}
                        <Typography component="span" color="primary.dark" fontWeight={700}>
                            {selectedUser?.fullName}
                        </Typography>{' '}
                        de tu organización. Si lo eliminas perderá accesos a la plataforma de manera
                        permanente.
                    </Typography>

                    <Grid container justifyContent="center" mt="20px">
                        <Collapse in={alert.open}>
                            <Alert severity={alert.type}>{alert.message}</Alert>
                        </Collapse>
                    </Grid>

                    <Grid container justifyContent="center" mt="20px">
                        {loading ? (
                            <InterpunctLoader />
                        ) : (
                            <>
                                <DefaultBtn
                                    fullWidth={smDown}
                                    size="small"
                                    variant="outlined"
                                    onClick={closeModal}
                                    sx={{
                                        mb: { xs: '20px', sm: 0 },
                                    }}
                                >
                                    Ok, no
                                </DefaultBtn>
                                <DefaultBtn
                                    fullWidth={smDown}
                                    size="small"
                                    onClick={onDelete}
                                    sx={{
                                        ml: { xs: 0, sm: '20px' },
                                    }}
                                >
                                    Sí, elimina
                                </DefaultBtn>
                            </>
                        )}
                    </Grid>
                </Grid>
            )}
        </Modal>
    );
};

export default DeleteUserModal;
