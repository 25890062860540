import { ReactNode } from 'react';
import {
    Step,
    StepLabel,
    Stepper,
    Grid,
    MobileStepper,
    useTheme,
    useMediaQuery,
    styled,
} from '@mui/material';
import { DefaultBtn, IconBtn, InterpunctLoader, Typography } from '@fairplay2/ui';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { HorizontalStepperProps, MobileStepperBtnProps } from './HorizontalStepper.types';

const BASE_CLASSNAME = 'InsightsHorizontalStepper';

const StyledMobileStepper = styled(MobileStepper)(({ theme }) => ({
    border: 'none',
    boxShadow: 'none',
    padding: 0,
    '&.MuiMobileStepper-root': {
        color: theme.palette.primary.dark,
        fontWeight: 500,
    },
}));

const MobileStepperBtn = styled(({ buttonText, direction, ...props }: MobileStepperBtnProps) => (
    <IconBtn {...props}>
        {direction === 'left' && <KeyboardArrowLeft color="secondary" />}
        <Typography variant="body1">{buttonText}</Typography>
        {direction === 'right' && <KeyboardArrowRight color="secondary" />}
    </IconBtn>
))(({ theme }) => ({
    padding: 0,
    '& .MuiTypography-root': {
        color: theme.palette.primary.main,
        fontWeight: 500,
        marginLeft: 1,
    },
}));

const StyledStepLabel = styled(StepLabel, {
    shouldForwardProp: (propName: string) => !['index', 'activeStep', 'sx'].includes(propName),
})<{ index: number; activeStep: number }>(({ index, activeStep, theme }) => ({
    '& .MuiTypography-root': {
        color: index <= activeStep ? theme.palette.primary.dark : 'inherit',
        fontWeight: index <= activeStep ? 700 : 500,
    },
}));

const StepsContainer = styled(Grid)(({ theme }) => ({
    [`.${BASE_CLASSNAME}-content`]: {
        minHeight: '400px',
    },
    [theme.breakpoints.down('md')]: {
        [`.${BASE_CLASSNAME}-content`]: {
            minHeight: 'auto',
        },
    },
}));

const StepContent = ({ activeStep, children }: { children: ReactNode; activeStep: number }) => (
    <div className={`${BASE_CLASSNAME}-content`}>
        {children || `No content provided for step ${activeStep + 1}`}
    </div>
);

const getForwardBtnText = (
    isLastStep: boolean,
    isPrevToLast: boolean | null,
    texts: Exclude<HorizontalStepperProps['forwardStepButtonTexts'], undefined>,
) => {
    if (isLastStep) return texts.stepsCompleted;
    if (typeof isPrevToLast === 'boolean' && isPrevToLast) return texts.prevCompletedSteps;

    return texts.default;
};

export const HorizontalStepper = ({
    activeStep,
    stepsTitles,
    goForwardOnly,
    children,
    loading,
    onStepBackward,
    onStepForward,
    backStepButtonText = 'Atrás',
    hasCompletedScreen = false,
    forwardStepButtonTexts = {
        default: 'Siguiente',
        prevCompletedSteps: 'Validar',
        stepsCompleted: 'Finalizar',
    },
}: HorizontalStepperProps) => {
    const theme = useTheme(),
        mdDown = useMediaQuery(theme.breakpoints.down('md')),
        stepsAreCompleted = activeStep === stepsTitles.length,
        showBackButton = activeStep !== 0 && !stepsAreCompleted && !goForwardOnly && !loading,
        stepContent = children[activeStep],
        forwardButtonText = getForwardBtnText(
            stepsAreCompleted,
            hasCompletedScreen ? activeStep === stepsTitles.length - 1 : null,
            forwardStepButtonTexts,
        );

    return (
        <Grid item container xs={12} gap={3}>
            {mdDown ? (
                <>
                    {activeStep !== stepsTitles.length && (
                        <Grid item xs={12}>
                            <Typography variant="body1" color="primary.dark" fontWeight={700}>
                                {stepsTitles[activeStep]}
                            </Typography>
                        </Grid>
                    )}
                    <StepsContainer item xs={12}>
                        <StepContent activeStep={activeStep}>{stepContent}</StepContent>
                    </StepsContainer>
                    <Grid item xs={12}>
                        {loading ? (
                            <Grid item container xs={12} justifyContent="center">
                                <InterpunctLoader height={35} />
                            </Grid>
                        ) : (
                            <StyledMobileStepper
                                variant="text"
                                steps={stepsTitles.length}
                                position="static"
                                activeStep={
                                    activeStep - Number(hasCompletedScreen && stepsAreCompleted)
                                }
                                backButton={
                                    <MobileStepperBtn
                                        onClick={onStepBackward}
                                        disabled={
                                            activeStep === 0 || activeStep === stepsTitles.length
                                        }
                                        buttonText={backStepButtonText}
                                        direction="left"
                                    />
                                }
                                nextButton={
                                    <MobileStepperBtn
                                        onClick={onStepForward}
                                        buttonText={forwardButtonText}
                                        direction="right"
                                    />
                                }
                            />
                        )}
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item xs={12}>
                        <Stepper activeStep={activeStep}>
                            {stepsTitles.map((label, index) => (
                                <Step key={label}>
                                    <StyledStepLabel index={index} activeStep={activeStep}>
                                        <Typography variant="body1">{label}</Typography>
                                    </StyledStepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>

                    <Grid item container xs={12} gap="inherit" ml="8px">
                        <StepsContainer item xs={12}>
                            <StepContent activeStep={activeStep}>{stepContent}</StepContent>
                        </StepsContainer>
                        <Grid
                            item
                            container
                            xs={12}
                            justifyContent={showBackButton ? 'space-between' : 'flex-end'}
                        >
                            {showBackButton && (
                                <Grid item>
                                    <DefaultBtn
                                        variant="outlined"
                                        size="small"
                                        onClick={onStepBackward}
                                    >
                                        {backStepButtonText}
                                    </DefaultBtn>
                                </Grid>
                            )}
                            <Grid item>
                                {loading ? (
                                    <Grid item>
                                        <InterpunctLoader height={35} />
                                    </Grid>
                                ) : (
                                    <DefaultBtn onClick={onStepForward} size="small">
                                        {forwardButtonText}
                                    </DefaultBtn>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default HorizontalStepper;
