export const handleUtmParams = (url: URL = new URL(window.location.href)) => {
    const utmSource = url.searchParams.get('utm_source');
    const utmMedium = url.searchParams.get('utm_medium');
    const utmCampaign = url.searchParams.get('utm_campaign');

    // If params are not Null, then they are added to utmParams Object
    const utmParams = {
        ...(utmSource && { utmSource }),
        ...(utmMedium && { utmMedium }),
        ...(utmCampaign && { utmCampaign }),
    };

    // If utmParams Object has at least one value, then save it in localStorage
    if (Object.values(utmParams).length)
        localStorage.setItem('utm_params', JSON.stringify(utmParams));
};

export const isValidParamValue = (value: any): boolean => {
    if (value === undefined || typeof value === 'undefined') return false;
    if (value === null) return false;
    if (Number.isNaN(value)) return false;
    if (typeof value === 'object') return false;
    if (typeof value === 'function') return false;
    if (typeof value === 'symbol') return false;
    if (value === '') return false;
    return true;
};

export const filterInvalidParams = (params: { [key: string]: any }): any => {
    const filteredParams: any = {};
    Object.entries(params || {}).forEach(([key, value]) => {
        if (isValidParamValue(value)) filteredParams[key] = value;
    });
    return filteredParams;
};

export const toParamsString = (params: { [key: string]: any }): string => {
    const paramsString = new URLSearchParams(filterInvalidParams(params)).toString();
    return paramsString ? `?${paramsString}` : '';
};
