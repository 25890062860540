import { useMediaQuery, useTheme } from '@mui/material';
import { Footer as FpFooter, FooterProps } from '@fairplay2/ui';
import { Certificates } from './certificates/Certificates.component';

export const Footer = ({
    BoxProps: { bgcolor = 'primary.dark', ...BoxProps } = {},
    ...props
}: FooterProps) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <FpFooter
            BoxProps={{ bgcolor, ...BoxProps }}
            BeforeBoxSlot={
                props.BeforeBoxSlot || (
                    <Certificates
                        useCompact={smDown}
                        marginBottom={{ xs: '8px', md: '23px' }}
                        sx={smDown ? { bgcolor: 'primary.dark', p: 1 } : undefined}
                    />
                )
            }
            {...props}
        />
    );
};

export default Footer;
