import { useState, useEffect, FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Collapse, useTheme, useMediaQuery } from '@mui/material';
import {
    DefaultBtn,
    Typography,
    Alert,
    ToggleBtn,
    InterpunctLoader,
    Autocomplete,
    Skeleton,
    NationalIcon,
} from '@fairplay2/ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import fairplayAPI from 'utils/api';
import { dataSourceRedirection } from 'components/dashboard/data-sources/utils';
import { useAlert } from 'utils/hooks';
import { AMAZON_COUNTRIES } from './constants';
import { Step1Props, CountryAmazon, CountryMercado } from './interfaces';

const TOGGLE_BTN_SX = {
    marginTop: '30px',
    marginRight: { xs: 0, sm: '60px' },
    width: 173,
    height: 152,
    cursor: 'default',
};

const Step1: FC<Step1Props> = ({ groupKey, connector }) => {
    const theme = useTheme(),
        { pathname } = useLocation(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        [selectedCountry, setSelectedCountry] = useState<CountryAmazon | CountryMercado | null>(
            null,
        ),
        [countries, setCountries] = useState<any[] | undefined>(undefined),
        [loading, setLoading] = useState(false),
        { alert, hideAlert, showAlert } = useAlert();

    const connectToAmazon = async () => {
        const url = `/v1/authentication/auth-connector?platform=amazon${
            pathname === '/app/onboarding' ? '&onboarding=true' : ''
        }`;
        hideAlert();

        try {
            const response: any = await fairplayAPI.get(url);
            dataSourceRedirection.create(response.data.body.results.url, {
                from: 'amazon',
                connector: connector?.id,
                extra: {
                    countryCode: (selectedCountry as CountryAmazon).countryCode,
                },
            });
        } catch (errResponse: ErrorObj | any) {
            setLoading(false);
            showAlert(errResponse?.error || 'Hubo un error al obtener la URL de Amazon');
        }
    };

    const connectToMercado = async () => {
        if (selectedCountry) {
            const url = `/v1/authentication/auth-connector?country=${
                (selectedCountry as CountryMercado).country.isoAlpha2
            }&platform=mercado-libre`;
            hideAlert();

            try {
                const response: any = await fairplayAPI.get(url);
                dataSourceRedirection.create(response.data.body.results.url, {
                    from: 'mercado-libre',
                    to: pathname === '/app/onboarding' ? '/app/onboarding' : undefined,
                    connector: connector?.id,
                });
            } catch (errResponse: ErrorObj | any) {
                setLoading(false);
                showAlert(errResponse?.error || 'Hubo un error al obtener la URL de Mercado');
            }
        }
    };

    const onCountry = (value: CountryAmazon | CountryMercado) => setSelectedCountry(value);

    const onNext = () => {
        setLoading(true);
        if (groupKey === 'amazon') connectToAmazon();
        if (groupKey === 'mercado-libre') connectToMercado();
    };
    const getOptionLabel = (option: any) => {
        if (groupKey === 'amazon') return option?.name || '';
        if (groupKey === 'mercado-libre') return option?.country?.name || '';
        return '';
    };

    useEffect(() => {
        const fetchMercadoCountries = async () => {
            try {
                const res: any = await fairplayAPI.get('/v1/locations/countries/mercado-libre');
                setCountries(res.data.body.results);
            } catch {
                showAlert('No se pudieron cargar los países');
            }
        };

        if (groupKey === 'amazon') setCountries(AMAZON_COUNTRIES);
        if (groupKey === 'mercado-libre') fetchMercadoCountries();
    }, [groupKey, showAlert]);

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Typography variant="subtitle2" color="primary.dark" fontWeight={700}>
                    ¿En dónde opera tu tienda?
                    <Typography component="span" color="primary.main" fontWeight={400}>
                        {' '}
                        Selecciona el país
                    </Typography>
                </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
                {!countries ? (
                    <Skeleton height={53} />
                ) : (
                    <Autocomplete
                        label="País de la tienda"
                        options={countries}
                        getOptionLabel={getOptionLabel}
                        value={selectedCountry}
                        placeholder="Selecciona el país"
                        onChange={(_, newValue) => onCountry(newValue)}
                    />
                )}
            </Grid>

            {groupKey === 'amazon' && (
                <>
                    <Grid item xs={12} paddingBottom={0}>
                        <Typography variant="subtitle2" color="primary.main" fontWeight={400}>
                            Esta es la región de tu tienda
                        </Typography>
                    </Grid>

                    <Grid item container xs={12} justifyContent="center" pt="0 !important">
                        <ToggleBtn
                            value="us-east-1"
                            selected={(selectedCountry as CountryAmazon)?.region === 'us-east-1'}
                            sx={TOGGLE_BTN_SX}
                            aria-disabled
                        >
                            <Grid container justifyContent="center">
                                <NationalIcon aria-hidden height="75px" />
                                <Grid item xs={12} marginTop={2.5}>
                                    <Typography variant="subtitle1" color="primary.main">
                                        Norteamérica
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ToggleBtn>

                        <ToggleBtn
                            value="eu-west-1"
                            selected={(selectedCountry as CountryAmazon)?.region === 'eu-west-1'}
                            sx={TOGGLE_BTN_SX}
                            aria-disabled
                        >
                            <Grid container justifyContent="center">
                                <NationalIcon aria-hidden height="75px" />
                                <Grid item xs={12} marginTop={2.5}>
                                    <Typography variant="subtitle1" color="primary.main">
                                        Europa
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ToggleBtn>

                        <ToggleBtn
                            value="us-west-2"
                            selected={(selectedCountry as CountryAmazon)?.region === 'us-west-2'}
                            sx={TOGGLE_BTN_SX}
                            aria-disabled
                        >
                            <Grid container justifyContent="center">
                                <NationalIcon aria-hidden height="75px" />
                                <Grid item xs={12} marginTop={2.5}>
                                    <Typography variant="subtitle1" color="primary.main">
                                        Lejano Oriente
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ToggleBtn>
                    </Grid>
                </>
            )}

            <Grid item container xs={12} justifyContent="center" py="0 !important">
                <Collapse in={alert.open}>
                    <Grid container item xs={12}>
                        <Alert severity={alert.type} sx={{ mt: 3, mb: -3 }}>
                            {alert.message}
                        </Alert>
                    </Grid>
                </Collapse>
            </Grid>

            <Grid item container xs={12} justifyContent={['center', 'flex-end']} marginTop={1.25}>
                {loading ? (
                    <InterpunctLoader />
                ) : (
                    <DefaultBtn
                        disabled={!selectedCountry}
                        fullWidth={smDown}
                        size="small"
                        onClick={onNext}
                        sx={{
                            ml: [0, '20px'],
                            mt: ['20px', 0],
                        }}
                    >
                        Siguiente
                    </DefaultBtn>
                )}
            </Grid>
        </Grid>
    );
};

export default Step1;
