import { useState, FC } from 'react';
import { useLocation } from 'react-router-dom';
import AdsImg from 'assets/img/connectors/Google_ads_logo.png';
import AnalyticsImg from 'assets/img/connectors/Google_analytics_logo.png';
import fairplayAPI from 'utils/api';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { toFormData } from 'utils/parsing';
import { dataSourceRedirection, getPlatformDisplayName } from '../../utils';
import BasePlatformItem from '../components/BasePlatformItem';
import { ConcretePlatformItemProps } from '../interfaces';
import useCodeInterceptor from '../useCodeInterceptor';
import { useDataSources } from '../../context/DataSourcesContext';
import AdsWizard from '../components/wizard-advertising';
import AnalyticsWizard from '../components/wizard-analytics';
import {
    ConnectorData,
    InterceptedParams,
    OnCodeInterception,
    RawPlatformKey,
} from '../../interfaces';
import { PartialConnectorData } from '../../context/interfaces';

const REDIRECTION_PARAMS = ['state', 'code', 'scope'];

const getAuthUrl = (pathname: string) => {
    let url = '/v1/authentication/auth-connector?platform=google';
    if (pathname === '/app/onboarding') url += '&onboarding=true';

    return url;
};

const BaseGoogleItem: FC<
    ConcretePlatformItemProps & { type: 'google-ads' | 'google-analytics' }
> = ({ notify, type }) => {
    const { baseAPI, dispatch } = useDataSources(),
        [loading, setLoading] = useState(false),
        [showWizard, setShowWizard] = useState<boolean | ConnectorData>(false),
        { pathname } = useLocation(),
        Wizard = type === 'google-ads' ? AdsWizard : AnalyticsWizard;

    const onConnect = async () => {
        setLoading(true);

        try {
            const response: any = await fairplayAPI.get(getAuthUrl(pathname));
            dataSourceRedirection.create(response.data.body.results.url, {
                from: type,
            });
        } catch (errResponse: ErrorObj | any) {
            setLoading(false);
            notify(
                errResponse?.error ||
                    'Ocurrió un error al generar la conexión con Google, intente más tarde',
                'error',
            );
        }
    };

    const onReauth = async ({ id, platform }: ConnectorData) => {
        const response: any = await fairplayAPI.get(getAuthUrl(pathname));
        dataSourceRedirection.create(response.data.body.results.url, {
            from: type,
            connector: id,
            extra: { platform },
        });

        return response;
    };

    const createConnector = async (
        { code, state, scope }: InterceptedParams,
        connector?: PartialConnectorData,
    ) => {
        if (!code || !state || !scope) return;

        // /reauth endpoint appends this value to the response
        const data = toFormData({
                platform: connector?.platform || 'google',
                code,
                ...(pathname === '/app/onboarding' && { onboarding: 'true' }),
            }),
            endpoint = `${baseAPI}${connector ? `/${connector.id}/reconnect` : ''}`;
        try {
            const response: any = await fairplayAPI.post(endpoint, data);
            const payload = response.data.body.results;
            const itemToShow = Array.isArray(payload)
                ? payload.find((item: any) => {
                      if (type === 'google-ads') return item?.platform === 'google-ads-v2';

                      return item?.platform === 'google-analytics-v4';
                  })
                : payload || {};

            dispatch({
                type: connector ? 'UPDATE' : 'ADD',
                payload,
                onCommit: () => {
                    if (connector)
                        notify(
                            `Se ha reconectado ${getPlatformDisplayName(type)} con éxito`,
                            'success',
                        );
                    else setShowWizard(itemToShow);
                },
            });
        } catch (errResponse: ErrorObj | any) {
            notify(
                errResponse?.error ||
                    'Ocurrió un error al generar la conexión con Google, intente más tarde',
                'error',
            );
        } finally {
            setLoading(false);
        }
    };

    const onCodeInterception: OnCodeInterception = (foundParams, { connector, extra }) => {
        // Redirection just began and react triggered a re-render that
        //  caused the hook to be executed
        if (loading) return false;

        setLoading(true);
        createConnector(
            foundParams,
            typeof connector === 'string'
                ? { id: connector, platform: (extra as any).platform as RawPlatformKey }
                : connector,
        );
        return true;
    };

    useCodeInterceptor(type, REDIRECTION_PARAMS, onCodeInterception);

    return (
        <BasePlatformItem
            picture={type === 'google-ads' ? AdsImg : AnalyticsImg}
            groupKey={type}
            showLoader={loading}
            notify={notify}
            onConnect={onConnect}
            onReauth={onReauth}
        >
            <Wizard
                open={!!showWizard}
                element={showWizard}
                onClose={() => setShowWizard(false)}
                groupKey={type}
            />
        </BasePlatformItem>
    );
};

export default BaseGoogleItem;
