import { FC } from 'react';
import { Skeleton } from '@fairplay2/ui';
import { Grid } from '@mui/material';

const AccumulatedSkeleton: FC = () => (
    <Grid container mt="10px">
        <Skeleton width="100%" height={115} />
        <Grid item container mt="30px" alignItems="center">
            <Grid item xs={12}>
                <Skeleton variant="text" height={20} sx={{ width: { xs: '280px', md: '480px' } }} />
            </Grid>
            <Grid item xs={12} md={5} mt="30px" pb={{ xs: 2, lg: 0 }}>
                <Grid item container justifyContent="center">
                    <Skeleton
                        variant="circular"
                        sx={{
                            height: { xs: '300px', md: '400px' },
                            width: { xs: '300px', md: '400px' },
                        }}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} md={7}>
                <Skeleton variant="rectangular" width="100%" height={400} />
            </Grid>
        </Grid>
        <Grid item container mt="30px">
            <Grid item xs={12}>
                <Skeleton variant="text" height={20} sx={{ width: { xs: '280px', md: '480px' } }} />
            </Grid>
            <Grid item xs={12} mt="30px">
                <Skeleton variant="rectangular" width="100%" height={446.5} />
            </Grid>
        </Grid>
        <Grid item container gap={1} mt="30px">
            <Skeleton variant="text" width={480} height={20} />
            <Skeleton variant="text" width={150} height={20} />
        </Grid>
    </Grid>
);

export default AccumulatedSkeleton;
