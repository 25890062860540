import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { ManSittingWithFilesAndCheckSvg } from 'assets/SVGs';
import { Typography, DefaultBtn } from '@fairplay2/ui';
import { Grid } from '@mui/material';

const EmailConfirmed: FC = () => {
    const history = useHistory();

    return (
        <Grid
            item
            container
            mt={{ md: '25%' }}
            mb={6}
            justifyContent="center"
            direction="column"
            gap={2}
            sx={{
                width: '100%',
                maxWidth: { md: '420px', lg: '460px' },
            }}
        >
            <Grid
                item
                container
                justifyContent={{
                    xs: 'center',
                    md: 'left',
                }}
            >
                <ManSittingWithFilesAndCheckSvg />
            </Grid>
            <Grid item container direction="column" gap={2}>
                <Grid item>
                    <Typography variant="h3" fontWeight={700}>
                        ¡Correo electrónico confirmado!
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2" color="primary.dark" fontWeight={700}>
                        Termina tu registro iniciando sesión con tu correo electrónico y contraseña.
                    </Typography>
                </Grid>

                <Grid item>
                    <DefaultBtn
                        onClick={() => history.push('/')}
                        fullWidth
                        sx={{
                            maxWidth: { md: '300px' },
                        }}
                    >
                        Iniciar sesión
                    </DefaultBtn>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EmailConfirmed;
