import { FC, useContext, useState } from 'react';
import { useTheme, useMediaQuery, Grid, Collapse } from '@mui/material';
import { Modal, DefaultBtn, InterpunctLoader, Typography, Alert } from '@fairplay2/ui';
import SessionContext from 'context/session/sessionContext';
import { ManSittingPlanSvg } from 'assets/SVGs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import fairplayAPI from 'utils/api';
import { useAlert } from 'utils/hooks';
import { DeleteModalProps } from '../interfaces';

const DeleteModal: FC<DeleteModalProps> = ({
    open,
    onClose,
    onOpenModal,
    fetchAccounts,
    account,
}) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        { selectedCompany } = useContext(SessionContext),
        [loading, setLoading] = useState(false),
        { alert, hideAlert, showAlert } = useAlert();

    const onConfirmDelete = async () => {
        hideAlert();
        if (account !== null) {
            setLoading(true);
            try {
                await fairplayAPI.delete(
                    `/v1/companies/${selectedCompany.company?.id}/accounts/${account.id}`,
                );
                setLoading(false);
                onOpenModal('confirm');
                fetchAccounts();
            } catch (errResponse: ErrorObj | any) {
                setLoading(false);
                if (errResponse.type === 'form') {
                    const errors = Object.values(errResponse.error);
                    showAlert(errors[0] as string);
                } else showAlert(errResponse?.error);
            }
        }
    };

    return (
        <Modal open={open} maxWidth={680} onClose={() => onClose('delete')}>
            <Grid container>
                <Grid container justifyContent="center">
                    <ManSittingPlanSvg />
                </Grid>
                <Typography variant="subtitle1" color="primary.main" mt="30px">
                    ¡Hey! ¿Estás seguro?
                </Typography>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="text.primary" fontWeight={500} mt="10px">
                        Estás a punto de eliminar la cuenta clabe:
                        <br />
                        <Typography component="span" color="primary.dark" fontWeight={700}>
                            {account?.clabe}{' '}
                            <Typography component="span" color="text.primary" fontWeight={500}>
                                de
                            </Typography>{' '}
                            {account?.company}
                        </Typography>{' '}
                        de tus cuentas bancarias asociadas.
                        <br /> Si lo haces no podrás recuperarla.
                    </Typography>
                </Grid>
                <Grid container justifyContent="center" marginTop={alert.open ? 2 : 0}>
                    <Collapse in={alert.open}>
                        <Alert severity={alert.type}>{alert.message}</Alert>
                    </Collapse>
                </Grid>
                <Grid container justifyContent="center" mt="20px">
                    {loading ? (
                        <InterpunctLoader />
                    ) : (
                        <>
                            <DefaultBtn
                                fullWidth={smDown}
                                sx={{
                                    mr: { xs: 0, sm: '20px' },
                                }}
                                size="small"
                                variant="outlined"
                                onClick={() => onClose('delete')}
                            >
                                Ok, ¡no!
                            </DefaultBtn>
                            <DefaultBtn
                                fullWidth={smDown}
                                sx={{
                                    mt: { xs: '20px', sm: 0 },
                                }}
                                size="small"
                                onClick={onConfirmDelete}
                            >
                                Sí, eliminar
                            </DefaultBtn>
                        </>
                    )}
                </Grid>
            </Grid>
        </Modal>
    );
};

export default DeleteModal;
