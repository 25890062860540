import { useContext } from 'react';
import sessionContext from 'context/session/sessionContext';

/**
 * isAdmin indicates the user has edit card permissions,
 * granted either as a legal representative or a super admin.
 */
export const useIsAdmin = () => {
    const { selectedCompany } = useContext(sessionContext);
    const isAdmin = !!(selectedCompany.is_legal_representative || selectedCompany.is_pomelo_admin);

    return isAdmin;
};
