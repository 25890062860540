import { FC, useEffect, useContext, useState, useMemo } from 'react';
import { Grid, Collapse } from '@mui/material';
import { Alert, Loader, Typography } from '@fairplay2/ui';
import { SessionContext } from 'context/session/sessionContext';
import fairplayAPI from 'utils/api';
import { addGoogleTag } from 'utils/google-tag-manager';
import DataSourcesGrid from 'components/dashboard/data-sources/components/DataSourcesGrid';
import { ManShowingMetrics } from 'assets/SVGs';
import { useDataSources } from 'components/dashboard/data-sources/context/DataSourcesContext';
import { groupPlatformKey } from 'components/dashboard/data-sources/context/utils';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { useAlert } from 'utils/hooks';
import { GroupedPlatformKey, RawPlatformKey } from 'components/dashboard/data-sources/interfaces';
import { getPlatformDisplayName } from 'components/dashboard/data-sources/utils';
import { ORDERS_PLATFORMS } from 'components/dashboard/data-sources/platforms/constants';
import { OnboardingStepProps } from './interfaces'; // ConnectorsTypes

// Edge case (only during development): User has a non-sales source
const PLATFORMS_TO_DISPLAY = [...ORDERS_PLATFORMS.filter((platform) => platform !== 's3'), 'sat'];

const Connectors: FC<OnboardingStepProps> = ({ onNext }) => {
    const { getCompanies, selectedCompany } = useContext(SessionContext),
        { platforms } = useDataSources(),
        connectedSourcesString = Object.entries(platforms).reduce((accString, [key, value]) => {
            if (!value) return accString;
            if (!value.connectors.length || !PLATFORMS_TO_DISPLAY.includes(key))
                return accString || '';
            return `${accString ? `${accString}, ` : ''}${getPlatformDisplayName(
                key as GroupedPlatformKey,
            )}`;
        }, undefined as undefined | string),
        [loading, setLoading] = useState(true),
        { alert, hideAlert, showAlert } = useAlert(),
        previousSelectedConnectors = useMemo(() => {
            const salesSources = selectedCompany.company?.sales_sources || [];

            // Convert string array to RawPlatformKey array
            const rawPlatformKeys: RawPlatformKey[] = salesSources
                .filter((source) => source !== 'own-ecommerce')
                .map((source) => source as RawPlatformKey);

            // Map RawPlatformKeys to GroupedPlatformKeys
            const groupedPlatformKeys: GroupedPlatformKey[] = rawPlatformKeys.map(
                (source: RawPlatformKey) => groupPlatformKey(source),
            );

            return groupedPlatformKeys;
        }, [selectedCompany.company?.sales_sources]);

    const onFinishOnboarding = async () => {
        hideAlert();
        setLoading(true);

        try {
            // change step to 4 (complete)
            await fairplayAPI.patch('/v1/users/update', { step: 4 });
            addGoogleTag('onboarding-connectors');
            onNext();
        } catch (errResponse: ErrorObj | any) {
            showAlert(errResponse?.error || 'Ocurrió un error, intente más tarde');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getCompanies();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (typeof connectedSourcesString !== 'undefined') setLoading(false);
    }, [connectedSourcesString]);

    return (
        <Grid
            container
            gap={6}
            direction="column"
            sx={{
                maxWidth: { md: '728px' },
            }}
        >
            <Grid item container direction="column">
                <Grid item marginBottom={2}>
                    <Typography variant="h3" color="primary.dark">
                        Conecta{' '}
                        <Typography component="span" color="secondary.main">
                            fuentes de órdenes
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2" color="primary.dark" fontWeight={500}>
                        Para continuar el proceso es necesario que conectes al menos una fuente de
                        órdenes.{' '}
                        <Typography component="span" color="primary.main" fontWeight={700}>
                            <a
                                style={{
                                    color: 'inherit',
                                }}
                                target="_blank"
                                href="https://guides.getfairplay.com/es-ES/knowledgebase/category/?id=CAT-01013"
                                rel="noopener noreferrer"
                            >
                                Consulta la guía de conexión
                            </a>
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>

            <Grid item container>
                <Grid
                    item
                    container
                    xs={12}
                    md={4}
                    justifyContent="center"
                    mr={{ xs: 0, md: 4 }}
                    mb={{ xs: 3, lg: 0 }}
                >
                    <ManShowingMetrics />
                </Grid>
                <Grid
                    item
                    container
                    direction="column"
                    xs={12}
                    md={7}
                    gap={1}
                    sx={{
                        maxWidth: { lg: '400px' },
                    }}
                >
                    <Grid item>
                        <Typography variant="subtitle1" color="primary.main" fontWeight={500}>
                            ¿Para qué conecto una fuente de órdenes?
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" color="text.primary" fontWeight={400}>
                            Aprovecha al máximo nuestra plataforma conectando tus fuentes de
                            órdenes.{' '}
                            <Typography component="span" color="primary.main">
                                Podrás consultar métricas importantes de tu negocio{' '}
                            </Typography>
                            y medir su desempeño.
                        </Typography>
                    </Grid>
                    <Grid item mt={1}>
                        <Typography variant="subtitle2" color="text.primary" fontWeight={400}>
                            Además, con tu información de ventas actualizada,{' '}
                            <Typography component="span" color="primary.main">
                                Fairplay podrá ofrecerte una propuesta de financiamiento ajustada{' '}
                            </Typography>
                            a tus necesidades y crecimiento.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid item container xs={12} gap={2} justifyContent={{ xs: 'left', md: 'center' }}>
                    <Grid item>
                        <Typography variant="subtitle1" color="primary.main" fontWeight={500}>
                            Conecta al menos uno de tus marketplaces
                        </Typography>
                    </Grid>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {loading ? (
                        <Grid item container xs={12} justifyContent="center">
                            <Grid item>
                                <Loader />
                            </Grid>
                        </Grid>
                    ) : connectedSourcesString ? (
                        <Grid item>
                            <Typography variant="subtitle2" color="text.primary" fontWeight={400}>
                                Ya conectaste{' '}
                                <Typography component="span" color="primary.dark" fontWeight={700}>
                                    {connectedSourcesString}
                                </Typography>
                                , puedes conectar{' '}
                                {connectedSourcesString === 'SAT'
                                    ? 'uno de tus Marketplaces'
                                    : 'otro Marketplace'}{' '}
                                o{' '}
                                <Typography
                                    component="span"
                                    color="primary.main"
                                    fontWeight={700}
                                    onClick={onFinishOnboarding}
                                    sx={{
                                        display: { xs: 'inline', lg: 'table' },
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                    }}
                                >
                                    finalizar tu proceso aquí
                                </Typography>
                            </Typography>
                        </Grid>
                    ) : null}
                </Grid>
                <DataSourcesGrid platforms={previousSelectedConnectors} justifyContent="center" />
            </Grid>
            <Collapse in={alert.open}>
                <Grid container justifyContent="right" alignItems="center" item xs={12}>
                    <Alert variant="filled" severity={alert.type}>
                        {alert.message}
                    </Alert>
                </Grid>
            </Collapse>
        </Grid>
    );
};

export default Connectors;
