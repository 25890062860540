import { FC, useState, useEffect, ChangeEvent, SyntheticEvent } from 'react';
import { SxProps } from '@mui/system';
import { Grid, MenuItem, Collapse, useTheme, useMediaQuery } from '@mui/material';
import {
    DefaultBtn,
    SearchInput,
    Select,
    Typography,
    Alert,
    NoResults,
    InterpunctLoader,
    Skeleton,
} from '@fairplay2/ui';
import { itemsContainerSx } from 'components/dashboard/data-sources/platforms/components/styles';
import fairplayAPI from 'utils/api';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj, getMainError } from 'utils/error-handler';
import { useAlert } from 'utils/hooks';
import { toFormData } from 'utils/parsing';
import { useDataSources } from '../../../context/DataSourcesContext';
import CheckboxListItem from './CheckboxListItem';
import LoadersAccounts from './LoadersAccounts';
import { ConfigureConnectorProps } from './interfaces';

const ConfigureFB: FC<ConfigureConnectorProps> = ({ element, onConfirm, onClose }) => {
    const { baseAPI, dispatch } = useDataSources(),
        theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        [search, setSearch] = useState(''),
        [sort, setSort] = useState(''),
        [loading, setLoading] = useState(false),
        [accounts, setAccounts] = useState([]),
        [FilteredAccounts, setFilteredAccounts] = useState<Array<any> | undefined>(undefined),
        [checked, setChecked] = useState<{ [k: string]: boolean }>({}),
        { alert, hideAlert, showAlert } = useAlert(),
        loadAccounts = FilteredAccounts === undefined;

    const onSeacrh = (event: ChangeEvent<HTMLInputElement>) => {
        if (!FilteredAccounts) return;
        setSearch(event.target.value);
        const filteredAccounts = accounts.filter(
            (item: any) =>
                item.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.name.toLowerCase().includes(event.target.value.toLowerCase()),
        );
        setFilteredAccounts(filteredAccounts);
    };

    const onSort = (event: ChangeEvent<HTMLInputElement>) => {
        if (!FilteredAccounts) return;

        setSort(event.target.value);
        if (event.target.value === 'asc')
            FilteredAccounts.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
        else if (event.target.value === 'desc')
            FilteredAccounts.sort((a, b) => {
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return -1;
                }
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
    };

    const onSave = async (e: SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setLoading(true);
        hideAlert();

        const selected: any = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const property in checked) {
            if (checked[property]) selected.push(property);
        }

        if (selected.length === 0) {
            setLoading(false);
            showAlert('Debes seleccionar al menos una cuenta');
            return;
        }

        const result = accounts.filter((account: any) => selected.includes(account.accountId));

        try {
            const res: any = await fairplayAPI.patch(
                `${baseAPI}/${element.id}`,
                toFormData({ accounts: JSON.stringify(result) }),
            );
            onConfirm();
            dispatch({ type: 'UPDATE', payload: res.data.body.results });
        } catch (errResponse: ErrorObj | any) {
            if (errResponse?.error?.accounts) showAlert(getMainError(errResponse.error.accounts));
            else showAlert(errResponse?.error);
        } finally {
            setLoading(false);
        }
    };

    const onCheck = (event: ChangeEvent<HTMLInputElement>) => {
        setChecked((prev) => ({ ...prev, [event.target.value]: event.target.checked }));
    };

    useEffect(() => {
        if (!element?.id) return;
        hideAlert();
        if (baseAPI) {
            (async () => {
                try {
                    const response: any = await fairplayAPI.get(
                        `${baseAPI}/${element.id}/accounts`,
                    );
                    setAccounts(response.data.body.results || []);
                    setFilteredAccounts(response.data.body.results || []);
                    const newValues: any = {};
                    // create check variable for each account
                    response.data.body.results.forEach((account: any) => {
                        newValues[`${account.accountId}`] = false;
                    });
                    setChecked(newValues);
                } catch (errResponse: ErrorObj | any) {
                    if (errResponse.type !== 'undefined') showAlert(errResponse.error);
                    else showAlert('No se pudieron obtener las cuentas');
                    setFilteredAccounts([]);
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [element?.id]);

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                    {loadAccounts ? (
                        <Skeleton height={53} />
                    ) : (
                        <SearchInput
                            type="text"
                            label="Buscar por ID o nombre de cuenta"
                            value={search}
                            onChange={onSeacrh}
                            placeholder="Buscar por ID o nombre de cuenta"
                            fullWidth
                        />
                    )}
                </Grid>

                <Grid item xs={12} sm={6}>
                    {loadAccounts ? (
                        <Skeleton height={53} />
                    ) : (
                        <Select
                            label="Orden de las cuentas"
                            value={sort}
                            onChange={onSort}
                            displayEmpty
                        >
                            <MenuItem value="" selected disabled>
                                Seleccionar
                            </MenuItem>
                            <MenuItem value="asc">Orden alfabético: A-Z</MenuItem>
                            <MenuItem value="desc">Orden alfabético: Z-A</MenuItem>
                        </Select>
                    )}
                </Grid>
                <Grid
                    item
                    container
                    spacing={4}
                    xs={12}
                    component="fieldset"
                    sx={{ border: 'none', pr: 0, pb: 0 }}
                >
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" color="primary.dark" fontWeight={700}>
                            Facebook
                            <Typography component="span" color="primary.main" fontWeight={400}>
                                {' '}
                                Selecciona las cuentas (Haz clic)
                            </Typography>
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={10}
                        md={6}
                        component="ul"
                        sx={itemsContainerSx as SxProps}
                    >
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {loadAccounts ? (
                            <LoadersAccounts />
                        ) : FilteredAccounts.length > 0 ? (
                            FilteredAccounts.map((item: any) => (
                                <CheckboxListItem
                                    key={item.accountId}
                                    leftText={item.name}
                                    rightText={`(${item.accountId})`}
                                    inputValue={item.accountId}
                                    onCheck={onCheck}
                                    checked={checked[item.accountId]}
                                />
                            ))
                        ) : (
                            <NoResults>No hemos encontrado cuentas disponibles.</NoResults>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            <Grid item container xs={12} justifyContent="center">
                <Collapse
                    in={alert.open}
                    sx={{
                        m: '20px 0px',
                    }}
                >
                    <Grid container item xs={12}>
                        <Alert severity={alert.type}>{alert.message}</Alert>
                    </Grid>
                </Collapse>
            </Grid>
            <Grid
                item
                container
                xs={12}
                justifyContent={{ xs: 'center', sm: 'flex-end' }}
                mt="10px"
            >
                {loading ? (
                    <InterpunctLoader />
                ) : (
                    <DefaultBtn
                        size="small"
                        onClick={accounts.length ? onSave : onClose}
                        fullWidth={smDown}
                        disabled={loadAccounts}
                    >
                        {accounts.length ? 'Siguiente' : 'Cerrar'}
                    </DefaultBtn>
                )}
            </Grid>
        </>
    );
};

export default ConfigureFB;
