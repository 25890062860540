import fairplayAPI from 'utils/api';
import { FILE_CONFIG } from 'utils/api/configs';
import { FairplayRequestConfig } from 'utils/api/interfaces';

// Util to create and download data-Blob type given
export const createFile = (blobFile: Blob, fileName: string) => {
    const url = window.URL.createObjectURL(blobFile);
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
};

/* Util to download file if a request url or data (except blob type)
   is given.
*/
export const downloadFile = async (
    type: string,
    fileName: string,
    source: string | BlobPart[],
    config?: FairplayRequestConfig,
) => {
    let blob;
    if (typeof source === 'string') {
        const res: any = await fairplayAPI.get(source, { ...FILE_CONFIG, ...(config || {}) });
        blob = new Blob([res.data], { type });
    } else if (Array.isArray(source)) blob = new Blob(source, { type });
    if (blob) createFile(blob, fileName);
};

export const validateFileSize = (file: File, maxMB: number, error: string) => {
    const fileSizeLimit = maxMB * 1024 ** 2;

    // These exceptions could be notified as errors in Sentry
    if (Number.isNaN(maxMB)) throw new Error('maxMB cannot be NaN');
    if (maxMB <= 0) throw new Error('maxMB must be greater than 0');

    if (file.size > fileSizeLimit) {
        return error;
    }

    return '';
};
