import { FC } from 'react';
import { Grid } from '@mui/material';
import { Typography } from '@fairplay2/ui';
import { ManSittingPlanSvg, ManSittingPlantTwoSvg } from 'assets/SVGs';
import { DeleteAccountProps } from '../interfaces';

const DeleteAccount: FC<DeleteAccountProps> = ({ account, mode, vendorName }) => (
    <Grid container justifyContent="center">
        <Grid container justifyContent="center">
            {mode === 'delete' ? <ManSittingPlanSvg /> : <ManSittingPlantTwoSvg />}
        </Grid>
        <Typography variant="subtitle1" color="primary.main" mt="20px">
            {mode === 'delete' ? '¡Hey! ¿Estás seguro?' : 'Se ha eliminado la cuenta asociada'}
        </Typography>
        <Typography variant="body1" color="text.primary" mt="10px">
            {mode === 'delete'
                ? 'Estás a punto de eliminar la cuenta '
                : 'Has eliminado exitosamente la cuenta bancaria asociada '}
            {`${account?.swift ? 'SWIFT' : 'Clabe'}: `}
            <Typography color="primary.dark" component="span" fontWeight={700}>
                {account?.swift ? account?.swift : account?.clabe}
            </Typography>
            {account?.swift && (
                <Typography component="span" variant="body1" color="text.primary">
                    {' '}
                    número de cuenta:{' '}
                    <Typography color="primary.dark" fontWeight={700} component="span">
                        {account?.accountNumber}
                    </Typography>
                </Typography>
            )}{' '}
            de{' '}
            <Typography color="primary.dark" component="span" fontWeight={700}>
                {vendorName}
            </Typography>{' '}
            {mode === 'delete' &&
                'de tus cuentas bancarias asociadas. Si lo haces no podrás recuperarla.'}
        </Typography>
    </Grid>
);

export default DeleteAccount;
