import ReactQRCode from 'react-qr-code';
import { IconBtn, Typography, Skeleton } from '@fairplay2/ui';
import { Grid, styled } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import { QRCodeProps } from './QRCode.types';

const QRText = styled(Typography)({
    wordBreak: 'break-word',
});

const QRSkeleton = styled(Skeleton)({
    borderRadius: '10px',
});

const CopyBtn = styled(IconBtn)(({ theme }) => ({
    color: theme.palette.primary.main,
    '&:active': {
        color: theme.palette.primary.dark,
    },
}));

export const QRCode = ({ altValue, value, loading }: QRCodeProps) => {
    const copyToClipboard = () => {
        navigator.clipboard.writeText(altValue);
    };

    return (
        <Grid container gap={3}>
            <Grid item container xs={12} gap={1} alignItems="center" alignContent="center">
                {loading || !altValue ? (
                    <Grid item height="30px" container alignContent="center">
                        <Skeleton width={224} height={20} />
                    </Grid>
                ) : (
                    <>
                        <QRText color="primary.main" fontWeight={700}>
                            {altValue}
                        </QRText>
                        <CopyBtn onClick={copyToClipboard}>
                            <ContentCopy titleAccess="Copiar al portapapeles" fontSize="small" />
                        </CopyBtn>
                    </>
                )}
            </Grid>
            <Grid
                item
                container
                bgcolor="background.paper"
                borderRadius="10px"
                maxWidth="fit-content"
                justifyContent="center"
                alignItems="center"
            >
                {loading || !value ? (
                    <QRSkeleton width={224} height={228} />
                ) : (
                    <Grid item xs={12} bgcolor="background.default" p="12px" m="20px">
                        <ReactQRCode value={value || ''} size={160} />
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default QRCode;
