import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Collapse, Grid } from '@mui/material';
import { Typography, IconBtn, LabelledIconBtn, Tooltip } from '@fairplay2/ui';
import { KeyboardArrowLeft, Close, Info } from '@mui/icons-material';
import { useDisbursementValuesContext } from 'context/disbursements/DisbursementValuesContext';
import CancelModal from './modals/CancelModal';

const currencies = [
    { code: 'USD', name: 'Dólares Estadounidenses' },
    { code: 'CAD', name: 'Dólares Canadienses' },
    { code: 'CHF', name: 'Franco Suizo' },
    { code: 'CNH', name: 'Yuan Extracontinental' },
    { code: 'EUR', name: 'Euro' },
    { code: 'GBP', name: 'Libra Esterlina' },
    { code: 'JPY', name: 'Yen Japonés' },
    { code: 'SEK', name: 'Corona Sueca' },
];

const DisbursementHeader: FC = () => {
    const history = useHistory(),
        { disbursementStep, disbursementLocation, setDisbursementStep } =
            useDisbursementValuesContext(),
        [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            {isModalOpen && (
                <CancelModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
            )}

            <Grid
                item
                container
                direction={{ xs: 'column-reverse', md: 'row' }}
                justifyContent="space-between"
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                marginTop="5px"
                wrap="nowrap"
            >
                <Grid item container xs={7} alignItems="center" wrap="nowrap">
                    <IconBtn
                        variant="filled"
                        color="primary"
                        size="small"
                        onClick={
                            disbursementStep
                                ? () => setDisbursementStep('backward')
                                : () => history.push('/app/disbursements/transactions')
                        }
                        disabled={disbursementStep === 1}
                        sx={{
                            svg: { fontSize: '1.5em !important' },
                            p: '0 !important',
                        }}
                    >
                        <KeyboardArrowLeft />
                    </IconBtn>
                    <Typography variant="h4" color="primary.main" ml="15px">
                        Dispersión{' '}
                        <Typography
                            component="span"
                            color="primary.dark"
                            fontWeight={700}
                            textTransform="lowercase"
                        >
                            {disbursementLocation}
                        </Typography>
                    </Typography>
                </Grid>
                {!!disbursementStep && (
                    <Grid
                        container
                        item
                        xs={5}
                        justifyContent="flex-end"
                        alignItems="center"
                        mb={{ xs: '10px', sm: 0 }}
                    >
                        <LabelledIconBtn
                            variant="filled"
                            color="primary"
                            size="small"
                            label="Cancelar solicitud"
                            LabelProps={{
                                fontSize: '14px',
                                margin: '10px',
                                forceBelow: 'always',
                            }}
                            onClick={() => setIsModalOpen(true)}
                        >
                            <Close />
                        </LabelledIconBtn>
                    </Grid>
                )}
            </Grid>

            <Grid item mt="20px">
                <Collapse in={!disbursementStep}>
                    <Typography color="primary.main">
                        {`Estás solicitando pagos a instituciones ${
                            disbursementLocation === 'Nacional'
                                ? 'en México con cuentas en pesos mexicanos o dólares estadounidenses.'
                                : 'fuera de México con cuentas en alguna divisa extranjera permitida.'
                        } `}
                        {disbursementLocation === 'Internacional' && (
                            <Tooltip
                                color="paper"
                                placement="bottom-end"
                                title={
                                    <Grid container maxWidth="220px">
                                        <Grid container mb="5px">
                                            <Grid item width="60px">
                                                <Typography
                                                    variant="body2"
                                                    fontWeight={500}
                                                    color="primary.dark"
                                                >
                                                    Código
                                                </Typography>
                                            </Grid>
                                            <Grid item flexGrow={1}>
                                                <Typography
                                                    variant="body2"
                                                    fontWeight={500}
                                                    color="primary.dark"
                                                >
                                                    Divisa
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        {currencies.map((currency) => (
                                            <Grid container key={currency.code} mb="3px">
                                                <Grid item width="60px">
                                                    <Typography
                                                        variant="body2"
                                                        fontWeight={700}
                                                        color="primary.dark"
                                                    >
                                                        {currency.code}
                                                    </Typography>
                                                </Grid>
                                                <Grid item flexGrow={1}>
                                                    <Typography
                                                        variant="body2"
                                                        fontWeight={500}
                                                        color="primary.dark"
                                                    >
                                                        {currency.name}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                }
                            >
                                <IconBtn
                                    color="info"
                                    sx={{
                                        mt: '-3px',
                                        pl: '0 !important',
                                    }}
                                >
                                    <Info />
                                </IconBtn>
                            </Tooltip>
                        )}
                    </Typography>
                    <Typography color="primary.dark" position="relative">
                        Recuerda que los proveedores a los que envíes pagos deben tener un estatus{' '}
                        <b>Aprobado</b>.
                    </Typography>
                </Collapse>
            </Grid>
        </>
    );
};

export default DisbursementHeader;
