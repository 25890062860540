import { FC, useEffect, useState, ChangeEvent, MouseEvent } from 'react';
import { Grid, TableCell, TableRow, useMediaQuery, useTheme, Collapse } from '@mui/material';
import { Checkbox, DefaultBtn, Typography, Alert } from '@fairplay2/ui';
import { addGoogleTag } from 'utils/google-tag-manager';
import { getItemsAtPage } from 'utils/lists';
import { useAlert, useToggle, useOtpStatus } from 'utils/hooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { DisbursementObj, Step2Props } from '../../interfaces';
import { StyledTable } from '../styles';
import SuccessModal from '../../create-disbursement/components/modals/SuccessModal';
import PerformedDisbursement from './PerformedDisbursement';
import LoadingModal from './LoadingModal';
import { ConfirmCancelMultipleDisbursements } from './ConfirmCancelMultipleDisbursements';

const Step2: FC<Step2Props> = ({
    disbursements,
    onSubmit,
    returnToFileUpload,
    otpValue,
    setOtpValue,
}) => {
    const { isOtpRequired } = useOtpStatus(),
        [page, setPage] = useState(0),
        [rowsPerPage, setRowsPerPage] = useState(10),
        [confirmation, setConfirmation] = useState(false),
        { alert, hideAlert, showAlert } = useAlert(),
        [isModalOpen, setIsModalOpen] = useState({
            loading: false,
            success: false,
        }),
        [isOtpModalOpen, toggleOtpModal] = useToggle(),
        [isOtpLoading, setIsOtpLoading] = useState(false),
        [otpError, setOtpError] = useState(''),
        theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm'));

    const onChangePage = (_: MouseEvent<HTMLButtonElement> | null, newPage: number) =>
        setPage(newPage);

    const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        document.body.style.overflow = 'auto';
    };

    const onCloseOtpModal = () => {
        if (otpValue) setOtpValue('');
        if (otpError) setOtpError('');
        toggleOtpModal();
    };

    const submitData = async () => {
        hideAlert();

        if (isOtpRequired) {
            setIsOtpLoading(true);
            setOtpError('');
            toggleOtpModal();
        }
        setIsModalOpen({ success: false, loading: true });

        try {
            const res: any = await onSubmit(true);

            if (res.data.body.results.totalDispersions) {
                addGoogleTag('disbursement-archive-success');
                setIsModalOpen({ success: true, loading: false });
            }
        } catch (errResponse: ErrorObj | any) {
            showAlert(
                isOtpRequired
                    ? 'El código es inválido o el usuario no existe, inténtalo de nuevo.'
                    : errResponse?.error,
            );
            setIsModalOpen({ success: false, loading: false });
            setOtpValue('');
        } finally {
            setIsOtpLoading(false);
        }
    };

    const onModalClose = () => {
        document.body.style.overflow = 'auto';
    };

    const toggleConfirmation = () => {
        setConfirmation((prev) => !prev);
    };

    useEffect(() => {
        // Reset pagination
        setPage(0);
        setRowsPerPage(10);
    }, [disbursements]);

    return (
        <>
            <SuccessModal open={isModalOpen.success} onClose={onModalClose} />
            <ConfirmCancelMultipleDisbursements
                open={isOtpModalOpen}
                onClose={onCloseOtpModal}
                loading={isOtpLoading}
                onConfirm={submitData}
                error={otpError}
                setValue={setOtpValue}
                value={otpValue}
            />
            {isModalOpen.loading && <LoadingModal isModalOpen={isModalOpen.loading} />}

            <Grid item container xs={12} justifyContent="center" m="20px 0">
                <StyledTable
                    pagination={{
                        rowsPerPageOptions: [5, 10, 20, 25, 50],
                        count: disbursements.length,
                        rowsPerPage,
                        page,
                        onPageChange: onChangePage,
                        onRowsPerPageChange: onChangeRowsPerPage,
                    }}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    getTableItem={(element: DisbursementObj) => (
                        <PerformedDisbursement disbursement={element} key={element.id} />
                    )}
                    currentItems={getItemsAtPage(disbursements, page, rowsPerPage)}
                    columns={5}
                >
                    <TableRow>
                        <TableCell>
                            <Typography color="text.primary">Alias/RFC</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary">Concepto de Pago</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary">Monto</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary">CLABE/SWIFT</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary">Banco</Typography>
                        </TableCell>
                    </TableRow>
                </StyledTable>
            </Grid>
            <Grid item container xs={12} alignItems="flex-start" wrap="nowrap">
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <Checkbox
                    id="massive-disbursement-confirmation"
                    onClick={toggleConfirmation}
                    name="confirm"
                />
                <Grid item marginLeft={1}>
                    <Typography
                        variant="body1"
                        color="text.primary"
                        fontWeight={500}
                        sx={{
                            mt: '2px',
                        }}
                    >
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor="massive-disbursement-confirmation">
                            Confirmo que los datos de las dispersiones a realizar son correctos
                        </label>
                    </Typography>
                    <Typography variant="caption" color="text.primary" fontWeight={500}>
                        Al confirmar aceptas que las transferencias se realicen a las cuentas
                        bancarias de cada proveedor actualmente asociadas.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container xs={12} justifyContent="flex-end">
                <Collapse in={alert.open}>
                    <Grid item xs={12} mt="10px">
                        <Alert severity={alert.type}>{alert.message}</Alert>
                    </Grid>
                </Collapse>
            </Grid>
            <Grid item container xs={12} justifyContent="flex-end" mt="30px">
                <DefaultBtn
                    fullWidth={smDown}
                    size="small"
                    variant="outlined"
                    onClick={returnToFileUpload}
                    sx={{
                        mb: { xs: '10px', sm: 0 },
                    }}
                >
                    Atrás
                </DefaultBtn>
                <DefaultBtn
                    fullWidth={smDown}
                    size="small"
                    disabled={!confirmation}
                    onClick={isOtpRequired ? toggleOtpModal : submitData}
                    sx={{
                        mb: { xs: '20px', sm: 0 },
                        ml: { xs: 0, sm: '20px' },
                    }}
                >
                    Solicitar
                </DefaultBtn>
            </Grid>
        </>
    );
};

export default Step2;
