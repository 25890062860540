import { FC, useState, useEffect, useContext, ChangeEvent } from 'react';
import { Grid, MenuItem, Collapse } from '@mui/material';
import { TextColorDescription } from 'common-components';
import { Typography, Alert, Select, Skeleton } from '@fairplay2/ui';
import DailyPaymentOrdersPaper from 'components/dashboard/contracts/detail/components/payments/DailyPaymentOrdersPaper';
import SessionContext from 'context/session/sessionContext';
import fairplayAPI from 'utils/api';
import { ColorDescription } from 'common-components/text-color-description/TextColorDescription.types';
import { useAlert } from 'utils/hooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { Contract } from '../../interfaces';
import { canSeePayments, getAdjacentDays, sanitize3Days } from '../../utils';
import { DailyPaymentOrdersData } from '../../detail/components/payments/interfaces';
import ActiveContractsListSkeleton from './ActiveContractsListSkeleton';
import ActiveContractsPaper from './ActiveContractsPaper';
import MonthlyDirectDebit from './MonthlyDirectDebit';

const CARD_METADATA = [
    { title: 'Ayer', type: 'past' as 'past' },
    { title: 'Hoy', type: 'today' as 'today' },
    { title: 'Mañana', type: 'future' as 'future' },
];

const colorDescriptions: ColorDescription[] = [
    { text: 'Monto pagado', color: 'auxiliary.main' },
    { text: 'Monto total', color: 'primary.dark', variant: 'filled' },
    { text: 'Monto del día', color: 'secondary.main' },
    { text: 'Monto por pagar', color: 'primary.main' },
    { text: 'Pendiente de pago', color: 'error.main' },
];

const ActiveContractsList: FC = () => {
    const { selectedCompany } = useContext(SessionContext),
        { alert, showAlert, hideAlert } = useAlert(),
        [loading, setLoading] = useState(true),
        [currentInspectedContract, setCurrentInspectedContract] = useState({
            id: '',
            fairplayInternId: '',
        }),
        [showPaymentsModule, setShowPaymentsModule] = useState<boolean>(false),
        [activeContractsList, setActiveContractsList] = useState<Contract[]>([]),
        [paymentOrdersDailyData, setPaymentOrdersDailyData] = useState<DailyPaymentOrdersData[]>(
            [],
        ),
        [directDebitMonthlyData, setDirectDebitMonthlyData] = useState<Contract>({} as Contract),
        [directDebitBankDetails, setDirectDebitBankDetails] = useState({
            clientAccountNumber: '',
            debitBank: '-',
        });

    const changeDebitContract = (event: ChangeEvent<HTMLInputElement>) => {
        const currentDebitContractId = activeContractsList.filter(
            (contract) => contract?.fairplayInternId === event.target.value,
        );
        setCurrentInspectedContract({
            id: currentDebitContractId[0]?.id,
            fairplayInternId: event.target.value,
        });
    };

    const getDirectDebitlessActiveContracts = () => {
        const directDebitlessActiveContracts = activeContractsList.filter(
            (contract) => !contract.firstPayment,
        );

        return directDebitlessActiveContracts.length;
    };

    const fetchActiveContractsList = () => {
        hideAlert();
        (async () => {
            setLoading(true);
            setPaymentOrdersDailyData([]);
            setDirectDebitMonthlyData({} as Contract);
            try {
                const res: any = await fairplayAPI.get(
                    `/v2/companies/${selectedCompany.company?.id}/contracts?status=active`,
                );
                const resArr = res.data.body.results;
                setActiveContractsList(resArr);
                if (res.data.body.count === 0) {
                    setLoading(false);
                    return;
                }
                setCurrentInspectedContract({
                    id: resArr[0]?.id,
                    fairplayInternId: resArr[0]?.fairplayInternId,
                });
            } catch (errResponse: ErrorObj | any) {
                showAlert(
                    errResponse?.error || 'No se pudieron cargar los contratos, intente más tarde',
                    'error',
                );
                setLoading(false);
            }
        })();
    };

    const fetchPaymentOrdersDailyData = () => {
        if (activeContractsList[0]?.id)
            (async () => {
                const [startDate, endDate] = getAdjacentDays();
                try {
                    const currentContract = activeContractsList.find(
                            (contract) => contract.id === currentInspectedContract.id,
                        ),
                        shouldShowPaymentsModule = canSeePayments(currentContract as any);

                    setShowPaymentsModule(shouldShowPaymentsModule);
                    let paymentOrdersResArr = [],
                        lastDirectDebitArr = [];

                    if (shouldShowPaymentsModule) {
                        const paymentOrdersRes: any = await fairplayAPI.get(
                                `/payment-order/api/v1/payment_orders/${currentInspectedContract.id}?start_date=${startDate}&end_date=${endDate}&page=1&page_size=3`,
                                { baseService: 'PAYMENT_ORDERS' },
                            ),
                            lastDirectDebitRes: any = await fairplayAPI.get(
                                `/v1/companies/${selectedCompany.company?.id}/contracts/${currentInspectedContract.id}/direct-debits?ordering=-charge_date&page_size=1`,
                            );
                        paymentOrdersResArr = paymentOrdersRes.data.results || [];
                        lastDirectDebitArr = lastDirectDebitRes.data.body.results || [];
                    } else {
                        // Keeps UX consistent when no request is performed
                        await new Promise((resolve) => {
                            setTimeout(resolve, 400);
                        });
                    }

                    // Validation necessary to turn off skeleton loader when there is an active contract without direct debits.
                    if (!lastDirectDebitArr.length) setLoading(false);

                    setPaymentOrdersDailyData(
                        sanitize3Days({ startDate, endDate }, paymentOrdersResArr),
                    );
                    setDirectDebitBankDetails({
                        clientAccountNumber: lastDirectDebitArr[0]?.clientAccountNumber || '',
                        debitBank: (lastDirectDebitArr[0]?.debitBank ?? [0, '-'])[1],
                    });
                } catch (errResponse: ErrorObj | any) {
                    showAlert(
                        errResponse?.error ||
                            'No se pudieron cargar los contratos, intente más tarde',
                        'error',
                    );
                    setLoading(false);
                }
            })();
    };

    const fetchDirectDebitMonthlyData = () => {
        if (currentInspectedContract.fairplayInternId)
            (async () => {
                try {
                    const resMonthlyData: any = await fairplayAPI.get(
                        `/v2/companies/${selectedCompany.company?.id}/contracts?search=${currentInspectedContract.fairplayInternId}`,
                    );
                    const resMonthlyDataArr = resMonthlyData.data.body.results[0];
                    setDirectDebitMonthlyData(resMonthlyDataArr);
                    showAlert(resMonthlyDataArr?.status.text, 'success', false);
                } catch (errResponse: ErrorObj | any) {
                    showAlert(
                        errResponse?.error ||
                            'No se pudieron cargar los contratos, intente más tarde',
                        'error',
                    );
                    setLoading(false);
                }
            })();
    };

    useEffect(() => {
        if (!selectedCompany.company?.id) return;

        fetchActiveContractsList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany.company?.id]);

    useEffect(() => {
        setLoading(true);
        fetchPaymentOrdersDailyData();
        fetchDirectDebitMonthlyData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentInspectedContract]);

    useEffect(() => {
        // useEffect necessary to wait for all fetch functions to fulfill, before turning off the skeleton loader.
        if (paymentOrdersDailyData.length) setLoading(false);
    }, [paymentOrdersDailyData]);

    return (
        <>
            <Grid item container xs={8} marginTop={3}>
                <Grid item width="fit-content" marginTop="-18px">
                    {loading ? (
                        <Skeleton width={250} height={25} sx={{ mt: '10px' }} />
                    ) : (
                        activeContractsList.length > 0 && (
                            <Select
                                value={currentInspectedContract.fairplayInternId}
                                fullWidth
                                onChange={changeDebitContract}
                                variant="standard"
                                sx={{ mt: '10px' }}
                            >
                                {activeContractsList.map((contract: any) => (
                                    <MenuItem
                                        value={contract?.fairplayInternId}
                                        key={contract?.fairplayInternId}
                                    >
                                        Contrato {contract?.fairplayInternId}
                                    </MenuItem>
                                ))}
                            </Select>
                        )
                    )}
                </Grid>
                {paymentOrdersDailyData.length > 0 && (
                    <Grid item xs={12}>
                        <Typography variant="body1" color="text.primary" mr="20px">
                            Pagos de tu contrato
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Grid
                item
                container
                xs={4}
                marginTop={3}
                justifyContent="flex-end"
                pr={{ xs: 0, lg: '65px' }}
            >
                <Grid width="fit-content">
                    <Collapse in={alert.open}>
                        <Alert severity={alert.type} aria-label="Alerta">
                            {alert.message}
                        </Alert>
                    </Collapse>
                </Grid>
            </Grid>

            {/* eslint-disable-next-line no-nested-ternary */}
            {loading ? (
                <ActiveContractsListSkeleton />
            ) : showPaymentsModule ? (
                <Grid item container xs={12} marginTop="15px" mb={6} justifyContent="center">
                    <Grid
                        item
                        container
                        xs={12}
                        md={8}
                        justifyContent={{ xs: 'center', md: 'flex-start' }}
                        role="list"
                        mb="15px"
                        gap={1}
                    >
                        {paymentOrdersDailyData.map((dailyData, index) => (
                            <DailyPaymentOrdersPaper
                                data={dailyData}
                                loading={false}
                                size="lg"
                                key={dailyData?.paymentDate ?? `nullish-${index}`}
                                dateTitle={CARD_METADATA[index].title}
                                timeType={CARD_METADATA[index].type}
                            />
                        ))}
                        <Grid item xs={12} mt={1}>
                            <TextColorDescription descriptions={colorDescriptions} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="text.primary">
                                *Todos los montos están reflejados en pesos mexicanos.
                            </Typography>
                        </Grid>
                        <Alert
                            severity="info"
                            aria-label="Información de pagos"
                            sx={{ maxWidth: '568px' }}
                        >
                            Importante: Recuerda que la información de los pagos del día y próximos,
                            se actualiza diariamente a partir de mediodía.
                        </Alert>
                    </Grid>

                    <Grid
                        item
                        container
                        xs={12}
                        md={4}
                        justifyContent={{
                            xs: 'center',
                            md: 'flex-end',
                        }}
                        pr={{ xs: 0, lg: '65px' }}
                        maxHeight="250px"
                    >
                        <MonthlyDirectDebit
                            contractId={directDebitMonthlyData?.id}
                            totalMonthCharges={directDebitMonthlyData?.totalMonthCharges}
                            clientAccountNumber={directDebitBankDetails.clientAccountNumber}
                            receiverBank={directDebitBankDetails.debitBank}
                        />
                    </Grid>
                </Grid>
            ) : (
                <Grid
                    item
                    container
                    xs={12}
                    marginTop="30px"
                    justifyContent={['center', 'flex-start']}
                    marginBottom={{ xs: 0, md: '50px' }}
                >
                    <ActiveContractsPaper
                        activeContracts={getDirectDebitlessActiveContracts()}
                        loading={loading}
                    />
                </Grid>
            )}
        </>
    );
};

export default ActiveContractsList;
