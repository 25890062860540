import { FC, ChangeEvent } from 'react';
import { Collapse, Divider, Grid } from '@mui/material';
import { DefaultInput, Typography, FilePicker, Alert } from '@fairplay2/ui';
import { CurrencyInput } from '@fairplay2/ui-x-currencies';
import { DatePicker } from '@fairplay2/ui-x-dates';
import { FormV1 } from '../../interfaces.v1';
import { onAmountChange } from '../../utils.v1';

interface SalesProps {
    values: any;
    errors: any;
    selectedCompany: any;
    fileName: string;
    disabledDays: any;
    date: Date | undefined;
    tooltips: any;
    onChangeInput: (prop: any, error: any) => void;
    onChange: (prop: keyof FormV1) => (event: ChangeEvent<HTMLInputElement>) => void;
    onDateSelect: (date: Date | undefined) => void;
    onFileChange: (file: File | null) => void;
}

const SalesAdvancement: FC<SalesProps> = ({
    onChangeInput,
    onChange,
    onDateSelect,
    onFileChange,
    values,
    errors,
    fileName,
    disabledDays,
    date,
    tooltips,
    selectedCompany,
}) => (
    <>
        <Divider sx={{ color: 'background.dark' }} />
        {selectedCompany.company!.registeredName?.length > 40 && (
            <Grid container item xs={12} lg={6} marginTop={4} marginBottom={-1}>
                <Alert severity="warning">
                    La dispersión puede demorar más de 24hrs si la razón social de tu empresa es de
                    más de 40 caracteres.
                </Alert>
            </Grid>
        )}
        <Grid container spacing={4} marginTop={1.25}>
            <Grid item xs={12} md={6}>
                <DefaultInput
                    labelVariant="external"
                    label="El RFC de tu empresa"
                    id="disbursement-RFC"
                    disabled
                    value={selectedCompany.company!.rfc}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <CurrencyInput
                    name="amount"
                    labelVariant="external"
                    label="Monto de la transferencia"
                    placeholder="Ej. 50,000.00"
                    onValueChange={onAmountChange(onChange)}
                    customInput={DefaultInput}
                    error={errors.amount ? errors.amount : ''}
                    onBlur={() => onChangeInput('amount', 'Ingresa el monto a pagar.')}
                    thousandSeparator
                    decimalScale={2}
                    allowNegative={false}
                    startAdornment={
                        <Typography color="primary.main" fontWeight={700}>
                            MXN$
                        </Typography>
                    }
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <DefaultInput
                    labelVariant="external"
                    label="Concepto de la transferencia"
                    id="disbursement-concept"
                    value={values.concept}
                    type="text"
                    placeholder="¿Para qué necesitas esta transferencia?"
                    onChange={onChange('concept')}
                    onBlur={() => onChangeInput('concept', 'Ingresa el concepto de la dispersión')}
                    error={errors.concept ? errors.concept : ''}
                />
                <Collapse in={tooltips.concept}>
                    <Typography variant="caption" color="text.primary">
                        *Puede incluir números y letras pero no debe contener caracteres especiales.
                    </Typography>
                </Collapse>
            </Grid>
            <Grid item xs={12} md={6}>
                <DefaultInput
                    labelVariant="external"
                    label="¿Cuál es la referencia numérica para la transferencia?"
                    id="disbursement-reference"
                    value={values.reference}
                    type="text"
                    placeholder="Ej. 4567803456"
                    onChange={onChange('reference')}
                    onBlur={() => onChangeInput('reference', 'Ingresa la referencia de la factura')}
                    error={errors.reference ? errors.reference : ''}
                />
                <Collapse in={tooltips.reference}>
                    <Typography variant="caption" color="text.primary">
                        *Puede incluir únicamente números
                    </Typography>
                </Collapse>
            </Grid>
            <Grid item xs={12} md={6}>
                <DatePicker
                    id="disbursement-date"
                    label="¿Cuándo te gustaría que se realice la transferencia?"
                    mode="single"
                    placeholder="Selecciona la fecha"
                    labelVariant="external"
                    value={values.date}
                    onChange={onDateSelect}
                    disabledDays={disabledDays}
                    error={errors.date}
                    displayIcon
                    PickerProps={{
                        fromDate: date,
                        captionLayout: 'buttons',
                    }}
                />
                <Typography variant="caption" color="text.primary">
                    *Recuerda solicitar pagos con mínimo un día de anticipación.
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography component="label" variant="label" htmlFor="disbursement-file">
                    Adjunta el soporte de la transferencia (factura, orden de compra, etc)
                </Typography>
                <FilePicker
                    id="disbursement-file"
                    onFileChange={onFileChange}
                    fileName={fileName}
                    accept={
                        'image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                    }
                    paperVariant="dashed"
                    variant="compact"
                    aria-describedby="disbursement-file-error"
                />
                {!!errors.invoiceFile && (
                    <Typography variant="caption" color="error.main" id="disbursement-file-error">
                        {`${errors.invoiceFile}`}
                    </Typography>
                )}
            </Grid>
        </Grid>
    </>
);

export default SalesAdvancement;
