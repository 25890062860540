import { FC, useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router';
import { Typography, IconBtn, Tooltip, Loader } from '@fairplay2/ui';
import { Grid, TableRow, TableCell } from '@mui/material';
import { Visibility, PersonAdd } from '@mui/icons-material';
import { formatAmount } from 'utils/parsing';
import { SHORT_READABLE_FORMAT, format } from 'utils/dates';
import SessionContext from 'context/session/sessionContext';
import fairplayAPI from 'utils/api';
import { FiscalTableItemProps } from '../../interfaces';
import { StyledSwitch } from '../../styles';

const FiscalTableItem: FC<FiscalTableItemProps> = ({ invoice, invoiceType, setAlert }) => {
    const { selectedCompany } = useContext(SessionContext),
        history = useHistory(),
        [switchState, setSwitchState] = useState(invoice.isPaid),
        [loading, setLoading] = useState(false);

    const toggleIsPaid = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>) => {
            setLoading(true);
            try {
                const res: any = await fairplayAPI.patch(
                    `/v2/companies/${selectedCompany?.company?.id}/invoices/${event.target.id}`,
                    {
                        is_paid: event.target.checked,
                    },
                );
                setSwitchState(res.data.body.results.isPaid);
                setLoading(false);
            } catch {
                setAlert('No se pudo modificar la factura, intente más tarde');
                setLoading(false);
                setTimeout(() => {
                    setAlert('');
                }, 5000);
            }
        },
        [selectedCompany.company?.id, setAlert],
    );

    return (
        <TableRow>
            <TableCell sx={{ minWidth: 85 }}>
                <Typography fontWeight={700} color="primary.main">
                    {`${format(invoice?.createdAt || '', SHORT_READABLE_FORMAT, {
                        fallbackString: '',
                    })}`}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontWeight={700} color="primary.main">
                    {/*  if invoiceType is 'receiver', it means the user is receiving the invoice from vendors, issuer information (vendor's data) will be displayed */}
                    {invoiceType === 'receiver' ? invoice.issuer?.name : invoice.receiver?.name}
                </Typography>
                <Typography fontWeight={500} color="primary.dark">
                    {invoiceType === 'receiver' ? invoice.issuer?.rfc : invoice.receiver?.rfc}
                </Typography>
            </TableCell>
            <TableCell sx={{ minWidth: 120 }}>
                <Typography fontWeight={500} color="primary.dark">
                    {invoice?.paymentType}
                </Typography>
                <Typography fontWeight={500} color="primary.dark">
                    {invoice?.paymentType === 'PPD' ? '(Diferido)' : '(Una exhibición)'}
                </Typography>
            </TableCell>
            <TableCell sx={{ minWidth: 150 }}>
                <Typography fontWeight={700} color="primary.main">
                    {`${formatAmount(invoice?.total)}`}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontWeight={500} color="primary.dark">
                    {invoice.status}
                </Typography>
            </TableCell>
            <TableCell align="center" sx={{ minWidth: 85 }}>
                <Grid container justifyContent="space-evenly">
                    <Tooltip title="Agregar proveedor" placement="top" distance={0}>
                        {/*
                            Span necessary to avoid MUI 'Tolltip containing disable button' warning.
                            Manually moving accesible label to button component.
                        */}
                        <span aria-label={undefined}>
                            <IconBtn
                                aria-label="Agregar proveedor"
                                color="secondary"
                                variant="outlined"
                                onClick={() => history.push(`/app/financing/vendors/add-vendor`)}
                                disabled={
                                    invoiceType === 'receiver'
                                        ? invoice.issuer?.exists
                                        : invoice.receiver?.exists
                                }
                            >
                                <PersonAdd />
                            </IconBtn>
                        </span>
                    </Tooltip>
                    <Tooltip title="Ver detalle factura" placement="top" distance={0}>
                        <IconBtn
                            color="secondary"
                            variant="outlined"
                            onClick={() =>
                                history.push(`/app/fiscal/detail/${invoice.folioFiscal}`)
                            }
                        >
                            <Visibility />
                        </IconBtn>
                    </Tooltip>
                </Grid>
            </TableCell>
            <TableCell>
                {loading ? (
                    <Grid container width={60}>
                        <Loader size={20} data-testid="loader-fical-tableItem" />
                    </Grid>
                ) : (
                    <>
                        <StyledSwitch
                            id={invoice.folioFiscal}
                            name="isPaidSwitch"
                            checked={switchState}
                            onChange={toggleIsPaid}
                            inputProps={{
                                'aria-label':
                                    invoiceType === 'receiver'
                                        ? 'Alternar factura pagada'
                                        : 'Alternar factura cobrada',
                            }}
                        />
                        {switchState && (
                            <Typography variant="body1" fontWeight={500} color="primary.dark">
                                {invoiceType === 'receiver' ? 'Pagada' : 'Cobrada'}
                            </Typography>
                        )}
                    </>
                )}
            </TableCell>
        </TableRow>
    );
};

export default FiscalTableItem;
