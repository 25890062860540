import { FC, FormEvent, useState } from 'react';
import { Grid, Collapse, Alert } from '@mui/material';
import { MailOutline } from '@mui/icons-material';
import { ReCaptcha } from 'common-components';
import { Typography, DefaultInput, DefaultBtn, InterpunctLoader } from '@fairplay2/ui';
import { validateEmail } from 'utils/validation';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import fairplayAPI from 'utils/api';
import { useRecaptcha, useAlert } from 'utils/hooks';
import { RecoverPasswordProps, RecoverPasswordError } from '../interfaces';
import ConfirmationRecover from './ConfirmationRecover';

const RecoverPassword: FC<RecoverPasswordProps> = ({ onCancel }) => {
    const [email, setEmail] = useState(''),
        [showConfirmation, setShowConfirmation] = useState(false),
        [loading, setLoading] = useState(false),
        [errors, setErrors] = useState<RecoverPasswordError>({
            email: '',
        }),
        { alert, hideAlert, showAlert } = useAlert(),
        { onLoadRecaptcha, recaptchaIsLoading, recaptchaRef } = useRecaptcha();

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        const recaptchaValue = recaptchaRef.current?.getValue();
        e.preventDefault();
        hideAlert();

        if (recaptchaValue) {
            setLoading(true);

            try {
                await fairplayAPI.post(
                    '/v1/users/reset_password',
                    {
                        email,
                        g_recaptcha_response: recaptchaValue,
                    },
                    { authorization: false },
                );
                setShowConfirmation(true);
            } catch (errResponse: ErrorObj | any) {
                showAlert(errResponse?.error || 'Se ha producido un error. Intente más tarde');
            } finally {
                setLoading(false);
            }
        } else showAlert('Es necesario completar el reCAPTCHA');
    };

    const onChange = (e: any) => {
        hideAlert();
        setEmail(e.target.value.toLowerCase());
    };

    const onChangeInput = () => {
        setErrors({ ...errors, email: email ? validateEmail(email) : 'Ingresa un email' });
    };

    return (
        <Grid
            item
            container
            xs={12}
            component="form"
            onSubmit={onSubmit}
            mt={['20px', '20px', '50px']}
        >
            <Typography variant="h4" color="primary.contrastText" gutterBottom>
                Recupera tu contraseña
            </Typography>
            {!showConfirmation ? (
                <>
                    <Typography
                        variant="body2"
                        color="primary.contrastText"
                        fontWeight={700}
                        lineHeight={1.8}
                    >
                        Ingresa tu correo electrónico. Te enviaremos un código al correo que
                        proporciones aquí para recuperar tu contraseña.
                    </Typography>
                    <Grid
                        item
                        container
                        xs={12}
                        justifyContent="center"
                        direction="column"
                        mt={3}
                        mb={{ xs: '140px', sm: '190px', md: '210px' }}
                    >
                        <Grid item container marginY={[2, 3]} justifyContent="center">
                            <DefaultInput
                                name="email"
                                placeholder="Email"
                                label="Email"
                                type="email"
                                value={email}
                                onChange={onChange}
                                onBlur={onChangeInput}
                                error={errors.email}
                                required
                                startAdornment={<MailOutline />}
                                forceLegibility
                            />
                        </Grid>

                        <Grid
                            item
                            container
                            justifyContent="center"
                            mb={[2, 3]}
                            sx={{
                                transform: {
                                    lg: 'scale(0.9)',
                                    xl: 'scale(1)',
                                },
                            }}
                        >
                            <ReCaptcha
                                ref={recaptchaRef}
                                isLoading={recaptchaIsLoading}
                                onLoad={onLoadRecaptcha}
                            />
                        </Grid>

                        <Collapse in={alert.open}>
                            <Grid container justifyContent="center" alignItems="center" mb={[2, 3]}>
                                <Grid item>
                                    <Alert variant="filled" severity={alert.type}>
                                        {alert.message}
                                    </Alert>
                                </Grid>
                            </Grid>
                        </Collapse>

                        <Grid
                            item
                            container
                            mb={[2, 3]}
                            justifyContent="center"
                            alignItems="center"
                        >
                            {loading ? (
                                <InterpunctLoader />
                            ) : (
                                <DefaultBtn type="submit" color="mixed" fullWidth>
                                    Enviar
                                </DefaultBtn>
                            )}
                        </Grid>
                        <Grid item container justifyContent="center" alignItems="center">
                            <DefaultBtn variant="text" color="mixed" fullWidth onClick={onCancel}>
                                Cancelar
                            </DefaultBtn>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <ConfirmationRecover email={email} onCancel={onCancel} />
            )}
        </Grid>
    );
};

export default RecoverPassword;
