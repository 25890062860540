import { FC } from 'react';
import { Grid, Stepper, Step } from '@mui/material';
import { Typography } from '@fairplay2/ui';
import { DeskStepLabel } from '../styles';
import { useActiveStep } from '../context/activeStep-context';
import { SimpleStepperProps } from '../interfaces';
import QontoStepIcon from './QontoStepIcon';

const SimpleStepper: FC<SimpleStepperProps> = ({
    steps,
    showActiveSteps = true,
    hideOnSmallScreens = true,
}) => {
    const activeStep = useActiveStep();

    return (
        <Grid item container xs={12} marginTop={3}>
            <Stepper
                activeStep={showActiveSteps ? activeStep : -1}
                orientation="vertical"
                connector={null}
            >
                {steps?.map((label) => {
                    const stepProps: { completed?: boolean } = {};

                    return (
                        <Step key={label} {...stepProps}>
                            <DeskStepLabel
                                StepIconComponent={(props) =>
                                    QontoStepIcon({ ...props, hideOnSmallScreens })
                                }
                                hideOnSmallScreens={hideOnSmallScreens}
                            >
                                <Typography
                                    variant="subtitle2"
                                    color="inherit"
                                    ml="14px"
                                    fontWeight={700}
                                >
                                    {label}
                                </Typography>
                            </DeskStepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </Grid>
    );
};

export default SimpleStepper;
