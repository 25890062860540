import { FC, useState, useContext, useEffect, ChangeEvent, MouseEvent } from 'react';
import { Grid, TableRow, TableCell, TableSortLabel, MenuItem, Collapse } from '@mui/material';
import { Table } from 'common-components';
import { Alert, Select, SearchInput, Typography } from '@fairplay2/ui';
import SessionContext from 'context/session/sessionContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { OrderType } from 'utils/interfaces';
import fairplayAPI from 'utils/api';
import { statusDic } from '../../utils';
import { DisTableProps, DisbursementObj, StatusDic } from '../../../interfaces';
import DisbursementsTableItem from './DisbursementsTableItem';

const ORDER_DIC = {
    asc: 'date',
    desc: '-date',
};

const DisbursementsTable: FC<DisTableProps> = ({ selectedContract }) => {
    const { selectedCompany } = useContext(SessionContext),
        [requestParams, setRequestParams] = useState(''),
        [page, setPage] = useState(0),
        [rowsPerPage, setRowsPerPage] = useState(5),
        [order, setOrder] = useState<OrderType>('desc'),
        [loading, setLoading] = useState(true),
        [search, setSearch] = useState(''),
        [disbursements, setDisbursements] = useState<DisbursementObj[]>([]),
        [status, setStatus] = useState<keyof StatusDic | ''>(''),
        [count, setCount] = useState(0),
        [selected, setSelected] = useState({
            companyId: '',
            contractId: '',
        }),
        [alert, setAlert] = useState({
            open: false,
            text: '',
        });

    const resetTable = () => {
        setStatus('');
        setSearch('');
        setOrder('desc');
        setPage(0);
        setRowsPerPage(5);
        setDisbursements([]);
    };

    const onSort = () => {
        const isAsc = order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
    };

    const onChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        document.body.style.overflow = 'auto';
    };

    const onSelect = (event: ChangeEvent<{ value: keyof StatusDic | '' }>) => {
        setStatus(event.target.value);
    };

    const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const hideAlert = () => setAlert((prevState) => ({ ...prevState, open: false }));

    useEffect(() => {
        if (!(selected?.companyId && selected?.contractId)) return;
        hideAlert();

        (async () => {
            setLoading(true);
            try {
                const res: any = await fairplayAPI.get(
                        `/v2/companies/${selected.companyId}/contracts/${selected.contractId}/dispersions${requestParams}`,
                    ),
                    resCount = res.data.body.count,
                    resArr = res.data.body.results,
                    newCount = resCount || resArr.length;

                setCount(newCount);
                setDisbursements(resArr);
                setLoading(false);
            } catch (errResponse: ErrorObj | any) {
                setLoading(false);
                setAlert({
                    open: true,
                    text:
                        errResponse?.error ||
                        'No se pudieron cargar las dispersiones, intente más tarde',
                });
            }
        })();
    }, [requestParams, selected]);

    // In order to reset the table just on the first render, assigns the company and contract ids to state.
    useEffect(() => {
        setLoading(true);
        if (selectedCompany.company?.id && selectedContract) {
            resetTable();
            setSelected({
                companyId: selectedCompany.company?.id,
                contractId: selectedContract.id,
            });
        }
    }, [selectedCompany, selectedContract]);

    return (
        <>
            <Grid
                item
                container
                xs={12}
                justifyContent={{ xs: 'center', sm: 'flex-start' }}
                m="40px 0 15px"
            >
                <Grid
                    item
                    container
                    sm={12}
                    md={9}
                    alignItems="center"
                    justifyContent={{ xs: 'space-between', md: 'flex-start' }}
                    mb="20px"
                >
                    <Typography
                        variant="subtitle2"
                        color="text.primary"
                        fontWeight={500}
                        m={{ xs: '0 0 10px 0', md: '0 40px 0 0' }}
                    >
                        Filtrar listado por estatus
                    </Typography>
                    <Select
                        value={status}
                        onChange={onSelect}
                        sx={{
                            width: { xs: '100%', sm: '200px' },
                            // Needed for legacy filters
                            '.MuiSelect-select': {
                                maxHeight: 16.4,
                                alignItems: 'center',
                                display: 'flex',
                            },
                        }}
                    >
                        <MenuItem value="">Todos los estatus</MenuItem>
                        {Object.keys(statusDic).map((stat: keyof StatusDic) => (
                            <MenuItem key={stat} value={stat}>
                                {statusDic[stat]}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={12} md={3}>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <SearchInput
                        value={search}
                        placeholder="Busca por alias, monto o concepto"
                        onChange={onSearch}
                        fullWidth
                    />
                </Grid>
            </Grid>

            <Grid item container xs={12} justifyContent="flex-end">
                <Collapse in={alert.open}>
                    <Alert
                        severity="error"
                        sx={{
                            mb: '15px',
                        }}
                    >
                        {alert.text}
                    </Alert>
                </Collapse>
            </Grid>

            <Grid item container xs={12} justifyContent="center">
                <Table
                    sx={{
                        marginBottom: 6.4,
                    }}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    getTableItem={(disbursement: DisbursementObj, i: number) => (
                        <DisbursementsTableItem
                            disbursement={disbursement}
                            key={i}
                            selectedCompanyId={selected.companyId}
                        />
                    )}
                    currentItems={disbursements}
                    columns={7}
                    loading={loading}
                    pagination={{
                        count,
                        page,
                        rowsPerPage,
                        onPageChange: onChangePage,
                        onRowsPerPageChange: onChangeRowsPerPage,
                    }}
                    externalFilters={{
                        status: status as string,
                        ordering: ORDER_DIC[order],
                        search,
                    }}
                    emptyItemsMessage="No hemos encontrado dispersiones disponibles"
                    onParamsQueryChange={setRequestParams}
                >
                    <TableRow>
                        <TableCell>
                            <Typography component="span" color="text.primary">
                                Solicitada
                            </Typography>
                            <TableSortLabel
                                active={disbursements.length > 0}
                                direction={order}
                                onClick={onSort}
                                aria-label={`Organizar por fecha ${
                                    order === 'desc' ? 'ascendente' : 'descendente'
                                }`}
                            />
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary">Alias</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary">Concepto</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary">Monto</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary">Medio</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="text.primary">Estatus</Typography>
                        </TableCell>
                        <TableCell align="center">
                            <Typography color="text.primary">Acciones</Typography>
                        </TableCell>
                    </TableRow>
                </Table>
            </Grid>
        </>
    );
};

export default DisbursementsTable;
