import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { Grid, useTheme, useMediaQuery, Collapse, Divider, MenuItem } from '@mui/material';
import {
    Typography,
    DefaultBtn,
    Alert,
    DefaultInput,
    Select,
    Loader,
    InterpunctLoader,
} from '@fairplay2/ui';
import { WomanShowingFiles } from 'assets/SVGs';
import { zipCodeRules } from 'utils/validation';
import { useAlert, useToggle } from 'utils/hooks';
import SatModal from 'components/dashboard/data-sources/platforms/sat/ConnectorForm';
import fairplayAPI from 'utils/api';
import { toFormData } from 'utils/parsing';
import sessionContext from 'context/session/sessionContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj, setFormErrors } from 'utils/error-handler';
import { useDataSources } from 'components/dashboard/data-sources/context/DataSourcesContext';
import Sat from 'components/dashboard/data-sources/platforms/sat';
import { RAW_PLATFORM_KEYS, noop } from 'utils/constants';
import { FiscalDataForm, OnboardingStepProps } from '../connectors/interfaces';

const FiscalData: FC<OnboardingStepProps> = ({ onNext }) => {
    const [loading, setLoading] = useState(false),
        [satConnectorIsLoading, setSatConnectorIsLoading] = useState(true),
        [showFiscalDataForm, setShowFiscalDataForm] = useState(false),
        theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        mdDown = useMediaQuery(theme.breakpoints.down('md')),
        [satModalIsOpen, toggleSatModalModal] = useToggle(),
        [taxRegimes, setTaxRegimes] = useState([]),
        { alert, showAlert } = useAlert(),
        { selectedCompany, getCompanies } = useContext(sessionContext),
        {
            platforms: { sat },
        } = useDataSources(),
        userHasAtLeastOneConnector = RAW_PLATFORM_KEYS.some((connector) =>
            selectedCompany.company?.sales_sources?.includes(connector),
        ),
        [showSATConnector, setShowSATConnector] = useState(false),
        {
            register,
            formState: { errors, isValid },
            getValues,
            watch,
            reset,
            trigger,
            setError,
            control,
        } = useForm<FiscalDataForm>({
            mode: 'onBlur',
            values: {
                regime: '',
                registeredName: '',
                taxPerson: selectedCompany.company?.taxPerson
                    .description as FiscalDataForm['taxPerson'],
                zipCode: '',
            },
        }),
        values = getValues();
    watch('regime');

    const openSATConnection = () => {
        if (showFiscalDataForm) {
            setShowFiscalDataForm(false);
            reset();
        }
        toggleSatModalModal();
    };

    const goToNextStep = async () => {
        try {
            await fairplayAPI.patch('/v1/users/update', {
                step: userHasAtLeastOneConnector ? 3 : 4,
            });
            onNext(!userHasAtLeastOneConnector);
        } catch (errResponse: ErrorObj | any) {
            showAlert(errResponse?.error || 'Ocurrió un error, intente más tarde');
        }
    };

    const onContinue = async () => {
        if (showFiscalDataForm && !isValid) return trigger();
        setLoading(true);

        const data = toFormData({
            registered_name: values.registeredName,
            tax_person: selectedCompany.company?.taxPerson.id,
            regime: values.regime,
            zip_code: values.zipCode,
            authorize_credit_bureau: false,
        });
        try {
            if (showSATConnector) {
                goToNextStep();
            } else {
                await fairplayAPI.post(
                    `/v2/companies/${selectedCompany.company?.id}/tax-data/register`,
                    data,
                );
                goToNextStep();
            }
        } catch (errResponse: ErrorObj | any) {
            if (errResponse?.type === 'form')
                setFormErrors<FiscalDataForm>(errResponse?.error, setError);
            else showAlert(errResponse?.error || 'Ocurrió un error, intente más tarde');
        } finally {
            setLoading(false);
        }
    };

    const getTaxRegimes = useCallback(async () => {
        try {
            const res: any = await fairplayAPI.get('/v2/tax-regime');
            setTaxRegimes(res?.data?.body?.results || []);
        } catch (errResponse: ErrorObj | any) {
            showAlert(errResponse?.error);
        }
    }, [showAlert]);

    useEffect(() => {
        getTaxRegimes();
    }, [getTaxRegimes]);

    useEffect(() => {
        if (sat) {
            setShowSATConnector(sat.connectors.length > 0);
            setSatConnectorIsLoading(false);
        }
    }, [sat]);

    useEffect(() => {
        if (selectedCompany) getCompanies();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid
            container
            gap={6}
            direction="column"
            sx={{
                maxWidth: { md: '728px' },
            }}
        >
            <SatModal
                open={satModalIsOpen}
                onClose={toggleSatModalModal}
                onConfirm={() => setShowSATConnector(true)}
                allowMultipleConnectors={false}
            />

            <Grid item container direction="column">
                <Grid item marginBottom={2}>
                    <Typography variant="h3" color="primary.dark">
                        ¡Conecta tu{' '}
                        <Typography component="span" color="secondary.main">
                            fuente fiscal!
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2" color="primary.dark" fontWeight={500}>
                        Si quieres acceder a financiamiento es necesario que conectes tu fuente de
                        SAT.{' '}
                        <Typography component="span" color="primary.main" fontWeight={700}>
                            <a
                                style={{
                                    color: 'inherit',
                                }}
                                target="_blank"
                                href="https://guides.getfairplay.com/es-ES/knowledgebase/category/?id=CAT-01013"
                                rel="noopener noreferrer"
                            >
                                Consulta la guía de conexión
                            </a>
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>

            <Grid item container>
                <Grid
                    item
                    container
                    xs={12}
                    md={4}
                    justifyContent={{ xs: 'center', lg: 'initial' }}
                    mr={{ xs: 0, md: 4 }}
                    mb={{ xs: 3, lg: 0 }}
                >
                    <WomanShowingFiles />
                </Grid>
                <Grid
                    item
                    container
                    direction="column"
                    xs={12}
                    md={7}
                    gap={1}
                    sx={{
                        maxWidth: { lg: '385px' },
                    }}
                >
                    <Grid item>
                        <Typography variant="subtitle1" color="primary.main">
                            ¿Por qué debo conectar SAT?
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" color="text.primary" fontWeight={400}>
                            ¡No saltes de una plataforma a otra!{' '}
                            <Typography component="span" color="primary.main">
                                Consulta y descarga tus facturas,{' '}
                            </Typography>
                            analiza flujos de efectivo, concentración de clientes y proveedores
                            desde Fairplay Insights conectando tu fuente SAT.
                        </Typography>
                    </Grid>
                    <Grid item mt={1}>
                        <Typography variant="subtitle2" color="text.primary" fontWeight={400}>
                            <Typography component="span" color="primary.main">
                                Además acelera tu acceso a una financiamiento
                                <br />
                            </Typography>
                            conectando desde ahora tu fuente fiscal ya que será un requisito para
                            obtener una financiamiento y firmar un contrato con Fairplay.
                        </Typography>
                    </Grid>
                    {!showSATConnector && !satConnectorIsLoading && (
                        <Grid
                            item
                            container
                            gap={2}
                            mt={2}
                            justifyContent={
                                !userHasAtLeastOneConnector || showFiscalDataForm || mdDown
                                    ? 'center'
                                    : 'initial'
                            }
                        >
                            {!showFiscalDataForm && userHasAtLeastOneConnector && (
                                <Grid item xs={12} sm={4} md={12} lg={5.5}>
                                    <DefaultBtn
                                        size="small"
                                        variant="outlined"
                                        fullWidth={mdDown}
                                        sx={{
                                            backgroundColor: 'background.default',
                                            width: { md: '100%' },
                                        }}
                                        onClick={() => setShowFiscalDataForm(true)}
                                    >
                                        Lo haré después
                                    </DefaultBtn>
                                </Grid>
                            )}
                            <Grid item xs={12} sm={4} md={12} lg={5.5}>
                                <DefaultBtn
                                    size="small"
                                    variant={showFiscalDataForm ? 'outlined' : 'contained'}
                                    fullWidth={mdDown}
                                    sx={{
                                        backgroundColor: showFiscalDataForm ? 'white' : '',
                                        width: { md: '100%' },
                                    }}
                                    onClick={openSATConnection}
                                >
                                    Conectar SAT
                                </DefaultBtn>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>

            {showSATConnector && !satConnectorIsLoading && (
                <Grid item container xs={12} justifyContent="center" mt={-2} mb={-3}>
                    <Sat notify={noop} />
                </Grid>
            )}

            {satConnectorIsLoading && (
                <Grid item container xs={12} justifyContent="center">
                    <Grid item>
                        <Loader />
                    </Grid>
                </Grid>
            )}

            <Collapse in={showFiscalDataForm}>
                <Grid
                    container
                    item
                    xs={12}
                    gap={4}
                    direction="column"
                    sx={{
                        maxWidth: { md: '663px' },
                    }}
                >
                    <Grid item>
                        <Alert severity="info" sx={{ backgroundColor: '#cddaf5' }}>
                            Puedes conectar tu fuente de datos fiscales en cualquier momento. Por
                            ahora ingresa los siguientes datos y la dirección fiscal de tu empresa
                            por favor:
                        </Alert>
                    </Grid>

                    <Grid item container>
                        <Grid item xs={12} md={5.5}>
                            <DefaultInput
                                label="Tipo de persona fiscal"
                                id="taxPerson"
                                ignoreDisabledStyles
                                sx={{
                                    mb: '25px',
                                }}
                                type="text"
                                error={errors.taxPerson?.message}
                                disabled
                                {...register('taxPerson')}
                            />
                        </Grid>
                        {!mdDown && (
                            <Divider
                                orientation="vertical"
                                flexItem
                                variant="fullWidth"
                                sx={{ mx: '4%', color: 'auxiliary.light' }}
                            />
                        )}
                        <Grid item xs={12} md={5.5}>
                            <DefaultInput
                                label="Nombre registrado"
                                id="registeredName"
                                placeholder={
                                    selectedCompany.company?.taxPerson.description ===
                                    'Persona Moral'
                                        ? 'Razón social'
                                        : 'Nombre completo'
                                }
                                sx={{
                                    mb: '25px',
                                }}
                                type="text"
                                error={errors.registeredName?.message}
                                {...register('registeredName', {
                                    required: `Ingresa tu ${
                                        selectedCompany.company?.taxPerson.description ===
                                        'Persona Moral'
                                            ? 'razón social'
                                            : 'nombre completo'
                                    }`,
                                })}
                            />
                        </Grid>
                        <Grid item xs={12} md={5.5}>
                            <Select
                                label="Régimen fiscal"
                                id="regime"
                                error={errors.regime?.message}
                                disabled={!taxRegimes.length}
                                value={values.regime}
                                sx={{
                                    mb: '25px',
                                }}
                                {...register('regime', {
                                    required: 'Ingresa tu régimen fiscal',
                                })}
                            >
                                <MenuItem value="" disabled>
                                    Régimen fiscal
                                </MenuItem>
                                {taxRegimes.length > 0 &&
                                    taxRegimes.map((option: any) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            <Typography
                                                ellipsis
                                                maxWidth={{
                                                    xs: '214px',
                                                    sm: '490px',
                                                    md: '395px',
                                                    lg: 'initial',
                                                }}
                                            >
                                                {option.name}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Grid>
                        {!mdDown && (
                            <Divider
                                orientation="vertical"
                                flexItem
                                variant="fullWidth"
                                sx={{ mx: '4%', color: 'auxiliary.light' }}
                            />
                        )}
                        <Grid item xs={12} md={5.5}>
                            <Controller
                                name="zipCode"
                                control={control}
                                rules={{
                                    required: 'Ingresa tu código postal',
                                    pattern: {
                                        value: zipCodeRules.regex,
                                        message: zipCodeRules.message,
                                    },
                                }}
                                render={({ field: { onChange, onBlur, name, value, ref } }) => (
                                    <NumberFormat
                                        id="zipCode"
                                        name={name}
                                        placeholder="Código postal"
                                        helperText={errors.zipCode?.message}
                                        error={!!errors.zipCode}
                                        customInput={DefaultInput}
                                        inputProps={{ maxLength: 5 }}
                                        onValueChange={({ floatValue }) => onChange(floatValue)}
                                        value={value}
                                        onBlur={onBlur}
                                        inputRef={ref}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Collapse>

            <Grid item mt={showFiscalDataForm ? 0 : '-50px'}>
                <Typography
                    variant="subtitle2"
                    color="text.primary"
                    fontWeight={400}
                    maxWidth={showFiscalDataForm ? { md: '620px' } : undefined}
                >
                    Tu información está protegida y su uso se lleva acabo de acuerdo a nuestros{' '}
                    <Typography component="span" color="primary.main">
                        <a
                            style={{
                                color: 'inherit',
                            }}
                            target="_blank"
                            href="https://getfairplay.com/terminos-y-condiciones/"
                            rel="noopener noreferrer"
                        >
                            Términos y condiciones de servicio
                        </a>
                    </Typography>
                    , así como a nuestro{' '}
                    <Typography component="span" color="primary.main">
                        <a
                            style={{
                                color: 'inherit',
                            }}
                            target="_blank"
                            href="https://getfairplay.com/aviso-de-privacidad/"
                            rel="noopener noreferrer"
                        >
                            Aviso de privacidad
                        </a>
                    </Typography>
                </Typography>
            </Grid>

            <Collapse in={alert.open}>
                <Grid
                    container
                    justifyContent="right"
                    alignItems="center"
                    item
                    xs={12}
                    sx={{
                        '&.MuiGrid-item': {
                            maxWidth: { md: '663px !important' },
                        },
                    }}
                >
                    <Alert variant="filled" severity={alert.type}>
                        {alert.message}
                    </Alert>
                </Grid>
            </Collapse>

            {(showFiscalDataForm || showSATConnector) && (
                <Grid
                    item
                    container
                    mt={alert.open ? 0 : -6}
                    mb={{ xs: '5px', md: 0 }}
                    justifyContent="flex-end"
                    sx={{
                        '&.MuiGrid-item': {
                            maxWidth: { md: '663px !important' },
                        },
                    }}
                >
                    <Grid item minHeight="48px">
                        {loading ? (
                            <InterpunctLoader />
                        ) : (
                            <DefaultBtn
                                size="medium"
                                fullWidth={smDown}
                                sx={{ width: { xs: '100%', sm: '294px' } }}
                                onClick={onContinue}
                            >
                                {userHasAtLeastOneConnector ? 'Continuar' : 'Finalizar'}
                            </DefaultBtn>
                        )}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default FiscalData;
