/**
 * Generates a function that can be passed to {@link Array.filter}
 * in order to exclude a specific index from a given array.
 *
 * @param targetIndex Index to exclude
 * @returns Generated function to pass to {@link Array.filter}
 */
export const removeIndex =
    (targetIndex: number): Parameters<Array<any>['filter']>[0] =>
    (item: any, index: number) =>
        index !== targetIndex;

/**
 * Moves an item from array A to array B based on its position
 * in A and the desired position in B. If no element exists
 * in the specified source position, nothing will be
 * inserted to B.
 *
 * @param sourceList Array where the item to move is picked from
 * @param sourceIndex Index of the item in the source list.
 * @param destinationList Array where the item will to be moved to
 * @param destinationIndex Index to place the moved item in the destination list.
 *                         Used as the first argument of {@link Array.splice}.
 * @returns A two-elements array in the form [newSourceList, newDestinationList]
 */
export function moveBetweenLists<T>(
    sourceList: T[],
    sourceIndex: number,
    destinationList: T[],
    destinationIndex: number,
): [T[], T[]] {
    const areTheSame = sourceList === destinationList,
        movedItem = sourceList[sourceIndex],
        newSourceList = sourceList.filter(removeIndex(sourceIndex)),
        newDestinationList = areTheSame ? newSourceList : [...destinationList];

    if (movedItem !== undefined) newDestinationList.splice(destinationIndex, 0, movedItem);

    return [newSourceList, newDestinationList];
}

/**
 * Performs local pagination of lists.
 *
 * @param fullList
 * @param page Page number (zero-based)
 * @param pageSize Page size (one-based)
 * @returns Sublist containing items at the specified page.
 */
export function getItemsAtPage<T>(fullList: T[], page: number, pageSize: number): T[] {
    return fullList.slice(page * pageSize, page * pageSize + pageSize);
}

export function compare<T>(toComeFirstInSort: T, toComeLaterInSort: T) {
    if (toComeFirstInSort > toComeLaterInSort) {
        return -1;
    }
    if (toComeFirstInSort < toComeLaterInSort) {
        return 1;
    }
    return 0;
}

export const reverseOf =
    (sortingFunction: typeof compare): typeof compare =>
    (a, b) =>
        sortingFunction(b, a);

export function forOneOrMany<T>(payload: T, operation: (payload: T) => void) {
    if (Array.isArray(payload)) payload.forEach(operation);
    else operation(payload);
}

// TODO: create test
export const asSorting = (criteria: string | null, order: 'asc' | 'desc'): string | undefined => {
    if (!criteria) return undefined;
    if (order === 'asc') return criteria;
    return `-${criteria}`;
};
