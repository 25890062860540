import { FC, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { DialogTitle, Grid } from '@mui/material';
import { Modal, DefaultBtn, Typography } from '@fairplay2/ui';
import { useDisbursementValuesContext } from 'context/disbursements/DisbursementValuesContext';
import { ManParagraphSvg } from 'assets/SVGs';
import { addGoogleTag } from 'utils/google-tag-manager';
import SessionContext from 'context/session/sessionContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { ModalProps } from 'utils/interfaces';
import fairplayAPI from 'utils/api';
import { useAlert } from 'utils/hooks/useAlert';
import ConfirmCancelDisbursement from './ConfirmCancelDisbursement';

const CancelModal: FC<ModalProps> = ({ open, onClose }) => {
    const history = useHistory(),
        { user } = useContext(SessionContext),
        { disbursementLocation, disbursementStep, resetDisbursementValues } =
            useDisbursementValuesContext(),
        [loading, setLoading] = useState(false),
        [closeConfirmation, setCloseConfirmation] = useState(false),
        { alert, hideAlert, showAlert } = useAlert();

    const onConfirm = async () => {
        setLoading(true);
        hideAlert();
        try {
            await fairplayAPI.delete(`/v1/cart/${user?.id}`, {
                baseService: 'DISBURSEMENT_CART',
            });
            setCloseConfirmation(true);
            addGoogleTag('disbursement-invoice-cancel');
        } catch (errResponse: ErrorObj | any) {
            showAlert(
                errResponse?.error ||
                    'Ocurrió un error eliminando la información, intente más tarde',
            );
        } finally {
            setLoading(false);
        }
    };

    const onModalClose = () => {
        if (closeConfirmation) {
            resetDisbursementValues();
            history.push('/app/disbursements/transactions');
        } else onClose();
    };

    return (
        <Modal
            disableBackdropClick
            open={open}
            maxWidth={524}
            onClose={loading ? undefined : onModalClose}
        >
            {closeConfirmation ? (
                <Grid container>
                    <Grid container item xs={12} justifyContent="center">
                        <ManParagraphSvg />
                    </Grid>
                    <Grid item xs={12} mt="20px">
                        <Typography
                            component={DialogTitle}
                            variant="subtitle1"
                            color="primary.main"
                            padding={0}
                        >
                            Solicitud de dispersión cancelada
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mt="20px">
                        <Typography color="text.primary" fontWeight={500}>
                            La solicitud de dispersión para{' '}
                            <Typography component="span" color="primary.dark" fontWeight={700}>
                                Dispersión {disbursementLocation}
                            </Typography>{' '}
                            se ha cancelado. Puedes iniciar una nueva solicitud en el menú
                            Dispersiones.
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} justifyContent="center" m="20px 0">
                        <DefaultBtn
                            size="small"
                            onClick={onModalClose}
                            sx={{ mx: '10px', mt: '10px' }}
                        >
                            Entendido
                        </DefaultBtn>
                    </Grid>
                </Grid>
            ) : (
                <ConfirmCancelDisbursement
                    disbursementStep={disbursementStep}
                    onConfirm={onConfirm}
                    loading={loading}
                    alert={alert}
                />
            )}
        </Modal>
    );
};

export default CancelModal;
