import { FC, useState, FocusEvent } from 'react';
import {
    Modal,
    Typography,
    DefaultBtn,
    DefaultInput,
    PasswordInput,
    InterpunctLoader,
    Alert,
} from '@fairplay2/ui';
import { Grid, Collapse, useMediaQuery, useTheme } from '@mui/material';
import fairplayAPI from 'utils/api';
import { useAlert } from 'utils/hooks';
import { toFormData } from 'utils/parsing';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { ConnectorProps } from '../interfaces';
import ConnectorFormTitle from '../components/ConnectorFormTitle';
import Confirmation from '../components/Confirmation';
import { useDataSources } from '../../context/DataSourcesContext';
import { MagentoForm } from './interfaces';

const EMPTY_VALUE_ERRORS: MagentoForm = {
    username: 'Ingresa el usuario',
    password: 'Ingresa la contraseña',
    storeUrl: 'Ingresa la url de la tienda',
};

const MagentoModal: FC<ConnectorProps> = ({ open, onClose, connector }) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        { baseAPI, dispatch } = useDataSources(),
        [confirmation, setConfirmation] = useState(false),
        [loading, setLoading] = useState(false),
        { alert, hideAlert, showAlert } = useAlert(),
        [values, setValues] = useState<MagentoForm>({
            username: '',
            password: '',
            storeUrl: '',
        }),
        [errors, setErrors] = useState<MagentoForm>({
            username: '',
            password: '',
            storeUrl: '',
        });

    const resetValues = () => {
        setValues({
            username: '',
            password: '',
            storeUrl: '',
        });
    };

    const resetErrors = () => {
        setErrors({
            username: '',
            password: '',
            storeUrl: '',
        });
        hideAlert();
    };

    const onCloseModal = () => {
        resetValues();
        resetErrors();
        setConfirmation(false);
        onClose();
    };

    const onChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setValues((prev) => ({ ...prev, [name]: value }));
        hideAlert();
    };

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        resetErrors();
        const data = toFormData({
                platform: connector?.platform || 'magento-v2',
                username: values.username,
                password: values.password,
                storeUrl: connector?.storeUrl || values.storeUrl,
            }),
            endpoint = `${baseAPI}${connector ? `/${connector.id}/reconnect` : ''}`;

        try {
            const res: any = await fairplayAPI.post(endpoint, data),
                payload = res.data.body.results;

            setConfirmation(true);
            dispatch({
                type: connector ? 'UPDATE' : 'ADD',
                payload,
            });
        } catch (errResponse: ErrorObj | any) {
            if (errResponse?.type === 'form') {
                const errorObj = errResponse.error;
                const newErrors: MagentoForm = { ...errors };
                // eslint-disable-next-line no-restricted-syntax
                for (const key in errorObj) {
                    if (Object.prototype.hasOwnProperty.call(errorObj, key))
                        newErrors[key] = errorObj[key][0];
                }
                setErrors(newErrors);
            } else
                showAlert(
                    errResponse?.error ||
                        'No se pudo realizar el registro de la tienda, intente nuevamente',
                );
        } finally {
            setLoading(false);
        }
    };

    const onBlur = (e: FocusEvent<HTMLInputElement>) => {
        const name = e.target.name as keyof MagentoForm;
        setErrors((prevState) => ({
            ...prevState,
            [name]: values[name] ? '' : EMPTY_VALUE_ERRORS[name],
        }));
    };

    return (
        <Modal
            disableBackdropClick
            open={open}
            onClose={onCloseModal}
            maxWidth={640}
            disableEscapeKeyDown
            title={!confirmation && <ConnectorFormTitle groupKey="magento" connector={connector} />}
            sx={{
                '.MuiDialogContent-root': {
                    pt: 0,
                },
            }}
        >
            {confirmation ? (
                <Confirmation item="Magento" onClose={onCloseModal} />
            ) : (
                <Grid container component="form" onSubmit={onSubmit}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" color="text.primary" mb="20px">
                            Para que podamos conectar tu tienda con nuestra plataforma, es necesario
                            que nos proporciones la siguiente información.
                        </Typography>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <DefaultInput
                                id="magento-username"
                                name="username"
                                labelVariant="external"
                                label="Usuario"
                                type="text"
                                value={values.username}
                                onChange={onChange}
                                onBlur={onBlur}
                                error={errors.username}
                                required
                                InputLabelProps={{ required: false }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PasswordInput
                                id="magento-password"
                                name="password"
                                labelVariant="external"
                                label="Contraseña"
                                type="password"
                                value={values.password}
                                onChange={onChange}
                                onBlur={onBlur}
                                error={errors.password}
                                forceHiddenText={loading}
                                required
                                InputLabelProps={{ required: false }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DefaultInput
                                id="magento-store-url"
                                name="storeUrl"
                                labelVariant="external"
                                label="URL de la tienda"
                                type="text"
                                value={connector?.storeUrl || values.storeUrl}
                                onChange={onChange}
                                onBlur={onBlur}
                                error={errors.storeUrl}
                                disabled={!!connector}
                                required
                                InputLabelProps={{ required: false }}
                            />
                        </Grid>
                    </Grid>
                    <Collapse in={alert.open} sx={{ mt: 2.5, width: '100%' }}>
                        <Grid item container xs={12} justifyContent="center">
                            <Alert severity={alert.type} sx={{ mt: 0.5 }}>
                                {alert.message}
                            </Alert>
                        </Grid>
                    </Collapse>

                    <Grid
                        item
                        container
                        xs={12}
                        justifyContent={{ xs: 'center', sm: 'flex-end' }}
                        marginTop={3}
                    >
                        {loading ? (
                            <InterpunctLoader />
                        ) : (
                            <>
                                <DefaultBtn
                                    fullWidth={smDown}
                                    size="small"
                                    variant="outlined"
                                    onClick={onCloseModal}
                                >
                                    Cancelar
                                </DefaultBtn>
                                <DefaultBtn
                                    fullWidth={smDown}
                                    size="small"
                                    type="submit"
                                    sx={{
                                        ml: { xs: 0, sm: '20px' },
                                        mt: { xs: '20px', sm: 0 },
                                    }}
                                >
                                    Siguiente
                                </DefaultBtn>
                            </>
                        )}
                    </Grid>
                </Grid>
            )}
        </Modal>
    );
};

export default MagentoModal;
