import { FC, useContext, useState } from 'react';
import { Grid, TableRow, TableCell } from '@mui/material';
import { AssignmentInd, CloudDownload, Visibility } from '@mui/icons-material';
import { Typography, IconBtn, Tooltip, Loader } from '@fairplay2/ui';
import { Status, StatusType } from 'common-components';
import SessionContext from 'context/session/sessionContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { TIME_READABLE_FORMAT, format } from 'utils/dates';
import { downloadFile } from 'utils/files';
import { OrdersTableItemProps } from '../interfaces';

const OrdersTableItem: FC<OrdersTableItemProps> = ({ order, onAlert, onModal }) => {
    const { selectedCompany } = useContext(SessionContext),
        [loading, setLoading] = useState(false);

    const onDownload = async (orderId: string) => {
        const url = `/v1/companies/${selectedCompany.company?.id}/datafiles/${orderId}/download`;
        try {
            await downloadFile('application/vnd.ms-excel', 'ordenes.csv', url);
        } catch (errResponse: ErrorObj | any) {
            onAlert(
                errResponse?.error || 'Hubo un error al descargar el archivo, intente más tarde',
                'error',
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <TableRow key={order.id}>
            <TableCell>
                <Grid container alignItems="center" minWidth={180} wrap="nowrap">
                    <AssignmentInd color="secondary" />
                    <Typography fontWeight={700} color="primary.main" ml="15px">
                        {order.uploaderBy.fullName}
                    </Typography>
                </Grid>
            </TableCell>
            <TableCell>
                <Typography fontWeight={500} color="primary.dark">
                    {format(order.uploadedDate || '', TIME_READABLE_FORMAT)}
                </Typography>
            </TableCell>

            <TableCell>
                <Status
                    variant="outlined"
                    role="presentation"
                    status={order.status!.id.toString() as StatusType}
                >
                    {order.status!.text}
                </Status>
            </TableCell>

            <TableCell align="center">
                <Grid container justifyContent="space-evenly">
                    <Tooltip title="Ver detalle" placement="top" distance={0}>
                        <IconBtn
                            aria-label="Ver detalle de archivo"
                            color="secondary"
                            variant="outlined"
                            onClick={() => onModal(order)}
                        >
                            <Visibility />
                        </IconBtn>
                    </Tooltip>
                    <Tooltip title="Descargar" placement="top" distance={0}>
                        <IconBtn
                            aria-label="Descargar archivo"
                            color="secondary"
                            variant="outlined"
                            onClick={() => onDownload(order.id)}
                        >
                            {loading ? (
                                <Loader size={24} data-testid="download-loader" />
                            ) : (
                                <CloudDownload />
                            )}
                        </IconBtn>
                    </Tooltip>
                </Grid>
            </TableCell>
        </TableRow>
    );
};

export default OrdersTableItem;
