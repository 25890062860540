import { useQueries } from 'react-query';
import { Theme } from '@mui/material';
import {
    defaultGrowthIndicatorProp,
    getChartData,
    getGmvGrowthData,
    getGmvPerformanceData,
} from '../components/projections/utils';
import { Config, Indicator } from '../components/projections/interfaces';

const REQUESTS_DIC: Record<Indicator, Config> = {
    chart: {
        getData: (selectedContractId: string, theme?: Theme) =>
            getChartData(selectedContractId, theme!),
    },
    gmvPerformance: {
        getData: getGmvPerformanceData,
        initialData: {
            props: defaultGrowthIndicatorProp,
            status: undefined,
        },
    },
    gmvGrowth: {
        getData: getGmvGrowthData,
        initialData: {
            props: defaultGrowthIndicatorProp,
            status: undefined,
        },
    },
    // expectedGrowth: {
    //     getData: getExpectedGrowthData,
    // initialData: {
    //     props: defaultGrowthIndicatorProp,
    //     status: undefined,
    // },
    // },
};

export const useProjectionsQueries = (selectedContractId: string, theme?: Theme) => {
    const [datapointsRequest, performanceRequest, growthRequest] = useQueries(
        Object.entries(REQUESTS_DIC).map(([key, config]) => ({
            queryKey: [key, selectedContractId],
            queryFn: () => config.getData(selectedContractId, key === 'chart' ? theme : undefined),
            enabled: !!selectedContractId,
            staleTime: 180000,
            cacheTime: 180000,
            // Initialize values to avoid undefined errors
            placeholderData: config.initialData,
        })),
    );

    return { datapointsRequest, performanceRequest, growthRequest };
};

export default useProjectionsQueries;
