import { useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DefaultBtn } from '@fairplay2/ui';
import { noop } from 'utils/constants';
import customGraphic from 'assets/img/connectors/manual_upload_logo.png';
import { useDataSources } from '../../context/DataSourcesContext';
import { ConnectorData } from '../../interfaces';
import { HybridS3WizardModalState, PlatformItem } from '../interfaces';
import BasePlatformItem from '../components/BasePlatformItem';
import Disconnect from '../components/disconnect-item/Disconnect';
// import ProcessingErrorModal from './components/ProcessingErrorModal';
import HybridWizardModal from './components/HybridWizardModal';
import { getActivePlatform, wizardModalReducer } from './utils';

const TITLE_DIC = {
    's3-manual-upload': 'Carga manual',
    s3connector: 'S3 Bucket',
};

const WIZARD_INITIAL_STATE: HybridS3WizardModalState = {
    showWizard: false,
    platform: undefined,
    reauthTarget: null,
};

export const getCardTitle = (activePlatform?: 's3connector' | 's3-manual-upload') => {
    if (!activePlatform) return `${TITLE_DIC.s3connector} / ${TITLE_DIC['s3-manual-upload']}`;
    return TITLE_DIC[activePlatform];
};

// eslint-disable-next-line react/prop-types
const S3: PlatformItem = ({ notify }) => {
    const {
            platforms: { s3 },
        } = useDataSources(),
        { push } = useHistory(),
        [{ showWizard, platform, reauthTarget }, dispatch] = useReducer(
            wizardModalReducer,
            WIZARD_INITIAL_STATE,
        ),
        // [showErrorModal, setShowErrorModal] = useState(false),
        [disconnectTarget, setDisconnectTarget] = useState<ConnectorData | null>(null),
        activePlatform = getActivePlatform(s3?.connectors),
        isManualUpload = activePlatform === 's3-manual-upload';

    const onS3Reauth = (connector: ConnectorData | null) => {
        dispatch({ type: 'REAUTH_S3', payload: connector });
    };

    const onCloseDisconnect = () => setDisconnectTarget(null);

    return (
        <BasePlatformItem
            // TODO: Implement notifications for s3 errors
            //  (requires changes from backend and data teams)
            // notification={...}
            picture={customGraphic}
            groupKey="s3"
            overrideCardTitle={getCardTitle(activePlatform)}
            notify={notify}
            onConnect={() => dispatch({ type: 'OPEN_PICKER' })}
            // Manual upload does not need reauthorization
            onReauth={isManualUpload ? noop : onS3Reauth}
            onDisconnect={isManualUpload ? false : setDisconnectTarget}
        >
            {/* <ProcessingErrorModal open={showErrorModal} onClose={() => setShowErrorModal(false)} /> */}
            <HybridWizardModal
                open={showWizard}
                onClose={() => dispatch({ type: 'CLOSE_WIZARD' })}
                platform={platform as 's3'}
                setPlatform={(newPlatform) =>
                    dispatch({ type: 'CHANGE_PLATFORM', payload: newPlatform })
                }
                element={(platform === 's3' ? reauthTarget : undefined) as null}
            />
            {isManualUpload ? (
                <DefaultBtn
                    variant="text"
                    onClick={() => push('/app/sources/upload')}
                    sx={{ px: 0 }}
                >
                    Administrar archivos de órdenes
                </DefaultBtn>
            ) : (
                <Disconnect
                    open={!!disconnectTarget}
                    platform="s3"
                    onCancel={onCloseDisconnect}
                    onClose={onCloseDisconnect}
                    element={disconnectTarget}
                />
            )}
        </BasePlatformItem>
    );
};

export default S3;
