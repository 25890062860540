import { FC, useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Step, StepLabel, Grid, useMediaQuery, useTheme } from '@mui/material';
import { Typography, LoaderPage } from '@fairplay2/ui';
import { handleUtmParams } from 'utils/handleParams';
import fairplayAPI, { hasActiveSession } from 'utils/api';
import { NotFoundPage } from 'layouts';
import { CustomConnector, MobileStepper } from './styles';
import CreateAccount from './components/customer-data/CreateAccount';
import CompanyData from './components/company-data';
import Connectors from './components/connectors';
import FiscalData from './components/fiscal-data';
import SuccessConfirmation from './components/SuccessConfirmation';
import { useActiveStep, useSetActiveStep } from './context/activeStep-context';
import { BicolumnLayout } from './components/BicolumnLayout';
import QontoStepIcon from './components/QontoStepIcon';
import { ProfileEmail } from './interfaces';

const getSteps = () => [
    'Datos personales',
    'Datos empresariales',
    'Datos fiscales',
    'Fuentes de órdenes',
]; // usuarios

// TODO: Rewrite onNext logic to trigger request to user profile based
//  on events and not as an effect
const CustomerOnboarding: FC = () => {
    const activeStep = useActiveStep() ?? 0,
        setActiveStep = useSetActiveStep(),
        params = useParams<{ token: string }>(),
        [loading, setLoading] = useState(() => !!params.token || hasActiveSession()),
        [profileEmail, setProfileEmail] = useState<ProfileEmail | undefined>(undefined),
        [emailTokenIsValid, setEmailTokenIsValid] = useState<boolean>(false),
        theme = useTheme(),
        mdDown = useMediaQuery(theme.breakpoints.down('md')),
        steps = getSteps(),
        token = useRef(params.token);

    const onNext = (completeOnboarding = false) => {
        if (completeOnboarding) setActiveStep(4);
        else setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    };

    const getStepContent = (step: number) => {
        switch (step) {
            case 1:
                return <CompanyData onNext={onNext} />;
            case 2:
                return <FiscalData onNext={onNext} />;
            case 3:
                return <Connectors onNext={onNext} />;
            case 0:
            default:
                return (
                    <CreateAccount
                        profileEmail={profileEmail}
                        emailTokenIsValid={emailTokenIsValid}
                        token={token.current}
                    />
                );
        }
    };

    const validateEmailToken = async () => {
        // There is an implicit loading=true caused by the presence of a token
        try {
            await fairplayAPI.post(
                '/v1/users/activate',
                { token: token.current },
                {
                    authorization: false,
                },
            );
            setEmailTokenIsValid(true);
        } catch {
            setEmailTokenIsValid(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Save UTM parameters of URL in LocalStorage
        if (!localStorage?.getItem('utm_params')) handleUtmParams();
        if (token.current) validateEmailToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (hasActiveSession() && !token.current && (activeStep === 0 || activeStep === 1)) {
            (async () => {
                // Redundant setState due to unclear requirements (change in activeStep from 0 to 1)
                setLoading(true);
                try {
                    const res: any = await fairplayAPI.get('/v1/users/profile');
                    setActiveStep(
                        !res.data.body.results.emailConfirmed ? 0 : res.data.body.results.step,
                    );
                    setProfileEmail({
                        isConfirmed: res.data.body.results.emailConfirmed,
                        email: res.data.body.results.email,
                    });
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [activeStep, setActiveStep]);

    // eslint-disable-next-line no-nested-ternary
    return loading ? (
        <LoaderPage loading={loading} />
    ) : !!token.current && !emailTokenIsValid ? (
        <NotFoundPage />
    ) : (
        <BicolumnLayout steps={steps}>
            {mdDown && (
                <Grid item container padding="30px 30px 0 30px" justifyContent="center">
                    <MobileStepper
                        sx={{ width: '86%', mt: 1, mb: 3 }}
                        activeStep={activeStep}
                        connector={<CustomConnector />}
                    >
                        {steps.map((label) => {
                            const stepProps: { completed?: boolean } = {};

                            return (
                                <Step sx={{ px: 0 }} key={label} {...stepProps}>
                                    <StepLabel StepIconComponent={QontoStepIcon} />
                                </Step>
                            );
                        })}
                    </MobileStepper>
                    {activeStep + 1 <= steps.length && (
                        <Grid item xs={12} marginBottom={3}>
                            <Typography variant="body1" color="text.primary" fontWeight={500}>
                                PASO {activeStep + 1}/{steps.length}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            )}
            {activeStep === steps.length ? <SuccessConfirmation /> : getStepContent(activeStep)}
        </BicolumnLayout>
    );
};

export default CustomerOnboarding;
