import { Badge, BadgeProps, Grid, MenuList, styled } from '@mui/material';
import { IconBtn, IconBtnProps } from '@fairplay2/ui';

export const SettingsIconBtn = styled(IconBtn, { shouldForwardProp: (prop) => prop !== 'error' })<
    IconBtnProps & { error?: boolean }
>(({ theme, error }) => ({
    color: error ? theme.palette.error.main : theme.palette.secondary.main,
    '&:hover': {
        color: error ? theme.palette.error.dark : theme.palette.secondary.dark,
        backgroundColor: 'transparent',
    },
}));

export const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        top: 25,
        padding: '0 2px',
        background: theme.palette.primary.A400,
        color: theme.palette.primary.contrastText,
    },
}));

export const topbarTooltipStyles = (forcedOpen: boolean) => (theme: any) => ({
    cursor: 'pointer',
    backgroundColor: forcedOpen ? theme.palette.primary.contrastText : theme.palette.primary.A400,
    textDecoration: 'underline',
    color: forcedOpen ? theme.palette.error.main : theme.palette.primary.contrastText,
    maxWidth: 75,
    borderRadius: '0 0 10px 10px',
    fontSize: 11,
    border: `1px solid ${forcedOpen ? theme.palette.error.main : theme.palette.primary.A400}`,
    padding: theme.spacing(1.5, 1, 1, 1.6),
    lineHeight: 1.3,
    '.tooltip-icon': {
        color: theme.palette.secondary.main,
        display: 'block',
        marginLeft: 'auto',
        fontSize: '1.7em',
        marginTop: 4,
    },
});

export const StyledMenuList = styled(MenuList)({
    maxHeight: 300,
    overflowY: 'auto',
    '@media (max-height: 650px)': {
        maxHeight: 120,
    },
    '@media (max-height: 580px)': {
        maxHeight: 70,
    },
});

export const NavbarFooterContainer = styled(Grid)(({ theme }) => ({
    paddingBottom: '10px',
    background: theme.palette.primary.dark,
}));

export const NavbarFooterLink = styled('a')({
    color: 'inherit',
    textDecoration: 'none',
});
