import img from 'assets/img/connectors/Shopify.png';
import ConnectorForm from 'components/dashboard/data-sources/platforms/shopify/ConnectorForm';
import { PlatformItem } from '../interfaces';
import BasePlatformItem from '../components/BasePlatformItem';
import useDualForm from '../useDualForm';

// eslint-disable-next-line react/prop-types
const Shopify: PlatformItem = ({ notify }) => {
    const { showForm, connector, onConnect, closeForm, onReauth } = useDualForm();

    return (
        <BasePlatformItem
            picture={img}
            groupKey="shopify"
            notify={notify}
            onConnect={onConnect}
            onReauth={onReauth}
        >
            <ConnectorForm open={showForm} onClose={closeForm} connector={connector} />
        </BasePlatformItem>
    );
};

export default Shopify;
