import { FC, useState, useEffect, useContext, ChangeEvent, FormEvent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme, useMediaQuery, Grid, Collapse, Alert, Button } from '@mui/material';
import { MailOutline } from '@mui/icons-material';
import {
    DefaultInput,
    DefaultBtn,
    Typography,
    InterpunctLoader,
    PasswordInput,
    FairplayLogoGraphic,
} from '@fairplay2/ui';
import { ReCaptcha, Footer } from 'common-components';
import fairplayAPI, { endSession } from 'utils/api';
import { handleUtmParams } from 'utils/handleParams';
import { validateEmail } from 'utils/validation';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { type ErrorObj } from 'utils/error-handler';
import { useRecaptcha, useAlert } from 'utils/hooks';
import sessionContext from 'context/session/sessionContext';
import RecoverPassword from './components/RecoverPassword';
import Sliders from './components/Sliders';
import WelcomePage from './components/WelcomePage';
import { Props, Form, Slider } from './interfaces';
import { Background, SliderContainer } from './styles';

const DEFAULT_FORM_VALUES = {
    email: '',
    password: '',
};

const SigninComponent: FC<Props> = ({ match }) => {
    const location = useLocation<any>(),
        theme = useTheme(),
        mdDown = useMediaQuery(theme.breakpoints.down('md')),
        { onLoadRecaptcha, resetRecaptcha, recaptchaIsLoading, recaptchaRef } = useRecaptcha(),
        { logout, login, getUser } = useContext(sessionContext),
        history = useHistory(),
        [showOnboardingWelcome, setShowOnboardingWelcome] = useState(false),
        [resetPassword, setResetPassword] = useState<boolean>(false),
        [loading, setLoading] = useState(false),
        [values, setValues] = useState<Form>(DEFAULT_FORM_VALUES),
        [errors, setErrors] = useState<Form>(DEFAULT_FORM_VALUES),
        { alert, hideAlert, showAlert } = useAlert(),
        welcomePageInfo: Slider[] = [
            {
                title: 'Monitorea y mide el desempeño de tus ventas.',
                description: 'Crea tu cuenta.',
            },
            {
                title: '¿Estás interesad@ en acceder a financiamiento?',
                description: '¡Regístrate!',
            },
            {
                title: '¿Ya tienes una cuenta? ',
                description: (
                    <Typography
                        component="span"
                        color="primary.main"
                        fontWeight={700}
                        onClick={() => {
                            setShowOnboardingWelcome(false);
                        }}
                        sx={{
                            '&:hover': {
                                cursor: 'pointer',
                            },
                            textDecoration: 'underline',
                        }}
                    >
                        Inicia sesión aquí
                    </Typography>
                ),
                lineBreak: false,
            },
        ];

    const clearForm = () => {
        setValues(DEFAULT_FORM_VALUES);
        setErrors(DEFAULT_FORM_VALUES);
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        hideAlert();
        const { name } = event.target;
        const { value } = event.target;
        if (name === 'email') setValues((prev) => ({ ...prev, [name]: value.toLowerCase() }));
        else setValues((prev) => ({ ...prev, [name]: value }));
    };

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        const recaptchaValue = recaptchaRef.current?.getValue();
        e.preventDefault();

        hideAlert();

        if (recaptchaValue) {
            setLoading(true);
            const data = {
                email: values.email,
                password: values.password,
                g_recaptcha_response: recaptchaValue,
            };
            try {
                await login(data);
                clearForm();

                if (match.params.token) {
                    const formData = new FormData();
                    formData.append('token', match.params.token);

                    await fairplayAPI.post('/v1/companies/collaborators/confirm', formData);
                    await getUser();
                }

                history.push('/app/sources');
            } catch (errResponse: ErrorObj | any) {
                if (errResponse?.type === 'form') {
                    const { token: tokenError, ...nonTokenErrors } = errResponse.error;

                    if (tokenError) {
                        showAlert(tokenError);
                        endSession();
                        /*  If the error is not caused by an invalid invite token,
                            AND a type="form", errors for password and/or email
                            will be provided in the response */
                    } else setErrors((prev) => ({ ...prev, ...nonTokenErrors }));
                } else showAlert(errResponse?.error);
            } finally {
                setLoading(false);
                resetRecaptcha();
            }
        } else if (Object.values(errors).every((error) => error === ''))
            showAlert('Es necesario completar el reCAPTCHA');
    };

    const onRecoverPassword = () => {
        setResetPassword(true);
        hideAlert();
    };

    const onCancel = () => {
        setResetPassword(false);
    };

    const onChangeInput = (prop: keyof Form, errorMsg: string) => {
        if (values[prop]) {
            switch (prop) {
                case 'email': {
                    const emailError = validateEmail(values.email);
                    setErrors((prev) => ({ ...prev, email: emailError }));
                    break;
                }
                default:
                    setErrors((prev) => ({ ...prev, [prop]: '' }));
                    break;
            }
        } else setErrors((prev) => ({ ...prev, [prop]: errorMsg }));
    };

    useEffect(() => {
        // Open ResetPassword if previous path is onboarding
        if (location?.state?.prevPath === '/app/onboarding') {
            setResetPassword(true);
            history.replace({
                state: {},
            });
        }
        logout();
        // Save UTM parameters of URL in LocalStorage
        handleUtmParams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container>
            <Background item container sm={12} md={4} justifyContent="center" alignItems="center">
                <Grid
                    item
                    container
                    direction="column"
                    px={showOnboardingWelcome ? 2 : 0}
                    {...(showOnboardingWelcome
                        ? {
                              maxWidth: '345px',
                          }
                        : { xs: 10, sm: 6, md: 9, lg: 7, xl: 6 })}
                >
                    <Grid item xs={12} mt="20px">
                        <FairplayLogoGraphic width={201} />
                        <Typography
                            variant="subtitle2"
                            color="secondary.main"
                            fontWeight={700}
                            mt="-10px"
                        >
                            INSIGHTS
                        </Typography>
                    </Grid>
                    {!resetPassword ? (
                        <Grid
                            item
                            container
                            xs={12}
                            mb={{ xs: '140px', sm: '190px', md: '210px' }}
                            direction="column"
                            component="form"
                            onSubmit={onSubmit}
                        >
                            {!showOnboardingWelcome ? (
                                <>
                                    <Typography
                                        variant="h4"
                                        color="primary.contrastText"
                                        mt="50px"
                                        gutterBottom
                                    >
                                        Welcome back!
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="primary.contrastText"
                                        fontWeight={700}
                                        mb="20px"
                                    >
                                        ¿Eres nuevo?{' '}
                                        <Typography
                                            component="span"
                                            color="secondary.main"
                                            fontWeight={700}
                                            onClick={() => {
                                                setShowOnboardingWelcome(true);
                                            }}
                                            sx={{
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                },
                                            }}
                                        >
                                            Crea una cuenta aquí
                                        </Typography>
                                    </Typography>
                                    <DefaultInput
                                        name="email"
                                        placeholder="Email"
                                        label="Email"
                                        value={values.email}
                                        onChange={onChange}
                                        onBlur={() => onChangeInput('email', 'Ingresa un email')}
                                        error={errors.email}
                                        required
                                        startAdornment={<MailOutline />}
                                        sx={{ mb: '20px' }}
                                        forceLegibility
                                        autoComplete="email"
                                    />
                                    <PasswordInput
                                        name="password"
                                        placeholder="Contraseña"
                                        label="Contraseña"
                                        value={values.password}
                                        onChange={onChange}
                                        onBlur={() =>
                                            onChangeInput('password', 'Ingresa la contraseña')
                                        }
                                        error={errors.password}
                                        forceLegibility
                                        sx={{
                                            mb: '20px',
                                        }}
                                        required
                                        forceHiddenText={loading}
                                    />
                                    <Grid
                                        item
                                        container
                                        justifyContent="center"
                                        mb={alert.open ? '' : '20px'}
                                        sx={{
                                            transform: {
                                                lg: 'scale(0.9)',
                                                xl: 'scale(1)',
                                            },
                                        }}
                                    >
                                        <ReCaptcha
                                            ref={recaptchaRef}
                                            isLoading={recaptchaIsLoading}
                                            onLoad={onLoadRecaptcha}
                                        />
                                    </Grid>
                                    <Collapse in={alert.open}>
                                        <Grid
                                            container
                                            justifyContent="center"
                                            alignItems="center"
                                            item
                                            my={alert.open ? '20px' : ''}
                                            xs={12}
                                        >
                                            <Alert variant="filled" severity={alert.type}>
                                                {alert.message}
                                            </Alert>
                                        </Grid>
                                    </Collapse>
                                    <Grid
                                        item
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        xs={12}
                                    >
                                        {loading ? (
                                            <InterpunctLoader />
                                        ) : (
                                            !recaptchaIsLoading && (
                                                <DefaultBtn type="submit" color="mixed" fullWidth>
                                                    Iniciar sesión
                                                </DefaultBtn>
                                            )
                                        )}
                                    </Grid>
                                    <Button
                                        variant="text"
                                        color="mixed"
                                        fullWidth
                                        sx={{ mt: '20px' }}
                                        onClick={onRecoverPassword}
                                    >
                                        ¿Olvidaste tu contraseña?
                                    </Button>
                                </>
                            ) : (
                                <WelcomePage
                                    returnToLogin={() => setShowOnboardingWelcome(false)}
                                />
                            )}
                        </Grid>
                    ) : (
                        <RecoverPassword onCancel={onCancel} />
                    )}
                    <Footer />
                </Grid>
            </Background>
            {!mdDown && (
                <SliderContainer
                    item
                    container
                    md={8}
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    direction="column"
                >
                    {showOnboardingWelcome && (
                        <Grid item width="553px">
                            <Typography variant="h3" color="primary.dark">
                                ¡Hola!{' '}
                                <Typography component="span" color="secondary.main">
                                    Bienvenid&#64;
                                </Typography>
                            </Typography>
                        </Grid>
                    )}
                    <Sliders information={showOnboardingWelcome ? welcomePageInfo : undefined} />
                </SliderContainer>
            )}
        </Grid>
    );
};

export default SigninComponent;
