import { FieldValues, Path, UseFormSetError } from 'react-hook-form';
import { captureException as sentryCaptureException } from '@sentry/react';
import { snakeToCamel } from 'utils/formatting';

export interface ErrorObj {
    error: string | object | any;
    status: string | number;
    type: 'cors' | 'non_field' | 'detail' | 'form' | 'server' | 'undefined' | '';
}

/**
 * @returns Valid values to render for each affected input in the form
 */
export const simplifyFormErrors = (fieldErrors: Record<string, any>, camelCaseFields = false) =>
    Object.entries(fieldErrors).reduce((acc, [_field, error]) => {
        if (!error) return acc;

        const field = camelCaseFields ? snakeToCamel(_field) : _field;
        if (typeof error === 'string') acc[field] = error;
        else if (Array.isArray(error)) acc[field] = error.join(', ');
        else if (typeof error === 'object') acc[field] = Object.values<string>(error).join(', ');
        else acc[field] = `${error}`;

        return acc;
    }, {} as Record<string, string>);

/**
 * Extracts the main error message for specific scenarios
 * e.g. detail, message, code
 *
 * @param msg
 * @returns Main error message in the object
 */
export const getMainError = (msg: any) => {
    if (!msg) return '';
    if (Array.isArray(msg)) return msg[0] as string;
    if (typeof msg === 'object' && Object.values(msg)) {
        const errors: any[] = Object.values(msg);
        if (Array.isArray(errors)) return errors[0].toString();
    }
    // return default value as it can be a string
    return msg;
};

const responseHandler = (res: any): ErrorObj => {
    // Returns specific errors for magento
    if (res?.data?.errors?.code === '109')
        return {
            error: 'La URL de la tienda no es válida.',
            status: 400,
            type: 'detail',
        };

    if (res?.data?.errors?.code === '8')
        return {
            error: 'La URL de la tienda ya ha sido registrada',
            status: 400,
            type: 'detail',
        };

    // Returns error data for special detail case
    if (res?.data?.detail)
        return {
            error: getMainError(res.data?.detail),
            status: res.status,
            type: 'detail',
        };

    if (res?.data?.errors?.detail)
        return {
            error: getMainError(res?.data?.errors?.detail),
            status: res.status,
            type: 'detail',
        };

    if (res?.data?.errors?.status)
        return {
            error: getMainError(res?.data?.errors?.status),
            status: res.status,
            type: 'detail',
        };

    // Returns error message for Facebook connectors accessToken type
    if (res?.data?.errors?.accessToken)
        return {
            error: getMainError(res?.data?.errors?.accessToken[0]),
            status: res.status,
            type: 'detail',
        };

    // Returns error message for Facebook connectors Message type
    if (res?.data?.error?.message)
        return {
            error: getMainError(res?.data?.error?.message),
            status: res.status,
            type: 'detail',
        };

    // Returns error message for Google connectors Code type
    if (res?.data.errors?.code)
        return {
            error: getMainError(res.data.errors.code),
            status: res.status,
            type: 'detail',
        };

    // Returns error message for Google connectors existing clientEmail error.
    if (res?.data.errors?.clientEmail)
        return {
            error: getMainError(res.data.errors.clientEmail),
            status: res.status,
            type: 'detail',
        };

    // Returns error data for non-field errors.
    if (res.data.errors?.non_field_errors)
        return {
            error: getMainError(res.data.errors.non_field_errors),
            status: res.status,
            type: 'non_field',
        };

    // Returns error data for non-field errors.
    if (res.data.errors?.nonFieldErrors)
        return {
            error: getMainError(res.data.errors.nonFieldErrors),
            status: res.status,
            type: 'non_field',
        };

    // Returns error message for downloadable invoice not found.
    if (res.data.errors?.xmlFile)
        return {
            error: getMainError(res.data.errors.xmlFile),
            status: res.status,
            type: 'detail',
        };

    // Returns error message for disbursement cart errors.
    if (res.data.errors?.error)
        return {
            error: getMainError(res.data.errors.error),
            status: res.status,
            type: 'detail',
        };

    // Returns error data for error array/object with form field errors.
    // It's important for this one to be the last check, since previous checks all have the data.errors value.
    if (res.data?.errors) {
        const { errors } = res.data;

        return Array.isArray(errors) && typeof errors[0] === 'string'
            ? { error: errors.join(', '), status: res.status, type: 'detail' }
            : // Returns error data for error array/object with form field errors.
              {
                  error: errors,
                  status: res.status,
                  type: 'form',
              };
    }

    // Returns default case for unhandled/undefined errors.
    return {
        error: 'Se ha producido un error. Intente más tarde',
        status: res.status,
        type: 'undefined',
    };
};

export const handleError = (error: any): ErrorObj => {
    // Check for CORS error. Returns parsed object.
    if (typeof error.response === 'undefined') {
        return {
            error: 'No hubo respuesta del servidor, vuelva a intentarlo.',
            status: 502,
            type: 'cors',
        };
    }

    // Check if reponse was returned from request
    if (error.response) {
        if (error.response.status === 413) {
            return {
                error: 'El archivo es muy grande, elige un archivo más pequeño',
                status: error.response.status,
                type: 'server',
            };
        }
        // If error pertains to expected user/incomplete/unauthorized error.
        if (error.response.status >= 400 && error.response.status < 500) {
            return responseHandler(error.response);
        }

        // If error pertains to unexpected server error.
        if (error.response.status >= 500 && error.response.status < 600) {
            return {
                error: 'Se produjo un error en el servidor. Intente más tarde',
                status: error.response.status,
                type: 'server',
            };
        }
    }

    // Check if request was sent, but no response received.
    if (error.request)
        return {
            error: 'No se obtuvo respuesta del servidor. Intente más tarde',
            status: 503,
            type: 'server',
        };

    // Return default message for unhandled/undefined error object that didn't meet any other case.
    return {
        error: 'Se ha producido un error. Intente más tarde',
        status: 500,
        type: 'undefined',
    };
};

/**
 * Sets custom validation errors for form fields using the provided error object.
 * This function is designed to work with the `react-hook-form` library.
 *
 * @template T - Generic type parameter for the field values.
 * @param {RecordRecord<string, any>} errors - An object containing the errors to be set.
 * @param {UseFormSetError<T>} setError - A function from the react-hook-form library used to set form errors.
 * @returns {void}
 */
export const setFormErrors = <T extends FieldValues>(
    errors: Record<string, any>,
    setError: UseFormSetError<T>,
): void => {
    Object.entries(simplifyFormErrors(errors, true)).forEach(([name, message]) => {
        setError(name as Path<T>, {
            type: 'custom',
            message,
        });
    });
};

export const captureException: typeof sentryCaptureException = (e, captureContext) => {
    /* istanbul ignore next */
    if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_DSN) {
        return captureException(e, captureContext);
    }
    /* eslint-disable no-console */
    console.group('capturedException');
    console.error(e);
    console.info(
        `You are not using a production build and/or Sentry is not enabled.
            The above exception was captured and logged instead. A mock eventId will be returned.
            The provided captureContext (if any) is attached here.`,
        { captureContext },
    );
    console.groupEnd();
    /* eslint-enable no-console */
    return '<MOCK ID>';
};
