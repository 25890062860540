import { FC } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { Checkbox, Tooltip, IconBtn, Typography, NoResults } from '@fairplay2/ui';
import { AccountsListProps } from '../interfaces';

const AccountsList: FC<AccountsListProps> = ({
    accounts,
    primaryAccount,
    onSelectAccount,
    onDelete,
}) => (
    <>
        <Typography variant="subtitle1" color="primary.main" mb="40px">
            Administra tus cuentas bancarias
        </Typography>
        <TableContainer sx={{ backgroundColor: '#FFFFFF', borderRadius: 'initial' }}>
            <Table size="small">
                <TableHead sx={{ opacity: accounts.length > 0 ? '1' : '0.5' }}>
                    <TableRow>
                        <TableCell align="center" padding="checkbox">
                            <Typography variant="body1" color="text.primary">
                                Principal
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" color="text.primary">
                                CLABE / SWIFT
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" color="text.primary">
                                Número de cuenta
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" color="text.primary">
                                Banco
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" color="text.primary">
                                Divisa
                            </Typography>
                        </TableCell>
                        <TableCell align="center">
                            <Typography variant="body1" color="text.primary">
                                Acciones
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {accounts.map((account, i) => (
                        <TableRow key={account.id} tabIndex={-1}>
                            <TableCell padding="checkbox" align="center">
                                <Checkbox
                                    inputProps={{
                                        'aria-label':
                                            primaryAccount === i
                                                ? 'Esta es la cuenta principal'
                                                : 'Seleccionar como cuenta principal',
                                    }}
                                    checked={primaryAccount === i}
                                    onClick={(event) => onSelectAccount(event, i)}
                                />
                            </TableCell>
                            <TableCell>
                                <Typography
                                    data-testid="clabe-swift"
                                    variant="body1"
                                    color="primary.main"
                                    fontWeight={700}
                                >
                                    {account.swift ? account.swift : account.clabe}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    data-testid="accountNumber"
                                    variant="body1"
                                    color="primary.main"
                                    fontWeight={700}
                                >
                                    {account.accountNumber}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    color="primary.dark"
                                    data-testid="accountBank"
                                    variant="body1"
                                >
                                    {account.bank}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    color="primary.dark"
                                    data-testid="accountCurrency"
                                    variant="body1"
                                >
                                    {account.accountCurrency?.id}
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Tooltip title="Eliminar" placement="right-start">
                                    <IconBtn
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => onDelete(account)}
                                        data-testid="Eliminar cuenta bancaria"
                                    >
                                        <Delete color="secondary" />
                                    </IconBtn>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {accounts.length === 0 && <NoResults>No hemos encontrado cuentas bancarias</NoResults>}
        </TableContainer>
    </>
);

export default AccountsList;
