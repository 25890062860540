import { FC, useEffect } from 'react';
import { Typography } from '@fairplay2/ui';
import { Alert, Grid, TableCell, TableRow } from '@mui/material';
import { useAlert } from 'utils/hooks';
import { AlertProps } from 'utils/interfaces';
import { ChannelsTableItemProps } from './interfaces';
import { StyledTypography } from './ChannelsTableItem.styles';

const ChannelsTableItemCells = ({
    alert,
    category,
    totals,
}: {
    alert: AlertProps;
    category: ChannelsTableItemProps['category'];
    totals: ChannelsTableItemProps['totals'];
}) => {
    const AlertComponent = (
        <Alert severity={alert.type} sx={{ width: 'fit-content' }}>
            {alert.message}
        </Alert>
    );
    if (!alert.open) {
        return (
            <>
                <TableCell>
                    <Typography color="primary.dark" fontWeight={500} textAlign="right">
                        {totals[category].periodPercentage}%
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography color="primary.dark" fontWeight={500} textAlign="right">
                        {totals[category].prevPeriodPercentage}%
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography
                        color={
                            totals[category].growthPercentage! > 0 ? 'success.main' : 'error.main'
                        }
                        fontWeight={500}
                        textAlign="right"
                    >
                        {totals[category].growthPercentage}%
                    </Typography>
                </TableCell>
            </>
        );
    }
    if (alert.type === 'warning')
        return (
            <>
                <TableCell>
                    <Typography color="primary.dark" fontWeight={500} textAlign="right">
                        {totals[category].periodPercentage}%
                    </Typography>
                </TableCell>
                <TableCell colSpan={2}>{AlertComponent}</TableCell>
            </>
        );
    return <TableCell colSpan={3}>{AlertComponent}</TableCell>;
};

const ChannelsTableItem: FC<ChannelsTableItemProps> = ({ name, totals, category, color }) => {
    const { alert, showAlert, hideAlert } = useAlert();

    // TODO: Extract logic into a more abstract form
    useEffect(() => {
        if (Object.values(totals[category]).every((value) => value === null))
            showAlert('Error de conexión', 'error', false);
        else if (totals[category].growthPercentage === null)
            showAlert('No hay datos suficientes', 'warning', false);
        else hideAlert();
    }, [category, totals, showAlert, hideAlert]);

    return (
        <TableRow>
            <TableCell>
                <Grid container alignItems="center" wrap="nowrap">
                    <StyledTypography
                        minWidth="100px"
                        color="primary.main"
                        fontWeight={700}
                        tagColor={color}
                    >
                        {name}
                    </StyledTypography>
                </Grid>
            </TableCell>
            <ChannelsTableItemCells alert={alert} category={category} totals={totals} />
        </TableRow>
    );
};

export default ChannelsTableItem;
