import { Grid } from '@mui/material';
import { Typography, Skeleton } from '@fairplay2/ui';

const SelectContractSkeleton = () => (
    <Grid
        item
        container
        mt={[0, 0, '25px']}
        columnSpacing={2}
        rowSpacing={[2, 2, 0]}
        alignItems="baseline"
        sx={{
            flexGrow: 1,
            flexBasis: 0,
        }}
        data-testid="select-contract-skeleton"
    >
        <Grid item xs={12} sm={4}>
            <Typography variant="label">Periodo de dispersión</Typography>
            <Skeleton height={53} />
        </Grid>
        <Grid item xs={12} sm={4}>
            <Typography variant="label">Solicitado en el periodo</Typography>
            <Skeleton height={53} />
        </Grid>
        <Grid item xs={12} sm={4}>
            <Typography variant="label">Disponible hoy</Typography>
            <Skeleton height={53} />
        </Grid>
    </Grid>
);

export default SelectContractSkeleton;
