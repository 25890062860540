import { ChartFactory } from 'utils/formatting';
import { CHART_MARKERS_SYMBOLS, COLORS_DIC } from '../utils/constants';
import { FilterValuesObj } from './components/interfaces';
import { TOTALS_TABLE_SUMARIES_EMPTY } from './components/mock/values';
import { ConnectedStore, SalesAction, SalesState, SummariesKeys, TabData } from './interfaces';

export const getMainChartData = (
    data: {
        label: string;
        value: number[][];
    }[],
    title: string,
    valuePrefix?: string,
) =>
    ChartFactory({
        plotOptions: {
            series: {
                marker: {
                    enabled: true,
                },
            },
        },
        series: data.map((item, index) => ({
            type: 'spline',
            color: COLORS_DIC[index % COLORS_DIC.length],
            data: item.value,
            marker: {
                symbol: CHART_MARKERS_SYMBOLS[index % CHART_MARKERS_SYMBOLS.length],
            },
            name: item.label,
        })),
        yTitle: title,
        valuePrefix,
    });

export const getChannelsChartData = (data: Array<any>, att: string) => {
    if (data.length <= 0) return {};

    return ChartFactory({
        series: [
            {
                type: 'pie',
                name: '',
                color: '',
                data: data.map((item: any) => ({
                    name: item.name,
                    y: item.totals[att].periodPercentage,
                })),
                colors: COLORS_DIC,
            },
        ],
    });
};

const mainChartData: SalesState['mainChartData'] = {
        gmv: {
            isEmpty: true,
            data: [],
            title: 'Ventas',
            valuePrefix: '$',
        },
        orders: {
            isEmpty: true,
            data: [],
            title: 'Órdenes',
            valuePrefix: '',
        },
        average: {
            isEmpty: true,
            data: [],
            title: 'Ticket promedio',
            valuePrefix: '$',
        },
    },
    DEFAULT_FILTERS = {
        unconfirmed: {
            selectedDayRange: {
                from: undefined,
                to: undefined,
            },
            year: [''],
            month: [''],
            store: [''],
            status: [''],
        } as FilterValuesObj,
        effective: {
            date_range_start: '',
            date_range_end: '',
            years: '',
            months: '',
            stores: '',
            status: '',
        },
    },
    salesTab: TabData = {
        emptyData: true,
        indicator: 'below',
        insuficientData: false,
        percentage: 0,
        status: 'Válido',
        title: 'Ventas',
        value: '0',
    },
    ordersTab: TabData = {
        emptyData: true,
        indicator: 'below',
        insuficientData: false,
        isNumber: false,
        percentage: 0,
        status: 'Válido',
        title: 'Órdenes',
        value: '0',
    },
    avergageTicketTab: TabData = {
        emptyData: true,
        indicator: 'below',
        insuficientData: false,
        percentage: 0,
        status: 'Válido',
        title: 'Ticket Promedio',
        value: '0',
    };

export const SalesInitialState: SalesState = {
    generalResults: [],
    connectedStores: [],
    informationModal: { type: null, affectedStores: [] },
    activeFilters: 0,
    allowSearch: false,
    channels: {
        count: 0,
        pieChartData: [],
        tableData: [],
        loading: true,
        page: 0,
        requestParams: '',
        rowsPerPage: 5,
    },
    externalFilters: DEFAULT_FILTERS.effective,
    filteredValues: undefined,
    filterValues: DEFAULT_FILTERS.unconfirmed,
    isFilterOff: true,
    isOpenAddConnectionModal: false,
    loading: true,
    mainChartData,
    lastMainChartUpdate: undefined,
    periods: {
        count: 0,
        tableData: [],
        loading: true,
        page: 0,
        requestParams: '',
        rowsPerPage: 5,
        summary: TOTALS_TABLE_SUMARIES_EMPTY,
    },
    stores: {
        '': 'Tienda',
        all: 'Todas',
    },
    tabsData: {
        gmv: salesTab,
        orders: ordersTab,
        average: avergageTicketTab,
    },
    tabValue: 'gmv',
};

const hasErrorStatus = (store: ConnectedStore) =>
    store.status === 'reauthorize' || store.status === 'invalid';

const hasFssInProcess = (store: ConnectedStore) => store.fss === 'collecting-data';

const hasUneditedAlias = (store: ConnectedStore) => store.alias_edited_by_user === false;

const getAffectedStoreName =
    (conditionFn: (store: ConnectedStore) => boolean) => (store: ConnectedStore) =>
        conditionFn(store) ? [store.alias] : [];

const getInformationModal = (connectedStores: ConnectedStore[]) => {
    let informationModal: SalesState['informationModal'] = {
        type: null,
        affectedStores: [],
    };

    if (connectedStores.some(hasErrorStatus)) {
        informationModal = {
            type: 'error',
            affectedStores: connectedStores.flatMap(getAffectedStoreName(hasErrorStatus)),
        };
    } else if (connectedStores.some(hasFssInProcess)) {
        informationModal = {
            type: 'pending',
            affectedStores: connectedStores.flatMap(getAffectedStoreName(hasFssInProcess)),
        };
    } else if (connectedStores.some(hasUneditedAlias)) {
        informationModal = {
            type: 'almostReady',
            affectedStores: connectedStores.flatMap(getAffectedStoreName(hasUneditedAlias)),
        };
    }

    return informationModal;
};

export const salesReducer = (state: SalesState, action: SalesAction) => {
    switch (action.type) {
        case 'SET_GENERAL_RESULTS': {
            const connectedStores = action.payload.results.slice(1) as ConnectedStore[],
                informationModal = action.payload.displayInformationModal
                    ? getInformationModal(connectedStores)
                    : state.informationModal;

            return {
                ...state,
                connectedStores,
                informationModal,
                generalResults: action.payload.results,
            };
        }
        case 'SET_COUNT':
            return {
                ...state,
                [action.payload.key]: {
                    ...(state[action.payload.key as keyof SalesState] as object),
                    count: action.payload.value,
                },
            };
        case 'SET_PAGE':
            return {
                ...state,
                [action.payload.key]: {
                    ...(state[action.payload.key as keyof SalesState] as object),
                    page: action.payload.value,
                },
            };
        case 'SET_ROWS_PER_PAGE':
            return {
                ...state,
                [action.payload.key]: {
                    ...(state[action.payload.key as keyof SalesState] as object),
                    rowsPerPage: action.payload.value,
                },
            };
        case 'SET_REQUEST_PARAMS':
            return {
                ...state,
                [action.payload.key]: {
                    ...(state[action.payload.key as keyof SalesState] as object),
                    requestParams: action.payload.value,
                },
            };
        case 'SET_PERIODS_DATA':
            return {
                ...state,
                periods: {
                    ...state.periods,
                    tableData: action.payload,
                },
            };
        case 'SET_PERIODS_SUMMARY':
            return {
                ...state,
                periods: {
                    ...state.periods,
                    summary: action.payload,
                },
            };
        case 'SET_CHANNELS_DATA':
            return {
                ...state,
                channels: {
                    ...state.channels,
                    pieChartData: action.payload,
                },
            };
        case 'SET_CHANNELS_TABLE_DATA':
            return {
                ...state,
                channels: {
                    ...state.channels,
                    tableData: action.payload.tableData,
                    count: action.payload.count,
                    ...(action.payload.page !== undefined && {
                        page: action.payload.page,
                    }),
                    ...(action.payload.rowsPerPage !== undefined && {
                        rowsPerPage: action.payload.rowsPerPage,
                    }),
                },
            };
        case 'SET_MAIN_CHART_DATA':
            return {
                ...state,
                lastMainChartUpdate: new Date(),
                mainChartData: Object.keys(action.payload).reduce((acc, key) => {
                    acc[key as SummariesKeys] = {
                        data: action.payload[key as SummariesKeys].data,
                        title: action.payload[key as SummariesKeys].title,
                        valuePrefix: action.payload[key as SummariesKeys].valuePrefix,
                        isEmpty: action.payload[key as SummariesKeys].data.every(
                            (item) => item.value.length === 0,
                        ),
                    };
                    return acc;
                }, {} as SalesState['mainChartData']),
            };
        case 'SET_TABS_DATA':
            return {
                ...state,
                tabsData: {
                    ...state.tabsData,
                    ...action.payload,
                },
            };
        case 'SET_EXTERNAL_FILTERS':
            return {
                ...state,
                externalFilters: {
                    ...state.externalFilters,
                    ...action.payload,
                },
            };
        case 'SET_FILTER_VALUES': {
            const monthsValue = action.payload.month.filter((month) => month !== 'all'),
                isStatusEmpty = action.payload.status[0] === '',
                isStoreEmpty = action.payload.store[0] === '',
                isDaily =
                    action.payload.selectedDayRange?.from !== undefined &&
                    action.payload.selectedDayRange?.to !== undefined,
                isMonthly = action.payload.year[0] !== '' && monthsValue[0] !== '';

            return {
                ...state,
                allowSearch: !isStatusEmpty && !isStoreEmpty && (isDaily || isMonthly),
                filterValues: {
                    ...state.filterValues,
                    ...action.payload,
                },
                isFilterOff: false,
            };
        }
        case 'SET_FILTERED_VALUES':
            return {
                ...state,
                filteredValues: {
                    ...state.filteredValues,
                    ...action.payload,
                },
            };
        case 'RESET_FILTER_VALUES':
            return {
                ...state,
                activeFilters: SalesInitialState.activeFilters,
                allowSearch: SalesInitialState.allowSearch,
                externalFilters: SalesInitialState.externalFilters,
                filteredValues: SalesInitialState.filteredValues,
                filterValues: SalesInitialState.filterValues,
                isFilterOff: SalesInitialState.isFilterOff,
            };
        case 'SET_ACTIVE_FILTERS':
            return {
                ...state,
                activeFilters: action.payload,
            };
        case 'SET_TAB_VALUE':
            return {
                ...state,
                tabValue: action.payload,
            };
        case 'SET_STORES':
            return {
                ...state,
                stores: action.payload,
            };
        case 'HIDE_INFORMATION_MODAL':
            return {
                ...state,
                informationModal: {
                    type: null,
                    // Keep the data to avoid visual flickering
                    affectedStores: state.informationModal.affectedStores,
                },
            };
        case 'TOGGLE_ADD_CONNECTION_MODAL':
            return {
                ...state,
                isOpenAddConnectionModal: !state.isOpenAddConnectionModal,
            };
        case 'STOP_LOADING_PERIODS':
            return {
                ...state,
                periods: {
                    ...state.periods,
                    loading: false,
                },
            };
        case 'STOP_LOADING_CHANNELS':
            return {
                ...state,
                channels: {
                    ...state.channels,
                    loading: false,
                },
            };
        case 'STOP_LOADING':
            return {
                ...state,
                loading: false,
            };
        case 'START_LOADING_PERIODS':
            return {
                ...state,
                periods: {
                    ...state.periods,
                    loading: true,
                },
            };
        case 'START_LOADING_CHANNELS':
            return {
                ...state,
                channels: {
                    ...state.channels,
                    loading: true,
                },
            };
        case 'START_LOADING':
            return {
                ...state,
                loading: true,
            };
        case 'RESTART_LOADING':
            return {
                ...SalesInitialState,
            };
        default: {
            return state;
        }
    }
};
