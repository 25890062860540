import { downloadFile } from 'utils/files';
import { TypographyProps } from '@mui/material';
import { StatusDic } from '../interfaces';

export const statusDic: StatusDic = {
    requested: 'Solicitado',
    refunded: 'Reembolsado',
    approved: 'Aprobado',
    executed: 'Ejecutado',
    rejected: 'Rechazado',
    cancelled: 'Cancelado',
};

export const getStatusColor = (status: string) => {
    const statusColorDictionary: { [key: string]: Exclude<TypographyProps['color'], undefined> } = {
        requested: 'info.main',
        refunded: 'info.main',
        approved: 'success.main',
        executed: 'success.main',
        rejected: 'error.main',
        cancelled: 'error.main',
    };
    return statusColorDictionary[status] || 'info.main';
};

/**
 *
 * @deprecated Use function from `utils/parsing` instead.
 */
export const formatAmount = (num: number) =>
    num.toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });

export const openLink = (url: string) => {
    if (url) window.open(url, '_blank', 'noopener,noreferrer');
};

export const downloadSlip = async (companyId: string, contractId: string, id: string) => {
    const url = `/v2/companies/${companyId}/contracts/${contractId}/dispersions/${id}/download`;
    await downloadFile('application/pdf', `${id}.pdf`, url);
};
