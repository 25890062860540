import { Typography } from '@fairplay2/ui';
import { Grid, Link } from '@mui/material';
import { AppleStoreSvg } from 'assets/SVGs';
import playstore from 'assets/img/playstore.png';

const AUTH_APPS_DOWNLOAD_URLS = [
    {
        name: 'Authy',
        playStore: 'https://play.google.com/store/apps/details?id=com.authy.authy',
        appleStore: 'https://apps.apple.com/mx/app/authy/id494168017',
    },
    {
        name: 'Microsoft authenticator',
        playStore: 'https://play.google.com/store/apps/details?id=com.azure.authenticator',
        appleStore: 'https://apps.apple.com/mx/app/microsoft-authenticator/id983156458',
    },
    {
        name: 'Google authenticator',
        playStore:
            'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
        appleStore: 'https://apps.apple.com/mx/app/google-authenticator/id388497605',
    },
];

export const Step2 = () => (
    <Grid container gap={3}>
        <Grid item xs={12}>
            <Typography fontWeight={500} variant="subtitle2">
                Instala en tu dispositivo móvil una de las siguientes aplicaciones:
            </Typography>
        </Grid>

        <Grid item container xs={12} spacing={2}>
            {AUTH_APPS_DOWNLOAD_URLS.map((app) => (
                <Grid item container key={app.name} alignItems="center">
                    <Grid item container xs={12} md={6}>
                        <Typography variant="subtitle2" fontWeight={700} color="primary.dark">
                            {app.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Link href={app.playStore} target="_blank" rel="noopener noreferrer">
                            <img
                                src={playstore}
                                alt="Google Play"
                                style={{
                                    width: '135px',
                                    height: 'auto',
                                }}
                            />
                        </Link>
                        <Link
                            href={app.appleStore}
                            target="_blank"
                            rel="noopener noreferrer"
                            ml="33px"
                        >
                            <AppleStoreSvg />
                        </Link>
                    </Grid>
                </Grid>
            ))}
        </Grid>

        <Grid item xs={12}>
            <Typography variant="subtitle2" fontWeight={500}>
                Si ya cuentas con alguna aplicación de autenticación instalada, puedes utilizarla.
            </Typography>
            <Typography variant="body1" fontWeight={500} mt="14px">
                Si tienes alguna duda de como instalar las aplicaciones consulta la{' '}
                <Link
                    href="https://guides.getfairplay.com/es-es/knowledgebase/article/KA-01111"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Typography component="span" color="primary.main" fontWeight={500}>
                        Guía de instalación
                    </Typography>
                </Link>
            </Typography>
        </Grid>
    </Grid>
);

export default Step2;
