import { FC, useState } from 'react';
import { ManSittingPlanSvg } from 'assets/SVGs';
import { Typography, DefaultBtn, Alert, InterpunctLoader } from '@fairplay2/ui';
import { ReCaptcha } from 'common-components';
import { Grid, Collapse } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj, simplifyFormErrors } from 'utils/error-handler';
import { useRecaptcha, useAlert } from 'utils/hooks';
import fairplayAPI from 'utils/api';

const EmailValidation: FC<{ email: string }> = ({ email }) => {
    const { alert, showAlert } = useAlert(),
        [showCaptcha, setShowCaptcha] = useState(false),
        [linkSent, setLinkSent] = useState(false),
        [loading, setLoading] = useState(false),
        { onLoadRecaptcha, recaptchaIsLoading, recaptchaRef, resetRecaptcha } = useRecaptcha();

    const onSend = async () => {
        if (!showCaptcha) {
            setShowCaptcha(true);
            return;
        }
        const recaptchaValue = recaptchaRef.current?.getValue();

        if (recaptchaValue) {
            setLoading(true);

            try {
                await fairplayAPI.post(
                    '/v1/users/register/resend-email',
                    {
                        email,
                        g_recaptcha_response: recaptchaValue,
                    },
                    { authorization: false },
                );
                showAlert('El correo se ha enviado nuevamente', 'success');
                setLinkSent(true);
            } catch (errResponse: ErrorObj | any) {
                let message = 'Se ha producido un error. Intente más tarde';

                if (errResponse?.type === 'form')
                    message = Object.values(simplifyFormErrors(errResponse.error)).join(', ');
                else message = errResponse?.error || message;

                showAlert(message);
            } finally {
                setLoading(false);
                resetRecaptcha();
            }

            setShowCaptcha(false);
        } else showAlert('Es necesario completar el reCAPTCHA');
    };

    return (
        <Grid
            item
            container
            mt={{ md: '30%' }}
            mb={6}
            xs={12}
            justifyContent="center"
            gap={{
                xs: 2,
                lg: 4,
            }}
        >
            <Grid
                item
                container
                xs={12}
                lg={4}
                mt={2}
                justifyContent={{
                    xs: 'center',
                    lg: 'right',
                }}
            >
                <ManSittingPlanSvg />
            </Grid>
            <Grid item container direction="column" xs={12} md={7} mt={2}>
                <Grid item>
                    <Typography
                        color="primary.dark"
                        variant="subtitle2"
                        fontWeight={700}
                        pl={1}
                        data-testid="email-sent-to"
                    >
                        Hemos enviado un correo electrónico a{' '}
                        <Typography
                            color="primary.A400"
                            component="span"
                            variant="subtitle2"
                            fontWeight={700}
                        >
                            {email}
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item mt={2}>
                    <Typography variant="subtitle2" color="text.primary" pl={1} fontWeight={500}>
                        Por favor, revisa tu bandeja de entrada y sigue las instrucciones del correo
                        electrónico. Si no encuentras el correo electrónico en tu bandeja de
                        entrada, revisa tu carpeta de spam o correo no deseado.
                    </Typography>
                </Grid>

                <Collapse in={showCaptcha}>
                    <Grid item container gap={2} mt={2}>
                        <Grid item>
                            <Typography
                                variant="body2"
                                fontWeight={700}
                                pl={1}
                                color="primary.dark"
                            >
                                Si deseas recibir otro link por favor activa la casilla de
                                verificación y haz click en confirmar.
                            </Typography>
                        </Grid>

                        <Grid item container pl={1}>
                            <ReCaptcha
                                ref={recaptchaRef}
                                isLoading={recaptchaIsLoading}
                                onLoad={onLoadRecaptcha}
                            />
                        </Grid>
                    </Grid>
                </Collapse>

                <Collapse in={alert.open}>
                    <Grid container item xs={12} pl={1} mt={2}>
                        <Alert variant="filled" severity={alert.type}>
                            {alert.message}
                        </Alert>
                    </Grid>
                </Collapse>

                {loading ? (
                    <Grid item mt={2}>
                        <InterpunctLoader />
                    </Grid>
                ) : (
                    <Grid item mt={2}>
                        <DefaultBtn
                            variant="text"
                            onClick={onSend}
                            sx={{
                                '&.MuiButton-root': {
                                    fontWeight: 500,
                                },
                            }}
                        >
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {linkSent && !showCaptcha
                                ? 'No recibí el link'
                                : showCaptcha
                                ? 'Confirmar el envío'
                                : 'Reenviar correo electrónico'}
                        </DefaultBtn>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default EmailValidation;
