import { FC } from 'react';
import { Grid } from '@mui/material';
import { Typography, DefaultBtn } from '@fairplay2/ui';
import { WomanFoldersSvg } from 'assets/SVGs';
import { getConnectorAlias, getPlatformDisplayName } from '../utils';
import { MultipleStatusesSuccessProps } from './interfaces';

const Component: FC<MultipleStatusesSuccessProps> = ({
    classifiedEcommerceName,
    onEcommerceClick,
    platformData,
    onDone,
}) => (
    <Grid container direction="column">
        <Grid item alignSelf="center" marginTop={5}>
            <WomanFoldersSvg
                height={210}
                width={265}
                style={{
                    marginRight: 30,
                    maxWidth: '100%',
                }}
            />
        </Grid>
        <Typography variant="subtitle1" color="text.primary" fontWeight={700} mt="18px" mb={0}>
            ¡Gracias!
        </Typography>
        <Typography variant="subtitle2" color="text.primary" fontWeight={500} mt="6px">
            Clasificaste los estatus de tu tienda{' '}
            <Typography color="primary.dark" component="span" fontWeight={700}>
                {classifiedEcommerceName}
            </Typography>
        </Typography>
        <Typography variant="subtitle2" color="text.primary" fontWeight={500} mt="5px">
            Con esto lograremos representar de manera mucho más certera el GMV total de tu tienda.
        </Typography>
        {!!platformData.editable.size && (
            <>
                <Typography variant="subtitle2" color="text.primary" fontWeight={700} mt="20px">
                    Te sugerimos hacerlo para cada una de tus tiendas{' '}
                    <Typography color="primary.dark" component="span" fontWeight="inherit">
                        {getPlatformDisplayName(platformData.platformKey)}
                    </Typography>{' '}
                    conectadas:
                </Typography>
                <Grid container spacing={2} marginLeft={-3}>
                    {Array.from(platformData.editable, ([id, item]) => (
                        <Grid item key={id}>
                            <DefaultBtn
                                variant="text"
                                value={id}
                                onClick={onEcommerceClick}
                                sx={{ fontSize: 16, textTransform: 'none !important' }}
                            >
                                {getConnectorAlias(item)}
                            </DefaultBtn>
                        </Grid>
                    ))}
                </Grid>
            </>
        )}
        <Grid container justifyContent="flex-end" spacing={2.5} marginTop={1.5}>
            {platformData.editable.size ? (
                <>
                    <Grid item xs={12} sm="auto">
                        <DefaultBtn size="small" variant="outlined" fullWidth onClick={onDone}>
                            Entendido
                        </DefaultBtn>
                    </Grid>
                    <Grid item xs={12} sm="auto">
                        <DefaultBtn
                            size="small"
                            fullWidth
                            value={platformData.defaultConnectorId}
                            onClick={onEcommerceClick}
                        >
                            Siguiente tienda
                        </DefaultBtn>
                    </Grid>
                </>
            ) : (
                <Grid item xs={12} sm="auto">
                    <DefaultBtn size="small" fullWidth onClick={onDone}>
                        Finalizar
                    </DefaultBtn>
                </Grid>
            )}
        </Grid>
    </Grid>
);

export default Component;
