import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, TableRow, TableCell } from '@mui/material';
import { FileCopy, Visibility, InsertDriveFile } from '@mui/icons-material';
import { Status, StatusType } from 'common-components';
import { Typography, IconBtn, Tooltip, Loader } from '@fairplay2/ui';
import { formatAmount } from 'utils/parsing';
import { contractPrintableName } from 'utils/formatting';
import fairplayAPI from 'utils/api';
import { openLink } from 'components/dashboard/disbursment/transactions/directory/utils';
import { ContractsTableItemProps } from '../../interfaces';
import InfoAlert from '../../detail/components/InfoAlert';
import { canSeePayments } from '../../utils';

const ContractsTableItem: FC<ContractsTableItemProps> = ({ contract, notify }) => {
    const history = useHistory(),
        [downloading, setDownloading] = useState(false);

    const downloadAccountStatement = async () => {
        setDownloading(true);
        try {
            const preliminarRes: any = await fairplayAPI.post(
                '/v1/services/ac-statement-excel-grpc',
                {
                    contract_id: contract.id,
                },
            );

            openLink(preliminarRes.data.body.results.url_download || '');
        } catch {
            notify(
                `Ocurrió un error descargando los movimientos del contrato ${contractPrintableName(
                    contract,
                )}.`,
                'error',
            );
        } finally {
            setDownloading(false);
        }
    };

    return (
        <TableRow>
            <TableCell>
                <Grid
                    container
                    alignItems="center"
                    wrap="nowrap"
                    onClick={() => history.push(`/app/financing/contract/detail/${contract.id}`)}
                    role="button"
                    sx={{ cursor: 'pointer' }}
                >
                    <FileCopy color="secondary" aria-hidden />
                    <Typography variant="body1" fontWeight={700} color="primary.main" ml="15px">
                        {contractPrintableName(contract)}
                    </Typography>
                </Grid>
            </TableCell>
            <TableCell>
                <Typography fontWeight={700} color="primary.main" noWrap>
                    {contract.principalAmount && `MXN ${formatAmount(contract.principalAmount)}`}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontWeight={700} color="primary.main" noWrap>
                    {contract.maxDispositionValue &&
                        `MXN ${formatAmount(contract.maxDispositionValue)}`}
                </Typography>
            </TableCell>
            <TableCell>
                {contract.status && (
                    <Status
                        role="presentation"
                        status={contract.status!.id as StatusType}
                        variant="outlined"
                    >
                        {contract.status!.text}
                    </Status>
                )}
            </TableCell>
            <TableCell>
                <InfoAlert infos={contract.importantFields} icon={false} justify="center" />
            </TableCell>
            <TableCell>
                <Grid container spacing={1} justifyContent="center">
                    <Grid item>
                        <Tooltip title="Ver detalle" placement="top">
                            <IconBtn
                                color="secondary"
                                variant="outlined"
                                onClick={() =>
                                    history.push(`/app/financing/contract/detail/${contract.id}`)
                                }
                            >
                                <Visibility titleAccess="Ver detalle" />
                            </IconBtn>
                        </Tooltip>
                    </Grid>
                    {canSeePayments(contract) && (
                        <Grid item>
                            <Tooltip
                                title={
                                    downloading
                                        ? 'Descargando movimientos'
                                        : 'Descargar movimientos'
                                }
                                placement="top"
                                disabled={downloading}
                            >
                                <span>
                                    <IconBtn
                                        color="secondary"
                                        variant="outlined"
                                        onClick={downloadAccountStatement}
                                        disabled={downloading}
                                        aria-label={
                                            downloading
                                                ? 'Descargando movimientos'
                                                : 'Descargar movimientos'
                                        }
                                    >
                                        {downloading ? (
                                            <Loader
                                                aria-label="Descargando movimientos"
                                                sx={{
                                                    width: '24px !important',
                                                    height: '24px !important',
                                                }}
                                            />
                                        ) : (
                                            <InsertDriveFile titleAccess="Descargar movimientos" />
                                        )}
                                    </IconBtn>
                                </span>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
            </TableCell>
        </TableRow>
    );
};

export default ContractsTableItem;
