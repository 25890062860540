import { FC, useEffect, useState, ChangeEvent, useContext } from 'react';
import { Collapse, Grid } from '@mui/material';
import { ShoppingCart, LiveTv, ReceiptLongOutlined, Receipt, Web } from '@mui/icons-material';
import { Alert, TabData, TabGroup } from '@fairplay2/ui';
import { useAlert } from 'utils/hooks';
import { SessionContext } from '../../../context/session/sessionContext';
import { useDataSources } from './context/DataSourcesContext';
import { dataSourceRedirection } from './utils';
import {
    ADS_PLATFORMS,
    ANALYTICS_PLATFORMS,
    FISCAL_PLATFORMS,
    ORDERS_PLATFORMS,
    PAYMENTS_PLATFORMS,
} from './platforms/constants';
import DataSourcesGrid from './components/DataSourcesGrid';

const DEFAULT_TABS_DATA: TabData[] = [
    {
        label: 'Órdenes',
        icon: <ShoppingCart />,
        hasError: false,
    },
    {
        label: 'Publicidad',
        icon: <LiveTv />,
        hasError: false,
    },
    {
        label: 'Analítica web',
        icon: <Web />,
        hasError: false,
    },
    {
        label: 'Fiscal',
        icon: <ReceiptLongOutlined />,
        hasError: false,
    },
    {
        label: 'Procesadores de pagos',
        icon: <Receipt />,
        hasError: false,
    },
];

const withError = (tab: typeof DEFAULT_TABS_DATA[number]) => ({ ...tab, hasError: true });

const DataSources: FC = () => {
    const { platforms } = useDataSources(),
        [activeTab, setActiveTab] = useState(0),
        [tabs, setTabs] = useState(DEFAULT_TABS_DATA),
        displayReauthError =
            Object.values(tabs).some(({ hasError }) => !!hasError) &&
            !dataSourceRedirection.readMetadata()?.connector,
        { errors } = useContext(SessionContext),
        { alert, showAlert } = useAlert(),
        effectiveAlert = errors.messages.connectors
            ? { open: true, message: errors.messages.connectors, type: 'error' as 'error' }
            : alert;

    const onTabChange = (_: ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    };

    useEffect(() => {
        const metadata = dataSourceRedirection.readMetadata();
        if (!metadata) return;

        if (ADS_PLATFORMS.includes(metadata.from as any)) setActiveTab(1);
        else if (ANALYTICS_PLATFORMS.includes(metadata.from as any)) setActiveTab(2);
        else if (FISCAL_PLATFORMS.includes(metadata.from as any)) setActiveTab(3);
        else if (PAYMENTS_PLATFORMS.includes(metadata.from as any)) setActiveTab(4);
    }, []);

    useEffect(() => {
        setTabs(
            Object.entries(platforms).reduce(
                (acc, [key, data]) => {
                    if (!data?.reauth?.length) return acc;

                    if (ORDERS_PLATFORMS.includes(key as any)) acc[0] = withError(acc[0]);
                    if (ADS_PLATFORMS.includes(key as any)) acc[1] = withError(acc[1]);
                    if (ANALYTICS_PLATFORMS.includes(key as any)) acc[2] = withError(acc[2]);
                    if (FISCAL_PLATFORMS.includes(key as any)) acc[3] = withError(acc[3]);
                    if (PAYMENTS_PLATFORMS.includes(key as any)) acc[4] = withError(acc[4]);

                    return acc;
                },
                [...DEFAULT_TABS_DATA],
            ),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [platforms]);

    useEffect(() => {
        if (displayReauthError) showAlert('Tienes reautorizaciones pendientes');
    }, [displayReauthError, showAlert]);

    return (
        <Grid container>
            <Collapse in={effectiveAlert.open} sx={{ mt: '20px', width: '100%' }}>
                <Grid container item xs={12} md="auto" marginBottom={2}>
                    <Alert severity={effectiveAlert.type} aria-label="Reautorizaciones pendientes">
                        {effectiveAlert.message}
                    </Alert>
                </Grid>
            </Collapse>

            <TabGroup
                item
                xs={12}
                tabs={tabs}
                onChange={onTabChange}
                value={activeTab}
                aria-label="Categorías de conectores"
            >
                <DataSourcesGrid platforms={ORDERS_PLATFORMS} />
                <DataSourcesGrid platforms={ADS_PLATFORMS} />
                <DataSourcesGrid platforms={ANALYTICS_PLATFORMS} />
                <DataSourcesGrid platforms={FISCAL_PLATFORMS} />
                <DataSourcesGrid platforms={PAYMENTS_PLATFORMS} />
            </TabGroup>
        </Grid>
    );
};

export default DataSources;
