import { NumberFormatPropsBase } from 'react-number-format';
import { ErrorsV1, FormV1, SalesProps } from './interfaces.v1';

export const getAmountError = (amount: string | null) => {
    const floatValue = parseFloat(amount || '');
    if (floatValue <= 0 || Number.isNaN(floatValue)) {
        return 'Ingresa un monto válido';
    }

    return '';
};

export const getFormStatusV1 = (contractType: string, formValues: FormV1) => {
    const result = {
            errors: {} as ErrorsV1,
            valid: true,
        },
        amountError = getAmountError(formValues.amount);

    if (amountError) {
        result.valid = false;
        result.errors.amount = amountError;
    }

    if (contractType === 'working-capital') {
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(formValues)) {
            if (!value && !['currency', 'invoiceFile'].includes(key)) {
                result.errors[key as keyof FormV1] = 'Campo requerido';
                result.valid = false;
            }
        }
    } else if (contractType === 'sales-advancement') {
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(formValues)) {
            if (!value && !['supplierId', 'supplierAccount', 'invoiceFile'].includes(key)) {
                result.errors[key as keyof FormV1] = 'Campo requerido';
                result.valid = false;
            }
        }
    } else {
        result.valid = false;
    }

    return result;
};

export const onAmountChange = (onChangeCallback: SalesProps['onChange']) =>
    (({ value }, source) => {
        if (source.source === 'event') {
            onChangeCallback('amount')({
                ...source.event,
                target: {
                    ...source.event.target,
                    value,
                },
            });
        }
    }) as Exclude<NumberFormatPropsBase['onValueChange'], undefined>;
