import { useContext, useEffect, useState } from 'react';
import SessionContext from 'context/session/sessionContext';
import fairplayAPI from 'utils/api';
import { ErrorObj } from 'utils/error-handler';
import { HydratedContract } from '../../../contracts/interfaces';

export const useGetContracts = () => {
    const { selectedCompany } = useContext(SessionContext),
        [error, setError] = useState<ErrorObj | any>(null),
        [loading, setLoading] = useState(true),
        [contracts, setContracts] = useState<HydratedContract[]>([]);

    useEffect(() => {
        (async () => {
            if (selectedCompany.company?.id) {
                // Run the hideAlert outside the hook
                setError('');
                setLoading(true);
                setContracts([]);
                try {
                    const res: any = await fairplayAPI.get(
                            `/v2/companies/${selectedCompany.company?.id}/contracts?enable_cards_module=true`,
                        ),
                        res2: any = await fairplayAPI.get(
                            `/v2/companies/${selectedCompany.company?.id}/contracts?dispersions_allowed=true`,
                        );

                    const cardContracts = res.data.body.results,
                        disbursementContracts = res2.data.body.results;

                    const hydratedContracts = cardContracts.map((contract: HydratedContract) => {
                        const disbursementAllowed = disbursementContracts.some(
                            (disbursementContract: HydratedContract) =>
                                disbursementContract.id === contract.id,
                        );

                        return { ...contract, disbursementAllowed };
                    });

                    setContracts((prev) => hydratedContracts || prev);
                } catch (e) {
                    setError(e);
                } finally {
                    setLoading(false);
                }
            }
        })();
    }, [selectedCompany.company?.id]);

    return { contracts, error, loading };
};

export default useGetContracts;
