import { FC } from 'react';
import { Grid } from '@mui/material';
import { Typography, FullscreenLayout } from '@fairplay2/ui';
import { GhostBoxSvg } from 'assets/SVGs';
import { MaintenancePageProps } from './interfaces';
import { BoxContainer } from './styles';

export const MaintenancePage: FC<MaintenancePageProps> = ({ startDate, endDate }) => (
    <FullscreenLayout
        alignItems="center"
        direction="column"
        flexWrap="nowrap"
        sx={{
            backgroundColor: 'primary.A400',
            overflowX: 'hidden',
        }}
    >
        <Grid item>
            <GhostBoxSvg />
        </Grid>
        <BoxContainer item>
            <Typography variant="h2" color="primary.contrastText" mt="10px">
                En mantenimiento
            </Typography>
            <Typography variant="h5" color="primary.contrastText" fontWeight={700} mt="20px">
                Nos estamos preparando para darte un mejor servicio pero estaremos de vuelta en muy
                poco tiempo.
            </Typography>
            <Typography variant="h5" color="primary.contrastText" fontWeight={400} mt="20px">
                Mantenimiento programado:
            </Typography>
            <Typography variant="h5" color="primary.contrastText" fontWeight={400}>
                De {startDate && startDate} a {endDate && endDate}
            </Typography>
        </BoxContainer>
    </FullscreenLayout>
);
