import { ChartFactory } from 'utils/formatting';
import { BaseState } from '../interfaces';
import { baseReducer } from '../baseReducer';
import { ProjectionsAction, ProjectionsChartKeys } from './interfaces';

const initialChartData = {
    prophet: {
        title: '',
        isEmpty: true,
        loading: true,
        options: {
            series: [
                {
                    type: 'spline',
                    color: '#9689F2',
                    name: 'Ventas',
                    data: [],
                },
            ],
            yTitle: 'Ventas',
            valuePrefix: '$',
        },
    },
};

export const getProphet = (data: any) => {
    if (data.length > 0) {
        const values = [];
        for (let i = 0; i < data.length; i++) {
            const date = data[i].date.split('T')[0];
            values.push([Date.parse(date), data[i].prophet]);
        }

        return {
            isEmpty: false,
            options: ChartFactory({
                ...initialChartData.prophet.options,
                series: [
                    {
                        ...initialChartData.prophet.options.series[0],
                        data: values,
                    },
                ],
            }),
        };
    }
    return { options: {}, isEmpty: true };
};

export const ProjectionsInitialState: BaseState<ProjectionsChartKeys> = {
    chartData: initialChartData,
};

export const ProjectionsReducer = (
    state: BaseState<ProjectionsChartKeys>,
    action: ProjectionsAction,
) => {
    switch (action.type) {
        case 'SET_PROPHET':
            return baseReducer(state, {
                type: 'SET_CHART',
                payload: { key: 'prophet', value: getProphet(action.payload.value) },
            });
        default:
            return baseReducer(state, action);
    }
};
