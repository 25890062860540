import { FC } from 'react';
import { Typography } from '@fairplay2/ui';
import { DialogTitle, Grid } from '@mui/material';
import { ModalTitleProps } from './interfaces';

const ModalTitle: FC<ModalTitleProps> = ({ children }) => (
    <Grid item container mb="15px">
        <Typography
            color="primary"
            fontWeight={500}
            component={DialogTitle}
            p={0}
            fontSize="18px !important"
        >
            {children}
        </Typography>
    </Grid>
);

export default ModalTitle;
