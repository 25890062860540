import { FC } from 'react';
import { BaseModal, BaseModalProps } from 'common-components';
import { LineChart } from 'assets/SVGs';

export const PresentationModal: FC<BaseModalProps> = ({ onConfirm, open, loading }) => (
    <BaseModal open={open} onClose={onConfirm} onPrimaryAction={onConfirm} loading={loading}>
        <BaseModal.Icon>
            <LineChart />
        </BaseModal.Icon>
        <BaseModal.Title>Usa tus datos para obtener mejores resultados</BaseModal.Title>
        <BaseModal.Content>
            Obtén una visión completa de tus ventas en la sección de{' '}
            <strong>Analíticos &gt; Desempeño comercial.</strong>
        </BaseModal.Content>
        <BaseModal.Actions primaryLabel="Entendido" />
    </BaseModal>
);

export default PresentationModal;
