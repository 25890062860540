import { FC } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { Typography } from '@fairplay2/ui';
import { formatAmount } from 'utils/parsing';
import { PerformedDisbursementProps } from '../../interfaces';

const PerformedDisbursement: FC<PerformedDisbursementProps> = ({ disbursement }) => (
    <TableRow key={disbursement.concept}>
        <TableCell>
            <Typography fontWeight={700} color="primary.main">
                {disbursement.supplier?.alias}
            </Typography>
            <Typography fontWeight={500} color="primary.dark">
                {disbursement.supplier?.taxId}
            </Typography>
        </TableCell>
        <TableCell>
            <Typography fontWeight={500} color="primary.dark">
                {disbursement.concept}
            </Typography>
        </TableCell>
        <TableCell>
            <Typography fontWeight={700} color="primary.main">
                {`${disbursement.currency?.id} ${formatAmount(disbursement.amount)}`}
            </Typography>
        </TableCell>
        <TableCell>
            <Typography fontWeight={500} color="primary.dark">
                {disbursement.supplierAccount?.swift
                    ? disbursement.supplierAccount?.swift
                    : disbursement.supplierAccount?.clabe}
            </Typography>
        </TableCell>
        <TableCell>
            <Typography fontWeight={500} color="primary.dark">
                {disbursement.supplierAccount?.bank}
            </Typography>
        </TableCell>
    </TableRow>
);

export default PerformedDisbursement;
