import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Modal, DefaultBtn, Typography } from '@fairplay2/ui';
import { WomanCelebratingSvg } from 'assets/SVGs';
import { SuccessModalProps } from '../interfaces';

const SuccessModal: FC<SuccessModalProps> = ({ isModalOpen, onModalClose }) => {
    const history = useHistory();

    const onClick = () => {
        onModalClose('success');
        history.push('/app/financing/vendors');
    };

    return (
        <Modal
            aria-labelledby="success-modal-title"
            open={isModalOpen}
            onClose={onClick}
            maxWidth={524}
        >
            <Grid container>
                <Grid container justifyContent="center">
                    <WomanCelebratingSvg />
                </Grid>
                <Typography
                    id="success-modal-title"
                    variant="subtitle1"
                    color="primary.main"
                    mt="20px"
                >
                    ¡Agregaste un proveedor!
                </Typography>
                <Typography variant="subtitle2" color="text.primary" mt="20px">
                    La aprobación de un nuevo proveedor puede tardar de dos a tres días. Podrás
                    verificar el estatus de la alta en el directorio de proveedores.
                </Typography>
                <Grid container justifyContent="center">
                    <DefaultBtn size="small" onClick={onClick} sx={{ mt: '20px' }}>
                        Entendido
                    </DefaultBtn>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default SuccessModal;
