import { useEffect, useState } from 'react';
import fairplayAPI from 'utils/api';
import { ErrorObj } from 'utils/error-handler';
import { ContractMetadata } from '../../transactions/interfaces';
import { HydratedContract } from '../../../contracts/interfaces';
import { formatPeriodsData, getContractInfo } from '../../utils';

export const useGetAvailableAndPeriods = (contract: HydratedContract | undefined) => {
    const [contractInfo, setContractInfo] = useState<ContractMetadata | null | undefined>(
            undefined,
        ),
        [error, setError] = useState<ErrorObj | any>(null);

    useEffect(() => {
        if (contract === undefined) return;

        setContractInfo(undefined);

        if (!contract.disbursementAllowed || contract.status?.id === 'suspended') {
            // this simulates a request to the backend to show the skeleton in SelectContract.tsx
            setTimeout(() => {
                setContractInfo(null);
            }, 500);
            return;
        }

        (async () => {
            // Run the hideAlert outside the hook
            setError('');
            try {
                const periodsData: any = await fairplayAPI.get(
                        `/v3/disbursement-periods?contract_id=${contract.id}`,
                        {
                            baseService: 'PERIODS_LOANBOOK',
                        },
                    ),
                    { currentPeriod } = formatPeriodsData(periodsData.data.body.results);

                // Avoid call the available endpoint if the contract has no periods
                if (currentPeriod === null)
                    return setContractInfo(getContractInfo(contract, currentPeriod, null));

                const availableData: any = await fairplayAPI.get(`/v1/${contract.id}/available`, {
                        baseService: 'AVAILABLE_LOANBOOK',
                    }),
                    {
                        body: { results: availableResults },
                    } = availableData.data;

                setContractInfo(getContractInfo(contract, currentPeriod, availableResults));
            } catch (errResponse: ErrorObj | any) {
                setContractInfo(null);
                setError(errResponse);
            }
        })();
    }, [contract]);

    return { contractInfo, error };
};

export default useGetAvailableAndPeriods;
