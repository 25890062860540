import { useContext, useEffect, useState } from 'react';
import { DefaultBtn, InterpunctLoader, Typography, Alert, Modal, ModalProps } from '@fairplay2/ui';
import { Collapse, DialogTitle, Grid, useMediaQuery, useTheme } from '@mui/material';
import { AlertSvg, SuccessSvg } from 'assets/SVGs';
import { useAlert } from 'utils/hooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import fairplayAPI from 'utils/api';
import SessionContext from 'context/session/sessionContext';

interface UnlinkUserModalProps extends ModalProps {
    onConfirmUnlink: () => void;
}

export const UnlinkUserModal = ({
    open,
    onClose,
    onConfirmUnlink,
    ...props
}: UnlinkUserModalProps) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        { user } = useContext(SessionContext),
        [hasBeenUnlinked, setHasBeenUnlinked] = useState(false),
        [loading, setLoading] = useState(false),
        { alert, hideAlert, showAlert } = useAlert(),
        userFullName = `${user?.firstName} ${user?.lastName}`;

    const onDelete = async () => {
        hideAlert();
        setLoading(true);

        try {
            await fairplayAPI.post(`/v3/2fa-service/google/otp/disable`);
            setHasBeenUnlinked(true);

            if (!sessionStorage.getItem('otpModalShown'))
                sessionStorage.setItem('otpModalShown', 'true');
        } catch (errResponse: ErrorObj | any) {
            showAlert(errResponse?.error || 'Ocurrió un error al desvincular al usuario');
        } finally {
            setLoading(false);
        }
    };

    const onCloseModal = () => {
        onClose?.();
        if (hasBeenUnlinked) onConfirmUnlink();
    };

    useEffect(() => {
        if (open) setHasBeenUnlinked(false);
    }, [open]);

    return (
        <Modal open={open} onClose={onCloseModal} maxWidth={647} {...props}>
            {hasBeenUnlinked ? (
                <Grid container>
                    <Grid item container justifyContent="center">
                        <Grid item>
                            <SuccessSvg />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            component={DialogTitle}
                            variant="subtitle1"
                            color="primary.main"
                            mt="20px"
                            lineHeight={1.5}
                            p={0}
                        >
                            Aplicaciones de autenticación desvinculadas
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            color="text.primary"
                            mt="25px"
                            p={0}
                            fontWeight={500}
                        >
                            Se han desvinculado exitosamente las aplicaciones de{' '}
                            <Typography component="span" color="primary.dark" fontWeight={700}>
                                {userFullName}.
                            </Typography>{' '}
                            Ya no aparecerá en el del Listado de usuarios vinculados.
                        </Typography>
                    </Grid>

                    <Grid container justifyContent="center" mt="30px">
                        <DefaultBtn size="small" onClick={onCloseModal}>
                            Entendido
                        </DefaultBtn>
                    </Grid>
                </Grid>
            ) : (
                <Grid container>
                    <Grid container justifyContent="center">
                        <AlertSvg />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            component={DialogTitle}
                            variant="subtitle1"
                            color="primary.main"
                            mt="20px"
                            p={0}
                            lineHeight={1.5}
                        >
                            ¡Estas a punto de desvincular todas tus aplicaciones de autenticación de
                            Insights! ¿Estás seguro?
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            color="text.primary"
                            mt="25px"
                            p={0}
                            fontWeight={500}
                        >
                            <Typography component="span" color="primary.dark" fontWeight={700}>
                                {userFullName}
                            </Typography>{' '}
                            si desvinculas tus aplicaciones de autenticación ya no podrás realizar
                            dispersiones y tendrás que volver a vincular al menos una.
                        </Typography>
                    </Grid>
                    <Grid container justifyContent="center" mt="20px" mb={alert.open ? '20px' : 0}>
                        <Collapse in={alert.open}>
                            <Alert severity={alert.type}>{alert.message}</Alert>
                        </Collapse>
                    </Grid>
                    <Grid container justifyContent="center" mt="5px">
                        {loading ? (
                            <InterpunctLoader />
                        ) : (
                            <>
                                <DefaultBtn
                                    fullWidth={smDown}
                                    size="small"
                                    variant="outlined"
                                    onClick={onClose}
                                    sx={{
                                        mb: { xs: '20px', sm: 0 },
                                    }}
                                >
                                    Cancelar
                                </DefaultBtn>
                                <DefaultBtn
                                    fullWidth={smDown}
                                    size="small"
                                    onClick={onDelete}
                                    sx={{
                                        ml: { sx: 0, sm: '20px' },
                                    }}
                                >
                                    Desvincular
                                </DefaultBtn>
                            </>
                        )}
                    </Grid>
                </Grid>
            )}
        </Modal>
    );
};
