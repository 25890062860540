import { FC, useState, useEffect, useRef, FormEvent, ChangeEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme, useMediaQuery, Grid, Collapse, Alert } from '@mui/material';
import {
    Checkbox,
    DefaultBtn,
    Typography,
    InterpunctLoader,
    LoaderPage,
    PasswordInput,
    FairplayLogoGraphic,
} from '@fairplay2/ui';
import { Footer } from 'common-components';
import { NotFoundPage } from 'layouts';
import { WomanManCelebratingTwoSvg } from 'assets/SVGs';
import fairplayAPI from 'utils/api';
import { useAlert } from 'utils/hooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { ChangePasswordForm, ChangePasswordParams } from '../interfaces';
import { Background, SliderContainer } from '../styles';
import Sliders from './Sliders';

const DEFAULT_FORM_VALUES = {
    password: '',
    confirmPassword: '',
};

const ChangePassword: FC = () => {
    const theme = useTheme(),
        mdDown = useMediaQuery(theme.breakpoints.down('md')),
        history = useHistory(),
        params = useParams<ChangePasswordParams>(),
        token = useRef(params.id),
        [checked, setChecked] = useState(false),
        [isValid, setIsValid] = useState(true),
        [confirm, setConfirm] = useState(false),
        [loading, setLoading] = useState({
            page: true,
            passwordChange: false,
        }),
        [values, setValues] = useState<ChangePasswordForm>(DEFAULT_FORM_VALUES),
        [errors, setErrors] = useState<ChangePasswordForm>(DEFAULT_FORM_VALUES),
        { alert, hideAlert, showAlert } = useAlert();

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        hideAlert();
        setValues((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    };

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        hideAlert();

        if (values.password !== values.confirmPassword) {
            setErrors((prev) => ({ ...prev, confirmPassword: 'La contraseña no coincide' }));
        } else {
            setLoading({ ...loading, passwordChange: true });
            setErrors((prev) => ({ ...prev, confirmPassword: '' }));
            const data = new FormData();
            data.append('token', token.current);
            data.append('password', values.password);
            data.append('terms', 'true');
            try {
                await fairplayAPI.post(`/v1/users/reset_password/confirm`, data);
                setConfirm(true);
            } catch (errResponse: ErrorObj | any) {
                if (errResponse?.type === 'form')
                    setErrors((prev) => ({ ...prev, ...errResponse.error }));
                // TODO: Implement password ErrorObj
                else showAlert(errResponse?.error);
            } finally {
                setLoading({ ...loading, passwordChange: false });
            }
        }
    };

    const onChangeInput = (prop: keyof ChangePasswordForm, errorMsg: string) => {
        if (values[prop]) {
            setErrors((prev) => ({ ...prev, [prop]: '' }));
        } else {
            setErrors((prev) => ({ ...prev, [prop]: errorMsg }));
        }
    };

    const onLogin = () => {
        history.push('/');
    };

    useEffect(() => {
        (async () => {
            try {
                await fairplayAPI.post('/v1/users/reset_password/validate', {
                    token: token.current,
                });
                setIsValid(true);
            } catch {
                setIsValid(false);
            } finally {
                setLoading((prev) => ({ ...prev, page: false }));
            }
        })();
    }, []);

    // eslint-disable-next-line no-nested-ternary
    return loading.page ? (
        <LoaderPage loading={loading.page} />
    ) : isValid ? (
        <Grid container>
            <Background item container sm={12} md={5} justifyContent="center" alignItems="center">
                <Grid
                    item
                    container
                    direction="column"
                    xs={10}
                    sm={6}
                    md={9}
                    lg={7}
                    xl={6}
                    mt={['20px', '20px', '50px']}
                    pb={{ xs: '140px', sm: '190px', md: '210px' }}
                >
                    <Grid item xs={12}>
                        <FairplayLogoGraphic width={201} />
                        <Typography
                            variant="subtitle2"
                            color="secondary.main"
                            fontWeight={700}
                            mt="-10px"
                        >
                            INSIGHTS
                        </Typography>
                    </Grid>
                    {confirm ? (
                        <Grid item container xs={12} direction="column" alignItems="center">
                            <Typography
                                variant="h4"
                                color="primary.contrastText"
                                mt="50px"
                                gutterBottom
                            >
                                ¡Cambio de contraseña exitoso!
                            </Typography>
                            <Typography
                                variant="body2"
                                color="primary.contrastText"
                                fontWeight={700}
                                mb="20px"
                            >
                                Tu contraseña ha sido cambiada con éxito, ahora puedes iniciar
                                sesión ingresando tu nueva contraseña.
                            </Typography>
                            <WomanManCelebratingTwoSvg width="100%" height="auto" />
                            <DefaultBtn
                                onClick={onLogin}
                                color="mixed"
                                fullWidth
                                sx={{
                                    mt: '20px',
                                }}
                            >
                                Iniciar sesión
                            </DefaultBtn>
                        </Grid>
                    ) : (
                        <Grid
                            item
                            container
                            xs={12}
                            direction="column"
                            component="form"
                            onSubmit={onSubmit}
                        >
                            <Typography
                                variant="h4"
                                color="primary.contrastText"
                                mt="50px"
                                gutterBottom
                            >
                                Crea tu nueva contraseña
                            </Typography>
                            <Typography
                                variant="body2"
                                color="primary.contrastText"
                                fontWeight={700}
                                mb="20px"
                            >
                                Ingresa una nueva contraseña que incluya letras y números.
                            </Typography>
                            <PasswordInput
                                name="password"
                                placeholder="Nueva contraseña"
                                label="Nueva contraseña"
                                value={values.password}
                                onChange={onChange}
                                onBlur={() => onChangeInput('password', 'Ingresa la contraseña')}
                                error={errors.password}
                                forceLegibility
                                required
                                sx={{
                                    mb: '20px',
                                }}
                                forceHiddenText={loading.passwordChange}
                            />
                            <PasswordInput
                                name="confirmPassword"
                                placeholder="Confirmar contraseña"
                                label="Confirmar contraseña"
                                value={values.confirmPassword}
                                onChange={onChange}
                                onBlur={() =>
                                    onChangeInput(
                                        'confirmPassword',
                                        'Ingresa la confirmación de la contraseña',
                                    )
                                }
                                error={errors.confirmPassword}
                                forceLegibility
                                required
                                sx={{
                                    mb: '20px',
                                }}
                                forceHiddenText={loading.passwordChange}
                            />
                            <Grid item container xs={12} alignItems="center">
                                <Checkbox
                                    checked={checked}
                                    onChange={() => setChecked((prev) => !prev)}
                                />
                                <Typography
                                    color="primary.contrastText"
                                    variant="body2"
                                    fontWeight={500}
                                >
                                    He leído y acepto los{' '}
                                    <a
                                        target="_blank"
                                        href="https://getfairplay.com/terminos-y-condiciones/"
                                        rel="noopener noreferrer"
                                        style={{ color: 'inherit' }}
                                    >
                                        términos y condiciones
                                    </a>
                                </Typography>
                            </Grid>
                            <Collapse in={alert.open}>
                                <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    item
                                    xs={12}
                                >
                                    <Alert variant="filled" severity={alert.type}>
                                        {alert.message}
                                    </Alert>
                                </Grid>
                            </Collapse>
                            <Grid
                                item
                                container
                                justifyContent="center"
                                alignItems="center"
                                xs={12}
                            >
                                {loading.passwordChange ? (
                                    <InterpunctLoader />
                                ) : (
                                    <DefaultBtn
                                        disabled={!checked}
                                        type="submit"
                                        color="mixed"
                                        fullWidth
                                        sx={{
                                            mt: '20px',
                                        }}
                                    >
                                        Continuar
                                    </DefaultBtn>
                                )}
                            </Grid>
                        </Grid>
                    )}
                    <Footer />
                </Grid>
            </Background>
            {!mdDown && (
                <SliderContainer item container md={7} justifyContent="center" alignItems="center">
                    <Sliders />
                </SliderContainer>
            )}
        </Grid>
    ) : (
        <NotFoundPage />
    );
};

export default ChangePassword;
