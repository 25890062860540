import { ChangeEvent, FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { EditableInput } from '@fairplay2/ui';
import fairplayAPI from 'utils/api';
import { isEnglishWords } from 'utils/validation';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { toFormData } from 'utils/parsing';
import { EditCardAliasProps } from '../../interfaces';

// TODO: create reusable component for the entire project
const EditCardAlias: FC<EditCardAliasProps> = ({
    cardId,
    alias: cardAlias,
    showAlert,
    selectedContractId,
    statusCard,
    onComplete,
}) => {
    const [prevAlias, setPrevAlias] = useState(cardAlias),
        [loading, setLoading] = useState(false),
        {
            formState: { errors },
            control,
            setValue,
            watch,
            clearErrors,
        } = useForm<{ alias: string }>({
            mode: 'onBlur',
            values: { alias: cardAlias },
        }),
        { alias: aliasValue } = watch();

    const onCancel = () => {
        setValue('alias', prevAlias);
        clearErrors();
    };

    const onSaveAlias = async () => {
        setLoading(true);
        try {
            const data = toFormData({
                contract_id: selectedContractId,
                card_id: cardId,
                request_type: 'update-alias',
                alias: aliasValue,
            });
            await fairplayAPI.post('/cards/v1/card/create-request', data, {
                baseService: 'CARDS',
            });

            setPrevAlias(aliasValue);
            showAlert('Alias de la tarjeta actualizado exitosamente', 'success');
            onComplete?.();
        } catch (errResponse: ErrorObj | any) {
            onCancel();
            showAlert(
                errResponse?.error ||
                    'Ocurrió un error al cambiar el alias de la tarjeta, intente más tarde',
                'error',
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <Grid container>
            <Grid item xs>
                <Controller
                    name="alias"
                    control={control}
                    rules={{
                        required: 'Ingresa el alias',
                    }}
                    render={({ field: { onChange, onBlur, value: prevValue, ref, name } }) => (
                        <EditableInput
                            label="Alias de la tarjeta"
                            loading={loading}
                            placeholder="Ej. Pagos Google"
                            type="text"
                            size="small"
                            name={name}
                            confirmButtonLabel="Confirmar"
                            editButtonLabel="Editar nombre"
                            value={prevValue}
                            error={errors.alias?.message}
                            disabled={!cardAlias}
                            disableConfirmButton={!!errors.alias}
                            disableEditButton={!cardAlias || statusCard !== 'active'}
                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
                                onChange(isEnglishWords(value) ? value : prevValue)
                            }
                            onBlur={onBlur}
                            onCancel={onCancel}
                            onConfirm={onSaveAlias}
                            inputRef={ref}
                            inputProps={{
                                maxLength: 22,
                            }}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};

export default EditCardAlias;
