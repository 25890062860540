import { FC } from 'react';
import { Grid } from '@mui/material';
import { DefaultBtn, Typography } from '@fairplay2/ui';
import { ManSittingSmartphoneSvg } from 'assets/SVGs';
import { ConfirmationProps } from '../../components/wizard-ecommerce/interfaces';

interface Props extends Pick<ConfirmationProps, 'onClose'> {
    type: 's3' | 'manual-upload';
}

const Confirmation: FC<Props> = ({ onClose, type }) => (
    <Grid container maxWidth={390} direction="column" mx="auto">
        <Grid item textAlign="center">
            <ManSittingSmartphoneSvg style={{ maxWidth: 260, width: '100%' }} />
        </Grid>
        <Grid item>
            <Typography
                variant="subtitle1"
                color="primary.main"
                mt="25px"
                mb="20px"
                lineHeight={1.1875}
            >
                ¡Conexión {type === 's3' ? 'Bucket AWS ' : ''}exitosa!
            </Typography>

            <Typography variant="subtitle2" color="text.primary" mb="30px" lineHeight={1.1875}>
                Tu{' '}
                <Typography component="span" color="primary.dark" fontWeight={700}>
                    {type === 's3' ? 'Bucket AWS' : 'conector de carga manual'}
                </Typography>{' '}
                ha sido {type === 's3' ? 'conectado' : 'creado'} con éxito.
            </Typography>
        </Grid>
        <Grid item container justifyContent="flex-end">
            <DefaultBtn size="small" onClick={onClose}>
                {type === 'manual-upload' ? 'Siguiente' : 'Entendido'}
            </DefaultBtn>
        </Grid>
    </Grid>
);

export default Confirmation;
