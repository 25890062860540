import { FullscreenLayout } from '@fairplay2/ui';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Background = styled(FullscreenLayout)(({ theme }) => ({
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    backgroundColor: theme.palette.primary.A400,
    '& .InsightsNotFoundPage-notFound': {
        width: '100%',
        height: 'auto',
        position: 'relative',
        zIndex: 9,
    },
    '& .InsightsNotFoundPage-moon': {
        position: 'absolute',
        top: 50,
        left: 'auto',
        '@media (max-width: 600px), (max-height: 700px)': {
            top: 20,
        },
    },
    '& .InsightsNotFoundPage-bigCloud': {
        position: 'absolute',
        top: 140,
        left: '25%',
        width: '133px',
        '@media (max-width: 600px),': {
            top: 50,
            left: '2%',
        },
        '@media (max-width: 360px), (max-height: 700px)': {
            top: 20,
            left: '2%',
        },
    },
    '& .InsightsNotFoundPage-smallCloud': {
        position: 'absolute',
        top: 110,
        right: '30%',
        width: '80px',
        '@media (max-width: 600px)': {
            top: 80,
            right: '10%',
        },
        '@media (max-width: 360px), (max-height: 700px)': {
            top: 90,
            right: '2%',
        },
    },
}));

export const Container = styled(Grid)({
    maxWidth: 650,
    marginTop: 110,
    '@media (max-width: 600px), (max-height: 800px)': {
        marginBottom: 60,
    },
});

export const Title = styled(Typography)({
    position: 'relative',
    zIndex: 10,
    marginBottom: -80,
    color: '#ffffff',
    '@media (max-width: 600px)': {
        marginBottom: -30,
    },
    '@media (max-width: 360px)': {
        marginBottom: -20,
    },
});

export const Subtitle = styled(Typography)({
    marginTop: -30,
    color: '#ffffff',
    '@media (max-width: 600px)': {
        marginTop: -10,
    },
    '@media (max-width: 360px)': {
        marginTop: -5,
    },
});
