import { format as _format, endOfMonth, subMonths, startOfMonth, startOfDay } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import { capitalize } from 'utils/formatting';
import { DateRange, SyntheticDate } from 'utils/interfaces';

export const MONTHS = Array.from({ length: 12 }, (_, i) => esLocale.localize?.month?.(i));

const SHORT_MONTHS = Array.from({ length: 12 }, (_, i) =>
    esLocale.localize?.month?.(i, {
        // To meet UI disigns 'Mayo' month complete name is needed
        width: i === 4 ? '' : 'abbreviated',
    }),
);

// Not using localize values as its ordinal abbreviations are incorrect
const QUARTER_LABELS = ['1er trimestre', '2do trimestre', '3er trimestre', '4to trimestre'];

export const TIME_FORMAT = 'HH:mm:ss';

export const SHORT_READABLE_FORMAT = 'd MMM yyyy';

export const TIME_SHORT_READABLE_FORMAT = `${SHORT_READABLE_FORMAT} ${TIME_FORMAT}`;

export const READABLE_FORMAT = `EEE ${SHORT_READABLE_FORMAT}`;

export const TIME_READABLE_FORMAT = `${READABLE_FORMAT} ${TIME_FORMAT}`;

export const TIMELESS_ISO_FORMAT = `yyyy-MM-dd`;

export const DATEPICKER_FORMAT = `yyyy/MM/dd`;

export type DateRepresentation = number | string | null | Date;

export const getDateParts = (formatter: Intl.DateTimeFormat, date: Date) =>
    formatter.formatToParts(date).reduce((acc, { value, type }) => {
        acc[type] = value;
        return acc;
    }, {} as Record<ReturnType<Intl.DateTimeFormat['formatToParts']>[0]['type'], string>);

export const toDate = (dateRepresentation: DateRepresentation = new Date()) =>
    typeof dateRepresentation === 'number' ||
    typeof dateRepresentation === 'string' ||
    dateRepresentation === null
        ? new Date(dateRepresentation ?? 'Invalid Date')
        : dateRepresentation;

/**
 * Negates the local offset so that date-fns can return predictable
 * results regardless of the user timezone.
 *
 * @param date Original date
 * @returns Date with negated timezone offset
 */
export const negateLocalOffset = (date: Date) =>
    new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
    );

export const isValidDate = (date: Date) => !Number.isNaN(date.getTime());

export type FormatOptions = Parameters<typeof _format>[2] & {
    /**
     * @default true
     */
    capitalize?: boolean;
    /**
     * String to use in case that the provided date is invalid.
     *
     * @default '-'
     */
    fallbackString?: string;
};

export const localFormat = (
    // eslint-disable-next-line @typescript-eslint/default-param-last
    date: DateRepresentation = new Date(),
    formatString: string,
    { capitalize: _withCapitalize = true, fallbackString = '-', ...options }: FormatOptions = {},
) => {
    const effectiveOptions = { ...options, locale: options.locale || esLocale },
        parsedDate = toDate(date);

    if (!isValidDate(parsedDate)) return fallbackString;

    const formatted = _format(parsedDate, formatString, effectiveOptions);

    return _withCapitalize ? capitalize(formatted) : formatted;
};

export const format = (
    // eslint-disable-next-line @typescript-eslint/default-param-last
    date: DateRepresentation = new Date(),
    formatString: string,
    options?: FormatOptions,
) => localFormat(negateLocalOffset(toDate(date)), formatString, options);

/**
 * @returns ISO string without time data.
 */
export const toTimelessISO = (date: Date = new Date()) => date.toISOString().split('T')[0];

export interface ToReadableTimelessISOConfigs {
    /**
     * @default false
     */
    includeYear?: boolean;
    /**
     * @default '-''
     */
    fallbackString?: string;
}

/**
 *
 * @param timelessISOString ISO string without time data.
 * @param configs `includeYear` Determines if the returned format is "MMM DD" or "MMM DD, YYYY",
 *                `fallbackString` configures the returned value if the provided string is empty.
 */
export const toReadableTimelessISO = (
    timelessISOString: string | null,
    { includeYear = false, fallbackString = '-' }: ToReadableTimelessISOConfigs = {},
) => {
    if (!timelessISOString) return fallbackString;

    const [year, paddedMonth, paddedDay] = timelessISOString.split('-'),
        dayOfMonthString = capitalize(`${SHORT_MONTHS[Number(paddedMonth) - 1]} ${paddedDay}`);

    return includeYear ? `${dayOfMonthString}, ${year}` : dayOfMonthString;
};

/**
 *
 * @param delta Indicates if any amount of months
 *              should be added/substracted from the reference date.
 * @param referenceDate Defaults to current date.
 * @returns Month data of the first and last day of the month.
 */
export const getMonthRangeData = (
    delta: number = 0,
    referenceDate: Date = new Date(),
): DateRange => {
    const targetMonthRef = subMonths(negateLocalOffset(referenceDate), delta);

    return {
        from: startOfMonth(targetMonthRef),
        to: startOfDay(endOfMonth(targetMonthRef)),
    };
};

// TODO: Test util
/**
 * Generates a string ISO format date without time,
 * from a object containing year, month & day properties.
 *
 * @param syntheticDate    Object containing year, month & day properties.
 * @returns    String ISO format date without time.
 */
export const syntheticDateToTimelessISO = (syntheticDate: SyntheticDate | null) =>
    syntheticDate
        ? `${syntheticDate.year}-${`${syntheticDate.month}`.padStart(
              2,
              '0',
          )}-${`${syntheticDate.day}`.padStart(2, '0')}`
        : '';

/**
 * Generates an array of years in the format 'YYYY', starting at
 * the reference date and going back in time.
 *
 * @param yearsBack     Number of years in the past
 * @param referenceDate Defaults to current date.
 */
export const generateYearsArray = (
    yearsBack: number,
    referenceDate: Date = new Date(),
): number[] => {
    const currentYear = referenceDate.getUTCFullYear();

    return Array.from({ length: yearsBack }, (_, i) => currentYear - i);
};

export interface YearUnit {
    data: { unitType: 'month' | 'quarter'; unitNumber: number; year: number };
    label: string;
}

/**
 * Generates an array of months in the format 'MMMM YYYY',
 * starting at any given date and going back in time.
 *
 * @param stopAt    Furthest date to consider in the array. Month is 1-indexed.
 * @param startAt   Initial date. Defaults to current date.
 */
export const generateMonthsArray = (
    { year: limitYear, month: limitMonth }: { year: number; month: number },
    startAt: Date = new Date(),
) => {
    const months: YearUnit[] = [];

    let year = startAt.getUTCFullYear(),
        month = startAt.getUTCMonth() + 1;

    // Prevents infinite loop if invalid values or dates are provided
    while (year && month && limitYear && limitMonth) {
        if (year < limitYear) break;
        if (year === limitYear && month < limitMonth) break;
        months.push({
            label: capitalize(`${MONTHS[month - 1]} ${year}`),
            data: {
                unitNumber: month,
                year,
                unitType: 'month',
            },
        });
        if (month === 1) {
            month = 12;
            year -= 1;
        } else {
            month -= 1;
        }
    }

    return months;
};

/**
 * Generates an array of (year) quarter labels,
 * starting at any given date and going back in time.
 *
 * @param stopAt    Furthest date to consider in the array. Month is 1-indexed.
 * @param startAt   Initial date. Defaults to current date.
 */
export const generateQuartersArray = (
    { year: limitYear, month: limitMonth }: { year: number; month: number },
    startAt: Date = new Date(),
) => {
    const quarters: YearUnit[] = [];

    let year = startAt.getUTCFullYear(),
        month = startAt.getUTCMonth() + 1;

    // standardizes month to use quarter delimitations
    month = Math.ceil(month / 3) * 3 - 2;

    // Prevents infinite loop if invalid values or dates are provided
    while (year && month && limitYear && limitMonth) {
        if (year < limitYear) break;
        if (year === limitYear && month < limitMonth) break;
        const quarterNumber = Math.ceil(month / 3);
        quarters.push({
            label: `${QUARTER_LABELS[quarterNumber - 1]} ${year}`,
            data: {
                unitNumber: quarterNumber,
                year,
                unitType: 'quarter',
            },
        });
        if (month === 1) {
            month = 10;
            year -= 1;
        } else {
            month -= 3;
        }
    }

    return quarters;
};
