import React, { FC, useEffect } from 'react';
import { Alert, Collapse, Grid, TableCell, TableRow } from '@mui/material';
import { Typography } from '@fairplay2/ui';
import { Table } from 'common-components';
import { useAlert } from 'utils/hooks';
import { SUMMARY_KEYS } from 'components/dashboard/analytics/utils/constants';
import { getTotalByConnectorValue } from 'components/dashboard/analytics/utils/utils';
import TotalsByConnectorTableItem from './TotalsByConnectorTableItem';
import { TotalsByConnectorTableItemProps, TotalsByConnectorTableProps } from './interfaces';

const TotalsByConnectorTable: FC<TotalsByConnectorTableProps> = ({
    connectorsData,
    count,
    externalFilters,
    loading,
    onAliasEdited,
    onChangePage,
    onChangeRowsPerPage,
    page,
    rowsPerPage,
    setRequestParams,
    summaries,
    tabValue,
}) => {
    const { alert: infoAlert, showAlert: showInfoAlert, hideAlert: hideInfoAlert } = useAlert(),
        { alert: aliasAlert, showAlert: showAliasAlert } = useAlert();

    // TODO: Refactor to avoid useEffect and prevent double renders. Alerts are static once connector data is received
    useEffect(() => {
        if (connectorsData.some((connector) => connector.isAliasEdited === false))
            showInfoAlert(
                'Asigna un alias a cada tienda para identificarlas en tu tablero',
                'info',
                false,
            );
        else hideInfoAlert();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectorsData]);

    return (
        <Grid item container xs={12} padding="30px 30px 0 30px">
            <Grid item container xs={12} gap={2} alignItems="center" justifyContent="space-between">
                <Typography
                    variant="subtitle2"
                    color="primary.dark"
                    textAlign="start"
                    fontWeight={500}
                >
                    Total de {SUMMARY_KEYS[tabValue].toLowerCase()} en el periodo
                </Typography>
                <Collapse in={aliasAlert.open}>
                    <Alert severity={aliasAlert.type} sx={{ width: 'fit-content' }}>
                        {aliasAlert.message}
                    </Alert>
                </Collapse>
            </Grid>
            <Table
                // eslint-disable-next-line react/no-unstable-nested-components
                getTableItem={(connector: TotalsByConnectorTableItemProps) => (
                    <TotalsByConnectorTableItem
                        alias={connector.alias}
                        fss={connector.fss}
                        id={connector.id}
                        inFilters={connector.inFilters}
                        isAliasEdited={connector.isAliasEdited}
                        key={connector.id}
                        onAliasEdited={onAliasEdited}
                        platform={connector.platform}
                        showAliasAlert={showAliasAlert}
                        status={connector.status}
                        summaries={connector.summaries}
                        tabValue={tabValue}
                    />
                )}
                currentItems={connectorsData}
                columns={5}
                loading={loading}
                pagination={{
                    count,
                    page,
                    rowsPerPage,
                    onPageChange: onChangePage,
                    onRowsPerPageChange: onChangeRowsPerPage,
                }}
                externalFilters={{ ...externalFilters, ...{ ordering: '' } }}
                emptyItemsMessage="No hemos encontrado totales en el periodo seleccionado"
                onParamsQueryChange={setRequestParams}
                sx={{ mt: '30px' }}
            >
                <TableRow sx={{ bgcolor: 'background.paper' }}>
                    <TableCell>
                        <Typography color="text.primary" fontWeight={500}>
                            Tienda
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography color="text.primary" fontWeight={500} textAlign="right" noWrap>
                            Valido
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography color="text.primary" fontWeight={500} textAlign="right" noWrap>
                            Invalido
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography color="text.primary" fontWeight={500} textAlign="right" noWrap>
                            Devoluciones
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography color="text.primary" fontWeight={500} textAlign="right" noWrap>
                            Total
                        </Typography>
                    </TableCell>
                </TableRow>
                {connectorsData.length > 0 && !loading && (
                    // TODO: Delegate the logic of this row to be handled in TotalsByConnectorTableItem
                    <TableRow>
                        <TableCell>
                            <Typography color="primary.main" fontWeight={700}>
                                Todas las tiendas
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="primary.main"
                                fontWeight={700}
                                textAlign="right"
                                noWrap
                            >
                                {getTotalByConnectorValue(tabValue, summaries, 'validTotal')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="primary.main"
                                fontWeight={700}
                                textAlign="right"
                                noWrap
                            >
                                {getTotalByConnectorValue(tabValue, summaries, 'invalidTotal')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="primary.main"
                                fontWeight={700}
                                textAlign="right"
                                noWrap
                            >
                                {getTotalByConnectorValue(tabValue, summaries, 'returnedTotal')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="primary.main"
                                fontWeight={700}
                                textAlign="right"
                                noWrap
                            >
                                {getTotalByConnectorValue(tabValue, summaries, 'total')}
                            </Typography>
                        </TableCell>
                    </TableRow>
                )}
            </Table>
            {!loading && (
                <>
                    <Typography
                        variant="subtitle2"
                        color="text.primary"
                        fontSize="12px"
                        fontWeight={500}
                        mt={{ xs: '0px', lg: '-33px' }}
                        role="note"
                    >
                        *Todos los montos están reflejados en pesos mexicanos
                    </Typography>
                    <Grid item xs={12} mt="30px">
                        <Collapse in={infoAlert.open}>
                            <Alert severity={infoAlert.type} sx={{ width: 'fit-content' }}>
                                {infoAlert.message}
                            </Alert>
                        </Collapse>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default TotalsByConnectorTable;
