import { FC, ReactNode, MouseEventHandler } from 'react';
import { Grid } from '@mui/material';
import { ArrowDropDown, ArrowRight } from '@mui/icons-material';
import { Typography } from '@fairplay2/ui';
import { nonClickableItemSx } from '../styles';

const DropdownListItem: FC<{
    children?: ReactNode;
    text: string;
    onClick: MouseEventHandler;
    isSelected?: boolean;
}> = ({ text, onClick, isSelected, children }) => {
    const iconColor = isSelected ? 'secondary.main' : 'primary.main',
        fontColor = isSelected ? 'primary.main' : 'primary.dark',
        fontWeight = isSelected ? 700 : 500,
        marginLeft = isSelected ? 2.5 : undefined,
        containerSx = {
            marginLeft,
            transition: 'margin-left 0.2s',
            '& .data': {
                ...nonClickableItemSx,
                cursor: 'pointer',
                color: 'primary.dark',
            },
            '& .arrow-icon': {
                float: 'right',
                color: iconColor,
            },
            '& .arrow-icon.arrow-right': {
                display: { xs: 'none', sm: 'block' },
            },
            '& .arrow-icon.arrow-drop-down': {
                display: { xs: 'block', sm: 'none' },
            },
            '& > ul': {
                margin: 0,
                padding: 0,
            },
        };

    return (
        <Grid component="li" container sx={containerSx}>
            <Grid
                item
                container
                alignItems="center"
                className="data"
                role="button"
                tabIndex={0}
                onClick={onClick}
                aria-current={isSelected}
            >
                <Grid item xs={10}>
                    <Typography variant="body1" color={fontColor} fontWeight={fontWeight}>
                        {text}
                    </Typography>
                </Grid>
                <Grid container item xs={2} justifyContent="flex-end">
                    <ArrowRight className="arrow-icon arrow-right" />
                    <ArrowDropDown className="arrow-icon arrow-drop-down" />
                </Grid>
            </Grid>
            {!!children && (
                <Grid item container component="ul">
                    {children}
                </Grid>
            )}
        </Grid>
    );
};

export default DropdownListItem;
