import { FC, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { Public, OpenInBrowser, CheckCircle } from '@mui/icons-material';
import { TabGroup, TabData } from '@fairplay2/ui';
import { FormErrors } from 'utils/interfaces';
import { useAlert } from 'utils/hooks';
import { HybridWizardAbstractProps, S3Form } from '../../interfaces';
import S3BucketForm from './S3BucketForm';
import S3AuthForm from './S3AuthForm';
import Confirmation from './Confirmation';

const TAB_DATA: TabData[] = [
    { label: '1. ID & Ubicación', icon: <Public />, disabled: true },
    { label: '2. Autorizar', icon: <OpenInBrowser />, disabled: true },
    { label: '3. Confirmar', icon: <CheckCircle />, disabled: true },
];
const STEP_2_TAB_DATA = TAB_DATA.map((label, i) =>
    i === 0 ? { ...label, disabled: false } : label,
);

const INITIAL_DATA = {
    id: undefined,
    bucket: '',
    awsRegion: '',
    awsAccessKeyId: '',
    awsSecretAccessKey: '',
};

const INITIAL_ERRORS: FormErrors<S3Form> = {
    ...INITIAL_DATA,
};

const S3Wizard: FC<HybridWizardAbstractProps> = ({
    onSuccess: onSuccessProp,
    onClose,
    element,
    setAwaitingOperation,
    awaitingOperation,
}) => {
    const [step, setStep] = useState(0),
        [data, setData] = useState<S3Form>(INITIAL_DATA),
        [errors, setErrors] = useState(INITIAL_ERRORS),
        alertData = useAlert(),
        commonProps = {
            data,
            setData,
            errors,
            setErrors,
            onCancel: onClose,
        };

    const onTabChange = (_: any, newStep: number) => {
        setStep(newStep);
    };

    const onSuccess = () => {
        onSuccessProp?.();
        setStep(2);
    };

    const hasError = (tabIndex: number) => {
        if (tabIndex === 0) return (step !== tabIndex && !!errors.bucket) || !!errors.awsRegion;
        if (tabIndex === 1) return step === tabIndex && !!errors.awsSecretAccessKey;

        return false;
    };

    useEffect(() => {
        // Connector needs reauth
        if (element) {
            setData((prev) => ({
                ...prev,
                bucket: element.bucket || '',
                bucketPath: element.s3_path || '',
                awsRegion: element.aws_region || '',
                id: element.id,
            }));
            setStep(1);
        } else {
            setData(INITIAL_DATA);
            setStep(0);
        }
        setErrors(INITIAL_ERRORS);
    }, [element]);

    return (
        <Grid container>
            <TabGroup
                tabs={step === 1 && !element ? STEP_2_TAB_DATA : TAB_DATA}
                value={step}
                hasError={hasError}
                onChange={onTabChange}
                tabDirection="column"
                TabsProps={{
                    variant: 'fullWidth',
                }}
                sx={{
                    '.MuiTabs-root': {
                        m: '20px 0px 30px 0px',
                        width: '100%',
                    },
                    '.FpTabPanel-root': {
                        mb: 0,
                    },
                }}
            >
                <S3BucketForm {...commonProps} onSubmit={() => onTabChange(null, 1)} />
                <S3AuthForm
                    {...commonProps}
                    awaitingOperation={awaitingOperation}
                    setAwaitingOperation={setAwaitingOperation}
                    onComplete={onSuccess}
                    alertData={alertData}
                />
                <Confirmation type="s3" onClose={onClose} />
            </TabGroup>
        </Grid>
    );
};

export default S3Wizard;
