import { FC, useState, useContext } from 'react';
import { Collapse, DialogTitle, Grid } from '@mui/material';
import { ManParagraphLeavesSvg } from 'assets/SVGs';
import { Modal, DefaultBtn, Typography, InterpunctLoader, Alert } from '@fairplay2/ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import fairplayAPI from 'utils/api';
import SessionContext from 'context/session/sessionContext';
import { useDisbursementValuesContext } from 'context/disbursements/DisbursementValuesContext';
import { useAlert } from 'utils/hooks/useAlert';
import { monetaryIntegerToDecimal } from 'utils/currency';
import { toFormData } from 'utils/parsing';
import { getTotalCurrenciesAmountSum } from '../../utils';
import { DeleteItemModalProps } from '../../interfaces';

const DeleteItemModal: FC<DeleteItemModalProps> = ({
    open,
    onClose,
    isFormSubmitted,
    index,
    setFormsStep,
}) => {
    const { user } = useContext(SessionContext),
        { cartData, removeDisbFormCart, setFormCopy, prevTotalRequestedAmount, exchangeRates } =
            useDisbursementValuesContext(),
        [loading, setLoading] = useState(false),
        { alert, hideAlert, showAlert } = useAlert();

    const onDelete = async () => {
        setLoading(true);
        hideAlert();
        try {
            if (isFormSubmitted) {
                const newTotalRequestedAmountSum = getTotalCurrenciesAmountSum(
                        prevTotalRequestedAmount,
                        exchangeRates,
                    ),
                    data = toFormData({
                        total_amount_mxn: monetaryIntegerToDecimal(
                            newTotalRequestedAmountSum,
                            false,
                        ),
                    });

                await fairplayAPI.delete(
                    `/v1/cart/${user?.id}/items/${cartData[index]?.id_dispersion}`,
                    {
                        data,
                        baseService: 'DISBURSEMENT_CART',
                    },
                );
            }

            const newCartData = removeDisbFormCart();
            setFormCopy({ ...newCartData[index] });
            // if last tabs is deleted, its previous one is selected
            if (newCartData.length === index) setFormsStep(newCartData.length - 1);
            onClose();
        } catch (errResponse: ErrorObj | any) {
            showAlert(
                errResponse?.error ||
                    'Ocurrió un error eliminando la información, intente más tarde',
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            disableBackdropClick
            open={open}
            maxWidth={524}
            onClose={loading ? undefined : onClose}
        >
            <Grid container>
                <Grid container item xs={12} justifyContent="center">
                    <ManParagraphLeavesSvg />
                </Grid>
                <Grid item xs={12} mt="20px">
                    <Typography
                        variant="subtitle1"
                        color="primary.main"
                        component={DialogTitle}
                        p={0}
                    >
                        ¿Estás seguro de eliminar esta dispersión?
                    </Typography>
                </Grid>
                <Grid item xs={12} mt="15px">
                    <Typography color="text.primary" fontWeight={700}>
                        Este paso eliminará esta dispersión solamente.
                    </Typography>
                    <Typography color="text.primary" fontWeight={500} mt="20px">
                        Podrás continuar con el resto de las dispersiones de tu solicitud.
                    </Typography>
                </Grid>
                <Grid container justifyContent="center">
                    <Collapse in={alert.open}>
                        <Alert severity={alert.type} sx={{ mt: 3 }}>
                            {alert.message}
                        </Alert>
                    </Collapse>
                </Grid>
                <Grid container item xs={12} justifyContent="center" mt="20px">
                    {loading ? (
                        <Grid item sx={{ mx: '10px', mt: '15px' }}>
                            <InterpunctLoader />
                        </Grid>
                    ) : (
                        <>
                            <DefaultBtn
                                size="small"
                                variant="outlined"
                                onClick={onClose}
                                sx={{ mx: '10px', mt: '10px' }}
                            >
                                Conservarla
                            </DefaultBtn>
                            <DefaultBtn
                                size="small"
                                onClick={onDelete}
                                sx={{ mx: '10px', mt: '10px' }}
                            >
                                Quitar dispersión
                            </DefaultBtn>
                        </>
                    )}
                </Grid>
            </Grid>
        </Modal>
    );
};

export default DeleteItemModal;
