import { FC } from 'react';
import { Grid } from '@mui/material';
import { Skeleton } from '@fairplay2/ui';

const LoadersAccounts: FC = () => (
    <Grid container spacing={2} data-testid="advertising-accounts-skeleton">
        <Grid item xs={12}>
            <Skeleton animation="wave" height={50} />
        </Grid>
        <Grid item xs={12}>
            <Skeleton animation="wave" height={50} />
        </Grid>
        <Grid item xs={12}>
            <Skeleton animation="wave" height={50} />
        </Grid>
        <Grid item xs={12}>
            <Skeleton animation="wave" height={50} />
        </Grid>
    </Grid>
);

export default LoadersAccounts;
