import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, useTheme, useMediaQuery } from '@mui/material';
import { Modal, DefaultBtn, Typography } from '@fairplay2/ui';
import { ManParagraphLeavesSvg } from 'assets/SVGs';
import { addGoogleTag } from 'utils/google-tag-manager';
import { CancelModalProps } from '../interfaces';

const CancelModal: FC<CancelModalProps> = ({ isModalOpen, onModalClose, clearForm, type }) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        history = useHistory();

    const onClick = () => {
        clearForm();
        onModalClose('cancel');
        history.push('/app/financing/vendors');
        addGoogleTag(`${type}-vendor-cancel`);
    };

    return (
        <Modal
            aria-labelledby="cancel-modal-title"
            disableBackdropClick
            open={isModalOpen}
            maxWidth={680}
            onClose={() => onModalClose('cancel')}
        >
            <Grid container>
                <Grid container justifyContent="center">
                    <ManParagraphLeavesSvg />
                </Grid>
                <Grid item>
                    <Typography id="cancel-modal-title" variant="h5" color="primary.main" mt="20px">
                        ¿Estás seguro de cancelar el proceso?
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2" color="text.primary" fontWeight={700} mt="20px">
                        ¡Se perderá la información!
                    </Typography>
                    <Typography variant="subtitle2" color="text.primary" mt="20px">
                        Si cancelas la alta del nuevo proveedor se perderán los datos que ya has
                        incluido y deberás comenzar el proceso desde el paso 1.
                    </Typography>
                </Grid>
                <Grid container justifyContent="center" mt="40px">
                    <DefaultBtn
                        fullWidth={smDown}
                        size="small"
                        variant="outlined"
                        onClick={() => onModalClose('cancel')}
                        sx={{
                            m: { xs: '0 0 10px 0', sm: '0 10px 0 0' },
                        }}
                    >
                        Ok, no
                    </DefaultBtn>
                    <DefaultBtn
                        fullWidth={smDown}
                        size="small"
                        onClick={onClick}
                        sx={{
                            m: { xs: '10px 0 0 0', sm: '0 0 0 10px' },
                        }}
                    >
                        Sí, cancelar
                    </DefaultBtn>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default CancelModal;
