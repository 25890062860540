import { FC, useState, ChangeEvent, FormEvent } from 'react';
import {
    Modal,
    Typography,
    DefaultBtn,
    DefaultInput,
    InterpunctLoader,
    Alert,
} from '@fairplay2/ui';
import { Grid, Collapse, useTheme, useMediaQuery } from '@mui/material';
import fairplayAPI from 'utils/api';
import { useAlert } from 'utils/hooks';
import { toFormData } from 'utils/parsing';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { ConnectorProps, VtexForm } from '../interfaces';
import Confirmation from '../components/Confirmation';
import { useDataSources } from '../../context/DataSourcesContext';
import ConnectorFormTitle from '../components/ConnectorFormTitle';

export const VtexModal: FC<ConnectorProps> = ({ open, connector, onClose }) => {
    const { baseAPI, dispatch } = useDataSources(),
        theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        [values, setValues] = useState<VtexForm>({
            accountName: '',
            vtexAccessKey: '',
            vtexAppToken: '',
        }),
        [errors, setErrors] = useState<VtexForm>({
            accountName: '',
            vtexAccessKey: '',
            vtexAppToken: '',
        }),
        [confirmation, setConfirmation] = useState(false),
        [loading, setLoading] = useState(false),
        { alert, hideAlert, showAlert } = useAlert();

    const onChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
        hideAlert();
        setValues((prev) => ({ ...prev, [name]: value }));
    };

    const onChangeInput = (prop: keyof VtexForm, errorMsg: string) => {
        if (values[prop]) setErrors((prev) => ({ ...prev, [prop]: '' }));
        else setErrors((prev) => ({ ...prev, [prop]: errorMsg }));
    };

    const clearForm = () => {
        setValues({ accountName: '', vtexAccessKey: '', vtexAppToken: '' });
        setErrors({ accountName: '', vtexAccessKey: '', vtexAppToken: '' });
        hideAlert();
    };

    const onConnect = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        hideAlert();

        setTimeout(() => {
            setLoading(false);
        }, 2000);

        // Check if there isn't an error
        if (!Object.values(errors).some((x) => x !== null && x !== '')) {
            setLoading(true);
            const data = toFormData({
                    platform: 'vtex',
                    accountName: connector?.accountName || values.accountName,
                    vtexAccessKey: values.vtexAccessKey,
                    vtexAppToken: values.vtexAppToken,
                }),
                endpoint = `${baseAPI}${connector ? `/${connector.id}/reconnect` : ''}`;

            try {
                const res: any = await fairplayAPI.post(endpoint, data);
                dispatch({
                    type: connector ? 'UPDATE' : 'ADD',
                    payload: res.data.body.results,
                });
                setConfirmation(true);
            } catch (errResponse: ErrorObj | any) {
                if (errResponse?.type === 'form')
                    setErrors((prev) => ({ ...prev, ...errResponse.error }));
                else {
                    showAlert(
                        errResponse?.error ||
                            'No se pudo realizar la conexión, verifique sus credenciales e intente nuevamente',
                    );
                }
            } finally {
                setLoading(false);
            }
        }
    };

    const closeModal = () => {
        document.body.style.overflow = 'auto';
        clearForm();
        setConfirmation(false);
        onClose();
    };

    return (
        <Modal
            disableBackdropClick
            open={open}
            onClose={closeModal}
            maxWidth={640}
            disableEscapeKeyDown
            title={!confirmation && <ConnectorFormTitle groupKey="vtex" connector={connector} />}
            sx={{
                '.MuiDialogContent-root': {
                    pt: 0,
                },
            }}
        >
            {confirmation ? (
                <Confirmation item="Vtex" onClose={closeModal} />
            ) : (
                <Grid container spacing={4} component="form" onSubmit={onConnect}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" color="text.primary">
                            Para que podamos conectar tu tienda con nuestra plataforma, es necesario
                            que nos proporciones la siguiente información.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <DefaultInput
                            id="vtex-account-name"
                            name="accountName"
                            labelVariant="external"
                            label="Nombre de la cuenta"
                            type="text"
                            value={connector?.accountName || values.accountName}
                            onChange={onChange}
                            onBlur={() =>
                                onChangeInput('accountName', 'Ingresa el nombre de la cuenta')
                            }
                            error={errors.accountName}
                            required
                            InputLabelProps={{ required: false }}
                            disabled={!!connector}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <DefaultInput
                            id="vtex-access-key"
                            name="vtexAccessKey"
                            labelVariant="external"
                            label="Access Key"
                            type="text"
                            value={values.vtexAccessKey}
                            onChange={onChange}
                            onBlur={() => onChangeInput('vtexAccessKey', 'Ingresa la Access key')}
                            error={errors.vtexAccessKey}
                            required
                            InputLabelProps={{ required: false }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <DefaultInput
                            id="vtex-app-token"
                            name="vtexAppToken"
                            labelVariant="external"
                            label="Token"
                            type="text"
                            value={values.vtexAppToken}
                            onChange={onChange}
                            onBlur={() => onChangeInput('vtexAppToken', 'Ingresa el Token')}
                            error={errors.vtexAppToken}
                            required
                            InputLabelProps={{ required: false }}
                        />
                    </Grid>

                    <Grid item container xs={12} justifyContent="center" paddingTop="0 !important">
                        <Collapse in={alert.open}>
                            <Grid container item xs={12}>
                                <Alert severity={alert.type} sx={{ mt: 3, mb: -2 }}>
                                    {alert.message}
                                </Alert>
                            </Grid>
                        </Collapse>
                    </Grid>

                    <Grid
                        item
                        container
                        xs={12}
                        justifyContent={{ xs: 'center', sm: 'flex-end' }}
                        marginTop={1.25}
                    >
                        {loading ? (
                            <InterpunctLoader />
                        ) : (
                            <>
                                <DefaultBtn
                                    fullWidth={smDown}
                                    size="small"
                                    variant="outlined"
                                    onClick={closeModal}
                                >
                                    Cancelar
                                </DefaultBtn>
                                <DefaultBtn
                                    fullWidth={smDown}
                                    size="small"
                                    type="submit"
                                    sx={{
                                        ml: { xs: 0, sm: '20px' },
                                        mt: { xs: '20px', sm: 0 },
                                    }}
                                >
                                    Conectar
                                </DefaultBtn>
                            </>
                        )}
                    </Grid>
                </Grid>
            )}
        </Modal>
    );
};

export default VtexModal;
