// TODO: Refactor fetch data functions and reducer to facilitate testing
import { getConnectorAliasWithInvalidStatus } from 'components/dashboard/data-sources/utils';
import fairplayAPI from 'utils/api';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { STATUS_DIC, SUMMARY_KEYS, TODAY_DATE, TODAY_MINUS_90 } from '../../utils/constants';
import {
    hasStoreWithoutGrowthPercentage,
    formatChannelsTableResults,
    formatMainChartResult,
} from '../../utils/utils';
import { SalesFetchDataProps, SalesFetchChannelsData, SummariesKeys } from '../interfaces';
import {
    GMVOrdersResponse,
    GMVTotalsByConnectorResponse,
    GMVTotalsByPlatformResponse,
} from './interfaces';

export const salesFetchChannelsData = async ({
    dispatch,
    globalData,
    hideAlert,
    showAlert,
}: SalesFetchChannelsData) => {
    dispatch({
        type: 'START_LOADING_CHANNELS',
    });
    try {
        hideAlert();

        const channelsTableResponse: any = await fairplayAPI.post(
                '/v1/analytics/gmv-orders/totals-by-platform',
                globalData,
            ),
            channelsTableResults = channelsTableResponse.data.body.results,
            channelsTableData = formatChannelsTableResults(channelsTableResults);

        dispatch({
            type: 'SET_CHANNELS_TABLE_DATA',
            payload: {
                count: channelsTableResponse.data.body.count,
                tableData: channelsTableData,
            },
        });
    } catch (errResponse: ErrorObj | any) {
        showAlert(
            errResponse?.error ||
                'No se pudieron cargar los datos de ventas por canal, intente más tarde',
            'error',
            false,
        );
    } finally {
        dispatch({
            type: 'STOP_LOADING_CHANNELS',
        });
    }
};

export const salesFetchPeriodsData = async ({
    dispatch,
    globalData,
    hideAlert,
    showAlert,
}: SalesFetchChannelsData) => {
    dispatch({
        type: 'START_LOADING_PERIODS',
    });
    try {
        hideAlert();

        const totalsResponse = await fairplayAPI.post<GMVTotalsByConnectorResponse>(
                '/v1/analytics/gmv-orders/totals-by-connector',
                globalData,
            ),
            totalsResults = totalsResponse.data.body.results;

        dispatch({
            type: 'SET_PERIODS_DATA',
            payload: totalsResults.slice(1, totalsResults.length).map((entry: any) => ({
                alias: entry.alias,
                fss: entry.fss,
                id: entry.id,
                inFilters: entry.in_filters,
                isAliasEdited: entry.alias_edited_by_user,
                platform: entry.platform,
                status: entry.status,
                summaries: Object.keys(entry.summaries).reduce((acc: any, key: string) => {
                    acc[key] = {
                        validTotal: entry.summaries[key].valid,
                        invalidTotal: entry.summaries[key].invalid,
                        returnedTotal: entry.summaries[key].returned,
                        total: entry.summaries[key].total,
                    };
                    return acc;
                }, {}),
            })),
        });
        dispatch({
            type: 'SET_PERIODS_SUMMARY',
            payload: Object.keys(totalsResults[0].summaries).reduce((acc: any, key: string) => {
                acc[key] = {
                    validTotal: totalsResults[0].summaries[key as SummariesKeys].valid,
                    invalidTotal: totalsResults[0].summaries[key as SummariesKeys].invalid,
                    returnedTotal: totalsResults[0].summaries[key as SummariesKeys].returned,
                    total: totalsResults[0].summaries[key as SummariesKeys].total,
                };
                return acc;
            }, {}),
        });
        dispatch({
            type: 'SET_COUNT',
            payload: {
                key: 'periods',
                value: totalsResponse.data.body.count ?? 0,
            },
        });
    } catch (errResponse: ErrorObj | any) {
        showAlert(
            errResponse?.error ||
                'No se pudieron cargar los datos de ventas por canal, intente más tarde',
            'error',
            false,
        );
    } finally {
        dispatch({
            type: 'STOP_LOADING_PERIODS',
        });
    }
};

export const salesFetchData = async ({
    dispatch,
    filterValues,
    globalData,
    needsToReset = false,
    hideAlert,
    showAlert,
    stores,
}: SalesFetchDataProps) => {
    dispatch({
        type: needsToReset ? 'RESTART_LOADING' : 'START_LOADING',
    });
    dispatch({
        type: 'START_LOADING_CHANNELS',
    });
    try {
        if (!globalData) return;

        hideAlert();

        const generalResponse = await fairplayAPI.post<GMVOrdersResponse>(
                '/v1/analytics/gmv-orders',
                globalData,
            ),
            channelsGraphResponse = await fairplayAPI.post<GMVTotalsByPlatformResponse>(
                '/v1/analytics/gmv-orders/totals-by-platform',
                globalData,
            ),
            generalResults = generalResponse.data.body.results,
            channelsGraphResults = channelsGraphResponse.data.body.results;
        dispatch({
            type: 'SET_GENERAL_RESULTS',
            payload: { results: generalResults, displayInformationModal: needsToReset },
        });
        dispatch({
            type: 'SET_TABS_DATA',
            payload: Object.keys(generalResults[0].summaries).reduce((acc: any, key: string) => {
                acc[key] = {
                    isNumber: key !== 'orders',
                    emptyData: generalResults[0].summaries[key as SummariesKeys].total === null,
                    indicator:
                        generalResults[0].summaries[key as SummariesKeys].growth_percentage > 0
                            ? 'over'
                            : 'below',
                    insuficientData:
                        generalResults[0].summaries[key as SummariesKeys].growth_percentage ===
                        null,
                    percentage: generalResults[0].summaries[key as SummariesKeys].growth_percentage,
                    status:
                        filterValues.status[0] === ''
                            ? 'Válido'
                            : STATUS_DIC[filterValues.status[0] as keyof typeof STATUS_DIC],
                    title: SUMMARY_KEYS[key as SummariesKeys],
                    value: String(generalResults[0].summaries[key as SummariesKeys].total),
                };
                return acc;
            }, {}),
        });
        dispatch({
            type: 'SET_MAIN_CHART_DATA',
            payload: {
                gmv: {
                    data: formatMainChartResult(generalResults, 'gmv'),
                    title: 'Ventas',
                    valuePrefix: '$',
                },
                orders: {
                    data: formatMainChartResult(generalResults, 'orders'),
                    title: 'Órdenes',
                    valuePrefix: '',
                },
                average: {
                    data: formatMainChartResult(generalResults, 'average'),
                    title: 'Ticket promedio',
                    valuePrefix: '$',
                },
            },
        });
        dispatch({
            type: 'SET_CHANNELS_DATA',
            payload: formatChannelsTableResults(channelsGraphResults),
        });

        if (needsToReset) {
            const filters = {
                selectedDayRange: {
                    from: TODAY_MINUS_90,
                    to: TODAY_DATE,
                },
                year: [''],
                month: [''],
                store: [Object.keys(stores)[0]],
                status: ['valid'],
            };
            dispatch({
                type: 'SET_STORES',
                payload: {
                    '': 'Tienda',
                    all: 'Todas',
                    ...stores,
                },
            });
            dispatch({
                type: 'SET_FILTER_VALUES',
                payload: filters,
            });
            dispatch({
                type: 'SET_FILTERED_VALUES',
                payload: filters,
            });
            dispatch({
                type: 'SET_ACTIVE_FILTERS',
                payload: 4,
            });
        } else {
            salesFetchChannelsData({ dispatch, globalData, hideAlert, showAlert });
            salesFetchPeriodsData({ dispatch, globalData, hideAlert, showAlert });
        }

        const storesWithErrors = getConnectorAliasWithInvalidStatus(generalResults.slice(1));

        if (storesWithErrors.length > 0)
            return showAlert(
                `Error de conexión con ${
                    storesWithErrors.length === 1 ? 'la tienda' : 'las tiendas'
                } ${storesWithErrors.join(', ')}`,
                'error',
                false,
            );
        if (hasStoreWithoutGrowthPercentage(generalResults.slice(1)))
            showAlert('Datos insuficientes para calcular el % de crecimiento', 'warning', false);
    } catch (errResponse: ErrorObj | any) {
        showAlert(
            errResponse?.error || 'No se pudieron cargar los datos de ventas, intente más tarde',
            'error',
            false,
        );
    } finally {
        dispatch({
            type: 'STOP_LOADING_CHANNELS',
        });
        dispatch({
            type: 'STOP_LOADING',
        });
    }
};
