import { FC } from 'react';
import { Grid, MenuItem } from '@mui/material';
import { Alert, DefaultInput, Select, Typography } from '@fairplay2/ui';
import { SHORT_READABLE_FORMAT, formatUtc } from 'utils/dates';
import { formatAmount } from 'utils/parsing';
import { AttachMoney } from '@mui/icons-material/';
import { contractPrintableName } from 'utils/formatting';
import { CurrencyInput } from '@fairplay2/ui-x-currencies';
import { SelectContractProps } from '../../interfaces';
import SelectContractSkeleton from './SelectContractSkeleton';

const SelectContract: FC<SelectContractProps> = ({
    contracts,
    selectedContractId,
    onContractSelect,
    contractInfo,
    title,
    canContractDisburse,
    alertMessage,
}) => (
    <>
        <Grid item xs={12} textAlign="left">
            <Typography variant="subtitle2" color="primary.main">
                {title}
            </Typography>
        </Grid>

        <Grid
            container
            sx={{
                '@media (max-width: 1080px)': {
                    flexDirection: 'column',
                    alignItems: 'start',
                },
                alignItems: 'end',
            }}
        >
            <Grid
                item
                mt="25px"
                mr="16px"
                minWidth="300px"
                sx={{
                    '@media (max-width:1310px)': {
                        minWidth: 230,
                    },
                    '@media (max-width: 1080px)': {
                        width: '100%',
                        mr: '0px',
                    },
                }}
            >
                <Select
                    labelVariant="external"
                    label="Selecciona el contrato"
                    name="contract"
                    value={contracts.length > 0 ? selectedContractId : ''}
                    onChange={(event) => onContractSelect(event.target.value)}
                >
                    {contracts.length > 0 ? (
                        contracts.map((contract) => (
                            <MenuItem
                                key={contract.id}
                                value={contract.id}
                            >{`Contrato ${contractPrintableName(contract)}`}</MenuItem>
                        ))
                    ) : (
                        <MenuItem value="">No hay contratos registrados</MenuItem>
                    )}
                </Select>
            </Grid>

            {/* eslint-disable-next-line no-nested-ternary */}
            {contractInfo === undefined ? (
                <SelectContractSkeleton />
            ) : canContractDisburse ? (
                <Grid
                    item
                    container
                    mt={[0, 0, '25px']}
                    columnSpacing={2}
                    rowSpacing={[2, 2, 0]}
                    alignItems="baseline"
                    sx={{
                        flexGrow: 1,
                        flexBasis: 0,
                    }}
                >
                    <Grid item xs={12} sm={4}>
                        <DefaultInput
                            labelVariant="external"
                            label="Periodo de dispersión"
                            id="country"
                            type="text"
                            value={`${formatUtc(
                                contractInfo?.start_date || '',
                                SHORT_READABLE_FORMAT,
                                {
                                    fallbackString: '',
                                },
                            )} - ${formatUtc(contractInfo?.end_date || '', SHORT_READABLE_FORMAT, {
                                fallbackString: '',
                            })}`}
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <CurrencyInput
                            labelVariant="external"
                            label="Solicitado en el periodo"
                            disabled
                            id="disbursement-over-period"
                            value={formatAmount(contractInfo?.total_period_spend ?? undefined)}
                            startAdornment={<AttachMoney color="primary" />}
                            thousandSeparator
                            sx={{
                                '.MuiInputBase-input': {
                                    textAlign: 'right',
                                },
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <CurrencyInput
                            labelVariant="external"
                            label="Disponible hoy"
                            disabled
                            id="disbursement-available-today"
                            value={formatAmount(contractInfo?.period_total_available ?? undefined)}
                            startAdornment={<AttachMoney color="primary" />}
                            thousandSeparator
                            fixedDecimalScale
                            sx={{
                                '.MuiInputBase-input': {
                                    textAlign: 'right',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            ) : (
                alertMessage && (
                    <Grid
                        sx={{
                            '@media (max-width: 1080px)': {
                                mt: '25px',
                            },
                        }}
                    >
                        <Alert severity="warning" sx={{ mb: '3px' }}>
                            {alertMessage}
                        </Alert>
                    </Grid>
                )
            )}
        </Grid>

        <Grid item xs={12} mt="10px">
            <Typography variant="body2" fontWeight={500} color="primary.dark">
                Montos en pesos mexicanos
            </Typography>
        </Grid>
    </>
);

export default SelectContract;
