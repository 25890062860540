import { FC } from 'react';
import { Grid, MenuItem } from '@mui/material';
import { DefaultInput, Select, Skeleton, Typography } from '@fairplay2/ui';
import { AddNationalAccountProps } from '../interfaces';

const AddNational: FC<AddNationalAccountProps> = ({
    step,
    values,
    loading,
    errors,
    onChange,
    onSelect,
    onBlur,
}) => (
    <Grid container spacing={1}>
        <Grid item container xs={12}>
            <Grid item mb="4px" xs={12}>
                <Typography
                    component="label"
                    color="text.primary"
                    variant="body1"
                    fontWeight={500}
                    htmlFor="vendor-CLABE"
                >
                    CLABE
                </Typography>
            </Grid>
            {loading ? (
                <Grid item xs={12} justifyContent="center" alignItems="center" minHeight={50}>
                    <Skeleton height={53} />
                </Grid>
            ) : (
                <DefaultInput
                    id="vendor-CLABE"
                    labelVariant="none"
                    disabled={step === 'confirm'}
                    name="clabe"
                    value={values.clabe}
                    onChange={onChange}
                    onBlur={() => onBlur('clabe', 'Ingresa la clabe')}
                    error={errors.clabe}
                    placeholder="18 dígitos"
                    inputProps={{ maxLength: 18 }}
                />
            )}
        </Grid>

        <Grid item xs={12}>
            <Select
                id="vendor-currency"
                label="Divisa"
                labelVariant="external"
                disabled={step === 'confirm'}
                value={values.accountCurrency}
                onChange={onSelect}
                onBlur={() => onBlur('accountCurrency', 'Ingresa la divisa')}
                error={errors.accountCurrency}
            >
                <MenuItem value="" disabled>
                    Selecciona la divisa
                </MenuItem>
                <MenuItem value="MXN">MXN</MenuItem>
                <MenuItem value="USD">USD</MenuItem>
            </Select>
        </Grid>
        <Grid item xs={12}>
            <DefaultInput
                id="vendor-accountNumber"
                label="Número de cuenta"
                labelVariant="external"
                disabled
                name="accountNumber"
                value={values.accountNumber}
            />
        </Grid>
        <Grid item xs={12}>
            <DefaultInput
                id="vendor-bank"
                label="Banco"
                labelVariant="external"
                disabled
                name="bank"
                value={values.bank}
            />
        </Grid>
    </Grid>
);

export default AddNational;
