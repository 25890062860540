import { FC, useState, useEffect } from 'react';
import { ReCaptcha } from 'common-components';
import { Typography, DefaultInput, DefaultBtn, InterpunctLoader } from '@fairplay2/ui';
import { Grid, Collapse, Alert } from '@mui/material';
import { MailOutline } from '@mui/icons-material';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { useRecaptcha, useAlert } from 'utils/hooks';
import fairplayAPI from 'utils/api';
import { censorEmail } from 'utils/formatting';
import { ConfirmationRecoverProps } from '../interfaces';

const ConfirmationRecover: FC<ConfirmationRecoverProps> = ({ email, onCancel }) => {
    const [hiddenEmail, setHiddenEmail] = useState(''),
        [showCaptcha, setShowCaptcha] = useState(false),
        [loading, setLoading] = useState(false),
        { onLoadRecaptcha, recaptchaIsLoading, recaptchaRef, resetRecaptcha } = useRecaptcha(),
        { alert, showAlert } = useAlert();

    const onSend = async () => {
        if (!showCaptcha) {
            setShowCaptcha(true);
            return;
        }
        const recaptchaValue = recaptchaRef.current?.getValue();

        if (recaptchaValue) {
            setLoading(true);
            try {
                await fairplayAPI.post(
                    '/v1/users/reset_password',
                    {
                        email,
                        g_recaptcha_response: recaptchaValue,
                    },
                    { authorization: false },
                );
                showAlert('El correo se ha enviado nuevamente', 'success');
            } catch (errResponse: ErrorObj | any) {
                showAlert(errResponse?.error || 'Se ha producido un error. Intente más tarde');
            } finally {
                setLoading(false);
                resetRecaptcha();
            }

            setShowCaptcha(false);
        } else showAlert('Es necesario completar el reCAPTCHA');
    };

    useEffect(() => {
        setHiddenEmail(censorEmail(email));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid
            item
            container
            xs={12}
            mb={{ xs: '140px', sm: '190px', md: '210px' }}
            direction="column"
            justifyContent="center"
        >
            <Grid item my={[2, 3]}>
                <Typography
                    variant="body2"
                    color="primary.contrastText"
                    fontWeight={700}
                    lineHeight={1.8}
                >
                    El link ha sido enviado al correo electrónico registrado. No olvides revisar la
                    bandeja de spam. Recuerda que el link puede tardar unos minutos en llegar.
                </Typography>
            </Grid>
            <Grid item mb={[2, 3]}>
                <DefaultInput
                    name="hiddenEmail"
                    label="Email oculto"
                    disabled
                    value={hiddenEmail}
                    startAdornment={<MailOutline />}
                />
            </Grid>
            <Grid item container mb={[2, 3]}>
                <DefaultBtn color="mixed" fullWidth onClick={onCancel}>
                    Iniciar sesión
                </DefaultBtn>
            </Grid>

            <Collapse
                in={showCaptcha}
                sx={{
                    width: '100%',
                }}
            >
                <Grid container gap={[2, 3]} mb={[2, 3]}>
                    <Grid item>
                        <Typography
                            variant="body2"
                            color="primary.contrastText"
                            fontWeight={700}
                            lineHeight={1.8}
                        >
                            Si deseas recibir otro link por favor activa la casilla de verificación
                            y haz click en confirmar.
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        container
                        justifyContent="center"
                        sx={{
                            transform: {
                                lg: 'scale(0.9)',
                                xl: 'scale(1)',
                            },
                        }}
                    >
                        <ReCaptcha
                            ref={recaptchaRef}
                            isLoading={recaptchaIsLoading}
                            onLoad={onLoadRecaptcha}
                        />
                    </Grid>
                </Grid>
            </Collapse>

            <Collapse in={alert.open}>
                <Grid
                    container
                    item
                    xs={12}
                    mb={alert.open ? [2, 3] : []}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Alert variant="filled" severity={alert.type}>
                        {alert.message}
                    </Alert>
                </Grid>
            </Collapse>

            <Grid item container justifyContent="center" alignItems="center">
                {loading ? (
                    <Grid item>
                        <InterpunctLoader />
                    </Grid>
                ) : (
                    <Grid item>
                        <DefaultBtn variant="text" color="mixed" fullWidth onClick={onSend}>
                            {showCaptcha ? 'Confirmar el envío' : 'No recibí el link'}
                        </DefaultBtn>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default ConfirmationRecover;
