import { ChangeEvent, FC, useState, useEffect, useCallback } from 'react';
import NumberFormat from 'react-number-format';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
    Alert,
    Checkbox,
    DefaultBtn,
    DefaultInput,
    InterpunctLoader,
    PasswordInput,
    Select,
    Typography,
} from '@fairplay2/ui';
import { ReCaptcha } from 'common-components';
import { Collapse, Divider, Grid, Link, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import { MailOutline, Smartphone } from '@mui/icons-material';
import fairplayAPI, { setSession } from 'utils/api';
import { addGoogleTag } from 'utils/google-tag-manager';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj, setFormErrors } from 'utils/error-handler';
import { phoneRules } from 'utils/validation';
import { useRecaptcha, useAlert, useToggle } from 'utils/hooks';
import { toFormData } from 'utils/parsing';
import { CreateAccountForm, SignUpFormProps } from '../../interfaces';
import DuplicatedAccountModal from './DuplicatedAccountModal';

const SignUpForm: FC<SignUpFormProps> = ({ setShowValidationSent, setEmail }) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        mdDown = useMediaQuery(theme.breakpoints.down('md')),
        history = useHistory(),
        { alert, hideAlert, showAlert } = useAlert(),
        { onLoadRecaptcha, resetRecaptcha, recaptchaIsLoading, recaptchaRef } = useRecaptcha(),
        [isDuplicatedAccountModalOpen, toggleDuplicatedAccountModal] = useToggle(),
        [checked, setChecked] = useState(false),
        [loading, setLoading] = useState(false),
        [emailCanBeRegistered, setEmailCanBeRegistered] = useState(true),
        [companyPositions, setCompanyPositions] = useState([]),
        {
            register,
            formState: { errors },
            handleSubmit,
            getValues,
            clearErrors,
            setError,
            setValue,
        } = useForm<CreateAccountForm>({
            mode: 'onBlur',
            values: {
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                password: '',
                password2: '',
                // companyPosition can't be undefined
                companyPosition: '',
            },
        }),
        values = getValues();

    const onLogin = () => {
        history.push('/');
    };

    const onCheck = (event: ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const clearAlert = () => {
        hideAlert();
        clearErrors();
    };

    const getCompanyPositions = useCallback(async () => {
        try {
            const res: any = await fairplayAPI.get('/v2/company-positions', {
                authorization: false,
            });
            setCompanyPositions(res.data.body.results);
        } catch (errResponse: ErrorObj | any) {
            showAlert(errResponse?.error);
        }
    }, [showAlert]);

    const onSubmit = async () => {
        const recaptchaValue = recaptchaRef.current?.getValue();

        hideAlert();
        clearErrors('password2');

        if (!recaptchaValue) return showAlert('Es necesario completar el reCAPTCHA');

        setLoading(true);
        const data = toFormData({
            ...values,

            g_recaptcha_response: recaptchaValue,
        });

        try {
            const res: any = await fairplayAPI.post('/v1/users/register', data, {
                authorization: false,
            });
            setSession(res.data.body.results.token);
            addGoogleTag('onboarding-user-data');
            setEmail(values.email);

            try {
                await fairplayAPI.post('/v1/users/terms', {
                    accepted: 'True',
                });
                setShowValidationSent(true);
            } catch (errResponse: ErrorObj | any) {
                showAlert('No se registraron los términos y condiciones');
            }
        } catch (errResponse: ErrorObj | any) {
            if (errResponse?.type === 'form') {
                // if the email exists, open popup
                // using toString() to convert object response to string and match data types
                if (
                    Object.values(errResponse?.error).some(
                        (value: any) =>
                            value.toString() ===
                            'Esta dirección de correo electrónico no se puede registrar.',
                    )
                ) {
                    toggleDuplicatedAccountModal();
                    setEmailCanBeRegistered(false);
                }
                setFormErrors<CreateAccountForm>(errResponse?.error, setError);
            } else showAlert(errResponse?.error || 'Se ha producido un error. Intente más tarde');
        } finally {
            setLoading(false);
            resetRecaptcha();
        }
    };

    useEffect(() => {
        getCompanyPositions();
    }, [getCompanyPositions]);

    return (
        <Grid component="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid item container xs={12} marginTop={5}>
                <Grid item container xs={12} md={5}>
                    <DefaultInput
                        label="Nombre (s)"
                        id="firstName"
                        placeholder="Nombre (s)"
                        sx={{
                            mb: '25px',
                        }}
                        type="text"
                        error={errors.firstName?.message}
                        {...register('firstName', {
                            required: 'Ingresa tu nombre',
                        })}
                    />
                </Grid>
                {!mdDown && (
                    <Divider
                        orientation="vertical"
                        flexItem
                        variant="fullWidth"
                        sx={{ mx: '4%', color: 'auxiliary.light' }}
                    />
                )}
                <Grid item container xs={12} md={5}>
                    <DefaultInput
                        label="Apellido (s)"
                        id="lastName"
                        placeholder="Apellido (s)"
                        sx={{
                            mb: '25px',
                        }}
                        type="text"
                        error={errors.lastName?.message}
                        {...register('lastName', {
                            onChange: () => clearAlert(),
                            required: 'Ingresa tu apellido',
                        })}
                    />
                </Grid>
                <Grid item container xs={12} md={5}>
                    <NumberFormat
                        label="Celular"
                        id="phone"
                        placeholder="Celular"
                        startAdornment={<Smartphone />}
                        type="tel"
                        error={errors.phone?.message}
                        customInput={DefaultInput}
                        inputProps={{ maxLength: 10 }}
                        {...register('phone', {
                            onChange: (e) => {
                                setValue('phone', e?.target?.value);
                                clearAlert();
                            },
                            required: 'Ingresa tu celular',
                            pattern: {
                                value: phoneRules.regex,
                                message: phoneRules.message,
                            },
                        })}
                    />
                </Grid>
                {!mdDown && (
                    <Divider
                        orientation="vertical"
                        flexItem
                        variant="fullWidth"
                        sx={{ mx: '4%', color: 'auxiliary.light' }}
                    />
                )}
                <Grid item container xs={12} md={5}>
                    <DefaultInput
                        label="Correo electrónico"
                        id="email"
                        placeholder="Correo electrónico"
                        sx={{
                            mt: { xs: '25px', md: 0 },
                        }}
                        startAdornment={<MailOutline />}
                        type="email"
                        value={values.email.toLowerCase()}
                        error={errors.email?.message}
                        {...register('email', {
                            onChange: () => {
                                if (!emailCanBeRegistered) setEmailCanBeRegistered(true);
                                clearAlert();
                            },
                            required: 'Ingresa tu correo electrónico',
                            pattern: /\S+@\S+\.\S+/,
                            validate: {
                                isValidEmail: (email) =>
                                    /\S+@\S+\.\S+/.test(email) || 'Correo electrónico no válido',
                                emailCanBeRegistered: () =>
                                    emailCanBeRegistered ||
                                    'Esta dirección de correo electrónico no se puede registrar',
                            },
                        })}
                    />
                </Grid>
                <Grid item container xs={12} md={5}>
                    <PasswordInput
                        label="Contraseña"
                        id="password"
                        placeholder="Contraseña"
                        sx={{
                            mt: '25px',
                        }}
                        error={errors.password?.message}
                        forceHiddenText={loading}
                        {...register('password', {
                            onChange: () => clearAlert(),
                            required: 'Ingresa la contraseña',
                            validate: {
                                isValidLengthPassword: () =>
                                    values.password.length >= 8 ||
                                    'La contraseña debe contener al menos 8 caracteres',
                            },
                        })}
                    />
                </Grid>
                {!mdDown && (
                    <Divider
                        orientation="vertical"
                        flexItem
                        variant="fullWidth"
                        sx={{ mx: '4%', color: 'auxiliary.light' }}
                    />
                )}
                <Grid item container xs={12} md={5}>
                    <PasswordInput
                        label="Confirmar contraseña"
                        id="password2"
                        placeholder="Confirmar contraseña"
                        sx={{
                            mt: '25px',
                        }}
                        error={errors.password2?.message}
                        forceHiddenText={loading}
                        {...register('password2', {
                            onChange: () => clearAlert(),
                            required: 'Ingresa la contraseña nuevamente',
                            validate: {
                                passwordsNotEqual: () =>
                                    values.password === values.password2 ||
                                    'La contraseña no coincide',
                            },
                        })}
                    />
                </Grid>
                <Grid item container xs={12} md={5} mt="25px">
                    <Select
                        label="Puesto dentro de la empresa"
                        id="companyPosition"
                        error={errors.companyPosition?.message}
                        disabled={!companyPositions.length}
                        value={values.companyPosition}
                        {...register('companyPosition', {
                            onChange: () => {
                                clearAlert();
                            },
                            onBlur: () => {
                                if (values.companyPosition) clearErrors('companyPosition');
                            },
                            required: 'Ingresa tu puesto dentro de la empresa',
                        })}
                    >
                        <MenuItem value="" disabled>
                            Puesto dentro de la empresa
                        </MenuItem>
                        {companyPositions.length > 0 &&
                            companyPositions.map((option: any) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                    </Select>
                </Grid>
                {!mdDown && (
                    <Divider
                        orientation="vertical"
                        flexItem
                        variant="fullWidth"
                        sx={{ mx: '4%', color: 'auxiliary.light' }}
                    />
                )}
            </Grid>
            <Grid
                item
                container
                xs={12}
                md={11}
                alignItems="flex-start"
                wrap="nowrap"
                sx={{ mt: 3, mb: 3 }}
            >
                <Checkbox name="terms" onChange={onCheck} checked={checked} />
                <Grid item marginLeft={1}>
                    <Typography variant="body1" color="text.primary" mt="2px">
                        Confirmo que entiendo y acepto los{' '}
                        <Link
                            href="https://getfairplay.com/terminos-y-condiciones/"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ textDecoration: 'underline' }}
                        >
                            Términos y condiciones
                        </Link>{' '}
                        de Fairplay Insights, así como el{' '}
                        <Link
                            href="https://getfairplay.com/aviso-de-privacidad/"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ textDecoration: 'underline' }}
                        >
                            Aviso de privacidad
                        </Link>{' '}
                        y cláusula de medios electrónicos tales como NIP.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container xs={12} md={11} justifyContent="flex-end" marginBottom={2}>
                <Grid
                    item
                    container
                    justifyContent={['center', 'flex-end']}
                    mb={alert.open ? '' : '20px'}
                >
                    <ReCaptcha
                        ref={recaptchaRef}
                        isLoading={recaptchaIsLoading}
                        onLoad={onLoadRecaptcha}
                    />
                </Grid>
                <Collapse in={alert?.open}>
                    <Grid item xs={12} my={alert.open ? '20px' : ''} width="300px">
                        <Alert variant="filled" severity={alert.type}>
                            {alert.message}
                        </Alert>
                    </Grid>
                </Collapse>
            </Grid>
            <Grid
                item
                container
                xs={12}
                md={11}
                marginBottom={{ xs: '5px', md: 0 }}
                justifyContent="flex-end"
            >
                {loading ? (
                    <InterpunctLoader />
                ) : (
                    <DefaultBtn
                        size="medium"
                        fullWidth={smDown}
                        sx={{ px: { xs: 0, sm: '10px' } }}
                        disabled={!checked}
                        type="submit"
                    >
                        Crear perfil de administrador
                    </DefaultBtn>
                )}
            </Grid>
            <DuplicatedAccountModal
                open={isDuplicatedAccountModalOpen}
                onClose={() => toggleDuplicatedAccountModal()}
                onConfirm={onLogin}
            />
        </Grid>
    );
};

export default SignUpForm;
