import { FC, useState, useEffect, ChangeEvent, FormEvent, useContext } from 'react';
import { Grid, Collapse, useTheme, useMediaQuery } from '@mui/material';
import {
    Modal,
    Typography,
    DefaultBtn,
    DefaultInput,
    PasswordInput,
    InterpunctLoader,
    Alert,
} from '@fairplay2/ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { AlertProps } from 'utils/interfaces';
import sessionContext from 'context/session/sessionContext';
import fairplayAPI from 'utils/api';
import { toFormData } from 'utils/parsing';
import { getPriorityConnector } from '../../context/utils';
import Confirmation from '../components/Confirmation';
import { useDataSources } from '../../context/DataSourcesContext';
import ConnectorFormTitle from '../components/ConnectorFormTitle';
import { ConnectorProps, SatErrors, SatForm } from '../interfaces';

export const SatModal: FC<ConnectorProps> = ({
    open,
    connector,
    onClose,
    onConfirm,
    allowMultipleConnectors = true,
}) => {
    const { baseAPI, dispatch } = useDataSources(),
        { selectedCompany } = useContext(sessionContext),
        theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        [confirmation, setConfirmation] = useState(false),
        [loading, setLoading] = useState(false),
        [values, setValues] = useState<SatForm>({
            rfc: '',
            password: '',
        }),
        [errors, setErrors] = useState<SatErrors>({
            rfc: '',
            password: '',
        }),
        [alert, setAlert] = useState<AlertProps>({
            message: '',
            type: 'error',
            open: false,
        });

    const clearForm = () => {
        setValues((prev) => ({ ...prev, password: '' }));
        setErrors({ password: '', rfc: '' });
        setAlert((prev) => ({ ...prev, open: false }));
    };

    const closeStatusAlert = () => setAlert({ ...alert, open: false });

    const showStatusAlert = (message: string, type: AlertProps['type']) =>
        setAlert({ message, type, open: true });

    const closeModal = () => {
        clearForm();
        setConfirmation(false);
        onClose();
    };

    const onChange =
        (prop: keyof SatForm) =>
        ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
            setValues((prev) => ({ ...prev, [prop]: value }));
            closeStatusAlert();
        };

    const onChangeInput = (prop: keyof SatForm, errorMsg: string) => {
        if (values[prop]) setErrors((prev) => ({ ...prev, [prop]: '' }));
        else setErrors((prev) => ({ ...prev, [prop]: errorMsg }));
    };

    const onConnect = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        closeStatusAlert();

        // Check if there is an error
        if (!Object.values(errors).some((x) => x)) {
            const msg =
                'No se pudo realizar la conexión, verifique sus credenciales e intente nuevamente';
            if (!baseAPI) {
                showStatusAlert(msg, 'error');
                return;
            }
            setLoading(true);
            showStatusAlert(
                'Conexión en proceso. Por favor, espera un momento. Permanece en esta ventana para continuar.',
                'info',
            );

            const data = toFormData({
                    password: values.password,
                    platform: 'sat',
                }),
                endpoint = `${baseAPI}${connector ? `/${connector.id}/reconnect` : ''}`;

            try {
                const res: any = await fairplayAPI.post(endpoint, data),
                    dataSources = res?.data?.body?.results,
                    // TODO: Delegate logic into DataSourcesReducer
                    satConnector = getPriorityConnector(
                        dataSources,
                        ['reauthorize', 'active', 'pending'],
                        'sat',
                    );

                if (connector && satConnector.length)
                    satConnector[0] = { ...satConnector[0], id: connector.id };

                dispatch({
                    type: connector ? 'UPDATE' : 'ADD',
                    payload: satConnector,
                });
                setConfirmation(true);
                onConfirm?.();
            } catch (errResponse: ErrorObj | any) {
                if (errResponse?.type === 'form')
                    setErrors((prev) => ({ ...prev, ...errResponse.error }));
                else {
                    showStatusAlert(errResponse?.error || msg, 'error');
                }
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (selectedCompany.company?.rfc) {
            setValues((prev) => ({ ...prev, rfc: selectedCompany.company?.rfc || '' }));
        }
    }, [selectedCompany]);

    return (
        <Modal
            disableBackdropClick
            open={open}
            onClose={closeModal}
            maxWidth={720}
            disableEscapeKeyDown
            closeIconButton={!loading}
            title={!confirmation && <ConnectorFormTitle groupKey="sat" connector={connector} />}
            sx={{
                '.MuiDialogContent-root': {
                    pt: 0,
                },
            }}
        >
            {confirmation ? (
                <Confirmation
                    item="SAT"
                    onClose={closeModal}
                    title="Solicitud de conexión enviada exitosamente"
                    description={
                        allowMultipleConnectors
                            ? 'Si la conexión es inválida, le pedimos revise sus credenciales y genere una nueva solicitud.'
                            : undefined
                    }
                    allowMultipleConnectors={allowMultipleConnectors}
                />
            ) : (
                <Grid
                    container
                    spacing={4}
                    component="form"
                    onSubmit={onConnect}
                    data-testid="sat-form"
                >
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" color="text.primary">
                            Para que podamos conectar tu cuenta con nuestra plataforma, es necesario
                            que nos proporciones la siguiente información.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <DefaultInput
                            id="rfc"
                            labelVariant="external"
                            label="RFC"
                            type="text"
                            value={values.rfc.toUpperCase()}
                            onChange={onChange('rfc')}
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <PasswordInput
                            id="CIEC-password"
                            labelVariant="external"
                            label="CIEC"
                            autoComplete="password"
                            value={values.password}
                            onChange={onChange('password')}
                            onBlur={() => onChangeInput('password', 'Ingresa la contraseña')}
                            error={errors.password}
                            forceHiddenText={loading}
                            InputLabelProps={{ required: false }}
                            required
                            titleAccess={{
                                show: 'Mostrar CIEC',
                                hide: 'Ocultar CIEC',
                            }}
                        />
                    </Grid>

                    <Grid item container xs={12} paddingTop="0 !important">
                        <Collapse in={alert.open}>
                            <Grid container item xs={12}>
                                <Alert severity={alert.type} sx={{ mt: 3, mb: -2 }}>
                                    {alert.message}
                                </Alert>
                            </Grid>
                        </Collapse>
                    </Grid>

                    <Grid
                        item
                        container
                        xs={12}
                        justifyContent={{ xs: 'center', sm: 'flex-end' }}
                        marginTop={1}
                    >
                        {loading ? (
                            <InterpunctLoader data-testid="interpunct-loader-sat-connector" />
                        ) : (
                            <>
                                <DefaultBtn
                                    variant="outlined"
                                    size="small"
                                    fullWidth={smDown}
                                    onClick={closeModal}
                                >
                                    Cancelar
                                </DefaultBtn>
                                <DefaultBtn
                                    size="small"
                                    fullWidth={smDown}
                                    type="submit"
                                    sx={{
                                        ml: { xs: 0, sm: '20px' },
                                        mt: { xs: '20px', sm: 0 },
                                    }}
                                >
                                    Conectar
                                </DefaultBtn>
                            </>
                        )}
                    </Grid>
                </Grid>
            )}
        </Modal>
    );
};

export default SatModal;
