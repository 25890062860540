import { FC, useState, useContext, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Collapse, useTheme, useMediaQuery } from '@mui/material';
import { Modal, DefaultBtn, Alert, InterpunctLoader } from '@fairplay2/ui';
import SessionContext from 'context/session/sessionContext';
import { validateClabe, validateSWIFT } from 'utils/validation';
import fairplayAPI from 'utils/api';
import { useAlert } from 'utils/hooks';
import { toFormData } from 'utils/parsing';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj, getMainError } from 'utils/error-handler';
import {
    AddAccountModalProps,
    AddAccountForm,
    AddAccountErrors,
    Step,
    Params,
} from '../interfaces';
import SelectAccountType from './SelectAccountType';
import AddInternational from './AddInternational';
import SuccessCreate from './SuccessCreate';
import AddNational from './AddNational';

const AddAccountModal: FC<AddAccountModalProps> = ({ open, onClose, fetchAccounts }) => {
    const params = useParams<Params>(),
        theme = useTheme(),
        mdDown = useMediaQuery(theme.breakpoints.down('md')),
        { selectedCompany } = useContext(SessionContext),
        [accountType, setAccoutType] = useState('national'),
        [step, setStep] = useState<Step>('select'),
        [currencies, setCurrencies] = useState<string[]>([]),
        [values, setValues] = useState<AddAccountForm>({
            clabe: '',
            swift: '',
            accountNumber: '',
            bank: '',
            bankAddress: '',
            state: '',
            country: { name: '', id: '' },
            accountCurrency: '',
        }),
        [errors, setErrors] = useState<AddAccountErrors>({
            clabe: '',
            swift: '',
            accountCurrency: '',
            accountNumber: '',
            country: '',
        }),
        [loading, setLoading] = useState({
            clabe: false,
            swift: false,
            addAccount: false,
        }),
        { alert, showAlert } = useAlert();

    const clearForm = () => {
        setValues({
            clabe: '',
            swift: '',
            accountNumber: '',
            bank: '',
            bankAddress: '',
            state: '',
            country: { name: '', id: '' },
            accountCurrency: '',
        });
    };

    const clearBankInfo = () => {
        setValues({
            ...values,
            accountNumber: '',
            bank: '',
            bankAddress: '',
            state: '',
            country: { name: '', id: '' },
            currency: '',
        });
    };

    const clearErrors = () => {
        setErrors({
            clabe: '',
            swift: '',
            accountCurrency: '',
            accountNumber: '',
            country: '',
        });
    };

    const fetchClabe = async (clabe: string) => {
        setValues({ ...values, bank: '' });
        setLoading({ ...loading, clabe: true });
        try {
            const res: any = await fairplayAPI.get(`/v1/clabe?clabe=${clabe}`, {
                baseService: 'BANK_INFORMATION',
            });
            const accountNum = clabe.slice(6, 17);
            setValues({ ...values, bank: res.data.bank, accountNumber: accountNum });
            setLoading({ ...loading, clabe: false });
        } catch (errResponse: ErrorObj | any) {
            clearBankInfo();
            setLoading({ ...loading, clabe: false });
            setErrors({
                ...errors,
                clabe:
                    getMainError(errResponse?.error) ||
                    'No se pudo cargar la información del banco',
            });
        }
    };

    const fetchSWIFT = async (code: string) => {
        setLoading({ ...loading, swift: true });
        try {
            const res: any = await fairplayAPI.get(`/v1/swift?code=${code}`, {
                baseService: 'BANK_INFORMATION',
            });
            setValues({
                ...values,
                bank: res.data.bank.name,
                bankAddress: res.data.address,
                state: res.data.city.name,
                country: res.data.country,
            });

            setCurrencies(res.data.currencies);

            setLoading({ ...loading, swift: false });
        } catch (errResponse: ErrorObj | any) {
            clearBankInfo();
            setLoading({ ...loading, swift: false });
            setErrors({
                ...errors,
                swift:
                    getMainError(errResponse?.error) ||
                    'No se pudo cargar la información del banco',
            });
        }
    };

    const onChangeStep = (newStep: Step) => {
        if (newStep === 'create') {
            clearErrors();
        }
        setStep(newStep);
    };

    const onAddAccount = async () => {
        const data = toFormData({
            clabe: values.clabe,
            swift: values.swift,
            accountNumber: values.accountNumber,
            bank: values.bank,
            bankAddress: values.bankAddress,
            state: values.state,
            country: values.swift ? values.country.id : 'MX',
            accountCurrency: values.accountCurrency,
        });
        try {
            setLoading({ ...loading, addAccount: true });
            await fairplayAPI.post(
                `/v1/companies/${selectedCompany.company?.id}/suppliers/${params.id}/accounts`,
                data,
            );
            setLoading({ ...loading, addAccount: false });
            clearForm();
            onChangeStep('success');
            await fetchAccounts();
        } catch (errResponse: ErrorObj | any) {
            setLoading({ ...loading, addAccount: false });
            if (errResponse.type === 'form')
                setErrors((prev) => ({ ...prev, ...errResponse.error }));
            else showAlert(errResponse?.error);
        }
    };

    const onToggle = (type: string) => {
        setAccoutType(type);
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    const onSelect = (event: ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, accountCurrency: event.target.value });
    };

    const onBlur = (target: keyof AddAccountErrors, errorMsg: string) => {
        if (values[target]) {
            switch (target) {
                case 'clabe': {
                    setValues({ ...values, bank: '', accountNumber: '' });
                    const clabeError = validateClabe(values.clabe);
                    setErrors({ ...errors, clabe: clabeError });
                    if (!clabeError) {
                        fetchClabe(values.clabe);
                    } else {
                        clearBankInfo();
                    }
                    break;
                }
                case 'swift': {
                    const swiftError = validateSWIFT(values.swift);
                    setErrors({ ...errors, swift: swiftError });
                    if (!swiftError) {
                        fetchSWIFT(values.swift);
                    } else {
                        clearBankInfo();
                    }
                    break;
                }
                default:
                    setErrors({ ...errors, [target]: '' });
                    break;
            }
        } else {
            setErrors({ ...errors, [target]: errorMsg });
        }
    };

    const shouldDisableSubmit = () => {
        if (!values.accountCurrency || !values.accountNumber || !values.bank) return true;

        if (accountType === 'national' && !values.clabe) {
            return true;
        }
        if (
            accountType === 'international' &&
            (!values.swift || !values.country.name || !values.state)
        ) {
            return true;
        }

        return false;
    };

    return (
        <Modal
            title={
                // eslint-disable-next-line no-nested-ternary
                step === 'select'
                    ? 'Selecciona el tipo de cuenta bancaria que agregarás al proveedor'
                    : // eslint-disable-next-line no-nested-ternary
                    step === 'create'
                    ? 'Agrega una cuenta bancaria'
                    : step === 'confirm'
                    ? 'Agregarás la siguiente cuenta bancaria ¿Son correctos los datos?'
                    : ''
            }
            open={open}
            onClose={onClose}
            maxWidth={
                // eslint-disable-next-line no-nested-ternary
                step === 'select'
                    ? 'md'
                    : accountType === 'international' || step === 'success'
                    ? 680
                    : 524
            }
            sx={{
                '.MuiDialogContent-root': {
                    pt: 0,
                },
            }}
        >
            <Grid container>
                {step === 'select' ? (
                    <>
                        <SelectAccountType accountType={accountType} onToggle={onToggle} />
                        <Grid container justifyContent="center" mt="40px">
                            <DefaultBtn size="small" onClick={() => onChangeStep('create')}>
                                Continuar
                            </DefaultBtn>
                        </Grid>
                    </>
                ) : (
                    <>
                        {(step === 'create' || step === 'confirm') && (
                            <>
                                {accountType === 'international' ? (
                                    <AddInternational
                                        step={step}
                                        values={values}
                                        currencies={currencies}
                                        loading={loading.swift}
                                        errors={errors}
                                        onChange={onChange}
                                        onSelect={onSelect}
                                        onBlur={onBlur}
                                    />
                                ) : (
                                    <AddNational
                                        step={step}
                                        values={values}
                                        loading={loading.clabe}
                                        errors={errors}
                                        onChange={onChange}
                                        onSelect={onSelect}
                                        onBlur={onBlur}
                                    />
                                )}

                                <Grid container justifyContent="center" mt="20px">
                                    <Collapse in={alert.open}>
                                        <Alert severity={alert.type}>{alert.message}</Alert>
                                    </Collapse>
                                </Grid>

                                <Grid container justifyContent="flex-end" mt="10px">
                                    {loading.addAccount ? (
                                        <InterpunctLoader data-testid="interpunct-loader-vendor-account" />
                                    ) : (
                                        <>
                                            <DefaultBtn
                                                size="small"
                                                variant="outlined"
                                                fullWidth={mdDown}
                                                onClick={() =>
                                                    step === 'create'
                                                        ? onClose()
                                                        : onChangeStep('create')
                                                }
                                                sx={{
                                                    mt: '10px',
                                                }}
                                            >
                                                {step === 'create' ? 'Cancelar' : 'Corregir'}
                                            </DefaultBtn>
                                            <DefaultBtn
                                                size="small"
                                                sx={{
                                                    ml: { xs: 0, md: '20px' },
                                                    mt: '10px',
                                                }}
                                                fullWidth={mdDown}
                                                onClick={() =>
                                                    step === 'create'
                                                        ? onChangeStep('confirm')
                                                        : onAddAccount()
                                                }
                                                disabled={shouldDisableSubmit()}
                                            >
                                                {step === 'create' ? 'Agregar' : 'Sí, agregar'}
                                            </DefaultBtn>
                                        </>
                                    )}
                                </Grid>
                            </>
                        )}
                        {step === 'success' && (
                            <Grid container justifyContent="center">
                                <SuccessCreate />
                                <DefaultBtn size="small" onClick={onClose} sx={{ mt: '20px' }}>
                                    Ok, entendido
                                </DefaultBtn>
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
        </Modal>
    );
};

export default AddAccountModal;
