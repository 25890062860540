import { useRef, KeyboardEvent, ChangeEvent, MouseEvent, ClipboardEvent } from 'react';
import { Tooltip, Typography, DefaultInput } from '@fairplay2/ui';
import { FormHelperText, Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import { Info } from '@mui/icons-material';
import { OTPInputProps } from './OTP.types';

const DigitInput = styled(DefaultInput)({
    width: '52px',
    textAlign: 'center',
    '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '10px',
    },
});

const isDigit = (value: string) => /^\d$/.test(value);

export const OTP = ({
    length = 6,
    value,
    setValue,
    error,
    tooltip = 'Si tienes dudas sobre como usar tu aplicación de autenticación consulta la guía de usuario.',
    rootName = 'otp',
    digitInputId = 'otp-digit',
    isValidSymbol = isDigit,
    onBlur,
}: OTPInputProps) => {
    // OTP logic reference https://mui.com/base-ui/react-input/#otp-input
    const inputRefs = useRef<HTMLInputElement[]>(new Array(length).fill(null)),
        inputsArray = Array.from({ length }, (_, index) => index),
        theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm'));

    const onFocusInput = (targetIndex: number) => {
        inputRefs.current[targetIndex].focus();
    };

    const onSelectInput = (targetIndex: number) => {
        inputRefs.current[targetIndex].select();
    };

    const onKeyDown = (event: KeyboardEvent<HTMLInputElement>, currentIndex: number) => {
        switch (event.key) {
            case 'ArrowUp':
            case 'ArrowDown':
            case ' ':
                event.preventDefault();
                break;
            case 'ArrowLeft':
                event.preventDefault();
                if (currentIndex > 0) {
                    onFocusInput(currentIndex - 1);
                    onSelectInput(currentIndex - 1);
                }
                break;
            case 'ArrowRight':
                event.preventDefault();
                if (currentIndex < length - 1) {
                    onFocusInput(currentIndex + 1);
                    onSelectInput(currentIndex + 1);
                }
                break;
            case 'Delete':
                event.preventDefault();
                setValue(
                    (prevOtp) => prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1),
                );

                break;
            case 'Backspace':
                event.preventDefault();
                if (currentIndex > 0) {
                    onFocusInput(currentIndex - 1);
                    onSelectInput(currentIndex - 1);
                }

                setValue(
                    (prevOtp) => prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1),
                );
                break;

            default:
                break;
        }
    };

    const onDigitInputChange = (event: ChangeEvent<HTMLInputElement>, currentIndex: number) => {
        const currentValue = event.target.value;

        if (!isValidSymbol(currentValue)) return;

        let indexToEnter = 0;

        while (indexToEnter <= currentIndex) {
            if (inputRefs.current[indexToEnter].value && indexToEnter < currentIndex)
                indexToEnter += 1;
            else break;
        }

        setValue((prev) => {
            const otpArray = prev.split(''),
                lastValue = currentValue[currentValue.length - 1];
            otpArray[indexToEnter] = lastValue;
            return otpArray.join('');
        });
        if (currentValue !== '') if (currentIndex < length - 1) onFocusInput(currentIndex + 1);
    };

    const onClick = (_: MouseEvent<HTMLInputElement, MouseEvent>, currentIndex: number) =>
        onSelectInput(currentIndex);

    const onPaste = (event: ClipboardEvent<HTMLInputElement>, currentIndex: number) => {
        event.preventDefault();
        const { clipboardData } = event;
        let pastedText = clipboardData.getData('text/plain');

        if (!isValidSymbol(pastedText)) return;

        // Check if there is text data in the clipboard
        if (clipboardData.types.includes('text/plain')) {
            pastedText = pastedText.substring(0, length).trim();
            let indexToEnter = 0;

            while (indexToEnter <= currentIndex) {
                if (inputRefs.current[indexToEnter].value && indexToEnter < currentIndex)
                    indexToEnter += 1;
                else break;
            }

            const otpArray = value.split('');

            for (let i = indexToEnter; i < length; i += 1) {
                const lastValue = pastedText[i - indexToEnter] ?? ' ';
                otpArray[i] = lastValue;
            }

            setValue(otpArray.join(''));
        }
    };

    return (
        <Grid item width="fit-content">
            <Grid item container xs={12} gap={{ xs: 1, md: 2 }} justifyContent="left">
                {inputsArray.map((inputNumber) => (
                    <Grid item key={`${digitInputId}-${inputNumber}`}>
                        <DigitInput
                            id={`${digitInputId}-${inputNumber}`}
                            name={`${rootName}-${inputNumber}`}
                            label={`Dígito ${inputNumber + 1} del OTP`}
                            labelVariant="hidden"
                            inputRef={(ele: any) => {
                                inputRefs.current[inputNumber] = ele!;
                            }}
                            onKeyDown={(event: any) => onKeyDown(event, inputNumber)}
                            onChange={(event: any) => onDigitInputChange(event, inputNumber)}
                            onClick={(event: any) => onClick(event, inputNumber)}
                            onPaste={(event: any) => onPaste(event, inputNumber)}
                            value={value[inputNumber] ?? ''}
                            InputProps={{
                                'aria-describedby': `${digitInputId}-error`,
                                autoComplete: 'off',
                                endAdornment: false,
                                error: !!error,
                                onBlur,
                            }}
                        />
                    </Grid>
                ))}
                {!!tooltip && !smDown && (
                    <Grid item>
                        <Tooltip
                            aria-label="Información sobre el OTP"
                            title={
                                <Grid container maxWidth="170px">
                                    {tooltip}
                                </Grid>
                            }
                            placement="top-end"
                            color="paper"
                            size="big"
                        >
                            <Info color="primary" />
                        </Tooltip>
                    </Grid>
                )}
            </Grid>
            {!!error && (
                <Grid item xs={12}>
                    <FormHelperText id={`${digitInputId}-error`}>
                        <Typography
                            component="span"
                            fontSize="10px"
                            fontWeight={400}
                            color="error.main"
                        >
                            {error}
                        </Typography>
                    </FormHelperText>
                </Grid>
            )}
        </Grid>
    );
};

export default OTP;
