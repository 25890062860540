import { FC, useEffect } from 'react';
import { Modal, DefaultBtn, Alert, Skeleton, Typography, Checkbox } from '@fairplay2/ui';
import {
    Collapse,
    FormControlLabel,
    FormGroup,
    Grid,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useAlert } from 'utils/hooks/useAlert';
import { SalesSourcesModalProps } from '../../interfaces';
import OtherSalesSourcesList from './OtherSalesSourcesList';

const SalesSourcesModal: FC<SalesSourcesModalProps> = ({
    currentSelection,
    isModalOpen,
    onModalClose,
    clearSalesSourcesSelection,
    onConfirm: onConfirmProp,
    transitionDuration,
    register,
    sourcesList,
    error,
    control,
    setValue,
    values,
}) => {
    const { alert, showAlert } = useAlert(),
        theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm'));

    const onCancel = () => {
        clearSalesSourcesSelection();
        onModalClose();
    };

    const onConfirm = () => {
        onModalClose();
        onConfirmProp?.();
        if (currentSelection?.length === 0) clearSalesSourcesSelection();
    };

    useEffect(() => {
        if (error)
            showAlert(
                error || 'Ocurrió un error con el servidor, intente más tarde',
                'error',
                false,
            );
    }, [error, showAlert]);

    useEffect(() => {
        if (currentSelection?.includes('other') && values.otherSalesSources.length === 0)
            setValue('otherSalesSources', [{ name: '' }]);

        if (!currentSelection?.includes('other') && values.otherSalesSources.length > 0)
            setValue('otherSalesSources', []);
    }, [currentSelection, setValue, values.otherSalesSources]);

    return (
        <Modal
            transitionDuration={transitionDuration}
            open={isModalOpen}
            onClose={onCancel}
            scroll="paper"
            disableBackdropClick
            actions={
                <DefaultBtn
                    size="small"
                    onClick={onConfirm}
                    fullWidth={smDown}
                    disabled={!currentSelection}
                >
                    Confirmar
                </DefaultBtn>
            }
            title="¿En dónde vendes? Elige una o más opciones"
            sx={{
                '.MuiDialog-paper': {
                    padding: {
                        xs: '18px',
                        sm: '48px 18px 20px',
                    },
                    width: '535px',
                },
                '.MuiDialogContent-root': {
                    padding: '3px 20px 0',
                    mb: 2,
                },
                '.MuiDialogTitle-root': {
                    p: '16px 20px 0',
                },
            }}
        >
            {/* eslint-disable-next-line no-nested-ternary */}
            {!sourcesList ? (
                Array(6)
                    .fill(0)
                    .map((_, i) => (
                        <Skeleton
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                            height={29}
                            variant="text"
                            sx={{ transform: 'scale(1, 0.90)', margin: '5px 0' }}
                        />
                    ))
            ) : sourcesList.length ? (
                <Grid container gap={1} mt={2}>
                    <FormGroup sx={{ gap: '8px', '.MuiFormControlLabel-root': { mx: 0 } }}>
                        {sourcesList?.map((source) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={source.id}
                                        {...register('salesSources', {
                                            required: 'Ingresa en donde vendes',
                                        })}
                                        value={source.id}
                                        checked={currentSelection?.includes(source.id)}
                                    />
                                }
                                label={
                                    <Typography
                                        variant="subtitle2"
                                        color="text.primary"
                                        mt="2px"
                                        ml="8px"
                                        fontWeight={500}
                                    >
                                        {source.name}
                                    </Typography>
                                }
                                key={source.id}
                            />
                        ))}
                    </FormGroup>
                    <OtherSalesSourcesList register={register} control={control} />
                </Grid>
            ) : (
                // 'info' severity should only appear in development environment, it
                //  means that backend returned a valid response, but the array is empty
                <Collapse in={alert.open}>
                    <Alert severity={alert.type}>{alert.message}</Alert>
                </Collapse>
            )}
        </Modal>
    );
};

export default SalesSourcesModal;
