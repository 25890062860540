import { Grid, TableCell, TableRow, GridProps, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Skeleton } from '@fairplay2/ui';

export const Container = styled(Grid)(({ theme }) => ({
    boxShadow: '0px 3px 6px #00000029',
    padding: '25px 25px 45px 25px',
    border: `1px solid ${theme.palette.primary.A400}`,
    borderRadius: '15px',
}));

export const InvoiceDetailContainer = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.primary.A400,
    padding: '30px',
    borderRadius: '15px',
    margin: '20px 0',
}));

export const ExpensesDetailContainer = styled(Grid)(({ theme }) => ({
    padding: '25px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '10px',
}));

export const StyledCell = styled(TableCell)({
    border: 'none',
});

export const StyledSmallCell = styled(StyledCell)({
    maxWidth: 35,
});

export const StyledMediumCell = styled(StyledCell)({
    maxWidth: 90,
});

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.dark,
    },
    '&:last-child td, &:last-child th': {
        borderBottom: '1px solid #E9E8F0',
    },
}));

export const DownloadLabelContainer = styled(Grid)<
    GridProps<'button', { component: 'button'; isDownload: boolean }>
>(({ theme, isDownload }) => ({
    width: '95px',
    height: '95px',
    margin: '0 15px 30px 0',
    padding: '5px 20px',
    borderRadius: '0px 0 10px 10px',
    border: '0px',
    backgroundColor: isDownload ? theme.palette.primary.dark : theme.palette.primary.A400,
    cursor: 'pointer',
}));

export const IconContainer = styled('div')(({ theme }) => ({
    width: 35,
    height: 35,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.primary.contrastText,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
        color: theme.palette.secondary.main,
        width: 20,
        height: 'auto',
    },
}));

export const FilterItemContainer = styled(Grid)({
    '&:hover': {
        backgroundColor: '#F6F6F9',
    },
    '.MuiInput-input': {
        cursor: 'pointer',
    },
});

export const StyledSwitch = styled(Switch)({
    '& .MuiSwitch-thumb': {
        color: '#ffffff',
        boxShadow: ' 0px 1px 3px #00000061',
    },
    '& .Mui-checked': {
        '& + .MuiSwitch-track': {
            opacity: 0.5,
            backgroundColor: '#4A3FD1',
        },
    },
});

export const StyledSkeleton = styled(Skeleton)({
    width: '100px',
    height: '90px',
    marginRight: '15px',
    '&.MuiSkeleton-root': {
        borderRadius: '0px 0px 20px 20px',
    },
});
