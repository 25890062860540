import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import { ManParagraphSvg, ManParagraphLeavesSvg } from 'assets/SVGs';
import { addGoogleTag } from 'utils/google-tag-manager';
import { Modal, DefaultBtn, Typography } from '@fairplay2/ui';
import { CancelModalProps } from '../../interfaces.v1';

const CancelModal: FC<CancelModalProps> = ({ isModalOpen, disbursementType, onModalClose }) => {
    const history = useHistory(),
        [closeConfirmation, setCloseConfirmation] = useState(false);

    const onFirstCancelCheck = () => {
        setCloseConfirmation(true);
        addGoogleTag('disbursement-invoice-cancel');
    };

    const onModalCloseRedirect = () => {
        if (closeConfirmation) history.push('/app/disbursements/transactions');
        else onModalClose('cancel');
    };

    return (
        <Modal
            disableBackdropClick
            open={isModalOpen}
            maxWidth={524}
            onClose={onModalCloseRedirect}
        >
            {closeConfirmation ? (
                <Grid container>
                    <Grid container item xs={12} justifyContent="center">
                        <ManParagraphSvg />
                    </Grid>
                    <Grid item xs={12} marginTop="20px">
                        <Typography variant="subtitle1" color="primary.main">
                            Dispersión cancelada
                        </Typography>
                    </Grid>
                    <Grid item xs={12} marginTop="20px">
                        <Typography color="text.primary" fontWeight={500}>
                            La solicitud de dispersión para{' '}
                            <Typography component="span" color="primary.dark" fontWeight={700}>
                                <Typography component="span" color="primary.dark" fontWeight={700}>
                                    {disbursementType === 'card'
                                        ? 'Tarjeta Fairplay'
                                        : 'Factura única'}
                                </Typography>{' '}
                            </Typography>
                            se ha cancelado. Puedes iniciar una nueva solicitud en el menú
                            Dispersiones.
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} justifyContent="center" margin="20px 0">
                        <DefaultBtn
                            size="small"
                            onClick={onModalCloseRedirect}
                            sx={{ mx: '10px', mt: '10px' }}
                        >
                            Entendido
                        </DefaultBtn>
                    </Grid>
                </Grid>
            ) : (
                <Grid container>
                    <Grid container item xs={12} justifyContent="center">
                        <ManParagraphLeavesSvg />
                    </Grid>
                    <Grid item xs={12} marginTop="20px">
                        <Typography variant="subtitle1" color="primary.main">
                            ¿Estás seguro de cancelar la dispersión?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} marginTop="15px">
                        <Typography color="text.primary" fontWeight={700}>
                            ¡Se perderá la información!
                        </Typography>
                        <Typography color="text.primary" fontWeight={500} mt="20px">
                            Si cancelas la dispersión, los datos que ya hayas ingresado se perderán
                            y deberás comenzar una nueva solicitud.
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} justifyContent="center" marginTop="20px">
                        <DefaultBtn
                            size="small"
                            variant="outlined"
                            onClick={onModalCloseRedirect}
                            sx={{ mx: '10px', mt: '10px' }}
                        >
                            Ok, no
                        </DefaultBtn>
                        <DefaultBtn
                            size="small"
                            onClick={onFirstCancelCheck}
                            sx={{ mx: '10px', mt: '10px' }}
                        >
                            Sí, cancelar
                        </DefaultBtn>
                    </Grid>
                </Grid>
            )}
        </Modal>
    );
};

export default CancelModal;
