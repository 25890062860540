import { FC, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import { Collapse, Divider, Grid, MenuItem } from '@mui/material';
import {
    DefaultInput,
    Select,
    Typography,
    Autocomplete,
    FilePicker,
    Skeleton,
} from '@fairplay2/ui';
import { DatePicker } from '@fairplay2/ui-x-dates';
import { CurrencyInput } from '@fairplay2/ui-x-currencies';
import { FormV1 } from '../../interfaces.v1';
import { onAmountChange } from '../../utils.v1';

interface CapitalProps {
    values: any;
    errors: any;
    suppliers: any;
    supplier: any;
    fileName: string;
    disabledDays: Date[];
    date: Date | undefined;
    inputValue: any;
    tooltips: any;
    setInputValue: (prop: any) => void;
    onSelectChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onChangeInput: (prop: any, error: any) => void;
    onChange: (prop: keyof FormV1) => (event: ChangeEvent<HTMLInputElement>) => void;
    onFileChange: (file: File | null) => void;
    onDateSelect: (date: Date | undefined) => void;
    onAutocompleteChange: (newValue: any) => void;
}

const WorkingCapital: FC<CapitalProps> = ({
    onSelectChange,
    onChangeInput,
    onChange,
    onAutocompleteChange,
    onFileChange,
    onDateSelect,
    values,
    errors,
    suppliers,
    supplier,
    fileName,
    disabledDays,
    date,
    tooltips,
    inputValue,
    setInputValue,
}) => {
    const businessName = inputValue?.split(' - ')[1];

    return (
        <>
            <Divider sx={{ color: 'background.dark' }} />
            <Grid container spacing={4} marginTop="10px" marginBottom="20px">
                <Grid item xs={12} md={6}>
                    {suppliers ? (
                        <>
                            <Autocomplete
                                labelVariant="external"
                                label="¿Para quién es la transferencia?"
                                id="disbursement-vendor"
                                name="supplierName"
                                options={suppliers}
                                getOptionLabel={(option: any) =>
                                    `${option.alias} - ${option.registeredName}`
                                }
                                inputValue={inputValue}
                                onChange={(_, newValue) => onAutocompleteChange(newValue)}
                                onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
                                placeholder="Selecciona el proveedor"
                                onBlur={() =>
                                    onChangeInput(
                                        'supplierName',
                                        'Selecciona el nombre comercial o la razón social del proveedor',
                                    )
                                }
                                error={errors.supplierId}
                                TextFieldProps={{
                                    helperText:
                                        businessName?.length > 40
                                            ? '*Una razón social de más de 40 caracteres puede demorar la dispersión más de 24hrs'
                                            : '',
                                }}
                            />
                            <Typography variant="caption" color="text.primary">
                                *Si el proveedor no aparece en la lista{' '}
                                <Typography
                                    component={Link}
                                    color="primary.main"
                                    to="/app/financing/vendors/add-vendor"
                                >
                                    agrégalo aquí
                                </Typography>
                            </Typography>
                        </>
                    ) : (
                        <Skeleton height={53} />
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    <CurrencyInput
                        labelVariant="external"
                        label="¿Cuál es el monto de la transferencia?"
                        name="amount"
                        placeholder="Ej. 50,000.00"
                        onValueChange={onAmountChange(onChange)}
                        customInput={DefaultInput}
                        error={errors.amount ? errors.amount : ''}
                        onBlur={() => onChangeInput('amount', 'Ingresa el monto a pagar.')}
                        thousandSeparator
                        decimalScale={2}
                        allowNegative={false}
                        startAdornment={null}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DefaultInput
                        labelVariant="external"
                        label="Concepto de la transferencia"
                        id="disbursement-concept"
                        value={values.concept}
                        placeholder="¿Para qué necesitas esta transferencia?"
                        onChange={onChange('concept')}
                        onBlur={() =>
                            onChangeInput('concept', 'Ingresa el concepto de la transferencia')
                        }
                        error={errors.concept ? errors.concept : ''}
                    />
                    <Collapse in={tooltips.concept}>
                        <Typography variant="caption" color="text.primary">
                            *Puede incluir números y letras pero no debe contener caracteres
                            especiales.
                        </Typography>
                    </Collapse>
                </Grid>
                <Grid item xs={12} md={6}>
                    <DefaultInput
                        labelVariant="external"
                        label="¿Cuál es la referencia numérica para la transferencia?"
                        id="disbursement-reference"
                        value={values.reference}
                        placeholder="Ej. 4567803456"
                        onChange={onChange('reference')}
                        onBlur={() =>
                            onChangeInput('reference', 'Ingresa la referencia de la factura')
                        }
                        error={errors.reference ? errors.reference : ''}
                    />
                    <Collapse in={tooltips.reference}>
                        <Typography variant="caption" color="text.primary">
                            *Puede incluir únicamente números
                        </Typography>
                    </Collapse>
                </Grid>
                <Grid item xs={12} md={6}>
                    <DatePicker
                        id="disbursement-date"
                        label="¿Cuándo te gustaría que se realice la transferencia?"
                        mode="single"
                        placeholder="Selecciona la fecha"
                        labelVariant="external"
                        value={values.date}
                        onChange={onDateSelect}
                        disabledDays={disabledDays}
                        error={errors.date}
                        displayIcon
                        PickerProps={{
                            fromDate: date,
                            captionLayout: 'buttons',
                        }}
                    />
                    <Typography variant="caption" color="text.primary">
                        *Recuerda solicitar pagos con mínimo un día de anticipación.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography component="label" variant="label" htmlFor="disbursement-file">
                        Adjunta el soporte de la transferencia (factura, orden de compra, etc)
                    </Typography>
                    <FilePicker
                        id="disbursement-file"
                        onFileChange={onFileChange}
                        fileName={fileName}
                        accept={
                            'image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                        }
                        paperVariant="dashed"
                        variant="compact"
                        aria-describedby="disbursement-file-error"
                    />
                    {!!errors.invoiceFile && (
                        <Typography
                            variant="caption"
                            color="error.main"
                            id="disbursement-file-error"
                        >
                            {`${errors.invoiceFile}`}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    <Select
                        labelVariant="external"
                        label="¿Cuál es la cuenta bancaria a la que se realizará la transferencia?"
                        id="disbursement-account"
                        name="supplierAccount"
                        value={values.supplierAccount}
                        onChange={onSelectChange}
                        onBlur={() =>
                            onChangeInput('supplierAccount', 'Selecciona la cuenta bancaria')
                        }
                        error={errors.supplierAccount ? errors.supplierAccount : ''}
                    >
                        <MenuItem value="" disabled>
                            Selecciona la cuenta bancaria
                        </MenuItem>
                        {supplier?.bankAccounts?.map((account: any, i: number) => (
                            // TODO: Validate account interface to use an id
                            // eslint-disable-next-line react/no-array-index-key
                            <MenuItem value={account} key={i}>
                                {account.swift
                                    ? `${account.swift} - ${account.accountNumber} - ${account.bank}`
                                    : `${account.clabe} - ${account.bank}`}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>
        </>
    );
};

export default WorkingCapital;
