import { Modal } from '@fairplay2/ui';
import { Grid } from '@mui/material';
import ModalActions from './ModalActions';
import ModalContent from './ModalContent';
import ModalIcon from './ModalIcon';
import ModalTitle from './ModalTitle';
import { ModalContext } from './useModalContext';
import { ExtendedModalContainer } from './interfaces';

// For some reason, props ar enot being recognized by eslint
/* eslint-disable react/prop-types */
const ModalContainer: ExtendedModalContainer = ({
    children,
    open,
    onClose,
    loading,
    onPrimaryAction,
    onSecondaryAction,
    maxWidth = 545,
    /* eslint-enable react/prop-types */
}) => (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ModalContext.Provider value={{ loading, onPrimaryAction, onSecondaryAction }}>
        <Modal
            disableBackdropClick
            disableEscapeKeyDown
            open={open}
            closeIconButton={!loading}
            onClose={onClose}
            maxWidth={maxWidth}
            sx={{
                '.MuiDialog-paper': {
                    p: 5,
                },
                '.MuiDialogContent-root': {
                    padding: '20px',
                    paddingBottom: '0',
                },
            }}
        >
            <Grid container>{children}</Grid>
        </Modal>
    </ModalContext.Provider>
);

ModalContainer.Actions = ModalActions;
ModalContainer.Content = ModalContent;
ModalContainer.Icon = ModalIcon;
ModalContainer.Title = ModalTitle;

export default ModalContainer;
