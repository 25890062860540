import { FC, useState } from 'react';
import { Grid, useTheme, useMediaQuery } from '@mui/material';
import { Typography, Paper } from '@fairplay2/ui';
import { ManGearAvatar } from 'assets/SVGs';
import { CreateAccountProps } from '../../interfaces';
import { HideGridOnSm } from '../../styles';
import EmailValidation from './EmailValidation';
import EmailConfirmed from './EmailConfirmed';
import SignUpForm from './SignUpForm';

const CreateAccount: FC<CreateAccountProps> = ({ profileEmail, emailTokenIsValid, token }) => {
    const theme = useTheme(),
        mdDown = useMediaQuery(theme.breakpoints.down('md')),
        [showValidationSent, setShowValidationSent] = useState(
            !!profileEmail && !profileEmail.isConfirmed,
        ),
        [email, setEmail] = useState(profileEmail?.email || '');

    return (
        <Grid container justifyContent={token ? 'center' : undefined}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {!!token && emailTokenIsValid ? (
                <EmailConfirmed />
            ) : !showValidationSent ? (
                <>
                    <Grid item xs={12} marginBottom={2}>
                        <Typography variant="h3" color="primary.dark">
                            ¡Cuéntanos{' '}
                            <Typography component="span" color="secondary.main">
                                sobre ti!
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Typography variant="subtitle2" color="primary.dark" fontWeight={500}>
                            Tu cuenta Fairplay necesita un Titular. Ingresa aquí los datos de quién
                            será el administrador general de la cuenta.{' '}
                            <Typography component="span" color="primary.main" fontWeight={700}>
                                Crea tu perfil de administrador.
                            </Typography>
                        </Typography>
                    </Grid>
                    <HideGridOnSm item xs={12} md={9} lg={10}>
                        <Paper
                            sx={{
                                borderColor: 'auxiliary.light',
                                boxShadow: 'none',
                                backgroundColor: 'background.dark',
                                mt: '40px',
                                mb: 2,
                            }}
                        >
                            <Grid
                                container
                                alignItems="center"
                                px={5}
                                py={{ xs: 2, lg: 0 }}
                                minHeight="145px"
                                width="80%"
                            >
                                <Grid item>
                                    <Typography
                                        fontSize="24px"
                                        fontWeight={700}
                                        color="primary.dark"
                                    >
                                        Todas las métricas de tu negocio <br />
                                        <Typography component="span" color="primary.main">
                                            en un solo lugar.
                                        </Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                            {!mdDown && (
                                <Grid
                                    item
                                    sx={{
                                        right: {
                                            xs: -105,
                                            lg: -90,
                                        },
                                        zIndex: 0,
                                        position: 'absolute',
                                        top: -13,
                                        width: 188,
                                        height: 'auto',
                                    }}
                                >
                                    <ManGearAvatar />
                                </Grid>
                            )}
                        </Paper>
                    </HideGridOnSm>
                    <SignUpForm setShowValidationSent={setShowValidationSent} setEmail={setEmail} />
                </>
            ) : (
                <EmailValidation email={email} />
            )}
        </Grid>
    );
};

export default CreateAccount;
