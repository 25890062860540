import { FC, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, useTheme, useMediaQuery, Divider, Collapse } from '@mui/material';
import fairplayAPI from 'utils/api';
import {
    Typography,
    DefaultInput,
    DefaultBtn,
    InterpunctLoader,
    Alert,
    Paper,
    Checkbox,
} from '@fairplay2/ui';
import { addGoogleTag } from 'utils/google-tag-manager';
import { ManGearAvatar } from 'assets/SVGs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj, setFormErrors } from 'utils/error-handler';
import IndustriesModal from 'components/dashboard/vendors/add-vendor/components/IndustriesModal';
import { IndustryAsProp } from 'components/dashboard/vendors/add-vendor/interfaces';
import { useToggle, useAlert } from 'utils/hooks';
import { toFormData } from 'utils/parsing';
import { HideGridOnSm } from '../../styles';
import { CompanyDataForm, OnboardingScreenProps, DataSourceType } from '../../interfaces';
import SalesSourcesModal from './SalesSourcesModal';
import LegalRepresentativeConfirmationModal from './LegalRepresentativeConfirmationModal';

const CompanyData: FC<OnboardingScreenProps> = ({ onNext }) => {
    const theme = useTheme(),
        mdDown = useMediaQuery(theme.breakpoints.down('md')),
        [isIndustriesModalOpen, toggleIndustriesModal] = useToggle(),
        [isLegalRepresentativeModalOpen, toggleLegalRepresentativeModal] = useToggle(),
        [isSalesSourcesModalOpen, toggleSalesSourcesModal] = useToggle(),
        [salesSources, setSalesSources] = useState<DataSourceType[] | undefined>(undefined),
        [salesSourcesError, setSalesSourcesError] = useState(''),
        [isLegalRepresentative, toggleLegalRepresentativeCheckbox] = useToggle(),
        {
            register,
            formState: { errors },
            handleSubmit,
            getValues,
            clearErrors,
            setError,
            setValue,
            watch,
            control,
        } = useForm<CompanyDataForm>({
            mode: 'onBlur',
            values: {
                businessName: '',
                rfc: '',
                urlCompany: '',
                investmentInterested: false,
                industry: null,
                salesSources: [],
                otherSalesSources: [],
                updateUserStep: true,
            },
        }),
        values = getValues(),
        { alert, hideAlert, showAlert } = useAlert(),
        [loading, setLoading] = useState(false);

    watch(['salesSources', 'investmentInterested']);

    const onConfirmModal = () => {
        toggleLegalRepresentativeModal();
        toggleLegalRepresentativeCheckbox();
    };

    const clearAlert = (name: keyof CompanyDataForm) => {
        hideAlert();
        clearErrors(name);
    };

    const onIndustrySelect = (_: object, industry: IndustryAsProp) => {
        clearErrors('industry');
        setValue('industry', industry);

        if (!industry)
            setError('industry', {
                type: 'custom',
                message: 'Ingresa la Industria',
            });
    };

    const clearSalesSourcesSelection = () => {
        clearErrors('salesSources');
        setValue('salesSources', []);

        setError('salesSources', {
            type: 'custom',
            message: 'Ingresa en donde vendes',
        });
    };

    const onClickNext = () => {
        if (!values.industry) {
            setError('industry', {
                type: 'custom',
                message: 'Ingresa la Industria',
            });
        } else {
            clearErrors('industry');
        }
    };

    const onSubmit = async () => {
        setLoading(true);

        const newSalesSources = [
                ...values.salesSources,
                ...values.otherSalesSources
                    .map(({ name }) => name.toLowerCase())
                    .filter((name) => name.length > 0),
            ],
            data = toFormData({
                business_name: values.businessName,
                rfc: values.rfc,
                url_company: values.urlCompany,
                sales_sources: newSalesSources,
                industry: values.industry?.id,
                investment_interested: values.investmentInterested,
                is_legal_representative: isLegalRepresentative,
                update_user_step: values.updateUserStep,
            });

        // get Meru data
        const url = new URL(window.location.href);
        const clientId = url.searchParams.get('client_id');
        const utmSource = url.searchParams.get('utm_source');
        const utmParams = JSON.parse(localStorage?.getItem('utm_params') || '{}');

        if (clientId && utmSource) {
            data.append('clientId', clientId);
            data.append('utmSource', utmSource);
        }

        if (Object.values(utmParams).length) {
            data.append('utm_campaign', utmParams?.utmCampaign);
            data.append('utm_medium', utmParams?.utmMedium);
            data.append('utm_source', utmParams?.utmSource);
        }

        try {
            await fairplayAPI.post('/v1/companies/register', data);
            localStorage.clear();

            onNext();
            addGoogleTag('onboarding-company-data');
        } catch (errResponse: ErrorObj | any) {
            if (errResponse?.type === 'form') {
                setFormErrors<CompanyDataForm>(errResponse?.error, setError);
            } else showAlert(errResponse?.error || 'Se ha producido un error. Intente más tarde');
        } finally {
            setLoading(false);
        }
    };

    const fetchSalesSourcesList = async () => {
        try {
            setSalesSourcesError('');
            const res: any = await fairplayAPI.get('/v2/connectors-orders');
            setSalesSources(
                res.data.body.results.filter(
                    ({ id }: any) =>
                        id !== 's3connector' && id !== 's3-manual-upload' && id !== 'liverpool',
                ),
            );
            if (!res.data.body.results.length)
                showAlert('No se encontraron fuentes de datos.', 'info', false);
        } catch (errResponse: ErrorObj | any) {
            setSalesSources([]);
            setSalesSourcesError(
                errResponse?.error || 'Ocurrió un error con el servidor, intente más tarde',
            );
        }
    };

    useEffect(() => {
        fetchSalesSourcesList();
        toggleLegalRepresentativeModal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <LegalRepresentativeConfirmationModal
                open={isLegalRepresentativeModalOpen}
                onClose={toggleLegalRepresentativeModal}
                onConfirm={onConfirmModal}
            />

            <SalesSourcesModal
                currentSelection={values.salesSources}
                isModalOpen={isSalesSourcesModalOpen}
                clearSalesSourcesSelection={clearSalesSourcesSelection}
                onModalClose={toggleSalesSourcesModal}
                register={register}
                setValue={setValue}
                control={control}
                sourcesList={salesSources}
                error={salesSourcesError}
                values={values}
            />

            <IndustriesModal
                currentSelection={values.industry}
                isModalOpen={isIndustriesModalOpen}
                onIndustrySelect={onIndustrySelect}
                onModalClose={toggleIndustriesModal}
                source="company"
            />

            <Grid component="form" onSubmit={handleSubmit(onSubmit)} container>
                <Grid item xs={12} marginBottom={2}>
                    <Typography variant="h3" color="primary.dark">
                        ¡Cuéntanos sobre{' '}
                        <Typography component="span" color="secondary.main">
                            tu empresa!
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="primary.dark" fontWeight={500}>
                        Queremos conocer tu marca y tu organización.{' '}
                        <Typography component="span" color="primary.main" fontWeight={700}>
                            Crea el perfil para tu empresa.
                        </Typography>
                    </Typography>
                </Grid>
                <HideGridOnSm item xs={12} md={11} lg={10}>
                    <Paper
                        sx={{
                            borderColor: 'auxiliary.light',
                            boxShadow: 'none',
                            backgroundColor: 'background.dark',
                            mt: '40px',
                            mb: 2,
                        }}
                    >
                        <Grid
                            container
                            alignItems="center"
                            px={5}
                            py={{ xs: 4 }}
                            minHeight="145px"
                            width="80%"
                        >
                            <Grid item>
                                <Typography color="primary.dark" fontSize="24px" fontWeight={700}>
                                    ¿Quieres hacer crecer tu negocio?
                                    <br />
                                    <Typography component="span" color="primary.main">
                                        Crea tu cuenta y accede a una propuesta de financiamiento.
                                    </Typography>
                                </Typography>
                            </Grid>
                        </Grid>
                        {!mdDown && (
                            <Grid
                                item
                                sx={{
                                    right: {
                                        xs: -105,
                                        lg: -90,
                                    },
                                    zIndex: 0,
                                    position: 'absolute',
                                    top: -13,
                                    width: 188,
                                    height: 'auto',
                                }}
                            >
                                <ManGearAvatar />
                            </Grid>
                        )}
                    </Paper>
                </HideGridOnSm>
                <Grid item container xs={12} marginTop={5} marginBottom={3} rowSpacing={3}>
                    <Grid item container xs={12} md={5} sx={{ pt: '0 !important' }}>
                        <DefaultInput
                            label="Marca o nombre comercial"
                            id="businessName"
                            type="text"
                            error={errors.businessName?.message}
                            placeholder="Marca o nombre comercial"
                            {...register('businessName', {
                                required: 'Ingresa tu marca o nombre comercial',
                                onChange: () => clearAlert('businessName'),
                            })}
                        />
                    </Grid>
                    {!mdDown && (
                        <Divider
                            orientation="vertical"
                            flexItem
                            variant="fullWidth"
                            sx={{ mx: '4%', color: 'auxiliary.light' }}
                        />
                    )}
                    <Grid
                        item
                        container
                        xs={12}
                        md={5}
                        sx={{
                            pt: {
                                md: '0 !important',
                            },
                        }}
                    >
                        <DefaultInput
                            label="RFC"
                            id="rfc"
                            type="text"
                            error={errors.rfc?.message}
                            inputProps={{
                                maxLength: 13,
                            }}
                            placeholder="RFC"
                            value={values.rfc.toUpperCase()}
                            {...register('rfc', {
                                onChange: () => clearAlert('rfc'),
                                required: 'Ingresa tu RFC',
                                validate: {
                                    isValidRFC: (rfc) =>
                                        rfc.length === 12 || rfc.length === 13 || 'RFC no válido',
                                },
                            })}
                        />
                    </Grid>
                    <Grid item container xs={12} md={5}>
                        <DefaultInput
                            label="Industria"
                            name="industry"
                            placeholder="Industria"
                            value={isIndustriesModalOpen ? '' : values.industry?.description || ''}
                            onClick={toggleIndustriesModal}
                            error={isIndustriesModalOpen ? '' : errors.industry?.message}
                        />
                    </Grid>
                    {!mdDown && (
                        <Divider
                            orientation="vertical"
                            flexItem
                            variant="fullWidth"
                            sx={{ mx: '4%', color: 'auxiliary.light' }}
                        />
                    )}
                    <Grid item container xs={12} md={5}>
                        <DefaultInput
                            label="¿En dónde vendes?"
                            name="salesSources"
                            placeholder="¿En dónde vendes?"
                            value={
                                isSalesSourcesModalOpen
                                    ? ''
                                    : salesSources
                                          ?.filter((source) =>
                                              values.salesSources.includes(source.id),
                                          )
                                          .map((source) => source.name)
                                          .join(', ') || ''
                            }
                            onClick={toggleSalesSourcesModal}
                            error={isSalesSourcesModalOpen ? '' : errors.salesSources?.message}
                        />
                    </Grid>
                    <Grid item container xs={12} md={5}>
                        <DefaultInput
                            label="Sitio web"
                            id="urlCompany"
                            type="text"
                            error={errors.urlCompany?.message}
                            placeholder="Sitio web"
                            {...register('urlCompany', {
                                onChange: () => clearAlert('urlCompany'),
                                required: 'Ingresa tu sitio web',
                            })}
                        />
                    </Grid>
                    {!mdDown && (
                        <Divider
                            orientation="vertical"
                            flexItem
                            variant="fullWidth"
                            sx={{ mx: '4%', color: 'auxiliary.light' }}
                        />
                    )}
                    <Grid item container xs={12} md={5} direction="column" gap={1}>
                        <Grid item>
                            <Typography variant="body1" color="primary.main" fontWeight={700}>
                                ¿Te interesa obtener una financiamiento?
                            </Typography>
                        </Grid>
                        <Grid item container>
                            <Grid item>
                                <Checkbox
                                    id="investmentInterested"
                                    {...register('investmentInterested')}
                                />
                            </Grid>
                            <Grid item marginLeft={1}>
                                <Typography
                                    component="label"
                                    htmlFor="investmentInterested"
                                    display="block"
                                    variant="body1"
                                    color="text.primary"
                                    mt="2px"
                                    fontWeight={700}
                                >
                                    Sí, me interesa
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={12} md={11} justifyContent="flex-end" marginBottom={2}>
                    <Collapse in={alert.open}>
                        <Grid item xs={12}>
                            <Alert variant="filled" severity={alert.type}>
                                {alert.message}
                            </Alert>
                        </Grid>
                    </Collapse>
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    md={11}
                    marginBottom={{ xs: '5px', md: 0 }}
                    justifyContent="flex-end"
                >
                    {loading ? (
                        <InterpunctLoader />
                    ) : (
                        <DefaultBtn
                            type="submit"
                            size="medium"
                            onClick={onClickNext}
                            sx={{
                                width: { xs: '100%', sm: '294px' },
                            }}
                        >
                            Crear perfil de empresa
                        </DefaultBtn>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default CompanyData;
