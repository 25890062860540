import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Grid, Collapse, useTheme, useMediaQuery, Link } from '@mui/material';
import {
    Modal,
    Typography,
    DefaultBtn,
    DefaultInput,
    InterpunctLoader,
    Alert,
    PasswordInput,
} from '@fairplay2/ui';
import fairplayAPI from 'utils/api';
import { toFormData } from 'utils/parsing';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj, simplifyFormErrors } from 'utils/error-handler';
import { useAlert } from 'utils/hooks';
import { ConnectorProps, ClipForm } from '../interfaces';
import Confirmation from '../components/Confirmation';
import ConnectorFormTitle from '../components/ConnectorFormTitle';
import { useDataSources } from '../../context/DataSourcesContext';

export const ClipModal: FC<ConnectorProps> = ({ open, connector, onClose }) => {
    const { baseAPI, dispatch } = useDataSources(),
        theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        [confirmation, setConfirmation] = useState(false),
        [loading, setLoading] = useState(false),
        {
            register,
            formState: { errors },
            handleSubmit,
            reset,
        } = useForm<ClipForm>({
            mode: 'onBlur',
            values: {
                alias: connector?.alias || '',
                apiKey: '',
                apiSecret: '',
            },
        }),
        { alert, hideAlert, showAlert } = useAlert();

    const closeModal = () => {
        document.body.style.overflow = 'auto';
        reset();
        onClose();
        setConfirmation(false);
    };

    const onConnect: SubmitHandler<ClipForm> = async ({ alias, apiKey, apiSecret }) => {
        hideAlert();
        setLoading(true);

        const data = toFormData({
                platform: 'clip',
                alias,
                api_key: apiKey,
                api_secret: apiSecret,
            }),
            endpoint = `${baseAPI}${connector ? `/${connector.id}/reconnect` : ''}`;

        let errorMsg =
            'No se pudo realizar la conexión, verifique sus credenciales e intente nuevamente';

        try {
            const res: any = await fairplayAPI.post(endpoint, data);

            setConfirmation(true);
            dispatch({
                type: connector ? 'UPDATE' : 'ADD',
                payload: res.data.body.results,
            });
        } catch (errResponse: ErrorObj | any) {
            if (errResponse.type === 'form')
                errorMsg = Object.values(simplifyFormErrors(errResponse.error)).join(', ');
            else errorMsg = errResponse?.error || errorMsg;
            showAlert(errorMsg);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            disableBackdropClick
            open={open}
            onClose={closeModal}
            maxWidth={640}
            disableEscapeKeyDown
            title={!confirmation && <ConnectorFormTitle groupKey="clip" connector={connector} />}
            sx={{
                '.MuiDialogContent-root': {
                    pt: 0,
                },
            }}
        >
            {confirmation ? (
                <Confirmation item="Clip" onClose={closeModal} />
            ) : (
                <Grid container spacing={4} component="form" onSubmit={handleSubmit(onConnect)}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" color="text.primary">
                            Para que podamos conectar tu procesador de pagos con nuestra plataforma,
                            es necesario que le asignes un <strong>alias</strong> y nos proporciones
                            tu <strong>API key</strong> y <strong>Clave secreta</strong>. ¿Tienes
                            dudas?{' '}
                            <Link
                                sx={{ textDecoration: 'underline' }}
                                target="_blank"
                                href="https://fairplayhelpdesk.powerappsportals.com/es-es/knowledgebase/article/KA-01113"
                                rel="noopener noreferrer"
                            >
                                Consulta nuestra guía aquí
                            </Link>
                            .
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <DefaultInput
                            id="clip-alias"
                            labelVariant="external"
                            label="Alias"
                            type="text"
                            error={errors.alias?.message}
                            InputLabelProps={{ required: false }}
                            required
                            {...register('alias', {
                                required: 'Ingresa el Alias',
                            })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DefaultInput
                            id="clip-api-key"
                            labelVariant="external"
                            label="API key"
                            type="text"
                            error={errors.apiKey?.message}
                            InputLabelProps={{ required: false }}
                            required
                            {...register('apiKey', {
                                required: 'Ingresa el API key',
                            })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordInput
                            id="clip-api-secret"
                            labelVariant="external"
                            label="Clave secreta"
                            error={errors.apiSecret?.message}
                            InputLabelProps={{ required: false }}
                            required
                            {...register('apiSecret', {
                                required: 'Ingresa la Clave secreta',
                            })}
                        />
                    </Grid>

                    <Grid item container xs={12} justifyContent="center" paddingTop="0 !important">
                        <Collapse in={alert.open}>
                            <Alert severity={alert.type} sx={{ mt: 3, mb: -2 }}>
                                {alert.message}
                            </Alert>
                        </Collapse>
                    </Grid>

                    <Grid
                        item
                        container
                        xs={12}
                        justifyContent={{ xs: 'center', sm: 'flex-end' }}
                        marginTop={1.25}
                    >
                        {loading ? (
                            <InterpunctLoader />
                        ) : (
                            <>
                                <DefaultBtn
                                    fullWidth={smDown}
                                    size="small"
                                    variant="outlined"
                                    onClick={closeModal}
                                >
                                    Cancelar
                                </DefaultBtn>
                                <DefaultBtn
                                    fullWidth={smDown}
                                    size="small"
                                    type="submit"
                                    sx={{
                                        ml: { xs: 0, sm: '20px' },
                                        mt: { xs: '20px', sm: 0 },
                                    }}
                                >
                                    Conectar
                                </DefaultBtn>
                            </>
                        )}
                    </Grid>
                </Grid>
            )}
        </Modal>
    );
};

export default ClipModal;
