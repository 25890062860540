import { compare, reverseOf } from 'utils/lists';

export interface GoogleAccountItem {
    _raw: any;
    type: 'ads' | 'analytics';
    /**
     * Index in the generated array. Useful to have a
     * stable index even after filtering and sorting.
     */
    index: number;
    /**
     * Might not be displayed to the user, standard
     * attribute for data manipulation.
     */
    effectiveId: string;
    readableId: string;
    /**
     * Auxiliar data to display next to the `readableId`.
     */
    auxReadableId?: string;
    children?: Omit<GoogleAccountItem, 'children' | 'type' | 'index'>[];
}

interface UnifiedGoogleData {
    /**
     * Uses `effectiveId` as entry key.
     */
    isSelected: { [key: string]: boolean };
    accounts: GoogleAccountItem[];
    pendingAccounts?: GoogleAccountItem[];
}

export const searchGoogleAccount = (targetString: string) => {
    const effectiveTargetString = targetString.toLowerCase();

    return (item: GoogleAccountItem) =>
        !!item.readableId.toLowerCase().includes(effectiveTargetString) ||
        !!item.auxReadableId?.toLowerCase().includes(effectiveTargetString);
};

export const getAnalyticsId = (id: string) => {
    if (id.includes('properties') || id.includes('accounts')) {
        return id.split('/')[1] || '';
    }
    return id;
};

export const compareGoogleAsc = (a: GoogleAccountItem, b: GoogleAccountItem) =>
    // Since parent and child-less accounts have different keys for their ID, a conditional check is neeeded to sort them.
    compare(b.readableId.toLowerCase(), a.readableId.toLowerCase());

export const compareGoogleDesc = reverseOf(compareGoogleAsc as unknown as typeof compare);

export const unifyGoogleStructure = (serverData: any) => {
    const unifiedData = {
        accounts: [],
        isSelected: {},
    } as UnifiedGoogleData;

    const error = Error('La información tiene un formato incompatible.');

    if (!serverData) throw error;
    try {
        // If available accounts exists, we are receiving a Google Ads v2 object with different structure.
        if (serverData.availableAccounts?.length) {
            unifiedData.pendingAccounts = serverData.pendingAccounts?.map(
                ({ customersId, ...element }: any, index: number) =>
                    ({
                        _raw: element,
                        type: 'ads',
                        index,
                        readableId: `${element.loginCustomerId || element.customerId}`,
                        effectiveId: `${element.loginCustomerId || element.customerId}`,
                    } as GoogleAccountItem),
            );

            serverData.availableAccounts.forEach(
                ({ customersId, ...element }: any, index: number) => {
                    const unifiedElement = {
                        _raw: element,
                        type: 'ads',
                        index,
                        readableId: `${element.loginCustomerId || element.customerId}`,
                        effectiveId: `${element.loginCustomerId || element.customerId}`,
                    } as GoogleAccountItem;

                    if (customersId?.length) {
                        unifiedElement.children = [];
                        for (let i = 0; i < customersId.length; i++) {
                            const effectiveId = `${element.loginCustomerId}/${customersId[i].customerId}`;
                            unifiedElement.children.push({
                                readableId: customersId[i].name || '',
                                auxReadableId: `${customersId[i].customerId}`,
                                effectiveId,
                                _raw: customersId[i],
                            });
                            unifiedData.isSelected[effectiveId] = false;
                        }
                    } else if (element.customerId) {
                        unifiedData.isSelected[unifiedElement.effectiveId] = false;
                    }
                    unifiedData.accounts.push(unifiedElement);
                },
            );
        }
        // Analytics account
        else if (Array.isArray(serverData)) {
            serverData.forEach(({ propertySummaries, ...element }: any, index: number) => {
                const accountId = getAnalyticsId(element.account);
                const unifiedElement = {
                    _raw: element,
                    type: 'analytics',
                    index,
                    readableId: element.displayName,
                    effectiveId: accountId,
                    auxReadableId: accountId,
                } as GoogleAccountItem;

                if (propertySummaries?.length) {
                    unifiedElement.children = [];
                    for (let i = 0; i < propertySummaries.length; i++) {
                        const propertyId = getAnalyticsId(propertySummaries[i].property);
                        const effectiveId = `${accountId}/${propertyId}`;
                        unifiedElement.children.push({
                            readableId: propertySummaries[i].displayName,
                            auxReadableId: propertyId,
                            _raw: propertySummaries[i],
                            effectiveId,
                        });
                        unifiedData.isSelected[effectiveId] = false;
                    }
                }

                unifiedData.accounts.push(unifiedElement);
            });
        }
    } catch {
        throw error;
    }

    return unifiedData;
};

/**
 * Generates an array containing only the accounts and properties/apps specified by the user.
 * It receives the unified array generated by {@link unifyGoogleStructure} but returns
 * the items in their original non-unified structure.
 *
 * @param accounts
 * @param selectedIds
 * @returns Array of elements to send to backend, in their original structure.
 */
export const getRequestPayload = (accounts: GoogleAccountItem[], selectedIds: string[]) =>
    accounts.reduce((acc, account) => {
        if (account.type === 'ads') {
            if (account.children?.length) {
                // eslint-disable-next-line no-underscore-dangle
                const filtered = { ...account._raw, customersId: [] as any[] };
                account.children?.forEach((child) => {
                    if (selectedIds.includes(child.effectiveId))
                        // eslint-disable-next-line no-underscore-dangle
                        filtered.customersId.push(child._raw);
                });
                acc.push(filtered);
            } else if (selectedIds.includes(account.effectiveId)) {
                // eslint-disable-next-line no-underscore-dangle
                acc.push(account._raw);
            }
        } else {
            // eslint-disable-next-line no-underscore-dangle
            const filtered = { ...account._raw, propertySummaries: [] as any[] };
            account.children?.forEach((child) => {
                if (selectedIds.includes(child.effectiveId))
                    // eslint-disable-next-line no-underscore-dangle
                    filtered.propertySummaries.push(child._raw);
            });
            if (filtered.propertySummaries.length) acc.push(filtered);
        }
        return acc;
    }, [] as any[]);
