import { FC, useState, useContext, useEffect, useCallback } from 'react';
import { DefaultBtn, Alert } from '@fairplay2/ui';
import { Grid, useMediaQuery, useTheme, Collapse } from '@mui/material';
import SessionContext from 'context/session/sessionContext';
import fairplayAPI from 'utils/api';
import { useAlert } from 'utils/hooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { BankAccountsProps, BankAccount } from '../interfaces';
import BankAccountsSkeleton from './BankAccountsSkeleton';
import ManageAccountsModal from './ManageAccountsModal';
import AddAccountModal from './AddAccountModal';
import BankAccountData from './BankAccountData';

const BankAccounts: FC<BankAccountsProps> = ({ vendorId, vendorName }) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm')),
        { selectedCompany } = useContext(SessionContext),
        [accounts, setAccounts] = useState<BankAccount[]>([]),
        [isModalOpen, setIsModalOpen] = useState({
            addAccount: false,
            manageAccounts: false,
        }),
        [loading, setLoading] = useState(false),
        { alert, hideAlert, showAlert } = useAlert();

    const onModal = (targetModal: string, state: boolean) => {
        setIsModalOpen({
            ...isModalOpen,
            [targetModal]: state,
        });
    };

    const fetchAccounts = useCallback(async () => {
        if (vendorId) {
            hideAlert();
            try {
                setLoading(true);
                const res: any = await fairplayAPI.get(
                    `/v1/companies/${selectedCompany.company?.id}/suppliers/${vendorId}/accounts`,
                );
                setAccounts(res.data.body.results);
            } catch (errResponse: ErrorObj | any) {
                showAlert(errResponse?.error);
            } finally {
                setLoading(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany.company?.id, vendorId]);

    useEffect(() => {
        if (!selectedCompany.company) return;
        fetchAccounts();
    }, [selectedCompany, fetchAccounts]);

    return (
        <>
            {isModalOpen.addAccount && (
                <AddAccountModal
                    open={isModalOpen.addAccount}
                    onClose={() => onModal('addAccount', false)}
                    fetchAccounts={fetchAccounts}
                />
            )}

            {isModalOpen.manageAccounts && (
                <ManageAccountsModal
                    open={isModalOpen.manageAccounts}
                    accounts={accounts}
                    vendorId={vendorId}
                    vendorName={vendorName}
                    onClose={() => onModal('manageAccounts', false)}
                    fetchAccounts={fetchAccounts}
                />
            )}

            {loading ? (
                <BankAccountsSkeleton />
            ) : (
                <Grid container mt="5px">
                    <Grid item container xs={12} md={4}>
                        <Collapse in={alert.open}>
                            <Alert severity={alert.type}>{alert.message}</Alert>
                        </Collapse>
                    </Grid>
                    <Grid container item xs={12} md={8} justifyContent="flex-end">
                        {accounts.length > 0 && (
                            <DefaultBtn
                                fullWidth={smDown}
                                size="small"
                                variant="outlined"
                                onClick={() => onModal('manageAccounts', true)}
                                sx={{
                                    mt: '10px',
                                }}
                            >
                                Administrar cuentas
                            </DefaultBtn>
                        )}
                        <DefaultBtn
                            fullWidth={smDown}
                            size="small"
                            onClick={() => onModal('addAccount', true)}
                            sx={{
                                mt: '10px',
                                ml: { xs: 0, sm: '20px' },
                            }}
                        >
                            Agregar cuenta
                        </DefaultBtn>
                    </Grid>
                    <Grid container item spacing={4} mt="20px">
                        {accounts.map((account, i) => (
                            <BankAccountData key={account.id} account={account} i={i} />
                        ))}
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default BankAccounts;
