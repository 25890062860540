import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, DialogTitle } from '@mui/material';
import { Modal, Typography, DefaultBtn } from '@fairplay2/ui';
import { PeopleCelebratingSvg } from 'assets/SVGs';
import { WelcomeModalProps } from '../interfaces';

export const WelcomeModal: FC<WelcomeModalProps> = ({ open, onClose }) => {
    const history = useHistory();

    const closeModal = () => {
        sessionStorage.setItem('popupShown', 'true');
        onClose();
    };

    const onConfiguration = () => {
        closeModal();
        history.push('/app/user/edit');
    };

    return (
        <Modal
            open={open}
            onClose={closeModal}
            onBackdropClick={closeModal}
            sx={{
                '.MuiDialog-paper': {
                    maxWidth: {
                        xs: 680,
                        lg: 850,
                    },
                },
            }}
        >
            <Grid container>
                <Grid item container xs={12} justifyContent="center">
                    <PeopleCelebratingSvg
                        style={{ width: '100%', maxWidth: 300, height: 'auto' }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        component={DialogTitle}
                        variant="subtitle1"
                        color="primary.main"
                        mt="10px"
                        paddingX={0}
                    >
                        ¡Bienvenido a Fairplay!
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="text.primary" fontWeight={700} mt="15px">
                        Ya puedes comenzar a usar Insights
                    </Typography>
                    <Typography variant="subtitle2" color="text.primary" mt="20px">
                        ¡Personaliza la plataforma! Te recomendamos elegir una foto para tu perfil
                        de usuario así como asignar una foto de perfil y un alias o nombre comercial
                        para tu organización. De esta manera tu experiencia será única.
                    </Typography>
                </Grid>
                <Grid item container direction="column" alignItems="center">
                    <DefaultBtn
                        size="small"
                        sx={{
                            mt: '30px',
                        }}
                        onClick={onConfiguration}
                    >
                        Personalizar perfil
                    </DefaultBtn>
                    <DefaultBtn
                        variant="text"
                        sx={{
                            mt: '20px',
                        }}
                        onClick={closeModal}
                    >
                        Lo haré más tarde
                    </DefaultBtn>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default WelcomeModal;
