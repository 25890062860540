import { FC, useState, useEffect, useContext, useCallback } from 'react';
import { Grid, Collapse } from '@mui/material';
import { Alert } from '@fairplay2/ui';
import SessionContext from 'context/session/sessionContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import fairplayAPI from 'utils/api';
import { useAlert } from 'utils/hooks';
import { validateFileSize } from 'utils/files';
import DeleteDocModal from './components/DeleteDocModal';
import UploadDocModal from './components/UploadDocModal';
import DocumentsTable from './components/DocumentsTable';
import {
    Document,
    DocType,
    DocumentsModals,
    DocumentsModalTypes,
    SelectedDocument,
} from './interfaces';

const filterDocumentsTypes = (docTypes: DocType[], documents: Document[]): DocType[] =>
    docTypes.filter((docType) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const doc of documents) if (doc.documentType.id === docType.id) return false;
        return true;
    });

const CompanyDocuments: FC = () => {
    const { selectedCompany } = useContext(SessionContext),
        [loading, setLoading] = useState(true),
        [isModalOpen, setIsModalOpen] = useState<DocumentsModals>({
            uploadDocument: false,
            deleteDocument: false,
        }),
        [documentTypes, setDocumentTypes] = useState<DocType[]>([]),
        [selectedDocument, setSelectedDocument] = useState<SelectedDocument>({
            document: null,
            type: null,
        }),
        [documents, setDocuments] = useState<Document[]>([]),
        [file, setFile] = useState<File | null>(null),
        { alert, showAlert, hideAlert } = useAlert();

    const getDocuments = async (companyId: string) => {
        if (!companyId) return;
        try {
            setLoading(true);
            setDocuments([]);
            setDocumentTypes([]);
            const typesRes: any = await fairplayAPI.get(
                    `/v1/companies/${companyId}/documents/types`,
                ),
                documentsRes: any = await fairplayAPI.get(`/v1/companies/${companyId}/documents`);
            setDocuments(documentsRes.data.body.results);
            setDocumentTypes(filterDocumentsTypes(typesRes.data.body.results, documents));
        } catch (error: ErrorObj | any) {
            showAlert(
                error?.error ||
                    'Hubo un error al obtener los documentos de la empresa, intente más tarde',
            );
        } finally {
            setLoading(false);
        }
    };

    const openModal = useCallback((modal: DocumentsModalTypes) => {
        setIsModalOpen((prev) => ({ ...prev, [modal]: true }));
    }, []);

    const closeModal = useCallback(
        (modal: DocumentsModalTypes, shouldFetch: boolean) => {
            setFile(null);
            setSelectedDocument({
                document: null,
                type: null,
            });
            setIsModalOpen((prev) => ({ ...prev, [modal]: false }));
            if (shouldFetch) getDocuments(selectedCompany.company!.id);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedCompany.company?.id],
    );

    const onFileSelect = (selectedFile: File | null) => {
        setFile(selectedFile);

        if (selectedFile) {
            const fileError = validateFileSize(
                selectedFile,
                15,
                'No puedes subir archivos mayores a 15MB.',
            );
            if (fileError) showAlert(fileError, 'error');
            else {
                hideAlert();
                openModal('uploadDocument');
            }
        }
    };

    useEffect(() => {
        if (!selectedCompany.company?.id) return;
        getDocuments(selectedCompany.company.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany.company?.id]);

    return (
        <>
            <Grid container>
                <Grid container>
                    <Collapse in={alert.open}>
                        <Grid item xs={12}>
                            <Alert aria-label="Mensaje de error" severity={alert.type}>
                                {alert.message}
                            </Alert>
                        </Grid>
                    </Collapse>
                </Grid>

                <DocumentsTable
                    loading={loading}
                    documents={documents}
                    documentTypes={documentTypes}
                    showAlert={showAlert}
                    onFileSelect={onFileSelect}
                    setSelectedDocument={setSelectedDocument}
                    openModal={openModal}
                />

                <Alert
                    aria-label="Advertencia: Documentos obligatorios"
                    severity="warning"
                    sx={{ marginTop: '20px', padding: '14px' }}
                >
                    IMPORTANTE: Los documentos marcados con * son obligatorios
                </Alert>
            </Grid>

            {isModalOpen.uploadDocument && (
                <UploadDocModal
                    open={isModalOpen.uploadDocument}
                    file={file}
                    documentType={selectedDocument.type}
                    selectedDocument={selectedDocument.document}
                    onFileSelect={onFileSelect}
                    onClose={(shouldFetch: boolean) => closeModal('uploadDocument', shouldFetch)}
                />
            )}

            {isModalOpen.deleteDocument && (
                <DeleteDocModal
                    open={isModalOpen.deleteDocument}
                    onClose={(shouldFetch: boolean) => closeModal('deleteDocument', shouldFetch)}
                    selectedDocument={selectedDocument.document}
                />
            )}
        </>
    );
};

export default CompanyDocuments;
