import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Typography } from '@fairplay2/ui';
import { Boo404Svg, Bg404MoonSvg, Bg404CloudSvg } from 'assets/SVGs';
import { hasActiveSession } from 'utils/api';
import { NotFoundPageProps } from './interfaces';
import { Container, Subtitle, Background, Title } from './styles';

export const NotFoundPage: FC<NotFoundPageProps> = ({ message }) => (
    <Background>
        <Grid
            container
            item
            direction="column"
            maxWidth={704}
            maxHeight={670}
            height="100%"
            wrap="nowrap"
            alignItems="center"
            padding={2}
            spacing={2}
        >
            <Bg404MoonSvg className="InsightsNotFoundPage-moon" />
            <Bg404CloudSvg className="InsightsNotFoundPage-bigCloud" />
            <Bg404CloudSvg className="InsightsNotFoundPage-smallCloud" />
            <Container item xs={10}>
                <Title variant="h2">¡Uh!</Title>
                <Grid item>
                    <Boo404Svg className="InsightsNotFoundPage-notFound" />
                </Grid>
                <Subtitle variant="h3">Nada por aquí…</Subtitle>
                <Typography variant="h5" color="primary.contrastText" fontWeight={700} mt="20px">
                    {message ||
                        'Lo sentimos, no pudimos encontrar la página que buscas. Por favor revisa que tu URL no tenga errores ortográficos. Si aún así no logras accesar puedes ir a '}
                    <Typography
                        variant="h5"
                        color="secondary.main"
                        fontWeight={700}
                        component={Link}
                        to={hasActiveSession() ? '/app/sources' : '/'}
                    >
                        Inicio.
                    </Typography>
                </Typography>
            </Container>
        </Grid>
    </Background>
);
