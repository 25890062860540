import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Skeleton, IconBtn, Tooltip } from '@fairplay2/ui';
import { Visibility } from '@mui/icons-material';
import { formatAmount } from 'utils/parsing';
import { DailyPaymentOrdersProps } from './interfaces';
import { StyledPaymentOrderPaper } from './styles';

const DailyPaymentOrdersPaper: FC<DailyPaymentOrdersProps> = ({
    data,
    dateTitle,
    timeType = 'today',
    loading,
    size,
    role = 'listitem',
}) => {
    const history = useHistory(),
        disabled = !data,
        appendRootLabel = !['presentation', 'none'].includes(role);

    const goToDetail = () => {
        sessionStorage.setItem('openCalendar', 'true');
        history.push(
            `/app/financing/contract/detail/${data?.contractId}/payments/${data?.paymentDate}`,
        );
    };

    return loading ? (
        <Skeleton
            role={role}
            aria-label={appendRootLabel ? `Cargando datos` : undefined}
            width={size === 'lg' ? 205 : 141}
            height={size === 'lg' ? 200 : 131}
            data-testid="dailyPaymentOrdersPaper-skeleton"
        />
    ) : (
        <Grid
            item
            sx={{ opacity: disabled ? 0.3 : 1 }}
            role={role}
            aria-label={
                disabled && appendRootLabel
                    ? `${dateTitle || 'Fecha desconocida'}: Sin datos`
                    : undefined
            }
        >
            {/* TODO: Enhance accessibility when data is present (label the amounts) */}
            <StyledPaymentOrderPaper
                color={timeType === 'today' ? 'blue' : 'grey'}
                data={data}
                timeType={timeType}
                size={size}
            >
                <div
                    className="PaymentOrder-container"
                    data-testid="dailyPaymentOrdersPaper-data"
                    aria-hidden={disabled}
                >
                    <div className="PaymentOrder-header">
                        <div className="PaymentOrder-title">{dateTitle || '-'}</div>
                        <Tooltip
                            title="Ver detalle"
                            placement="top"
                            variant="standard"
                            disabled={disabled}
                        >
                            {/* Span necessary to avoid MUI 'Tolltip containing disable
                                        button' warning. Manually moving accesible label to button
                                        component. */}
                            <span>
                                <IconBtn
                                    color="secondary"
                                    variant="outlined"
                                    onClick={goToDetail}
                                    disabled={disabled}
                                    aria-label="Ver detalle"
                                >
                                    <Visibility />
                                </IconBtn>
                            </span>
                        </Tooltip>
                    </div>
                    <div
                        className="PaymentOrder-totalPayAmount"
                        data-testid="dailyPaymentOrdersPaper-totalPayAmount"
                    >
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {data
                            ? `$${formatAmount(
                                  // eslint-disable-next-line no-nested-ternary
                                  timeType === 'today'
                                      ? data.totalSummary!
                                      : timeType === 'future'
                                      ? data.remainingAmount
                                      : data.paid,
                              )}`
                            : timeType !== 'past'
                            ? 'Monto'
                            : 'Pagado'}
                    </div>
                    {timeType === 'today' && (
                        <div
                            className="PaymentOrder-todaysPayAmount"
                            data-testid="dailyPaymentOrdersPaper-todaysPayAmount"
                        >
                            {data ? `$${formatAmount(data.remainingAmount!)}` : 'Del día'}
                        </div>
                    )}
                    {timeType !== 'future' && (
                        <div
                            className="PaymentOrder-remainingAmount"
                            data-testid="dailyPaymentOrdersPaper-remainingAmount"
                        >
                            {data
                                ? `$${formatAmount(
                                      timeType === 'today' ? data.summary! : data.remainingAmount,
                                  )}`
                                : 'Pendiente'}
                        </div>
                    )}
                </div>
            </StyledPaymentOrderPaper>
        </Grid>
    );
};

export default DailyPaymentOrdersPaper;
