import { Alert, styled } from '@mui/material';

export const StyledAlert = styled(Alert)(({ severity, variant, theme }) => ({
    height: 38,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontWeight: 700,
    fontSize: 14,
    border: 'none',
    padding: variant === 'outlined' ? 0 : undefined,
    '&.MuiAlert-standardSuccess': { backgroundColor: '#CEE7D3' },
    '&.MuiAlert-outlinedSuccess': { color: theme.palette.success.main },
    '&.MuiAlert-outlinedInfo': { color: theme.palette.info.main },
    '&.MuiAlert-outlinedWarning': { color: theme.palette.warning.main },
    '&.MuiAlert-outlinedError': { color: theme.palette.error.main },
    '&.MuiAlert-filled': { color: severity ? theme.palette[severity].contrastText : undefined },
    '.MuiAlert-message': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));
