import { FC, useState, MouseEvent } from 'react';
import { Grid, Link } from '@mui/material';
import { DefaultBtn, ToggleBtn, Typography, UploadFileIcon, UploadIcon } from '@fairplay2/ui';
import { downloadManualUploadTemplate } from '../../../manual-upload/utils';
import { HybridWizardPlatformPickerProps } from '../../interfaces';

const TOGGLE_BTN_SX = {
    display: 'flex',
    mx: 'auto',
    width: '180px',
    height: '160px',
};

const PlatformPicker: FC<HybridWizardPlatformPickerProps> = ({
    onClose: onCloseProp,
    onConfirm: onConfirmProp,
}) => {
    const [platform, setPlatform] = useState('');

    const onTypeSelection = (e: MouseEvent<HTMLElement>, value: any) => {
        setPlatform(value);
    };

    const onClose = () => {
        onCloseProp?.();
        setPlatform('');
    };

    const onConfirm = () => {
        if (!platform) return;
        onConfirmProp(platform as 's3');
    };

    return (
        <Grid container direction="column">
            <Typography variant="subtitle2" color="text.primary" lineHeight={1.1875}>
                Esta opción te permitirá crear un acceso controlado a tu servicio de almacenamiento
                de objetos de AWS o utilizar la carga manual de información usando una plantilla en
                formato csv. Si tienes dudas sobre la conexión{' '}
                <Link
                    href="https://guides.getfairplay.com/es-es/knowledgebase/article/KA-01050"
                    target="_blank"
                    fontWeight={700}
                >
                    consulta la guía aquí
                </Link>
                .
            </Typography>
            <Grid item container justifyContent="center" mt={2} mb={6}>
                <Grid item width={180} mx={[1, 2, 3]} mt={2} textAlign="center">
                    <ToggleBtn
                        selected={platform === 's3'}
                        value="s3"
                        onClick={onTypeSelection}
                        sx={TOGGLE_BTN_SX}
                    >
                        <UploadIcon height="80px" aria-hidden="true" style={{ marginTop: '9px' }} />
                        <Typography
                            variant="subtitle2"
                            color="primary.main"
                            fontWeight={700}
                            mt="10px"
                        >
                            S3 Bucket
                        </Typography>
                    </ToggleBtn>
                    <Typography
                        variant="subtitle2"
                        color="text.primary"
                        lineHeight={1.1875}
                        mt={2}
                        textAlign="left"
                    >
                        Conecta un S3 Bucket de AWS.
                    </Typography>
                </Grid>
                <Grid item width={230} mx={[1, 2, 3]} mt={2} textAlign="center">
                    <ToggleBtn
                        selected={platform === 'manual-upload'}
                        value="manual-upload"
                        onClick={onTypeSelection}
                        sx={TOGGLE_BTN_SX}
                    >
                        <UploadFileIcon
                            height="53px"
                            aria-hidden="true"
                            style={{ marginTop: '25px' }}
                        />
                        <Typography
                            variant="subtitle2"
                            color="primary.main"
                            fontWeight={700}
                            mt="22px"
                        >
                            Carga manual
                        </Typography>
                    </ToggleBtn>
                    <Typography
                        variant="subtitle2"
                        color="text.primary"
                        lineHeight={1.1875}
                        mt={2}
                        textAlign="left"
                    >
                        Carga tus órdenes usando{' '}
                        <DefaultBtn
                            variant="text"
                            onClick={downloadManualUploadTemplate}
                            sx={{
                                fontSize: 'inherit',
                                padding: '0 !important',
                                lineHeight: 'inherit',
                            }}
                        >
                            esta plantilla en formato csv
                        </DefaultBtn>
                        .
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                item
                container
                rowSpacing={2}
                columnSpacing={3}
                width={[undefined, 'auto']}
                alignSelf="center"
            >
                <Grid item xs={12} sm>
                    <DefaultBtn size="small" fullWidth variant="outlined" onClick={onClose}>
                        Cancelar
                    </DefaultBtn>
                </Grid>
                <Grid item xs={12} sm>
                    <DefaultBtn size="small" fullWidth onClick={onConfirm} disabled={!platform}>
                        Conectar
                    </DefaultBtn>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PlatformPicker;
