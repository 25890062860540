import { FC, useReducer, ReactNode, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import fairplayAPI, { setSession, endSession } from 'utils/api';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { snakeToCamel } from 'utils/formatting';
import { SessionContext } from './sessionContext';
import sessionReducer, { restoreSavedState } from './sessionReducer';
import {
    CompanyInfo,
    UserState,
    User,
    LoginData,
    LoginResponse,
    GeneralSessionError,
} from './interfaces';

const SessionProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const initialState: UserState = {
        token: null,
        user: null,
        companies: [],
        selectedCompany: {},
        totalCompanies: null,
        loading: true,
        globalSettings: null,
        errors: {
            isFatal: false,
            messages: {},
        },
    };

    const [state, dispatch] = useReducer(sessionReducer, initialState, restoreSavedState);

    // Set loading
    const setLoading = (loading = true) => dispatch({ type: 'SET_LOADING', payload: loading });

    const setError = (value: GeneralSessionError) => {
        dispatch({ type: 'SET_ERROR', payload: value });
    };

    const hasFinancingModulesPermission = async (selectedCompanyId: string) => {
        try {
            const res: any = await fairplayAPI.get(`/v2/companies/${selectedCompanyId}/contracts`),
                contracts = res.data?.body;

            return {
                enabledContracts: contracts?.count > 0,
                enabledCards:
                    Array.isArray(contracts?.results) &&
                    !!contracts.results.find((contract: any) => contract.enableCardsModule),
            };
        } catch {
            return {
                enabledContracts: false,
                enabledCards: false,
            };
        }
    };

    // Get all user's companies
    const getCompanies = async () => {
        try {
            const response: any = await fairplayAPI.get(`/v1/users/companies`);
            if (response.data) {
                // Set total of companies
                dispatch({
                    type: 'TOTAL_COMPANIES',
                    payload: response.data.body.count,
                });
                if (response.data.body.results.length > 0) {
                    let company: any = {};

                    const selectedCompany = sessionStorage.getItem('selectedCompany')
                        ? JSON.parse(sessionStorage.selectedCompany)
                        : {};

                    if (Object.keys(selectedCompany).length) {
                        company = selectedCompany;
                        let companies;
                        // Add the company if does not exists in companies array
                        if (
                            !response.data.body.results.find(
                                (element: any) => element.company.id === company.company.id,
                            )
                        )
                            companies = [...response.data.body.results, company];
                        else companies = response.data.body.results;

                        dispatch({
                            type: 'SET_COMPANIES',
                            payload: companies,
                        });
                    } else {
                        company = response.data.body.results[0];

                        dispatch({
                            type: 'SET_COMPANIES',
                            payload: response.data.body.results,
                        });
                    }

                    const { enabledContracts, enabledCards } = await hasFinancingModulesPermission(
                        selectedCompany.company?.id || response.data.body.results[0].company.id,
                    );

                    company.enabledContracts = enabledContracts;
                    company.enabledCards = enabledCards;

                    dispatch({
                        type: 'SET_COMPANY',
                        payload: company,
                    });
                }
            }
        } catch (errResponse: ErrorObj | any) {
            dispatch({
                type: 'SET_ERROR',
                payload: {
                    isFatal: true,
                    messages: {
                        companies:
                            errResponse.error ||
                            'Se ha producido un error. Por favor intenta más tarde',
                    },
                },
            });
        }
    };

    // Get feature flags
    const fetchGlobalSettings = async () => {
        try {
            const response: any = await fairplayAPI.get('/v1/global_settings'),
                globalSettingsDic = {
                    ...response.data.body.results.reduce(
                        (acc: any, setting: any) => ({
                            ...acc,
                            [snakeToCamel(setting.name)]: setting.value,
                        }),
                        {},
                    ),
                };

            dispatch({
                type: 'SET_GLOBAL_SETTINGS',
                payload: globalSettingsDic,
            });
        } catch (errResponse: ErrorObj | any) {
            dispatch({
                type: 'SET_ERROR',
                payload: {
                    isFatal: false,
                    messages: {
                        globalSettings:
                            errResponse.error ||
                            'Se ha producido un error. Por favor intenta más tarde',
                    },
                },
            });
        }
    };

    // get user info
    const getUser = async () => {
        setLoading();
        try {
            const response: any = await fairplayAPI.get('/v1/users/profile');

            if (response.data) {
                dispatch({
                    type: 'SET_USER',
                    payload: response.data.body.results,
                });
            }
            getCompanies();
        } catch (errResponse: ErrorObj | any) {
            dispatch({
                type: 'SET_ERROR',
                payload: {
                    isFatal: false,
                    messages: {
                        user:
                            errResponse.error ||
                            'Se ha producido un error. Por favor intenta más tarde',
                    },
                },
            });
        }
    };

    // set user info when logging in
    const login = async (data: LoginData) => {
        const response: AxiosResponse<LoginResponse> = await fairplayAPI.post(
            '/v1/users/login',
            data,
            {
                authorization: false,
            },
        );
        if (response.data) {
            const { token, ...user } = response.data.body.results;

            setSession(token);
            // DataSources context is setting back to loading: false
            //  after a company changes
            setLoading();

            dispatch({
                type: 'SET_USER',
                payload: user,
            });
            getCompanies();
            fetchGlobalSettings();
        }
    };

    const updateUser = (data: User) => {
        dispatch({
            type: 'SET_USER',
            payload: data,
        });
    };

    // Set selected company globally
    const updateCompany = async (companyInfo: CompanyInfo, partialUpdate?: any) => {
        const updatedCompany: CompanyInfo = {
            ...companyInfo,
        };

        if (!partialUpdate) {
            setLoading();
            if (companyInfo.company?.id) {
                const { enabledContracts, enabledCards } = await hasFinancingModulesPermission(
                    companyInfo.company?.id,
                );

                updatedCompany.enabledContracts = enabledContracts;
                updatedCompany.enabledCards = enabledCards;
            }
        }

        dispatch({
            type: 'SET_COMPANY',
            payload: updatedCompany,
        });

        // Add the company if does not exists in companies array
        if (!state.companies.find((element) => element.company?.id === companyInfo.company?.id)) {
            dispatch({
                type: 'SET_COMPANIES',
                payload: [...state.companies, companyInfo],
            });
        } else {
            const updatedCompanies = state.companies.map((item) => {
                if (item.company?.id === companyInfo.company?.id)
                    return { ...item, company: companyInfo.company };
                return item;
            });

            dispatch({
                type: 'SET_COMPANIES',
                payload: updatedCompanies,
            });
        }
        dispatch({ type: 'RESTART_ERRORS' });
    };

    // logout
    const logout = () => {
        endSession(false);
        dispatch({
            type: 'SET_LOGOUT',
        });
    };

    useEffect(() => {
        if (state.user && !state.globalSettings) fetchGlobalSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SessionContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                token: state.token,
                user: state.user,
                companies: state.companies,
                selectedCompany: state.selectedCompany,
                loading: state.loading,
                errors: state.errors,
                totalCompanies: state.totalCompanies,
                globalSettings: state.globalSettings,
                login,
                getUser,
                getCompanies,
                logout,
                updateUser,
                updateCompany,
                setLoading,
                setError,
                fetchGlobalSettings,
            }}
        >
            {children}
        </SessionContext.Provider>
    );
};

export default SessionProvider;
