import { useState, createContext, ChangeEvent, MouseEvent, useContext } from 'react';
import { useAlert } from 'utils/hooks';
import { OrderType } from 'utils/interfaces';
import { ProviderTableProps, TableState } from '../interfaces';

export const TableContext = createContext({} as TableState<any>);

const TableProvider = ({ initialData, periodAvailable, children }: ProviderTableProps) => {
    const [filterValues, setFilterValues] = useState(initialData.unconfirmed),
        [currencyRange, setCurrencyRange] = useState<number[]>([]),
        [isFilterOff, setIsFilterOff] = useState(true),
        [activeFilters, setActiveFilters] = useState(0),
        // externalFilters state necessary to avoid Table component filtering the data every time the user modifies an individual filter
        [externalFilters, setExternalFilters] = useState(initialData.effective),
        // TODO: Create function to extract 'orderCriteria' & 'order' initial values from initialData.effective.ordering
        [orderCriteria, setOrderCriteria] = useState(
            initialData.effective?.ordering || 'request_date',
        ),
        [order, setOrder] = useState<OrderType>('desc'),
        [page, setPage] = useState(0),
        [rowsPerPage, setRowsPerPage] = useState(5),
        [requestParams, setRequestParams] = useState(''),
        { alert, hideAlert, showAlert } = useAlert();

    const resetFilterValues = () => {
        setIsFilterOff(true);
        setFilterValues(initialData.unconfirmed);
        setCurrencyRange([]);
        setOrder('desc');
        setOrderCriteria(initialData.effective?.ordering || 'request_date');
        setActiveFilters(0);
        setPage(0);
        setRowsPerPage(5);
        setExternalFilters(initialData.effective);
    };

    const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const onSortDate = (event: MouseEvent<HTMLElement>) => {
        let source: typeof orderCriteria | null = null;
        // eslint-disable-next-line no-restricted-syntax
        for (const element of event.nativeEvent.composedPath()) {
            const eventTrigger = (element as any).dataset?.criteria;
            if (eventTrigger) {
                source = eventTrigger;
                break;
            }
        }

        if (!source) return;
        if (source === orderCriteria) setOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
        else {
            setOrderCriteria(source);
            setOrder('desc');
        }
    };

    const onCurrencyRangeChange: typeof setCurrencyRange = (newValue) => {
        if (isFilterOff) setIsFilterOff(false);
        setCurrencyRange(newValue);
    };

    return (
        <TableContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                filterValues,
                setFilterValues,
                currencyRange,
                isFilterOff,
                setIsFilterOff,
                activeFilters,
                setActiveFilters,
                externalFilters,
                setExternalFilters,
                orderCriteria,
                order,
                page,
                setPage,
                rowsPerPage,
                requestParams,
                setRequestParams,
                alert,
                showAlert,
                periodAvailable,
                // functions
                hideAlert,
                resetFilterValues,
                onChangeRowsPerPage,
                onSortDate,
                onCurrencyRangeChange,
            }}
        >
            {children}
        </TableContext.Provider>
    );
};

export const useTableContext = () => {
    const context = useContext(TableContext);
    if (!context) {
        throw new Error('useTableContext must be used inside TableProvider');
    }
    return context;
};

export default TableProvider;
