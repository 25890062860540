import { Grid } from '@mui/material';
import { Typography, Skeleton } from '@fairplay2/ui';

export const CardDetailSkeleton = () => (
    <Grid item container flexGrow={1} flexBasis={0} data-testid="skeleton-card-detail">
        <Grid
            container
            columnSpacing={{ sm: '20px' }}
            rowSpacing={{ xs: '25px', md: '30px' }}
            maxWidth={{ xs: '100%', md: '560px' }}
            minWidth={{ xs: '100%', md: '390px' }}
        >
            <Grid item xs={12} sm={6}>
                <Typography variant="label">Contrato asociado</Typography>
                <Skeleton height={53} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant="label">Disponible hoy</Typography>
                <Skeleton height={53} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant="label">Solicitado en el periodo</Typography>
                <Skeleton height={53} />
            </Grid>
            <Grid item xs={12} sm={6} alignSelf="flex-end">
                <Grid container>
                    <Typography variant="label">Monto congelado</Typography>
                    <Grid item ml="5px">
                        <Skeleton variant="circular" height="20px" width="20px" />
                    </Grid>
                </Grid>
                <Skeleton height={53} />
            </Grid>
        </Grid>
    </Grid>
);

export default CardDetailSkeleton;
