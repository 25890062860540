import { FC } from 'react';
import { Modal } from '@fairplay2/ui';
import ConfigureAdvertising from './Configure';
import { WizardProps } from './interfaces';

const Wizard: FC<WizardProps> = ({ open = false, onClose, ...props }) => (
    <Modal disableBackdropClick open={open} onClose={onClose} maxWidth={680} disableEscapeKeyDown>
        <ConfigureAdvertising onClose={onClose} onCancel={onClose} wizard {...props} />
    </Modal>
);

export default Wizard;
