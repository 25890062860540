import { Avatar } from '@fairplay2/ui';
import { styled } from '@mui/material/styles';

export const InfoGrid = styled('div')(({ theme }) => ({
    display: 'grid',
    width: '100%',
    height: '100%',
    gridTemplateColumns: '1fr 100px',
    gridTemplateRows: '60% 40%',
    gridTemplateAreas: '"infos avatar""infos actions"',
    [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: '40% 30% 30%',
        gridTemplateAreas: '"avatar""infos""actions"',
    },
}));

export const StyledAvatar = styled(Avatar)({
    gridArea: 'avatar',
    alignSelf: 'center',
    justifySelf: 'end',
    margin: '35px 35px 0 0',
});

export const InfoContainer = styled('div')(({ theme }) => ({
    gridArea: 'infos',
    alignSelf: 'end',
    margin: '0 0 55px 35px',
    [theme.breakpoints.down('md')]: {
        margin: '15px 0 0 35px',
    },
    width: 220,
}));

export const ActionsContainer = styled('div')({
    gridArea: 'actions',
    display: 'flex',
    alignSelf: 'center',
    justifySelf: 'end',
    margin: '35px 65px',
});
