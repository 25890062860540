import { useState } from 'react';
import img from 'assets/img/connectors/SAT.png';
import { PlatformItem } from '../interfaces';
import BasePlatformItem from '../components/BasePlatformItem';
import Disconnect from '../components/disconnect-item/Disconnect';
import useDualForm from '../useDualForm';
import { ConnectorData } from '../../interfaces';
import ConnectorForm from './ConnectorForm';

// eslint-disable-next-line react/prop-types
const SAT: PlatformItem = ({ notify }) => {
    const { showForm, connector, onConnect, closeForm, onReauth } = useDualForm(),
        [disconnectTarget, setDisconnectTarget] = useState<ConnectorData | null>(null);

    const closeDisconnect = () => setDisconnectTarget(null);

    return (
        <BasePlatformItem
            picture={img}
            groupKey="sat"
            notify={notify}
            onConnect={onConnect}
            onReauth={onReauth}
            onDisconnect={setDisconnectTarget}
        >
            <ConnectorForm open={showForm} onClose={closeForm} connector={connector} />
            <Disconnect
                platform="sat"
                open={!!disconnectTarget}
                onCancel={closeDisconnect}
                onClose={closeDisconnect}
                element={disconnectTarget}
            />
        </BasePlatformItem>
    );
};

export default SAT;
