import {
    mimicDateAtCDMX,
    syntheticDateToTimelessISO,
    negateLocalOffset,
    localFormat,
    TIMELESS_ISO_FORMAT,
    toTimelessISO,
} from 'utils/dates';
import {
    monetaryDecimalToInteger,
    monetaryIntegerToDecimal,
    multiplyMonetaryIntegers,
} from 'utils/currency';
import fairplayAPI from 'utils/api';
import { Errors, ExchangeRatesProps, Form, TotalRequestedAmount } from './interfaces';

// TODO: Rewrite calendar utils to use function form of DatePicker['disabled']
//  (When disb v1 utils get removed)

enum WEEKDAYS {
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6,
    SUNDAY = 0,
}

/**
 * *Important: Calculations use CDMX Timezone and rely on Intl API
 *
 * Calculate the closest day for a user to
 * schedule a disbursement, based on the current date.
 *
 * By default, this is the day immediately after the current date, unless
 * current hour is greater than 5pm, in which case an extra
 * day is added.
 *
 * If the current date is weekend or friday after 5pm,
 * next Tuesday is returned instead.
 *
 * @returns Closest available {@link Date}.
 */
export const getMinDisbursementDate = (): Date => {
    const date = mimicDateAtCDMX();
    const TIME_AT_CDMX = `${`${date.getUTCHours()}`.padStart(
            2,
            '0',
        )}:${`${date.getUTCMinutes()}`.padStart(2, '0')}:${`${date.getUTCSeconds()}`.padStart(
            2,
            '0',
        )}`,
        WEEKDAY_AT_CDMX = date.getUTCDay();

    // Next day is the default min date
    date.setUTCDate(date.getUTCDate() + 1);
    date.setUTCHours(0);
    date.setUTCMinutes(0);
    date.setUTCSeconds(0);

    // Check if it's the weekend. If it's weekend, set start date to Tuesday.
    if (WEEKDAY_AT_CDMX === WEEKDAYS.SATURDAY) date.setUTCDate(date.getUTCDate() + 2);
    else if (WEEKDAY_AT_CDMX === WEEKDAYS.SUNDAY) {
        date.setUTCDate(date.getUTCDate() + 1);
    } else if (TIME_AT_CDMX >= '17:00:00') {
        date.setUTCDate(date.getUTCDate() + 1);
        // If Friday after 5pm, set start date to Tuesday.
        if (WEEKDAY_AT_CDMX === WEEKDAYS.FRIDAY) {
            date.setUTCDate(date.getUTCDate() + 2);
        }
    }

    return negateLocalOffset(date);
};

/**
 *
 * @param month Month of the year (0-based)
 * @param year Year (1-based)
 * @returns Total count of days in the specified month
 */
export const getDaysInMonth = (month: any, year: any) => new Date(year, month + 1, 0).getDate();

/**
 * Calculates an array of {@link Date} objects for the
 * weekends of the current month and the next 3 months.
 */
export const get4MonthsWeekends = (): Date[] => {
    const today = mimicDateAtCDMX();
    today.setUTCHours(0);
    today.setUTCMinutes(0);
    today.setUTCSeconds(0);

    const weekends = [];
    for (let j = 0; j < 4; j++) {
        // Get total days in each month
        const daysInMonth = getDaysInMonth(today.getUTCMonth() + j, today.getUTCFullYear()),
            todayMonth = today.getUTCMonth(),
            todayYear = today.getUTCFullYear();

        for (let i = 1; i <= daysInMonth; i++) {
            const unsanitizedMonthNumber = todayMonth + j + 1,
                month = unsanitizedMonthNumber % 12 || 12,
                year =
                    todayYear +
                    (unsanitizedMonthNumber <= 12 ? 0 : Math.floor(unsanitizedMonthNumber / 12)),
                newDate = new Date(syntheticDateToTimelessISO({ year, month, day: i }));

            if (
                newDate.getUTCDay() === WEEKDAYS.SATURDAY ||
                newDate.getUTCDay() === WEEKDAYS.SUNDAY
            ) {
                weekends.push(negateLocalOffset(newDate));
            }
        }
    }
    return weekends;
};

export const getFormStatus = (
    contractType: 'sales-advancement' | 'working-capital',
    formValues: Form,
    hasExternalError: boolean,
) => {
    const result = {
        errors: {} as Errors,
        valid: !hasExternalError,
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(formValues || {})) {
        if (
            !value &&
            !(key === 'fileName' && contractType === 'sales-advancement') &&
            !(key === 'supplierId' && contractType === 'sales-advancement') &&
            !['currency', 'id_dispersion', 'disabled', 'confirmed', 'file'].includes(key)
        ) {
            result.errors[key as keyof Form] = 'Campo requerido';
            result.valid = false;
        }
    }

    return result;
};

export const getFormData = (
    cartItem: Form & { totalRequestedAmountSum: number },
    productType: 'working-capital' | 'sales-advancement',
) => {
    const data = new FormData();
    Object.entries(cartItem).forEach(([key, value]: any) => {
        switch (key) {
            case 'supplierAccount':
                if (value?.id) data.append('supplier_account', value.id);
                break;
            case 'supplierData':
                if (value?.id) data.append('supplier_id', value.id);
                break;
            case 'dispersion_date':
                data.append(
                    key,
                    localFormat(value || '', TIMELESS_ISO_FORMAT, { fallbackString: '' }),
                );
                break;
            case 'totalRequestedAmountSum':
                data.append('total_amount_mxn', monetaryIntegerToDecimal(value, false));
                break;
            case 'file':
                if (value) data.append('file_name', value.name);
                break;
            default:
                if (value && ['confirmed', 'concept', 'amount', 'reference'].includes(key))
                    data.append(key, value);
                break;
        }
    });
    if (productType === 'sales-advancement')
        data.append('metadata_cart', JSON.stringify(Object.fromEntries(data)));

    return data;
};

export const getTotalCurrenciesAmountSum = (
    totalRequestedAmount: TotalRequestedAmount,
    exchangeRates: ExchangeRatesProps,
) => {
    let totalRequestedAmountSum = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const currency in totalRequestedAmount) {
        if (currency === 'mxn') {
            totalRequestedAmountSum += totalRequestedAmount.mxn;
        } else {
            totalRequestedAmountSum += multiplyMonetaryIntegers(
                totalRequestedAmount[currency],
                exchangeRates[currency]?.rate,
            );
        }
    }

    return totalRequestedAmountSum;
};

export const getAmountValidation = (
    amount: number | null,
    currency: string,
    exchangeRates: ExchangeRatesProps,
    prevTotalRequestedAmount: TotalRequestedAmount,
    periodAvailableBalance: number,
) => {
    const formatedAmount = monetaryDecimalToInteger(amount || 0),
        requestedAmount =
            currency === 'mxn'
                ? formatedAmount
                : multiplyMonetaryIntegers(formatedAmount, exchangeRates[currency]?.rate),
        currentTotalAmount = getTotalCurrenciesAmountSum(prevTotalRequestedAmount, exchangeRates),
        newTotalAmount = currentTotalAmount + requestedAmount,
        newAmount = {
            ...prevTotalRequestedAmount,
            [currency]: (prevTotalRequestedAmount[currency] || 0) + formatedAmount,
        };

    // When there is no exchange rate for the selected currency.
    // Truthy values checked for the first form render not to return this error when fetching data.
    if (
        !!currency &&
        !!Object.keys(exchangeRates).length &&
        currency !== 'mxn' &&
        !exchangeRates[currency]
    )
        return { available: false, value: newAmount, exchangeError: 'Error con tipo de cambio' };

    return {
        available: newTotalAmount <= periodAvailableBalance,
        exchangeError: '',
        value: newAmount,
    };
};

export const onPresignedUrlUpload = async (
    presignedUrl: string,
    file: string,
    onError: () => void,
) => {
    if (!presignedUrl) {
        return;
    }

    const headers = {
        'Content-Type': '',
        Accept: 'application/json, text/plain, */*',
    };

    try {
        await fairplayAPI.put(presignedUrl, file, { headers, authorization: false });
    } catch (error) {
        onError();
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw {
            error: 'Ocurrió un error al subir el archivo, intente de nuevo.',
            type: 'server',
            status: 500,
        };
    }
};

/**
 * Generates an array of holidays that the user cannot select
 * for a disbursement, based on the current date.
 *
 * * Important: Holidays are requested from the API.
 *
 * @returns Array of holidays
 *
 */
export const getHolidaysDates = async () => {
    const res: any = await fairplayAPI.get(
        `/v1/common/holidays?ordering=date&date=${toTimelessISO(new Date())}`,
        {
            authorization: false,
        },
    );
    return res.data.map((holiday: any) => negateLocalOffset(new Date(holiday.date))) as Date[];
};
