import { FC } from 'react';
import { DialogTitle, Grid, Link } from '@mui/material';
import { DefaultBtn, Typography } from '@fairplay2/ui';
import { ManSittingPlanSvg } from 'assets/SVGs';
import { S3DisclaimerModalProps } from '../../interfaces';

const S3Disclaimer: FC<S3DisclaimerModalProps> = ({ onClose, onConfirm }) => (
    <Grid container direction="column" alignItems="center">
        <ManSittingPlanSvg width={151} height={118} />
        <Typography
            color="primary.main"
            fontSize="17px !important"
            lineHeight={1.1875}
            p={0}
            component={DialogTitle}
            mt={4}
            width="100%"
        >
            Almacenamiento de datos S3 Bucket de Amazon
        </Typography>
        <Typography variant="subtitle2" color="text.primary" lineHeight={1.1875} mt={2}>
            Estás a punto de darnos un acceso controlado a tu servicio de almacenamiento de objetos
            de Amazon. Para que Fairplay Insights pueda procesar correctamente la información que
            almacenas en tu directorio necesitamos que tus archivos cumplan con un formato
            específico.
        </Typography>
        <Typography variant="subtitle2" color="text.primary" lineHeight={1.1875} mt={2}>
            Por favor verifica que el formato de tus documentos coincida con los requerimientos de
            Fairplay.{' '}
            <Link
                href="https://cdn.getfairplay.com/api/customOrderValidator.json"
                target="_blank"
                fontWeight={700}
            >
                Verifica el formato aquí
            </Link>
            .
        </Typography>
        <Typography
            variant="subtitle2"
            color="text.primary"
            lineHeight={1.1875}
            mt={2}
            width="100%"
        >
            Si ya has verificado que el formato es correcto da clic en Confirmar.
        </Typography>
        <Grid
            item
            container
            rowSpacing={2}
            columnSpacing={3}
            width={[undefined, 'auto']}
            alignSelf="center"
            mt={2}
        >
            <Grid item xs={12} sm>
                <DefaultBtn size="small" fullWidth variant="outlined" onClick={onClose}>
                    Cancelar
                </DefaultBtn>
            </Grid>
            <Grid item xs={12} sm>
                <DefaultBtn size="small" fullWidth onClick={onConfirm}>
                    Confirmar
                </DefaultBtn>
            </Grid>
        </Grid>
    </Grid>
);

export default S3Disclaimer;
