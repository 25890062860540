import { FC, useContext, useEffect, useState } from 'react';
import SessionContext from 'context/session/sessionContext';
import fairplayAPI from 'utils/api';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { LoaderPage } from '@fairplay2/ui';
import { useAlert } from 'utils/hooks/useAlert';
import { useDisbursementValuesContext } from 'context/disbursements/DisbursementValuesContext';
import { HydratedContract } from '../../../contracts/interfaces';
import { getStorageContract } from '../../utils';
import DisbursementV1 from './index.v1';
import Disbursement from './index';

const VersionController: FC = () => {
    const { selectedCompany } = useContext(SessionContext),
        { setEnableDisbursementsV2Module } = useDisbursementValuesContext(),
        [loading, setLoading] = useState(true),
        { alert, hideAlert, showAlert } = useAlert(),
        [contracts, setContracts] = useState<HydratedContract[]>([]),
        [selectedContract, setSelectedContract] = useState<HydratedContract>(contracts[0]);

    const replaceContractsList = (hydratedContracts: HydratedContract[]) => {
        setContracts(hydratedContracts);
        if (!hydratedContracts || !hydratedContracts.length) return;

        const savedContract = getStorageContract(),
            contractExists = hydratedContracts.find(
                (contract) => contract.id === savedContract?.id,
            ),
            newContractSelect = contractExists || hydratedContracts[0];

        setSelectedContract(newContractSelect);
        setEnableDisbursementsV2Module(newContractSelect?.enableDisbursementsV2Module);
    };

    const fetchContracts = async () => {
        hideAlert();
        setLoading(true);
        try {
            const res1: any = await fairplayAPI.get(
                    `/v2/companies/${selectedCompany.company?.id}/contracts`,
                ),
                hydratedContracts: HydratedContract[] = res1.data.body.results;

            const res2: any = await fairplayAPI.get(
                    `/v2/companies/${selectedCompany.company?.id}/contracts?dispersions_allowed=true`,
                ),
                allowedContracts: HydratedContract[] = res2.data.body.results;

            sessionStorage.setItem('allowedContracts', JSON.stringify(allowedContracts));

            const updatedContracts = hydratedContracts.map((contractToUpdate) => {
                const disbursementAllowed = allowedContracts.some(
                    (allowedContract) => allowedContract.id === contractToUpdate.id,
                );

                return { ...contractToUpdate, disbursementAllowed };
            });
            replaceContractsList(updatedContracts);
        } catch (errResponse: ErrorObj | any) {
            showAlert(
                errResponse?.error || 'No se pudieron obtener los contratos, Intente más tarde',
            );
        } finally {
            setLoading(false);
        }
    };

    const onContractSelect = (contractId: string) => {
        const newSelectedContract =
            contracts.find((contract) => contract.id === contractId) || contracts[0];

        setSelectedContract(newSelectedContract);

        if (contracts.length > 0) {
            sessionStorage.setItem('selectedContract', JSON.stringify(newSelectedContract));
        }

        if (
            newSelectedContract?.enableDisbursementsV2Module !==
            selectedContract?.enableDisbursementsV2Module
        ) {
            setLoading(true);
            setEnableDisbursementsV2Module(newSelectedContract?.enableDisbursementsV2Module);
            /*  Timeout used in order for the user experience to be
                smoother with a loader when changing between both versions,
                and it not changing instantaneously. */
            setTimeout(() => {
                setLoading(false);
            }, 250);
        }
    };

    useEffect(() => {
        if (selectedCompany.company?.id) {
            fetchContracts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany.company?.id]);

    // eslint-disable-next-line no-nested-ternary
    return loading ? (
        <LoaderPage loading={loading} />
    ) : selectedContract?.enableDisbursementsV2Module ? (
        <Disbursement
            contracts={contracts}
            selectedContract={selectedContract}
            onContractSelect={onContractSelect}
            alert={alert}
            hideAlert={hideAlert}
            showAlert={showAlert}
        />
    ) : (
        <DisbursementV1
            contracts={contracts}
            selectedContract={selectedContract}
            onContractSelect={onContractSelect}
            alert={alert}
            hideAlert={hideAlert}
            showAlert={showAlert}
        />
    );
};

export default VersionController;
