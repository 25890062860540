import { FC, useContext, useState, useEffect, ChangeEvent } from 'react';
import { Collapse, Fade, Grid, MenuItem } from '@mui/material';
import SessionContext from 'context/session/sessionContext';
import { Select, Typography, Skeleton, IconBtn, DefaultInput, Tooltip, Paper } from '@fairplay2/ui';
import { CurrencyInput } from '@fairplay2/ui-x-currencies';
import { AttachMoney, Info, Receipt, MoreHoriz, Remove } from '@mui/icons-material';
import fairplayAPI from 'utils/api';
import { useDisbursementValuesContext } from 'context/disbursements/DisbursementValuesContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { formatUtc, negateLocalOffset, SHORT_READABLE_FORMAT } from 'utils/dates';
import { monetaryDecimalToInteger, monetaryIntegerToDecimal } from 'utils/currency';
import { contractPrintableName } from 'utils/formatting';
import { formatPeriodsData, getStorageContract } from 'components/dashboard/disbursment/utils';
import { CurrenciesInfoProps, Form } from '../interfaces';
import { HydratedContract } from '../../../../contracts/interfaces';
import { getHolidaysDates } from '../utils';

const getDisbursementCurrencyCount = (cartData: Form[], currency: string) =>
    cartData.filter((cartItem: Form) => cartItem.currency === currency).length;

const CurrenciesInfo: FC<CurrenciesInfoProps> = ({ cartData, totalRequestedAmount, dataType }) => (
    <>
        {Object.entries(totalRequestedAmount).map((currency: [string, unknown], index) => {
            if (!currency[1]) return null;
            return (
                // eslint-disable-next-line react/no-array-index-key
                <Fade in timeout={500} key={index}>
                    <Grid item container justifyContent="space-between" wrap="nowrap" role="row">
                        <Typography
                            color={currency[0] === 'mxn' ? 'primary.main' : 'primary.dark'}
                            fontWeight={700}
                            mt="10px"
                            role="cell"
                        >
                            {dataType === 'disbursementsCount' && 'En '}
                            {currency[0].toUpperCase()}$
                        </Typography>
                        <Typography
                            color={currency[0] === 'mxn' ? 'primary.main' : 'primary.dark'}
                            mt="10px"
                            ml="8px"
                            fontWeight={700}
                            ellipsis
                            role="cell"
                        >
                            {dataType === 'disbursementsCount'
                                ? getDisbursementCurrencyCount(cartData, currency[0])
                                : monetaryIntegerToDecimal(currency[1] as number)}
                        </Typography>
                    </Grid>
                </Fade>
            );
        })}
    </>
);

const DisbursementInfo: FC = () => {
    const { selectedCompany } = useContext(SessionContext),
        {
            selectedContract,
            disbursementStep,
            disbursementPeriod,
            cartData,
            disbursementAlert,
            setDisbursementPeriod,
            setDisbursementAlert,
            setDisbursementContract,
            setMinDisbursementDate,
            setMaxDisbursementDate,
            setPeriodAvailableBalance,
            setExchangeRates,
            periodAvailableBalance,
            totalRequestedAmount,
            suppliersCount,
            exchangeRates,
            totalRequestedAmountSum,
            disbursementLocation,
            isAmountValid,
            setDisabledDisbursementDays,
        } = useDisbursementValuesContext(),
        [loading, setLoading] = useState({ contract: true, period: true }),
        [contracts, setContracts] = useState([]),
        [periodsDates, setPeriodsDates] = useState<any>(null),
        [expand, setExpand] = useState({ disbursements: false, amounts: false }),
        foreignCurrenciesUsed = Object.entries(totalRequestedAmount).filter(
            ([currency, amount]) => currency !== 'mxn' && !!amount,
        ),
        hasForeignCurrencies = !!foreignCurrenciesUsed?.length;

    const onPeriodChange = (event: ChangeEvent<HTMLInputElement>) =>
        setDisbursementPeriod(event.target.value);

    const onContractSelect = (event: ChangeEvent<HTMLInputElement>) => {
        const newContract = contracts.find(
            (contract: HydratedContract) => contract.id === event.target.value,
        );
        // TODO: Verify if sessionStorage items 'selectedContract' and 'allowedContracts', should be deleted when changing contracts
        setDisbursementContract(newContract);
    };

    const initializeDisbursement = async () => {
        try {
            setDisbursementAlert({
                msg: '',
                severity: 'success',
            });
            setLoading((prevstate) => ({ ...prevstate, contract: true }));

            const dates = await getHolidaysDates();
            setDisabledDisbursementDays(dates);

            // This avoids reloading contracts when the user retrieves his cart
            if (disbursementStep) {
                if (!selectedContract) throw new Error('Should have a contract selected');
                return;
            }

            const savedContract = getStorageContract();
            let allowedContracts = JSON.parse(sessionStorage.getItem('allowedContracts') || '[]');

            if (!allowedContracts.length) {
                const res: any = await fairplayAPI.get(
                    `/v2/companies/${selectedCompany.company?.id}/contracts?dispersions_allowed=true`,
                );
                allowedContracts = res.data.body.results || [];
            }

            const v2Contracts = allowedContracts.filter(
                (contract: HydratedContract) => contract.enableDisbursementsV2Module,
            );
            setContracts(v2Contracts);

            const canCurrentContractDisb = v2Contracts.some(
                (contract: HydratedContract) => contract.id === savedContract?.id,
            );

            if (canCurrentContractDisb) setDisbursementContract(savedContract);
            else setDisbursementContract(v2Contracts[0]);
        } catch (errResponse: ErrorObj | any) {
            setLoading((prevstate) => ({ ...prevstate, contract: false }));
            setDisbursementAlert({
                msg:
                    errResponse?.error ||
                    'Ocurrió un error creando la dispersión, intente más tarde',
                severity: 'error',
            });
        }
    };

    const fetchPeriodsDates = async () => {
        try {
            setDisbursementAlert({
                msg: '',
                severity: 'success',
            });
            const res: any = await fairplayAPI.get(
                `/v3/disbursement-periods?contract_id=${selectedContract.id}`,
                {
                    baseService: 'PERIODS_LOANBOOK',
                },
            );

            const { currentPeriod, nextPeriod } = formatPeriodsData(res.data.body.results);

            if (!currentPeriod?.start_date && !nextPeriod) return;
            setPeriodsDates({
                ISOcurrentPeriodStartDate: currentPeriod?.start_date,
                currentPeriodMinDate: currentPeriod?.start_date,
                currentPeriodEndDate: currentPeriod?.end_date,
                nextPeriodStartDate: nextPeriod?.start_date,
                nextPeriodEndDate: nextPeriod?.end_date,
            });
        } catch (errResponse: ErrorObj | any) {
            setDisbursementAlert({
                msg:
                    errResponse?.error ||
                    'Ocurrió un error seleccionando las fechas del periodo, intente más tarde',
                severity: 'error',
            });
        } finally {
            setLoading((prevstate) => ({ ...prevstate, ...{ contract: false } }));
        }
    };

    const fetchPeriodBalance = async () => {
        try {
            setDisbursementAlert({
                msg: '',
                severity: 'success',
            });
            setLoading((prevstate) => ({ ...prevstate, ...{ period: true } }));
            const res: any = await fairplayAPI.get(
                    `/v1/${selectedContract.id}/available?start_date=${
                        disbursementPeriod === 'current'
                            ? periodsDates?.ISOcurrentPeriodStartDate
                            : periodsDates?.nextPeriodStartDate
                    }`,
                    {
                        baseService: 'AVAILABLE_LOANBOOK',
                    },
                ),
                availableBalance = monetaryDecimalToInteger(
                    res.data.body.results.period_total_available,
                ),
                newExchangeRates = res.data.body.results.exchange_rates || {};

            // eslint-disable-next-line no-restricted-syntax
            for (const currency in newExchangeRates) {
                if (Object.prototype.hasOwnProperty.call(newExchangeRates, currency))
                    newExchangeRates[currency].rate = monetaryDecimalToInteger(
                        newExchangeRates[currency].rate,
                    );
            }

            setPeriodAvailableBalance(availableBalance);
            setExchangeRates(newExchangeRates);

            if (!availableBalance)
                setDisbursementAlert({
                    msg: 'El contrato no cuenta con saldo disponible',
                    severity: 'warning',
                });

            if (
                selectedContract.productType === 'sales-advancement' &&
                disbursementLocation === 'Internacional'
            ) {
                setDisbursementAlert({
                    msg: 'El contrato seleccionado no permite realizar dispersiones internacionales',
                    severity: 'warning',
                });
            }
        } catch (errResponse: ErrorObj | any) {
            setDisbursementAlert({
                msg:
                    errResponse?.error ||
                    'Ocurrió un error seleccionando el balance disponible, intente más tarde',
                severity: 'error',
            });
        } finally {
            setLoading((prevstate) => ({ ...prevstate, ...{ period: false } }));
        }
    };

    const setPeriodDateRange = () => {
        if (!periodsDates?.currentPeriodMinDate || !periodsDates?.currentPeriodEndDate) {
            if (disbursementAlert.msg)
                setDisbursementAlert({
                    msg: 'Ocurrió un error seleccionando las fechas del periodo, intente más tarde',
                    severity: 'error',
                });
            return;
        }
        const referenceDates =
            disbursementPeriod === 'current'
                ? {
                      min: periodsDates.currentPeriodMinDate,
                      max: periodsDates?.currentPeriodEndDate && periodsDates.currentPeriodEndDate,
                  }
                : {
                      min: periodsDates.nextPeriodStartDate,
                      max: periodsDates?.nextPeriodEndDate && periodsDates.nextPeriodEndDate,
                  };

        setMinDisbursementDate(
            referenceDates.min ? negateLocalOffset(new Date(referenceDates.min)) : undefined,
        );
        setMaxDisbursementDate(
            referenceDates.max ? negateLocalOffset(new Date(referenceDates.max)) : undefined,
        );
    };

    useEffect(() => {
        initializeDisbursement();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedContract) fetchPeriodsDates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedContract]);

    useEffect(() => {
        if (selectedContract && periodsDates) {
            fetchPeriodBalance();
            setPeriodDateRange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disbursementPeriod, selectedContract, periodsDates]);

    return (
        <Grid item container pt="35px" mb="40px" spacing={4}>
            <Grid item xs={12} sm={6} md={3}>
                {/* eslint-disable-next-line no-nested-ternary */}
                {loading.contract ? (
                    <Skeleton height={55} sx={{ mt: '25px' }} />
                ) : disbursementStep ? (
                    <DefaultInput
                        label="Contrato activo"
                        labelVariant="external"
                        disabled
                        id="active-contract"
                        value={
                            selectedContract
                                ? `Contrato ${contractPrintableName(selectedContract)}`
                                : ''
                        }
                    />
                ) : (
                    <Select
                        label="Contrato activo"
                        labelVariant="external"
                        name="contract"
                        value={contracts.length > 0 ? selectedContract.id : ''}
                        onChange={onContractSelect}
                        disabled={!!disbursementAlert.msg}
                    >
                        {contracts.length > 0 ? (
                            contracts.map((contract: HydratedContract) => (
                                <MenuItem
                                    key={contract.id}
                                    value={contract.id}
                                >{`Contrato ${contractPrintableName(contract)}`}</MenuItem>
                            ))
                        ) : (
                            <MenuItem value="">No hay contratos registrados</MenuItem>
                        )}
                    </Select>
                )}
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                {loading.contract || loading.period ? (
                    <Skeleton
                        width="100%"
                        height={55}
                        sx={{ m: { xs: '25px 0 0', sm: '25px 0 20px' } }}
                        data-testid="available-balance-skeleton"
                    />
                ) : (
                    <>
                        <Typography
                            variant="label"
                            component={Grid}
                            container
                            wrap="nowrap"
                            display="flex"
                        >
                            <Typography
                                variant="inherit"
                                color="text.primary"
                                mr="5px"
                                aria-hidden="true"
                                fontWeight={500}
                            >
                                Disponible
                            </Typography>
                            <Grid item maxWidth={['fit-content', '45%', '60%']}>
                                {disbursementStep ? (
                                    <Typography
                                        variant="inherit"
                                        color="primary.main"
                                        aria-hidden="true"
                                        fontWeight={700}
                                        ellipsis
                                    >
                                        {disbursementPeriod === 'current'
                                            ? 'Periodo actual'
                                            : 'Periodo siguiente'}
                                    </Typography>
                                ) : (
                                    <Select
                                        label="Periodo de dispersión"
                                        value={disbursementPeriod}
                                        onChange={onPeriodChange}
                                        variant="standard"
                                        disabled={!!disbursementStep}
                                        sx={(theme: any) => ({
                                            '.MuiSelect-select': { p: '0 24px 0 0' },
                                            '.MuiSvgIcon-root': {
                                                color: `${theme.palette.secondary.main} !important`,
                                            },
                                        })}
                                    >
                                        <MenuItem value="current">Periodo actual</MenuItem>
                                        <MenuItem
                                            value="next"
                                            disabled={
                                                !periodsDates?.nextPeriodStartDate ||
                                                !periodsDates?.nextPeriodEndDate
                                            }
                                        >
                                            Periodo siguiente
                                        </MenuItem>
                                    </Select>
                                )}
                            </Grid>
                        </Typography>
                        <CurrencyInput
                            label="Monto disponible en el periodo"
                            inputProps={{
                                'aria-describedby': 'available-balance',
                            }}
                            disabled
                            id="disbursement-available-balance"
                            value={monetaryIntegerToDecimal(periodAvailableBalance)}
                            customInput={DefaultInput}
                            startAdornment={<AttachMoney />}
                            thousandSeparator
                            decimalScale={2}
                            sx={{
                                '.MuiInputBase-input': {
                                    textAlign: 'right',
                                },
                            }}
                        />
                        <Typography
                            variant="body2"
                            mt="5px"
                            id="available-balance"
                            color="primary.dark"
                        >
                            De{' '}
                            <Typography component="span" color="primary.main" fontWeight={700}>
                                {periodsDates &&
                                    (disbursementPeriod === 'current'
                                        ? `${formatUtc(
                                              periodsDates.ISOcurrentPeriodStartDate,
                                              SHORT_READABLE_FORMAT,
                                              {
                                                  fallbackString: '',
                                              },
                                          )}`
                                        : `${formatUtc(
                                              periodsDates.nextPeriodStartDate,
                                              SHORT_READABLE_FORMAT,
                                              {
                                                  fallbackString: '',
                                              },
                                          )}`)}
                            </Typography>{' '}
                            hasta{' '}
                            <Typography component="span" color="primary.main" fontWeight={700}>
                                {periodsDates &&
                                    (disbursementPeriod === 'current'
                                        ? `${formatUtc(
                                              periodsDates.currentPeriodEndDate,
                                              SHORT_READABLE_FORMAT,
                                              {
                                                  fallbackString: '',
                                              },
                                          )}`
                                        : `${formatUtc(
                                              periodsDates.nextPeriodEndDate,
                                              SHORT_READABLE_FORMAT,
                                              {
                                                  fallbackString: '',
                                              },
                                          )}`)}
                            </Typography>
                        </Typography>
                    </>
                )}
            </Grid>

            <Grid item xs={12} sm={6} md={3} sx={{ opacity: disbursementStep ? 1 : 0.5 }}>
                <Typography
                    htmlFor="disbursement-requested-amount"
                    sx={{ display: 'flex', justifyContent: 'space-between', lineHeight: 1.4 }}
                    fontWeight={500}
                    component="label"
                    color="text.primary"
                    variant="body1"
                    mb="5px"
                >
                    Total solicitado
                    {hasForeignCurrencies && (
                        <IconBtn
                            onClick={() =>
                                setExpand((prev) => ({
                                    ...prev,
                                    amounts: !prev.amounts,
                                }))
                            }
                            color="secondary"
                            aria-controls="amounts-currency-details"
                            aria-expanded={expand.amounts}
                            sx={{
                                p: '0 !important',
                                mr: '17px',
                                mb: '-3px',
                            }}
                        >
                            {expand.amounts ? (
                                <Remove titleAccess="Ocultar detalle de monto" />
                            ) : (
                                <MoreHoriz titleAccess="Muestra detalle de monto" />
                            )}
                        </IconBtn>
                    )}
                </Typography>
                <Paper variant="elevation">
                    <CurrencyInput
                        labelVariant="none"
                        inputProps={{
                            'aria-describedby': 'exchange-rate',
                        }}
                        disabled
                        id="disbursement-requested-amount"
                        value={monetaryIntegerToDecimal(totalRequestedAmountSum)}
                        customInput={DefaultInput}
                        startAdornment={<AttachMoney />}
                        thousandSeparator
                        error={!isAmountValid ? true : undefined}
                        sx={{
                            '.MuiInputBase-input': {
                                textAlign: 'right',
                            },
                        }}
                    />
                    {hasForeignCurrencies && (
                        <Collapse in={expand.amounts}>
                            <Grid
                                id="amounts-currency-details"
                                container
                                borderTop={2}
                                borderColor={expand.amounts ? 'background.dark' : 'transparent'}
                                direction="column"
                                padding="5px 15px 10px"
                                role="table"
                                aria-label="Detalle del monto"
                            >
                                <CurrenciesInfo
                                    cartData={cartData}
                                    totalRequestedAmount={totalRequestedAmount}
                                    dataType="totalAmount"
                                />
                            </Grid>
                        </Collapse>
                    )}
                </Paper>
                <Grid container alignItems="center" wrap="nowrap">
                    {loading.period ? (
                        <Skeleton width="80%" height={10} />
                    ) : (
                        <Typography
                            variant="body2"
                            mt="4px"
                            id="exchange-rate"
                            color="primary.dark"
                        >
                            Tipo de cambio aproximado:{' '}
                        </Typography>
                    )}
                    <Tooltip
                        color="paper"
                        overrideStyles={{ maxWidth: 155 }}
                        title={
                            <>
                                <Typography variant="body2" mt="5px" color="primary.dark">
                                    El monto es un estimado y puede variar según el día que se
                                    ejecute la dispersión.
                                </Typography>
                                <Typography
                                    variant="body2"
                                    mt="5px"
                                    color="primary.main"
                                    fontWeight={700}
                                >
                                    USD ${monetaryIntegerToDecimal(exchangeRates.usd?.rate)}
                                </Typography>
                                {foreignCurrenciesUsed.map((currency: any) =>
                                    currency[0] === 'usd' ? null : (
                                        <Typography
                                            variant="body2"
                                            mt="5px"
                                            color="primary.main"
                                            fontWeight={700}
                                            key={currency[0]}
                                        >
                                            {currency[0].toUpperCase()} $
                                            {monetaryIntegerToDecimal(
                                                exchangeRates[currency[0]]?.rate,
                                            )}
                                        </Typography>
                                    ),
                                )}
                            </>
                        }
                        placement="right-start"
                        disabled={!disbursementStep}
                    >
                        {/*
                            Span necessary to avoid MUI 'Tolltip containing disable button' warning.
                            Manually moving accesible label to button component.
                        */}
                        <span>
                            <IconBtn
                                color="info"
                                disabled={!disbursementStep}
                                aria-label="Saber más"
                                sx={{
                                    p: 0,
                                }}
                            >
                                <Info />
                            </IconBtn>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={3} sx={{ opacity: disbursementStep ? 1 : 0.5 }}>
                <Typography
                    htmlFor="disbursement-requested-currencies"
                    sx={{ lineHeight: 1.4 }}
                    fontWeight={500}
                    component="label"
                    color="text.primary"
                    variant="body1"
                    mb="5px"
                    display="flex"
                    justifyContent="space-between"
                >
                    <span id="requested-dispersion-title">Dispersiones solicitadas</span>
                    {hasForeignCurrencies && (
                        <IconBtn
                            onClick={() =>
                                setExpand((prev) => ({
                                    ...prev,
                                    disbursements: !prev.disbursements,
                                }))
                            }
                            color="secondary"
                            aria-controls="disbursement-currency-details"
                            aria-expanded={expand.disbursements}
                            sx={{
                                p: '0 !important',
                                mr: '17px',
                                mb: '-3px',
                            }}
                        >
                            {expand.disbursements ? (
                                <Remove titleAccess="Ocultar detalle de dispersiones" />
                            ) : (
                                <MoreHoriz titleAccess="Muestra detalle de dispersiones" />
                            )}
                        </IconBtn>
                    )}
                </Typography>
                <Paper
                    variant="elevation"
                    sx={{ width: '100%' }}
                    aria-describedby="requested-dispersion"
                    aria-labelledby="requested-dispersion-title"
                    role="status"
                >
                    <Grid container height={53} alignItems="center" justifyContent="center">
                        <Receipt color="secondary" />
                        <Typography variant="body1" color="primary.main" fontWeight={700}>
                            {disbursementStep ? cartData.length : '-'}
                        </Typography>
                    </Grid>
                    {hasForeignCurrencies && (
                        <Collapse in={expand.disbursements}>
                            <Grid
                                id="disbursement-requested-currencies"
                                container
                                borderTop={2}
                                borderColor={
                                    expand.disbursements ? 'background.dark' : 'transparent'
                                }
                                direction="column"
                                padding="5px 15px 10px"
                                role="table"
                                aria-label="Detalle de dispersiones"
                            >
                                <CurrenciesInfo
                                    cartData={cartData}
                                    totalRequestedAmount={totalRequestedAmount}
                                    dataType="disbursementsCount"
                                />
                            </Grid>
                        </Collapse>
                    )}
                </Paper>
                {selectedContract?.productType === 'working-capital' && (
                    <Typography
                        variant="body2"
                        mt="5px"
                        id="requested-dispersion"
                        color="primary.dark"
                    >
                        Proveedores:{' '}
                        <Typography component="span" color="primary.main" fontWeight={700}>
                            {suppliersCount}
                        </Typography>
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
};

export default DisbursementInfo;
