import { FC, useState, useEffect, useContext, lazy, Suspense, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SessionContext from 'context/session/sessionContext';
import { Typography, IconBtn, DefaultBtn, Skeleton, Alert } from '@fairplay2/ui';
import { Grid, Collapse } from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';
import fairplayAPI from 'utils/api';
import { AlertProps, AlertType } from 'utils/interfaces';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { Container, StyledSkeleton } from '../../styles';
import { InvoiceValues } from '../../interfaces';
import InvoiceHeader from './InvoiceHeader';
import InvoiceEmissionDetail from './InvoiceEmissionDetail';
import InvoiceDetailTable from './InvoiceDetailTable';
import InvoiceExpenses from './InvoiceExpenses';
import CFDIdetail from './CFDIdetail';
import FiscalDetailSkeleton from './FiscalDetailSkeleton';

const DownloadButtons = lazy(
    () => import(/* webpackChunkName: 'DownloadButtons' */ './DownloadButtons'),
);

const FiscalDetail: FC = () => {
    const { selectedCompany } = useContext(SessionContext),
        { id: invoiceId } = useParams<{ id: string }>(),
        [invoiceValues, setInvoiceValues] = useState<InvoiceValues | null>(null),
        { push } = useHistory(),
        [loading, setLoading] = useState(true),
        [alert, setAlert] = useState<AlertProps>({
            type: 'error',
            message: '',
            open: false,
        });

    const onAlert = useRef((type: AlertType, msg: string) => {
        setAlert({
            type,
            message: msg,
            open: true,
        });

        setTimeout(() => {
            setAlert((prev) => ({ ...prev, message: '', open: false }));
        }, 3000);
    });

    useEffect(() => {
        if (Object.keys(selectedCompany).length > 0) {
            const fetchContract = async () => {
                try {
                    const res: any = await fairplayAPI.get(
                        `/v2/companies/${selectedCompany?.company?.id}/invoices/${invoiceId}`,
                    );
                    setInvoiceValues(res.data.body.results);
                } catch (errResponse: ErrorObj | any) {
                    if (
                        errResponse?.status === 404 &&
                        window.location.pathname.includes('/app/fiscal/detail')
                    )
                        push('/app/fiscal/');
                    onAlert.current(
                        'error',
                        errResponse?.error || 'Ocurrió un error, intente más tarde',
                    );
                } finally {
                    setLoading(false);
                }
            };
            fetchContract();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany, invoiceId]);

    return (
        <Grid container>
            <Grid item container xs={12} alignItems="center" wrap="nowrap" my="25px">
                <IconBtn
                    variant="filled"
                    color="primary"
                    size="small"
                    onClick={() => push('/app/fiscal')}
                    sx={{
                        p: '0 !important',
                        svg: {
                            fontSize: '1.5em',
                        },
                    }}
                >
                    <KeyboardArrowLeft />
                </IconBtn>
                <Typography variant="h4" color="primary.main" fontWeight={500} ml="15px">
                    Detalle Factura{' '}
                    {!loading && (
                        <Typography component="span" color="primary.dark" fontWeight={700}>
                            {invoiceValues?.folioFiscal}
                        </Typography>
                    )}
                </Typography>
                {loading && <Skeleton width={200} height={24} />}
            </Grid>
            {loading ? (
                <FiscalDetailSkeleton />
            ) : (
                <Container container item xs={12} md={11}>
                    <InvoiceHeader invoiceValues={invoiceValues} />
                    <InvoiceEmissionDetail invoiceValues={invoiceValues} />
                    <InvoiceDetailTable conceptDetail={invoiceValues?.invoicesItems} />
                    <InvoiceExpenses invoiceValues={invoiceValues} />
                    <Collapse
                        sx={{ width: '100%' }}
                        in={
                            !!invoiceValues?.stampCfd &&
                            !!invoiceValues?.stampSat &&
                            !!invoiceValues?.qrInvoiceSat
                        }
                    >
                        <CFDIdetail invoiceValues={invoiceValues} />
                    </Collapse>
                </Container>
            )}
            <Grid item container xs={12} md={11} marginBottom={5}>
                <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={3}
                    sm={4}
                    md={3}
                >
                    <DefaultBtn variant="text" onClick={() => push('/app/fiscal')}>
                        Regresar a listado
                    </DefaultBtn>
                </Grid>
                <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent={{ xs: 'center', md: 'flex-end' }}
                    xs={12}
                    md={4}
                    lg={5}
                    sx={{
                        order: { xs: 2, md: 1 },
                    }}
                >
                    <Collapse in={alert.open}>
                        <Alert
                            sx={{
                                mr: { xs: 0, md: '-15%' },
                            }}
                            severity={alert.type}
                        >
                            {alert.message}
                        </Alert>
                    </Collapse>
                </Grid>
                <Grid
                    item
                    container
                    justifyContent="flex-end"
                    xs={9}
                    sm={8}
                    md={5}
                    lg={4}
                    sx={{ order: { xs: 1, md: 2 } }}
                >
                    <Suspense
                        fallback={
                            <>
                                <StyledSkeleton />
                                <StyledSkeleton />
                            </>
                        }
                    >
                        <DownloadButtons
                            invoiceId={invoiceId}
                            onAlert={onAlert.current}
                            company={selectedCompany.company}
                            setInvoiceValues={setInvoiceValues}
                            invoiceValues={invoiceValues}
                        />
                    </Suspense>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FiscalDetail;
