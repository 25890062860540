import { FC } from 'react';
import TableProvider from '../../../state/TableContext';
import { CardParamsProps } from '../../interfaces';
import { DEFAULT_EXE_TRANSACTION_FILTERS } from '../../../constants';
import TransactionsTable from './ExecutedTransactionsTable';
import ExecutedTransactionFilters from './ExecutedTransactionFilters';

export const ExecutedTransactionsWidget: FC<CardParamsProps> = ({ cardId }) => (
    <TableProvider initialData={DEFAULT_EXE_TRANSACTION_FILTERS}>
        <ExecutedTransactionFilters />
        <TransactionsTable cardId={cardId} />
    </TableProvider>
);

export default ExecutedTransactionsWidget;
