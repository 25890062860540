import { FC, useContext } from 'react';
import { DefaultInput } from '@fairplay2/ui';
import { Grid } from '@mui/material';
import SessionContext from 'context/session/sessionContext';
import { CompanyDataAddress } from './interfaces';
import CompanyDataSkeleton from './components/CompanyDataSkeleton';

const CompanyData: FC = () => {
    const { selectedCompany } = useContext(SessionContext),
        loading = Object.keys(selectedCompany).length === 0,
        values: CompanyDataAddress = {
            state: selectedCompany.company?.address?.settlement?.state || '',
            township: selectedCompany.company?.address?.settlement?.municipality || '',
            country: selectedCompany.company?.country?.name || '',
            zipCode: selectedCompany.company?.address?.settlement?.postalCode || '',
            settlement: selectedCompany.company?.address?.settlement?.name || '',
            street: selectedCompany.company?.address?.street || '',
            numExt: selectedCompany.company?.address?.exteriorNumber || '',
            numInt: selectedCompany.company?.address?.interiorNumber || '',
        };

    return (
        <Grid container spacing={4} mb={5}>
            {loading ? (
                <CompanyDataSkeleton />
            ) : (
                <>
                    <Grid item xs={12} sm={6} md={4}>
                        <DefaultInput
                            id="country"
                            label="País:"
                            labelVariant="external"
                            value={values.country}
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <DefaultInput
                            id="zipCode"
                            name="zipCode"
                            label="Código Postal:"
                            labelVariant="external"
                            value={values.zipCode}
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <DefaultInput
                            id="state"
                            name="state"
                            label="Estado:"
                            labelVariant="external"
                            value={values.state}
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <DefaultInput
                            id="township"
                            name="township"
                            label="Alcaldía / Municipio:"
                            labelVariant="external"
                            value={values.township}
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <DefaultInput
                            id="settlement"
                            name="settlement"
                            label="Colonia:"
                            labelVariant="external"
                            value={values.settlement}
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <DefaultInput
                            id="street"
                            name="street"
                            label="Calle / Vialidad:"
                            labelVariant="external"
                            value={values.street}
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <DefaultInput
                            id="numExt"
                            name="numExt"
                            label="Número exterior:"
                            labelVariant="external"
                            value={values.numExt}
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <DefaultInput
                            id="numInt"
                            name="numInt"
                            label="Número interior:"
                            labelVariant="external"
                            value={values.numInt}
                            disabled
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default CompanyData;
