import { FC, useContext, useState, useEffect, ChangeEvent } from 'react';
import { Grid } from '@mui/material';
import { EditableInput } from '@fairplay2/ui';
import SessionContext from 'context/session/sessionContext';
import fairplayAPI from 'utils/api';
import { toFormData } from 'utils/parsing';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ErrorObj } from 'utils/error-handler';
import { EditCompanyNameProps } from '../interfaces';

const EditCompanyName: FC<EditCompanyNameProps> = ({ onAlert, ...props }) => {
    const { selectedCompany, updateCompany } = useContext(SessionContext),
        [businessName, setBusinessName] = useState(''),
        [loading, setLoading] = useState(false);

    const onCancel = () => {
        setBusinessName(selectedCompany.company?.businessName || '');
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>) => setBusinessName(event.target.value);

    const onSaveName = async () => {
        setLoading(true);
        try {
            const res: any = await fairplayAPI.patch(
                `/v1/companies/${selectedCompany.company?.id}`,
                toFormData({ businessName }),
            );
            const newCompany = { ...selectedCompany, company: res.data.body.results };
            await updateCompany(newCompany, true);
            onAlert('Nombre de la empresa actualizado exitosamente', 'success');
        } catch (errResponse: ErrorObj | any) {
            onAlert(
                errResponse?.error ||
                    'Ocurrió un error al cambiar el nombre de la empresa, intente más tarde',
                'error',
            );
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedCompany.company) setBusinessName(selectedCompany.company?.businessName);
    }, [selectedCompany]);

    return (
        <Grid item container {...props}>
            <Grid item xs>
                <EditableInput
                    id="businessName"
                    label="Nombre de la empresa:"
                    loading={loading}
                    labelVariant="external"
                    placeholder="Ingresa el nombre de la empresa"
                    confirmButtonLabel="Confirmar"
                    editButtonLabel="Editar nombre"
                    value={businessName}
                    onChange={onChange}
                    onCancel={onCancel}
                    onConfirm={onSaveName}
                />
            </Grid>
        </Grid>
    );
};

export default EditCompanyName;
