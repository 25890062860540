import { FC } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { ManSittingPlanSvg } from 'assets/SVGs';
import { Modal, DefaultBtn, Typography } from '@fairplay2/ui';
import { ModalProps } from 'utils/interfaces';

const DuplicatedAccountModal: FC<ModalProps> = ({ open, onClose, onConfirm }) => {
    const theme = useTheme(),
        smDown = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Modal disableBackdropClick open={open} onClose={onClose} maxWidth={680}>
            <Grid container>
                <Grid item container xs={12} justifyContent="center">
                    <ManSittingPlanSvg />
                </Grid>
                <Grid item xs={12} marginTop={3}>
                    <Typography variant="subtitle1" color="primary.main">
                        ¡Esta dirección de correo electrónico no se puede registrar!
                    </Typography>
                </Grid>
                <Grid item xs={12} marginTop={2}>
                    <Typography color="text.primary" fontWeight={400}>
                        Si ya tienes una cuenta puedes iniciar sesión o intentar tu registro con una
                        dirección de correo distinta.
                    </Typography>
                </Grid>
                <Grid item container justifyContent="center" xs={12} marginTop={4}>
                    <DefaultBtn
                        fullWidth={smDown}
                        size="small"
                        variant="outlined"
                        onClick={onConfirm}
                        sx={{ mr: smDown ? 'initial' : '15px', mt: '10px' }}
                    >
                        Iniciar sesión
                    </DefaultBtn>
                    <DefaultBtn
                        fullWidth={smDown}
                        size="small"
                        onClick={onClose}
                        sx={{ mt: smDown ? '15px' : '10px' }}
                    >
                        Crear cuenta nueva
                    </DefaultBtn>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default DuplicatedAccountModal;
